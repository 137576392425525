import { useState, useEffect, useRef } from 'react'
import { useMatch, Navigate } from 'react-router-dom'
import axios from 'axios'
import ReactSelect from 'react-select'
import Moment from 'react-moment'
import AuthService from '../../services/AuthService'
import authHeader from '../../services/AuthHeader'
import { v4 as uuidv4 } from 'uuid'
import { MultiSelect } from "react-multi-select-component"
import Button from '../UI/Button'
import { format } from 'util'

const ModalAddToBillingPlan = (props) => {

    const [firstInvoiceDate, setFirstInvoiceDate] = useState('')
    // const [firstInvoicePercentage, setFirstInvoicePercentage] = useState(25)
    // const [firstInvoiceValue, setFirstInvoiceValue] = useState(Number(props.serviceDetails.fee) * 0.25)

    const [numberOfIntermediateInvoices, setNumberOfIntermediateInvoices] = useState(0)
    const [valueOfEachIntermittentInvoice, setValueOfEachIntermittentInvoice] = useState(0)
    const [totalValueOfAllIntermittentInvoices, setTotalValueOfAllIntermittentInvoices] = useState(0.01)

    const [lastInvoiceDate, setLastInvoiceDate] = useState('')
    // const [lastInvoicePercentage, setLastInvoicePercentage] = useState(25)
    // const [lastInvoiceValue, setLastInvoiceValue] = useState(Number(props.serviceDetails.fee) * 0.25)

    const firstInvoicePercentageInput = useRef(null);
    const firstInvoiceValueInput = useRef(null);
    const lastInvoicePercentageInput = useRef(null);
    const lastInvoiceValueInput = useRef(null);

    const moment = require('moment');
    
    const API_URL = process.env.REACT_APP_API_URL

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    function formatInvoiceDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/');
    }

    function stringToDate(_date,_format,_delimiter)
    {
        var formatLowerCase=_format.toLowerCase();
        var formatItems=formatLowerCase.split(_delimiter);
        var dateItems=_date.split(_delimiter);
        var monthIndex=formatItems.indexOf("mm");
        var dayIndex=formatItems.indexOf("dd");
        var yearIndex=formatItems.indexOf("yyyy");
        var month=parseInt(dateItems[monthIndex]);
        month-=1;
        var formatedDate = new Date(dateItems[yearIndex],month,dateItems[dayIndex]);
        return formatedDate;
    }

    const addInvoice = async (form) => {
        await axios.post(process.env.REACT_APP_API_URL + '/invoices', form)
    }

    const onSubmit = async (e) => {
        e.preventDefault()

        if (firstInvoiceDate > lastInvoiceDate) {

            window.alert("Please ensure that the Last Invoice Date is later than the First Invoice Date")

            return

        }

        if (props.serviceDetails.clientToInvoice === "" || props.serviceDetails.invoiceContactId === "") {

            window.alert("Please set Client and Contact before adding this Service to the Billing Plan")

            return

        }

        //take off any fees that are costs and use this instead of 
        var costActivities = props.serviceDetails.activities.filter(item => item.cost > 0)

        if (costActivities.filter(item => item.costCategory === "").length > 0) {

            window.alert("Please ensure all cost related activities have been assigned a cost category")

            return

        }

        var activityWithCost
        var costType

        var newInvoices
        await axios.get(API_URL + '/invoices_by_project/' + props.serviceDetails.projectId)
        .then((res) => {
            newInvoices = res.data.sort((item1, item2) => item1.invoiceNumber < item2.invoiceNumber ? -1 : item1.invoiceNumber > item2.invoiceNumber ? 1 : 0)
        })
        .catch((err) => {
            console.log(err);
        })

        var invoiceNumber
        var newInvoiceListArray = []
        var sortedDNumbers = []

        if (newInvoices.filter((item) => item.invoiceNumber.substring(0, 1) === "D").length > 0) {
            sortedDNumbers = newInvoices.filter((item) => item.invoiceNumber.substring(0, 1) === "D").sort((item1, item2) => Number(item1.invoiceNumber.substring(1, item1.invoiceNumber.length)) < Number(item2.invoiceNumber.substring(1, item2.invoiceNumber.length)) ? -1 : Number(item1.invoiceNumber.substring(1, item1.invoiceNumber.length)) > Number(item2.invoiceNumber.substring(1, item2.invoiceNumber.length)) ? 1 : 0)
            invoiceNumber = sortedDNumbers[sortedDNumbers.length-1].invoiceNumber
            invoiceNumber = Number(invoiceNumber.slice(1,invoiceNumber.length)) + 1
            invoiceNumber = "D" + invoiceNumber
        } else {
            invoiceNumber = "D1"
        }

        var numberOfExtraInvoices = 0
        
        if (lastInvoiceValueInput.current.value > 0) {
            numberOfExtraInvoices = 1
        }

        var daysBetweenIntermediateInvoices = ((Number(moment(firstInvoiceDate).diff(lastInvoiceDate,'days')) * -1) / (Number(numberOfIntermediateInvoices) + numberOfExtraInvoices))
    
        // newInvoiceListArray.push({ invoiceLineItemId: uuidv4(), serviceId: props.serviceDetails.serviceId, value: props.serviceDetails.fee, invoiceLineDescription: props.serviceDetails.projectNumber + " - " + props.serviceDetails.projectName + ": " + props.serviceDetails.service + " - Fee due for completion of <ENTER WORK DETAILS HERE> <MMM-YY>", invoiceLineComments: "", invoiceLineStatus: "Amber", okToInvoice: "NO", serviceManager: props.serviceDetails.serviceManagerId, projectManager: props.serviceDetails.projectManagerId })

        //Add firstInvoice
        newInvoiceListArray.push({ invoiceLineItemId: uuidv4(), serviceId: props.serviceDetails.serviceId, value: Number(firstInvoiceValueInput.current.value), invoiceLineDescription: props.serviceDetails.projectNumber + " - " + props.serviceDetails.projectName + ": " + props.serviceDetails.service + " - Fee due for completion of <ENTER WORK DETAILS HERE> <MMM-YY>", invoiceLineComments: "", invoiceLineStatus: "Amber", okToInvoice: "NO", serviceManager: props.serviceDetails.serviceManagerId, projectManager: props.serviceDetails.projectManagerId })
        if (numberOfIntermediateInvoices === 0 && Number(lastInvoiceValueInput.current.value) === 0) {
            //add costs as seperate invoice lines:
            for (activityWithCost = 0; activityWithCost < costActivities.length; activityWithCost++) {
                if (costActivities[activityWithCost].costCategory === "Certifications Fees") {
                    costType = "Third Party Fee"
                } else if (costActivities[activityWithCost].costCategory === "Freelance Environmental Consultants") {
                    costType = "Sub-contractor Fee"
                } else {
                    costType = "Project Expenses"
                }

                newInvoiceListArray.push({ invoiceLineItemId: uuidv4(), serviceId: props.serviceDetails.serviceId, value: Number(costActivities[activityWithCost].fee), invoiceLineDescription: props.serviceDetails.projectNumber + " - " + props.serviceDetails.projectName + ": " + props.serviceDetails.service + " - Fee due for " + costActivities[activityWithCost].activity + "<MMM-YY>", invoiceLineComments: "", invoiceLineStatus: costType, okToInvoice: "NO", serviceManager: props.serviceDetails.serviceManagerId, projectManager: props.serviceDetails.projectManagerId })
            }
        }
        await addInvoice({ invoiceNumber, invoiceDate: formatDate(firstInvoiceDate), invoicePaid: "NO", invoiceContactId: props.serviceDetails.clientToInvoiceContact, invoiceIssued: "NO", invoiceNotes: "", clientId: props.serviceDetails.clientToInvoice, invoiceLines: newInvoiceListArray, draftInvoice: "YES", projectId: props.serviceDetails.projectId, invoiceCustPo: props.serviceDetails.clientPO })

        //Add intermediate invoices

        var intermediateInvoice
        var newInvoiceDate = formatDate(firstInvoiceDate)

        for (intermediateInvoice = 0; intermediateInvoice < numberOfIntermediateInvoices; intermediateInvoice++) {
            newInvoiceListArray = []
            newInvoiceDate = moment(newInvoiceDate).add(daysBetweenIntermediateInvoices, 'days').locale('en').format("YYYY-MM-DD")
            invoiceNumber = Number(invoiceNumber.slice(1,invoiceNumber.length)) + 1
            invoiceNumber = "D" + invoiceNumber
            newInvoiceListArray.push({ invoiceLineItemId: uuidv4(), serviceId: props.serviceDetails.serviceId, value: Number(valueOfEachIntermittentInvoice), invoiceLineDescription: props.serviceDetails.projectNumber + " - " + props.serviceDetails.projectName + ": " + props.serviceDetails.service + " - Fee due for completion of <ENTER WORK DETAILS HERE> <MMM-YY>", invoiceLineComments: "", invoiceLineStatus: "Amber", okToInvoice: "NO", serviceManager: props.serviceDetails.serviceManagerId, projectManager: props.serviceDetails.projectManagerId })
                        
            if (intermediateInvoice === numberOfIntermediateInvoices - 1 && Number(lastInvoiceValueInput.current.value) === 0) {
                //add costs as seperate invoice lines:
                for (activityWithCost = 0; activityWithCost < costActivities.length; activityWithCost++) {
                    if (costActivities[activityWithCost].costCategory === "Certifications Fees") {
                        costType = "Third Party Fee"
                    } else if (costActivities[activityWithCost].costCategory === "Freelance Environmental Consultants") {
                        costType = "Sub-contractor Fee"
                    } else {
                        costType = "Project Expenses"
                    }

                    newInvoiceListArray.push({ invoiceLineItemId: uuidv4(), serviceId: props.serviceDetails.serviceId, value: Number(costActivities[activityWithCost].fee), invoiceLineDescription: props.serviceDetails.projectNumber + " - " + props.serviceDetails.projectName + ": " + props.serviceDetails.service + " - Fee due for " + costActivities[activityWithCost].activity + "<MMM-YY>", invoiceLineComments: "", invoiceLineStatus: costType, okToInvoice: "NO", serviceManager: props.serviceDetails.serviceManagerId, projectManager: props.serviceDetails.projectManagerId })
                }
            }
            
            await addInvoice({ invoiceNumber, invoiceDate: formatDate(newInvoiceDate), invoicePaid: "NO", invoiceContactId: props.serviceDetails.clientToInvoiceContact, invoiceIssued: "NO", invoiceNotes: "", clientId: props.serviceDetails.clientToInvoice, invoiceLines: newInvoiceListArray, draftInvoice: "YES", projectId: props.serviceDetails.projectId, invoiceCustPo: props.serviceDetails.clientPO })
        }

        //Add last invoice
        if (Number(lastInvoiceValueInput.current.value) > 0) {
            newInvoiceListArray = []
            invoiceNumber = Number(invoiceNumber.slice(1,invoiceNumber.length)) + 1
            invoiceNumber = "D" + invoiceNumber
            newInvoiceListArray.push({ invoiceLineItemId: uuidv4(), serviceId: props.serviceDetails.serviceId, value: Number(lastInvoiceValueInput.current.value), invoiceLineDescription: props.serviceDetails.projectNumber + " - " + props.serviceDetails.projectName + ": " + props.serviceDetails.service + " - Fee due for completion of <ENTER WORK DETAILS HERE> <MMM-YY>", invoiceLineComments: "", invoiceLineStatus: "Amber", okToInvoice: "NO", serviceManager: props.serviceDetails.serviceManagerId, projectManager: props.serviceDetails.projectManagerId })
            //add costs as seperate invoice lines:
            for (activityWithCost = 0; activityWithCost < costActivities.length; activityWithCost++) {
                if (costActivities[activityWithCost].costCategory === "Certifications Fees") {
                    costType = "Third Party Fee"
                } else if (costActivities[activityWithCost].costCategory === "Freelance Environmental Consultants") {
                    costType = "Sub-contractor Fee"
                } else {
                    costType = "Project Expenses"
                }

                newInvoiceListArray.push({ invoiceLineItemId: uuidv4(), serviceId: props.serviceDetails.serviceId, value: Number(costActivities[activityWithCost].fee), invoiceLineDescription: props.serviceDetails.projectNumber + " - " + props.serviceDetails.projectName + ": " + props.serviceDetails.service + " - Fee due for " + costActivities[activityWithCost].activity + "<MMM-YY>", invoiceLineComments: "", invoiceLineStatus: costType, okToInvoice: "NO", serviceManager: props.serviceDetails.serviceManagerId, projectManager: props.serviceDetails.projectManagerId })
            }

            await addInvoice({ invoiceNumber, invoiceDate: formatDate(lastInvoiceDate), invoicePaid: "NO", invoiceContactId: props.serviceDetails.clientToInvoiceContact, invoiceIssued: "NO", invoiceNotes: "", clientId: props.serviceDetails.clientToInvoice, invoiceLines: newInvoiceListArray, draftInvoice: "YES", projectId: props.serviceDetails.projectId, invoiceCustPo: props.serviceDetails.clientPO })
        }

        props.onClose()

    }


    useEffect(() => {

        if (props.show) {

            const newDate = new Date()

            firstInvoicePercentageInput.current.value = 50
            firstInvoiceValueInput.current.value = Number(props.serviceDetails.fee) * 0.5
            lastInvoicePercentageInput.current.value = 50
            lastInvoiceValueInput.current.value = Number(props.serviceDetails.fee) * 0.5
            setTotalValueOfAllIntermittentInvoices(0)
            setValueOfEachIntermittentInvoice(0)

            setFirstInvoiceDate(formatDate(newDate))
            setLastInvoiceDate(moment(formatDate(newDate)).add(1, 'months').locale('en').format("YYYY-MM-DD"))
            
        }

    },[props.serviceDetails.fee, props.show, moment])


    useEffect(() => {

        if (props.show && totalValueOfAllIntermittentInvoices === 0) {

            setNumberOfIntermediateInvoices(0)
            setValueOfEachIntermittentInvoice(0)

        }

        if (props.show && totalValueOfAllIntermittentInvoices !== 0) {

            if (isNaN(totalValueOfAllIntermittentInvoices / numberOfIntermediateInvoices)) {
                setValueOfEachIntermittentInvoice(0)
            } else {
                setValueOfEachIntermittentInvoice(totalValueOfAllIntermittentInvoices / numberOfIntermediateInvoices)
            }

        }

    },[totalValueOfAllIntermittentInvoices, numberOfIntermediateInvoices, props.show])



    if (!props.show || !firstInvoiceValueInput || !firstInvoiceValueInput || !lastInvoicePercentageInput || !lastInvoiceValueInput) {
        return null
    }
    return (
        <div className="update-value-modal z-50">
            <div className="update-value-modal-content">
                <div className="update-value-modal-header">
                    <p className="update-value-modal-title text-congress-blue text-xl">{props.title}</p>
                </div>
                <div className="update-value-modal-body">
                    <form className="" onSubmit={onSubmit}>

                        <div className="grid grid-cols-3">
                            <div className="form-control-8A">
                                <label>First Invoice Date</label>
                                <input type="date" placeholder="Enter Invoice Number Here" value={firstInvoiceDate} onChange={(e) => setFirstInvoiceDate(e.target.value)} />
                            </div>

                            <div className="form-control-8A">
                                <label>First Invoice %</label>
                                <input ref={firstInvoicePercentageInput} type="text" onBlur={() => {firstInvoiceValueInput.current.value =  (firstInvoicePercentageInput.current.value / 100) * props.serviceDetails.fee; setTotalValueOfAllIntermittentInvoices(Number(props.serviceDetails.fee) - firstInvoiceValueInput.current.value - lastInvoiceValueInput.current.value)}} />
                            </div>
                            <div className="form-control-8A">
                                <label>Value</label>
                                <input ref={firstInvoiceValueInput} type="text" onBlur={() => {firstInvoicePercentageInput.current.value =  (firstInvoiceValueInput.current.value / props.serviceDetails.fee) * 100; setTotalValueOfAllIntermittentInvoices(Number(props.serviceDetails.fee) - firstInvoiceValueInput.current.value - lastInvoiceValueInput.current.value)}} />
                            </div>
                        </div>

                        <div className="grid grid-cols-3">
                            <div className="form-control-8A">
                                <label>Intermediate Invoices</label>
                                <input type="text" placeholder="Enter Invoice Number Here" value={numberOfIntermediateInvoices} onChange={(e) => {setNumberOfIntermediateInvoices(e.target.value); setTotalValueOfAllIntermittentInvoices(Number(props.serviceDetails.fee) - firstInvoiceValueInput.current.value - lastInvoiceValueInput.current.value)}} />
                            </div>
                            <div className="form-control-8A">
                                <label>Value Each</label>
                                <input disabled={true} className="bg-slate-100" type="text" placeholder="Enter Invoice Number Here" value={valueOfEachIntermittentInvoice} />
                            </div>
                            <div className="form-control-8A">
                                <label>Total </label>
                                <input disabled={true} className="bg-slate-100" type="text" placeholder="Enter Invoice Number Here" value={totalValueOfAllIntermittentInvoices} />
                            </div>
                        </div>

                        <div className="grid grid-cols-3">
                            <div className="form-control-8A">
                                <label>Last Invoice Date</label>
                                <input type="date" placeholder="Enter Invoice Number Here" value={lastInvoiceDate} onChange={(e) => setLastInvoiceDate(e.target.value)} />
                            </div>

                            <div className="form-control-8A">
                                <label>Last Invoice %</label>
                                <input ref={lastInvoicePercentageInput} type="text" onBlur={() => {lastInvoiceValueInput.current.value =  (lastInvoicePercentageInput.current.value / 100) * props.serviceDetails.fee; setTotalValueOfAllIntermittentInvoices(Number(props.serviceDetails.fee) - firstInvoiceValueInput.current.value - lastInvoiceValueInput.current.value)}} />
                            </div>
                            <div className="form-control-8A">
                                <label>Value</label>
                                <input ref={lastInvoiceValueInput} type="text" onBlur={() => {lastInvoicePercentageInput.current.value =  (lastInvoiceValueInput.current.value / props.serviceDetails.fee) * 100; setTotalValueOfAllIntermittentInvoices(Number(props.serviceDetails.fee) - firstInvoiceValueInput.current.value - lastInvoiceValueInput.current.value)}} />
                            </div>
                        </div>

                        <div className="form-control-8A">
                            <label>Total Fees: {props.serviceDetails.fee}</label>
                            <label>Total Costs (will be added to final invoice): {props.serviceDetails.costs}</label>
                        </div>
                        {valueOfEachIntermittentInvoice === Infinity &&
                        <div className="text-red-500 text-xl p-2">Please specify number of intermediate invoices or assign total value over first and last invoices</div>
                        }
                        {totalValueOfAllIntermittentInvoices < 0 &&
                        <div className="text-red-500 text-xl p-2">Intermediate Invoices Total cannot be less than 0</div>
                        }
                        {props.serviceDetails.activities.filter(item => item.cost > 0 && item.costCategory === "").length > 0 &&
                        <div className="text-red-500 text-xl p-2">Some cost categories have not been set against cost related activities.  Please set cost categories against cost related activities to continue</div>
                        }
                        <input disabled={totalValueOfAllIntermittentInvoices < 0 || valueOfEachIntermittentInvoice === Infinity || props.serviceDetails.activities.filter(item => item.cost > 0 && item.costCategory === "").length > 0 } type="submit" value="Add to Billing Plan" className="cursor-pointer inline-block rounded-2xl w-full text-white bg-bondi-blue" />
                        <button className=" bg-congress-blue inline-block rounded-2xl w-full text-white mt-2 bottom-0" onClick={props.onClose}>Cancel</button>
                    </form>

                </div>

            </div>
    
        </div>
    )
}

export default ModalAddToBillingPlan


