import Header from '../UI/Header'
import { useState, useEffect } from 'react'
import { useMatch } from 'react-router-dom'
import { MapContainer, TileLayer, Marker, Popup, Polygon } from 'react-leaflet'
import { icon } from "leaflet"
import 'leaflet/dist/leaflet.css'
import FileUploadAndDownload from '../FileUploadAndDownload'
import axios from 'axios'
import Sidebar from '../UI/Sidebar'



const ViewSite = (props) => {

    const match = useMatch('/viewSite/:siteId')

    const [siteName, setSiteName] = useState('')
    const [siteDescription, setSiteDescription] = useState('')
    const [siteLatitude, setSiteLatitude] = useState('')
    const [siteLongitude, setSiteLongitude] = useState('')
    const [sitePolygonCoordinates, setSitePolygonCoordinates] = useState([])
    const [sidebarToggle, setSidebarToggle] = useState(false)

    const [loading, setLoading] = useState(true)

    useEffect(() => {

        axios.get(process.env.REACT_APP_API_URL + '/sites/' + match.params.siteId)
            .then((res) => {
                setSiteName(res.data.siteName)
                setSiteDescription(res.data.siteDescription)
                setSiteLatitude(res.data.siteLatitude)
                setSiteLongitude(res.data.siteLongitude)
                setSitePolygonCoordinates(res.data.sitePolygonCoordinates)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });

    }, [match.params.siteId])

    const ICON = icon({
        iconUrl: "/images/NZC-Hover.svg",
        iconSize: [27, 27],
    })

    if (!loading) {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="flex ">

                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title={siteName} section="" showButtons={false} iconFileName="Site-Hover" />

                        <div id="showcase-8A">

                        </div>

                        <br></br>
                        <div className="flex flex-row border-2 rounded-xl m-3">
                            <div className="m-5 basis-1/3">
                                <br></br>
                                <div>
                                    <div>
                                        <label> Site Name: </label>
                                        <p className="viewObjectField-8A"> {siteName}</p>
                                    </div>
                                    <div>
                                        <label> Site Description: </label>
                                        <p className="viewObjectField-8A"> {siteDescription}</p>
                                    </div>
                                    <div>
                                        <label> Site Latitude: </label>
                                        <p className="viewObjectField-8A"> {siteLatitude}</p>
                                    </div>
                                    <div>
                                        <label> Site Longitude: </label>
                                        <p className="viewObjectField-8A"> {siteLongitude}</p>
                                    </div>
                                    <br></br>

                                </div>
                            </div>

                            <div className="m-5 basis-2/3 float-right">
                                <MapContainer center={[siteLatitude, siteLongitude]} zoom={4} >
                                    <TileLayer
                                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                    <Marker icon={ICON} position={[siteLatitude, siteLongitude]}>
                                        <Popup>
                                            <h4>{siteName}</h4>
                                            <p>Latitude: {siteLatitude}</p>
                                            <p>Longitude: {siteLongitude}</p>

                                            <div>
                                                <h4> Site Polygon Coordinates: </h4>

                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th> Latitude</th>
                                                            <th> Longitude</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {sitePolygonCoordinates.map((coordinate) => (
                                                            <tr key={coordinate.point}>
                                                                <td> {coordinate.lat} </td>
                                                                <td> {coordinate.lng}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>

                                            </div>
                                        </Popup>
                                    </Marker>
                                    <Polygon color={'green'} positions={sitePolygonCoordinates} />
                                </MapContainer>
                            </div>
                        </div>

                        <br></br>

                        <div className="flex">
                            <div className="basis-1/3 border-2 rounded-xl bg-linen m-3"><p className="text-center">Supporting Documents: </p>
                                <FileUploadAndDownload id={match.params.siteId} />
                            </div>
                            <div className="basis-2/3 border-2 rounded-xl bg-linen m-3"><p className="text-center">Notes: </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )

    } else {

        return (
            <div>
                <Header title={siteName} section="" showButtons={false} iconFileName="Site-Hover" />
                <p>Data is loading...</p>
            </div>)

    }
};

export default ViewSite;
