import Header from '../UI/Header'
import { useState, useEffect, useRef } from 'react'
import { useMatch } from 'react-router-dom'
import Sidebar from '../UI/Sidebar'
import axios from 'axios'
import ReactPDF, { PDFDownloadLink, Page, Text, View, Document, StyleSheet, Image, Font} from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
// import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';

const ViewBREEAMSiteVisitReport = () => {

    Font.register({ family: 'TT Norms Pro', src: '/fonts/TypeType - TT Norms Pro Regular.ttf', fontStyle: 'normal', fontWeight: 'normal' });

    // const match = useMatch('/viewBuilding/:buildingId')

    const match = useMatch('/viewBREEAMSiteVisitReport/:buildingId/:assessmentId')

    const [sidebarToggle, setSidebarToggle] = useState(false)
    const [buildingBREEAMAssessments, setBuildingBREEAMAssessments] = useState([])

    const [files, setFiles] = useState([]);

    const [clientName, setClientName] = useState('');

    const [loading, setLoading] = useState(true)
    const [buildingsLoading, setBuildingsLoading] = useState(true)
    const [clientsLoading, setClientsLoading] = useState(true)

    useEffect(() => {

        axios.get(process.env.REACT_APP_API_URL + "/buildings_BREEAM_assessments/" + match.params.assessmentId)
            .then((response) => {
                // console.log(response.data)
                setBuildingBREEAMAssessments(response.data)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });

        axios.get(process.env.REACT_APP_API_URL + "/buildings/" + match.params.buildingId)
            .then((response) => {
                axios.get(process.env.REACT_APP_API_URL + "/clients/" + response.data.clientId)
                .then((res) => {
                    setClientName(res.data.clientName)
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setClientsLoading(false);
                });
            })
            // setClientName (response.data.clientId) })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setBuildingsLoading(false);
            });

        axios.get(process.env.REACT_APP_API_URL + '/presigned_url/' + match.params.buildingId)
        // axios.get(process.env.REACT_APP_API_URL + '/presigned_url')
            .then((res) => {
                setFiles(res.data)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });

    }, [match.params.buildingId, match.params.assessmentId])
  
    const styles = StyleSheet.create({
        body: {
          paddingTop: 35,
          paddingBottom: 65,
          paddingHorizontal: 35,
          fontFamily: 'TT Norms Pro'
        },
        reportTitle: {
            fontSize: 40,
            paddingTop: '40%',
            textAlign: 'center',
            fontFamily: 'TT Norms Pro'
            
          },
        reportSubtitle: {
            fontSize: 40,
            textAlign: 'center',
            fontFamily: 'TT Norms Pro'
            
          },
        author: {
            fontSize: 25,
            paddingTop: '40%',
            textAlign: 'center',
            fontFamily: 'TT Norms Pro'
          },
        title: {
          fontSize: 34,
          color: '#cbd5e1',
          paddingBottom: 10,
          fontFamily: 'TT Norms Pro'
        },
        section: {
            fontSize: 28,
            paddingBottom: 10,
            fontFamily: 'TT Norms Pro'
          },
        categoryCode: {
            fontSize: 15,
            paddingBottom: 10,
            fontFamily: 'TT Norms Pro'
          },
        creditDescription: {
            fontSize: 16,
            paddingBottom: 10,
            fontFamily: 'TT Norms Pro'
          },
        imageDescription: {
            fontSize: 14,
            paddingBottom: 10,
            fontFamily: 'TT Norms Pro'
          },
        text: {
          margin: 12,
          fontSize: 14,
          textAlign: 'justify',
          fontFamily: 'TT Norms Pro'
        },
        logo: {
            width: '119',
            paddingBottom: 20,
            fontFamily: 'TT Norms Pro'
          },
        image: {
          width: 'auto',
          height: 'auto',
          objectFit: 'scale-down',
          paddingBottom: 20,
          paddingTop: 20,
          fontFamily: 'TT Norms Pro'
        },
        pageNumber: {
          position: 'absolute',
          fontSize: 12,
          bottom: 30,
          left: 0,
          right: 0,
          textAlign: 'center',
          color: 'grey',
          fontFamily: 'TT Norms Pro'
        },
        rowView: {
            fontSize: 8, display: 'flex', flexDirection: 'row', borderTop: '1px solid #EEE', paddingTop: 8,
          paddingBottom: 8,
          paddingHorizontal: 35,
          fontFamily: 'TT Norms Pro'
        },
        responsibility: {
            fontWeight: 'bold',
            fontSize: 10,
            fontFamily: 'TT Norms Pro'
        },
        tickBox: {
            fontWeight: 'bold',
            fontSize: 20,
            fontFamily: 'TT Norms Pro'
        }
      });

    const downloadPdf = async () => {
    const fileName = 'test.pdf';
    const blob = await pdf(<MyDoc />).toBlob();
    // saveAs(blob, fileName);
    };
     
    const MyDoc = () => (

        <Document>
            <Page style={styles.body}>
            <Image
                style={styles.logo}
                src="/images/eight-versa-colour.png"
            />
            <Text style={styles.reportTitle}>BREEAM</Text>
            <Text style={styles.reportSubtitle}>Site Inspection Report</Text>
            <Text style={styles.reportSubtitle}>{clientName}</Text>
            <Text style={styles.author}>By {buildingBREEAMAssessments.projectManager}</Text>
            {buildingBREEAMAssessments.credits.map((credit) => (
                <view break>
                    <div key={credit.id}>
                        <Image
                            style={styles.logo}
                            src="/images/eight-versa-colour.png"
                        />
                        <Text style={styles.section}>{credit.section}</Text>
                        <Text style={styles.title}>BREEAM Site Inspection Report</Text>
                        <Text style={styles.categoryCode}>{credit.creditCategoryCode + " - " + credit.creditCategoryDescription}</Text>
                        <Text style={styles.creditDescription}>{credit.creditDescription}</Text>
                        {credit.files.filter(item => item.filename.slice(-3) === "jpg" || item.filename.slice(-3) === "JPG").map((file) => (
                            <div key={file.id}>
                                <Text style={styles.imageDescription}>{file.description}</Text>
                                <View wrap={false} style={{ display: 'flex', height: '400', width:'auto', position: 'relative' }}>
                                    <Image 
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                            bottom: 0,
                                            left: 0,
                                            objectFit: 'scale-down',
                                        }}
                                        src={files.find(item => item.name === match.params.buildingId + "/" + file.filename) && files.find(item => item.name === match.params.buildingId + "/" + file.filename).url}
                                    />
                                    </View>
                            </div>                        
                        ))}
                    </div>
                </view>
                ))}
                {/* <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                `${pageNumber} / ${totalPages}`
                )} fixed /> */}
            </Page>
        </Document>

    )

    if (!loading && !buildingsLoading && !clientsLoading) {

        return (

            <div>

                <div className="bg-slate-100 flex h-screen overflow-hidden">

                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden mt-1 m-5">
                        <div>
                            <div className="flex items-center bg-slate-100">
                                <div className="container max-w-6xl px-5 mx-auto my-5">


                                </div>
                            </div>
                        </div>
                    </div>

                    <button onClick={downloadPdf}>Download PDF</button>

                    {/* <PDFDownloadLink document={<MyDoc />} fileName="BREEAM_Site_Vist_Report_12345.pdf">
                        {({ blob, url, loading, error }) =>
                            loading ? 'Loading document...' : 'Download now!'
                        }
                    </PDFDownloadLink> */}

                {/* <PDFViewer className='w-full'>

                </PDFViewer > */}
            </div>
            </div>

        )

    } else {

        return (
            <div>
                Data Loaing... Please Wait...  
            </div>
        )

    }

};

export default ViewBREEAMSiteVisitReport;
