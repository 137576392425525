import Header from '../UI/Header'
import { useState, useEffect, useRef, useMemo } from 'react'
import { useMatch } from 'react-router-dom'
import Sidebar from '../UI/Sidebar'
import axios from 'axios'
import ReactPDF, { PDFViewer, PDFDownloadLink, Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';

const ViewBREEAMComplianceLetter = () => {

    Font.register({ family: 'TT Norms Pro', src: '/fonts/TypeType - TT Norms Pro Regular.ttf', fontStyle: 'normal', fontWeight: 'normal' });
    
    const match = useMatch('/viewBREEAMComplianceLetter/:buildingId/:assessmentId/:stakeholder')

    const [sidebarToggle, setSidebarToggle] = useState(false)
    const [buildingBREEAMAssessments, setBuildingBREEAMAssessments] = useState([])

    const [files, setFiles] = useState([]);

    const [clientName, setClientName] = useState('');

    const [loading, setLoading] = useState(true)
    const [buildingsLoading, setBuildingsLoading] = useState(true)
    const [clientsLoading, setClientsLoading] = useState(true)

    useEffect(() => {

        axios.get(process.env.REACT_APP_API_URL + "/buildings_BREEAM_assessments/" + match.params.assessmentId)
            .then((response) => {
                // console.log(response.data)
                setBuildingBREEAMAssessments(response.data)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });

        axios.get(process.env.REACT_APP_API_URL + "/buildings/" + match.params.buildingId)
            .then((response) => {
                axios.get(process.env.REACT_APP_API_URL + "/clients/" + response.data.clientId)
                .then((res) => {
                    setClientName(res.data.clientName)
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setClientsLoading(false);
                });
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setBuildingsLoading(false);
            });

        axios.get(process.env.REACT_APP_API_URL + '/presigned_url/' + match.params.buildingId)
            .then((res) => {
                setFiles(res.data)
            })
            .catch((err) => {
                console.log(err);
            })

    }, [match.params.assessmentId, match.params.buildingId])

    const styles = StyleSheet.create({
        body: {
          paddingTop: 35,
          paddingBottom: 65,
          paddingHorizontal: 35,
          fontFamily: 'TT Norms Pro'
        },
        reportTitle: {
            fontSize: 40,
            paddingTop: '40%',
            textAlign: 'center',
            fontFamily: 'TT Norms Pro'
            
          },
        reportSubtitle: {
            fontSize: 40,
            textAlign: 'center',
            fontFamily: 'TT Norms Pro'
            
          },
        author: {
            fontSize: 25,
            paddingTop: '40%',
            textAlign: 'center',
            fontFamily: 'TT Norms Pro'
          },
        title: {
          fontSize: 34,
          color: '#cbd5e1',
          paddingBottom: 10,
          fontFamily: 'TT Norms Pro'
        },
        section: {
            fontSize: 28,
            paddingBottom: 10,
            fontFamily: 'TT Norms Pro'
          },
        categoryCode: {
            fontSize: 15,
            paddingBottom: 10,
            fontFamily: 'TT Norms Pro'
          },
        creditDescription: {
            fontSize: 16,
            paddingBottom: 10,
            fontFamily: 'TT Norms Pro'
          },
        imageDescription: {
            fontSize: 14,
            paddingBottom: 10,
            fontFamily: 'TT Norms Pro'
          },
        text: {
          margin: 12,
          fontSize: 14,
          textAlign: 'justify',
          fontFamily: 'TT Norms Pro'
        },
        logo: {
            width: '119',
            paddingBottom: 20,
            fontFamily: 'TT Norms Pro'
          },
        image: {
          width: 'auto',
          height: 'auto',
          objectFit: 'scale-down',
          paddingBottom: 20,
          paddingTop: 20,
          fontFamily: 'TT Norms Pro'
        },
        pageNumber: {
          position: 'absolute',
          fontSize: 12,
          bottom: 30,
          left: 0,
          right: 0,
          textAlign: 'center',
          color: 'grey',
          fontFamily: 'TT Norms Pro'
        },
        rowView: {
            fontSize: 8, display: 'flex', flexDirection: 'row', borderTop: '1px solid #EEE', paddingTop: 8,
          paddingBottom: 8,
          paddingHorizontal: 35,
          fontFamily: 'TT Norms Pro'
        },
        responsibility: {
            fontWeight: 'bold',
            fontSize: 10,
            fontFamily: 'TT Norms Pro'
        },
        tickBox: {
            fontWeight: 'bold',
            fontSize: 20,
            fontFamily: 'TT Norms Pro'
        },
        letterHeader: {
            textAlign: 'right',
            fontFamily: 'TT Norms Pro'
        },
        letterFooter: {
            textAlign: 'center',
            fontSize: 20,
            fontFamily: 'TT Norms Pro'
        }
      });

    const MyDoc = () => (
        <Document>
            <Page style={styles.body}>
                <Text style={styles.letterHeader}>Date:</Text>
                <Text style={styles.letterHeader}>{Date().slice(0,15)}</Text>
                <Text>{"\n"}</Text>
                <Text style={styles.letterHeader}>Attention:</Text>
                <Text style={styles.letterHeader}>{buildingBREEAMAssessments.projectTeam.find(item => item.role === match.params.stakeholder) ? buildingBREEAMAssessments.projectTeam.find(item => item.role === match.params.stakeholder).name : "[" + match.params.stakeholder + " name not set]"}</Text>
                <Text style={styles.letterHeader}>{buildingBREEAMAssessments.projectTeam.find(item => item.role === match.params.stakeholder) ? buildingBREEAMAssessments.projectTeam.find(item => item.role === match.params.stakeholder).company : "[" + match.params.stakeholder + " company not set]"}</Text>
                <Text>{"\n"}</Text>
                <Text>
                <Text>{"\n"}</Text>
                Reference:
                <Text>{"\n"}</Text>
                {buildingBREEAMAssessments.projectName}
                <Text>{"\n"}</Text>
                BREEAM 2018 New Construction Assessment
                <Text>{"\n"}</Text>
                Design Stage Compliance Letter
                <Text>{"\n"}</Text>
                Dear {buildingBREEAMAssessments.projectTeam.find(item => item.role === match.params.stakeholder) ? buildingBREEAMAssessments.projectTeam.find(item => item.role === match.params.stakeholder).name : "[" + match.params.stakeholder + " name not set]"}, 
                <Text>{"\n\n"}</Text>
                Please review the statements below to ensure all meet with your approval and will be included in the design and carried out during construction. 
                <Text>{"\n\n"}</Text>
                These statements will be used as supporting evidence for the Design Stage of the BREEAM 2018 New Construction assessment. 
                <Text>{"\n\n"}</Text>
                Please amend anything highlighted in yellow, copy and paste the text into company letterhead, before printing, signing by hand, scanning and sending back to us as a PDF attachment.
                <Text>{"\n\n"}</Text>
                ---- Copy text below this line ----
                </Text>
                <Text>{"\n\n"}</Text>

                {buildingBREEAMAssessments.credits.filter(item => item.documentaryEvidence.filter(item => item.complianceLetter !== "" && item.complianceLetter !== "-" && item.responsibility === match.params.stakeholder).length > 0 && item.creditsTargeted > 0).map((credit) => (
                    <view break>
                        <div key={credit.id}>
                            <Text style={styles.section}>{credit.section}</Text>
                            <Text style={styles.categoryCode}>{credit.creditCategoryCode + " - " + credit.creditCategoryDescription}</Text>
                            <Text style={styles.creditDescription}>{credit.creditDescription}</Text>
                            {credit.documentaryEvidence.filter(item => item.complianceLetter !== "" && item.complianceLetter !== "-" && item.evidenceDocumentRequired === "YES" && item.responsibility === match.params.stakeholder).map((evidenceDocument) => (
                                <div key={evidenceDocument.id}>
                                    <Text style={styles.rowView}>{evidenceDocument.complianceLetter}</Text>

                                </div>                        
                            ))}
                        </div>
                    </view>
                ))}
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed />
            </Page>
        </Document>
    );

    if (!loading && !buildingsLoading && !clientsLoading) {

        return (

            <div>

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    

                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden mt-1 m-5">
                        <div>
                            <div className="flex items-center bg-slate-100">
                                <div className="container max-w-6xl px-5 mx-auto my-5">


                                </div>
                            </div>
                        </div>
                    </div>

                    <PDFDownloadLink document={<MyDoc />} fileName="BREEAM_Compliance_Report_12345.pdf">
                        {({ blob, url, loading, error }) =>
                            loading ? 'Loading document...' : 'Download now!'
                        }
                    </PDFDownloadLink>

                {/* <PDFViewer className='w-full'>
                    <Document>
                        <Page style={styles.body}>
                            <Text style={styles.letterHeader}>Date:</Text>
                            <Text style={styles.letterHeader}>{Date().slice(0,15)}</Text>
                            <Text>{"\n"}</Text>
                            <Text style={styles.letterHeader}>Attention:</Text>
                            <Text style={styles.letterHeader}>{buildingBREEAMAssessments.projectTeam.find(item => item.role === match.params.stakeholder) ? buildingBREEAMAssessments.projectTeam.find(item => item.role === match.params.stakeholder).name : "[" + match.params.stakeholder + " name not set]"}</Text>
                            <Text style={styles.letterHeader}>{buildingBREEAMAssessments.projectTeam.find(item => item.role === match.params.stakeholder) ? buildingBREEAMAssessments.projectTeam.find(item => item.role === match.params.stakeholder).company : "[" + match.params.stakeholder + " company not set]"}</Text>
                            <Text>{"\n"}</Text>
                            <Text>
                            <Text>{"\n"}</Text>
                            Reference:
                            <Text>{"\n"}</Text>
                            {buildingBREEAMAssessments.projectName}
                            <Text>{"\n"}</Text>
                            BREEAM 2018 New Construction Assessment
                            <Text>{"\n"}</Text>
                            Design Stage Compliance Letter
                            <Text>{"\n"}</Text>
                            Dear {buildingBREEAMAssessments.projectTeam.find(item => item.role === match.params.stakeholder) ? buildingBREEAMAssessments.projectTeam.find(item => item.role === match.params.stakeholder).name : "[" + match.params.stakeholder + " name not set]"}, 
                            <Text>{"\n\n"}</Text>
                            Please review the statements below to ensure all meet with your approval and will be included in the design and carried out during construction. 
                            <Text>{"\n\n"}</Text>
                            These statements will be used as supporting evidence for the Design Stage of the BREEAM 2018 New Construction assessment. 
                            <Text>{"\n\n"}</Text>
                            Please amend anything highlighted in yellow, copy and paste the text into company letterhead, before printing, signing by hand, scanning and sending back to us as a PDF attachment.
                            <Text>{"\n\n"}</Text>
                            ---- Copy text below this line ----
                            </Text>
                            <Text>{"\n\n"}</Text>

                            {buildingBREEAMAssessments.credits.filter(item => item.documentaryEvidence.filter(item => item.complianceLetter !== "" && item.complianceLetter !== "-" && item.responsibility === match.params.stakeholder).length > 0 && item.creditsTargeted > 0).map((credit) => (
                                <view break>
                                    <div key={credit.id}>
                                        <Text style={styles.section}>{credit.section}</Text>
                                        <Text style={styles.categoryCode}>{credit.creditCategoryCode + " - " + credit.creditCategoryDescription}</Text>
                                        <Text style={styles.creditDescription}>{credit.creditDescription}</Text>
                                        {credit.documentaryEvidence.filter(item => item.complianceLetter !== "" && item.complianceLetter !== "-" && item.evidenceDocumentRequired === "YES" && item.responsibility === match.params.stakeholder).map((evidenceDocument) => (
                                            <div key={evidenceDocument.id}>
                                                <Text style={styles.rowView}>{evidenceDocument.complianceLetter}</Text>

                                            </div>                        
                                        ))}
                                    </div>
                                </view>
                                ))} */}
                            {/* <View render={({ pageNumber }) => (
                                pageNumber / 2 === 0 && (
                                <View style={{ background: 'red' }}>
                                    <Text>I'm only visible in odd pages!</Text>
                                </View>
                                )
                            )} /> */}

                            {/* <Text style={styles.pageNumbers} render={({ pageNumber, totalPages }) => (
                                    `${pageNumber} / ${totalPages}`
                                )} fixed /> */}
                            {/* <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                            `${pageNumber} / ${totalPages}`
                            )} fixed /> */}
                        {/* </Page>
                    </Document>
                </PDFViewer  > */}
            </div>

            </div>
        )

    } else {

        return (
            <div>
                Data Loaing... Please Wait...
            </div>
        )

    }

};

export default ViewBREEAMComplianceLetter;
