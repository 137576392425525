import { useState, useEffect } from 'react'
import axios from 'axios'
import Header from '../UI/Header'
import { useNavigate, useMatch, Navigate } from 'react-router-dom'
import authHeader from '../../services/AuthHeader'
import { v4 as uuidv4 } from 'uuid'


const AddOrUpdateClientProjectService = () => {

    const match = useMatch('/addOrUpdateClientProjectService/:projectId/:serviceId')

    const navigate = useNavigate()

    const [loading, setLoading] = useState(true)

    const [activities, setActivities] = useState([])
    const [projectId, setProjectId] = useState('')
    const [service, setService] = useState('')
    const [serviceManager, setServiceManager] = useState('')
    const [department, setDepartment] = useState('')
    const [workStage, setWorkStage] = useState('')
    const [serviceStatus, setServiceStatus] = useState('')
    const [serviceCreatedDate, setServiceCreatedDate] = useState('')
    const [serviceLiveDate, setServiceLiveDate] = useState('')
    const [serviceClosedDate, setServiceClosedDate] = useState('')
    const [serviceComments, setServiceComments] = useState('')
    const [dealNumber, setDealNumber] = useState('')
    const [dealName, setDealName] = useState('')
    const [customerPo, setCustomerPo] = useState('')
    const [lineItemNumber, setLineItemNumber] = useState('')
    const [changedFlag, setChangedFlag] = useState(false)
    const [serviceInvoiceClient, setServiceInvoiceClient] = useState('')
    const [serviceInvoiceClientContact, setServiceInvoiceClientContact] = useState('')

    const [servicesList, setServicesList] = useState([])
    const [departmentsList, setDepartmentsList] = useState([])
    const [workStagesList, setWorkStagesList] = useState([])
    const [departmentsServicesActivities, setDepartmentsServicesActivities] = useState([])
    const [departmentsServicesActivitiesLoading, setDepartmentsServicesActivitiesLoading] = useState(true)

    const [usersLoading, setUsersLoading] = useState(true)
    const [users, setUsers] = useState([])
    const [clients, setClients] = useState([])
    const [contacts, setContacts] = useState([])
    const [deals, setDeals] = useState([])

    const [redirecting, setRedirecting] = useState(false)

    const API_URL = process.env.REACT_APP_API_URL;

    const aggregateServicesArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.service === val.service);
            if (index === -1) {
                acc.push({
                    service: val.service,
                    department: val.department
                });
            };
            return acc;
        }, []);
    };

    const aggregateDepartmentsArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.department === val.department);
            if (index === -1) {
                acc.push({
                    department: val.department
                });
            };
            return acc;
        }, []);
    };

    const aggregateWorkStagesArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.workStage === val.workStage);
            if (index === -1) {
                acc.push({
                    workStage: val.workStage
                });
            };
            return acc;
        }, []);
    };

    function stringToDate(_date,_format,_delimiter)
    {
        var formatLowerCase=_format.toLowerCase();
        var formatItems=formatLowerCase.split(_delimiter);
        var dateItems=_date.split(_delimiter);
        var monthIndex=formatItems.indexOf("mm");
        var dayIndex=formatItems.indexOf("dd");
        var yearIndex=formatItems.indexOf("yyyy");
        var month=parseInt(dateItems[monthIndex]);
        month-=1;
        var formatedDate = new Date(dateItems[yearIndex],month,dateItems[dayIndex]);
        return formatedDate;
    }

    useEffect(() => {

        axios.get(API_URL + '/users')
            .then((res) => {
                setUsers(res.data.filter(item => item.role !== 3001 && item.role !== 9999 && item.role !== 9998).sort((item1, item2) => item1.firstName + item1.lastName < item2.firstName + item2.lastName ? -1 : 0))
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUsersLoading(false);
            });

        axios.get(API_URL + '/clients')
            .then((res) => {
                setClients(res.data.sort((item1, item2) => item1.clientName + item1.clientName < item2.clientName + item2.clientName ? -1 : 0))
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUsersLoading(false);
            });

            axios.get(API_URL + '/contacts')
            .then((res) => {
                setContacts(res.data.sort((item1, item2) => item1.contactName < item2.contactName ? -1 : 0))
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUsersLoading(false);
            });

        axios.post(process.env.REACT_APP_API_URL + '/hubspotDealsFiltered')
            .then((response) => {
                setDeals(response.data.results)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });

        axios.get(API_URL + '/projects_services/' + match.params.serviceId, { headers: authHeader() })
            //fetch(process.env.REACT_APP_API_URL + '/projects_services/' + match.params.serviceId)
            //.then((res) => res.json())
            .then((response) => {

                setProjectId(response.data.projectId)
                setService(response.data.service)
                setServiceManager(response.data.serviceManager)
                setDepartment(response.data.department)
                setWorkStage(response.data.workStage)
                setServiceStatus(response.data.serviceStatus)
                setServiceCreatedDate(response.data.serviceCreatedDate)
                setServiceLiveDate(response.data.serviceLiveDate)
                setServiceClosedDate(response.data.serviceClosedDate)
                setServiceComments(response.data.serviceComments)
                setActivities(response.data.activities)
                setDealNumber(response.data.dealNumber)
                setDealName(response.data.dealName)
                setCustomerPo(response.data.customerPo)
                setLineItemNumber(response.data.lineItemNumber)
                setChangedFlag(response.data.changedFlag)
                setServiceInvoiceClient(response.data.serviceInvoiceClient)
                setServiceInvoiceClientContact(response.data.serviceInvoiceClientContact)

            },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    console.log(_content);
                    setRedirecting(true)
                })
            .finally(() => {
                setLoading(false);
            });

        axios.get(API_URL + '/departments_services_activities')
            .then((res) => {

                setDepartmentsServicesActivities(res.data)
                setWorkStagesList(aggregateWorkStagesArray(res.data).sort((item1, item2) => item1.workStage < item2.workStage ? -1 : item1.workStage > item2.workStage ? 1 : 0))
                setServicesList(aggregateServicesArray(res.data).sort((item1, item2) => item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))
                setDepartmentsList(aggregateDepartmentsArray(res.data).sort((item1, item2) => item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : 0))

            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setDepartmentsServicesActivitiesLoading(false);
            });


    }, [match.params.serviceId])

    useEffect(() => {

        setServicesList(aggregateServicesArray(departmentsServicesActivities.filter((service) => service.department === department && service.workStage === workStage)).sort((item1, item2) => item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))

        setWorkStagesList(aggregateWorkStagesArray(departmentsServicesActivities.filter((service) => service.department === department)).sort((item1, item2) => item1.workStage < item2.workStage ? -1 : item1.workStage > item2.workStage ? 1 : 0))


    }, [department, workStage, departmentsServicesActivities, serviceManager])


    const updateForm = async (form) => {
        await axios.put(process.env.REACT_APP_API_URL + '/projects_services/' + match.params.serviceId, form)
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        //If there's no contact and the client cbo isn't touched (but has a value) then set the contact to the first record in the list (should be the same as the first record on the screen)
        var varContact
        if (serviceInvoiceClient!=="" && serviceInvoiceClientContact===""){
            varContact = contacts.find(item => item.clientId === serviceInvoiceClient).id
        }else{
            varContact=serviceInvoiceClientContact
        }

        var newProjectsServices

            await axios.get(API_URL + '/projects_services/' + match.params.serviceId, { headers: authHeader() })
            .then((response) => {
                newProjectsServices = response.data
            })
            .catch((err) => {
                console.log(err);
            })

        newProjectsServices.department = department
        newProjectsServices.workStage = workStage
        newProjectsServices.service = service
        newProjectsServices.serviceManager = serviceManager
        newProjectsServices.serviceInvoiceClient = serviceInvoiceClient
        newProjectsServices.serviceInvoiceClientContact = varContact
        newProjectsServices.customerPo = customerPo
        newProjectsServices.dealNumber = dealNumber
        newProjectsServices.dealName = dealName
        newProjectsServices.changedFlag = true

        //updateForm({ projectId, workStage, service, serviceManager, department, activities, serviceStatus, serviceComments, toBeDeleted: "NO", serviceCreatedDate, serviceLiveDate, serviceClosedDate, dealNumber, dealName, lineItemNumber, customerPo, changedFlag: true, serviceInvoiceClient, serviceInvoiceClientContact:varContact}).then(res => { navigate(-1) })
    
        await axios.get(process.env.REACT_APP_API_URL+'/invoices_by_project/'+ match.params.projectId)
        .then((res) => {
            var invoice = res.data.filter((invoice) => stringToDate(invoice.invoiceDate,"YYYY-MM-DD","-") > Date.now() && invoice.invoiceIssued==="NO").sort((item1, item2) => item1.invoiceDate < item2.invoiceDate ? -1 : item1.invoiceDate > item2.invoiceDate ? 1 : 0)

            invoice.forEach(invoice => {
                var invoiceLines = invoice.invoiceLines
                var recsToUpdate = 0
                invoiceLines.forEach(invoiceLine => {
                    if (invoiceLine.serviceId===match.params.serviceId){
                        recsToUpdate++
                        }
                    });

                if (recsToUpdate>0){
                    axios.put(process.env.REACT_APP_API_URL + '/invoices/' + invoice.id, { ...invoice, clientId: serviceInvoiceClient, invoiceContactId: varContact})
                }
                
            });
        })
        .catch((err) => {
            console.log(err);
        })

        updateForm(newProjectsServices).then(res => { navigate(-1) })
    }

    if (redirecting) {

        return (
            <Navigate replace to="/login" />
        )
    }

    else if (!loading && !usersLoading) {

        return (

            <div className='flex items-center bg-jungle-mist-100 min-h-screen'>
                <div className="container-form-8A">

                    <Header title="Projects - Add / Edit Service" section="service Main Details" />

                    <form className="add-form-8A" onSubmit={onSubmit}>

                        <div className="form-control-8A">
                            <label>Department</label>
                            <select value={department} onFocus={(e) => setDepartment(e.target.value)} onChange={(e) => setDepartment(e.target.value)}>
                                {departmentsList.map((department) => (
                                    <option key={department.department} value={department.department}>{department.department}</option>
                                ))}
                            </select>
                        </div>

                        {department && (
                            <div className="form-control-8A">
                                <label>Work Stage</label>
                                <select value={workStage} onFocus={(e) => setWorkStage(e.target.value)} onChange={(e) => setWorkStage(e.target.value)}>
                                    {workStagesList.map((workStage) => (
                                        <option key={workStage.workStage} value={workStage.workStage}>{workStage.workStage}</option>
                                    ))}
                                </select>
                            </div>
                        )
                        }

                        {workStage && (
                            <div className="form-control-8A">
                                <label>Service</label>
                                <select value={service} onFocus={(e) => setService(e.target.value)} onChange={(e) => setService(e.target.value)}>
                                    {servicesList.map((service) => (
                                        <option key={service.service} value={service.service}>{service.service}</option>
                                    ))}
                                </select>
                            </div>
                        )
                        }
                        {service && (
                            <div className="form-control-8A">
                                <label>Service Manager</label>
                                <select value={serviceManager} onChange={(e) => setServiceManager(e.target.value)}>
                                    {users.map((user) => (
                                        <option key={user.id} value={user.id}>{user.firstName + ' ' + user.lastName}</option>
                                    ))}
                                </select>
                            </div>
                        )
                        }
                        <div className="form-control-8A">
                            <label>Client to Invoice</label>
                            <select value={serviceInvoiceClient} onChange={(e) => {setServiceInvoiceClient(e.target.value); setServiceInvoiceClientContact(contacts.filter(item => item.clientId === e.target.value)[0].id) }} onFocus={(e) => {setServiceInvoiceClient(e.target.value); setServiceInvoiceClientContact(contacts.filter(item => item.clientId === e.target.value)[0].id) }}>
                                <option value="">Please Select...</option>
                                    {clients.map((client) => (
                                        <option key={client.id} value={client.id}>{client.clientName}</option>
                                    ))}
                            </select>
                        </div>
                        <div className="form-control-8A">
                            <label>Contact</label>
                            <select value={serviceInvoiceClientContact} onChange={(e) => setServiceInvoiceClientContact(e.target.value)}>
                                {contacts.filter(item => item.clientId === serviceInvoiceClient).map((contact) => (
                                    <option key={contact.id} value={contact.id}>{contact.contactName}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-control-8A">
                            <label>Client PO Number</label>
                            <input type="text" placeholder="Enter Cust. PO Number Here" value={customerPo} onChange={(e) => setCustomerPo(e.target.value)} />
                        </div>
                        <div className="form-control-8A">
                            <label>HubSpot Deal Number</label>
                            <select value={dealNumber} onChange={(e) => {setDealNumber(e.target.value); setDealName(deals.find(item => item.id === e.target.value).properties.dealname)}}>
                                <option value="">Please Select...</option>
                                {deals.map((deal) => (                                    
                                    <option key={deal.id} value={deal.id}> {deal.id + ' - ' + deal.properties.dealname}</option>
                                ))}
                            </select>
                        </div>
                        {/* <div className="form-control-8A">
                            <label>HubSpot Line Item Number</label>
                            <input type="text" placeholder="Enter Line Item Number Here" value={lineItemNumber} onChange={(e) => setLineItemNumber(e.target.value)} />
                        </div> */}

                        <input type="submit" value="Save" className="btn-8A btn-block-8A" />

                    </form>
                    <button onClick={() => navigate(-1)} className="btn-8A w-full">Cancel</button>

                </div>
            </div>
        )

    } else {

        return (
            <div>
                <Header title="Projects - Add / Edit Service" section="Service Main Details" />
                <p>Data is loading...</p>
            </div>)

    }

}

export default AddOrUpdateClientProjectService