import Header from '../UI/Header'
import { useState, useEffect, useRef } from 'react'
import { useNavigate, useMatch } from 'react-router-dom'
import Sidebar from '../UI/Sidebar'
import axios from 'axios'
import Button from '../UI/Button'
import { v4 as uuidv4 } from 'uuid'
import Modal from '../UI/Modal'
import ReactSelect from 'react-select'
import Options from '../UI/Options'
import { ExportToCsv } from 'export-to-csv'

const ViewEventFootprintItemHelperTool = (props) => {

    const descriptionInput = useRef(null);

    const match = useMatch('/viewEventFootprintItemHelperTool/:eventId/:footprintId/:footprintItemId')

    const [sidebarToggle, setSidebarToggle] = useState(false)

    const [loading, setLoading] = useState(true)

    const [footprint, setFootprint] = useState({})

    const [showModal, setShowModal] = useState(false)

    const [editToggle, setEditToggle] = useState(false)

    const [carbonEmissionFactors, setCarbonEmissionFactors] = useState([])
    const [carbonFactorsLoading, setCarbonFactorsLoading] = useState(false)

    const [fromPostcodeCoordinates, setFromPostcodeCoordinates] = useState('')
    const [toPostcodeCoordinates, setToPostcodeCoordinates] = useState('')

    const instance = axios.create({});

    const [selectedTransportModeToAdd, setSelectedTransportModeToAdd] = useState({})

    const [transportModeOptions, setTransportModeOptions] = useState([])

    const [descriptionToAdd, setDescriptionToAdd] = useState('')
    const [supplierToAdd, setSupplierToAdd] = useState('')
    const [facilityToAdd, setFacilityToAdd] = useState('')
    const [individualToAdd, setIndividualToAdd] = useState('')
    const [fromPostCodeToAdd, setFromPostCodeToAdd] = useState('')
    const [toPostCodeToAdd, setToPostCodeToAdd] = useState('')
    const [consumptionToAdd, setConsumptionToAdd] = useState(0)
    const [unitToAdd, setUnitToAdd] = useState('')
    const [emissionFactorToAdd, setEmissionFactorToAdd] = useState(0)
    const [carbonEmissionsToAdd, setCarbonEmissionsToAdd] = useState(0)
    const [commentsToAdd, setCommentsToAdd] = useState('')
    const [multiplierToAdd, setMultiplierToAdd] = useState(1)
    const [emissionFactorSourceToAdd, setEmissionFactorSourceToAdd] = useState('')
    const [subItemIdToAdd, setSubItemIdToAdd] = useState('')

    const internationalNumberFormat = new Intl.NumberFormat('en-US')

    const removeCommas = (num) => num.toString().replace(/,/g, "");

    const handleChangeForConsumption = (e) => {
        const value = e.target.value;
        const clean = value.replace(/,/g, "");
        const regex = /^[0-9]*\.?[0-9]*$/;

        if (value && clean.match(regex)) {
            if (!value.includes(".")) {
                const formatted = new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 10,
                }).format(parseFloat(clean));
                setConsumptionToAdd(formatted);
            } else {
                setConsumptionToAdd(value);
            }
        } else {
            setConsumptionToAdd(value.replace(/[^0-9,.]/g, ''))
        }
    };

    const setFormattedConsumption = (e) => {
        const value = e.toString();
        const clean = value.replace(/,/g, "");
        const regex = /^[0-9]*\.?[0-9]*$/;

        if (value && clean.match(regex)) {

            const formatted = new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 10,
            }).format(parseFloat(clean));
            setConsumptionToAdd(formatted);

        } else {
            setConsumptionToAdd("");
        }

    };

    const handleChangeForEmissions = (e) => {
        const value = e.target.value;
        const clean = value.replace(/,/g, "");
        const regex = /^[0-9]*\.?[0-9]*$/;

        if (value && clean.match(regex)) {
            if (!value.includes(".")) {
                const formatted = new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 10,
                }).format(parseFloat(clean));
                setCarbonEmissionsToAdd(formatted);
            } else {
                setCarbonEmissionsToAdd(value);
            }
        } else {
            setCarbonEmissionsToAdd(value.replace(/[^0-9,.]/g, ''))
        }
    };

    const setFormattedEmissions = (e) => {
        const value = e.toString();
        const clean = value.replace(/,/g, "");
        const regex = /^[0-9]*\.?[0-9]*$/;

        if (value && clean.match(regex)) {

            const formatted = new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 10,
            }).format(parseFloat(clean));
            setCarbonEmissionsToAdd(formatted);

        } else {
            setCarbonEmissionsToAdd("");
        }

    };

    const arrayToCsv = (data) => {


        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: 'Sub Items Export from Helper Tool',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };

        var dataWithHeadersOrdered = []
        var subItemIndex

        for (subItemIndex = 0; subItemIndex < data.length; subItemIndex++) {

            dataWithHeadersOrdered.push({footprintSubItemId: data[subItemIndex].footprintSubItemId, description: data[subItemIndex].description, supplier: data[subItemIndex].supplier, facility: data[subItemIndex].facility, individual: data[subItemIndex].individual, fromPostCode: data[subItemIndex].fromPostCode, toPostCode: data[subItemIndex].toPostCode, transportMode: data[subItemIndex].transportMode, consumption: data[subItemIndex].consumption, multiplier: data[subItemIndex].multiplier, unit: data[subItemIndex].unit, emissionFactor: data[subItemIndex].emissionFactor, carbonEmissions: data[subItemIndex].carbonEmissions, comments: data[subItemIndex].comments, emissionFactorSource: data[subItemIndex].emissionFactorSource})

        }

        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(dataWithHeadersOrdered)

    }

    //set styles for ReactSelect
    const styles = {
        option: provided => ({
            ...provided,
            color: 'black'
        }),
        control: provided => ({
            ...provided,
            color: 'black',
            fontSize: 17,
            minWidth: "100%",
            borderRadius: "0%",
            height: "41px"
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'black',
            background: "#bfe3f1"
        }),
        menu: provided => ({
            ...provided,
            color: 'black',
            width: "500px"
        }),
    }

    const stylesWhenEditing = {
        option: provided => ({
            ...provided,
            color: 'black',
            background: "#bfe3f1"
        }),
        control: provided => ({
            ...provided,
            color: 'black',
            fontSize: 17,
            minWidth: "100%",
            borderRadius: "0%",
            height: "41px",
            background: "#bfe3f1"
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'black',
            background: "#bfe3f1"
        }),
        menu: provided => ({
            ...provided,
            color: 'black',
            width: "500px",
            background: "#bfe3f1"
        }),
    }

    const navigate = useNavigate()

    const [file, setFile] = useState()
    const fileReader = new FileReader()

    const handleOnChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleOnSubmit = (e) => {
        e.preventDefault();

        if (file) {
            fileReader.onload = function (event) {
                const text = event.target.result;
                csvFileToArray(text);
            };

            fileReader.readAsText(file);
        }
    };

    const csvFileToArray = string => {

        if (footprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).submitted !== "" || footprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).verified !== "") {

            window.alert("This footprint item has been submitted and/or verified.  Please ensure the item is not marked as submitted or verified")

        } else {

            //below line of code coudl be useful if we want to dynamically handle different column names (keys)
            //const csvHeader = string.slice(0, string.indexOf("\n")).split(",");

            const csvHeader = ["footprintSubItemId", "description", "supplier", "facility", "individual", "fromPostCode", "toPostCode", "transportMode", "consumption", "multiplier", "unit", "emissionFactor", "carbonEmissions", "comments", "emissionFactorSource"]
            const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

            //drop the last line (blank)
            csvRows.pop()

            const array = csvRows.map(i => {

                //const values = i.split(",")

                const values = i.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/)

                var field

                for (field = 0; field < values.length; field++) {

                    values[field] = values[field].replace(/^"|"$/g, '')
                    values[field] = values[field].replace(/""/g, '"')
                    values[field] = values[field].replace(/^"|"$/, '')

                    //If the last field has any commas then a traling quotation mark (") still remains before the end of the line
                    //This code checks for this and removes the quotation mark (")

                    if (values[field].slice(values[field].length - 2, values[field].length - 1) === '"') {
                        values[field] = values[field].slice(0, values[field].length - 2)
                    }
                }

                const obj = csvHeader.reduce((object, header, index) => {
                    object[header] = values[index];
                    return object;
                }, {});
                return obj;

            });

            //code here to generate uniqueIds for each line
            var subItemIndex

            for (subItemIndex = 0; subItemIndex < array.length; subItemIndex++) {
                array[subItemIndex].footprintSubItemId = uuidv4()
                array[subItemIndex].consumption = Number(array[subItemIndex].consumption)
                array[subItemIndex].emissionFactor = Number(array[subItemIndex].emissionFactor)
                array[subItemIndex].carbonEmissions = Number(array[subItemIndex].carbonEmissions)
            }


            var newFootprintSubItems, newFootprint

            newFootprint = { ...footprint }

            newFootprintSubItems = footprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems.concat(array)

            newFootprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems = newFootprintSubItems

            setFootprint(newFootprint)

        }

    };

    //below line of code coudl be useful if we want to dynamically handle different column names (keys)
    // const headerKeys = Object.keys(Object.assign({}, ...array));

    const viewCarbonFactors = () => {
        // console.log(carbonEmissionFactors)
        setShowModal(true)
    }

    const setSelectedFactor = (e, datasource) => {
        setEmissionFactorToAdd(e)
        setEmissionFactorSourceToAdd(datasource)
    }

    const editFootprintSubItem = (e) => {

        setEditToggle(true)
        setDescriptionToAdd(e.description)
        setSupplierToAdd(e.supplier)
        setFacilityToAdd(e.facility)
        setIndividualToAdd(e.individual)
        setFromPostCodeToAdd(e.fromPostCode)
        setToPostCodeToAdd(e.toPostCode)
        setFormattedConsumption(e.consumption)
        setMultiplierToAdd(e.multiplier)
        setUnitToAdd(e.unit)
        setSelectedTransportModeToAdd({value: e.transportMode, label: e.transportMode} )
        setEmissionFactorToAdd(e.emissionFactor)
        setFormattedEmissions(e.carbonEmissions)
        setCommentsToAdd(e.comments)
        setEmissionFactorSourceToAdd(e.emissionFactorSource)
        setSubItemIdToAdd(e.footprintSubItemId)
        descriptionInput.current.focus()

    }

    const cancelAddOrEditSubItem = (e) => {

        setDescriptionToAdd("")
        setSupplierToAdd("")
        setFacilityToAdd("")
        setIndividualToAdd("")
        setFromPostCodeToAdd("")
        setToPostCodeToAdd("")
        setFormattedConsumption(0)
        setMultiplierToAdd(0)
        setUnitToAdd("")
        setSelectedTransportModeToAdd({})
        setEmissionFactorToAdd(0)
        setFormattedEmissions(0)
        setCommentsToAdd("")
        setEmissionFactorSourceToAdd("")
        setEditToggle(false)
    }

    const calculateAllDistances = async () => {

        //loop through all records in footprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems

        console.log("Entered Function")

        var fromPostcodeCoordinatesForSubItem
        var toPostcodeCoordinatesForSubItem
        var subItemIndex
        var newFootprint

        newFootprint = { ...footprint }

        for (subItemIndex = 0; subItemIndex < newFootprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems.length; subItemIndex++) {

            console.log("Entered loop")

            // console.log(newFootprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems[subItemIndex].fromPostCode)

            await instance.get('https://nominatim.openstreetmap.org/search.php?q=' + newFootprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems[subItemIndex].fromPostCode + '&format=jsonv2')
                .then((res) => {
                    if (res.data[0]) {
                        fromPostcodeCoordinatesForSubItem = res.data[0].lon + ',' + res.data[0].lat
                    }
                })

            await instance.get('https://nominatim.openstreetmap.org/search.php?q=' + newFootprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems[subItemIndex].toPostCode + '&format=jsonv2')
                .then((res) => {
                    if (res.data[0]) {
                        toPostcodeCoordinatesForSubItem = res.data[0].lon + ',' + res.data[0].lat
                    }
                })

            await instance.get(`https://router.project-osrm.org/route/v1/driving/${fromPostcodeCoordinatesForSubItem};${toPostcodeCoordinatesForSubItem}?overview=false`)
                .then((res) => {
                    newFootprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems[subItemIndex] = { ...newFootprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems[subItemIndex], consumption: res.data.routes[0].distance / 1000, unit: "km" }
                })

        }

        console.log("exited loop")

        setFootprint(newFootprint)

    }

    const calculateAllEmissionFactors = async () => {

        //loop through all records in footprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems

        console.log("Entered Function")

        var emissionFactor
        var carbonEmissions
        var modeOfTransport
        var unit
        var subItemIndex
        var newFootprint
        var multiplier
        var consumption

        newFootprint = { ...footprint }

        for (subItemIndex = 0; subItemIndex < newFootprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems.length; subItemIndex++) {

            console.log(newFootprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems.length)

            modeOfTransport = newFootprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems[subItemIndex].transportMode
            unit = newFootprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems[subItemIndex].unit
            multiplier = newFootprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems[subItemIndex].multiplier
            consumption = newFootprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems[subItemIndex].consumption

            emissionFactor = carbonEmissionFactors.find(item => item.Level2 + " " + item.Lookup + " " + item.ColumnText === modeOfTransport && item.UOMsimple === unit).GHGConversion
            carbonEmissions = emissionFactor * multiplier * consumption


            newFootprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems[subItemIndex].emissionFactor = emissionFactor
            newFootprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems[subItemIndex].carbonEmissions = carbonEmissions

        }

        console.log("exited loop")

        setFootprint(newFootprint)

    }

    const calculateAllEmissions = async () => {

        //loop through all records in footprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems

        console.log("Entered Function")

        var emissionFactor
        var carbonEmissions
        var subItemIndex
        var newFootprint
        var multiplier
        var consumption

        newFootprint = { ...footprint }

        for (subItemIndex = 0; subItemIndex < newFootprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems.length; subItemIndex++) {

            multiplier = newFootprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems[subItemIndex].multiplier
            consumption = newFootprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems[subItemIndex].emissionFactor
            emissionFactor = newFootprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems[subItemIndex].consumption

            carbonEmissions = emissionFactor * multiplier * consumption

            newFootprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems[subItemIndex].carbonEmissions = carbonEmissions

        }

        console.log("exited loop")

        setFootprint(newFootprint)

    }

    const onSubmit = () => {

        if (!isNaN(footprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0))) {

            //Code here to update footprintItem.emissions to be the total emissions of all subItems
            if (footprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems.length > 0) {

                //Code here to update footprintItem.emissions to be the total emissions of all subItems
                footprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).carbonEmissions = footprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)
                footprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).unit = "various"
                footprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionFactor = 1
                footprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).consumption = footprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)

                var oneMonthsActual = 0
                var numberOfMonths = footprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).numberOfMonths

                if (numberOfMonths < 12) {

                    oneMonthsActual = footprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).carbonEmissions / numberOfMonths
                    footprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).additionalEstimatedEmissions = (oneMonthsActual * (12 - numberOfMonths))

                } else {
                    footprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).additionalEstimatedEmissions = 0
                }

                updateForm(footprint).then(res => { navigate(-1) })

            } else {

                updateForm(footprint).then(res => { navigate(-1) })
            }

        } else {

            window.alert("Unable to import data.  Please review imported data for invalid data items - Tip: Search the data shown below for NaN data items and remove any carriage returns within data fields and address any non-numeric formatting in the source CSV.")

        }
    }

    const updateForm = async (form) => {

        await axios.put(process.env.REACT_APP_API_URL + '/events_footprints/' + match.params.footprintId, form)
    }

    const addFootprintSubItem = () => {

        if (footprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).submitted !== "" || footprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).verified !== "") {

            window.alert("This footprint item has been submitted and/or verified.  Please ensure the item is not marked as submitted or verified")

        } else {

            if(descriptionToAdd !== "") {

                var uniqueId = uuidv4()

                var newFootprint = { ...footprint }

                newFootprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems.push({ footprintSubItemId: uniqueId, description: descriptionToAdd, supplier: supplierToAdd, facility: facilityToAdd, individual: individualToAdd, fromPostCode: fromPostCodeToAdd, toPostCode: toPostCodeToAdd, transportMode: selectedTransportModeToAdd.value, consumption: Number(removeCommas(consumptionToAdd)), multiplier: multiplierToAdd, unit: unitToAdd, emissionFactor: emissionFactorToAdd, carbonEmissions: Number(removeCommas(carbonEmissionsToAdd)), comments: commentsToAdd, emissionFactorSource: emissionFactorSourceToAdd })

                setFootprint(newFootprint)

            }
        }

    }

    const updateFootprintSubItem = (e) => {

        if (footprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).submitted !== "" || footprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).verified !== "") {

            window.alert("This footprint item has been submitted and/or verified.  Please ensure the item is not marked as submitted or verified")

        } else {

            var index

            var newFootprint = { ...footprint }

            // console.log(newFootprint)

            // console.log(newFootprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems.find(item => item.footprintSubItemId === subItemIdToAdd))

            index = newFootprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems.indexOf(newFootprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems.find(item => item.footprintSubItemId === subItemIdToAdd))

            newFootprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems[index] = { footprintSubItemId: subItemIdToAdd, description: descriptionToAdd, supplier: supplierToAdd, facility: facilityToAdd, individual: individualToAdd, fromPostCode: fromPostCodeToAdd, toPostCode: toPostCodeToAdd, transportMode: selectedTransportModeToAdd.value, consumption: Number(removeCommas(consumptionToAdd)), multiplier: multiplierToAdd, unit: unitToAdd, emissionFactor: emissionFactorToAdd, carbonEmissions: Number(removeCommas(carbonEmissionsToAdd)), comments: commentsToAdd, emissionFactorSource: emissionFactorSourceToAdd }

            setFootprint(newFootprint)

            setEditToggle(false)

            setDescriptionToAdd("")
            setSupplierToAdd("")
            setFacilityToAdd("")
            setIndividualToAdd("")
            setFromPostCodeToAdd("")
            setToPostCodeToAdd("")
            setFormattedConsumption(0)
            setMultiplierToAdd(0)
            setUnitToAdd("")
            setSelectedTransportModeToAdd({})
            setEmissionFactorToAdd(0)
            setFormattedEmissions(0)
            setCommentsToAdd("")
            setEmissionFactorSourceToAdd("")

        }

    }

    const deleteFootprintSubItem = (id) => {

        if (window.confirm('Are you sure you want to delete this sub item?')) {

            if (footprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).submitted !== "" || footprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).verified !== "") {

                window.alert("This footprint item has been submitted and/or verified.  Please ensure the item is not marked as submitted or verified")

            } else {

                var newFootprint = { ...footprint }

                const index = newFootprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems.findIndex(item => item.footprintSubItemId === id)

                // console.log(newFootprint)

                // console.log(id)

                // console.log(newFootprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems.find(item => item.footprintSubItemId === id))

                newFootprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems.splice(index, 1);

                setFootprint(newFootprint)

            }

        }

    }

    const deleteAllFootprintSubItems = () => {

        if (window.confirm('Are you sure you want to delete all sub items?')) {

            if (footprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).submitted !== "" || footprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).verified !== "") {

                window.alert("This footprint item has been submitted and/or verified.  Please ensure the item is not marked as submitted or verified")

            } else {

                var newFootprint = { ...footprint }

                newFootprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems = [];

                setFootprint(newFootprint)

            }

        }

    }

    ///////////////////////////////////////////////////////



    ///////////////////////////////////////////////////////

    const [company, setCompany] = useState("")
    const [role, setRole] = useState(0)
    const [userPropertiesLoading, setUserPropertiesLoading] = useState(true)

    const getUserProperties = async () => {

        await axios.get(process.env.REACT_APP_API_URL + "/current_user_properties")
            .then((response) => {
                setCompany(response.data.company)
                setRole(response.data.role)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUserPropertiesLoading(false);
            });

    }

    useEffect(() => {

        setUserPropertiesLoading(true)

        getUserProperties()

    }, [])

    useEffect(() => {

        if (company && role && !userPropertiesLoading) {

            axios.get(process.env.REACT_APP_API_URL + '/events/' + match.params.eventId)
                .then((res) => {
                    if ((role === 3001 || role === 6001) && res.data.clientId !== company) {
                        navigate(`/errorPage`)
                    }
                })
                .catch((err) => {
                    console.log(err);
                })

            axios.get(process.env.REACT_APP_API_URL + '/events_footprints/' + match.params.footprintId)
                .then((res) => {
                    if (res.data.eventId !== match.params.eventId) {
                        navigate(`/errorPage`)
                    }
                    setFootprint(res.data)
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });

            axios.get(process.env.REACT_APP_API_URL + '/carbon_factors')
                .then((res) => {
                    setCarbonEmissionFactors(res.data)
                    setTransportModeOptions(res.data.filter(item => item.Level1.slice(0, 15) === "Business travel"))
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setCarbonFactorsLoading(false);
                });

        }

    }, [match.params.footprintItemId, match.params.footprintId, company, role, userPropertiesLoading, match.params.eventId])

    useEffect(() => {

        //Code here to call get carbon factor

        setTransportModeOptions(carbonEmissionFactors.filter(item => item.Level1.slice(0, 15) === "Business travel" && item.UOMsimple === unitToAdd))

    }, [unitToAdd, carbonEmissionFactors, company, role, userPropertiesLoading])

    useEffect(() => {

        if (fromPostCodeToAdd !== "" && fromPostCodeToAdd.length > 4) {

            instance.get('https://nominatim.openstreetmap.org/search.php?q=' + fromPostCodeToAdd + '&format=jsonv2')
                .then((res) => {
                    if (res.data[0]) {
                        setFromPostcodeCoordinates(res.data[0].lon + ',' + res.data[0].lat)
                    }
                })

        }

    }, [fromPostCodeToAdd, company, role, userPropertiesLoading])

    useEffect(() => {

        if (toPostCodeToAdd !== "" && toPostCodeToAdd.length > 4 && fromPostcodeCoordinates) {

            instance.get('https://nominatim.openstreetmap.org/search.php?q=' + toPostCodeToAdd + '&format=jsonv2')
                .then((res) => {
                    if (res.data[0]) {
                        setToPostcodeCoordinates(res.data[0].lon + ',' + res.data[0].lat)
                    }

                    instance.get(`https://router.project-osrm.org/route/v1/driving/${fromPostcodeCoordinates};${res.data[0].lon + ',' + res.data[0].lat}?overview=false`)
                        .then((res) => {
                            // console.log(res.data.routes[0].distance / 1000)
                            setFormattedConsumption(res.data.routes[0].distance / 1000)
                            setUnitToAdd("km")
                        })
                })

        }

    }, [toPostCodeToAdd, company, role, userPropertiesLoading, fromPostcodeCoordinates, fromPostCodeToAdd])

    useEffect(() => {

        if (company && role && !userPropertiesLoading) {

            if (selectedTransportModeToAdd.value) {

                setEmissionFactorToAdd(carbonEmissionFactors.find(item => item.Level2 + " " + item.Lookup + " " + item.ColumnText === selectedTransportModeToAdd.value).GHGConversion)
                setFormattedEmissions(carbonEmissionFactors.find(item => item.Level2 + " " + item.Lookup + " " + item.ColumnText === selectedTransportModeToAdd.value).GHGConversion * removeCommas(consumptionToAdd) * multiplierToAdd)
            } else {

                setFormattedEmissions(emissionFactorToAdd * removeCommas(consumptionToAdd) * multiplierToAdd)

            }

        }

    }, [selectedTransportModeToAdd, company, role, userPropertiesLoading, carbonEmissionFactors, consumptionToAdd, multiplierToAdd, emissionFactorToAdd])


    if (carbonEmissionFactors.length > 0 && !carbonFactorsLoading && !loading) {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Event Footprint Helper Tool" section="Event Footprint Item" showUserMenu='true' url={role === 3001 || role === 6001 ? "/ViewClient/" + company : "/"}/>
                        <br></br>

                        <div>
                            <div className="flex items-center">
                                <div className="w-full mx-auto mb-5 px-3">
                                    <div className="grid gap-2 sm:gap-7 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">

                                        <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                            <div>
                                                <div className="text-sm text-gray-500 ">Footprint Item Description: </div>
                                                <div className="flex items-center pt-1">
                                                    <div className="text-xl font-medium text-pacific-blue "><p className="my-1">{footprint.eventFootprintItems.find(item => item.id === match.params.footprintItemId).itemDescription}</p></div>
                                                </div>
                                            </div>
                                            <div className="text-gray-400">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                                                </svg>
                                            </div>
                                        </div>

                                        <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                            <div>
                                                <div className="text-sm text-gray-500 ">Footprint Item Consumption: </div>
                                                <div className="flex items-center pt-1">
                                                    <div className="text-xl font-medium text-pacific-blue "><p className="my-1">{internationalNumberFormat.format(footprint.eventFootprintItems.find(item => item.id === match.params.footprintItemId).consumption) + " " + footprint.eventFootprintItems.find(item => item.id === match.params.footprintItemId).unit}</p></div>
                                                </div>
                                            </div>
                                            <div className="text-gray-400">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 10.5h.375c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125H21M3.75 18h15A2.25 2.25 0 0021 15.75v-6a2.25 2.25 0 00-2.25-2.25h-15A2.25 2.25 0 001.5 9.75v6A2.25 2.25 0 003.75 18z" />
                                                </svg>

                                            </div>
                                        </div>

                                        <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                            <div>
                                                <div className="text-sm text-gray-500 ">Footprint Item Scope: </div>
                                                <div className="flex items-center pt-1">
                                                    <div className="text-xl font-medium text-pacific-blue "><p className="my-1">{footprint.eventFootprintItems.find(item => item.id === match.params.footprintItemId).itemCategory}</p></div>
                                                </div>
                                            </div>
                                            <div className="text-gray-400">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 01-1.125-1.125v-3.75zM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-8.25zM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-2.25z" />
                                                </svg>

                                            </div>
                                        </div>

                                        <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                            <div>
                                                <div className="text-sm text-gray-500 ">Footprint Item Sub Categoy: </div>
                                                <div className="flex items-center pt-1">
                                                    <div className="text-xl font-medium text-pacific-blue "><p className="my-1">{footprint.eventFootprintItems.find(item => item.id === match.params.footprintItemId).itemSubCategory}</p></div>
                                                </div>
                                            </div>
                                            <div className="text-gray-400">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 01-1.125-1.125v-3.75zM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-8.25zM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-2.25z" />
                                                </svg>
                                            </div>
                                        </div>

                                        <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                            <div>
                                                <div className="text-sm text-gray-500 ">Footprint Item Carbon Emissions (kgCO<sub>2</sub>e): </div>
                                                <div className="flex items-center pt-1">
                                                    <div className="text-xl font-medium text-pacific-blue "><p className="my-1">{internationalNumberFormat.format(footprint.eventFootprintItems.find(item => item.id === match.params.footprintItemId).carbonEmissions)}</p></div>
                                                </div>
                                            </div>
                                            <div className="text-gray-400">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z" />
                                                </svg>


                                            </div>
                                        </div>

                                        <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                            <div>
                                                <div className="text-sm text-gray-500 ">Actions: </div>
                                                {role !== 3001 && role !== 5001 && role !== 6002 && role !== 2003 &&
                                                <div className="flex items-center pt-1">
                                                    <form>

                                                        <input id="csvFileInput" accept={".csv"} type={"file"} onChange={handleOnChange} />
                                                        <label className="p-2" htmlFor="csvFileInput">Choose a File</label>
                                                        <Button color="bondi-blue" text="Import Data" onClick={(e) => { handleOnSubmit(e) }} />
                                                    </form>
                                                        <div>
                                                            <Button color="bondi-blue" text="AI Emissions Factors" />
                                                            <Button color="bondi-blue" text="Delete All Items" onClick={(e) => { deleteAllFootprintSubItems() }} />
                                                        </div>
                                            
                                                </div>
                                                }
    
                                            </div>
                                            <div className="text-gray-400">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12a7.5 7.5 0 0015 0m-15 0a7.5 7.5 0 1115 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077l1.41-.513m14.095-5.13l1.41-.513M5.106 17.785l1.15-.964m11.49-9.642l1.149-.964M7.501 19.795l.75-1.3m7.5-12.99l.75-1.3m-6.063 16.658l.26-1.477m2.605-14.772l.26-1.477m0 17.726l-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205L12 12m6.894 5.785l-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864l-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495" />
                                                </svg>


                                            </div>
                                        </div>

                                        {/* {sidebarToggle &&
                                            <button className="absolute top-2 left-1" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                            </button>
                                        } */}

                                    </div>

                                </div>
                            </div>
                        </div>
                        {role !== 3001 && role !== 5001 && role !== 6002 && role !== 2003 &&
                        <div>
                        {!editToggle &&
                        <h1 className='ml-5 header-8A mt-10'>Add new footprint item</h1>
                        }
                        {editToggle &&
                        <h1 className='ml-5 header-8A mt-10'>Edit footprint item</h1>
                        }

                        <div className="flex items-center">
                            <div className="w-full mx-auto mb-5 px-3">
                                <div className="grid grid-cols-2 gap-2 grid-col-1 sm:grid-cols-3 lg:grid-cols-7">
                                    <div className="form-control-8A">
                                        <label className="text-center">Description</label>
                                        <input className={editToggle ? "bg-pacific-blue-50" : ""} ref={descriptionInput} type="text" value={descriptionToAdd} onChange={(e) => setDescriptionToAdd(e.target.value)} />
                                    </div>
                                    <div className="form-control-8A">
                                        <label className="text-center">Supplier</label>
                                        <input className={editToggle ? "bg-pacific-blue-50" : ""} type="text" value={supplierToAdd} onChange={(e) => setSupplierToAdd(e.target.value)} />
                                    </div>
                                    <div className="form-control-8A">
                                        <label className="text-center">Facility</label>
                                        <input className={editToggle ? "bg-pacific-blue-50" : ""} type="text" value={facilityToAdd} onChange={(e) => setFacilityToAdd(e.target.value)} />
                                    </div>
                                    <div className="form-control-8A">
                                        <label className="text-center">Individual(s)</label>
                                        <input className={editToggle ? "bg-pacific-blue-50" : ""} type="text" value={individualToAdd} onChange={(e) => setIndividualToAdd(e.target.value)} />
                                    </div>
                                    <div className="form-control-8A">
                                        <label className="text-center">From</label>
                                        <input className={editToggle ? "bg-pacific-blue-50" : ""} type="text" value={fromPostCodeToAdd} onChange={(e) => setFromPostCodeToAdd(e.target.value)} />
                                    </div>
                                    <div className="form-control-8A">
                                        <label className="text-center">To</label>
                                        <input className={editToggle ? "bg-pacific-blue-50" : ""} type="text" value={toPostCodeToAdd} onChange={(e) => setToPostCodeToAdd(e.target.value)} />
                                    </div>
                                    <div className="form-control-8A">
                                        <label className="text-center">Consumption</label>
                                        <input className={editToggle ? "bg-pacific-blue-50" : ""} type="text" value={consumptionToAdd} onChange={handleChangeForConsumption} />
                                    </div>
                                    <div className="form-control-8A">
                                        <label className="text-center">Multiplier</label>
                                        <input className={editToggle ? "bg-pacific-blue-50" : ""} type="number" value={multiplierToAdd} onChange={(e) => setMultiplierToAdd(e.target.value)} />
                                    </div>
                                    <div className="form-control-8A">
                                        <label className="text-center">Unit</label>
                                        {/* <input type="text" value={unitToAdd} onChange={(e) => setUnitToAdd(e.target.value)} /> */}
                                        <select className={editToggle ? "bg-pacific-blue-50" : ""} value={unitToAdd} onChange={(e) => setUnitToAdd(e.target.value)}>
                                        <option value="">Please Select...</option>
                                        <option value="kg">kg</option>
                                        <option value="tonnes">tonnes</option>
                                        <option value="kWh">kWh</option>
                                        <option value="miles">miles</option>
                                        <option value="km">km</option>
                                        <option value="passenger.km">passenger.km</option>
                                        <option value="tonne.km">tonne.km</option>
                                        <option value="litres">litres</option>
                                        <option value="cubic metres">cubic metres</option>
                                        <option value="GJ">GJ</option>
                                        <option value="GBP">GBP</option>
                                        <option value="EUR">EUR</option>
                                        <option value="USD">USD</option>
                                        <option value="other">other</option>
                                        <option value="various">various</option>
					<option value="kgCO2e">kgCO2e</option>
                                        </select>
                                    </div>
                                    <div className="pl-1 pt-5 z-20">
                                        <div className="text-center pb-1">
                                            <label className="text-center">Transport Mode</label>
                                        </div>
                                        <ReactSelect
                                            styles={!editToggle ? styles : stylesWhenEditing}
                                            value={selectedTransportModeToAdd}
                                            onChange={setSelectedTransportModeToAdd}
                                            onClick={setSelectedTransportModeToAdd}
                                            options={transportModeOptions.sort((item1, item2) => (item1.Level2 < item2.Level2) ? -1 : (item1.Level2 > item2.Level2) ? 1 : 0).map((item) => {
                                                return {
                                                    label: item.Level2 + " " + item.Lookup + " " + item.ColumnText,
                                                    value: item.Level2 + " " + item.Lookup + " " + item.ColumnText
                                                }
                                            })} />
                                    </div>
                                    {role !== 3001 &&
                                    <div className={role === 6001 ? "grid grid-cols-1" : "grid grid-cols-2"}>
                                        <div className="form-control-8A">
                                            <label className="text-center">Factor</label>
                                            <input className={editToggle ? "bg-pacific-blue-50" : ""} type="number" value={emissionFactorToAdd} onChange={(e) => setEmissionFactorToAdd(e.target.value)} />
                                        </div>
                                        {role !== 6001 &&
                                        <div className="form-control-8A p-1">
                                            <label className="text-center text-slate-100">Select</label>
                                            <Button color="bondi-blue" text={"Select"} onClick={() => viewCarbonFactors()} />
                                        </div>
                                        }
                                    </div>
                                    }
                                    <div className="form-control-8A">
                                        <label className="text-center">Emissions (kgCO2e)</label>
                                        <input className={editToggle ? "bg-pacific-blue-50" : ""} type="text" value={carbonEmissionsToAdd} onChange={handleChangeForEmissions} />
                                    </div>
                                    <div className="form-control-8A">
                                        <label className="text-center">Comments</label>
                                        <input className={editToggle ? "bg-pacific-blue-50" : ""} type="text" value={commentsToAdd} onChange={(e) => setCommentsToAdd(e.target.value)} />
                                    </div>
                                    <div className="form-control-8A whitespace-nowrap">
                                        <label className="text-center">Emission Factor Source</label>
                                        <input className={editToggle ? "bg-pacific-blue-50" : ""} type="text" value={emissionFactorSourceToAdd} onChange={(e) => setEmissionFactorSourceToAdd(e.target.value)} />
                                    </div>


                                </div>
                                <div className="flex">
                                    {!editToggle && (
                                    <Button color="bg-bondi-blue" text="Add Item" onClick={() => addFootprintSubItem()} />
                                    )}
                                    {editToggle && (
                                    <Button color="bg-bondi-blue" text="Update Item" onClick={() => updateFootprintSubItem()} />
                                    )}
                                    <Button color="bg-bondi-blue" text="Cancel" onClick={() => cancelAddOrEditSubItem()} />

                                </div>
                            </div>
                        </div>
                        </div>
                        }

                        {/* <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="shadow overflow-hidden border-jungle-mist-100 sm:rounded border-2"> */}
                        <div className="flex flex-col py-2">
                            <div className="overflow-x-auto items-holder-8A">
                                <div className="text-eight-text align-middle inline-block min-w-full px-3">
                                    <div className="shadow-md shadow-slate-300 rounded-2xl">
                                        <table className="objects-8A min-w-full divide-y divide-gray-200">
                                            <thead className="bg-pacific-blue-50 z-10 sticky top-0">
                                                <tr>
                                                    <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Description</th>
                                                    <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Supplier</th>
                                                    <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Facility</th>
                                                    <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Individual(s)</th>
                                                    <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> From</th>
                                                    <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> To</th>
                                                    <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Transport Mode</th>
                                                    {role !== 3001 && role !== 5001 && role !== 6002 && role !== 2003 &&
                                                    <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> <Button color="bg-bondi-blue" text="Consumption" onClick={() => calculateAllDistances()} /></th>
                                                     }
                                                    {(role === 3001 || role === 5001 || role === 6002 || role === 2003) &&
                                                     <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Consumption</th>
                                                    }
                                                    <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Multiplier</th>
                                                    <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Unit</th>
                                                    {role !== 3001 && role !== 5001 && role !== 6002 && role !== 2003 &&
                                                        <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> <Button color="bg-bondi-blue" text="Emissions Factor" onClick={() => calculateAllEmissionFactors()} /></th>
                                                    }
                                                    {(role === 5001 || role === 6002 || role === 2003) &&
                                                     <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Emissions Factor</th>
                                                    }
                                                    <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Emission Factor Source</th>
                                                    {/* {role === 3001 &&
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Emissions Factor</th>
                                            } */}
                                                    {role !== 3001 && role !== 5001 && role !== 6002 && role !== 2003 &&
                                                        <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> <Button color="bg-bondi-blue" text="Emissions" onClick={() => calculateAllEmissions()} /></th>
                                                    }
                                                    {(role === 3001 || role === 5001 || role === 6002 || role === 2003) &&
                                                        <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Emissions</th>
                                                    }
                                                    <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Comments</th>
                                                    {role !== 3001 && role !== 5001 && role !== 6002 && role !== 2003 &&
                                                    <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Actions</th>
                                                    }
                                                    </tr>
                                            </thead>

                                            <tbody className="bg-white divide-y divide-gray-200">
                                                {footprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems.map((footprintSubItem) => (
                                                    <tr key={footprintSubItem.footprintSubItemId}>
                                                        <td className="px-4 md:px-6  py-2 hover:cursor-pointer"> {footprintSubItem.description}</td>
                                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {footprintSubItem.supplier}</td>
                                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {footprintSubItem.facility}</td>
                                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {footprintSubItem.individual} </td>
                                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {footprintSubItem.fromPostCode}</td>
                                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {footprintSubItem.toPostCode}</td>
                                                        <td className="px-4 md:px-6  py-2 hover:cursor-pointer"> {footprintSubItem.transportMode}</td>
                                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {internationalNumberFormat.format(footprintSubItem.consumption)}</td>
                                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {footprintSubItem.multiplier}</td>
                                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {footprintSubItem.unit}</td>
                                                        {role !== 3001 &&
                                                            <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {footprintSubItem.emissionFactor}</td>
                                                        }
                                                        <td className="px-4 md:px-6  py-2 hover:cursor-pointer text-xs"> {footprintSubItem.emissionFactorSource}</td>
                                                        {/* {role === 3001 &&
                                                <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {footprintSubItem.emissionFactor}</td>
                                                } */}
                                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {internationalNumberFormat.format(footprintSubItem.carbonEmissions)}</td>
                                                        <td className="px-4 md:px-6  py-2 hover:cursor-pointer"> {footprintSubItem.comments}</td>
                                                        
                                                        {role !== 3001 && role !== 5001 && role !== 6002 && role !== 2003 &&
                                                        <td className="px-4 md:px-6  py-2 hover:cursor-pointer">
                                                            <Options deleteFunction={deleteFootprintSubItem} deleteObject={footprintSubItem.footprintSubItemId} editFunction={editFootprintSubItem} editObject={{...footprintSubItem}}></Options>
                                                        </td>
                                                        }
                                                    </tr>
                                                ))}
                                            </tbody>

                                        </table>
                                        <Modal reportingYear={footprint.eventFootprintYear} tableData={carbonEmissionFactors} onChange={setSelectedFactor} onClose={() => setShowModal(false)} show={showModal} title="Browse Emission Factor Database" />

                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className='flex inline-block min-w-full px-3 my-5'>
                            <Button color="bg-bondi-blue" text="Save and Close" onClick={() => onSubmit()} />
                            <Button color="bg-bondi-blue" text="Export Data to CSV" onClick={() => arrayToCsv(footprint.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems)} />
                        </div>
                    </div>

                </div>

            </div>
        )

    } else {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Event Footprint Helper Tool" section="Event Footprint Item" showUserMenu='true' url={role === 3001 || role === 6001 ? "/ViewClient/" + company : "/"}/>
                        <p>Data is loading</p>
                    </div>

                </div>

            </div>
        )

    }

};

export default ViewEventFootprintItemHelperTool;
