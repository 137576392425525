import Header from '../UI/Header'
import { useState, useEffect } from 'react'
import { useMatch } from 'react-router-dom'
import { MapContainer, TileLayer, Marker, Popup, Polygon } from 'react-leaflet'
import { icon } from "leaflet"
import 'leaflet/dist/leaflet.css'
import FileUploadAndDownload from '../FileUploadAndDownload'
import axios from 'axios'



const ViewProjectPublic = (props) => {

    const match = useMatch('/viewProjectPublic/:projectId')

    const [projectNCSId, setProjectNCSId] = useState('')
    const [projectName, setProjectName] = useState('')
    const [projectType, setProjectType] = useState('')
    const [projectLocationCountry, setProjectLocationCountry] = useState('')
    const [projectSDGs, setProjectSDGs] = useState([])
    const [projectLatitude, setProjectLatitude] = useState(0)
    const [projectLongitude, setProjectLongitude] = useState(0)
    const [projectDetails, setProjectDetails] = useState(0)
    const [projectPolygonCoordinates, setProjectPolygonCoordinates] = useState([])

    const [loading, setLoading] = useState(true)

    useEffect(() => {

        axios.get(process.env.REACT_APP_API_URL + '/offset_projects/' + match.params.projectId)
            .then((res) => {
                setProjectNCSId(res.data.projectNCSId)
                setProjectName(res.data.projectName)
                setProjectType(res.data.projectType)
                setProjectLocationCountry(res.data.projectLocationCountry)
                setProjectSDGs(res.data.projectSDGs)
                setProjectLatitude(res.data.projectLatitude)
                setProjectLongitude(res.data.projectLongitude)
                setProjectDetails(res.data.projectDetails)
                setProjectPolygonCoordinates(res.data.projectPolygonCoordinates)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });

    }, [match.params.projectId])

    const ICON = icon({
        iconUrl: "/images/NZC-Hover.svg",
        iconSize: [27, 27],
    })

    if (!loading) {

        return (
            <div>
                <Header title={projectName} section={projectNCSId} showButtons={false} public="Public"/>

                <div id="showcase2-8A">

                </div>

                <br></br>
                <div className="flex flex-row border-2 rounded-xl m-3">
                    <div className="m-5 basis-1/4">
                        <br></br>
                        <div>
                            <div>
                                <label> Project NCS ID: </label>
                                <p className="viewObjectField-8A"> {projectNCSId}</p>
                            </div>
                            <div>
                                <label> Project Name: </label>
                                <p className="viewObjectField-8A"> {projectName}</p>
                            </div>
                            <div>
                                <label> Project Type: </label>
                                <p className="viewObjectField-8A"> {projectType}</p>
                            </div>
                            <div>
                                <label> Project Country: </label>
                                <p className="viewObjectField-8A"> {projectLocationCountry}</p>
                            </div>
                            <div>
                                <label> SDGs: </label>
                                <p className="viewObjectField-8A">{projectSDGs.map((image) => (<img className="sdg-8A" src={"/images/SDG" + image + ".png"} key={image} alt="" />))}</p>
                            </div>
                            <br></br>

                        </div>
                    </div>

                    <div className="m-5 basis-3/4">
                        <MapContainer center={[projectLatitude, projectLongitude]} zoom={4} >
                            <TileLayer
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker icon={ICON} position={[projectLatitude, projectLongitude]}>
                                <Popup>
                                    <h4>{projectName}</h4>
                                    <p>Latitude: {projectLatitude}</p>
                                    <p>Longitude: {projectLongitude}</p>

                                    <div>
                                        <h4> Project Polygon Coordinates: </h4>

                                        <table>
                                            <thead>
                                                <tr>
                                                    <th> Latitude</th>
                                                    <th> Longitude</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {projectPolygonCoordinates.map((coordinate) => (
                                                    <tr key={coordinate.point}>
                                                        <td> {coordinate.lat} </td>
                                                        <td> {coordinate.lng}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                    </div>
                                </Popup>
                            </Marker>
                            <Polygon color={'green'} positions={projectPolygonCoordinates} />
                        </MapContainer>
                    </div>
                </div>
                <br></br>
                <br></br>

                <div className="basis-full border-2 rounded-xl m-3"><p className="text-left">Project Description: </p>
                    <br></br>
                    <p className="viewObjectField-8A">{projectDetails}</p>
                </div>
                <br></br>

                <div className="flex">
                    <div className="basis-1/3 border-2 rounded-xl bg-linen m-3"><p className="text-center">Supporting Documents: </p>
                        <FileUploadAndDownload id={match.params.projectId} />
                    </div>
                    <div className="basis-2/3 border-2 rounded-xl bg-linen m-3"><p className="text-center">Notes: </p>
                    </div>
                </div>

            </div>
        )

    } else {

        return (
            <div>
                <Header title="Project - View" section="Project Details" showButtons={false} public="Public"/>
                <p>Data is loading...</p>
            </div>)

    }
};

export default ViewProjectPublic;
