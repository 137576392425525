import { useEffect, useState } from 'react'
import axios from 'axios'
import Button from '../UI/Button'
import Header from '../UI/Header'
import { useNavigate } from 'react-router-dom'
import Sidebar from '../UI/Sidebar'
import Options from '../UI/Options'

const ListBuildingPortfolios = () => {

    const navigate = useNavigate()

    const [buildingPortfolios, setBuildingPortfolios] = useState([])

    const [sidebarToggle, setSidebarToggle] = useState(false)

    const [loading, setLoading] = useState(false)

    useEffect(() => {

        setLoading(true)

        axios.get(process.env.REACT_APP_API_URL + '/buildings_portfolios')
            .then((res) => res.data)
            .then((res) => {
                setBuildingPortfolios(res)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });

    }, [])

    const deleteBuildingPortfolio = (id) => {

        window.confirm('Are you sure you want to delete this portfolio?') && (axios.delete(process.env.REACT_APP_API_URL + '/buildings_portfolios/' + id).then(setBuildingPortfolios(buildingPortfolios.filter((buildingPortfolio) => buildingPortfolio.id !== id))))

    }

    const editBuilding = (id) => {
        navigate(`/addOrUpdateBuildingPortfolio/${id}`)

    }

    const viewBuildingPortfolio = (id) => {
        navigate(`/viewBuildingPortfolio/${id}`);
    }

    if (buildingPortfolios.length > 0 && !loading) {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">

                    {sidebarToggle &&
                        <Sidebar />
                    }
                        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">

                        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                        <Header title="Building Portfolios" section="Select Building Portfolio" iconFileName="Building-Hover" showUserMenu />

                        <div className="flex flex-col py-2">
                            <div className="overflow-x-auto items-holder-8A">
                                <div className="text-eight-text align-middle inline-block min-w-full">
                                    <div className="shadow-md shadow-slate-300 rounded-2xl">

                                <table className="objects-8A min-w-full divide-y divide-gray-200">
 
                                    <thead className="bg-pacific-blue-50 z-10 sticky top-0">
                                        <tr>
                                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"></th>
                                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Porfolio Name</th>
                                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Number of Buildings</th>
                                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Assessment Types</th>
                                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Actions</th>
                                        </tr>
                                    </thead>

                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {buildingPortfolios.map((buildingPortfolio) => (
                                            <tr key={buildingPortfolio.id}>
                                                <td onClick={() => viewBuildingPortfolio(buildingPortfolio.id)} className="p-3 hover:cursor-pointer">                                         

<svg fill="#00518c" height="50px" width="50px" version="1.1" id="Layer_1" viewBox="0 0 512 512">

<path d="M366.933,477.867H128c-4.71,0-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533
v-8.533h230.4c4.71,0,8.533-3.823,8.533-8.533S371.644,477.867,366.933,477.867z"/>
<path d="M187.733,213.333c0,4.71,3.823,8.533,8.533,8.533H230.4c4.71,0,8.533-3.823,8.533-8.533v-51.2
c0-4.71-3.823-8.533-8.533-8.533h-34.133c-4.71,0-8.533,3.823-8.533,8.533V213.333z M204.8,170.667h17.067V204.8H204.8V170.667z"
/>
<path d="M110.933,102.4H8.533c-4.71,0-8.533,3.823-8.533,8.533V281.6c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533
V119.467h93.867c4.71,0,8.533-3.823,8.533-8.533S115.644,102.4,110.933,102.4z"/>
<path d="M409.6,418.133c0,4.71,3.823,8.533,8.533,8.533h34.133c4.71,0,8.533-3.823,8.533-8.533v-51.2
c0-4.71-3.823-8.533-8.533-8.533h-34.133c-4.71,0-8.533,3.823-8.533,8.533V418.133z M426.667,375.467h17.067V409.6h-17.067
V375.467z"/>
<path d="M42.667,307.2C0.486,307.2,0,417.024,0,418.133c0,20.608,14.686,37.837,34.133,41.805v43.529
c0,4.71,3.823,8.533,8.533,8.533c4.71,0,8.533-3.823,8.533-8.533v-43.529c19.447-3.968,34.133-21.197,34.133-41.805
C85.333,417.024,84.847,307.2,42.667,307.2z M42.667,443.733c-14.114,0-25.6-11.486-25.6-25.6
c0-42.513,11.418-93.867,25.6-93.867c14.182,0,25.6,51.354,25.6,93.867C68.267,432.247,56.781,443.733,42.667,443.733z"/>
<path d="M93.867,256H59.733c-4.71,0-8.533,3.823-8.533,8.533V281.6c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533
v-8.533h17.067v42.667c0,4.71,3.823,8.533,8.533,8.533c4.71,0,8.533-3.823,8.533-8.533v-51.2
C102.4,259.823,98.577,256,93.867,256z"/>
<path d="M418.133,221.867h34.133c4.71,0,8.533-3.823,8.533-8.533v-51.2c0-4.71-3.823-8.533-8.533-8.533h-34.133
c-4.71,0-8.533,3.823-8.533,8.533v51.2C409.6,218.044,413.423,221.867,418.133,221.867z M426.667,170.667h17.067V204.8h-17.067
V170.667z"/>
<path d="M93.867,153.6H59.733c-4.71,0-8.533,3.823-8.533,8.533v51.2c0,4.71,3.823,8.533,8.533,8.533h34.133
c4.71,0,8.533-3.823,8.533-8.533v-51.2C102.4,157.423,98.577,153.6,93.867,153.6z M85.333,204.8H68.267v-34.133h17.067V204.8z"/>
<path d="M273.067,213.333c0,4.71,3.823,8.533,8.533,8.533h34.133c4.71,0,8.533-3.823,8.533-8.533v-51.2
c0-4.71-3.823-8.533-8.533-8.533H281.6c-4.71,0-8.533,3.823-8.533,8.533V213.333z M290.133,170.667H307.2V204.8h-17.067V170.667z
"/>
<path d="M503.467,102.4h-102.4c-4.71,0-8.533,3.823-8.533,8.533s3.823,8.533,8.533,8.533h93.867V462.37
c-2.679-0.956-5.521-1.57-8.533-1.57c-1.476,0-2.944,0.128-4.386,0.384c-5.888-10.607-17.092-17.451-29.747-17.451
c-12.655,0-23.859,6.844-29.747,17.451c-1.442-0.256-2.91-0.384-4.386-0.384c-14.114,0-25.6,11.486-25.6,25.6
s11.486,25.6,25.6,25.6H486.4c14.114,0,25.6-11.486,25.6-25.6V110.933C512,106.223,508.177,102.4,503.467,102.4z M486.4,494.933
h-68.267c-4.702,0-8.533-3.831-8.533-8.533s3.831-8.533,8.533-8.533c1.638,0,3.191,0.469,4.625,1.391
c2.338,1.502,5.257,1.775,7.834,0.734c2.577-1.041,4.48-3.277,5.103-5.982c1.801-7.774,8.619-13.21,16.572-13.21
c7.953,0,14.771,5.436,16.572,13.21c0.623,2.705,2.526,4.941,5.103,5.982c2.569,1.041,5.495,0.768,7.834-0.734
c5.555-3.584,13.158,0.802,13.158,7.142C494.933,491.102,491.102,494.933,486.4,494.933z"/>
<path d="M281.6,119.467h34.133c4.71,0,8.533-3.823,8.533-8.533v-51.2c0-4.71-3.823-8.533-8.533-8.533H281.6
c-4.71,0-8.533,3.823-8.533,8.533v51.2C273.067,115.644,276.89,119.467,281.6,119.467z M290.133,68.267H307.2V102.4h-17.067
V68.267z"/>
<path d="M273.067,315.733c0,4.71,3.823,8.533,8.533,8.533h34.133c4.71,0,8.533-3.823,8.533-8.533v-51.2
c0-4.71-3.823-8.533-8.533-8.533H281.6c-4.71,0-8.533,3.823-8.533,8.533V315.733z M290.133,273.067H307.2V307.2h-17.067V273.067z
"/>
<path d="M196.267,119.467H230.4c4.71,0,8.533-3.823,8.533-8.533v-51.2c0-4.71-3.823-8.533-8.533-8.533h-34.133
c-4.71,0-8.533,3.823-8.533,8.533v51.2C187.733,115.644,191.556,119.467,196.267,119.467z M204.8,68.267h17.067V102.4H204.8
V68.267z"/>
<path d="M249.941,412.075c-1.536,1.621-2.475,3.84-2.475,6.059c0,2.219,0.939,4.437,2.475,6.059
c1.621,1.536,3.84,2.475,6.059,2.475c2.219,0,4.437-0.939,6.059-2.475c1.536-1.621,2.475-3.84,2.475-6.059
c0-2.219-0.939-4.437-2.475-6.059C258.816,408.917,253.184,408.917,249.941,412.075z"/>
<path d="M187.733,315.733c0,4.71,3.823,8.533,8.533,8.533H230.4c4.71,0,8.533-3.823,8.533-8.533v-51.2
c0-4.71-3.823-8.533-8.533-8.533h-34.133c-4.71,0-8.533,3.823-8.533,8.533V315.733z M204.8,273.067h17.067V307.2H204.8V273.067z"
/>
<path d="M170.667,366.933c0,4.71,3.823,8.533,8.533,8.533h25.6v76.8c0,4.71,3.823,8.533,8.533,8.533
c4.71,0,8.533-3.823,8.533-8.533v-76.8h68.267v76.8c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533v-76.8h25.6
c4.71,0,8.533-3.823,8.533-8.533s-3.823-8.533-8.533-8.533H179.2C174.49,358.4,170.667,362.223,170.667,366.933z"/>
<path d="M409.6,315.733c0,4.71,3.823,8.533,8.533,8.533h34.133c4.71,0,8.533-3.823,8.533-8.533v-51.2
c0-4.71-3.823-8.533-8.533-8.533h-34.133c-4.71,0-8.533,3.823-8.533,8.533V315.733z M426.667,273.067h17.067V307.2h-17.067
V273.067z"/>
<path d="M366.933,0H145.067c-4.71,0-8.533,3.823-8.533,8.533v443.733c0,4.71,3.823,8.533,8.533,8.533
c4.71,0,8.533-3.823,8.533-8.533v-435.2h204.8v435.2c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533V8.533
C375.467,3.823,371.644,0,366.933,0z"/>
</svg>
</td>
                                                <td onClick={() => viewBuildingPortfolio(buildingPortfolio.id)} className="px-4 md:px-6  py-2 hover:cursor-pointer"> {buildingPortfolio.portfolioName}</td>
                                                <td onClick={() => viewBuildingPortfolio(buildingPortfolio.id)} className="px-4 md:px-6  py-2 hover:cursor-pointer"> {"4"}</td>
                                                <td onClick={() => viewBuildingPortfolio(buildingPortfolio.id)} className="px-4 md:px-6  py-2 hover:cursor-pointer"> {"GRESB, WELL"}</td>
                                                <td className="px-4 md:px-6 py-2 whitespace-nowrap md:pr-5">
                                                    <Options edit={`/addOrUpdateBuilding/${buildingPortfolio.id}`} deleteFunction={() => deleteBuildingPortfolio(buildingPortfolio.id)} deleteObject={buildingPortfolio.id}></Options>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                        <br></br>
                        <Button color="bg-bondi-blue" text="Add New Building Portfolio" onClick={() => navigate('/addOrUpdateBuilding/_add')} />
                    </div>
                </div>
            </div>
            </div>
        )

    } else {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="buildingPortfolios" section="Select Building Portfolio" iconFileName="Building-Hover" showUserMenu />
                        <p>Data is loading</p>
                    </div>

                </div>

            </div>
        )

    }

}

export default ListBuildingPortfolios
