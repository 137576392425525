import { useState, useEffect } from 'react'
import axios from 'axios'
import Header from '../UI/Header'
import { useNavigate, useMatch } from 'react-router-dom'
import Button from '../UI/Button'

const AddOrUpdateBuildingConstructionCarbonEmissions = () => {

    const match = useMatch('/addOrUpdateBuildingConstructionCarbonEmissions/:buildingId/:buildingConstructionCarbonEmissionsId')

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [buildingId, setBuildingId] = useState('')
    const [address, setAddress] = useState('')
    const [embodiedCarbon, setEmbodiedCarbon] = useState('')
    const [areaSqMetres, setAreaSqMetres] = useState('')
    const [RIBAStage, setRIBAStage] = useState([])
    const [offsetting, setOffsetting] = useState([])
    const [constructionLabel, setConstructionLabel] = useState([])
    const [wholeLifeCycleCarbon, setWholeLifeCycleCarbon] = useState([])

    const [file, setFile] = useState()
    const fileReader = new FileReader()

    const handleOnChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleOnSubmit = (e) => {
        e.preventDefault();

        if (file) {
            fileReader.onload = function (organisation) {
                const text = organisation.target.result;
                csvFileToArray(text);
            };

            fileReader.readAsText(file);
        }
    };

    const csvFileToArray = string => {

        //below line of code coudl be useful if we want to dynamically handle different column names (keys)
        //const csvHeader = string.slice(0, string.indexOf("\n")).split(",");

        const csvHeader = ["Section", "Resource", "UserInput", "Unit", "MassOfRawMaterialsKG", "TotalKGCO2e"]
        const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

        var filteredArray

        csvRows.shift()
        csvRows.shift()

        //drop the last line (blank)
        csvRows.pop()

        const array = csvRows.map(i => {

            //const values = i.split(",")

            const values = i.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/)

            var field

            for (field = 0; field < 5; field++) {

                values[field] = values[field].replace(/^"|"$/g, '')
                values[field] = values[field].replace(/""/g, '"')
                values[field] = values[field].replace(/^"|"$/, '')

                //If the last field has any commas then a trailing quotation mark (") still remains before the end of the line
                //This code checks for this and removes the quotation mark (")

                if (values[field].slice(values[field].length - 2, values[field].length - 1) === '"') {
                    values[field] = values[field].slice(0, values[field].length - 2)
                }

            }

            const obj = csvHeader.reduce((object, header, index) => {
                object[header] = values[index];
                return object;
            }, {});
            return obj;

        });

        filteredArray = array.filter(item => item.Resource !== "" && item.Section !== "TOTAL")

        setWholeLifeCycleCarbon(filteredArray.map(item => item.TotalKGCO2e).reduce((prev, curr) => Number(prev) + Number(curr), 0))
        setEmbodiedCarbon(filteredArray.filter(item => item.Section.slice(0,1) === "A").map(item => item.TotalKGCO2e).reduce((prev, curr) => Number(prev) + Number(curr), 0))

    };


    useEffect(() => {

        setBuildingId(match.params.buildingId)

        if (match.params.buildingConstructionCarbonEmissionsId !== "_add") {

            setLoading(true)

            axios.get(process.env.REACT_APP_API_URL + '/buildings_construction_carbon_emissions/' + match.params.buildingConstructionCarbonEmissionsId)
                .then((res) => res.data)
                .then((res) => {
                    setBuildingId(res.buildingId)
                    setAddress(res.address)
                    setEmbodiedCarbon(res.embodiedCarbon)
                    setAreaSqMetres(res.areaSqMetres)
                    setRIBAStage(res.RIBAStage)
                    setOffsetting(res.offsetting)
                    setConstructionLabel(res.constructionLabel)
                    setWholeLifeCycleCarbon(res.wholeLifeCycleCarbon)

                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });

        }
    }, [match.params.buildingConstructionCarbonEmissionsId, match.params.buildingId])

    const addForm = async (form) => {
        await axios.post(process.env.REACT_APP_API_URL + '/buildings_construction_carbon_emissions', form)
    }

    const updateForm = async (form) => {
        await axios.put(process.env.REACT_APP_API_URL + '/buildings_construction_carbon_emissions/' + match.params.buildingConstructionCarbonEmissionsId, form)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (match.params.buildingConstructionCarbonEmissionsId !== "_add") {
            updateForm({ buildingId, address, areaSqMetres, embodiedCarbon, RIBAStage, offsetting, constructionLabel, wholeLifeCycleCarbon }).then(res => { navigate(-1) })
        } else {
            addForm({ buildingId, address, areaSqMetres, embodiedCarbon, RIBAStage, offsetting, constructionLabel, wholeLifeCycleCarbon }).then(res => { navigate(-1) })
        }
    }

    if (!loading) {

        return (

                <div className='flex items-center bg-jungle-mist-100 min-h-screen'>
                    <div className="container-form-8A">

                    <Header title="Add / Edit Building Construction Carbon Emissions" section="Building Construction Carbon Emissions Main Details" iconFileName="Building-Hover" />

                    <form className="p-2 ">
                        <input id="csvFileInput" accept={".csv"} type={"file"} onChange={handleOnChange} />
                        <label className="p-2" htmlFor="csvFileInput">Choose a File</label>
                        <Button color="bondi-blue" text="Import Embodied Carbon and WLC from One Click" onClick={(e) => { handleOnSubmit(e) }} />
                    </form>

                    <form className="add-form-8A" onSubmit={onSubmit}>

                        <div className="form-control-8A">
                            <label>Building Address</label>
                            <input type="text" placeholder="Enter Address Here" value={address} onChange={(e) => setAddress(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Area (m2)</label>
                            <input type="text" placeholder="Enter Area (m2) Here" value={areaSqMetres} onChange={(e) => setAreaSqMetres(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Embodied Carbon</label>
                            <input type="text" placeholder="Enter Embodied Carbon Here" value={embodiedCarbon} onChange={(e) => setEmbodiedCarbon(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>RIBA Stage</label>
                            <input type="text" placeholder="Enter RIBA Stage Here" value={RIBAStage} onChange={(e) => setRIBAStage(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Offsetting</label>
                            <input type="text" placeholder="Enter Offsetting Here" value={offsetting} onChange={(e) => setOffsetting(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Construction Label</label>
                            <input type="text" placeholder="Enter Construction Label Here" value={constructionLabel} onChange={(e) => setConstructionLabel(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Whole Life Cycle Carbon</label>
                            <input type="text" placeholder="Enter Whole Life Cycle Carbon Here" value={wholeLifeCycleCarbon} onChange={(e) => setWholeLifeCycleCarbon(e.target.value)} />
                        </div>

                        <input type="submit" value="Save" className="btn-8A btn-block-8A" />

                    </form>

                </div>
            </div>
        )

    } else {

        return (
            <div className='flex items-center bg-jungle-mist-100 min-h-screen'>
                <div className="container-form-8A">
                    <Header title="Add / Edit Building Construction Carbon Emissions" section="Building Construction Carbon Emissions Main Details" iconFileName="Building-Hover" />
                    <p>Data is loading...</p>
                </div>
            </div>)

    }

}

export default AddOrUpdateBuildingConstructionCarbonEmissions