import { useState, useEffect } from 'react'
import axios from 'axios'
import Header from '../UI/Header'
import Button from '../UI/Button'
import Modal from '../UI/Modal'
import { useNavigate, useMatch } from 'react-router-dom'
import FileUploadAndDownload from '../FileUploadAndDownload'
import { v4 as uuidv4 } from 'uuid'
import AuthService from '../../services/AuthService'


const AddOrUpdateEventFootprintItem = () => {

    const match = useMatch('/addOrUpdateEventFootprintItem/:eventId/:footprintId/:footprintItemId')

    const navigate = useNavigate()

    const [showModal, setShowModal] = useState(false)

    const [carbonFactorLoading, setCarbonFactorsLoading] = useState(false)
    const [footprintsLoading, setFootprintsLoading] = useState(false)
    const [usersLoading, setUsersLoading] = useState(false)
    const [userId, setUserId] = useState(false)

    const [carbonEmissionFactors, setCarbonEmissionFactors] = useState([])

    const [footprint, setFootprint] = useState([])

    const [users, setUsers] = useState([])

    const [eventId, setEventId] = useState('')
    const [eventFootprintRef, setEventFootprintRef] = useState('')
    const [eventFootprintYear, setEventFootprintYear] = useState('')
    const [eventFootprintDate, setEventFootprintDate] = useState('')
    const [eventFootprintItems, setEventFootprintItems] = useState([])
    const [eventFootprintRecordConsolidation, setEventFootprintRecordConsolidation] = useState('')
    const [eventFootprintType, setEventFootprintType] = useState('')
    //const [eventFootprintNumberOfAttendees, setEventFootprintNumberOfAttendees] = useState('')
    const [eventFootprintLocation, setEventFootprintLocation] = useState('')
    const [eventFootprintNotes, setEventFootprintNotes] = useState('')
    const [eventFootprintCertification, setEventFootprintCertification] = useState('')
    const [eventFootprintConsultant, setEventFootprintConsultant] = useState('')
    const [eventFootprintClientContact, setEventFootprintClientContact] = useState('')
    const [eventFootprintVerifier, setEventFootprintVerifier] = useState('')
    const [eventFootprintTargetYear, setEventFootprintTargetYear] = useState('')
    const [eventFootprintOverallReductionTargets, setEventFootprintOverallReductionTargets] = useState({})
    const [eventFootprintNumberOfAttendees, setEventNumberOfAttendees] = useState(0)
    const [eventTurnoverGBP, setEventTurnoverGBP] = useState(0)
    const [eventFootprintObjectives, setEventFootprintObjectives] = useState('')
    const [eventFootprintScopeBoundaries, setEventFootprintScopeBoundaries] = useState('')
    const [eventFootprintLevelOfAssurance, setEventFootprintLevelOfAssurance] = useState('')
    const [eventFootprintMateriality, setEventFootprintMateriality] = useState({"oneHundredPercentGHGEmissions": "", "categoryExclusionAllowed": ""})
    const [eventFootprintVerificationActivitiesAndTechniques, setEventFootprintVerificationActivitiesAndTechniques] = useState({"observation": "", "inquiry": "", "analyticalTesting": "", "confirmation": "", "recalculation": "", "examination": "", "retracing": "", "tracing": "", "controlTesting": "", "sampling": "", "estimateTesting": "", "crossChecking": "", "reconciliation": ""})

    const [id, setEventFootprintItemId] = useState()
    const [itemDescription, setEventFootprintItemDescription] = useState('')
    const [consumption, setEventFootprintItemConsumption] = useState(0)
    const [unit, setEventFootprintItemUnit] = useState('')
    const [emissionFactor, setEventFootprintItEmissionFactor] = useState(0)
    const [carbonEmissions, setEventFootprintItemCarbonEmissions] = useState(0)
    const [itemCategory, setEventFootprintItemCategory] = useState('')
    const [itemSubCategory, setEventFootprintItemSubCategory] = useState('')
    const [itemConsultantComment, setEventFootprintItemConsultantComment] = useState('')
    const [itemTag, setEventFootprintItemTag] = useState('')
    const [dataType, setDataType] = useState('')
    const [basicUncertainty, setBasicUncertainty] = useState('')
    const [basicUncertaintyFactor, setBasicUncertaintyFactor] = useState('')

    const [basicUncertaintyFactors, setBasicUncertaintyFactors] = useState([])

    const [dataQualityTechnology, setDataQualityTechnology] = useState('')
    const [dataQualityTechnologyClassification, setDataQualityTechnologyClassification] = useState('')
    const [dataQualityTechnologyFactor, setDataQualityTechnologyFactor] = useState('')

    const [dataQualityTime, setDataQualityTime] = useState('')
    const [dataQualityTimeClassification, setDataQualityTimeClassification] = useState('')
    const [dataQualityTimeFactor, setDataQualityTimeFactor] = useState('')

    const [dataQualityGeography, setDataQualityGeography] = useState('')
    const [dataQualityGeographyClassification, setDataQualityGeographyClassification] = useState('')
    const [dataQualityGeographyFactor, setDataQualityGeographyFactor] = useState('')

    const [dataQualityCompleteness, setDataQualityCompleteness] = useState('')
    const [dataQualityCompletenessClassification, setDataQualityCompletenessClassification] = useState('')
    const [dataQualityCompletenessFactor, setDataQualityCompletenessFactor] = useState('')

    const [dataQualityReliability, setDataQualityReliability] = useState('')
    const [dataQualityReliabilityClassification, setDataQualityReliabilityClassification] = useState('')
    const [dataQualityReliabilityFactor, setDataQualityReliabilityFactor] = useState('')

    const [emissionsFactorSource, setEmissionsFactorSource] = useState('')

    const [emissionsFactorTechnology, setEmissionsFactorTechnology] = useState('')
    const [emissionsFactorTechnologyClassification, setEmissionsFactorTechnologyClassification] = useState('')
    const [emissionsFactorTechnologyFactor, setEmissionsFactorTechnologyFactor] = useState('')

    const [emissionsFactorTime, setEmissionsFactorTime] = useState('')
    const [emissionsFactorTimeClassification, setEmissionsFactorTimeClassification] = useState('')
    const [emissionsFactorTimeFactor, setEmissionsFactorTimeFactor] = useState('')

    const [emissionsFactorGeography, setEmissionsFactorGeography] = useState('')
    const [emissionsFactorGeographyClassification, setEmissionsFactorGeographyClassification] = useState('')
    const [emissionsFactorGeographyFactor, setEmissionsFactorGeographyFactor] = useState('')

    const [emissionsFactorCompleteness, setEmissionsFactorCompleteness] = useState('')
    const [emissionsFactorCompletenessClassification, setEmissionsFactorCompletenessClassification] = useState('')
    const [emissionsFactorCompletenessFactor, setEmissionsFactorCompletenessFactor] = useState('')

    const [emissionsFactorReliability, setEmissionsFactorReliability] = useState('')
    const [emissionsFactorReliabilityClassification, setEmissionsFactorReliabilityClassification] = useState('')
    const [emissionsFactorReliabilityFactor, setEmissionsFactorReliabilityFactor] = useState('')

    const [reductionControlCategory, setReductionControlCategory] = useState('')
    const [reductionCommentsOnControl, setReductionCommentsOnControl] = useState('')
    const [reductionTarget, setReductionTarget] = useState('')
    const [reductionTargetYear, setReductionTargetYear] = useState('')
    const [reductionMeasures, setReductionMeasures] = useState('')

    const [numberOfMonths, setNumberOfMonths] = useState(12)
    const [additionalEstimatedEmissions, setAdditionalEstimatedEmissions] = useState(0)

    const [filesUploaded, setFilesUploaded] = useState(0)

    const [submitted, setSubmitted] = useState('')
    const [verified, setVerified] = useState('')
    const [submissionComment, setSubmissionComment] = useState('')
    const [verificationComment, setVerificationComment] = useState('')

    const [newSubmissionComment, setNewSubmissionComment] = useState('')
    const [newVerificationComment, setNewVerificationComment] = useState('')
    const [newVerificationStatus, setNewVerificationStatus] = useState('')

    const [commentThread, setCommentThread] = useState([{id: "", commentDate: "", commentType: "", commentUser:"", comment: ""}])

    const [reductionProjection, setReductionProjection] = useState('0')
    const [reductionProjectionYear, setReductionProjectionYear] = useState('')

    const [reductionTargetPercentageOverride, setReductionTargetPercentageOverride] = useState(0)

    const [footprintSubItems, setFootprintSubItems] = useState([])

    const [uncertaintyCategories, setUncertaintyCategories] = useState([])
    const [uncertaintyCategoriesLoading, setUncertaintyCategoriesLoading] = useState(false)

    const [company, setCompany] = useState("")
    const [role, setRole] = useState(0)
    const [userPropertiesLoading, setUserPropertiesLoading] = useState(true)

    const getUserProperties = async () => {

        await axios.get(process.env.REACT_APP_API_URL + "/current_user_properties")
            .then((response) => {
                setCompany(response.data.company)
                setRole(response.data.role)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUserPropertiesLoading(false);
            });

    }

    const removeCommas = (num) => num.toString().replace(/,/g, "");

    const handleChangeForConsumption = (e) => {
        const value = e.target.value;
        const clean = value.replace(/,/g, "");
        const regex = /^[0-9]*\.?[0-9]*$/;

        if (value && clean.match(regex)) {
            if (!value.includes(".")) {
                const formatted = new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 10,
                }).format(parseFloat(clean));
                setEventFootprintItemConsumption(formatted);
            } else {
                setEventFootprintItemConsumption(value);
            }
        } else {
            setEventFootprintItemConsumption(value.replace(/[^0-9,.]/g, ''))
        }
    };

    const setFormattedConsumption = (e) => {
        const value = e.toString();
        const clean = value.replace(/,/g, "");
        const regex = /^[0-9]*\.?[0-9]*$/;

        if (value && clean.match(regex)) {

            const formatted = new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 10,
            }).format(parseFloat(clean));
            setEventFootprintItemConsumption(formatted);

        } else {
            setEventFootprintItemConsumption("");
        }

    };

    const handleChangeForEmissions = (e) => {
        const value = e.target.value;
        const clean = value.replace(/,/g, "");
        const regex = /^[0-9]*\.?[0-9]*$/;

        if (value && clean.match(regex)) {
            if (!value.includes(".")) {
                const formatted = new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 10,
                }).format(parseFloat(clean));
                setEventFootprintItemCarbonEmissions(formatted);
            } else {
                setEventFootprintItemCarbonEmissions(value);
            }
        } else {
            setEventFootprintItemCarbonEmissions(value.replace(/[^0-9,.]/g, ''))
        }
    };

    const setFormattedEmissions = (e) => {
        const value = e.toString();
        const clean = value.replace(/,/g, "");
        const regex = /^[0-9]*\.?[0-9]*$/;

        if (value && clean.match(regex)) {

            const formatted = new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 10,
            }).format(parseFloat(clean));
            setEventFootprintItemCarbonEmissions(formatted);

        } else {
            setEventFootprintItemCarbonEmissions("");
        }

    };

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const submitItem = () => {
        const newDate = new Date()
        setSubmitted(formatDate(newDate))
    };

    // const verifyItem = () => {
    //     const newDate = new Date()
    //     setVerified(formatDate(newDate))
    // };

    const updateUncertaintyFields = (e) => {

        setEventFootprintItemSubCategory(e)

        //Code here to set all uncertainty fields to last item of the same subCategory
        var numberOfItems = footprint.eventFootprintItems.filter(item => item.itemSubCategory === itemSubCategory && item.id !== id).length

        var footprintToCopy = footprint.eventFootprintItems.filter(item => item.itemSubCategory === itemSubCategory).sort((item1, item2) => item1.itemCategory < item2.itemCategory ? -1 : item1.itemCategory > item2.itemCategory ? 1 : item1.carbonEmissions > item2.carbonEmissions ? -1 : item1.carbonEmissions < item2.carbonEmissions ? 1 : 0)[numberOfItems]

        if (numberOfItems > 0) {

            setDataType(footprintToCopy.dataType)
            setBasicUncertainty(footprintToCopy.basicUncertainty)
            setBasicUncertaintyFactor(footprintToCopy.basicUncertaintyFactor)

            setDataQualityTechnology(footprintToCopy.dataQualityTechnology)
            setDataQualityTechnologyClassification(footprintToCopy.dataQualityTechnologyClassification)
            setDataQualityTechnologyFactor(footprintToCopy.dataQualityTechnologyFactor)

            setDataQualityTime(footprintToCopy.dataQualityTime)
            setDataQualityTimeClassification(footprintToCopy.dataQualityTimeClassification)
            setDataQualityTimeFactor(footprintToCopy.dataQualityTimeFactor)

            setDataQualityGeography(footprintToCopy.dataQualityGeography)
            setDataQualityGeographyClassification(footprintToCopy.dataQualityGeographyClassification)
            setDataQualityGeographyFactor(footprintToCopy.dataQualityGeographyFactor)

            setDataQualityCompleteness(footprintToCopy.dataQualityCompleteness)
            setDataQualityCompletenessClassification(footprintToCopy.dataQualityCompletenessClassification)
            setDataQualityCompletenessFactor(footprintToCopy.dataQualityCompletenessFactor)

            setDataQualityReliability(footprintToCopy.dataQualityReliability)
            setDataQualityReliabilityClassification(footprintToCopy.dataQualityReliabilityClassification)
            setDataQualityReliabilityFactor(footprintToCopy.dataQualityReliabilityFactor)

            setEmissionsFactorSource(footprintToCopy.emissionsFactorSource)


            setEmissionsFactorTechnology(footprintToCopy.emissionsFactorTechnology)
            setEmissionsFactorTechnologyClassification(footprintToCopy.emissionsFactorTechnologyClassification)
            setEmissionsFactorTechnologyFactor(footprintToCopy.emissionsFactorTechnologyFactor)

            setEmissionsFactorTime(footprintToCopy.emissionsFactorTime)
            setEmissionsFactorTimeClassification(footprintToCopy.emissionsFactorTimeClassification)
            setEmissionsFactorTimeFactor(footprintToCopy.emissionsFactorTimeFactor)

            setEmissionsFactorGeography(footprintToCopy.emissionsFactorGeography)
            setEmissionsFactorGeographyClassification(footprintToCopy.emissionsFactorGeographyClassification)
            setEmissionsFactorGeographyFactor(footprintToCopy.emissionsFactorGeographyFactor)

            setEmissionsFactorCompleteness(footprintToCopy.emissionsFactorCompleteness)
            setEmissionsFactorCompletenessClassification(footprintToCopy.emissionsFactorCompletenessClassification)
            setEmissionsFactorCompletenessFactor(footprintToCopy.emissionsFactorCompletenessFactor)

            setEmissionsFactorReliability(footprintToCopy.emissionsFactorReliability)
            setEmissionsFactorReliabilityClassification(footprintToCopy.emissionsFactorReliabilityClassification)
            setEmissionsFactorReliabilityFactor(footprintToCopy.emissionsFactorReliabilityFactor)

        }

    }

    const setSelectedFactor = (e, datasource) => {

        setEventFootprintItEmissionFactor(Number(e))
        setEmissionsFactorSource(datasource)

    }

    const setUpdatedNumberOfFilesUploaded = async (e) => {

        setFilesUploaded(Number(e) + 1)

        eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).filesUploaded = Number(e) + 1

        await axios.put(process.env.REACT_APP_API_URL + '/events_footprints/' + match.params.footprintId, { eventId, eventFootprintRef, eventFootprintYear, eventFootprintDate, eventFootprintItems, eventFootprintRecordConsolidation, eventFootprintType, eventFootprintNotes, eventFootprintCertification, eventFootprintConsultant, eventFootprintClientContact, eventFootprintVerifier, eventFootprintTargetYear, eventFootprintOverallReductionTargets, eventFootprintNumberOfAttendees, eventFootprintLocation, eventFootprintObjectives, eventFootprintScopeBoundaries, eventFootprintLevelOfAssurance, eventFootprintMateriality, eventFootprintVerificationActivitiesAndTechniques })

    }

    const onSubmit = (e) => {
        e.preventDefault()

        if (!isNaN(Number(removeCommas(carbonEmissions))) && !isNaN(Number(removeCommas(consumption)))) {
            
            if (newSubmissionComment !== "") {
                commentThread.push({id: uuidv4(), commentDate: Date(), commentType: "Submission", commentUser: userId, comment: newSubmissionComment})
            }
        
            if (newVerificationComment !== "") {
                commentThread.push({id: uuidv4(), commentDate: Date(), commentType: "Verification", commentUser: userId, comment: newVerificationComment})
            }
    
            //if _add then add new item object to eventFootprintItems array
            if (match.params.footprintItemId === "_add") {
                eventFootprintItems.push({ id, itemDescription, consumption: Number(removeCommas(consumption)), unit, emissionFactor, carbonEmissions: Number(removeCommas(carbonEmissions)), itemCategory, itemSubCategory, itemConsultantComment, itemTag, dataType, basicUncertainty, basicUncertaintyFactor, dataQualityTechnology, dataQualityTechnologyClassification, dataQualityTechnologyFactor, dataQualityTime, dataQualityTimeClassification, dataQualityTimeFactor, dataQualityGeography, dataQualityGeographyClassification, dataQualityGeographyFactor, dataQualityCompleteness, dataQualityCompletenessClassification, dataQualityCompletenessFactor, dataQualityReliability, dataQualityReliabilityClassification, dataQualityReliabilityFactor, emissionsFactorSource, emissionsFactorTechnology, emissionsFactorTechnologyClassification, emissionsFactorTechnologyFactor, emissionsFactorTime, emissionsFactorTimeClassification, emissionsFactorTimeFactor, emissionsFactorGeography, emissionsFactorGeographyClassification, emissionsFactorGeographyFactor, emissionsFactorCompleteness, emissionsFactorCompletenessClassification, emissionsFactorCompletenessFactor, emissionsFactorReliability, emissionsFactorReliabilityClassification, emissionsFactorReliabilityFactor, reductionControlCategory, reductionCommentsOnControl, reductionTarget, reductionTargetYear, reductionMeasures, filesUploaded, submitted, verified, submissionComment: newSubmissionComment, verificationComment: newVerificationComment, reductionProjection, reductionProjectionYear, footprintSubItems: [], numberOfMonths, additionalEstimatedEmissions, reductionTargetPercentageOverride, commentThread })
            }
    
            updateForm({ eventId, eventFootprintRef, eventFootprintYear, eventFootprintDate, eventFootprintItems, eventFootprintRecordConsolidation, eventFootprintType, eventFootprintNotes, eventFootprintCertification, eventFootprintConsultant, eventFootprintClientContact, eventFootprintVerifier, eventFootprintTargetYear, eventFootprintOverallReductionTargets, eventFootprintNumberOfAttendees, eventFootprintLocation, eventFootprintObjectives, eventFootprintScopeBoundaries, eventFootprintLevelOfAssurance, eventFootprintMateriality, eventFootprintVerificationActivitiesAndTechniques }).then(res => { navigate(-1) })


        } else {

            window.alert("Please check that numeric values are valid")

        }

    }



    const openHelperTool = () => {

        //if _add then add new item object to eventFootprintItems array
        if (match.params.footprintItemId === "_add") {
            eventFootprintItems.push({ id, itemDescription, consumption: Number(removeCommas(consumption)), unit, emissionFactor, carbonEmissions: Number(removeCommas(carbonEmissions)), itemCategory, itemSubCategory, itemConsultantComment, itemTag, dataType, basicUncertainty, basicUncertaintyFactor, dataQualityTechnology, dataQualityTechnologyClassification, dataQualityTechnologyFactor, dataQualityTime, dataQualityTimeClassification, dataQualityTimeFactor, dataQualityGeography, dataQualityGeographyClassification, dataQualityGeographyFactor, dataQualityCompleteness, dataQualityCompletenessClassification, dataQualityCompletenessFactor, dataQualityReliability, dataQualityReliabilityClassification, dataQualityReliabilityFactor, emissionsFactorSource, emissionsFactorTechnology, emissionsFactorTechnologyClassification, emissionsFactorTechnologyFactor, emissionsFactorTime, emissionsFactorTimeClassification, emissionsFactorTimeFactor, emissionsFactorGeography, emissionsFactorGeographyClassification, emissionsFactorGeographyFactor, emissionsFactorCompleteness, emissionsFactorCompletenessClassification, emissionsFactorCompletenessFactor, emissionsFactorReliability, emissionsFactorReliabilityClassification, emissionsFactorReliabilityFactor, reductionControlCategory, reductionCommentsOnControl, reductionTarget, reductionTargetYear, reductionMeasures, filesUploaded, submitted, verified, submissionComment: newSubmissionComment, verificationComment: newVerificationComment, reductionProjection, reductionProjectionYear, footprintSubItems: [], numberOfMonths, additionalEstimatedEmissions, reductionTargetPercentageOverride, commentThread })
        }

        updateForm({ eventId, eventFootprintRef, eventFootprintYear, eventFootprintDate, eventFootprintItems, eventFootprintRecordConsolidation, eventFootprintType, eventFootprintNotes, eventFootprintCertification, eventFootprintConsultant, eventFootprintClientContact, eventFootprintVerifier, eventFootprintTargetYear, eventFootprintOverallReductionTargets, eventFootprintNumberOfAttendees, eventFootprintLocation, eventFootprintObjectives, eventFootprintScopeBoundaries, eventFootprintLevelOfAssurance, eventFootprintMateriality, eventFootprintVerificationActivitiesAndTechniques }).then(res => { navigate(`/ViewEventFootprintItemHelperTool/${match.params.eventId}/${match.params.footprintId}/${id}`) })
    }

    const updateForm = async (form) => {
        if (match.params.footprintItemId !== "_add") {
            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).id = id
            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemDescription = itemDescription
            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).consumption = Number(removeCommas(consumption))
            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).unit = unit
            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionFactor = emissionFactor
            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).carbonEmissions = Number(removeCommas(carbonEmissions))
            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemCategory = itemCategory
            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemSubCategory = itemSubCategory
            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemConsultantComment = itemConsultantComment
            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemTag = itemTag
            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataType = dataType
            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).basicUncertainty = basicUncertainty
            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).basicUncertaintyFactor = basicUncertaintyFactor
            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityTechnology = dataQualityTechnology
            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityTechnologyClassification = dataQualityTechnologyClassification
            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityTechnologyFactor = dataQualityTechnologyFactor

            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityTime = dataQualityTime
            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityTimeClassification = dataQualityTimeClassification
            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityTimeFactor = dataQualityTimeFactor

            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityGeography = dataQualityGeography
            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityGeographyClassification = dataQualityGeographyClassification
            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityGeographyFactor = dataQualityGeographyFactor

            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityCompleteness = dataQualityCompleteness
            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityCompletenessClassification = dataQualityCompletenessClassification
            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityCompletenessFactor = dataQualityCompletenessFactor

            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityReliability = dataQualityReliability
            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityReliabilityClassification = dataQualityReliabilityClassification
            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityReliabilityFactor = dataQualityReliabilityFactor


            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorSource = emissionsFactorSource

            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorTechnology = emissionsFactorTechnology
            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorTechnologyClassification = emissionsFactorTechnologyClassification
            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorTechnologyFactor = emissionsFactorTechnologyFactor

            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorTime = emissionsFactorTime
            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorTimeClassification = emissionsFactorTimeClassification
            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorTimeFactor = emissionsFactorTimeFactor

            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorGeography = emissionsFactorGeography
            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorGeographyClassification = emissionsFactorGeographyClassification
            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorGeographyFactor = emissionsFactorGeographyFactor

            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorCompleteness = emissionsFactorCompleteness
            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorCompletenessClassification = emissionsFactorCompletenessClassification
            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorCompletenessFactor = emissionsFactorCompletenessFactor

            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorReliability = emissionsFactorReliability
            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorReliabilityClassification = emissionsFactorReliabilityClassification
            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorReliabilityFactor = emissionsFactorReliabilityFactor


            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).reductionControlCategory = reductionControlCategory
            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).reductionCommentsOnControl = reductionCommentsOnControl
            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).reductionTarget = reductionTarget
            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).reductionTargetYear = reductionTargetYear
            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).reductionMeasures = reductionMeasures

            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).filesUploaded = filesUploaded

            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).submitted = submitted
            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).verified = verified
            if (newSubmissionComment === "") {
                eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).submissionComment = submissionComment
            } else {
                eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).submissionComment = newSubmissionComment 
            }
            if (newVerificationComment === "") {
                eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).verificationComment = verificationComment
            } else {
                eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).verificationComment = newVerificationComment
            }
            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).reductionProjection = reductionProjection
            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).reductionProjectionYear = reductionProjectionYear
            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).numberOfMonths = numberOfMonths
            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).additionalEstimatedEmissions = additionalEstimatedEmissions

            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).reductionTargetPercentageOverride = reductionTargetPercentageOverride

            eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).commentThread = commentThread

        }
        await axios.put(process.env.REACT_APP_API_URL + '/events_footprints/' + match.params.footprintId, form)
    }

    const viewCarbonFactors = () => {
        setShowModal(true)
    }

    useEffect(() => {

        setUserPropertiesLoading(true)

        getUserProperties()

    }, [])


    useEffect(() => {

        if (company && role && !userPropertiesLoading) {

            setCarbonFactorsLoading(true)
            setFootprintsLoading(true)
            setUncertaintyCategoriesLoading(true)
            setUsersLoading(true)

            axios.get(process.env.REACT_APP_API_URL + '/users')
            .then((res) => {
                setUsers(res.data)
                setUserId(res.data.find(user => user.emailId === AuthService.getCurrentUserName()).id)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUsersLoading(false);
            });

            axios.get(process.env.REACT_APP_API_URL + '/carbon_factors')
                .then((res) => {
                    setCarbonEmissionFactors(res.data)
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setCarbonFactorsLoading(false);
                });

            axios.get(process.env.REACT_APP_API_URL + '/select_options_footprints_uncertainty_categories')
                .then((res) => {
                    setUncertaintyCategories(res.data)

                    if (match.params.footprintItemId === "_add") {
                        setDataQualityTechnology(res.data.find(item => item.classification === "Poor" && item.field === "dataQualityTechnology").ratingDescription)
                        setDataQualityTechnologyClassification("Poor")
                        setDataQualityTechnologyFactor(res.data.find(item => item.classification === "Poor" && item.field === "dataQualityTechnology").ratingValue)

                        setDataQualityTime(res.data.find(item => item.classification === "Poor" && item.field === "dataQualityTime").ratingDescription)
                        setDataQualityTimeClassification("Poor")
                        setDataQualityTimeFactor(res.data.find(item => item.classification === "Poor" && item.field === "dataQualityTime").ratingValue)

                        setDataQualityGeography(res.data.find(item => item.classification === "Poor" && item.field === "dataQualityGeography").ratingDescription)
                        setDataQualityGeographyClassification("Poor")
                        setDataQualityGeographyFactor(res.data.find(item => item.classification === "Poor" && item.field === "dataQualityGeography").ratingValue)

                        setDataQualityCompleteness(res.data.find(item => item.classification === "Poor" && item.field === "dataQualityCompleteness").ratingDescription)
                        setDataQualityCompletenessClassification("Poor")
                        setDataQualityCompletenessFactor(res.data.find(item => item.classification === "Poor" && item.field === "dataQualityCompleteness").ratingValue)

                        setDataQualityReliability(res.data.find(item => item.classification === "Poor" && item.field === "dataQualityReliability").ratingDescription)
                        setDataQualityReliabilityClassification("Poor")
                        setDataQualityReliabilityFactor(res.data.find(item => item.classification === "Poor" && item.field === "dataQualityReliability").ratingValue)

                        setEmissionsFactorTechnology(res.data.find(item => item.classification === "Poor" && item.field === "emissionsFactorTechnology").ratingDescription)
                        setEmissionsFactorTechnologyClassification("Poor")
                        setEmissionsFactorTechnologyFactor(res.data.find(item => item.classification === "Poor" && item.field === "emissionsFactorTechnology").ratingValue)

                        setEmissionsFactorTime(res.data.find(item => item.classification === "Poor" && item.field === "emissionsFactorTime").ratingDescription)
                        setEmissionsFactorTimeClassification("Poor")
                        setEmissionsFactorTimeFactor(res.data.find(item => item.classification === "Poor" && item.field === "emissionsFactorTime").ratingValue)

                        setEmissionsFactorGeography(res.data.find(item => item.classification === "Poor" && item.field === "emissionsFactorGeography").ratingDescription)
                        setEmissionsFactorGeographyClassification("Poor")
                        setEmissionsFactorGeographyFactor(res.data.find(item => item.classification === "Poor" && item.field === "emissionsFactorGeography").ratingValue)

                        setEmissionsFactorCompleteness(res.data.find(item => item.classification === "Poor" && item.field === "emissionsFactorCompleteness").ratingDescription)
                        setEmissionsFactorCompletenessClassification("Poor")
                        setEmissionsFactorCompletenessFactor(res.data.find(item => item.classification === "Poor" && item.field === "emissionsFactorCompleteness").ratingValue)

                        setEmissionsFactorReliability(res.data.find(item => item.classification === "Poor" && item.field === "emissionsFactorReliability").ratingDescription)
                        setEmissionsFactorReliabilityClassification("Poor")
                        setEmissionsFactorReliabilityFactor(res.data.find(item => item.classification === "Poor" && item.field === "emissionsFactorReliability").ratingValue)

                        setBasicUncertainty("Thermal energy")
                        setBasicUncertaintyFactor("1.05")
                    }

                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setUncertaintyCategoriesLoading(false);
                });

            setBasicUncertaintyFactors([{
                "activityOrEmissionCategory": "Thermal energy",
                "basicUncertaintyFactor": "1.05"
            }, {
                "activityOrEmissionCategory": "Electricity",
                "basicUncertaintyFactor": "1.05"
            }, {
                "activityOrEmissionCategory": "Semi-finished products",
                "basicUncertaintyFactor": "1.05"
            }, {
                "activityOrEmissionCategory": "Raw materials",
                "basicUncertaintyFactor": "1.05"
            }, {
                "activityOrEmissionCategory": "Transport services",
                "basicUncertaintyFactor": "2"
            }, {
                "activityOrEmissionCategory": "Waste treatment services",
                "basicUncertaintyFactor": "1.05"
            }, {
                "activityOrEmissionCategory": "Infrastructure",
                "basicUncertaintyFactor": "3"
            }, {
                "activityOrEmissionCategory": "CO2 emissions",
                "basicUncertaintyFactor": "1.05"
            }, {
                "activityOrEmissionCategory": "Methane emissions from combustion",
                "basicUncertaintyFactor": "1.5"
            }, {
                "activityOrEmissionCategory": "Methane emissions from agriculture",
                "basicUncertaintyFactor": "1.2"
            }, {
                "activityOrEmissionCategory": "N2O emissions from combustion",
                "basicUncertaintyFactor": "1.5"
            }, {
                "activityOrEmissionCategory": "N2O emissions from agriculture",
                "basicUncertaintyFactor": "1.4"
            }, {
                "activityOrEmissionCategory": "N/A",
                "basicUncertaintyFactor": "1"
            }]
            )

            axios.get(process.env.REACT_APP_API_URL + '/events_footprints/' + match.params.footprintId)
                .then((res) => {
                    setFootprint(res.data)
                    setEventId(res.data.eventId)
                    setEventFootprintRef(res.data.eventFootprintRef)
                    setEventFootprintYear(res.data.eventFootprintYear)
                    setEventFootprintDate(res.data.eventFootprintDate)
                    setEventFootprintItems(res.data.eventFootprintItems)
                    setEventFootprintRecordConsolidation(res.data.eventFootprintRecordConsolidation)
                    setEventFootprintType(res.data.eventFootprintType)
                    //setEventFootprintNumberOfAttendees(res.data.eventFootprintNumberOfAttendees)
                    setEventFootprintLocation(res.data.eventFootprintLocation)
                    setEventFootprintNotes(res.data.eventFootprintNotes)
                    setEventFootprintCertification(res.data.eventFootprintCertification)
                    setEventFootprintConsultant(res.data.eventFootprintConsultant)
                    setEventFootprintClientContact(res.data.eventFootprintClientContact)
                    setEventFootprintVerifier(res.data.eventFootprintVerifier)
                    setEventFootprintTargetYear(res.data.eventFootprintTargetYear)
                    setEventFootprintOverallReductionTargets(res.data.eventFootprintOverallReductionTargets)
                    setEventNumberOfAttendees(res.data.eventFootprintNumberOfAttendees)
                    setEventTurnoverGBP(res.data.eventTurnoverGBP)
                    setEventFootprintObjectives(res.data.eventFootprintObjectives)
                    setEventFootprintScopeBoundaries(res.data.eventFootprintScopeBoundaries)
                    setEventFootprintLevelOfAssurance(res.data.eventFootprintLevelOfAssurance)
                    setEventFootprintMateriality(res.data.eventFootprintMateriality)
                    setEventFootprintVerificationActivitiesAndTechniques(res.data.eventFootprintVerificationActivitiesAndTechniques)

                    //if a scope item is being edited then set state for ItemID, Description etc for that scope item...
                    if (match.params.footprintItemId !== "_add") {
                        setEventFootprintItemId(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).id)
                        setEventFootprintItemDescription(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemDescription)
                        // setEventFootprintItemConsumption(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).consumption)
                        setFormattedConsumption(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).consumption)
                        setEventFootprintItemUnit(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).unit)
                        setEventFootprintItEmissionFactor(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionFactor)
                        setFormattedEmissions(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).carbonEmissions)
                        setEventFootprintItemCategory(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemCategory)
                        setEventFootprintItemSubCategory(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemSubCategory)
                        setEventFootprintItemConsultantComment(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemConsultantComment)
                        setEventFootprintItemTag(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemTag)
                        setDataType(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataType)
                        setBasicUncertainty(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).basicUncertainty)
                        setBasicUncertaintyFactor(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).basicUncertaintyFactor)

                        setDataQualityTechnology(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityTechnology)
                        setDataQualityTechnologyClassification(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityTechnologyClassification)
                        setDataQualityTechnologyFactor(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityTechnologyFactor)

                        setDataQualityTime(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityTime)
                        setDataQualityTimeClassification(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityTimeClassification)
                        setDataQualityTimeFactor(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityTimeFactor)

                        setDataQualityGeography(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityGeography)
                        setDataQualityGeographyClassification(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityGeographyClassification)
                        setDataQualityGeographyFactor(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityGeographyFactor)

                        setDataQualityCompleteness(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityCompleteness)
                        setDataQualityCompletenessClassification(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityCompletenessClassification)
                        setDataQualityCompletenessFactor(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityCompletenessFactor)

                        setDataQualityReliability(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityReliability)
                        setDataQualityReliabilityClassification(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityReliabilityClassification)
                        setDataQualityReliabilityFactor(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).dataQualityReliabilityFactor)

                        setEmissionsFactorSource(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorSource)


                        setEmissionsFactorTechnology(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorTechnology)
                        setEmissionsFactorTechnologyClassification(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorTechnologyClassification)
                        setEmissionsFactorTechnologyFactor(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorTechnologyFactor)

                        setEmissionsFactorTime(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorTime)
                        setEmissionsFactorTimeClassification(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorTimeClassification)
                        setEmissionsFactorTimeFactor(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorTimeFactor)

                        setEmissionsFactorGeography(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorGeography)
                        setEmissionsFactorGeographyClassification(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorGeographyClassification)
                        setEmissionsFactorGeographyFactor(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorGeographyFactor)

                        setEmissionsFactorCompleteness(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorCompleteness)
                        setEmissionsFactorCompletenessClassification(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorCompletenessClassification)
                        setEmissionsFactorCompletenessFactor(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorCompletenessFactor)

                        setEmissionsFactorReliability(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorReliability)
                        setEmissionsFactorReliabilityClassification(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorReliabilityClassification)
                        setEmissionsFactorReliabilityFactor(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).emissionsFactorReliabilityFactor)


                        setReductionControlCategory(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).reductionControlCategory)
                        setReductionCommentsOnControl(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).reductionCommentsOnControl)
                        setReductionTarget(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).reductionTarget)
                        setReductionTargetYear(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).reductionTargetYear)
                        setReductionMeasures(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).reductionMeasures)

                        setSubmitted(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).submitted)
                        setVerified(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).verified)
                        setSubmissionComment(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).submissionComment)
                        setVerificationComment(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).verificationComment)
                        setFilesUploaded(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).filesUploaded)
                        setReductionProjection(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).reductionProjection)
                        setReductionProjectionYear(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).reductionProjectionYear)
                        setNumberOfMonths(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).numberOfMonths)
                        setAdditionalEstimatedEmissions(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).additionalEstimatedEmissions)
                        setFootprintSubItems(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).footprintSubItems)
                        setReductionTargetPercentageOverride(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).reductionTargetPercentageOverride)
                        setCommentThread(res.data.eventFootprintItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).commentThread)
                    } else {

                        setEventFootprintItemId(uuidv4())
                    }
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setFootprintsLoading(false);
                });

        }

    }, [match.params.footprintId, match.params.footprintItemId, company, role, userPropertiesLoading])

    useEffect(() => {

        if (company && role && !userPropertiesLoading) {

            var oneMonthsActual = 0

            if (numberOfMonths < 12) {

                oneMonthsActual = (Number(removeCommas(consumption)) * Number(emissionFactor)) / numberOfMonths
                setAdditionalEstimatedEmissions(oneMonthsActual * (12 - numberOfMonths))

            } else {
                setAdditionalEstimatedEmissions(0)
            }

            // setEventFootprintItemCarbonEmissions(Number(removeCommas(consumption)) * Number(emissionFactor))

            setFormattedEmissions(Number(removeCommas(consumption)) * Number(emissionFactor))

        }

    }, [consumption, emissionFactor, numberOfMonths, company, role, userPropertiesLoading])

    if (carbonEmissionFactors.length > 0 && !userPropertiesLoading && role && company && !carbonFactorLoading && !footprintsLoading && !usersLoading && !uncertaintyCategoriesLoading && uncertaintyCategories.length > 0) {

        return (

            <div className='flex items-center bg-jungle-mist-100 min-h-screen'>
                <div className="container-form-8A">

                    <Header title="Events - Add / Edit Footprint Item" section="Event Footprint Item Details" url={role === 3001 || role === 6001 ? "/ViewClient/" + company : "/"}/>

                    <br></br>

                    <div className="flex items-center justify-between p-5 bg-white rounded-2xl shadow-md shadow-slate-200 border border-slate-200">
                        <div>
                            <div className="text-sm text-gray-500 ">Uploaded Documentary Evidence</div>
                            <div className="flex items-center pt-1">
                                <div className="text-xs font-medium text-indigo-500 w-11/12"><FileUploadAndDownload id={match.params.footprintItemId} onChange={setUpdatedNumberOfFilesUploaded} allowUploads={role === 4001 || role === 2004 || role === 6001 || role === 2001 ? true : false}/></div>
                            </div>
                        </div>
                        <div className="text-gray-400">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M5 19a2 2 0 01-2-2V7a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1M5 19h14a2 2 0 002-2v-5a2 2 0 00-2-2H9a2 2 0 00-2 2v5a2 2 0 01-2 2z" />
                            </svg>
                        </div>

                        {/* <input type="number" value={filesUploaded} onChange={(e) => setFilesUploaded(Number(e.target.value))} /> */}

                    </div>

                    <form className="add-form-8A" onSubmit={onSubmit}>

                        <div className="form-control-8A">
                            <label>Scope</label>
                            <select disabled = {submitted !== "" || verified !== "" || role === 5001 || role === 6002 || role === 2003 ? true : false} value={itemCategory} onChange={(e) => setEventFootprintItemCategory(e.target.value)}>
                                <option value="">Please Select...</option>
                                <option value="Upstream">Upstream</option>
                                <option value="Core">Core</option>
                                <option value="Downstream">Downstream</option>
                            </select>
                        </div>
                        <div className="form-control-8A">
                            <label>Scope Sub Category</label>
                            {
                                {
                                    "": <div>
                                        <select disabled = {submitted !== "" || verified !== "" || role === 5001 || role === 6002 || role === 2003 ? true : false} value={itemSubCategory} onClick={(e) => updateUncertaintyFields(e.target.value)} onChange={(e) => updateUncertaintyFields(e.target.value)}>
                                            <option value="">Please Select...</option>
                                        </select>
                                    </div>,
                                    "Upstream": <div>
                                        <select disabled = {submitted !== "" || verified !== "" || role === 5001 || role === 6002 || role === 2003 ? true : false} value={itemSubCategory} onClick={(e) => updateUncertaintyFields(e.target.value)} onChange={(e) => updateUncertaintyFields(e.target.value)}>
                                            <option value="">Please Select...</option>
                                            <option value="1.1 Assembling of the Event">1.1 Assembling of the Event</option>
                                            <option value="1.2 Electrical Equipment">1.2 Electrical Equipment</option>
                                            <option value="1.3 Consumables Production">1.3 Consumables Production</option>
                                            <option value="1.4 Marketing & Advertising">1.4 Marketing & Advertising</option>
                                            <option value="1.5 Services Transportation">1.5 Services Transportation</option>
                                        </select>
                                    </div>,
                                    "Core": <div>
                                        <select disabled = {submitted !== "" || verified !== "" || role === 5001 || role === 6002 || role === 2003 ? true : false} value={itemSubCategory} onClick={(e) => updateUncertaintyFields(e.target.value)} onChange={(e) => updateUncertaintyFields(e.target.value)}>
                                            <option value="">Please Select...</option>
                                            <option value="2.1 Event Operations">2.1 Event Operations</option>
                                            <option value="2.2 Water Waste & Treatment">2.2 Water Waste & Treatment</option>
                                            <option value="2.3 Food & Beverage">2.3 Food & Beverage</option>
                                            <option value="2.4 Staff Transportation & Accommodation">2.4 Staff Transportation & Accommodation</option>
                                            <option value="2.5 Attendee Transportation & Accommodation">2.5 Attendee Transportation & Accommodation</option>
                                        </select>
                                    </div>,
                                    "Downstream": <div>
                                        <select disabled = {submitted !== "" || verified !== "" || role === 5001 || role === 6002 || role === 2003 ? true : false} value={itemSubCategory} onClick={(e) => updateUncertaintyFields(e.target.value)} onChange={(e) => updateUncertaintyFields(e.target.value)}>
                                            <option value="" >Please Select...</option>
                                            <option value="3.1 Dismantling and Waste of the Event">3.1 Dismantling and Waste of the Event</option>
                                            <option value="3.2 Operational Waste">3.2 Operational Waste</option>
                                        </select>
                                    </div>
                                }[itemCategory]
                            }
                        </div>
                        <div className="form-control-8A">
                            <label>Footprint Item Description</label>
                            <input readOnly = {submitted !== "" || verified !== "" || role === 5001 || role === 6002 || role === 2003 ? true : false} type="text" placeholder="Enter Footprint Item Description Here" value={itemDescription} onChange={(e) => setEventFootprintItemDescription(e.target.value)} />
                        </div>
                        {footprintSubItems.length === 0 &&
                            <div>
                                <div className="form-control-8A">
                                    <label>Consumption</label>
                                    <input readOnly = {submitted !== "" || verified !== "" || role === 5001 || role === 6002 || role === 2003 ? true : false} type="text" placeholder="Enter Consumption Here" value={consumption} onChange={handleChangeForConsumption} />
                                </div>
                                <div className="form-control-8A">
                                    <label>Unit</label>
                                    {/* <input type="text" placeholder="Enter Unit Here" value={unit} onChange={(e) => setEventFootprintItemUnit(e.target.value)} /> */}
                                    <select disabled = {submitted !== "" || verified !== "" || role === 5001 || role === 6002 || role === 2003 ? true : false} value={unit} onChange={(e) => setEventFootprintItemUnit(e.target.value)}>
                                        <option value="">Please Select...</option>
                                        <option value="kg">kg</option>
                                        <option value="tonnes">tonnes</option>
                                        <option value="kWh">kWh</option>
                                        <option value="miles">miles</option>
                                        <option value="km">km</option>
                                        <option value="passenger.km">passenger.km</option>
                                        <option value="tonne.km">tonne.km</option>
                                        <option value="litres">litres</option>
                                        <option value="cubic metres">cubic metres</option>
                                        <option value="GJ">GJ</option>
                                        <option value="GBP">GBP</option>
                                        <option value="EUR">EUR</option>
                                        <option value="USD">USD</option>
                                        <option value="other">other</option>
                                        <option value="various">various</option>
					<option value="kgCO2e">kgCO2e</option>
                                    </select>
                                </div>
                                {role !== 6001 &&
                                <Button color="black" text="Collect Carbon Factors from Database" onClick={() => viewCarbonFactors()} />
                                }
                                <div className="form-control-8A">
                                    <label>Emission Factor</label>
                                    <input readOnly = {submitted !== "" || verified !== "" || role === 5001 || role === 6002 || role === 2003 ? true : false} type="number" placeholder="Enter Emission Factor Here" value={emissionFactor} onChange={(e) => setEventFootprintItEmissionFactor(Number(e.target.value))} />
                                </div>
                                <div className="form-control-8A">
                                    <label>Emissions Factor Source</label>
                                    <input readOnly = {submitted !== "" || verified !== "" || role === 5001 || role === 6002 || role === 2003 ? true : false} type="text" placeholder="Enter Emission Factor Source Here" value={emissionsFactorSource} onChange={(e) => setEmissionsFactorSource(e.target.value)} />
                                </div>
                                <div className="form-control-8A">
                                    <label>Carbon Emissions (kgCO2e)</label>
                                    <input readOnly = {submitted !== "" || verified !== "" || role === 5001 || role === 6002 || role === 2003 ? true : false} type="text" placeholder="Enter Carbon Emissions Here" value={carbonEmissions} onChange={handleChangeForEmissions} />
                                </div>
                                {match.params.footprintItemId !== "_add" &&
                                    <Button color="black" text="Helper Tool for Sub Items" onClick={() => openHelperTool()} />
                                }
                                </div>
                        }
                        {footprintSubItems.length > 0 &&
                            <div>
                                {/* <div className="form-control-8A">
                                    <label>Consumption</label>
                                    <input className="bg-slate-200" readOnly={true} type="number" placeholder="Enter Consumption Here" value={consumption} onChange={(e) => setEventFootprintItemConsumption(Number(e.target.value))} />
                                </div> */}
                                <div className="form-control-8A">
                                    <label>Carbon Emissions (kgCO2e)</label>
                                    <input className="bg-slate-200" readOnly={true} type="text" placeholder="Enter Carbon Emissions Here" value={carbonEmissions} onChange={handleChangeForEmissions} />
                                </div>
                            </div>
                        }
                        <div className="form-control-8A">
                            <label>Consultant Comment</label>
                            <input readOnly = {submitted !== "" || verified !== "" || role === 5001 || role === 6002 || role === 2003 ? true : false} type="text" placeholder="Enter Consultant Comment Here" value={itemConsultantComment} onChange={(e) => setEventFootprintItemConsultantComment(e.target.value)} />
                        </div>
                        {/* <div className="form-control-8A">
                        <label>Item Tag</label>
                        <input type="text" placeholder="Enter Tag Here" value={itemTag} onChange={(e) => setEventFootprintItemTag(e.target.value)} />
                    </div> */}
                        {eventFootprintType === "Actual (Baseline)" && (
                            <div>
                                <p className="font-bold text-xl">Reduction Plan:</p>
                                <div className="form-control-8A">
                                    <label>Control Category</label>
                                    <select disabled = {submitted !== "" || verified !== "" || role === 5001 || role === 6002 || role === 2003 ? true : false} value={reductionControlCategory} onChange={(e) => setReductionControlCategory(e.target.value)}>
                                        <option value="">Please Select...</option>
                                        <option value="Full Control">Full Control</option>
                                        <option value="Partial Control">Partial Control</option>
                                        <option value="No Control">No Control</option>
                                        <option value="N/A">N/A</option>
                                    </select>
                                </div>
                                <div className="form-control-8A">
                                    <label>Reduction - Comments on Control</label>
                                    <input readOnly = {submitted !== "" || verified !== "" || role === 5001 || role === 6002 || role === 2003 ? true : false} type="text" placeholder="Enter Comments on Control Here" value={reductionCommentsOnControl} onChange={(e) => setReductionCommentsOnControl(e.target.value)} />
                                </div>

                                {/* <div className="form-control-8A">
                            <label>Reduction - Target %</label>
                            <input type="text" placeholder="Enter Reduction Target % Here" value={reductionTarget} onChange={(e) => setReductionTarget(e.target.value)} />
                        </div>
                        <div className="form-control-8A">
                            <label>Reduction - Target Year</label>
                            <input type="text" placeholder="Enter Reduction Target Year Here" value={reductionTargetYear} onChange={(e) => setReductionTargetYear(e.target.value)} />
                        </div> */}

                                <div className="form-control-8A">
                                    <label>Reduction Target Override % (use for intensity based target setting only)</label>
                                    <input readOnly = {submitted !== "" || verified !== "" || role === 5001 || role === 6002 || role === 2003 ? true : false} type="number" placeholder="Reduction Target Override" value={reductionTargetPercentageOverride} onChange={(e) => setReductionTargetPercentageOverride(Number(e.target.value))} />
                                </div>

                                <div className="form-control-8A">
                                    <label>Reduction - Forecast %</label>
                                    <input readOnly = {submitted !== "" || verified !== "" || role === 5001 || role === 6002 || role === 2003 ? true : false} type="text" placeholder="Enter Reduction Forecast % Here" value={reductionProjection} onChange={(e) => setReductionProjection(e.target.value)} />
                                </div>
                                <div className="form-control-8A">
                                    <label>Reduction - Forecast Year</label>
                                    <input readOnly = {submitted !== "" || verified !== "" || role === 5001 || role === 6002 || role === 2003 ? true : false} type="text" placeholder="Enter Reduction Forecast Year Here" value={reductionProjectionYear} onChange={(e) => setReductionProjectionYear(e.target.value)} />
                                </div>
                                <div className="form-control-8A">
                                    <label>Reduction - Measures</label>
                                    <input readOnly = {submitted !== "" || verified !== "" || role === 5001 || role === 6002 || role === 2003 ? true : false} type="text" placeholder="Enter Reduction Measures Here" value={reductionMeasures} onChange={(e) => setReductionMeasures(e.target.value)} />
                                </div>
                            </div>
                        )}

                        {(eventFootprintType === "Actual (Baseline)" || eventFootprintType === "Actual") && (
                            <div>

                                <p className="font-bold text-xl">Uncertainty:</p>
                                <div className="form-control-8A">
                                    <label>Data Type</label>
                                    <select disabled = {submitted !== "" || verified !== "" || role === 5001 || role === 6002 || role === 2003 ? true : false} value={dataType} onChange={(e) => setDataType(e.target.value)}>
                                        <option value="">Please Select...</option>
                                        <option value="Primary Data">Primary Data</option>
                                        <option value="Secondary Data">Secondary Data</option>
                                    </select>
                                </div>
                                <div className="form-control-8A">
                                    <label>Basic Uncertainty</label>
                                    <select disabled = {submitted !== "" || verified !== "" || role === 5001 || role === 6002 || role === 2003 ? true : false} value={basicUncertainty} onChange={(e) => { setBasicUncertainty(e.target.value); setBasicUncertaintyFactor(basicUncertaintyFactors.find(item => item.activityOrEmissionCategory === e.target.value).basicUncertaintyFactor) }}>
                                        {basicUncertaintyFactors.map((item) => (
                                            <option key={item.activityOrEmissionCategory} value={item.activityOrEmissionCategory}>{item.activityOrEmissionCategory}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className="form-control-8A">
                                    <label>Data Quality - Technology</label>
                                    <select disabled = {submitted !== "" || verified !== "" || role === 5001 || role === 6002 || role === 2003 ? true : false} value={dataQualityTechnology} onChange={(e) => { setDataQualityTechnology(e.target.value); setDataQualityTechnologyClassification(uncertaintyCategories.filter(item => item.field === "dataQualityTechnology").find(item => item.ratingDescription === e.target.value).classification); setDataQualityTechnologyFactor(uncertaintyCategories.filter(item => item.field === "dataQualityTechnology").find(item => item.ratingDescription === e.target.value).ratingValue) }}>
                                        {uncertaintyCategories.filter(item => item.field === "dataQualityTechnology").map((item) => (
                                            <option key={item.id} value={item.ratingDescription}>{item.ratingDescription}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className="form-control-8A">
                                    <label>Data Quality - Time</label>
                                    <select disabled = {submitted !== "" || verified !== "" || role === 5001 || role === 6002 || role === 2003 ? true : false} value={dataQualityTime} onChange={(e) => { setDataQualityTime(e.target.value); setDataQualityTimeClassification(uncertaintyCategories.filter(item => item.field === "dataQualityTime").find(item => item.ratingDescription === e.target.value).classification); setDataQualityTimeFactor(uncertaintyCategories.filter(item => item.field === "dataQualityTime").find(item => item.ratingDescription === e.target.value).ratingValue) }}>
                                        {uncertaintyCategories.filter(item => item.field === "dataQualityTime").map((item) => (
                                            <option key={item.id} value={item.ratingDescription}>{item.ratingDescription}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className="form-control-8A">
                                    <label>Data Quality - Geography</label>
                                    <select disabled = {submitted !== "" || verified !== "" || role === 5001 || role === 6002 || role === 2003 ? true : false} value={dataQualityGeography} onChange={(e) => { setDataQualityGeography(e.target.value); setDataQualityGeographyClassification(uncertaintyCategories.filter(item => item.field === "dataQualityGeography").find(item => item.ratingDescription === e.target.value).classification); setDataQualityGeographyFactor(uncertaintyCategories.filter(item => item.field === "dataQualityGeography").find(item => item.ratingDescription === e.target.value).ratingValue) }}>
                                        {uncertaintyCategories.filter(item => item.field === "dataQualityGeography").map((item) => (
                                            <option key={item.id} value={item.ratingDescription}>{item.ratingDescription}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className="form-control-8A">
                                    <label>Data Quality - Completeness</label>
                                    <select disabled = {submitted !== "" || verified !== "" || role === 5001 || role === 6002 || role === 2003 ? true : false} value={dataQualityCompleteness} onChange={(e) => { setDataQualityCompleteness(e.target.value); setDataQualityCompletenessClassification(uncertaintyCategories.filter(item => item.field === "dataQualityCompleteness").find(item => item.ratingDescription === e.target.value).classification); setDataQualityCompletenessFactor(uncertaintyCategories.filter(item => item.field === "dataQualityCompleteness").find(item => item.ratingDescription === e.target.value).ratingValue) }}>
                                        {uncertaintyCategories.filter(item => item.field === "dataQualityCompleteness").map((item) => (
                                            <option key={item.id} value={item.ratingDescription}>{item.ratingDescription}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className="form-control-8A">
                                    <label>Data Quality - Reliability</label>
                                    <select disabled = {submitted !== "" || verified !== "" || role === 5001 || role === 6002 || role === 2003 ? true : false} value={dataQualityReliability} onChange={(e) => { setDataQualityReliability(e.target.value); setDataQualityReliabilityClassification(uncertaintyCategories.filter(item => item.field === "dataQualityReliability").find(item => item.ratingDescription === e.target.value).classification); setDataQualityReliabilityFactor(uncertaintyCategories.filter(item => item.field === "dataQualityReliability").find(item => item.ratingDescription === e.target.value).ratingValue) }}>
                                        {uncertaintyCategories.filter(item => item.field === "dataQualityReliability").map((item) => (
                                            <option key={item.id} value={item.ratingDescription}>{item.ratingDescription}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className="form-control-8A">
                                    <label>Emissions Factor - Technology</label>
                                    <select disabled = {submitted !== "" || verified !== "" || role === 5001 || role === 6002 || role === 2003 ? true : false} value={emissionsFactorTechnology} onChange={(e) => { setEmissionsFactorTechnology(e.target.value); setEmissionsFactorTechnologyClassification(uncertaintyCategories.filter(item => item.field === "emissionsFactorTechnology").find(item => item.ratingDescription === e.target.value).classification); setEmissionsFactorTechnologyFactor(uncertaintyCategories.filter(item => item.field === "emissionsFactorTechnology").find(item => item.ratingDescription === e.target.value).ratingValue) }}>
                                        {uncertaintyCategories.filter(item => item.field === "emissionsFactorTechnology").map((item) => (
                                            <option key={item.id} value={item.ratingDescription}>{item.ratingDescription}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className="form-control-8A">
                                    <label>Emissions Factor - Time</label>
                                    <select disabled = {submitted !== "" || verified !== "" || role === 5001 || role === 6002 || role === 2003 ? true : false} value={emissionsFactorTime} onChange={(e) => { setEmissionsFactorTime(e.target.value); setEmissionsFactorTimeClassification(uncertaintyCategories.filter(item => item.field === "emissionsFactorTime").find(item => item.ratingDescription === e.target.value).classification); setEmissionsFactorTimeFactor(uncertaintyCategories.filter(item => item.field === "emissionsFactorTime").find(item => item.ratingDescription === e.target.value).ratingValue) }}>
                                        {uncertaintyCategories.filter(item => item.field === "emissionsFactorTime").map((item) => (
                                            <option key={item.id} value={item.ratingDescription}>{item.ratingDescription}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className="form-control-8A">
                                    <label>Emissions Factor - Geography</label>
                                    <select disabled = {submitted !== "" || verified !== "" || role === 5001 || role === 6002 || role === 2003 ? true : false} value={emissionsFactorGeography} onChange={(e) => { setEmissionsFactorGeography(e.target.value); setEmissionsFactorGeographyClassification(uncertaintyCategories.filter(item => item.field === "emissionsFactorGeography").find(item => item.ratingDescription === e.target.value).classification); setEmissionsFactorGeographyFactor(uncertaintyCategories.filter(item => item.field === "emissionsFactorGeography").find(item => item.ratingDescription === e.target.value).ratingValue) }}>
                                        {uncertaintyCategories.filter(item => item.field === "emissionsFactorGeography").map((item) => (
                                            <option key={item.id} value={item.ratingDescription}>{item.ratingDescription}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className="form-control-8A">
                                    <label>Emissions Factor - Completeness</label>
                                    <select disabled = {submitted !== "" || verified !== "" || role === 5001 || role === 6002 || role === 2003 ? true : false} value={emissionsFactorCompleteness} onChange={(e) => { setEmissionsFactorCompleteness(e.target.value); setEmissionsFactorCompletenessClassification(uncertaintyCategories.filter(item => item.field === "emissionsFactorCompleteness").find(item => item.ratingDescription === e.target.value).classification); setEmissionsFactorCompletenessFactor(uncertaintyCategories.filter(item => item.field === "emissionsFactorCompleteness").find(item => item.ratingDescription === e.target.value).ratingValue) }}>
                                        {uncertaintyCategories.filter(item => item.field === "emissionsFactorCompleteness").map((item) => (
                                            <option key={item.id} value={item.ratingDescription}>{item.ratingDescription}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className="form-control-8A">
                                    <label>Emissions Factor - Reliability</label>
                                    <select disabled = {submitted !== "" || verified !== "" || role === 5001 || role === 6002 || role === 2003 ? true : false} value={emissionsFactorReliability} onChange={(e) => { setEmissionsFactorReliability(e.target.value); setEmissionsFactorReliabilityClassification(uncertaintyCategories.filter(item => item.field === "emissionsFactorReliability").find(item => item.ratingDescription === e.target.value).classification); setEmissionsFactorReliabilityFactor(uncertaintyCategories.filter(item => item.field === "emissionsFactorReliability").find(item => item.ratingDescription === e.target.value).ratingValue) }}>
                                        {uncertaintyCategories.filter(item => item.field === "emissionsFactorReliability").map((item) => (
                                            <option key={item.id} value={item.ratingDescription}>{item.ratingDescription}</option>
                                        ))}
                                    </select>
                                </div>

                                <p className="font-bold text-xl">Verification:</p>

                                {submitted ==="" && role !== 5001 && role !== 6002 && role !== 2003 &&
                                <div className="pt-4">
                                    <Button text="Submit" onClick={() => submitItem()} />
                                </div>
                                }

                                {submitted !=="" &&

                                    <div className="form-control-8A">
                                        <div className="form-control-8A">
                                            <label>Submitted</label>
                                            <input readOnly = {role === 5001 || role === 6002 || role === 2003 ? true : false} type="date" placeholder="Enter Date Here" value={submitted} onChange={(e) => setSubmitted(e.target.value)} />
                                        </div>

                                    </div>

                                }

                                {/* {verified ==="" && submitted !=="" &&
                                <div className="pt-4">
                                    <Button text="Verify" onClick={() => verifyItem()} />
                                </div>
                                } */}

                                {/* {verified !=="" && submitted !=="" && */}
                                {/* <div className="form-control-8A">
                                    <div className="form-control-8A">
                                        <label>Verified</label>
                                        <input type="date" placeholder="Enter Date Here" value={verified} onChange={(e) => setVerified(e.target.value)} />
                                    </div>

                                </div> */}

                                <div className="form-control-8A">
                                    <label>Verification Status</label>
                                    <select disabled = {role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? true : false} value={verified} onChange={(e) => setVerified(e.target.value)}>
                                        <option value="">Please Select...</option>
                                        <option value="Compliant">Compliant</option>
                                        <option value="Response Required">Response Required</option>
                                        <option value="Recommendation">Recommendation</option>
                                        <option value="Non Compliant">Non Compliant</option>
                                    </select>
                                </div>
                                {/* } */}

                                {/* <div className="form-control-8A">
                                    <label>Last Submission Comment</label>
                                    <textarea className="bg-slate-100 w-full border-2" readOnly={true} type="text" placeholder="" value={submissionComment} onChange={(e) => setSubmissionComment(e.target.value)} />
                                </div>

                                <div className="form-control-8A">
                                    <label>Last Verification Comment</label>
                                    <textarea className="bg-slate-100 w-full border-2" readOnly={true} type="text" placeholder="" value={verificationComment} onChange={(e) => setVerificationComment(e.target.value)} />
                                </div> */}
                                {role !== 5001 && role !== 6002 && role !== 2003 &&
                                <div className="form-control-8A">
                                    <label>New Submission Comment</label>
                                    <textarea className="w-full border-2" type="text" placeholder="Enter New Comments Here" value={newSubmissionComment} onChange={(e) => setNewSubmissionComment(e.target.value)} />
                                </div>
                                }
                                {(role === 5001 || role === 6002 || role === 2003 || role === 2001) &&
                                <div className="form-control-8A">
                                    <label>New Verification Comment</label>
                                    <textarea className="w-full border-2" type="text" placeholder="Enter New Comments Here" value={newVerificationComment} onChange={(e) => setNewVerificationComment(e.target.value)} />
                                </div>
                                }

                                {newVerificationComment !== "" &&
                                <div className="form-control-8A">
                                    <label>Verification Status</label>
                                    <select value={newVerificationStatus} onChange={(e) => setNewVerificationStatus(e.target.value)}>
                                        <option value="">Please Select...</option>
                                        <option value="Compliant">Compliant</option>
                                        <option value="Response Required">Response Required</option>
                                        <option value="Recommendation">Recommendation</option>
                                        <option value="Non Compliant">Non Compliant</option>
                                    </select>
                                </div>
                                }

                                <div className="shadow-md shadow-slate-300 overflow-hidden rounded-2xl m-4">
                                    <table className="objects-8A min-w-full divide-y divide-gray-200">
    
                                        <thead className="bg-pacific-blue-50">
                                            <tr>
                                                <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Date</th>
                                                <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Type</th>
                                                <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> User</th>
                                                <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Comment</th>
                                            </tr>
                                        </thead>

                                        <tbody className="bg-white divide-y divide-gray-200">
                                            {commentThread.map((commentInThread) => (
                                                <tr key={commentInThread.id}>
                                                    <td className="px-4 md:px-6  py-2 hover:cursor-pointer"> {commentInThread.commentDate} </td>
                                                    <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {commentInThread.commentType} </td>
                                                    <td className="px-4 md:px-6  py-2 hover:cursor-pointer"> {commentInThread.commentUser !== "" ? users.find(user => user.id === commentInThread.commentUser).firstName + " " + users.find(user => user.id === commentInThread.commentUser).lastName : ""} </td>
                                                    <td className="px-4 md:px-6  py-2 hover:cursor-pointer"> {commentInThread.comment} </td>
                                                </tr>
                                            ))}
                                        </tbody>

                                    </table>
                                </div>

                            </div>
                        )}

                        <input type="submit" value="Save" className="btn-8A btn-block-8A" />

                    </form>
                    <Modal reportingYear={eventFootprintYear} tableData={carbonEmissionFactors} onChange={setSelectedFactor} onClose={() => setShowModal(false)} show={showModal} title="Browse Emission Factor Database" />
                </div>
            </div>
        )

    } else {

        return (
            <div className='flex bg-jungle-mist-100 min-h-screen'>
                <div className="container-form-8A">
                    <Header title="Events - Add / Edit Footprint Item" section="Event Footprint Item Details" url={role === 3001 || role === 6001 ? "/ViewClient/" + company : "/"}/>
                    <p>Data is loading...</p>
                </div>
            </div>)

    }

}

export default AddOrUpdateEventFootprintItem