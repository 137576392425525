import { useEffect, useState } from 'react'
import axios from 'axios'
import Button from '../UI/Button'
import Header from '../UI/Header'
import Options from '../UI/Options'
import { useNavigate, useMatch } from 'react-router-dom'

const ListBuildingOperationalCarbonEmissions = () => {


    const match = useMatch('/viewBuilding/:buildingId')

    const [loading, setLoading] = useState(false)

    const [buildingOperationalCarbonEmissions, setBuildingOperationalCarbonEmissions] = useState([])

    useEffect(() => {

        setLoading(true)

        axios.get(process.env.REACT_APP_API_URL + "/buildings_operational_carbon_emissions")
            .then((response) => {
                setBuildingOperationalCarbonEmissions(response.data.filter(item => item.buildingId === match.params.buildingId))
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });

    }, [])

    const navigate = useNavigate ()

    const deleteBuildingOperationalCarbonEmissions = (id) => {
        if (window.confirm('Are you sure you want to delete this Assessment?')) {
            axios.delete(process.env.REACT_APP_API_URL + '/buildings_operational_carbon_emissions/' + id)
            setBuildingOperationalCarbonEmissions(buildingOperationalCarbonEmissions.filter((assessment) => assessment.id !== id))
        }
    }

    const viewBuildingOperationalCarbonEmissions = (id) => {
        navigate(`/viewBuildingOperationalCarbonEmissionsAssessment/${match.params.buildingId}/${id}`);
    }

    return (
        <div className=''>
            <h1 className='pt-4 sm:px-6 lg:px-8 pb-2 mx-auto header-8A'>Operational Carbon Emissions</h1>

            <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full px-4 sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-jungle-mist-100 sm:rounded border-2">
                            <table className="objects-8A min-w-full divide-y divide-gray-200">
                                <thead className="bg-pacific-blue-50">
                                    <tr>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Address</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Area (m2)</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Electricity energy demand kwh/m2</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Gas energy demand kwh/m2</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Other fuel kwh/m2</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Renewable generation kWh/m2</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> RIBA Stage</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Year</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> CO2 emissions. Tonnes in CO2/m2</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Offsetting in tonnes CO2</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Operational Label​</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider md:pr-10 lg:pr-20 float-right "> Actions</th>
                                    </tr>
                                </thead>


                                <tbody className="bg-white divide-y divide-gray-200">
                                {buildingOperationalCarbonEmissions.map((buildingEAItem) => (
                                    <tr key={buildingEAItem.id}>
                                        <td onClick={() => viewBuildingOperationalCarbonEmissions(buildingEAItem.id)} className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer">{buildingEAItem.address}</td>
                                        <td onClick={() => viewBuildingOperationalCarbonEmissions(buildingEAItem.id)} className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer">{buildingEAItem.areaSqMetres}</td>
                                        <td onClick={() => viewBuildingOperationalCarbonEmissions(buildingEAItem.id)} className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer">{buildingEAItem.electricityEnergyDemand}</td>
                                        <td onClick={() => viewBuildingOperationalCarbonEmissions(buildingEAItem.id)} className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer">{buildingEAItem.gasEnergyDemand}</td>
                                        <td onClick={() => viewBuildingOperationalCarbonEmissions(buildingEAItem.id)} className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer">{buildingEAItem.otherFuel}</td>
                                        <td onClick={() => viewBuildingOperationalCarbonEmissions(buildingEAItem.id)} className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer">{buildingEAItem.renewableGeneration}</td>
                                        <td onClick={() => viewBuildingOperationalCarbonEmissions(buildingEAItem.id)} className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer">{buildingEAItem.RIBAStage}</td>
                                        <td onClick={() => viewBuildingOperationalCarbonEmissions(buildingEAItem.id)} className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer">{buildingEAItem.year}</td>
                                        <td onClick={() => viewBuildingOperationalCarbonEmissions(buildingEAItem.id)} className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer">{buildingEAItem.emissions}</td>
                                        <td onClick={() => viewBuildingOperationalCarbonEmissions(buildingEAItem.id)} className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer">{buildingEAItem.offsetting}</td>
                                        <td onClick={() => viewBuildingOperationalCarbonEmissions(buildingEAItem.id)} className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer">{buildingEAItem.operationalLabel}</td>
                                        <td className="px-4 md:px-6 py-2 whitespace-nowrap md:pr-10 lg:pr-20 float-right ">
                                        <Options deleteFunction={deleteBuildingOperationalCarbonEmissions} deleteObject={buildingEAItem.id} edit={`/addOrUpdateBuildingOperationalCarbonEmissions/${match.params.buildingId}/${buildingEAItem.id}`}></Options>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>

                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <br></br>
            <Button className='bg-bondi-blue' text="Add New Operational Carbon Emissions Assessment" onClick={() => navigate(`/addOrUpdateBuildingOperationalCarbonEmissions/${match.params.buildingId}/_add`)}/>

        </div>
    )

    /*     } else {
    
            return (
            <div>
                <Header title="BREEAM Assessments" section="" showLogo={false}/>
                <p>Data is loading...</p>
            </div>)
    
        } */

}

export default ListBuildingOperationalCarbonEmissions
