import { useEffect, useState } from 'react'
import axios from 'axios'
import Button from '../UI/Button'
import Options from '../UI/Options'
import { useNavigate, useMatch } from 'react-router-dom'


const ListBuildingOperationalCarbonEmissionsAssessmentItems = () => {

  const match = useMatch('/viewBuildingOperationalCarbonEmissionsAssessment/:buildingId/:buildingOperationalCarbonEmissionsAssessmentId')

  const [loading, setLoading] = useState(false)

  const [buildingOperationalCarbonEmissionsAssessmentItems, setBuildingOperationalCarbonEmissionsAssessmentItems] = useState([])
  const [buildingOperationalCarbonEmissions, setBuildingOperationalCarbonEmissions] = useState([])

  useEffect(() => {

    setLoading(true)

    axios.get(process.env.REACT_APP_API_URL + '/buildings_operational_carbon_emissions/' + match.params.buildingOperationalCarbonEmissionsAssessmentId)
      .then((res) => res.data)
      .then((res) => {
        setBuildingOperationalCarbonEmissionsAssessmentItems(res.operationalCarbonEmissionsAssessmentItems);
        setBuildingOperationalCarbonEmissions(res);

      })
      .catch((err) => {
        console.log(err);
      }).finally(() => {
        setLoading(false);
      });

  }, [match.params.buildingOperationalCarbonEmissionsAssessmentId])

  const navigate = useNavigate()

  const deleteBuildingOperationalCarbonEmissionsAssessmentItem = (id) => {

    //code here to get array index of scope item id=id

    const index = buildingOperationalCarbonEmissions.operationalCarbonEmissionsAssessmentItems.findIndex(item => item.buildingOperationalCarbonEmissionsAssessmentId === id)

    buildingOperationalCarbonEmissions.operationalCarbonEmissionsAssessmentItems.splice(index, 1);

    const form = { ...buildingOperationalCarbonEmissions}
    axios.put(process.env.REACT_APP_API_URL + '/buildings_operational_carbon_emissions/' + match.params.buildingLCAId, form)
    setBuildingOperationalCarbonEmissionsAssessmentItems(buildingOperationalCarbonEmissionsAssessmentItems.filter((item) => item.id !== id))
  }

  if (!loading) {

    return (
      <div>
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full px-4 sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-jungle-mist-100 sm:rounded border-2">
                <table className="objects-8A min-w-full divide-y divide-gray-200">
                  <thead className="bg-jungle-mist-200">
                    <tr>
                      <th scope="col" className="px-4 md:px-6 py-3 text-left text-sm font-medium text-congress-blue uppercase tracking-wider"> Meter Number</th>
                      <th scope="col" className="px-4 md:px-6 py-3 text-left text-sm font-medium text-congress-blue uppercase tracking-wider"> Metered Area Description</th>
                      <th scope="col" className="px-4 md:px-6 py-3 text-left text-sm font-medium text-congress-blue uppercase tracking-wider"> Metered Period - From</th>
                      <th scope="col" className="px-4 md:px-6 py-3 text-left text-sm font-medium text-congress-blue uppercase tracking-wider md:pr-10 lg:pr-20 float-right"> Actions</th>
                    </tr>
                  </thead>

                  <tbody className="m-5 bg-white divide-y divide-gray-200">
                    {buildingOperationalCarbonEmissionsAssessmentItems.map((item) => (
                      <tr key={item.id}>
                        <td className="px-2 md:px-3 py-2"> {item.meterNumber} </td>
                        <td className="px-2 md:px-3 py-2"> {item.meteredAreaDescription} </td>
                        <td className="px-2 md:px-3 py-2"> {item.meteredPeriodFrom} </td>
                        <td className="px-4 md:px-6 py-2 whitespace-nowrap md:pr-10 lg:pr-20 float-right ">
                        <Options deleteFunction={deleteBuildingOperationalCarbonEmissionsAssessmentItem} deleteObject={item.id} edit={`/addOrUpdateBuildingOperationalCarbonEmissionsAssessmentItem/${match.params.buildingId}/${match.params.buildingOperationalCarbonEmissionsAssessmentId}/${item.id}`} ></Options>
                        </td>
                      </tr>
                    ))}
                  </tbody>

                </table>
              </div></div></div></div>
        <br></br>
        <Button color="bondi-blue" text="Add New Operational Carbon Emissions Assessment Item" onClick={() => navigate(`/addOrUpdateBuildingOperationalCarbonEmissionsAssessmentItem/${match.params.buildingId}/${match.params.buildingOperationalCarbonEmissionsAssessmentId}/_add`)} />

      </div>
    )

  } else {

    return (
      <div>
        <p>Data is loading...</p>
      </div>)

  }

}

export default ListBuildingOperationalCarbonEmissionsAssessmentItems
