import { useEffect, useState } from 'react'
import axios from 'axios'
import Button from '../UI/Button'
import Header from '../UI/Header'
import Options from '../UI/Options'
import { useNavigate, useMatch } from 'react-router-dom'
import AuthService from '../../services/AuthService'
import { v4 as uuidv4 } from 'uuid'

const ListEventFootprints = () => {

    const match = useMatch('/viewEvent/:eventId')

    const internationalNumberFormat = new Intl.NumberFormat('en-US')

    const [loading, setLoading] = useState(false)

    const [refreshTrigger, setRefreshTrigger] = useState(false)

    const [eventFootprints, setEventFootprints] = useState([])

    const [company, setCompany] = useState("")
    const [role, setRole] = useState(0)
    const [userPropertiesLoading, setUserPropertiesLoading] = useState(true)

    const getUserProperties = async () => {

        await axios.get(process.env.REACT_APP_API_URL + "/current_user_properties")
            .then((response) => {
                setCompany(response.data.company)
                setRole(response.data.role)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUserPropertiesLoading(false);
            });

    }

    useEffect(() => {

        setUserPropertiesLoading(true)

        getUserProperties()

    }, [])

    useEffect(() => {

        if (company && role && !userPropertiesLoading) {

            setLoading(true)

            axios.get(process.env.REACT_APP_API_URL + '/events_footprints_by_eventId/' + match.params.eventId)
                .then((res) => {
                    role === 3001 && setEventFootprints(res.data.filter(item => item.eventFootprintType === "Actual" || item.eventFootprintType === "Actual (Baseline)").sort((item1, item2) => item1.eventFootprintYear < item2.eventFootprintYear ? -1 : item1.eventFootprintYear > item2.eventFootprintYear ? 1 : 0))
                    role !== 3001 && setEventFootprints(res.data.sort((item1, item2) => item1.eventFootprintYear < item2.eventFootprintYear ? -1 : item1.eventFootprintYear > item2.eventFootprintYear ? 1 : 0))

                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });

            setRefreshTrigger(false)

        }

    }, [refreshTrigger, company, role, userPropertiesLoading])

    const navigate = useNavigate()

    const deleteEventFootprint = (id) => {

        if (window.confirm('Are you sure you want to delete it?')) {

            if (eventFootprints.find(item => item.id === id).eventFootprintItems.length > 0) {

                window.alert("This footprint contains one or more footprint item(s).  Please remove footprint items before deleting!")

            } else {

                axios.delete(process.env.REACT_APP_API_URL + '/events_footprints/' + id)
                setEventFootprints(eventFootprints.filter((eventFootprint) => eventFootprint.id !== id))

            }

        }

    }

    const cloneEventFootprint = (id) => {

        const footprintToClone = eventFootprints.find(item => item.id === id)
        const newFootprint = { ...footprintToClone, eventFootprintRef: "Clone" }

        //Loop through all footprintItems and re-generate ids

        var item
        for (item = 0; item < newFootprint.eventFootprintItems.length; item++) {

            newFootprint.eventFootprintItems[item].id = uuidv4()
            newFootprint.eventFootprintItems[item].verified = ""
            newFootprint.eventFootprintItems[item].submitted = ""
            newFootprint.eventFootprintItems[item].filesUploaded = 0
            newFootprint.eventFootprintItems[item].submissionComment = ""
            newFootprint.eventFootprintItems[item].verificationComment = ""

        }

        addForm(newFootprint)

        setRefreshTrigger(true)

    }

    const editEventFootprint = (id) => {
        navigate(`/addOrUpdateEventFootprint/${match.params.eventId}/${id}`);
    }

    const viewEventFootprint = (id) => {
        navigate(`/viewEventFootprint/${match.params.eventId}/${id}`);
    }

    const addForm = async (form) => {
        await axios.post(process.env.REACT_APP_API_URL + '/events_footprints', form)
    }

    if (eventFootprints.length > 0 && !loading) {

        return (
            <div className="bg-slate-100">
                <h1 className='pt-4 px-3 pb-2 mx-auto header-8A'>Event Footprints</h1>
                <div className="flex flex-col">
                    <div className="-my-2 overflow-x-auto">
                        <div className="py-2 text-eight-text align-middle inline-block min-w-full px-3">
                            <div className="shadow-md shadow-slate-300 overflow-hidden rounded-2xl">
                                <table className="objects-8A min-w-full divide-y divide-gray-200">
 
                                    <thead className="bg-pacific-blue-50">
                                        <tr>
                                            {/* <th> Event Id</th> */}
                                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Event Footprint Ref</th>
                                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Event Footprint Year</th>
                                            {/* <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Event Footprint Date</th> */}
                                            {/* <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Event Footprint Type</th> */}
                                            {/* <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Event Footprint Location</th> */}
                                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Attendees</th>
                                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> tCO2e</th>
                                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> tCO2e per Attendee</th>
                                            {/* <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Uncertainty Rating</th> */}
                                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Certification</th>
                                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Actions</th>
                                        </tr>
                                    </thead>

                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {eventFootprints.filter((eventFootprint) => eventFootprint.eventId === match.params.eventId).map((eventFootprint) => (
                                            <tr key={eventFootprint.id}>
                                                {/* <td> {eventFootprint.eventId} </td> */}
                                                <td onClick={() => viewEventFootprint(eventFootprint.id)} className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {eventFootprint.eventFootprintRef} </td>
                                                <td onClick={() => viewEventFootprint(eventFootprint.id)} className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {eventFootprint.eventFootprintYear}</td>
                                                {/* <td onClick={() => viewEventFootprint(eventFootprint.id)} className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {eventFootprint.eventFootprintDate}</td> */}
                                                {/* <td onClick={() => viewEventFootprint(eventFootprint.id)} className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {eventFootprint.eventFootprintType}</td> */}
                                                {/* <td onClick={() => viewEventFootprint(eventFootprint.id)} className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {eventFootprint.eventFootprintLocation}</td> */}
                                                <td onClick={() => viewEventFootprint(eventFootprint.id)} className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {eventFootprint.eventFootprintNumberOfAttendees}</td>
                                                <td onClick={() => viewEventFootprint(eventFootprint.id)} className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {`${internationalNumberFormat.format((((eventFootprint.eventFootprintItems.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)))/1000).toFixed(0))}`}</td>
                                                <td onClick={() => viewEventFootprint(eventFootprint.id)} className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {`${internationalNumberFormat.format((((eventFootprint.eventFootprintItems.map(item => item.carbonEmissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)) / eventFootprint.eventFootprintNumberOfAttendees)/1000).toFixed(3))}`}</td>
                                                {/* <td onClick={() => viewEventFootprint(eventFootprint.id)} className="px-4 md:px-6 py-2 whitespace-nowrap hover:cursor-pointer"> <svg className="inline-block fill-current text-lime" width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 18a6 6 0 100-12 6 6 0 000 12z"/></svg><div className="inline-block pr-1">Very Good</div></td> */}
                                                <td onClick={() => viewEventFootprint(eventFootprint.id)} className="px-4 md:px-6 py-2 hover:cursor-pointer text-xs ">
                                                    {eventFootprint.eventFootprintCertification === "Carbon Measured" ? <div className="grid grid-cols-2 gap-3"><div className="float-left w-16"><img className="p-1 h-14 w-14" src={"/images/CM-event.png"}></img></div><div>Carbon Measured</div></div> : <></>}
                                                    {eventFootprint.eventFootprintCertification === "Lower Carbon Committed" ? <div className="grid grid-cols-2 gap-3"><div className="float-left w-16"><img className="p-1 h-14 w-14" src={"/images/LCC-Event.png"}></img></div><div>Carbon Neutral</div></div> : <></>}
                                                    {eventFootprint.eventFootprintCertification === "Lower Carbon" ? <div className="grid grid-cols-2 gap-3"><div className="float-left w-16"><img className="p-1 h-14 w-14" src={"/images/LC-Event.png"}></img></div><div>Lower Carbon</div></div> : <></>}
                                                    {eventFootprint.eventFootprintCertification === "Net Zero Carbon Committed" ? <div className="grid grid-cols-2 gap-3"><div className="float-left w-16"><img className="p-1 h-14 w-14" src={"/images/NZCC-Event.png"}></img></div><div>Net Zero Carbon Committed</div></div> : <></>}
                                                    {eventFootprint.eventFootprintCertification === "Net Zero Carbon" ? <div className="grid grid-cols-2 gap-3"><div className="float-left w-16"><img className="p-1 h-14 w-14" src={"/images/NZC-event.png"}></img></div><div>Net Zero Carbon</div></div> : <></>}
                                                    {eventFootprint.eventFootprintCertification === "Climate Positive" ? <div className="grid grid-cols-2 gap-3"><div className="float-left w-16"><img className="p-1 h-14 w-14" src={"/images/CP-event.png"}></img></div><div>Climate Positive</div></div> : <></>}</td>

                                                <td className="px-4 md:px-6 py-2 whitespace-nowrap md:pr-5">
                                                    { role !== 3001 && role !== 5001 && role !== 6002 && role !== 2003 ? 
                                                    <Options edit={`/addOrUpdateEventFootprint/${match.params.eventId}/${eventFootprint.id}`} deleteFunction={deleteEventFootprint} deleteObject={eventFootprint.id} cloneFunction={cloneEventFootprint} cloneObject={eventFootprint.id}></Options>
                                                     : <Options edit={`/addOrUpdateEventFootprint/${match.params.eventId}/${eventFootprint.id}`} ></Options>
                                                    }
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <br></br>
                {role !== 3001 && role !== 5001 && role !== 6002 && role !== 2003 ? <Button color="bondi-blue" text="Add New Event Footprint" onClick={() => navigate(`/addOrUpdateEventFootprint/${match.params.eventId}/_add`)} /> : <></>}
            </div>
        )

    } else {

        return (
            <div>
                {/* <Header title="Event Footprints" section="" showLogo={false} /> */}
                <br></br>
                {role && role !== 3001 && role !== 5001 && role !== 6002 && role !== 2003 ? <Button color="bondi-blue" text="Add New Event Footprint" onClick={() => navigate(`/addOrUpdateEventFootprint/${match.params.eventId}/_add`)} /> : <></>}
            </div>)

    }

}

export default ListEventFootprints
