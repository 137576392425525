import { useState, useEffect } from 'react'
import axios from 'axios'
import Header from '../UI/Header'
import { useNavigate, useMatch } from 'react-router-dom'

const AddOrUpdateBuildingEnvironmentalAssessment = () => {

    const match = useMatch('/addOrUpdateBuildingEnvironmentalAssessment/:buildingId/:buildingEnvironmentalAssessmentId')

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [buildingId, setBuildingId] = useState('')
    const [address, setAddress] = useState('')
    const [environmentalAssessment, setEnvironmentalAssessment] = useState('')
    const [areaSqMetres, setAreaSqMetres] = useState('')
    const [environmentalRating, setEnvironmentalRating] = useState([])
    const [certificationDate, setCertificationDate] = useState([])
    const [expiryDate, setExpiryDate] = useState([])

    useEffect(() => {

        setBuildingId(match.params.buildingId)

        if (match.params.buildingEnvironmentalAssessmentId !== "_add") {

            setLoading(true)

            axios.get(process.env.REACT_APP_API_URL + '/buildings_environmental_assessments/' + match.params.buildingEnvironmentalAssessmentId)
                .then((res) => res.data)
                .then((res) => {
                    setBuildingId(res.buildingId)
                    setAddress(res.address)
                    setEnvironmentalAssessment(res.environmentalAssessment)
                    setAreaSqMetres(res.areaSqMetres)
                    setEnvironmentalRating(res.environmentalRating)
                    setCertificationDate(res.certificationDate)
                    setExpiryDate(res.expiryDate)

                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });

        }
    }, [match.params.buildingEnvironmentalAssessmentId, match.params.buildingId])

    const addForm = async (form) => {
        await axios.post(process.env.REACT_APP_API_URL + '/buildings_environmental_assessments', form)
    }

    const updateForm = async (form) => {
        await axios.put(process.env.REACT_APP_API_URL + '/buildings_environmental_assessments/' + match.params.buildingEnvironmentalAssessmentId, form)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (match.params.buildingEnvironmentalAssessmentId !== "_add") {
            updateForm({ buildingId, address, environmentalAssessment, areaSqMetres, environmentalRating, certificationDate, expiryDate }).then(res => { navigate(-1) })
        } else {
            addForm({ buildingId, address, environmentalAssessment, areaSqMetres, environmentalRating, certificationDate, expiryDate }).then(res => { navigate(-1) })
        }
    }

    if (!loading) {

        return (

                <div className='flex items-center bg-jungle-mist-100 min-h-screen'>
                    <div className="container-form-8A">

                    <Header title="Building Environmental Assessment - Add / Edit Building Environmental Assessment" section="Building Environmental Assessment Main Details" iconFileName="Building-Hover"/>

                    <form className="add-form-8A" onSubmit={onSubmit}>

                        <div className="form-control-8A">
                            <label>Building Address</label>
                            <input type="text" placeholder="Enter Address Here" value={address} onChange={(e) => setAddress(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Area (m2)</label>
                            <input type="text" placeholder="Enter Area (m2) Here" value={areaSqMetres} onChange={(e) => setAreaSqMetres(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Environmental Assessment</label>
                            <input type="text" placeholder="Enter Environmental Assessment Here" value={environmentalAssessment} onChange={(e) => setEnvironmentalAssessment(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Environmental Rating</label>
                            <input type="text" placeholder="Enter Environmental Rating Here" value={environmentalRating} onChange={(e) => setEnvironmentalRating(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Certification Date</label>
                            <input type="date" placeholder="Enter Area (m2) Here" value={certificationDate} onChange={(e) => setCertificationDate(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Expiry Date</label>
                            <input type="date" placeholder="Enter Expiry Date Here" value={expiryDate} onChange={(e) => setExpiryDate(e.target.value)} />
                        </div>



                        <input type="submit" value="Save" className="btn-8A btn-block-8A" />

                    </form>

                </div>
            </div>
        )

    } else {

        return (
            <div className='flex items-center bg-jungle-mist-100 min-h-screen'>
                <div className="container-form-8A">
                    <Header title="Building EnvironmentalAssessment - Add / Edit Building EnvironmentalAssessment" section="Building EnvironmentalAssessment Main Details" iconFileName="Building-Hover" />
                    <p>Data is loading...</p>
                </div>
            </div>)

    }

}

export default AddOrUpdateBuildingEnvironmentalAssessment