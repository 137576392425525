import Header from '../UI/Header'
import { useState, useEffect } from 'react'
import { useMatch, useNavigate } from 'react-router-dom'
import ListBuildingBREEAMCredits from './ListBuildingBREEAMCredits'
import ListBuildingBREEAMCreditsInformationSchedule from './ListBuildingBREEAMCreditsInformationSchedule'
import Sidebar from '../UI/Sidebar'
import axios from 'axios'
import Button from '../UI/Button'
import ModalUpdateValue from '../UI/ModalUpdateValue'
import ModalDownload from '../UI/ModalDownload'

const ViewBuildingBREEAMAssessment = (props) => {

    const match = useMatch('/viewBuildingBREEAMAssessment/:buildingId/:assessmentId')

    const [sidebarToggle, setSidebarToggle] = useState(false)
    const [copyingView, setCopyingView] = useState(false)

    const [loading, setLoading] = useState(true)
    const [usersLoading, setUsersLoading] = useState(true)
    const [contactsLoading, setContactsLoading] = useState(true)

    const [tabToShow, setTabToShow] = useState("Score & Tracker")

    const [buildingBREEAMAssessment, setBuildingBREEAMAssessment] = useState([])

    const [users, setUsers] = useState([])
    const [contacts, setContacts] = useState([])

    const [clientId, setClientId] = useState('')

    const [allTeamMembers, setAllTeamMembers] = useState([])

    const navigate = useNavigate()

    const [company, setCompany] = useState("")
    const [role, setRole] = useState(0)
    const [userPropertiesLoading, setUserPropertiesLoading] = useState(true)
    const [selectedStakeholder, setSelectedStakeholder] = useState(true)

    const [showModal, setShowModal] = useState(false)
    const [showDownloadModal, setShowDownloadModal] = useState(false)
    const [valueToSendToModal, setValueToSendToModal] = useState('')
    const [objectIdToSendToModal, setObjectIdToSendToModal] = useState('')
    const [fieldToSendToModal, setFieldToSendToModal] = useState('')
    const [collectionToSendToModal, setCollectionToSendToModal] = useState('')
    const [reportTypeToSendToModal, setReportTypeToSendToModal] = useState('')
    const [modalClosed, setModalClosed] = useState(false)

    const getUserProperties = async () => {

        await axios.get(process.env.REACT_APP_API_URL + "/current_user_properties")
            .then((response) => {
                setCompany(response.data.company)
                setRole(response.data.role)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUserPropertiesLoading(false);
            });

    }

    const setStakeholder = (e) => {

        setSelectedStakeholder(e)

    }

    const generateComplianceLetter = () => {

        if (selectedStakeholder.length > 1) {

            window.alert("Please select ONLY ONE stakeholder from the RESPONSIBILITY filter below")

        } else if (selectedStakeholder.length === 0) {

            window.alert("Please select ONE stakeholder from the RESPONSIBILITY filter below")

        } else {

            //window.open(`/ViewBREEAMComplianceLetter/` + match.params.buildingId + "/" + match.params.assessmentId + "/" + selectedStakeholder[0].value,'_blank')
            setReportTypeToSendToModal("ComplianceLetter")
            setShowDownloadModal(true)
            console.log("ComplianceLetter")
        }

    }

    const generateSiteVisitReport = () => {

        setReportTypeToSendToModal("SiteVisitReport")
        setShowDownloadModal(true)
        console.log("SiteVisitReport")

    }

    // const generateInformationSchedule = () => {

    //     if (selectedStakeholder.length > 1 && selectedStakeholder.length < allTeamMembers.length) {

    //         window.alert("Please select ONLY ONE stakeholder or ALL stakeholders from the RESPONSIBILITY filter below")

    //     } else if (selectedStakeholder.length === 0) {

    //         window.alert("Please select ONE stakeholder or ALL stakeholders from the RESPONSIBILITY filter below")

    //     } else if (selectedStakeholder.length === 1) {

    //         window.open(`/viewBREEAMInformationScheduleReport/` + match.params.buildingId + "/" + match.params.assessmentId + "/" + selectedStakeholder[0].value,'_blank')
         
    //     } else if (selectedStakeholder.length === allTeamMembers.length) {

    //         window.open(`/viewBREEAMInformationScheduleReport/` + match.params.buildingId + "/" + match.params.assessmentId + "/All",'_blank')
        
    //     }

    // }

    const selectText = (nodeId) => {
        const node = document.getElementById(nodeId);

        setCopyingView(!copyingView)

        if (!copyingView) {
    
            if (document.body.createTextRange) {
                const range = document.body.createTextRange();
                range.moveToElementText(node);
                range.select();
            } else if (window.getSelection) {
                const selection = window.getSelection();
                const range = document.createRange();
                range.selectNodeContents(node);
                selection.removeAllRanges();
                selection.addRange(range);
            } else {
                console.warn("Could not select text in node: Unsupported browser.");
            }

        } else {

            if (window.getSelection) {window.getSelection().removeAllRanges();}
            else if (document.selection) {document.selection.empty();}

        }

    }

    const openVersatilityProject = async (projectNumber) => {

        await axios.get(process.env.REACT_APP_API_URL + '/projects_by_project_number/' + projectNumber)
        .then((res) => {

            console.log(res)
            if (res.data) {

                window.open("/viewClientProject/" + res.data.id)

            } else {

                window.alert("Project Number " + projectNumber + " does not exist")
            
            }
        })
        .catch((err) => {
            window.alert("Project Number " + projectNumber + " does not exist")
            console.log(err);
        })

    }

    useEffect(() => {

        setUserPropertiesLoading(true)

        getUserProperties()

    }, [])

    useEffect(() => {

        if (company && role && !userPropertiesLoading) {

            axios.get(process.env.REACT_APP_API_URL + '/buildings/' + match.params.buildingId)
                .then((res) => {
                    setClientId(res.data.clientId)
                    if ((role === 3001 || role === 6001) && res.data.clientId !== company) {
                        navigate(`/errorPage`)
                    }
                })
                .catch((err) => {
                    console.log(err);
                })

            axios.get(process.env.REACT_APP_API_URL + '/buildings_BREEAM_assessments/' + match.params.assessmentId)
                .then((res) => {
                    if (res.data.buildingId !== match.params.buildingId) {
                        navigate(`/errorPage`)
                    }
                    setBuildingBREEAMAssessment(res.data)
                    setAllTeamMembers(res.data.projectTeam.map((item) => {
                        return {
                            label: item.role + " (" + item.name + ")",
                            value: item.role
                        }
                    }))

                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });

            axios.get(process.env.REACT_APP_API_URL + '/users')
                .then((res) => {
                    setUsers(res.data)
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setUsersLoading(false);
                });

            setContactsLoading(true)

            axios.get(process.env.REACT_APP_API_URL + '/contacts')
                .then((res) => {
                    setContacts(res.data)
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setContactsLoading(false);
                });
        }

    }, [match.params.buildingId, match.params.assessmentId, role, company, userPropertiesLoading])

    useEffect(() => {

        if (modalClosed === true) {

            setReportTypeToSendToModal('')
            setModalClosed(false)
            console.log(reportTypeToSendToModal)

        }

    }, [modalClosed])

    if (!loading && !userPropertiesLoading && role && company && !usersLoading && users.length > 0 && contacts.length >0 && !contactsLoading) {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="BREEAM Assessment" section="BREEAM Assessment Main Details" showUserMenu='true' url={role === 3001 || role === 6001 ? "/ViewClient/" + company : "/"}/>

                        <div><div className="flex items-center">
                            <div className="w-full mx-auto mb-5 px-3">
                                <div className="grid gap-2 sm:gap-7 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">

                                    <div className="flex items-center grid-cols-1 sm:col-span-1 justify-between p-2 md:p-4 bg-white rounded-2xl shadow-md shadow-slate-300">
                                        <div>
                                            <div className="text-sm text-gray-500 ">BREEAM Project Name: </div>
                                            <div className="flex items-center">
                                                <div className="text-xl font-medium text-pacific-blue "><p className="my-1">{buildingBREEAMAssessment.projectName}</p></div>
                                            </div>
                                        </div>
                                        <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                                            </svg>
                                        </div>
                                    </div>

                                    <div className="flex items-center grid-cols-1 sm:col-span-1 justify-between p-2 md:p-4 bg-white rounded-2xl shadow-md shadow-slate-300">
                                        <div>
                                            <div className="text-sm text-gray-500 ">BREEAM Project Number: </div>
                                            <div className="flex items-center">
                                                <div className="text-xl font-medium text-pacific-blue hover:cursor-pointer"><p onClick={() => {openVersatilityProject(buildingBREEAMAssessment.projectNumber)}} className="my-1"><svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 float-left" viewBox="0 0 20 20" fill="gray">
                                                                <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                                                                <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                                                            </svg>{buildingBREEAMAssessment.projectNumber}</p></div>
                                            </div>
                                        </div>
                                        <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                                            </svg>
                                        </div>
                                    </div>

                                    <div className="flex items-center grid-cols-1 sm:col-span-1 justify-between p-2 md:p-4 bg-white rounded-2xl shadow-md shadow-slate-300">
                                        <div>
                                            <div className="text-sm text-gray-500 ">Building Type: </div>
                                            <div className="flex items-center">
                                                <div className="text-xl font-medium text-pacific-blue "><p className="my-1">{buildingBREEAMAssessment.buildingType}</p></div>
                                            </div>
                                        </div>
                                        <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                                            </svg>
                                        </div>
                                    </div>

                                    <div className="flex items-center grid-cols-1 sm:col-span-1 justify-between p-2 md:p-4 bg-white rounded-2xl shadow-md shadow-slate-300">
                                        <div>
                                            <div className="text-sm text-gray-500 ">Assessment Type: </div>
                                            <div className="flex items-center">
                                                <div className="text-xl font-medium text-pacific-blue "><p className="my-1">{buildingBREEAMAssessment.assessmentType}</p></div>
                                            </div>
                                        </div>
                                        <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                                            </svg>
                                        </div>
                                    </div>

                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                        <div>
                                            <div className="text-sm text-gray-500">Assessment Notes</div>
                                                <div className="flex items-center pt-1">
                                                    <div onClick={() => { setShowModal(true); setValueToSendToModal(buildingBREEAMAssessment.comments); setFieldToSendToModal("BREEAMAssessmentComments"); setObjectIdToSendToModal(match.params.assessmentId); setCollectionToSendToModal("buildings_BREEAM_assessments") }} className="text-sm font-medium text-pacific-blue hover:cursor-pointer text-ellipsis overflow-hidden ... h-16"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                        <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                        <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                        </svg> {buildingBREEAMAssessment.comments}
                                                    </div>
                                            </div>
                                        </div>
                                        <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
                                            </svg>
                                        </div>
                                    </div>

                                    <div className="flex items-center grid-cols-1 sm:col-span-1 justify-between p-2 md:p-4 bg-white rounded-2xl shadow-md shadow-slate-300">
                                        <div>
                                            <div className="text-sm text-gray-500 ">BREEAM Project Stage: </div>
                                            <div className="flex items-center">
                                                <div className="text-xl font-medium text-pacific-blue "><p className="my-1">{buildingBREEAMAssessment.stage}</p></div>
                                            </div>
                                        </div>
                                        <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                                            </svg>
                                        </div>
                                    </div>

                                    <div className="flex items-center grid-cols-1 sm:col-span-2 justify-between p-2 md:p-4 bg-white rounded-2xl shadow-md shadow-slate-300">
                                        <div>
                                            <div className="text-sm text-gray-500 ">Reports: </div>
                                            {/* <Button color="bondi-blue" text="Site Visit Report" onClick={() => window.open(`/viewBREEAMSiteVisitReport/` + match.params.buildingId + "/" + match.params.assessmentId,'_blank')} /> */}
                                            <Button color="bondi-blue" text="Site Visit Report" onClick={() => generateSiteVisitReport()} />
                                            <Button color="bondi-blue" text="Compliance Letter" onClick={() => generateComplianceLetter()} />
                                            <Button color="bondi-blue" text="Chart Dashboard" onClick={() => window.open(`/dashboardBREEAMAssessments/` + match.params.buildingId + "/" + match.params.assessmentId,'_blank')} />
                                            {copyingView && tabToShow === "Information Schedule" && 
                                            <Button color="black" text="Toggle Data Select for Copy" onClick={() => selectText("exportContent")} />
                                            }
                                            {!copyingView && tabToShow === "Information Schedule" &&
                                            <Button color="bondi-blue" text="Toggle Data Select for Copy" onClick={() => selectText("exportContent")} />
                                            }
                                        </div>
                                        <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                                            </svg>
                                        </div>
                                    </div>

                                    <button onClick={() => setTabToShow("Score & Tracker")} className={ tabToShow === "Score & Tracker" ? "SubmitButton col-span-2 rounded-full m-1 p-2 text-white t-10 bg-pacific-blue-600 text-center" : "SubmitButton col-span-2 rounded-full m-1 p-2 text-white t-10 bg-pacific-blue-300 text-center"}>Score & Tracker</button>

                                    <button onClick={() => setTabToShow("Information Schedule")}className={ tabToShow === "Information Schedule" ? "SubmitButton col-span-2 rounded-full m-1 p-2 text-white t-10 bg-pacific-blue-600 text-center" : "SubmitButton col-span-2 rounded-full m-1 p-2 text-white t-10 bg-pacific-blue-300 text-center"}>Information Schedule</button>

                                </div>

                            </div>
                        </div>
                        </div>

                        <ModalUpdateValue objectToUpdate={objectIdToSendToModal} fieldToUpdate={fieldToSendToModal} valueToUpdate={valueToSendToModal} collectionToUpdate={collectionToSendToModal} arrayToShow={buildingBREEAMAssessment} onClose={() => setShowModal(false)} show={showModal} title="Update BREEAM Assessment Information" />
                        <ModalDownload reportType={reportTypeToSendToModal} stakeholder={selectedStakeholder} onClose={() => {setShowDownloadModal(false); setModalClosed(true)}} show={showDownloadModal} />
                        {tabToShow === "Score & Tracker" &&
                        <ListBuildingBREEAMCredits currentBuildingBREEAMAssessment={buildingBREEAMAssessment} role={role} selectedStakeholder={setStakeholder}/>
                        }

                        {tabToShow === "Information Schedule" &&
                        <ListBuildingBREEAMCreditsInformationSchedule currentBuildingBREEAMAssessment={buildingBREEAMAssessment} role={role} selectedStakeholder={setStakeholder} copyingView={copyingView}/>
                        }

                    </div>

                </div>

            </div>
        )

    } else {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="BREEAM Assessment" section="BREEAM Assessment Main Details" showUserMenu='true' url={role === 3001 || role === 6001 ? "/ViewClient/" + company : "/"}/>
                        <p>Data is loading</p>
                    </div>

                </div>

            </div>
        )

    }

};

export default ViewBuildingBREEAMAssessment;
