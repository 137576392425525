import { useState, useEffect } from 'react'

const InfoModal = (props) => {

    //const [processWhat, setProcessWhat] = useState()

    const processAll = () => {

        //setProcessWhat("All")
        console.log("Process All")
        //setProcessWhat("All")
        props.onClose()

    }

    const processZero = () => {

        //setProcessWhat("Zero")
        console.log("Process Zero")
        //setProcessWhat("Zero")
        props.onClose()

    }

    if (!props.show) {
        return null
    }
    return (
        <div className="update-value-modal z-50">
            <div className="update-value-modal-content">
                <div className="flex justify-center text-center mt-10">
                    <p className="inline-block text-center text-2xl font-bold">{props.title}</p>
                </div>
                <div className="flex justify-center items-center text-lg text-center mt-8 mb-8 p-5">
                    <p className="body">{props.message}</p>
                </div>
                <div className="flex justify-center text-xs text-center align-bottom">
                    <button className="bg-bondi-blue w-40 h-12 m-2 bg-blue-800 text-white rounded text-lg cursor-pointer flex justify-center items-center" onClick={props.onAll}>All</button>
                    <button className="bg-bondi-blue w-40 h-12 m-2 bg-blue-800 text-white rounded text-lg cursor-pointer flex justify-center items-center" onClick={props.onZero}>Just 0km</button>
                    <button className="bg-bondi-blue w-40 h-12 m-2 bg-blue-800 text-white rounded text-lg cursor-pointer flex justify-center items-center" onClick={props.onClose}>Cancel</button>
                </div>
            </div>    
        </div>
    )
}




export default InfoModal


