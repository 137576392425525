import { useState, useEffect } from 'react'
// import { useMatch, Navigate } from 'react-router-dom'
import axios from 'axios'
// import ReactSelect from 'react-select'
import Moment from 'react-moment'
import AuthService from '../../services/AuthService'
// import authHeader from '../../services/AuthHeader'
// import { v4 as uuidv4 } from 'uuid'
import ProgressBar from '../UI/ProgressBar'

const ModalUpdateValue = (props) => {

    const [valueToUpdate, setValueToUpdate] = useState(props.valueToUpdate)
    const [allActivities, setAllActivities] = useState([])
    // const match = useMatch('/viewClientProject/:projectId')
    const [servicesToUpdate, setServicesToUpdate] = useState([])
    const [projectsToUpdate, setProjectsToUpdate] = useState([])
    const [timesheetsToUpdate, setTimesheetsToUpdate] = useState([])
    const [users, setUsers] = useState([])
    const [clients, setClients] = useState([])
    const [loading, setLoading] = useState(true)
    const [usersLoading, setUsersLoading] = useState(true)
    const [serviceLiveDate, setServiceLiveDate] = useState('')
    const [serviceClosedDate, setServiceClosedDate] = useState('')
    const [todaysDate, setTodaysDate] = useState('')
    const [userId, setUserId] = useState(false)
    const moment = require('moment');
    const [titleProject, setTitleProject] = useState('')
    const [titleProjectNumber, setTitleProjectNumber] = useState('')
    const [titleService, setTitleService] = useState('')
    const [titleActivity, setTitleActivity] = useState('')
    const [titleDay, setTitleDay] = useState('')
    const [titleDate, setTitleDate] = useState('')
    const [activityAdded, setActivityAdded] = useState(false)
    const [completedFlag, setCompletedFlag] = useState(false)

    const timeout = (ms) => {
        
        return new Promise(resolve => setTimeout(resolve, ms));

    }

    const setFocusAndSelect = async () => {
        
        const input = document.getElementById("hoursInput");
        input.focus();
        await timeout(10)
        input.select();

    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const onSubmit = async (e) => {
        e.preventDefault()

        if (props.fieldToUpdate === "timesheetNotes" && props.collectionToUpdate === "timesheets") {
            props.arrayToShow.find(item => item.id === props.objectToUpdate).timesheetNotes = valueToUpdate            
            await updateForm({ ...timesheetsToUpdate, timesheetNotes: valueToUpdate})
            await props.onClose()
        }

        if (props.fieldToUpdate === "timesheetMonday" && props.collectionToUpdate === "timesheets") {
            let totalHrs = (Number(timesheetsToUpdate.timesheetHours)-Number(timesheetsToUpdate.timesheetMonday))+Number(valueToUpdate)
            props.arrayToShow.find(item => item.id === props.objectToUpdate).timesheetMonday = Number(valueToUpdate)
            props.arrayToShow.find(item => item.id === props.objectToUpdate).timesheetHours = Number(totalHrs)
            await updateForm({ ...timesheetsToUpdate, timesheetMonday: Number(valueToUpdate), timesheetHours: Number(totalHrs)})
            await props.onClose()
        }

        if (props.fieldToUpdate === "timesheetTuesday" && props.collectionToUpdate === "timesheets") {
            let totalHrs = (Number(timesheetsToUpdate.timesheetHours)-Number(timesheetsToUpdate.timesheetTuesday))+Number(valueToUpdate)
            props.arrayToShow.find(item => item.id === props.objectToUpdate).timesheetTuesday = Number(valueToUpdate)
            props.arrayToShow.find(item => item.id === props.objectToUpdate).timesheetHours = Number(totalHrs)
            await updateForm({ ...timesheetsToUpdate, timesheetTuesday: Number(valueToUpdate), timesheetHours: Number(totalHrs)})
            await props.onClose()
        }

        if (props.fieldToUpdate === "timesheetWednesday" && props.collectionToUpdate === "timesheets") {
            let totalHrs = (Number(timesheetsToUpdate.timesheetHours)-Number(timesheetsToUpdate.timesheetWednesday))+Number(valueToUpdate)
            props.arrayToShow.find(item => item.id === props.objectToUpdate).timesheetWednesday = Number(valueToUpdate)
            props.arrayToShow.find(item => item.id === props.objectToUpdate).timesheetHours = Number(totalHrs)
            await updateForm({ ...timesheetsToUpdate, timesheetWednesday: Number(valueToUpdate), timesheetHours: Number(totalHrs)})
            await props.onClose()
        }

        if (props.fieldToUpdate === "timesheetThursday" && props.collectionToUpdate === "timesheets") {
            let totalHrs = (Number(timesheetsToUpdate.timesheetHours)-Number(timesheetsToUpdate.timesheetThursday))+Number(valueToUpdate)
            props.arrayToShow.find(item => item.id === props.objectToUpdate).timesheetThursday = Number(valueToUpdate)
            props.arrayToShow.find(item => item.id === props.objectToUpdate).timesheetHours = Number(totalHrs)
            await updateForm({ ...timesheetsToUpdate, timesheetThursday: Number(valueToUpdate), timesheetHours: Number(totalHrs)})
            await props.onClose()
        }

        if (props.fieldToUpdate === "timesheetFriday" && props.collectionToUpdate === "timesheets") {
            let totalHrs = (Number(timesheetsToUpdate.timesheetHours)-Number(timesheetsToUpdate.timesheetFriday))+Number(valueToUpdate)
            props.arrayToShow.find(item => item.id === props.objectToUpdate).timesheetFriday = Number(valueToUpdate)
            props.arrayToShow.find(item => item.id === props.objectToUpdate).timesheetHours = Number(totalHrs)
            await updateForm({ ...timesheetsToUpdate, timesheetFriday: Number(valueToUpdate), timesheetHours: Number(totalHrs)})
            await props.onClose()
        }

        if (props.fieldToUpdate === "timesheetSaturday" && props.collectionToUpdate === "timesheets") {
            let totalHrs = (Number(timesheetsToUpdate.timesheetHours)-Number(timesheetsToUpdate.timesheetSaturday))+Number(valueToUpdate)
            props.arrayToShow.find(item => item.id === props.objectToUpdate).timesheetSaturday = Number(valueToUpdate)
            props.arrayToShow.find(item => item.id === props.objectToUpdate).timesheetHours = Number(totalHrs)
            await updateForm({ ...timesheetsToUpdate, timesheetSaturday: Number(valueToUpdate), timesheetHours: Number(totalHrs)})
            await props.onClose()
        }

        if (props.fieldToUpdate === "timesheetSunday" && props.collectionToUpdate === "timesheets") {
            let totalHrs = (Number(timesheetsToUpdate.timesheetHours)-Number(timesheetsToUpdate.timesheetSunday))+Number(valueToUpdate)
            props.arrayToShow.find(item => item.id === props.objectToUpdate).timesheetSunday = Number(valueToUpdate)
            props.arrayToShow.find(item => item.id === props.objectToUpdate).timesheetHours = Number(totalHrs)
            await updateForm({ ...timesheetsToUpdate, timesheetSunday: Number(valueToUpdate), timesheetHours: Number(totalHrs)})
            await props.onClose()
        }  

    }

    const updateForm = async (form) => {

        if (props.collectionToUpdate === "timesheets") {

            await axios.put(process.env.REACT_APP_API_URL + '/timesheets/' + props.objectToUpdate, form)

        }

    }

    const addActivityToTimeSheet = async (activityId) => {

        //window.alert("Activity " + activityId)
        var timesheetFilteredToThisID = props.arrayToShow.find(item => item.id === props.objectToUpdate)
        await axios.post(process.env.REACT_APP_API_URL + '/timesheets', { userId: timesheetFilteredToThisID.userId, projectId: timesheetFilteredToThisID.projectId, serviceId: timesheetFilteredToThisID.serviceId, activityId: activityId, timesheetDate: timesheetFilteredToThisID.timesheetDate, timesheetHours:0, timesheetStartDate: timesheetFilteredToThisID.timesheetStartDate, timesheetMonday:0, timesheetTuesday:0, timesheetWednesday:0, timesheetThursday:0, timesheetFriday:0, timesheetSaturday:0, timesheetSunday:0, timesheetNotes: "", timesheetAvailableHours: timesheetFilteredToThisID.timesheetAvailableHours })
        var allActivitiesCopy = allActivities
        allActivitiesCopy.find(item => item.activityId === activityId).onCurrentTimeSheet = true
        setActivityAdded(true)
        setAllActivities(allActivitiesCopy)
        window.alert("The activity will be aded to the timesheet when you close this window")

    }

    const handleDoubleClick = async (activityId) => {

        //window.alert("Double Click")
        
        var timesheetFilteredToThisID = props.arrayToShow.find(item => item.id === props.objectToUpdate)
        var thisService = props.timeSheetProjectServices.find(item => item.id === timesheetFilteredToThisID.serviceId)
        var allActivitiesCopy = allActivities
        var activityCompleteFlag = allActivitiesCopy.find(item => item.activityId === activityId).activityComplete === "YES" ? allActivitiesCopy.find(item => item.activityId === activityId).activityComplete = "NO" : allActivitiesCopy.find(item => item.activityId === activityId).activityComplete = "YES"
        allActivitiesCopy.find(item => item.activityId === activityId).activityComplete = activityCompleteFlag
        props.timeSheetProjectServices.find(item => item.id === timesheetFilteredToThisID.serviceId).activities.find(activity => activity.activityId === activityId).activityComplete = activityCompleteFlag
        await axios.put(process.env.REACT_APP_API_URL + '/projects_services/' + timesheetFilteredToThisID.serviceId, { ...thisService, activities: allActivitiesCopy })
        setCompletedFlag(true)
        setAllActivities(allActivitiesCopy)

    }

    useEffect(() => {

        if (activityAdded===true){
            setActivityAdded(false)
            props.activityAdded()
        }else if(completedFlag===true){
            setCompletedFlag(false)
        }

    },[activityAdded, completedFlag])

    useEffect(() => {

        axios.get(process.env.REACT_APP_API_URL + '/users_internal_only')
            .then((res) => {
                setUsers(res.data.filter(item => item.role !== 3001 && item.role !== 9999 && item.role !== 9998).sort((item1, item2) => item1.firstName + item1.lastName < item2.firstName + item2.lastName ? -1 : 0))
                setUserId(res.data.find(user => user.emailId === AuthService.getCurrentUserName()).id)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUsersLoading(false);
            });

        if (props.collectionToUpdate === "projects_services") {

            axios.get(process.env.REACT_APP_API_URL + '/projects_services/' + props.objectToUpdate)
                .then((res) => {
                    setServicesToUpdate(res.data)
                    setServiceClosedDate(res.data.serviceClosedDate)
                    setServiceLiveDate(res.data.serviceLiveDate)
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });

            axios.get(process.env.REACT_APP_API_URL + '/clients')
                .then((res) => {
                    setClients(res.data.sort((item1, item2) => item1.clientName + item1.clientName < item2.clientName + item2.clientName ? -1 : 0))
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setUsersLoading(false);
                });

        }

        if (props.collectionToUpdate === "timesheets") {

            var svcID
            var currWeekHrs
            var actualHours

            setTimesheetsToUpdate(props.arrayToShow.find(item => item.id === props.objectToUpdate))
            var timesheetFilteredToThisID = props.arrayToShow.find(item => item.id === props.objectToUpdate)
            svcID = timesheetFilteredToThisID.serviceId

            setTitleProject(timesheetFilteredToThisID.projectName)
            setTitleProjectNumber(timesheetFilteredToThisID.projectNumber)
            setTitleService(timesheetFilteredToThisID.serviceName)
            setTitleActivity(timesheetFilteredToThisID.activityName)
            setTitleDay(props.fieldToUpdate.split("timesheet").pop())
            var varDate=""
            switch (props.fieldToUpdate.split("timesheet").pop()) {
                case "Monday":
                    varDate = timesheetFilteredToThisID.timesheetDate
                    break;
                case "Tuesday":
                    varDate = moment(timesheetFilteredToThisID.timesheetDate).add(1,'days').format("DD-MM-YYYY")
                    break;
                case "Wednesday":
                    varDate = moment(timesheetFilteredToThisID.timesheetDate).add(2,'days').format("DD-MM-YYYY")
                    break;
                case "Thursday":
                    varDate = moment(timesheetFilteredToThisID.timesheetDate).add(3,'days').format("DD-MM-YYYY")
                    break;
                case "Friday":
                    varDate = moment(timesheetFilteredToThisID.timesheetDate).add(4,'days').format("DD-MM-YYYY")
                    break;
                case "Saturday":
                    varDate = moment(timesheetFilteredToThisID.timesheetDate).add(5,'days').format("DD-MM-YYYY")
                    break;
                case "Sunday":
                    varDate = moment(timesheetFilteredToThisID.timesheetDate).add(6,'days').format("DD-MM-YYYY")
                    break;
              }

            setTitleDate(varDate)


            //Get all activities on the project_service into an array
            var arrAllActivities = props.timeSheetProjectServices.filter(item => item.id === svcID)[0].activities
            var arrTimeSheetDataForThisWeek = props.timeSheetDataForThisWeek
            var dataToShow =[]
            var onCurrentTimeSheet

            //add the current week hours
            arrAllActivities.forEach(activity => {
                currWeekHrs=0
                onCurrentTimeSheet=false
                actualHours=0
                actualHours = props.arrayToShow.filter((item) => item.serviceId === svcID && item.activityId === activity.activityId).map(item => item.timesheetHours).reduce((prev, curr) => Number(prev) + Number(curr), 0)
                if (arrTimeSheetDataForThisWeek.some(item => item.activityId === activity.activityId)){
                    currWeekHrs = Number(arrTimeSheetDataForThisWeek.find(item2 => item2.activityId === activity.activityId).timesheetMonday) + Number(arrTimeSheetDataForThisWeek.find(item2 => item2.activityId === activity.activityId).timesheetTuesday) + Number(arrTimeSheetDataForThisWeek.find(item2 => item2.activityId === activity.activityId).timesheetWednesday) + Number(arrTimeSheetDataForThisWeek.find(item2 => item2.activityId === activity.activityId).timesheetThursday) + Number(arrTimeSheetDataForThisWeek.find(item2 => item2.activityId === activity.activityId).timesheetFriday) + Number(arrTimeSheetDataForThisWeek.find(item2 => item2.activityId === activity.activityId).timesheetSaturday) + Number(arrTimeSheetDataForThisWeek.find(item2 => item2.activityId === activity.activityId).timesheetSunday)
                    onCurrentTimeSheet = true
                }
                dataToShow.push({...activity, "hoursThisWeek": currWeekHrs, "hoursTotal": actualHours, "onCurrentTimeSheet": onCurrentTimeSheet})
            });

            setAllActivities(dataToShow.sort((item1, item2) => item1.activity < item2.activity ? -1 : 0))

        }

        setValueToUpdate(props.valueToUpdate)

        if (props.show) {

            setFocusAndSelect()

        }

        const newDate = new Date()
        setTodaysDate(formatDate(newDate))

    }, [props.objectToUpdate, props.valueToUpdate, props.collectionToUpdate, props.show])

    if (!props.show) {
        return null
    }
    return (
        <div className="update-value-modal z-50" >
            <div className="update-value-modal-timesheet-content">
                <div className="update-value-modal-header">
                    <p className="update-value-modal-title text-congress-blue text-xl">{props.title}</p>
                </div>
                <div className="update-value-modal-body">
                    <form className="" onSubmit={onSubmit}>
                        <div>
                            <p>Project: {titleProject}</p>
                            <p>Service: {titleService}</p>
                            <p>Activity: {titleActivity}</p>
                            <br />
                            {props.fieldToUpdate !== "timesheetNotes" &&
                                <div>
                                    <label className="text-center">Hours to assign to {titleDay} {titleDate}: </label>
                                    <div className="form-control-8A">
                                        <input id="hoursInput" type="number" step="0.001" placeholder="Enter Hours Here" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                                    </div>
                                </div>
                            }
                            {props.fieldToUpdate === "timesheetNotes" &&
                                <div className="form-control-8A w-full p-2 h-24">
                                    <div className="form-control-8A">
                                        <input type="Text" placeholder="Enter Notes" value={valueToUpdate} onChange={(e) => setValueToUpdate(e.target.value)} />
                                    </div>
                                </div>
                            }
                        </div>
                        <input type="submit" value="Save" className="cursor-pointer inline-block rounded-2xl w-full text-white bg-bondi-blue" />
                        <button className=" bg-congress-blue inline-block rounded-2xl w-full text-white mt-2 bottom-0" onClick={props.onClose}>Cancel</button>
                    </form>
                    {titleProjectNumber !== 0 &&
                        <div className="min-w-full mt-8 border-t-solid border-t-2 border-t-congress-blue">
                            <div className="update-value-modal-header mt-5">
                                <p className="update-value-modal-title text-congress-blue text-xl">All Activities on this Service</p>
                            </div>
                            <div className='overflow-auto h-[400px]'>
                                {allActivities.filter(item => item.activityComplete === "NO").length > 0 &&
                                <table className="objects-8A min-w-full divide-y divide-gray-200 table-fixed">
                                    <thead className="bg-pacific-blue-50">
                                        <tr>
                                            <th scope="col" className="pl-4 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '240px' }}>Activity (Not Yet Completed)</th>
                                            <th scope="col" className="pl-4 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '100px' }}>Hours This Week</th>
                                            <th scope="col" className="pl-4 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '100px' }}>Hours Total</th>
                                            <th scope="col" className="pl-4 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '100px' }}>Est. Hours</th>
                                            <th scope="col" className="pl-4 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '100px' }}>Est. Remaining</th>
                                            <th scope="col" className="pl-4 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '100px' }}>Complete<br/>(Dbl click to change)</th>
                                            <th scope="col" className="pl-4 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '100px' }}>Add to Timesheet</th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {allActivities.filter(item => item.activityComplete === "NO").map((item) => (
                                            <tr>
                                                <td className="pl-4 py-2 whitespace-normal">{item.activity}<ProgressBar bgcolor={item.activityComplete === "NO" && (item.hoursTotal / item.estimatedHours)*100 >= 100 ? "#ff0000" : item.activityComplete === "YES" ? "#49fc03" : "#0091ca"} completed={isNaN((item.hoursTotal / item.estimatedHours)*100) || (item.hoursTotal / item.estimatedHours)*100 >= 100 ? 100 : Math.round((item.hoursTotal / item.estimatedHours)*100)} completedLabel={isNaN((item.hoursTotal / item.estimatedHours)*100) ? 100 : Math.round((item.hoursTotal / item.estimatedHours)*100) === Infinity ? 100 : Math.round((item.hoursTotal / item.estimatedHours)*100)}></ProgressBar></td>
                                                <td className="pl-4 py-2 whitespace-normal">{Number(item.hoursThisWeek).toFixed(1)}</td>
                                                <td className="pl-4 py-2 whitespace-normal">{Number(item.hoursTotal).toFixed(1)}</td>
                                                <td className="pl-4 py-2 whitespace-normal">{Number(item.estimatedHours).toFixed(1)}</td>
                                                <td className="pl-4 py-2 whitespace-normal">{Number(Number(item.estimatedHours)-Number(item.hoursTotal)).toFixed(1)}</td>
                                                <td className="pl-4 py-2 whitespace-normal"><button onDoubleClick={() => { handleDoubleClick(item.activityId)}}> {item.activityComplete}</button></td>
                                                {(item.activityComplete === 'NO' && item.onCurrentTimeSheet === false) ?
                                                    <td className="pl-4 py-2 whitespace-normal h-[45px] w-[100px]"><button onClick={() => { addActivityToTimeSheet(item.activityId) }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-square" viewBox="0 0 17 17"> <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/> <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/> </svg></button></td>
                                                :<td className="pl-4 py-2 whitespace-normal h-[45px] w-[100px]">{item.onCurrentTimeSheet === true ? "Activity on Timesheet" : "Activity Complete"}</td>
                                                }
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                }
                                {allActivities.filter(item => item.activityComplete === "YES").length > 0 &&
                                <table className="objects-8A min-w-full divide-y divide-gray-200 table-fixed mt-4">
                                    <thead className="bg-pacific-blue-50">
                                        <tr>
                                            <th scope="col" className="pl-4 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '240px' }}>Activity (Completed)</th>
                                            <th scope="col" className="pl-4 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '100px' }}>Hours This Week</th>
                                            <th scope="col" className="pl-4 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '100px' }}>Hours Total</th>
                                            <th scope="col" className="pl-4 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '100px' }}>Est. Hours</th>
                                            <th scope="col" className="pl-4 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '100px' }}>Est. Remaining</th>
                                            <th scope="col" className="pl-4 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '100px' }}>Complete<br/>(Dbl click to change)</th>
                                            <th scope="col" className="pl-4 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '100px' }}>Add to Timesheet</th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {allActivities.filter(item => item.activityComplete === "YES").map((item) => (
                                            <tr>
                                                <td className="pl-4 py-2 whitespace-normal">{item.activity}<ProgressBar bgcolor={item.activityComplete === "NO" && (item.hoursTotal / item.estimatedHours)*100 >= 100 ? "#ff0000" : item.activityComplete === "YES" ? "#49fc03" : "#0091ca"} completed={isNaN((item.hoursTotal / item.estimatedHours)*100) || (item.hoursTotal / item.estimatedHours)*100 >= 100 ? 100 : Math.round((item.hoursTotal / item.estimatedHours)*100)} completedLabel={isNaN((item.hoursTotal / item.estimatedHours)*100) ? 100 : Math.round((item.hoursTotal / item.estimatedHours)*100) === Infinity ? 100 : Math.round((item.hoursTotal / item.estimatedHours)*100)}></ProgressBar></td>
                                                <td className="pl-4 py-2 whitespace-normal">{Number(item.hoursThisWeek).toFixed(1)}</td>
                                                <td className="pl-4 py-2 whitespace-normal">{Number(item.hoursTotal).toFixed(1)}</td>
                                                <td className="pl-4 py-2 whitespace-normal">{Number(item.estimatedHours).toFixed(1)}</td>
                                                <td className="pl-4 py-2 whitespace-normal">{Number(Number(item.estimatedHours)-Number(item.hoursTotal)).toFixed(1)}</td>
                                                <td className="pl-4 py-2 whitespace-normal"><button onDoubleClick={() => { handleDoubleClick(item.activityId)}}> {item.activityComplete}</button></td>
                                                {(item.activityComplete === 'NO' && item.onCurrentTimeSheet === false) ?
                                                    <td className="pl-4 py-2 whitespace-normal h-[45px] w-[100px]"><button onClick={() => { addActivityToTimeSheet(item.activityId) }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-square" viewBox="0 0 17 17"> <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/> <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/> </svg></button></td>
                                                :<td className="pl-4 py-2 whitespace-normal h-[45px] w-[100px]">{item.onCurrentTimeSheet === true ? "Activity on Timesheet" : "Activity Complete"}</td>
                                                }
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                }
                            </div>
                        </div>
                    }

                </div>

            </div>
    
        </div>
    )
}

export default ModalUpdateValue