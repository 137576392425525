import { useEffect, useState } from 'react'
import axios from 'axios'
import Button from '../UI/Button'
import Header from '../UI/Header'
import { useNavigate } from 'react-router-dom'
import Sidebar from '../UI/Sidebar'

const ListProducts = () => {

    const [products, setProducts] = useState([])

    const [sidebarToggle, setSidebarToggle] = useState(false)

    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {

        setLoading(true)

        axios.get(process.env.REACT_APP_API_URL + '/products')
            .then((res) => {
                setProducts(res.data)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });

    }, [])

    const deleteProduct = (id) => {
        window.confirm('Are you sure you want to delete it?')

        // axios.delete(process.env.REACT_APP_API_URL + '/products/' + id)
        // setProducts(products.filter((product) => product.id !== id))
    }

    const editProduct = (id) => {
        navigate(`/addOrUpdateProduct/${id}`);
    }

    const viewProduct = (id) => {
        navigate(`/viewProduct/${id}`);
    }

    if (products.length > 0 && !loading) {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">

                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Products" section="Select Product" iconFileName="Product-Hover" showUserMenu='true'/>

                        <div className="flex flex-wrap">
                            {products.map((product) => (
                                <div className="bg-slate-200 w-64 shadow-xl border-2 p-5 m-3 content-center rounded-md" key={product.id}>
                                    <img className="p-1 h-24 w-32 text-center" src={"/images/product_images/" + product.productName + ".jpg"} alt="" />
                                    <p className="text-xs pt-1">{product.productName}</p>
                                    <p className="h-10 overflow-hidden text-xs">{product.productDescription}</p>
                                    <p className="text-xs">{product.productLCAGoalAndScope}</p>
                                    <Button color="black" text="View" onClick={() => viewProduct(product.id)} /><Button color="black" text="Edit" onClick={() => editProduct(product.id)} /><Button color="black" text="Delete" onClick={() => deleteProduct(product.id)} />
                                </div>
                            ))}
                        </div>
                        <br></br>
                        <Button color="black" text="Add New Product" onClick={() => navigate('/addOrUpdateProduct/_add', { replace: true })} />
                    </div>
                </div>

            </div>
        )

    } else {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Products" section="Select Product" iconFileName="Product-Hover" showUserMenu='true' />
                        <p>Data is loading</p>
                    </div>

                </div>

            </div>
        )

    }

}

export default ListProducts
