import { useEffect, useState } from 'react'
import axios from 'axios'
import Button from '../UI/Button'
import Header from '../UI/Header'
import { useNavigate } from 'react-router-dom'
import Sidebar from '../UI/Sidebar'
import Options from '../UI/Options'
//import AuthService from '../../services/AuthService'

const ListEvents = () => {

    const [events, setEvents] = useState([])

    const [sidebarToggle, setSidebarToggle] = useState(false)

    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    const [company, setCompany] = useState("")
    const [role, setRole] = useState(0)
    const [userPropertiesLoading, setUserPropertiesLoading] = useState(true)

    const getUserProperties = async () => {

        await axios.get(process.env.REACT_APP_API_URL + "/current_user_properties")
            .then((response) => {
                setCompany(response.data.company)
                setRole(response.data.role)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUserPropertiesLoading(false);
            });

    }

    useEffect(() => {

        setUserPropertiesLoading(true)

        getUserProperties()

    }, [])

    useEffect(() => {

        if (company && role && !userPropertiesLoading) {

            setLoading(true)

            if (role === 3001 || role === 6001) {

                //Code here to call special API endpoint returning only events for that client
                axios.get(process.env.REACT_APP_API_URL + '/events_by_clientId/' + company)
                    .then((res) => {
                        setEvents(res.data)
                    })
                    .catch((err) => {
                        console.log(err);
                    }).finally(() => {
                        setLoading(false);
                    });

            } else {

                axios.get(process.env.REACT_APP_API_URL + '/events')
                    .then((res) => {
                        setEvents(res.data)
                    })
                    .catch((err) => {
                        console.log(err);
                    }).finally(() => {
                        setLoading(false);
                    });

            }

        }

    }, [company, role, userPropertiesLoading])

    const deleteEvent = (id) => {

        if (window.confirm('Are you sure you want to delete this event?')) {

            if (role === 3001 || role === 6001) {

                window.alert("Please contact your Eight consultant to delete events")

            } else {

                //Check here to see if any event_footprints relate to this event
                //If there are any then do not allow the deletion

                axios.get(process.env.REACT_APP_API_URL + '/events_footprints_by_eventId/' + id)
                    .then((res) => {

                        if (res.data.length > 0) {
                            window.alert("Please remove event footprints from event in order to delete event")
                        } else {
                            axios.delete(process.env.REACT_APP_API_URL + '/events/' + id)
                            setEvents(events.filter((event) => event.id !== id))
                        }

                    })
                    .catch((err) => {
                        console.log(err);
                    })

            }

        }

    }

    const editEvent = (id) => {
        navigate(`/addOrUpdateEvent/${id}`);
    }

    const viewEvent = (id) => {
        navigate(`/viewEvent/${id}`);
    }
    const buttonStyles = {
        color: 'gray',
        background: 'slate'
    }

    if (events.length > 0 && !loading && !userPropertiesLoading) {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="text-congress-blue absolute top-2 left-2 z-50" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="text-white absolute top-2 left-2 " onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="flex h-screen overflow-hidden bg-slate-100">

                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                        <Header title="Events" section="Select Event" iconFileName="Event-Hover" showUserMenu='true' url={role === 3001 || role === 6001 ? "/ViewClient/" + company : "/"}/>
                        <div className="flex flex-col flex-1 overflow-y-auto overflow-x-hidden ">
                            <div className="grid h-screen place-items-center mt-5">
                                <div className="flex flex-wrap lg:w-3/4">
                                    {events.map((event) => (
                                        <div className=" p-5 m-3 grid grid-cols-12 w-full bg-white rounded-2xl shadow-md shadow-slate-300" key={event.id}>
                                            <img className="border-solid p-1 h-24 w-32 text-center col-span-3" src={`https://storage.googleapis.com/public_images_bucket/${event.id}/` + event.eventName + ".jpg"} onError={(e) => { e.target.src = "/images/organisation_generic.png" }} alt="loading..." />
                                            <div onClick={() => viewEvent(event.id)} className="col-span-6 hover:cursor-pointer hover:underline">
                                                {/* <p className="text-md pt-1 uppercase font-extrabold text-slate-400">{event.eventDuration}</p> */}
                                                <p className="text-md pt-1 uppercase font-extrabold text-slate-400">{event.eventDate}</p>
                                                <p className="text-lg pt-1 uppercase font-extrabold text-pacific-blue-500">{event.eventName}</p>
                                                <p className="overflow-hidden text-sm text-slate-500">{event.eventDescription}</p>
                                            </div>
                                            <div className="col-span-2 mx-auto">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 stroke-slate-500 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                                                </svg>
                                                <p className="text-xs">{event.eventNumberOfAttendees}</p>

                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 stroke-slate-500 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                                                </svg>
                                                <p className="text-xs pl-1">{event.eventCountry}</p>
                                            </div>
                                            <div className="col-span-1">
                                                <Options edit={`/addOrUpdateEvent/${event.id}`} deleteFunction={deleteEvent} deleteObject={event.id}></Options>
                                            </div>

                                        </div>
                                    ))}
                                </div>
                                {role !== 3001 &&
                                    <div className='lg:w-3/4 w-full p-3'>
                                        <button className="w-full shadow-md shadow-slate-300 rounded-full mt-5  p-2 text-white bg-pacific-blue-500" onClick={() => navigate('/addOrUpdateEvent/_add')} >Add New Event</button>
                                    </div>
                                }
                                {/* <Button color="bondi-blue" text="Add New Event" onClick={() => navigate('/addOrUpdateEvent/_add')} /> */}

                            </div>

                        </div>
                    </div>
                </div>

            </div>
        )

    } else if (events.length === 0 && (loading || userPropertiesLoading)) {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Events" section="Select Event" iconFileName="Event-Hover" showUserMenu="true" url={role === 3001 || role === 6001 ? "/ViewClient/" + company : "/"}/>
                        <br></br>
                        <p>Data is loading</p>
                    </div>

                </div>

            </div>
        )

    } else if (events.length === 0 && !loading && !userPropertiesLoading) {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle &&
                        <Sidebar />
                    }

                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">


                        <Header title="Events" section="Select Event" iconFileName="Event-Hover" showUserMenu="true" url={role === 3001 || role === 6001 ? "/ViewClient/" + company : "/"}/>
                        <br></br>
                        <div className="grid h-screen place-items-center">
                            <div className="flex flex-wrap lg:w-3/4 l divide-y">
                                <h1 className='p-10 mx-auto border-2 rounded-lg header-8A'>No Events</h1>
                            </div>
                        </div>
                        <br></br>
                        {role !== 3001 &&
                            <Button color="bondi-blue" text="Add New Event" onClick={() => navigate('/addOrUpdateEvent/_add')} />
                        }
                        {/* <button className="rounded-sm m-1 p-2 text-white whitespace-nowrap px-40 bg-pacific-blue-600" onClick={() => navigate('/addOrUpdateEvent/_add')} >Add New Event</button> */}

                    </div>

                </div>
            </div>
        )

    }

}

export default ListEvents
