
const MaintenanceScreen = () => {
    return (
        <body>
            <div class="flex items-center justify-center h-screen bg-purple-600">
                <div class="flex flex-col items-center justify-center max-w-2xl">
                    <div>
        
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-20 h-20 text-emerald-500">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12a7.5 7.5 0 0015 0m-15 0a7.5 7.5 0 1115 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077l1.41-.513m14.095-5.13l1.41-.513M5.106 17.785l1.15-.964m11.49-9.642l1.149-.964M7.501 19.795l.75-1.3m7.5-12.99l.75-1.3m-6.063 16.658l.26-1.477m2.605-14.772l.26-1.477m0 17.726l-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205L12 12m6.894 5.785l-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864l-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495" />
                        </svg>

                    </div>
                    <h1 class="mb-3 text-3xl font-bold text-center text-purple-100">We’ll be back soon!</h1>
                    <p class="text-center text-gray-700">
                        Sorry for the inconvenience. 
                        <br/>
                        We’re performing some maintenance at the moment.We’ll be back up shortly! 
                        <br/>
                        In the meantime you can reach out to us if you need anything.
                    </p>
                </div>
            </div>
        </body>
    );
}

export default MaintenanceScreen;