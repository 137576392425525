import React, { useState, useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import AuthService from "../services/AuthService";

import axios from 'axios'


const MFAValidateScreen = () => {
    const [loading, setLoading] = useState(false)
    const [token, setToken] = useState("")
    const [tokenError, setTokenError] = useState("")
    const [redirecting, setRedirecting] = useState("")

    const value = JSON.parse(localStorage.getItem("user"))

    const [company, setCompany] = useState("")
    const [role, setRole] = useState(0)
    const [userPropertiesLoading, setUserPropertiesLoading] = useState(true)

    // useEffect(() => {
    //     if (token.match(re)) {
    //         setTokenError("Valid token address format ✔️")
    //     }
    // })
    const getUserProperties = async () => {

        await axios.get(process.env.REACT_APP_API_URL + "/current_user_properties")
            .then((response) => {
                setCompany(response.data.company)
                setRole(response.data.role)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUserPropertiesLoading(false);
            });

    }

    useEffect(() => {
        setLoading(false)
    }, [])

    const addForm = async (form) => {
        await AuthService.validateToken(form)
            .then((resData) => {
                if (sessionStorage.getItem("user") && localStorage.getItem("userName") && localStorage.getItem("user") && resData.status === 200) {
                    localStorage.setItem("tab", "Services")
                    setRedirecting(true)
                    setUserPropertiesLoading(true)
                    getUserProperties()
                }

            }).catch(error => {
                setTokenError(error.data)
            })
    }

    const onSubmit = (e) => {
        e.preventDefault()
        // fetching user email from storage to validate code against the email in the backend
        const user = sessionStorage.getItem("user")
        addForm({ token, user })
    }

    if (redirecting && !userPropertiesLoading && role && company && (role.toString().slice(0,1) === "2")) {

        console.log('going to / ')
        sessionStorage.setItem("siteArea", "Projects")

        return (
            <Navigate replace to="/listClientProjects" />
        )

    } else if (redirecting && !userPropertiesLoading && role && company && (role === 1001)) {
        console.log('going to / ')
        sessionStorage.setItem("siteArea", "Projects")
        return (
            <Navigate replace to="/listClientProjects" />
        )

    } else if (redirecting && !userPropertiesLoading && role && company && (role === 4001)) {
        console.log('going to / ')
        sessionStorage.setItem("siteArea", "Projects")
        return (
            <Navigate replace to="/listClientProjects" />
        )

    } else if (redirecting && !userPropertiesLoading && role && company && (role === 5001)) {
        console.log('going to / ')
        sessionStorage.setItem("siteArea", "Projects")
        return (
            <Navigate replace to="/listClientProjects" />
        )

    } else if (redirecting && !userPropertiesLoading && role && company && (role === 3001)) {
        console.log('going to / ')
        sessionStorage.setItem("siteArea", "Organisation")
        return (
            <Navigate replace to={"/viewClient/" + company} />
        )

    } else if (redirecting && !userPropertiesLoading && role && company && (role === 6002)) {
        console.log('going to / ')
        sessionStorage.setItem("siteArea", "Organisation")
        return (
            <Navigate replace to={"/listOrganisations"} />
        )

    }else if (redirecting && !userPropertiesLoading && role && company && (role === 6001)) {
        console.log('going to / ')
        sessionStorage.setItem("siteArea", "Organisation")
        return (
            <Navigate replace to={"/viewClient/" + company} />
        )

    }

    // if (redirecting) {
    //     console.log('going to / ')
    //     sessionStorage.setItem("siteArea", "Projects")
    //     return (
    //         <Navigate replace to="/listClientProjects" />
    //     )
    // }

    else {
        return (
            <div className='flex items-center bg-jungle-mist-100 min-h-screen'>
                <div className="container-form-8A w-1/2">

                    <p className=' text-2xl text-congress-blue font-bold'>Verify your Identity</p>

                    <form className="add-form-8A" onSubmit={onSubmit}>
                        <div className="form-control-8A">
                            <label className='text-sm font-bold'>Please check your email and enter the authentication code</label>
                            <input type="text" value={token} onChange={(e) => setToken(e.target.value)} />
                        </div>
                        <p className='text-xs'>{tokenError}</p>
                        <input type="submit" value="Check" className={`bg-emerald-500 block mx-auto text-white text-sm rounded-full shadow-slate-300 shadow-md px-8 py-3 mt-4 cursor-not-allowed ${(token.length > 5) ? 'cursor-pointer' : ''}`} />
                    </form>
                </div>
            </div>
        );
    }


}

export default MFAValidateScreen;