import { useEffect, useState } from 'react'
import axios from 'axios'
import Button from '../UI/Button'
import { MultiSelect } from "react-multi-select-component";
import Options from '../UI/Options'
import { useNavigate, useMatch } from 'react-router-dom'
import ModalUpdateValue from '../UI/ModalUpdateValue'


const ListBuildingBREEAMCredits = (props) => {

  const match = useMatch('/viewBuildingBREEAMAssessment/:buildingId/:assessmentId')

  const [loading, setLoading] = useState(false)

  const [buildingBREEAMCredits, setBuildingBREEAMCredits] = useState([])
  const [buildingBREEAMAssessment, setBuildingBREEAMAssessment] = useState([])

  const [selectedSection, setSelectedSection] = useState([])
  const [selectedResponsibility, setSelectedResponsibility] = useState([])
  const [selectedAchieved, setSelectedAchieved] = useState([])

  const [allTeamMembers, setAllTeamMembers] = useState([])

  const [selectedStage, setSelectedStage] = useState('')

  const [showModal, setShowModal] = useState(false)
  const [valueToSendToModal, setValueToSendToModal] = useState('')
  const [objectIdToSendToModal, setObjectIdToSendToModal] = useState('')
  const [fieldToSendToModal, setFieldToSendToModal] = useState('')
  const [collectionToSendToModal, setCollectionToSendToModal] = useState('')
  const [collectionArrayToSendToModal, setCollectionArrayToSendToModal] = useState('')
  const [collectionArrayIdToSendToModal, setCollectionArrayIdToSendToModal] = useState('')

  const expandArrayElementsIntoConcatinatedString = arr => {

    var acc = ""
    var aggregatedArray = aggregateResponsibilityArray(arr)

    var i;
    for (i = 0; i < aggregatedArray.length; i++) {

        if (i === aggregatedArray.length -1) {
            acc = acc + aggregatedArray[i].responsibility
        } else {
            acc = acc + aggregatedArray[i].responsibility + '; '
        }

    }

    return acc

};

    const toggleAchieved = async (id) => {

        var allBREEAMCreditData
        var newBREEAMAssessment

        await axios.get(process.env.REACT_APP_API_URL + '/buildings_BREEAM_assessments/' + match.params.assessmentId)
        .then((res) => res.data)
        .then((res) => {

            newBREEAMAssessment = res
            allBREEAMCreditData = res.credits

            if (allBREEAMCreditData.find(item => item.id === id).creditAchieved === "") {
                allBREEAMCreditData.find(item => item.id === id).creditAchieved = "YES"
            } else {
                allBREEAMCreditData.find(item => item.id === id).creditAchieved = ""
            }

            newBREEAMAssessment.credits = allBREEAMCreditData

            setBuildingBREEAMAssessment(newBREEAMAssessment)
            setBuildingBREEAMCredits(allBREEAMCreditData)

        })
        .catch((err) => {
          console.log(err);
        })

        await axios.put(process.env.REACT_APP_API_URL + '/buildings_BREEAM_assessments/' + match.params.assessmentId, { ...newBREEAMAssessment })
        
    }

  const aggregateResponsibilityArray = arr => {
    return arr.reduce((acc, val) => {
        const index = acc.findIndex(obj => obj.responsibility === val.responsibility);
        if (index === -1) {
            acc.push({
                responsibility: val.responsibility,
            });
        };
        return acc.sort((item1, item2) => item1.responsibility < item2.responsibility ? -1 : item1.responsibility > item2.responsibility ? 1 : 0);
    }, []);
};

const aggregateSectionArray = arr => {
    return arr.reduce((acc, val) => {
        const index = acc.findIndex(obj => obj.section === val.section);
        if (index === -1) {
            acc.push({
                section: val.section,
            });
        };
        return acc;
    }, []);
};

  useEffect(() => {

    setLoading(true)

    axios.get(process.env.REACT_APP_API_URL + '/buildings_BREEAM_assessments/' + match.params.assessmentId)
      .then((res) => res.data)
      .then((res) => {
        setBuildingBREEAMCredits(res.credits);
        setBuildingBREEAMAssessment(res);
        setSelectedSection(aggregateSectionArray(res.credits).map((item) => {
            return {
                label: item.section,
                value: item.section
            }
        }))
        // setSelectedResponsibility([{label: "Project Manager", value: "Project Manager"}, {label: "Client", value: "Client"}, {label: "No Stakeholder Set", value: ""}, {label: "Specialist Consultant", value: "Specialist Consultant"}, {label: "Architect", value: "Architect"}, {label: "M&E", value: "M&E"}, {label: "Contractor", value: "Contractor"}, {label: "Eight Versa", value: "Eight Versa"}, {label: "Ecologist", value: "Ecologist"}, {label: "BREEAM Accredited Professional", value: "BREEAM Accredited Professional"}, {label: "Civil Engineer", value: "Civil Engineer"}].sort((item1, item2) => item1.label < item2.label ? -1 : item1.label > item2.label ? 1 : 0))
        // setAllTeamMembers([{label: "Project Manager", value: "Project Manager"}, {label: "Client", value: "Client"}, {label: "No Stakeholder Set", value: ""}, {label: "Specialist Consultant", value: "Specialist Consultant"}, {label: "Architect", value: "Architect"}, {label: "M&E", value: "M&E"}, {label: "Contractor", value: "Contractor"}, {label: "Eight Versa", value: "Eight Versa"}, {label: "Ecologist", value: "Ecologist"}, {label: "BREEAM Accredited Professional", value: "BREEAM Accredited Professional"}, {label: "Civil Engineer", value: "Civil Engineer"}].sort((item1, item2) => item1.label < item2.label ? -1 : item1.label > item2.label ? 1 : 0))
        setSelectedResponsibility(res.projectTeam.map((item) => {
            return {
                label: item.role,
                value: item.role
            }
        }).concat([{label:"", value: ""}, {label:"-", value: "-"}]).sort((item1, item2) => item1.label < item2.label ? -1 : item1.label > item2.label ? 1 : 0))

        setSelectedAchieved([{label: "NO", value: ""}, {label: "YES", value: "YES"}])

        setAllTeamMembers(res.projectTeam.map((item) => {
            return {
                label: item.role + " (" + item.name + ")",
                value: item.role
            }
        }).concat([{label:"", value: ""}, {label:"-", value: "-"}]).sort((item1, item2) => item1.label < item2.label ? -1 : item1.label > item2.label ? 1 : 0))
      })
      .catch((err) => {
        console.log(err);
      }).finally(() => {
        setLoading(false);
      });

  }, [match.params.assessmentId])

  useEffect(() => {

    props.selectedStakeholder(selectedResponsibility)

  }, [props, selectedResponsibility])

  const navigate = useNavigate()

  const deleteBuildingBREEAMCredit = (id) => {

    //code here to get array index of scope item id=id

    const index = buildingBREEAMAssessment.credits.findIndex(item => item.id === id)

    buildingBREEAMAssessment.credits.splice(index, 1);

    const form = { ...buildingBREEAMAssessment}
    axios.put(process.env.REACT_APP_API_URL + '/buildings_BREEAM_assessments/' + match.params.assessmentId, form)
    setBuildingBREEAMCredits(buildingBREEAMCredits.filter((item) => item.id !== id))
  }

  if (!loading && buildingBREEAMCredits.length > 0) {

    return (
      <div>

<div className="p-3 grid gap-2 sm:gap-7 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">

<div className="flex items-center grid-cols-1 sm:col-span-1 justify-between p-2 md:p-4 bg-white rounded-2xl shadow-md shadow-slate-300">
    <div>
        <div className="text-sm text-gray-500 ">Required Rating: </div>
        <div className="flex items-center">
            <div className="text-xl font-medium text-pacific-blue "><p className="my-1">{buildingBREEAMAssessment.targetRating}</p></div>
        </div>
    </div>
    <div className="text-gray-400">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
            <path strokeLinecap="round" strokeLinejoin="round" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
        </svg>
    </div>
</div>

<div className="flex items-center grid-cols-1 sm:col-span-1 justify-between p-2 md:p-4 bg-white rounded-2xl shadow-md shadow-slate-300">
    <div>
        <div className="text-sm text-gray-500 ">Credits Targeted: </div>
        <div className="flex items-center">
            <div className="text-xl font-medium text-pacific-blue "><p className="my-1">{buildingBREEAMCredits.filter(item => item.creditsTargeted !== "Yes" && item.creditsTargeted !== "-").map(item => item.creditsTargeted).reduce((prev, curr) => Number(prev) + Number(curr), 0) + " out of " + ( (buildingBREEAMCredits.filter(item => item.credits !== "Yes" && item.credits !== "-").map(item => item.credits).reduce((prev, curr) => Number(prev) + Number(curr), 0))) }</p></div>
        </div>
    </div>
    <div className="text-gray-400">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
            <path strokeLinecap="round" strokeLinejoin="round" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
        </svg>
    </div>
</div>

<div className="flex items-center grid-cols-1 sm:col-span-1 justify-between p-2 md:p-4 bg-white rounded-2xl shadow-md shadow-slate-300">
    <div>
        <div className="text-sm text-gray-500 ">Credits Achieved: </div>
        <div className="flex items-center">
            <div className="text-xl font-medium text-pacific-blue "><p className="my-1">{buildingBREEAMCredits.filter(item => item.creditAchieved !== "").map(item => item.credits).reduce((prev, curr) => Number(prev) + Number(curr), 0) }</p></div>
        </div>
    </div>
    <div className="text-gray-400">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
            <path strokeLinecap="round" strokeLinejoin="round" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
        </svg>
    </div>
</div>

<div className="flex items-center grid-cols-1 sm:col-span-1 justify-between p-2 md:p-4 bg-white rounded-2xl shadow-md shadow-slate-300">
    <div>
        <div className="text-sm text-gray-500 ">Progress: </div>
        <div className="flex items-center">
            <div className="text-xl font-medium text-pacific-blue "><p className="my-1">{(((buildingBREEAMCredits.filter(item => item.creditAchieved !== "").map(item => item.credits).reduce((prev, curr) => Number(prev) + Number(curr), 0)) / (buildingBREEAMCredits.filter(item => item.creditsTargeted !== "Yes" && item.creditsTargeted !== "-").map(item => item.creditsTargeted).reduce((prev, curr) => Number(prev) + Number(curr), 0))) *100).toFixed(0) + "%"}</p></div>
        </div>
    </div>
    <div className="text-gray-400">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
            <path strokeLinecap="round" strokeLinejoin="round" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
        </svg>
    </div>
</div>

</div>

    <div className="flex flex-col py-2">
        <div className="overflow-x-auto items-holder-8A">
            <div className="text-eight-text align-middle inline-block min-w-full px-3">
                <div className="shadow-md shadow-slate-300 rounded-2xl">
                <table className="objects-8A min-w-full divide-y divide-gray-200">
                <thead className="bg-pacific-blue-50 z-10 sticky top-0">
                    <tr>
                      <th scope="col" className="px-4 md:px-6 py-3 text-left text-sm font-medium text-congress-blue uppercase tracking-wider"> section
                                                                <div className="w-40">
                                                                <MultiSelect
                                                                    options={aggregateSectionArray(buildingBREEAMCredits).map((item) => {
                                                                        return {
                                                                            label: item.section,
                                                                            value: item.section
                                                                        }
                                                                    })}
                                                                    value={selectedSection}
                                                                    onChange={setSelectedSection}
                                                                    labelledBy="Select"
                                                                />
                                                                </div>
                                                                </th>
                      <th scope="col" className="px-4 md:px-6 py-3 text-left text-sm font-medium text-congress-blue uppercase tracking-wider"> Category Code</th>
                      <th scope="col" className="px-4 md:px-6 py-3 text-left text-sm font-medium text-congress-blue uppercase tracking-wider"> Category Description</th>
                      <th scope="col" className="px-4 md:px-6 py-3 text-left text-sm font-medium text-congress-blue uppercase tracking-wider"> Credit Description</th>
                      <th scope="col" className="px-4 md:px-6 py-3 text-left text-sm font-medium text-congress-blue uppercase tracking-wider [writing-mode:vertical-lr]"> Credits</th>
                      <th scope="col" className="px-4 md:px-6 py-3 text-left text-sm font-medium text-congress-blue uppercase tracking-wider [writing-mode:vertical-lr]"> Minimum</th>
                      <th scope="col" className="px-4 md:px-6 py-3 text-left text-sm font-medium text-congress-blue uppercase tracking-wider [writing-mode:vertical-lr]"> Targeted</th>
                      <th scope="col" className="px-4 md:px-6 py-3 text-left text-sm font-medium text-congress-blue uppercase tracking-wider [writing-mode:vertical-lr]"> Potential</th>
                      <th scope="col" className="px-4 md:px-6 py-3 text-left text-sm font-medium text-congress-blue uppercase tracking-wider"> Credit Overview</th>
                      <th scope="col" className="px-4 md:px-6 py-3 text-left text-sm font-medium text-congress-blue uppercase tracking-wider"> Responsibility
                                                                <div className="w-44">
                                                                <MultiSelect
                                                                    //options={[{label: "Project Manager", value: "Project Manager"}, {label: "Client", value: "Client"}, {label: "No Stakeholder Set", value: ""}, {label: "Specialist Consultant", value: "Specialist Consultant"}, {label: "Architect", value: "Architect"}, {label: "M&E", value: "M&E"}, {label: "Contractor", value: "Contractor"}, {label: "Eight Versa", value: "Eight Versa"}, {label: "Ecologist", value: "Ecologist"}, {label: "BREEAM Accredited Professional", value: "BREEAM Accredited Professional"}, {label: "Civil Engineer", value: "Civil Engineer"}].sort((item1, item2) => item1.label < item2.label ? -1 : item1.label > item2.label ? 1 : 0)}
                                                                    options={allTeamMembers}
                                                                    value={selectedResponsibility}
                                                                    onChange={setSelectedResponsibility}
                                                                    labelledBy="Select"
                                                                />
                                                                </div>
                                                                </th>
                      <th scope="col" className="px-4 md:px-6 py-3 text-left text-sm font-medium text-congress-blue uppercase tracking-wider"> Evidence Required vs Received</th>
                      <th scope="col" className="px-4 md:px-6 py-3 text-left text-sm font-medium text-congress-blue uppercase tracking-wider"> Achieved                                                                <div className="w-40">
                                                                <MultiSelect
                                                                    options={[{label: "NO", value: ""}, {label: "YES", value: "YES"}]}
                                                                    value={selectedAchieved}
                                                                    onChange={setSelectedAchieved}
                                                                    labelledBy="Select"
                                                                />
                                                                </div></th>
                      <th scope="col" className="px-4 md:px-6 py-3 text-left text-sm font-medium text-congress-blue uppercase tracking-wider"> Actions</th>
                    </tr>
                  </thead>

                  <tbody className="m-5 bg-white divide-y divide-gray-200">
                    {buildingBREEAMCredits.filter(item => item.credits !== "0" && item.credits !== 0).filter((itemA) => {
                                                        return selectedSection.find((itemB) => {
                                                            return itemA.section === itemB.value;
                                                        })
                                                    }).filter((itemA) => {
                                                        return selectedAchieved.find((itemB) => {
                                                            return itemA.creditAchieved === itemB.value;
                                                        })
                                                    }).filter((itemA) => {
                                                        return selectedResponsibility.find((itemB) => {
                                                            return expandArrayElementsIntoConcatinatedString(itemA.documentaryEvidence).includes(itemB.value);
                                                        })
                                                    }).map((item) => (
                      <tr key={item.id}>
                        <td className="px-2 md:px-3 py-2"> {item.section} </td>
                        <td className="px-2 md:px-3 py-2"> {item.creditCategoryCode} </td>
                        <td className="px-2 md:px-3 py-2"> {item.creditCategoryDescription} </td>
                        <td className="px-2 md:px-3 py-2"> {item.creditDescription} </td>
                        <td className="px-2 md:px-3 py-2 hover:cursor-pointer" onClick={() => { setShowModal(true); setValueToSendToModal(item.credits); setFieldToSendToModal("BREEAMCreditsValue"); setObjectIdToSendToModal(match.params.assessmentId); setCollectionToSendToModal("buildings_BREEAM_assessments"); setCollectionArrayToSendToModal("credits"); setCollectionArrayIdToSendToModal(item.id) }}> {item.credits} </td>
                        <td className="px-2 md:px-3 py-2 hover:cursor-pointer" onClick={() => { setShowModal(true); setValueToSendToModal(item.creditsMinimumStandards); setFieldToSendToModal("BREEAMMinimumStandardsValue"); setObjectIdToSendToModal(match.params.assessmentId); setCollectionToSendToModal("buildings_BREEAM_assessments"); setCollectionArrayToSendToModal("credits"); setCollectionArrayIdToSendToModal(item.id) }}> {item.creditsMinimumStandards} </td>
                        <td className="px-2 md:px-3 py-2 hover:cursor-pointer" onClick={() => { setShowModal(true); setValueToSendToModal(item.creditsTargeted); setFieldToSendToModal("BREEAMCreditsTargetValue"); setObjectIdToSendToModal(match.params.assessmentId); setCollectionToSendToModal("buildings_BREEAM_assessments"); setCollectionArrayToSendToModal("credits"); setCollectionArrayIdToSendToModal(item.id) }}> {item.creditsTargeted} </td>
                        <td className="px-2 md:px-3 py-2 hover:cursor-pointer" onClick={() => { setShowModal(true); setValueToSendToModal(item.creditsPotential); setFieldToSendToModal("BREEAMCreditsPotentialValue"); setObjectIdToSendToModal(match.params.assessmentId); setCollectionToSendToModal("buildings_BREEAM_assessments"); setCollectionArrayToSendToModal("credits"); setCollectionArrayIdToSendToModal(item.id) }}> {item.creditsPotential} </td>
                        <td className="px-2 md:px-3 py-2"> {item.creditsOverview} </td>
                        <td className="px-2 md:px-3 py-2"> {expandArrayElementsIntoConcatinatedString(item.documentaryEvidence)} </td>
                        <td className="px-4 md:px-3 py-2 ">{item.documentaryEvidence.filter(item => item.filename !== "").length + " / " + item.documentaryEvidence.filter(item => item.evidenceDocumentRequired === "YES").length}{item.files.length > 0 ? <svg className="inline-block fill-current text-bondi-blue" width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 18a6 6 0 100-12 6 6 0 000 12z" /></svg> : ""}</td>
                        <td className="px-4 md:px-3 py-2 hover:cursor-pointer" onClick={item.documentaryEvidence.filter(item => item.filename !== "").length === item.documentaryEvidence.filter(item => item.evidenceDocumentRequired === "YES").length ? (e) => toggleAchieved(item.id) : () => {}}>{item.documentaryEvidence.filter(item => item.filename !== "").length === item.documentaryEvidence.filter(item => item.evidenceDocumentRequired === "YES").length && item.creditAchieved === "" ? <img className="h-10" src="/images/Amber-Tick.png" alt=""></img> : item.documentaryEvidence.filter(item => item.filename !== "").length === item.documentaryEvidence.filter(item => item.evidenceDocumentRequired === "YES").length && item.creditAchieved !== "" ? <img className="h-10" src="/images/GreenTick.png" alt=""></img> : ""}</td>
                        <td className="px-4 md:px-6 py-2 whitespace-nowrap md:pr-10 lg:pr-20 float-right ">
                        <Options deleteFunction={deleteBuildingBREEAMCredit} deleteObject={item.id} edit={`/addOrUpdateBuildingBREEAMAssessmentCredit/${match.params.buildingId}/${match.params.assessmentId}/${item.id}`} ></Options>
                        {/* <Options deleteFunction={deleteBuildingBREEAMCredit} deleteObject={item.id} ></Options> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>

                </table>
                <ModalUpdateValue objectToUpdate={objectIdToSendToModal} fieldToUpdate={fieldToSendToModal} valueToUpdate={valueToSendToModal} collectionToUpdate={collectionToSendToModal} arrayToUpdate={collectionArrayToSendToModal} arrayIdToUpdate={collectionArrayIdToSendToModal} arrayToShow={buildingBREEAMCredits} onClose={() => setShowModal(false)} show={showModal} title={"Update BREEAM Credit"} />
              </div></div></div></div>
        <br></br>
    
      </div>
    )

  } else {

    return (
      <div>
        <p>Data is loading...</p>
      </div>)

  }

}

export default ListBuildingBREEAMCredits
