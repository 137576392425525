import Button from './Button'
import ReactSelect from 'react-select';
import { useState, useEffect } from 'react'
// import ReactTooltip from "react-tooltip"

const Modal = (props) => {

    const [listFiltered, setListFiltered] = useState(props.tableData)
    const [list, setList] = useState(props.tableData)
    const [firstRecord, setFirstRecord] = useState(0)
    const [lastRecord, setLastRecord] = useState(100)

    const [selectedScope, setSelectedScope] = useState('All')
    const [selectedLevel1, setSelectedLevel1] = useState('All')
    const [selectedLevel2, setSelectedLevel2] = useState('All')
    const [selectedLevel3, setSelectedLevel3] = useState('All')
    const [selectedUOMSimple, setSelectedUOMSimple] = useState('All')
    const [selectedDataSource, setSelectedDataSource] = useState('All')
    const [selectedYear, setSelectedYear] = useState('All')
    const [selectedRegion, setSelectedRegion] = useState('All')
    const [inflationFactor, setInflationFactor] = useState(props.inflationFact)
    const [selectedPreviousFactor, setSelectedPreviousFactor] = useState("")

    const FV = (rate, nper, pmt, pv, type) => {
        var pow = Math.pow(1 + rate, nper),
           fv;
        if (rate) {
         fv = (pmt*(1+rate*type)*(1-pow)/rate)-pv*pow;
        } else {
         fv = -1 * (pv + pmt * nper);
        }
        return fv;
      }


    const selectFactor = (e, datasource) => {

        props.onChange(e, datasource)
        props.onClose()

    }


    const aggregateScopesArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.Scope === val.Scope);
            if (index === -1) {
                acc.push({
                    Scope: val.Scope,
                });
            };
            return acc.sort((item1, item2) => item1.Scope < item2.Scope ? -1 : item1.Scope > item2.Scope ? 1 : 0);
        }, []);
    };

    const aggregateLevel1sArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.Level1 === val.Level1);
            if (index === -1) {
                acc.push({
                    Level1: val.Level1,
                });
            };
            return acc.sort((item1, item2) => item1.Level1 < item2.Level1 ? -1 : item1.Level1 > item2.Level1 ? 1 : 0);
        }, []);
    };

    const aggregateLevel2sArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.Level2 === val.Level2);
            if (index === -1) {
                acc.push({
                    Level2: val.Level2,
                });
            };
            return acc.sort((item1, item2) => item1.Level2 < item2.Level2 ? -1 : item1.Level2 > item2.Level2 ? 1 : 0);
        }, []);
    };

    const aggregateLevel3sArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.Level3 === val.Level3);
            if (index === -1) {
                acc.push({
                    Level3: val.Level3,
                });
            };
            return acc.sort((item1, item2) => item1.Level3 < item2.Level3 ? -1 : item1.Level3 > item2.Level3 ? 1 : 0);
        }, []);
    };

    const aggregateUOMSimplesArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.UOMsimple === val.UOMsimple);
            if (index === -1) {
                acc.push({
                    UOMsimple: val.UOMsimple,
                });
            };
            return acc.sort((item1, item2) => item1.UOMsimple < item2.UOMsimple ? -1 : item1.UOMsimple > item2.UOMsimple ? 1 : 0);
        }, []);
    };

    const aggregateDataSourcesArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.DataSource === val.DataSource);
            if (index === -1) {
                acc.push({
                    DataSource: val.DataSource,
                });
            };
            return acc.sort((item1, item2) => item1.DataSource < item2.DataSource ? -1 : item1.DataSource > item2.DataSource ? 1 : 0);
        }, []);
    };

    const aggregateYearArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.Year === val.Year);
            if (index === -1) {
                acc.push({
                    Year: val.Year,
                });
            };
            return acc.sort((item1, item2) => item1.Year < item2.Year ? -1 : item1.Year > item2.Year ? 1 : 0);
        }, []);
    };

    const aggregateRegionsArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.Region === val.Region);
            if (index === -1) {
                acc.push({
                    Region: val.Region,
                });
            };
            return acc.sort((item1, item2) => item1.Region < item2.Region ? -1 : item1.Region > item2.Region ? 1 : 0);
        }, []);
    };

    const aggregateEmissionFactorSourcesArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.emissionFactorSource === val.emissionFactorSource);
            if (index === -1) {
                acc.push({
                    emissionFactorSource: val.emissionFactorSource,
                    emissionFactor: val.emissionFactor,
                });
            };
            return acc.sort((item1, item2) => item1.emissionFactorSource < item2.emissionFactorSource ? -1 : 0)
        }, []);
    };

    const styles = {
        option: provided => ({
            ...provided,
            color: 'black'
        }),
        control: provided => ({
            ...provided,
            color: 'black',
            width: 160,
            fontSize: 13
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'black'
        }),
        menu: provided => ({
            ...provided,
            color: 'black',
            width: 160,
            fontSize: 13
        }),
    }

    const clearFilters = () => {

        setSelectedScope('All')
        setSelectedLevel1('All')
        setSelectedLevel2('All')
        setSelectedLevel3('All')
        setSelectedUOMSimple('All')
        setSelectedDataSource('All')
        setSelectedYear('All')
        setSelectedRegion('All')

    }

    const prev100Records = () => {
        if (firstRecord > 99) {

            setFirstRecord(firstRecord - 100)
            setLastRecord(lastRecord - 100)

        }

    }

    const next100Records = () => {

        if (lastRecord <= listFiltered.length) {

            setFirstRecord(firstRecord + 100)
            setLastRecord(lastRecord + 100)

        }

    }


    useEffect(() => {

        setListFiltered(list)

        if (selectedScope !== "All") {
            setListFiltered(listFiltered => listFiltered.filter((item) => item.Scope === selectedScope.value))
            setFirstRecord(0)
            setLastRecord(100)
        }

        if (selectedLevel1 !== "All") {
            setListFiltered(listFiltered => listFiltered.filter((item) => item.Level1 === selectedLevel1.value))
            setFirstRecord(0)
            setLastRecord(100)
        }

        if (selectedLevel2 !== "All") {
            setListFiltered(listFiltered => listFiltered.filter((item) => item.Level2 === selectedLevel2.value))
            setFirstRecord(0)
            setLastRecord(100)
        }

        if (selectedLevel3 !== "All") {
            setListFiltered(listFiltered => listFiltered.filter((item) => item.Level3 === selectedLevel3.value))
            setFirstRecord(0)
            setLastRecord(100)
        }

        if (selectedUOMSimple !== "All") {
            setListFiltered(listFiltered => listFiltered.filter((item) => item.UOMsimple === selectedUOMSimple.value))
            setFirstRecord(0)
            setLastRecord(100)
        }

        if (selectedDataSource !== "All") {
            setListFiltered(listFiltered => listFiltered.filter((item) => item.DataSource === selectedDataSource.value))
            setFirstRecord(0)
            setLastRecord(100)
        }

        if (selectedYear !== "All") {
            setListFiltered(listFiltered => listFiltered.filter((item) => item.Year === selectedYear.value))
            setFirstRecord(0)
            setLastRecord(100)
        }

        if (selectedRegion !== "All") {
            setListFiltered(listFiltered => listFiltered.filter((item) => item.Region === selectedRegion.value))
            setFirstRecord(0)
            setLastRecord(100)
        }

    }, [selectedScope, selectedLevel1, selectedLevel2, selectedLevel3, selectedUOMSimple, selectedDataSource, list, selectedYear, selectedRegion])


    useEffect(() => {

        setInflationFactor(props.inflationFact)

    }, [props.inflationFact])

    if (!props.show) {
        return null
    }
    return (
        <div className="modal z-50">
            <div className="modal-content">
                <div className="modal-header">

                    <div className="grid sm:grid-cols-2 lg:grid-cols-2 grid-rows-1">

                        <div>
                            <p className="modal-title">{props.title}</p>
                        </div>

                        <div className="text-right">
                            <Button text="<< Previous 100" onClick={() => prev100Records()}></Button>
                            <Button text="Next 100 >>" onClick={() => next100Records()}></Button>
                            <Button color="bg-bondi-blue" text="Clear Filters" onClick={() => clearFilters()} />
                            <Button text="Close" onClick={props.onClose}></Button>
                        </div>

                    </div>

                </div>

                <div className="modal-body">

                    {props.multiplierOrFactor === "factor" &&
                    <div className="form-control-8A px-4 md:px-6">
                        <label>Already Used Factors</label>
                        <div className="form-control-8A grid grid-cols-4 gap-2">
                            <select className="form-control-8A col-span-3 text-xs" value={selectedPreviousFactor} onChange={(e) => setSelectedPreviousFactor(e.target.value)}>
                                {[<option className="text-xs" key="abc123" value="">Please Select...</option>].concat(
                                (aggregateEmissionFactorSourcesArray(props.allSubItems).map((item) => (
                                    <option className="text-xs" key={item.emissionFactorSource} value={item.emissionFactorSource}>{item.emissionFactorSource}</option>
                                ))))}
                            </select>
                            {selectedPreviousFactor !== "" && <Button text="Select this Factor" onClick={() => selectFactor(props.allSubItems.find(item => item.emissionFactorSource === selectedPreviousFactor).emissionFactor, selectedPreviousFactor)}></Button>}
                        </div>
                    </div>
                    }

                    <div className="form-control-8A px-4 md:px-6">
                        <label>Inflation Factor</label>
                        <input type="number" placeholder="Enter Inflation Factor Here" value={inflationFactor} onChange={(e) => setInflationFactor(Number(e.target.value))} />
                    </div>
                    <br></br>
                    {props.tableData &&
                        <table className="objects-8A min-w-full divide-y divide-gray-200">

                            <thead className="bg-pacific-blue-50 sticky top-0">
                                <tr>
                                    <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Scope <svg onClick={() => setSelectedScope('All')} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle float-right pb-1" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
</svg><ReactSelect
                                        styles={styles}
                                        value={selectedScope}
                                        onChange={setSelectedScope}
                                        options={aggregateScopesArray(listFiltered).map((item) => {
                                            return {
                                                label: item.Scope,
                                                value: item.Scope
                                            }
                                        })} /></th>

                                    <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Level1 <svg onClick={() => setSelectedLevel1('All')} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle float-right pb-1" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
</svg><ReactSelect
                                        styles={styles}
                                        value={selectedLevel1}
                                        onChange={setSelectedLevel1}
                                        options={aggregateLevel1sArray(listFiltered).map((item) => {
                                            return {
                                                label: item.Level1,
                                                value: item.Level1
                                            }
                                        })} /></th>

                                    <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Level2 <svg onClick={() => setSelectedLevel2('All')} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle float-right pb-1" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
</svg><ReactSelect
                                        styles={styles}
                                        value={selectedLevel2}
                                        onChange={setSelectedLevel2}
                                        options={aggregateLevel2sArray(listFiltered).map((item) => {
                                            return {
                                                label: item.Level2,
                                                value: item.Level2
                                            }
                                        })} /></th>
                                    <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Level3 <svg onClick={() => setSelectedLevel3('All')} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle float-right pb-1" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
</svg><ReactSelect
                                        styles={styles}
                                        value={selectedLevel3}
                                        onChange={setSelectedLevel3}
                                        options={aggregateLevel3sArray(listFiltered).map((item) => {
                                            return {
                                                label: item.Level3,
                                                value: item.Level3
                                            }
                                        })} /></th>

                                    <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> UOMsimple <svg onClick={() => setSelectedUOMSimple('All')} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle float-right pb-1" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
</svg><ReactSelect
                                        styles={styles}
                                        value={selectedUOMSimple}
                                        onChange={setSelectedUOMSimple}
                                        options={aggregateUOMSimplesArray(listFiltered).map((item) => {
                                            return {
                                                label: item.UOMsimple,
                                                value: item.UOMsimple
                                            }
                                        })} /></th>
                                    <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> GHG Conversion - Click to Select</th>
                                    <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> GHG Conversion (Inflation Adjusted)- Click to Select</th>
                                    <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Data Source <svg onClick={() => setSelectedDataSource('All')} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle float-right pb-1" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
</svg><ReactSelect
                                        styles={styles}
                                        value={selectedDataSource}
                                        onChange={setSelectedDataSource}
                                        options={aggregateDataSourcesArray(listFiltered).map((item) => {
                                            return {
                                                label: item.DataSource,
                                                value: item.DataSource
                                            }
                                        })} /></th>
                                    <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Year <svg onClick={() => setSelectedYear('All')} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle float-right pb-1" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
</svg><ReactSelect
                                        styles={styles}
                                        value={selectedYear}
                                        onChange={setSelectedYear}
                                        options={aggregateYearArray(listFiltered).map((item) => {
                                            return {
                                                label: item.Year,
                                                value: item.Year
                                            }
                                        })} /></th>
                                    <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Region <svg onClick={() => setSelectedRegion('All')} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle float-right pb-1" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
</svg><ReactSelect
                                        styles={styles}
                                        value={selectedRegion}
                                        onChange={setSelectedRegion}
                                        options={aggregateRegionsArray(listFiltered).map((item) => {
                                            return {
                                                label: item.Region,
                                                value: item.Region
                                            }
                                        })} /></th>
                                    <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Comments</th>
                                    <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Column Text</th>
                                </tr>
                            </thead>

                            <tbody>
                                {listFiltered.slice(firstRecord, lastRecord).map((item) => (
                                    <tr key={item.id}>
                                        <td className="px-4 md:px-6"> {item.Scope} </td>
                                        <td className="px-4 md:px-6"> {item.Level1} </td>
                                        <td className="px-4 md:px-6"> {item.Level2} </td>
                                        <td className="px-4 md:px-6"> {item.Level3}</td>
                                        <td className="px-4 md:px-6"> {item.UOMsimple}</td>
                                        <td className="px-4 md:px-6"> <Button text={item.GHGConversion} onClick={() => selectFactor(item.GHGConversion, item.id + "; " + item.DataSource + "; " + item.Scope + "; " + item.Level1 + "; " + item.Level2 + "; " + item.Level3 + "; " + item.UOMsimple + "; " + item.Year + "; " + item.Region + "; " + item.Comments + "; " + item.ColumnText )}></Button></td>
                                        <td className="px-4 md:px-6"> {item.UOMsimple === "EUR" || item.UOMsimple === "GBP" || item.UOMsimple === "USD" ? <Button text={((item.GHGConversion / FV(inflationFactor, Number(props.reportingYear)-Number(item.Year), 0, 1, 0)).toFixed(5))*-1} onClick={() => selectFactor(((item.GHGConversion / FV(inflationFactor, Number(props.reportingYear)-Number(item.Year), 0, 1, 0)).toFixed(5))*-1, item.id + "; " + item.DataSource + "; " + item.Scope + "; " + item.Level1 + "; " + item.Level2 + "; " + item.Level3 + "; " + item.UOMsimple + "; " + item.Year + "; " + item.Region + "; " + item.Comments + "; " + item.ColumnText + "; Inflation Adjusted (at rate: " + inflationFactor * 100 + "%)" )}></Button> : "-"}</td>
                                        <td className="text-xs p-1"> {item.DataSource}</td>
                                        <td className="px-4 md:px-6"> {item.Year}</td>
                                        <td className="px-4 md:px-6"> {item.Region}</td>
                                        <td className="px-4 md:px-6"> {item.Comments}</td>
                                        <td className="px-4 md:px-6"> {item.ColumnText}</td>
                                    </tr>
                                ))}
                            </tbody>

                        </table>
                    }
                </div>
                <div className="modal-footer">
                    <Button text="<< Previous 100" onClick={() => prev100Records()}></Button>
                    <Button text="Next 100 >>" onClick={() => next100Records()}></Button>
                    <Button text="Close" onClick={props.onClose}>Close</Button>
                </div>
            </div>
        </div>
    )
}

Modal.defaultProps = {
    reportingYear: "2023",
    inflationFact: 0
}


export default Modal


