import { useEffect, useState } from 'react'
import axios from 'axios'
import Button from '../UI/Button'
import Header from '../UI/Header'
import { useNavigate } from 'react-router-dom'
import Sidebar from '../UI/Sidebar'

const ListSites = () => {

    const [sites, setSites] = useState([])

    const [sidebarToggle, setSidebarToggle] = useState(false)

    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {

        setLoading(true)

        axios.get(process.env.REACT_APP_API_URL + '/sites')
            .then((res) => {
                setSites(res.data)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });

    }, [])

    const deleteSite = (id) => {
        window.confirm('Are you sure you want to delete it?')
        // axios.delete(process.env.REACT_APP_API_URL + '/sites/' + id)
        // setSites(sites.filter((site) => site.id !== id))
    }

    const editSite = (id) => {
        navigate(`/addOrUpdateSite/${id}`);
    }

    const viewSite = (id) => {
        navigate(`/viewSite/${id}`);
    }

    if (sites.length > 0 && !loading) {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">

                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Sites" section="Select Site" iconFileName="Site-Hover" showUserMenu='true' />
                        {/*                             <div>
                                <table className = "objects-8A">

                                    <thead>
                                        <tr>
                                            <th> Product Name </th>
                                            <th> Product Description </th>
                                            <th> LCA Goal and Scope </th>
                                            <th> Actions </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {products.map((product) => (
                                            <tr key = {product.id}>
                                                <td> {product.productName} </td>   
                                                <td> {product.productDescription}</td>
                                                <td> {product.productLCAGoalAndScope}</td>
                                                <td> <Button color="black" text="View" onClick={() => viewProduct(product.id)}/><Button color="black" text="Edit" onClick={() => editProduct(product.id)}/><Button color="black" text="Delete" onClick={() => deleteProduct(product.id)}/></td>
                                            </tr>
                                        ))}
                                    </tbody>

                                </table>
                            </div> */}

                        <div className="flex flex-wrap">
                            {sites.map((site) => (
                                <div className="bg-slate-200 w-64 shadow-xl border-2 p-5 m-3 content-center rounded-md" key={site.id}>
                                    {/* <img className="p-1 h-24 w-32 text-center" src={"/images/site_images/" + site.siteName + ".jpg"} /> */}
                                    <img className="p-1 h-24 w-32 text-center" src={`https://storage.googleapis.com/public_images_bucket/${site.id}/` + site.siteName + ".jpg"} alt="" />
                                    <p className="text-xs pt-1">{site.siteName}</p>
                                    <p className="mb-1 h-8 overflow-hidden text-xs">{site.siteDescription}</p>
                                    <p className="text-xs">{site.siteLatitude}</p>
                                    <p className="text-xs">{site.siteLongitude}</p>
                                    <Button color="black" text="View" onClick={() => viewSite(site.id)} /><Button color="black" text="Edit" onClick={() => editSite(site.id)} /><Button color="black" text="Delete" onClick={() => deleteSite(site.id)} />
                                </div>
                            ))}
                        </div>
                        <br></br>
                        <Button color="black" text="Add New Site" onClick={() => navigate('/addOrUpdateSite/_add')} />
                    </div>
                </div>

            </div>
        )

    } else {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Sites" section="Select Site" iconFileName="Site-Hover" />
                        <p>Data is loading</p>
                    </div>

                </div>

            </div>
        )

    }

}

export default ListSites
