import { useState, useEffect } from 'react'
import axios from 'axios'
import Header from '../UI/Header'
import { useNavigate, useMatch } from 'react-router-dom'

const AddOrUpdateBuildingConstructionSiteImpactAssessment = () => {

    const match = useMatch('/addOrUpdateBuildingConstructionSiteImpactAssessment/:buildingId/:buildingConstructionSiteImpactAssessmentId')

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [buildingId, setBuildingId] = useState('')
    const [siteManager, setSiteManager] = useState('')
    const [sustainabilityChampion, setSustainabilityChampion] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')

    useEffect(() => {

        setBuildingId(match.params.buildingId)

        if (match.params.buildingConstructionSiteImpactAssessmentId !== "_add") {

            setLoading(true)

            axios.get(process.env.REACT_APP_API_URL + '/site_log/' + match.params.buildingConstructionSiteImpactAssessmentId)
                .then((res) => res.data)
                .then((res) => {
                    setBuildingId(res.buildingId)
                    setSiteManager(res.siteManager)
                    setSustainabilityChampion(res.sustainabilityChampion)
                    setStartDate(res.startDate)
                    setEndDate(res.endDate)
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });

        }
    }, [match.params.buildingConstructionSiteImpactAssessmentId, match.params.buildingId])

    const addForm = async (form) => {
        await axios.post(process.env.REACT_APP_API_URL + '/site_logs', form)
    }

    const updateForm = async (form) => {
        await axios.put(process.env.REACT_APP_API_URL + '/site_log/' + match.params.buildingConstructionSiteImpactAssessmentId, form)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (match.params.buildingConstructionSiteImpactAssessmentId !== "_add") {
            updateForm({ buildingId, siteManager, sustainabilityChampion, startDate, endDate }).then(res => { navigate(-1) })
        } else {
            addForm({ buildingId, siteManager, sustainabilityChampion, startDate, endDate }).then(res => { navigate(-1) })
        }
    }

    if (!loading) {

        return (

                <div className='flex items-center bg-jungle-mist-100 min-h-screen min-w-fit'>
                    <div className="container-form-8A">

                    <Header title="Building Construction Site Impact Assessment - Add / Edit Building Building Construction Site Impact Assessment" section="Building Construction Site Impact Assessment Main Details" iconFileName="Building-Hover" />

                    <form className="add-form-8A" onSubmit={onSubmit}>

                        <div className="form-control-8A">
                            <label>Building Id</label>
                            <input type="text" placeholder="" value={buildingId} onChange={(e) => setBuildingId(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Site Manager</label>
                            <input type="text" placeholder="" value={siteManager} onChange={(e) => setSiteManager(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Sustainability Champion</label>
                            <input type="text" placeholder="" value={sustainabilityChampion} onChange={(e) => setSustainabilityChampion(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Start Date</label>
                            <input type="date" placeholder="Enter Start Date Here" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>End Date</label>
                            <input type="date" placeholder="Enter End Date Here" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                        </div>

                        <input type="submit" value="Save" className="btn-8A btn-block-8A" />

                    </form>

                </div>
            </div>
        )

    } else {

        return (
            <div>
                <Header title="Building EPC - Add / Edit Building EPC" section="Building EPC Main Details" iconFileName="Building-Hover"/>
                <p>Data is loading...</p>
            </div>)

    }

}

export default AddOrUpdateBuildingConstructionSiteImpactAssessment