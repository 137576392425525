import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;


const register = (email, password) => {
    return axios.post(API_URL + "/register", {
        email,
        password,
    });
};

const setToken = (email, password) => {
    return axios.post(API_URL + "/generate_2fa", {
        email,
        password,
    })
        .then((response) => {
            console.log(response.data)
            if (response.data === "Email sent") {
                // set user email in session storage to later validate the otp. 
                // It has to be in local storage so we don't get errors 
                // TODO: move to REDIS
                sessionStorage.setItem("user", email)
            }
            return response;
        }
        )

};

const validateToken = (form) => {
    return axios.post(API_URL + "/validate_2fa", form)
        .then((response) => {
            if (response.data.access_token) {
                localStorage.setItem("user", JSON.stringify(response.data));
                localStorage.setItem("userName", form.user)
            }
            return response;
        }
        )

};


const login = (email, password) => {
    return axios
        .post(API_URL + "/login", {
            email,
            password,
        })
        .then((response) => {
            if (response.data.access_token) {
                localStorage.setItem("user", JSON.stringify(response.data));
                localStorage.setItem("userName", email)
                //axios.get(API_URL + "/stat").then((res)=>console.log(res))
            }
            return response;
        })


};
const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("userName");
    localStorage.removeItem("tab");
    sessionStorage.removeItem("user")
    sessionStorage.removeItem("siteArea")
    window.location.replace('/login')

};
const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

const getCurrentUserName = () => {
    return localStorage.getItem("userName");
};

// const getRole = () => {
//     const localStorageValue = JSON.parse(localStorage.getItem("user"))
//     const roles = localStorageValue?.roles
//     return roles
// }

// const getCompany = () => {
//     const localStorageValue = JSON.parse(localStorage.getItem("user"))
//     const company = localStorageValue?.company
//     return company
// }

export default {
    register,
    login,
    logout,
    getCurrentUser,
    getCurrentUserName,
    setToken,
    validateToken
    // getRole,
    // getCompany
};