import axios from "axios";
import authHeader from "./AuthHeader";
const API_URL = process.env.REACT_APP_API_URL;
const getPublicContent = () => {
    return axios.get(API_URL + "all");
};
const getUserBoard = () => {
    return axios.get(API_URL + "user", { headers: authHeader() });
};
const getAdminBoard = () => {
    return axios.get(API_URL + "admin", { headers: authHeader() });
};
const getStores = () => {
    return axios.get(API_URL + "/stores", { headers: authHeader() })
}
const getClients = () => {
    return axios.get(API_URL + "/clients", { headers: authHeader() })
}


const getEndpoint = (props) => {
    return axios.get(API_URL + props, { headers: authHeader() } )
}

const postEndpoint = (props) => {
    return axios.post(API_URL + props, { headers: authHeader() } )
}

export default {
    getPublicContent,
    getUserBoard,
    getAdminBoard,
    getStores,
    getClients,
    getEndpoint,
    postEndpoint,
}; 