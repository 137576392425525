import { useState, useEffect } from 'react'
import axios from 'axios'
import Header from '../UI/Header'
import { useNavigate, useMatch } from 'react-router-dom'

const AddOrUpdateCredit = () => {

    const match = useMatch('/addOrUpdateCredit/:creditId')

    const navigate = useNavigate()

    const [loadingProjects, setLoadingProjects] = useState(false)
    const [loadingCredits, setLoadingCredits] = useState(false)

    const [projects, setProjects] = useState([])

    const [projectId, setProjectId] = useState('')
    const [creditSerialNumber, setCreditSerialNumber] = useState('')
    const [creditVintage, setCreditVintage] = useState('')
    const [creditStatus, setCreditStatus] = useState('')
    const [creditQuantity, setCreditQuantity] = useState(0)
    const [creditBatch, setCreditBatch] = useState(0)
    const [creditIssuanceDate, setCreditIssuanceDate] = useState('')
    const [creditMonitoringPeriodFrom, setCreditMonitoringPeriodFrom] = useState('')
    const [creditMonitoringPeriodTo, setCreditMonitoringPeriodTo] = useState('')
    const [creditRetirementDate, setCreditRetirementDate] = useState('')
    const [creditSplit, setCreditSplit] = useState('')
    const [creditType, setCreditType] = useState('')
    const [creditNumberFrom, setCreditNumberFrom] = useState(0)
    const [creditNumberTo, setCreditNumberTo] = useState(0)
    const [creditBuffer, setCreditBuffer] = useState('')

    useEffect(() => {

        if (match.params.creditId === "_add") {

            setLoadingProjects(true)

            axios.get(process.env.REACT_APP_API_URL + '/offset_projects')
                .then((res) => {
                    setProjects(res.data);
                    setProjectId(res.data[0].id);
                    setCreditSerialNumber(res.data[0].projectNCSId + "-" + creditVintage + "-" + creditBatch + "-" + creditNumberFrom + "-" + creditNumberTo);
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoadingProjects(false);
                });

        }

        if (match.params.creditId !== "_add") {

            setLoadingCredits(true)
            setLoadingProjects(true)

            axios.get(process.env.REACT_APP_API_URL + '/offset_credits/' + match.params.creditId)
                .then((res) => {
                    setProjectId(res.data.projectId)
                    setCreditSerialNumber(res.data.creditSerialNumber)
                    setCreditVintage(res.data.creditVintage)
                    setCreditStatus(res.data.creditStatus)
                    setCreditQuantity(res.data.creditQuantity)
                    setCreditBatch(res.data.creditBatch)
                    setCreditIssuanceDate(res.data.creditIssuanceDate)
                    setCreditMonitoringPeriodFrom(res.data.creditMonitoringPeriodFrom)
                    setCreditMonitoringPeriodTo(res.data.creditMonitoringPeriodTo)
                    setCreditRetirementDate(res.data.creditRetirementDate)
                    setCreditSplit(res.data.creditSplit)
                    setCreditType(res.data.creditType)
                    setCreditNumberFrom(res.data.creditNumberFrom)
                    setCreditNumberTo(res.data.creditNumberTo)
                    setCreditBuffer(res.data.creditBuffer)
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoadingCredits(false);
                });

            axios.get(process.env.REACT_APP_API_URL + '/offset_projects')
                .then((res) => {
                    setProjects(res.data);
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoadingProjects(false);
                });

        }

    }, [match.params.creditId, creditVintage, creditBatch, creditNumberFrom, creditNumberTo]);


    useEffect(() => {

        if (projects.length > 0 && !loadingCredits && !loadingProjects) {

            setCreditSerialNumber(projects.find(project => project.id === projectId).projectNCSId + "-" + creditVintage + "-" + creditBatch + "-" + creditNumberFrom + "-" + creditNumberTo);

        }

    }, [projects, loadingCredits, loadingProjects, projectId, creditVintage, creditBatch, creditNumberFrom, creditNumberTo]);

    const addForm = async (form) => {
        await axios.post(process.env.REACT_APP_API_URL + '/offset_credits', form)
    }

    const updateForm = async (form) => {
        await axios.put(process.env.REACT_APP_API_URL + '/offset_credits/' + match.params.creditId, form)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (match.params.creditId !== "_add") {
            updateForm({ projectId, creditSerialNumber, creditVintage, creditStatus, creditQuantity, creditBatch, creditIssuanceDate, creditMonitoringPeriodFrom, creditMonitoringPeriodTo, creditRetirementDate, creditSplit, creditType, creditNumberFrom, creditNumberTo, creditBuffer }).then(res => { navigate(-1) })
        } else {
            addForm({ projectId, creditSerialNumber, creditVintage, creditStatus, creditQuantity, creditBatch, creditIssuanceDate, creditMonitoringPeriodFrom, creditMonitoringPeriodTo, creditRetirementDate, creditSplit, creditType, creditNumberFrom, creditNumberTo, creditBuffer }).then(res => { navigate(-1) })
        }
    }


    if (projects.length > 0 && !loadingCredits && !loadingProjects) {

        return (

            <div className="container-form-8A">

                <Header title="Credit - Add / Edit Credit" section="Credit Main Details" showButtons={false} />

                <form className="add-form-8A" onSubmit={onSubmit}>

                    <div className="form-control-8A">
                        <label>Project</label>
                        <select value={projectId} onChange={(e) => setProjectId(e.target.value)}>
                            {projects.map((project) => (
                                <option key={project.id} value={project.id}>{project.projectName}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-control-8A">
                        <label>Type</label>
                        <select value={creditType} onChange={(e) => setCreditType(e.target.value)}>
                            <option value="">Please Select...</option>
                            <option value="Pre-Credits (NCS-PC)">Pre-Credits (NCS-PC)</option>
                            <option value="Credits (NCS-C)">Credits (NCS-C)</option>
                        </select>
                    </div>
                    <div className="form-control-8A">
                        <label>Vintage</label>
                        <input type="text" placeholder="Enter Vintage Here" value={creditVintage} onChange={(e) => setCreditVintage(e.target.value)} />
                    </div>
                    <div className="form-control-8A">
                        <label>Batch</label>
                        <input type="number" placeholder="Enter Batch Here" value={creditBatch} onChange={(e) => setCreditBatch(Number(e.target.value))} />
                    </div>
                    <div className="form-control-8A">
                        <label>Status</label>
                        <select value={creditStatus} onChange={(e) => setCreditStatus(e.target.value)}>
                            <option value="">Please Select...</option>
                            <option value="Issued">Issued</option>
                            <option value="Retired">Retired</option>
                            <option value="Cancelled">Cancelled</option>
                        </select>
                    </div>
                    <div className="form-control-8A">
                        <label>Quantity</label>
                        <input type="number" placeholder="Enter Quantity Here" value={creditQuantity} onChange={(e) => setCreditQuantity(Number(e.target.value))} />
                    </div>

                    <div className="form-control-8A">
                        <label>Credit Number From</label>
                        <input type="number" placeholder="Enter 1st Credit number Here" value={creditNumberFrom} onChange={(e) => setCreditNumberFrom(Number(e.target.value))} />
                    </div>

                    <div className="form-control-8A">
                        <label>Credit Number To</label>
                        <input type="number" placeholder="Enter Last Credit number Here" value={creditNumberTo} onChange={(e) => setCreditNumberTo(Number(e.target.value))} />
                    </div>

                    <div className="form-control-8A">
                        <label>Buffer</label>
                        <select value={creditBuffer} onChange={(e) => setCreditBuffer(e.target.value)}>
                            <option value="">Please Select...</option>
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                        </select>
                    </div>

                    <div className="form-control-8A">
                        <label>Serial Number</label>
                        <input type="text" placeholder="Enter Serial Number Here" value={creditSerialNumber} onChange={(e) => setCreditSerialNumber(e.target.value)} />
                    </div>

                    <input type="submit" value="Save" className="btn-8A btn-block-8A" />

                </form>

            </div>
        )

    } else {

        return (
            <div>
                <Header title="Credit - Add / Edit Credit" section="Credit Main Details" showButtons={false} />
                <p>Data is loading...</p>
            </div>)

    }

}

export default AddOrUpdateCredit