import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import authHeader from './services/AuthHeader';
import { useNavigate } from "react-router-dom";


const localStorageValue = JSON.parse(localStorage.getItem("user"))
let authTokenRequest;


// function makeActualAuthenticationRequest(){
//   axios.post(process.env.REACT_APP_API_URL+'/clients',{ headers: authHeader() })
// }
// This function makes a call to get the auth token
// or it returns the same promise as an in-progress call to get the auth token
function getAuthToken() {
  if (!authTokenRequest) {
    authTokenRequest = getIsTokenValid();
    authTokenRequest.then(resetAuthTokenRequest, resetAuthTokenRequest);
  }
  return authTokenRequest;
}

function resetAuthTokenRequest() {
  authTokenRequest = null;
}
function getIsTokenValid() {
  axios.post(process.env.REACT_APP_API_URL + "/token", {}, { headers: authHeader() })
    .then((response) => {
      if (response.status === 200) {
        return localStorageValue.access_token
      }
      else {
        return false
      }
    })
}
//const access_token = localStorageValue?.access_token
// Add a request interceptor
axios.interceptors.request.use(
  config => {
    const token = JSON.parse(localStorage.getItem("user"))?.access_token
    if (token) {
      // console.log(config)
      config.headers['Authorization'] = 'Bearer ' + token
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)
axios.interceptors.response.use(
  response => {
    return response
  },
  function (err) {
    const error = err.response;
    // if (error.status === 401 && error.config && !error.config.__isRetryRequest) {
    //   return getAuthToken().then(response => {
    //     //saveTokens(response.data);
    //     error.config.__isRetryRequest = true;
    //     return axios(error.config);
    //   });
    // }
    if (
      error.status === 422 || error.status === 401// &&
      // originalRequest.url === 'http://127.0.0.1:3000/v1/auth/token'
    ) {
      // localStorage.removeItem("user");
      // localStorage.removeItem("userName");

      window.location.href = process.env.REACT_APP_URL + '/login'
      // router.push('/login')
      // return Promise.reject(error)
    }

    // if (error.response.status === 401 && !originalRequest._retry) {
    //   originalRequest._retry = true
    //   const refreshToken = localStorageService.getRefreshToken()
    //   return axios
    //     .post('/auth/token', {
    //       refresh_token: refreshToken
    //     })
    //     .then(res => {
    //       if (res.status === 201) {
    //         localStorageService.setToken(res.data)
    //         axios.defaults.headers.common['Authorization'] =
    //           'Bearer ' + localStorageService.getAccessToken()
    //         return axios(originalRequest)
    //       }
    //     })
    // }
    return Promise.reject(error)
  }
)

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
