import { useEffect, useState } from "react";
import Header from "../UI/Header";
import axios from "axios";
import { Doughnut, Bar, Line } from "react-chartjs-2";
import Sidebar from "../UI/Sidebar";
import CountUp from "react-countup";
import { MultiSelect } from "react-multi-select-component";
import ReactApexChart from "react-apexcharts";
import Button from '../UI/Button'

import {
    Chart as ChartJS,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle,
} from "chart.js";

ChartJS.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
);


const DashboardEvents = () => {

    const [loading, setLoading] = useState(false);

    const [sidebarToggle, setSidebarToggle] = useState(false);

    const [numberOfRecordsToShow, setNumberOfRecordsToShow] = useState(50);

    const [eventFootprints, setEventFootprints] = useState([]);
    const [events, setEvents] = useState([]);

    const [carbonEmissionsForSubItems, setCarbonEmissionsForSubItems] = useState([]);
    const [labelsForSubItems, setLabelsForSubItems] = useState([]);

    const [carbonEmissions, setCarbonEmissions] = useState([]);
    const [labels, setLabels] = useState([]);

    const [carbonEmissionsForCategory, setCarbonEmissionsForCategory] = useState([]);
    const [labelsForCategory, setLabelsForCategory] = useState([]);

    const [carbonEmissionsPerYearActuals, setCarbonEmissionsPerYearActuals] = useState([]);
    const [carbonEmissionsPerYearProjections, setCarbonEmissionsPerYearProjections] = useState([]);
    const [carbonEmissionsPerYearTargets, setCarbonEmissionsPerYearTargets] = useState([]);

    const [carbonEmissionsPerYearActualsPerAttendee, setCarbonEmissionsPerYearActualsPerAttendee] = useState([]);
    const [carbonEmissionsPerYearProjectionsPerAttendee, setCarbonEmissionsPerYearProjectionsPerAttendee] = useState([]);
    const [carbonEmissionsPerYearTargetsPerAttendee, setCarbonEmissionsPerYearTargetsPerAttendee] = useState([]);

    const [stackedLineChartCarbonEmissions, setStackedLineChartCarbonEmissions] = useState([]);
    const [stackedLineChartLabels, setStackedLineChartLabels] = useState([]);

    const [stackedBarChartCarbonEmissions, setStackedBarChartCarbonEmissions] = useState([]);
    const [stackedBarChartCarbonEmissionsPerAttendee, setStackedBarChartCarbonEmissionsPerAttendee] = useState([]);
    const [stackedBarChartCarbonEmissionsPerGBP, setStackedBarChartCarbonEmissionsPerGBP] = useState([]);
    const [stackedBarChartLabels, setStackedBarChartLabels] = useState([]);
    const [stackedBarChartLabelsForSubItems, setStackedBarChartLabelsForSubItems] = useState([]);
    const [stackedBarChartCarbonEmissionsForSubItems, setStackedBarChartCarbonEmissionsForSubItems] = useState([]);
    const [stackedBarChartCarbonEmissionsPerAttendeeForSubItems, setStackedBarChartCarbonEmissionsPerAttendeeForSubItems] = useState([]);
    const [stackedBarChartCarbonEmissionsPerGBPForSubItems, setStackedBarChartCarbonEmissionsPerGBPForSubItems] = useState([]);

    const [singleHorizontalBarChartCarbonEmissionsPerAttendee, setSingleHorizontalBarChartCarbonEmissionsPerAttendee] = useState([]);
    const [singleHorizontalBarChartCarbonEmissionsPerGBP, setSingleHorizontalBarChartCarbonEmissionsPerGBP] = useState([]);
    const [singleHorizontalBarChartLabels, setSingleHorizontalBarChartLabels] = useState([]);

    const [treeMapCarbonEmissions, setTreeMapCarbonEmissions] = useState([]);

    const [selectedEvent, setSelectedEvent] = useState([]);
    const [selectedYear, setSelectedYear] = useState([]);
    const [selectedSubItem, setSelectedSubItem] = useState([{value:"", label:""}]);
    const [selectedYearDefaults, setSelectedYearDefaults] = useState([]);
    const [allFootprintData, setAllFootprintData] = useState([]);
    const [subItems, setSubItems] = useState([]);

    const internationalNumberFormat = new Intl.NumberFormat('en-US')

    const [selectedFootprintType, setSelectedFootprintType] = useState([{ value: "Actual (Baseline)", label: "Actual (Baseline)" }]);

    const subCategories = [{ value: "1.1 Assembling of the Event", label: "1.1 Assembling of the Event" },
    { value: "1.2 Electrical Equipment", label: "1.2 Electrical Equipment" },
    { value: "1.3 Consumables Production", label: "1.3 Consumables Production" },
    { value: "1.4 Marketing & Advertising", label: "1.4 Marketing & Advertising" },
    { value: "1.5 Services Transportation", label: "1.5 Services Transportation" },
    { value: "2.1 Event Operations", label: "2.1 Event Operations" },
    { value: "2.2 Water Waste & Treatment", label: "2.2 Water Waste & Treatment" },
    { value: "2.3 Food & Beverage", label: "2.3 Food & Beverage" },
    { value: "2.4 Staff Transportation & Accommodation", label: "2.4 Staff Transportation & Accommodation" },
    { value: "2.5 Attendee Transportation & Accommodation", label: "2.5 Attendee Transportation & Accommodation" },
    { value: "3.1 Dismantling and Waste of the Event", label: "3.1 Dismantling and Waste of the Event" },
    { value: "3.2 Operational Waste", label: "3.2 Operational Waste" }
    ]

    const chartColours = [
        "#0090CA",
        "#00A1AA",
        "#00518C",
        "#B9D3DC",
        "#05cfc0",
        "#0a4f4e",
        "#158a2c",
        "#78de4c",
        "#738a69",
        "#c2df7d",
        "#E89B26",
        "#FCE3BD",
        "#592c19",
        "#ff7276",
        "#FF7C40",
        "#FCF062",
        "#7BA7BC",
        "#5459C1",
        "#753fc2",
        "#827db8",
        "#90286b",
        "#ef66f0",
    ];
    const chartHoverColours = [
        "#10475C",
        "#007980",
        "#0E2343",
        "#96abb3",
        "#036860",
        "#0B4516",
        "#052827",
        "#3C6F26",
        "#3A4535",
        "#61703F",
        "#a16b1a",
        "#CEB691",
        "#2E211C",
        "#802741",
        "#732912",
        "#7A6009",
        "#4d6875",
        "#303372",
        "#3B2061",
        "#413F5C",
        "#481436",
        "#783378",
    ];

    const treeMapOptions = {

        plotOptions: {
            treemap: {
              enableShades: false
            }
          },

        legend: {
            show: true,
            itemMargin: {
                horizontal: 10,
                vertical: 10
            },
            fontFamily: 'TT Norms Pro',
            fontSize: 15,
            color: '#5d6a70'
        },
        chart: {
            height: 350,
            type: 'treemap'
        },
        dataLabels: {
            style: {
              fontSize: 16,
              fontFamily: "TT Norms Pro"
            },
          },
        title: {
            text: 'Carbon Emissions by Event per Sub Category tCO2e',
            align: 'center',
            style: {
                fontFamily: 'TT Norms Pro',
                fontSize: 18,
                color: '#5d6a70'
            },
        },
        colors: [
            // '#00518C', '#00A1A8', '#B0D2D8', "#158a2c", "#5459C1", "#FFD800", '#0090CA', "#B9D3DC", "#00E396",
            "#0090CA",
        "#00A1AA",
        "#00518C",
        "#B9D3DC",
        "#05cfc0",
        "#0a4f4e",
        "#158a2c",
        "#78de4c",
        "#738a69",
        "#c2df7d",
        "#E89B26",
        "#FCE3BD",
        "#592c19",
        "#ff7276",
        "#FF7C40",
        "#FCF062",
        "#7BA7BC",
        "#5459C1",
        "#753fc2",
        "#827db8",
        "#90286b",
        "#ef66f0",
        ]
    }

    const [selectedItemSubCategory, setSelectedItemSubCategory] = useState(subCategories);

    const aggregateYearsList = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.value === val.value);
            if (index === -1) {
                acc.push({
                    value: val.value,
                });
            };
            return acc;
        }, []);
    };

    const aggregateYearsListFromFootprintData = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.eventFootprintYear === val.eventFootprintYear);
            if (index === -1) {
                acc.push({
                    eventFootprintYear: val.eventFootprintYear,
                });
            };
            return acc.sort((item1, item2) => item1.eventFootprintYear < item2.eventFootprintYear ? -1 : item1.eventFootprintYear > item2.eventFootprintYear ? 1 : 0);
        }, []);
    };

    const aggregateFootprintTypesListFromFootprintData = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.eventFootprintType === val.eventFootprintType);
            if (index === -1) {
                acc.push({
                    eventFootprintType: val.eventFootprintType,
                });
            };
            return acc;
        }, []);
    };

    const aggregateFootprintItemSubCategoriesListFromFootprintData = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.itemSubCategory === val.itemSubCategory);
            if (index === -1) {
                acc.push({
                    itemSubCategory: val.itemSubCategory,
                });
            };
            return acc;
        }, []);
    };

    const aggregateSubItems = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.description === val.description);
            if (index === -1) {
                acc.push({
                    description: val.description,
                });
            };
            return acc;
        }, []);
    };

    //Chart 5 ( 3 by Sub Category per Event)

    const dataChart5 = {
        labels: labels,
        datasets: [
            {
                data: carbonEmissions,
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

        //Chart 6 ( Category per Event)

        const dataChart6 = {
            labels: labelsForCategory,
            datasets: [
                {
                    data: carbonEmissionsForCategory,
                    backgroundColor: chartColours,
                    hoverBackgroundColor: chartHoverColours,
                },
            ],
        };

        const dataChart6PerAttendee = {
            labels: singleHorizontalBarChartLabels,
            datasets: [
                {
                    data: singleHorizontalBarChartCarbonEmissionsPerAttendee,
                    backgroundColor: chartColours,
                    hoverBackgroundColor: chartHoverColours,
                },
            ],
        };

        const dataChart6PerGBP = {
            labels: singleHorizontalBarChartLabels,
            datasets: [
                {
                    data: singleHorizontalBarChartCarbonEmissionsPerGBP,
                    backgroundColor: chartColours,
                    hoverBackgroundColor: chartHoverColours,
                },
            ],
        };

    const dataChart10 = {
        labels: labelsForSubItems.slice(0,numberOfRecordsToShow),
        datasets: [
            {
                data: carbonEmissionsForSubItems.slice(0,numberOfRecordsToShow),
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

    //Chart 7
    const dataChart7 = {
        labels: selectedYear.sort((item1, item2) => item1.label < item2.label ? -1 : item1.label > item2.label ? 1 : 0).map(item => item.label),
        datasets: stackedLineChartCarbonEmissions
    };

    //Chart 7b
    const dataChart7b = {
        labels: stackedBarChartLabels,
        datasets: stackedBarChartCarbonEmissions
    };

    //Chart 7bb
    const dataChart7bb = {
        labels: stackedBarChartLabels,
        datasets: stackedBarChartCarbonEmissionsPerAttendee
    };

    //Chart 7bc
    const dataChart7bc = {
        labels: stackedBarChartLabels,
        datasets: stackedBarChartCarbonEmissionsPerGBP
    };


    //Chart 7bbSingleHorizontal
    const dataChart7bbSingleHorizontal = {
        labels: singleHorizontalBarChartLabels,
        datasets: [
            {
                data: singleHorizontalBarChartCarbonEmissionsPerAttendee,
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

    //Chart 7bcSingleHorizontal
    const dataChart7bcSingleHorizontal = {
        labels: singleHorizontalBarChartLabels,
        datasets: [
            {
                data: singleHorizontalBarChartCarbonEmissionsPerGBP,
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

    //Chart 7bForSubItems
    const dataChart7bForSubItems = {
        labels: stackedBarChartLabelsForSubItems,
        datasets: stackedBarChartCarbonEmissionsForSubItems
    };

    //Chart 7bb
    const dataChart7bbForSubItems = {
        labels: stackedBarChartLabelsForSubItems,
        datasets: stackedBarChartCarbonEmissionsPerAttendeeForSubItems
    };

    //Chart 7bc
    const dataChart7bcForSubItems = {
        labels: stackedBarChartLabelsForSubItems,
        datasets: stackedBarChartCarbonEmissionsPerGBPForSubItems
    };

    //Chart 7c
    const dataChart7c = {
        labels: selectedYear.sort((item1, item2) => item1.label < item2.label ? -1 : item1.label > item2.label ? 1 : 0).map(item => item.label),
        datasets: [
            {
                label: 'Target Emissions - Maximum acceptable carbon emissions in line with NCS protocol in order to achieve Net Zero Carbon as defined by SBTi',
                type: 'line',
                borderColor: chartColours[2],
                data: carbonEmissionsPerYearTargets,
                spanGaps: true,
                fill: 'origin'
            },
            {
                label: 'Forecast Emissions - Estimated carbon emissions in line with proposed reduction measures',
                type: 'line',
                borderColor: chartColours[1],
                data: carbonEmissionsPerYearProjections,
                spanGaps: true
            },
            {
                label: 'Actual Emissions - Verified carbon emissions per the reporting year in tCO2e',
                type: 'bar',
                fill: true,
                backgroundColor: chartColours[0],
                data: carbonEmissionsPerYearActuals
            },

        ]
    };

    //Chart 7e
    const dataChart7e = {
        labels: selectedYear.sort((item1, item2) => item1.label < item2.label ? -1 : item1.label > item2.label ? 1 : 0).map(item => item.label),
        datasets: [
            {
                label: 'Target Emissions Per Attendee - Maximum acceptable carbon emissions in line with NCS protocol in order to achieve Net Zero Carbon as defined by SBTi',
                type: 'line',
                borderColor: chartColours[2],
                data: carbonEmissionsPerYearTargetsPerAttendee,
                spanGaps: true,
                fill: 'origin'
            },
            {
                label: 'Forecast Emissions Per Attendee - Estimated carbon emissions in line with proposed reduction measures',
                type: 'line',
                borderColor: chartColours[1],
                data: carbonEmissionsPerYearProjectionsPerAttendee,
                spanGaps: true
            },
            {
                label: 'Actual Emissions Per Attendee - Verified carbon emissions per the reporting year in tCO2e',
                type: 'bar',
                fill: true,
                backgroundColor: chartColours[0],
                data: carbonEmissionsPerYearActualsPerAttendee
            },

        ]
    };

    //Pie Options
    const pieOptions = {
        plugins: {
            title: {
                display: true,
                text: 'Carbon Emissions per Sub Category tCO2e',
                font: {
                    size: 20
                }
            },
            legend: {
                display: true,
                maxWidth: 100,
                position: "bottom",
                labels: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    },
                },
            },
            datalabels: {
                display: false,
            }
        },
    };

    const pieOptionsPerAttendee = {
        plugins: {
            title: {
                display: true,
                text: 'Carbon Emissions per Attendee by Sub Category tCO2e',
                font: {
                    size: 20
                }
            },
            legend: {
                display: true,
                maxWidth: 100,
                position: "bottom",
                labels: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    },
                },
            },
            datalabels: {
                display: false,
            }
        },
    };

    const pieOptionsForCategory = {
        plugins: {
            title: {
                display: true,
                text: 'Carbon Emissions per Scope tCO2e',
                font: {
                    size: 20
                }
            },
            legend: {
                display: true,
                maxWidth: 100,
                position: "bottom",
                labels: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    },
                },
            },
            datalabels: {
                display: false,
            }
        },
    };

    const barOptionsStacked = {

        plugins: {
            title: {
                display: true,
                text: 'Absolute Carbon Emissions by Event per Sub Category tCO2e',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: true,
                maxWidth: 100,
                position: "bottom",
                labels: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    },
                },
            },
            datalabels: {
                display: false,
            }
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            },
            y: {
                stacked: true,
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            }
        },

    };

    const barOptionsStackedPerAttendee = {

        plugins: {
            title: {
                display: true,
                text: 'Intensity Based Carbon Emissions by Event per Sub Category tCO2e per Attendee',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: true,
                maxWidth: 100,
                position: "bottom",
                labels: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    },
                },
            },
            datalabels: {
                display: false,
            }
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            },
            y: {
                stacked: true,
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            }
        },

    };

    const barOptionsStackedForSubItems = {

        plugins: {
            title: {
                display: true,
                text: 'Absolute Carbon Emissions by Event per Sub Item tCO2e',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: true,
                maxWidth: 100,
                position: "bottom",
                labels: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    },
                },
            },
            datalabels: {
                display: false,
            }
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            },
            y: {
                stacked: true,
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            }
        },

    };

    const barOptionsStackedPerAttendeeForSubItems = {

        plugins: {
            title: {
                display: true,
                text: 'Intensity Based Carbon Emissions by Event per Sub Item tCO2e per Attendee',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: true,
                maxWidth: 100,
                position: "bottom",
                labels: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    },
                },
            },
            datalabels: {
                display: false,
            }
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            },
            y: {
                stacked: true,
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            }
        },

    };

    const barOptionsHorizontal = {

        indexAxis: 'y',
        scales: {
            y: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            },
            x: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            }
        },

        plugins: {
            title: {
                display: true,
                text: 'Carbon Emissions per Sub Category tCO2e',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: false,
            },
            datalabels: {
                display: false,
            }
        },
        

        responsive: true,

    };

    const barOptionsHorizontalForCategory = {

        indexAxis: 'y',
        scales: {
            y: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            },
            x: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            }
        },

        plugins: {
            title: {
                display: true,
                text: 'Carbon Emissions per Scope tCO2e',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: false,
            },
            datalabels: {
                display: false,
            }
        },
        

        responsive: true,

    };

    const barOptionsHorizontalPerAttendee = {

        indexAxis: 'y',
        scales: {
            y: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            },
            x: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            }
        },

        plugins: {
            title: {
                display: true,
                text: 'Intensity Based Carbon Emissions by Event per Sub Item tCO2e per Attendee',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: false,
            },
            datalabels: {
                display: false,
            }
        },
        

        responsive: true,
        
        options: {
            maintainAspectRatio: false,
        }


    };

    const barOptionsHorizontalForSubItems = {

        indexAxis: 'y',
        scales: {
            y: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            },
            x: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            }
        },

        plugins: {
            title: {
                display: true,
                text: 'Carbon Emissions per Sub Item tCO2e',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: false,
            },
            datalabels: {
                display: false,
            }
        },

        responsive: true,

        options: {
            maintainAspectRatio: false,
        }

    };

    const lineBarOptions = {
        elements: {
            line: {
                tension: 0.5,
            },
            point: {
                radius: 2
            }
        },

        scales: {
            y: {
                min: 0,
                stacked: true,
                ticks: {
                    font: {
                    size: 15,
                    family: 'TT Norms Pro'
                    }
                }
            },
            x: {
                ticks: {
                    font: {
                    size: 15,
                    family: 'TT Norms Pro'
                    }
                }
            },
        },
        plugins: {
            title: {
                display: true,
                text: 'Carbon Emissions per year tCO2e',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: true,
                position: "bottom",
                labels: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    },
                },
            },
            datalabels: {
                display: false,
            }
        },
    };


    const [company, setCompany] = useState("")
    const [role, setRole] = useState(0)
    const [userPropertiesLoading, setUserPropertiesLoading] = useState(true)

    const getUserProperties = async () => {

        await axios.get(process.env.REACT_APP_API_URL + "/current_user_properties")
            .then((response) => {
                setCompany(response.data.company)
                setRole(response.data.role)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUserPropertiesLoading(false);
            });

    }

    useEffect(() => {

        setUserPropertiesLoading(true)

        getUserProperties()

    }, [])

    // const tooltip = document.getElementById("tooltip")

    const barAndLineOptions = {
        
        plugins: {

            title: {
                display: true,
                text: 'Forecast vs Target vs Actual tCO2e',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },    
           
            legend: {
                display: true,
                maxWidth: 100,
                position: "bottom",
                
                labels: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    },
                },
            },
            datalabels: {
                display: false,
            }
        },
        responsive: true,
        scales: {
            y: {
                ticks: {
                    font: {
                    size: 15,
                    family: 'TT Norms Pro'
                    }
                }
            },
            x: {
                ticks: {
                    font: {
                    size: 15,
                    family: 'TT Norms Pro'
                    }
                }
            },
        },
    }

    const barAndLineOptionsPerAttendee = {
        plugins: {
            title: {
                display: true,
                text: 'Forecast vs Target vs Actual tCO2e per Attendee',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: true,
                maxWidth: 100,
                position: "bottom",
                labels: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    },
                },
            },
            datalabels: {
                display: false,
            }
        },
        responsive: true,
        scales: {
            y: {
                ticks: {
                    font: {
                    size: 15,
                    family: 'TT Norms Pro'
                    }
                }
            },
            x: {
                ticks: {
                    font: {
                    size: 15,
                    family: 'TT Norms Pro'
                    }
                }
            },
        },
    }


    useEffect(() => {

        if (company && role && !userPropertiesLoading) {

            setSelectedItemSubCategory(subCategories)

            if (role === 3001 || role === 6001) {

                //Code here to call special API endpoint returning only events for that client
                axios.get(process.env.REACT_APP_API_URL + '/events_by_clientId/' + company)
                    .then((res) => {
                        setEvents(res.data);
                        setSelectedEvent(res.data.map((item) => {
                            return {
                                label: item.eventName,
                                value: item.id
                            }
                        }))

                        axios.get(process.env.REACT_APP_API_URL + "/events_footprints_with_subItems_by_clientId/" + company)
                            .then((res2) => {

                                var filteredResByEvents = res2.data.filter((itemA) => {
                                    return res.data.map((item) => {
                                        return {
                                            label: item.eventName,
                                            value: item.id
                                        }
                                    }).find((itemB) => {
                                        return itemA.eventId === itemB.value;
                                    })
                                })

                                setAllFootprintData(filteredResByEvents.filter(item => item.eventFootprintCertification !== ""))

                                setSelectedYear(aggregateYearsListFromFootprintData(filteredResByEvents).map((item) => {
                                    return {
                                        label: item.eventFootprintYear,
                                        value: item.eventFootprintYear
                                    }
                                }))
                                setSelectedYearDefaults(aggregateYearsListFromFootprintData(filteredResByEvents).map((item) => {
                                    return {
                                        label: item.eventFootprintYear,
                                        value: item.eventFootprintYear
                                    }
                                }))
                            })
                            .catch((err) => {
                                console.log(err);
                            })

                    })
                    .catch((err) => {
                        console.log(err);
                    }).finally(() => {
                        setLoading(false);
                    });

            } else {

                axios.get(process.env.REACT_APP_API_URL + '/events')
                    .then((res) => {
                        setEvents(res.data);
                        setSelectedEvent(res.data.map((item) => {
                            return {
                                label: item.eventName,
                                value: item.id
                            }
                        }))
                    })
                    .catch((err) => {
                        console.log(err);
                    }).finally(() => {
                        setLoading(false);
                    });

                axios.get(process.env.REACT_APP_API_URL + "/events_footprints_with_subItems")
                    .then((res) => {

                        setAllFootprintData(res.data.filter(item => item.eventFootprintCertification !== ""))

                        setSelectedYear(aggregateYearsListFromFootprintData(res.data).map((item) => {
                            return {
                                label: item.eventFootprintYear,
                                value: item.eventFootprintYear
                            }
                        }))
                        setSelectedYearDefaults(aggregateYearsListFromFootprintData(res.data).map((item) => {
                            return {
                                label: item.eventFootprintYear,
                                value: item.eventFootprintYear
                            }
                        }))
                    })
                    .catch((err) => {
                        console.log(err);
                    })
                    .finally(() => {
                        setLoading(false);
                    });

            }

        }

    }, [role, company]);


    useEffect(() => {

        if (company && role && !userPropertiesLoading) {

            //Set eventFootprints for Multiselect filter
            setEventFootprints(
                allFootprintData.filter(item => item.eventFootprintCertification !== "").sort((item1, item2) =>
                    item1.eventFootprintRef < item2.eventFootprintRef
                        ? -1
                        : item1.eventFootprintRef > item2.eventFootprintRef
                            ? 1
                            : 0
                )
            );

            //Filter the eventFootprints to selected event ids and footprint ids
            var filteredResByEvents = []
            var filteredResByEventsAndFootprintType = []
            var filteredResByEventsAndFootprintTypeAndYear = []
            var filteredRes = allFootprintData.filter(item => item.eventFootprintCertification !== "")

            var filteredResActuals = []
            var filteredResProjections = []
            var filteredResTargets = []

            filteredResByEvents = filteredRes.filter((itemA) => {
                return selectedEvent.find((itemB) => {
                    return itemA.eventId === itemB.value;
                })
            })

            filteredResByEventsAndFootprintType = filteredResByEvents.filter((itemA) => {
                return selectedFootprintType.find((itemB) => {
                    return itemA.eventFootprintType === itemB.value;
                })
            })

            filteredResByEventsAndFootprintTypeAndYear = filteredResByEventsAndFootprintType.filter((itemA) => {
                return selectedYear.find((itemB) => {
                    return itemA.eventFootprintYear === itemB.value;
                })
            })

            //get all of the selected eventFootprints and concatenat their footprintItems arrays
            var concatenatedListOfItems = [];
            //var arrayOfYearlyEmissionsBySubCategory = [];
            var arrayOfEventEmissionsBySubCategory = [];
            var index = 0

            var i;
            for (i = 0; i < filteredResByEventsAndFootprintTypeAndYear.length; i++) {
                concatenatedListOfItems = concatenatedListOfItems.concat(
                    filteredResByEventsAndFootprintTypeAndYear[i].eventFootprintItems.filter((itemA) => {
                        return selectedItemSubCategory.find((itemB) => {
                            return itemA.itemSubCategory === itemB.value;
                        })
                    })
                );
            }

            console.log(concatenatedListOfItems)

            //Reduce to itemSubCategory and sum carbonEmissions
            var result = [];
            concatenatedListOfItems.reduce(function (res, value) {
                if (!res[value.itemSubCategory]) {
                    res[value.itemSubCategory] = {
                        itemSubCategory: value.itemSubCategory,
                        carbonEmissions: 0,
                    };
                    result.push(res[value.itemSubCategory]);
                }
                res[value.itemSubCategory].carbonEmissions +=
                    value.carbonEmissions/1000;
                return res;
            }, {});

            //Set labels (sub categories) and carbon emissions data

            result.sort((item1, item2) => item1.carbonEmissions > item2.carbonEmissions ? -1 : item1.carbonEmissions < item2.carbonEmissions ? 1 : 0)

            var scopeSubCategoryData = result.map((item) => item.carbonEmissions);
            var scopeSubCategoryLabels = result.map(
                (item) => item.itemSubCategory
            );

            setLabels(scopeSubCategoryLabels);
            setCarbonEmissions(scopeSubCategoryData);

            //Reduce to itemCategory and sum carbonEmissions
            result = [];
            concatenatedListOfItems.reduce(function (res, value) {
                if (!res[value.itemCategory]) {
                    res[value.itemCategory] = {
                        itemCategory: value.itemCategory,
                        carbonEmissions: 0,
                    };
                    result.push(res[value.itemCategory]);
                }
                res[value.itemCategory].carbonEmissions +=
                    value.carbonEmissions/1000;
                return res;
            }, {});

            //Set labels (categories) and carbon emissions data

            result.sort((item1, item2) => item1.carbonEmissions > item2.carbonEmissions ? -1 : item1.carbonEmissions < item2.carbonEmissions ? 1 : 0)

            var scopeCategoryData = result.map((item) => item.carbonEmissions);
            var scopeCategoryLabels = result.map(
                (item) => item.itemCategory
            );

            setLabelsForCategory(scopeCategoryLabels);
            setCarbonEmissionsForCategory(scopeCategoryData);


            //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

            //get all of the concatinated eventFootprintItems and concatenat their subItems arrays
        
            var concatenatedListOfSubItems = [];
            var concatenatedListOfAllSubItems = [];

            index = 0

            for (i = 0; i < concatenatedListOfItems.length; i++) {
                concatenatedListOfAllSubItems = concatenatedListOfAllSubItems.concat(
                    concatenatedListOfItems[i].footprintSubItems
                );
            }

            for (i = 0; i < concatenatedListOfItems.length; i++) {
                concatenatedListOfSubItems = concatenatedListOfSubItems.concat(
                    concatenatedListOfItems[i].footprintSubItems.filter((itemA) => {
                        return selectedSubItem.find((itemB) => {
                            return itemA.description=== itemB.value;
                        })
                    })
                );
            }

            //Reduce to description and sum carbonEmissions

            result = [];
            concatenatedListOfSubItems.reduce(function (res, value) {
                if (!res[value.description]) {
                    res[value.description] = {
                        description: value.description,
                        carbonEmissions: 0,
                    };
                    result.push(res[value.description]);
                }
                res[value.description].carbonEmissions +=
                    value.carbonEmissions/1000;
                return res;
            }, {});

            setSubItems(aggregateSubItems(concatenatedListOfAllSubItems).sort((item1, item2) => item1.description < item2.description ? -1 : item1.description > item2.description ? 1 : 0))

            //Set labels (sub sub items) and carbon emissions data

            result.sort((item1, item2) => item1.carbonEmissions > item2.carbonEmissions ? -1 : item1.carbonEmissions < item2.carbonEmissions ? 1 : 0)

            var scopeSubItemData = result.map((item) => item.carbonEmissions);
            var scopeSubItemLabels = result.map(
                (item) => item.description
            );

            

            setLabelsForSubItems(scopeSubItemLabels);
            setCarbonEmissionsForSubItems(scopeSubItemData);

            //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!


            //Code Here to get to emissions per scopeCategory per year

            const carbonEmissionsPerYearPerSubCategory = [];

            const listOfSubCategories = [
                ...new Set(
                    concatenatedListOfItems.map(
                        (item) => item.itemSubCategory
                    )
                ),
            ];

            var j, k, subCategory, emissions, year;
            var datasetsArray = [];
            var datasetsArrayPerAttendee = [];
            var datasetsArrayPerGBP = [];
            var emissionsForThatSubCategory = [];
            var arrayOfYearlyEmissions = [];
            var carbonEmissionsPerYearPerSubCategorySorted = [];

            for (i = 0; i < selectedYear.length; i++) {
                concatenatedListOfItems = [];

                year = selectedYear.sort((item1, item2) => item1.label < item2.label ? -1 : item1.label > item2.label ? 1 : 0).map(item => item.label)[i];

                for (k = 0; k < filteredResByEventsAndFootprintTypeAndYear.length; k++) {
                    if (
                        filteredResByEventsAndFootprintTypeAndYear[k].eventFootprintYear === year
                    ) {
                        concatenatedListOfItems =
                            concatenatedListOfItems.concat(
                                filteredResByEventsAndFootprintTypeAndYear[k].eventFootprintItems
                            );
                    }
                }

                for (j = 0; j < scopeSubCategoryLabels.length; j++) {
                    subCategory = scopeSubCategoryLabels[j];
                    emissions = concatenatedListOfItems
                        .filter((item) => item.itemSubCategory === subCategory)
                        .map((item) => item.carbonEmissions)
                        .reduce((prev, curr) => Number(prev) + Number(curr), 0);
                    carbonEmissionsPerYearPerSubCategory.push({
                        year,
                        subCategory,
                        emissions,
                    });

                }

            }

            carbonEmissionsPerYearPerSubCategorySorted =
                carbonEmissionsPerYearPerSubCategory.sort((item1, item2) =>
                    item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0
                );

            for (i = 0; i < scopeSubCategoryLabels.length; i++) {
                subCategory = scopeSubCategoryLabels[i];
                emissionsForThatSubCategory =
                    carbonEmissionsPerYearPerSubCategorySorted.filter(
                        (item) => item.subCategory === subCategory
                    );

                arrayOfYearlyEmissions = [];

                for (j = 0; j < selectedYear.length; j++) {
                    year = selectedYear.sort((item1, item2) => item1.label < item2.label ? -1 : item1.label > item2.label ? 1 : 0).map(item => item.label)[j];
                    emissions = emissionsForThatSubCategory
                        .filter((item) => item.year === year)
                        .map((item) => item.emissions)
                        .reduce((prev, curr) => Number(prev) + Number(curr), 0);
                    arrayOfYearlyEmissions.push(emissions/1000);

                }

                datasetsArray.push({
                    label: subCategory,
                    data: arrayOfYearlyEmissions,
                    backgroundColor: chartColours,
                    borderColor: chartColours,
                    hoverBackgroundColor: chartHoverColours,
                    fill: true,
                    pointBorderColor: '#F1F6F8',
                    pointBackgroundColor: chartColours[i]
                });

            }

            setStackedLineChartLabels(selectedYear.sort((item1, item2) => item1.label < item2.label ? -1 : item1.label > item2.label ? 1 : 0).map(item => item.label));
            setStackedLineChartCarbonEmissions(datasetsArray);

            //Code here to calculate Actuals, Projections and Targets per year
            //Actuals

            filteredResActuals = filteredResByEvents.filter(item => item.eventFootprintType === "Actual" || item.eventFootprintType === "Actual (Baseline)")

            var carbonEmissionsPerYear = [];
            var carbonEmissionsPerYearPerGBP = [];
            // var carbonEmissionsByTurnoverGBP;
            var carbonEmissionsPerYearPerAttendee = [];
            var carbonEmissionsByAttendee;

            for (i = 0; i < aggregateYearsList(selectedYear).length; i++) {
                concatenatedListOfItems = [];

                year = aggregateYearsList(selectedYear)[i].value

                for (k = 0; k < filteredResActuals.length; k++) {
                    if (
                        filteredResActuals[k].eventFootprintYear === year
                    ) {
                        concatenatedListOfItems =
                            concatenatedListOfItems.concat(
                                filteredResActuals[k].eventFootprintItems
                            );
                    }
                }

                emissions = concatenatedListOfItems.filter((itemA) => {
                    return selectedItemSubCategory.find((itemB) => {
                        return itemA.itemSubCategory === itemB.value;
                    })
                })
                    .map((item) => item.carbonEmissions)
                    .reduce((prev, curr) => Number(prev) + Number(curr), 0)/1000;
                if (emissions === 0) {
                    emissions = "N/A"
                }
                carbonEmissionsPerYear.push({
                    year,
                    emissions,
                });

                carbonEmissionsByAttendee = emissions / (allFootprintData.filter(item => item.eventFootprintYear === year && (item.eventFootprintType === "Actual" || item.eventFootprintType === "Actual (Baseline)")).filter((itemA) => {
                    return selectedEvent.find((itemB) => {
                        return itemA.eventId === itemB.value;
                    })
                }).map(item => item.eventFootprintNumberOfAttendees).reduce((prev, curr) => Number(prev) + Number(curr), 0))

                carbonEmissionsPerYearPerAttendee.push({
                    year,
                    emissions: carbonEmissionsByAttendee
                });

            }

            setCarbonEmissionsPerYearActuals(carbonEmissionsPerYear.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));
            // setCarbonEmissionsPerYearActualsPerGBPTurnover(carbonEmissionsPerYearPerGBP.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));
            setCarbonEmissionsPerYearActualsPerAttendee(carbonEmissionsPerYearPerAttendee.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));

            //Targets

            filteredResTargets = filteredResByEvents.filter(item => item.eventFootprintType === "Target")

            carbonEmissionsPerYear = [];
            carbonEmissionsPerYearPerGBP = [];
            carbonEmissionsPerYearPerAttendee = [];

            for (i = 0; i < aggregateYearsList(selectedYear).length; i++) {
                concatenatedListOfItems = [];

                year = aggregateYearsList(selectedYear)[i].value;

                for (k = 0; k < filteredResTargets.length; k++) {
                    if (
                        filteredResTargets[k].eventFootprintYear === year
                    ) {
                        concatenatedListOfItems =
                            concatenatedListOfItems.concat(
                                filteredResTargets[k].eventFootprintItems
                            );
                    }
                }

                emissions = concatenatedListOfItems.filter((itemA) => {
                    return selectedItemSubCategory.find((itemB) => {
                        return itemA.itemSubCategory === itemB.value;
                    })
                })
                    .map((item) => item.carbonEmissions)
                    .reduce((prev, curr) => Number(prev) + Number(curr), 0)/1000;
                if (emissions === 0) {
                    emissions = "N/A"
                }
                carbonEmissionsPerYear.push({
                    year,
                    emissions,
                });

                carbonEmissionsByAttendee = emissions / (allFootprintData.filter(item => item.eventFootprintYear === year && item.eventFootprintType === "Target").filter((itemA) => {
                    return selectedEvent.find((itemB) => {
                        return itemA.eventId === itemB.value;
                    })
                }).map(item => item.eventFootprintNumberOfAttendees).reduce((prev, curr) => Number(prev) + Number(curr), 0))

                carbonEmissionsPerYearPerAttendee.push({
                    year,
                    emissions: carbonEmissionsByAttendee
                });

            }

            setCarbonEmissionsPerYearTargets(carbonEmissionsPerYear.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));
            // setCarbonEmissionsPerYearTargetsPerGBPTurnover(carbonEmissionsPerYearPerGBP.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));
            setCarbonEmissionsPerYearTargetsPerAttendee(carbonEmissionsPerYearPerAttendee.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));

            //Projections

            filteredResProjections = filteredResByEvents.filter(item => item.eventFootprintType === "Forecast")

            carbonEmissionsPerYear = [];
            carbonEmissionsPerYearPerGBP = [];
            carbonEmissionsPerYearPerAttendee = [];

            for (i = 0; i < aggregateYearsList(selectedYear).length; i++) {
                concatenatedListOfItems = [];

                year = aggregateYearsList(selectedYear)[i].value;

                for (k = 0; k < filteredResProjections.length; k++) {
                    if (
                        filteredResProjections[k].eventFootprintYear === year
                    ) {
                        concatenatedListOfItems =
                            concatenatedListOfItems.concat(
                                filteredResProjections[k].eventFootprintItems
                            );
                    }
                }

                emissions = concatenatedListOfItems.filter((itemA) => {
                    return selectedItemSubCategory.find((itemB) => {
                        return itemA.itemSubCategory === itemB.value;
                    })
                })
                    .map((item) => item.carbonEmissions)
                    .reduce((prev, curr) => Number(prev) + Number(curr), 0)/1000;
                if (emissions === 0) {
                    emissions = "N/A"
                }
                carbonEmissionsPerYear.push({
                    year,
                    emissions,
                });


                carbonEmissionsByAttendee = emissions / (allFootprintData.filter(item => item.eventFootprintYear === year && item.eventFootprintType === "Target").filter((itemA) => {
                    return selectedEvent.find((itemB) => {
                        return itemA.eventId === itemB.value;
                    })
                }).map(item => item.eventFootprintNumberOfAttendees).reduce((prev, curr) => Number(prev) + Number(curr), 0))

                carbonEmissionsPerYearPerAttendee.push({
                    year,
                    emissions: carbonEmissionsByAttendee
                });

            }

            setCarbonEmissionsPerYearProjections(carbonEmissionsPerYear.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));
            // setCarbonEmissionsPerYearProjectionsPerGBPTurnover(carbonEmissionsPerYearPerGBP.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));
            setCarbonEmissionsPerYearProjectionsPerAttendee(carbonEmissionsPerYearPerAttendee.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));

            //Code Here to get to emissions per scopeCategory per event

            const carbonEmissionsPerEventPerSubCategory = [];

            datasetsArray = [];
            datasetsArrayPerAttendee = [];
            datasetsArrayPerGBP = [];
            emissionsForThatSubCategory = [];
            var arrayOfEmissionsByEvent = [];
            var arrayOfEmissionsByEventPerAttendee = [];
            var arrayOfEmissionsByEventPerGBP = [];
            var eventId
            var chartColourIndex = 0

            for (i = 0; i < selectedEvent.length; i++) {
                concatenatedListOfItems = [];

                eventId = selectedEvent.sort((item1, item2) => item1.label < item2.label ? -1 : item1.label > item2.label ? 1 : 0)[i].value;

                for (k = 0; k < filteredResByEventsAndFootprintTypeAndYear.length; k++) {
                    if (
                        filteredResByEventsAndFootprintTypeAndYear[k].eventId === eventId
                    ) {
                        concatenatedListOfItems =
                            concatenatedListOfItems.concat(
                                filteredResByEventsAndFootprintTypeAndYear[k].eventFootprintItems
                            );
                    }
                }

                for (j = 0; j < scopeSubCategoryLabels.length; j++) {
                    subCategory = scopeSubCategoryLabels[j];
                    emissions = concatenatedListOfItems
                        .filter((item) => item.itemSubCategory === subCategory)
                        .map((item) => item.carbonEmissions)
                        .reduce((prev, curr) => Number(prev) + Number(curr), 0)/1000;
                    carbonEmissionsPerEventPerSubCategory.push({
                        eventId,
                        subCategory,
                        emissions,
                    });
                }

            }

            for (i = 0; i < scopeSubCategoryLabels.length; i++) {
                subCategory = scopeSubCategoryLabels[i];
                emissionsForThatSubCategory =
                    carbonEmissionsPerEventPerSubCategory.filter(
                        (item) => item.subCategory === subCategory
                    );

                arrayOfEmissionsByEvent = [];
                arrayOfEmissionsByEventPerAttendee = [];
                arrayOfEmissionsByEventPerGBP = [];

                for (j = 0; j < selectedEvent.length; j++) {
                    eventId = selectedEvent[j].value;
                    emissions = emissionsForThatSubCategory
                        .filter((item) => item.eventId === eventId)
                        .map((item) => item.emissions)
                        .reduce((prev, curr) => Number(prev) + Number(curr), 0);
                    arrayOfEmissionsByEvent.push(emissions);
                    arrayOfEmissionsByEventPerAttendee.push(emissions / filteredResByEventsAndFootprintTypeAndYear.filter(item => item.eventId === eventId).map(item => item.eventFootprintNumberOfAttendees).reduce((prev, curr) => Number(prev) + Number(curr), 0))
                    // arrayOfEmissionsByEventPerGBP.push(emissions / (filteredResByEventsAndFootprintTypeAndYear.filter(item => item.eventId === eventId).map(item => item.eventTurnoverGBP).reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000))

                }

                datasetsArray.push({
                    label: subCategory,
                    data: arrayOfEmissionsByEvent,
                    backgroundColor: chartColours[chartColourIndex],
                    borderColor: chartColours[chartColourIndex],
                    hoverBackgroundColor: chartHoverColours[chartColourIndex],
                });

                datasetsArrayPerAttendee.push({
                    label: subCategory,
                    data: arrayOfEmissionsByEventPerAttendee,
                    backgroundColor: chartColours[chartColourIndex],
                    borderColor: chartColours[chartColourIndex],
                    hoverBackgroundColor: chartHoverColours[chartColourIndex],
                });

                datasetsArrayPerGBP.push({
                    label: subCategory,
                    data: arrayOfEmissionsByEventPerGBP,
                    backgroundColor: chartColours[chartColourIndex],
                    borderColor: chartColours[chartColourIndex],
                    hoverBackgroundColor: chartHoverColours[chartColourIndex],
                });

                if (chartColourIndex === chartColours.length - 1) {
                    chartColourIndex = 0
                } else {
                    chartColourIndex++
                }
            }

            setStackedBarChartLabels(selectedEvent.sort((item1, item2) => item1.label < item2.label ? -1 : item1.label > item2.label ? 1 : 0).map(item => item.label))
            setStackedBarChartCarbonEmissions(datasetsArray);
            setStackedBarChartCarbonEmissionsPerAttendee(datasetsArrayPerAttendee);
            setStackedBarChartCarbonEmissionsPerGBP(datasetsArrayPerGBP);

            var seperateDataForSingleEventPerAttendee = []
            var seperateDataForSingleEventPerGBP = []

            setSingleHorizontalBarChartLabels(datasetsArray.map(item => item.label))

            for (i = 0; i < datasetsArray.length; i++) {
                seperateDataForSingleEventPerAttendee.push(datasetsArrayPerAttendee[i].data[0])
                seperateDataForSingleEventPerGBP.push(datasetsArrayPerGBP[i].data[0])
            }

            setSingleHorizontalBarChartCarbonEmissionsPerAttendee(seperateDataForSingleEventPerAttendee);
            setSingleHorizontalBarChartCarbonEmissionsPerGBP(seperateDataForSingleEventPerGBP);

            var treeMapArray = []
            var treeMapArrayFiltered = []


            var totalForThisEvent = 0

            for (i = 0; i < selectedEvent.length; i++) {

                totalForThisEvent = 0

                treeMapArray.push({
                    name: selectedEvent.sort((item1, item2) => item1.label < item2.label ? -1 : item1.label > item2.label ? 1 : 0).map(item => item.label)[i],
                    data: []
                })

                for (j = 0; j < datasetsArray.length; j++) {

                    if (datasetsArray[j].data[i] > 0.001) {

                        treeMapArray[i].data.push({
                            x: [treeMapArray[i].name,  datasetsArray[j].label],
                            //Can add .toFixed(3) to the below, but numbers lower than 0.0001 will show with large text and 0 value?!?!?
                            y: datasetsArray[j].data[i].toFixed(3)
                        },)

                    totalForThisEvent = totalForThisEvent + 1

                    } else {

                        treeMapArray[i].data.push({
                            x: [""],
                            y: 0
                        },)
                    }

                }

                //ensure that only events with at least some emissions based on the filters applied are included (otherwise treemap labels are not
                //rendered correctly)

                if (totalForThisEvent > 0) {

                    treeMapArrayFiltered.push(treeMapArray[i])

                }

            }

            setTreeMapCarbonEmissions(treeMapArrayFiltered)

            //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            //Code Here to get to emissions per subItem per event

            var carbonEmissionsPerEventPerSubItem = [];

             datasetsArray = [];
             datasetsArrayPerAttendee = [];
             datasetsArrayPerGBP = [];
             var l = 0
             var description
             var emissionsForThatSubItem = [];
             arrayOfEmissionsByEvent = [];
             arrayOfEmissionsByEventPerAttendee = [];
             arrayOfEmissionsByEventPerGBP = [];
             chartColourIndex = 0
             //var listOfSubItems = selectedSubItem
             var listOfSubItems = scopeSubItemLabels.slice(0,numberOfRecordsToShow)

             for (i = 0; i < selectedEvent.length; i++) {
                concatenatedListOfSubItems = [];
 
                 eventId = selectedEvent.sort((item1, item2) => item1.label < item2.label ? -1 : item1.label > item2.label ? 1 : 0)[i].value;
 
                 for (k = 0; k < filteredResByEventsAndFootprintTypeAndYear.length; k++) {
                     if (
                         filteredResByEventsAndFootprintTypeAndYear[k].eventId === eventId
                     ) {
                        //loop through each item of footprint [k] and concatenate the subItems
                        for (l = 0; l < filteredResByEventsAndFootprintTypeAndYear[k].eventFootprintItems.length; l++) {
                            concatenatedListOfSubItems =
                            concatenatedListOfSubItems.concat(
                                    filteredResByEventsAndFootprintTypeAndYear[k].eventFootprintItems[l].footprintSubItems
                                )
                        }
                     }
                 }

        
                //somehow need to get full list of subCategoryItems!!!!!!
                 for (j = 0; j < listOfSubItems.length; j++) {
                    description = listOfSubItems[j];
                     emissions = concatenatedListOfSubItems
                         .filter((item) => item.description === description)
                         .map((item) => item.carbonEmissions)
                         .reduce((prev, curr) => Number(prev) + Number(curr), 0)/1000;
                     carbonEmissionsPerEventPerSubItem.push({
                         eventId,
                         description,
                         emissions,
                     });
                 }
 
             }
 
             for (i = 0; i < listOfSubItems.length; i++) {
                description = listOfSubItems[i];
                 emissionsForThatSubItem =
                     carbonEmissionsPerEventPerSubItem.filter(
                         (item) => item.description === description
                     );
 
                 arrayOfEmissionsByEvent = [];
                 arrayOfEmissionsByEventPerAttendee = [];
                 arrayOfEmissionsByEventPerGBP = [];
 
                 for (j = 0; j < selectedEvent.length; j++) {
                     eventId = selectedEvent[j].value;
                     emissions = emissionsForThatSubItem
                         .filter((item) => item.eventId === eventId)
                         .map((item) => item.emissions)
                         .reduce((prev, curr) => Number(prev) + Number(curr), 0);
                     arrayOfEmissionsByEvent.push(emissions);
                     arrayOfEmissionsByEventPerAttendee.push(emissions / filteredResByEventsAndFootprintTypeAndYear.filter(item => item.eventId === eventId).map(item => item.eventFootprintNumberOfAttendees).reduce((prev, curr) => Number(prev) + Number(curr), 0))
                    //  arrayOfEmissionsByEventPerGBP.push(emissions / (filteredResByEventsAndFootprintTypeAndYear.filter(item => item.eventId === eventId).map(item => item.eventTurnoverGBP).reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000))
 
                 }

                if (description !== "") {
                 datasetsArray.push({
                     label: description,
                     data: arrayOfEmissionsByEvent,
                     backgroundColor: chartColours[chartColourIndex],
                     borderColor: chartColours[chartColourIndex],
                     hoverBackgroundColor: chartHoverColours[chartColourIndex],
                 });
 
                 datasetsArrayPerAttendee.push({
                     label: description,
                     data: arrayOfEmissionsByEventPerAttendee,
                     backgroundColor: chartColours[chartColourIndex],
                     borderColor: chartColours[chartColourIndex],
                     hoverBackgroundColor: chartHoverColours[chartColourIndex],
                 });
 
                 datasetsArrayPerGBP.push({
                     label: description,
                     data: arrayOfEmissionsByEventPerGBP,
                     backgroundColor: chartColours[chartColourIndex],
                     borderColor: chartColours[chartColourIndex],
                     hoverBackgroundColor: chartHoverColours[chartColourIndex],
                 });
 
                 if (chartColourIndex === chartColours.length - 1) {
                     chartColourIndex = 0
                 } else {
                     chartColourIndex++
                 }

                }

             }
 
             setStackedBarChartLabelsForSubItems(selectedEvent.sort((item1, item2) => item1.label < item2.label ? -1 : item1.label > item2.label ? 1 : 0).map(item => item.label))
             setStackedBarChartCarbonEmissionsForSubItems(datasetsArray);
             setStackedBarChartCarbonEmissionsPerAttendeeForSubItems(datasetsArrayPerAttendee);
             setStackedBarChartCarbonEmissionsPerGBPForSubItems(datasetsArrayPerGBP);

        }

    }, [selectedEvent, selectedYear, selectedFootprintType, selectedItemSubCategory, selectedSubItem, numberOfRecordsToShow, company, role]);

    if (
        events.length > 0 &&
        eventFootprints.length > 0 &&
        !loading
    ) {
        return (
            <div>
                {!sidebarToggle && (
                    <button
                        className="absolute top-2 left-1 text-congress-blue"
                        onClick={() => setSidebarToggle(!sidebarToggle)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M4 6h16M4 12h16M4 18h16"
                            />
                        </svg>
                    </button>
                )}
                {sidebarToggle && (
                    <button
                        className="absolute top-2 left-1 text-white"
                        onClick={() => setSidebarToggle(!sidebarToggle)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                )}

                <div className="flex bg-slate-100 h-screen overflow-hidden">
                    {sidebarToggle && <Sidebar />}
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Event" section="Dashboard View" iconFileName="Dashboard-Hover" showUserMenu='true' url={role === 3001 || role === 6001 ? "/ViewClient/" + company : "/"}/>

                        <div className="flex items-center">
                            <div className="w-full mx-auto m-5 px-3">
                                <div className="grid grid-cols-2 gap-2 sm:gap-7 sm:grid-cols-4">
                                    <div>
                                        <label>Event</label>
                                        <MultiSelect
                                            options={events.map((item) => {
                                                return {
                                                    label: item.eventName,
                                                    value: item.id
                                                }
                                            })}
                                            value={selectedEvent}
                                            onChange={setSelectedEvent}
                                            labelledBy="Select"
                                        />
                                    </div>
                                    <div>
                                        <label>Year</label>
                                        <MultiSelect
                                            options={selectedYearDefaults}
                                            value={selectedYear}
                                            onChange={setSelectedYear}
                                            labelledBy="Select"
                                        />
                                    </div>

                                    <div>
                                        <label>Sub Category</label>
                                        <MultiSelect
                                            options={subCategories}
                                            value={selectedItemSubCategory}
                                            onChange={setSelectedItemSubCategory}
                                            labelledBy="Select"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="flex items-center">
                            <div className="w-full mx-auto mb-5 px-3">
                                <div className="grid gap-2 sm:gap-7 sm:grid-cols-3">

                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                                        <div>
                                            <div className="text-sm text-gray-500 ">Total Carbon Emissions (tCO<sub>2</sub>e): </div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-pacific-blue "><CountUp
                                                    start={0}
                                                    end={(
                                                        carbonEmissions.reduce(
                                                            (prev, curr) => Number(prev) + Number(curr),
                                                            0
                                                        )
                                                    ).toFixed(2)}
                                                    separator=","
                                                /></div>
                                            </div>
                                        </div>
                                        <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                                        <div>
                                            <div className="text-sm text-gray-500 ">Events Selected:  </div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-pacific-blue "><CountUp
                                                    start={0}
                                                    end={selectedEvent.length}
                                                    separator=","
                                                /></div>
                                            </div>
                                        </div>
                                        <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                                            </svg>
                                        </div>
                                    </div>

                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                                        <div>
                                            <div className="text-sm text-gray-500 ">Sub Categories Selected:  </div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-pacific-blue ">{selectedItemSubCategory.map(item => item.value + "; ")}</div>
                                            </div>
                                        </div>
                                        <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="basis-full rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white relative">
                            <Line data={dataChart7c} options={barAndLineOptions} height={100} />

                        </div>

                        <div>

                            <div className="basis-full rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Line data={dataChart7e} options={barAndLineOptionsPerAttendee} height={100} />
                            </div>
                        </div>



                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div>
                {!sidebarToggle && (
                    <button
                        className="absolute top-2 left-1"
                        onClick={() => setSidebarToggle(!sidebarToggle)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M4 6h16M4 12h16M4 18h16"
                            />
                        </svg>
                    </button>
                )}
                {sidebarToggle && (
                    <button
                        className="absolute top-2 left-1"
                        onClick={() => setSidebarToggle(!sidebarToggle)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                )}

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle && <Sidebar />}
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Event" section="Dashboard View" iconFileName="Dashboard-Hover" showUserMenu='true' url={role === 3001 || role === 6001 ? "/ViewClient/" + company : "/"}/>
                        <p>Data is loading...</p>
                    </div>

                </div>
            </div>
        );
    }
};

export default DashboardEvents;
