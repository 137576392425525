import React, { useState, useEffect, useContext } from 'react';
import { Navigate } from 'react-router-dom'
import AuthService from "../services/AuthService";
//import { UserContext } from '../context/UserContext';
//import { render } from 'react-dom/cjs/react-dom.development';


const LoginNCS = () => {
    /**
     * 
     */
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [emailError, setEmailError] = useState("")
    const [userError, setUserError] = useState("")
    const [redirecting, setRedirecting] = useState(false)
    // Value keeps the response from the API, it is used to determine users' role
    // const { value, setValue } = useContext(UserContext)
    const value = JSON.parse(localStorage.getItem("user"))
    const re = /\S+@\S+\.\S+/

    const onSubmit = (e) => {

        e.preventDefault()

        if (emailError == "✔️") {
            try {
                AuthService.login(email, password)
                    .then((resData) => {
                        const roles = resData.roles
                        const access_token = resData.access_token
                        // setValue({ roles, access_token, });
                        // console.log(value)
                        // console.log(redirecting, value?.roles)
                        setRedirecting(true);
                        // console.log(redirecting, value?.roles)

                    })
            }
            catch (error) {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                setUserError("Please provide a valid email address and password.")

            }

        }

    }


    useEffect(() => {
        if (email.match(re)) {
            setEmailError("✔️")
        }

        else {
            setEmailError("")
        }
    })

    if (redirecting && (value?.roles !== 'undefined') && (value?.roles == 2001)) {
        console.log('goin/\ to / ')

        return (
            <Navigate replace to="/listClientProjects" />
        )

    } else if (redirecting && (value?.roles !== 'undefined') && (value?.roles == 1001)) {
        console.log('goin to / ')
        return (
            <Navigate replace to="/listClientProjects" />
        )

    } else {

        return (
            <form className="min-h-screen bg-jungle-mist-100 flex flex-wrap items-center justify-center py-2 px-2 sm:px-6 lg:px-8 w-full " onSubmit={onSubmit}>
                <div className="max-w-3xl flex shadow-md overflow-hidden">

                    <div className="md:w-1/2 relative bg-white items-center justify-center ">

                        <div className="md:absolute md:inset-x-0 md:bottom-0">
                            <div className="m-12">
                                <img className="h-32" src='../images/NCS-Logo-Rev-02.svg' />
                            </div>
                            <h2 className="font-medium text-center text-xl text-pacific-blue-700 mb-6">Vero Platform</h2>
                            <p className="text-center text-red-500">{userError}</p>
                            <div className="w-4/5 mx-auto">
                                <div className="flex items-center bg-white rounded shadow-md mb-4">
                                    <span className="px-3">
                                        <svg className="fill-current text-pacific-blue-700
                                 w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M18 2a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4c0-1.1.9-2 2-2h16zm-4.37 9.1L20 16v-2l-5.12-3.9L20 6V4l-10 8L0 4v2l5.12 4.1L0 14v2l6.37-4.9L10 14l3.63-2.9z" /></svg>
                                    </span>
                                    <input
                                        id="email"
                                        // className={' w-full h-12 border-none focus:outline-none' + (errors.hasOwnProperty('username') ? "border-red-500" : '')}
                                        className='w-full h-12 border-none focus:outline-none'
                                        name="email"
                                        type="text"
                                        placeholder="Email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <p>{emailError}</p>
                                </div>
                                <section className="flex items-center bg-white rounded shadow-md mb-4">
                                    <span className="px-3">
                                        <svg className="fill-current text-pacific-blue-700 w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M4 8V6a6 6 0 1 1 12 0h-3v2h4a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h1zm5 6.73V17h2v-2.27a2 2 0 1 0-2 0zM7 6v2h6V6a3 3 0 0 0-6 0z" /></svg>
                                    </span>
                                    <input
                                        id="password"
                                        className='w-full h-12 border-none focus:outline-none'
                                        name="password"
                                        type="password"
                                        placeholder="Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </section >
                                <button disabled={emailError !== "✔️"} className="bg-bondi-blue block mx-auto text-white text-sm uppercase rounded shadow-md px-8 py-3 m-6 ">Log in</button>
                            </div>
                        </div>
                    </div>
                    <div className='hidden md:inline-block md:w-1/2'><img src='../images/staircase.jpg' /></div>
                </div>
            </form>
        );
    }
}

export default LoginNCS;