import { useState, useEffect } from 'react'
import axios from 'axios'
import Header from '../UI/Header'
import Button from '../UI/Button'
import Modal from '../UI/Modal'
import { useNavigate, useMatch } from 'react-router-dom'
import FileUploadAndDownload from '../FileUploadAndDownload'
import { v4 as uuidv4 } from 'uuid'
import AuthService from '../../services/AuthService'


const AddOrUpdateBuildingOperationalCarbonEmissionsAssessmentItems = () => {

    const match = useMatch('/AddOrUpdateBuildingOperationalCarbonEmissionsAssessmentItem/:buildingId/:buildingOperationalCarbonEmissionsId/:buildingOperationalCarbonEmissionItemId')

    const navigate = useNavigate()

    const [showModal, setShowModal] = useState(false)

    const [usersLoading, setUsersLoading] = useState(false)
    const [loading, setLoading] = useState(false)

    const [carbonEmissionFactors, setCarbonEmissionFactors] = useState([])

    const [users, setUsers] = useState([])

    const [organisationId, setOrganisationId] = useState('')
    const [organisationFootprintRef, setOrganisationFootprintRef] = useState('')
    const [organisationFootprintYear, setOrganisationFootprintYear] = useState('')
    const [buildingOperationalCarbonEmissionsAssessmentItems, setBuildingOperationalCarbonEmissionsAssessmentItems] = useState([])

    const [id, setOrganisationFootprintItemId] = useState()
    const [itemDescription, setOrganisationFootprintItemDescription] = useState('')
    const [consumption, setOrganisationFootprintItemConsumption] = useState(0)
    const [unit, setOrganisationFootprintItemUnit] = useState('')
    const [emissionFactor, setOrganisationFootprintItEmissionFactor] = useState(0)
    const [carbonEmissions, setOrganisationFootprintItemCarbonEmissions] = useState(0)
    const [itemConsultantComment, setOrganisationFootprintItemConsultantComment] = useState('')

    const [emissionsFactorSource, setEmissionsFactorSource] = useState('')
    const [filesUploaded, setFilesUploaded] = useState(0)

    const [company, setCompany] = useState("")
    const [role, setRole] = useState(0)
    const [userPropertiesLoading, setUserPropertiesLoading] = useState(true)

    const getUserProperties = async () => {

        await axios.get(process.env.REACT_APP_API_URL + "/current_user_properties")
            .then((response) => {
                setCompany(response.data.company)
                setRole(response.data.role)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUserPropertiesLoading(false);
            });

    }

    const removeCommas = (num) => num.toString().replace(/,/g, "");

    const handleChangeForConsumption = (e) => {
        const value = e.target.value;
        const clean = value.replace(/,/g, "");
        const regex = /^[0-9]*\.?[0-9]*$/;

        if (value && clean.match(regex)) {
            if (!value.includes(".")) {
                const formatted = new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 10,
                }).format(parseFloat(clean));
                setOrganisationFootprintItemConsumption(formatted);
            } else {
                setOrganisationFootprintItemConsumption(value);
            }
        } else {
            setOrganisationFootprintItemConsumption(value.replace(/[^0-9,.]/g, ''))
        }
    };

    const setFormattedConsumption = (e) => {
        const value = e.toString();
        const clean = value.replace(/,/g, "");
        const regex = /^[0-9]*\.?[0-9]*$/;

        if (value && clean.match(regex)) {

            const formatted = new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 10,
            }).format(parseFloat(clean));
            setOrganisationFootprintItemConsumption(formatted);

        } else {
            setOrganisationFootprintItemConsumption("");
        }

    };

    const handleChangeForEmissions = (e) => {
        const value = e.target.value;
        const clean = value.replace(/,/g, "");
        const regex = /^[0-9]*\.?[0-9]*$/;

        if (value && clean.match(regex)) {
            if (!value.includes(".")) {
                const formatted = new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 10,
                }).format(parseFloat(clean));
                setOrganisationFootprintItemCarbonEmissions(formatted);
            } else {
                setOrganisationFootprintItemCarbonEmissions(value);
            }
        } else {
            setOrganisationFootprintItemCarbonEmissions(value.replace(/[^0-9,.]/g, ''))
        }
    };

    const setFormattedEmissions = (e) => {
        const value = e.toString();
        const clean = value.replace(/,/g, "");
        const regex = /^[0-9]*\.?[0-9]*$/;

        if (value && clean.match(regex)) {

            const formatted = new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 10,
            }).format(parseFloat(clean));
            setOrganisationFootprintItemCarbonEmissions(formatted);

        } else {
            setOrganisationFootprintItemCarbonEmissions("");
        }

    };

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const setSelectedFactor = (e, datasource) => {

        setOrganisationFootprintItEmissionFactor(Number(e))
        setEmissionsFactorSource(datasource)

    }

    const setUpdatedNumberOfFilesUploaded = async (e) => {

        setFilesUploaded(Number(e) + 1)

        buildingOperationalCarbonEmissionsAssessmentItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).filesUploaded = Number(e) + 1

        await axios.put(process.env.REACT_APP_API_URL + '/organisations_footprints/' + match.params.footprintId, {organisationId, organisationFootprintRef })

    }

    const onSubmit = (e) => {
        e.preventDefault()

        if (!isNaN(Number(removeCommas(carbonEmissions))) && !isNaN(Number(removeCommas(consumption)))) {
                
            if (match.params.footprintItemId === "_add") {
                buildingOperationalCarbonEmissionsAssessmentItems.push({ id, itemDescription, consumption: Number(removeCommas(consumption)), unit, emissionFactor, carbonEmissions: Number(removeCommas(carbonEmissions)) })
            }
    
            updateForm({organisationId, buildingOperationalCarbonEmissionsAssessmentItems }).then(res => { navigate(-1) })


        } else {

            window.alert("Please check that numeric values are valid")

        }

    }

    const updateForm = async (form) => {
        if (match.params.footprintItemId !== "_add") {
            buildingOperationalCarbonEmissionsAssessmentItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).id = id
            buildingOperationalCarbonEmissionsAssessmentItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).itemDescription = itemDescription
            buildingOperationalCarbonEmissionsAssessmentItems.find(footprintItem => footprintItem.id === match.params.footprintItemId).consumption = Number(removeCommas(consumption))

        }
        await axios.put(process.env.REACT_APP_API_URL + '/organisatibuildings_operational_carbon_emissionsons_footprints/' + match.params.footprintId, form)
    }

    useEffect(() => {

        setUserPropertiesLoading(true)

        getUserProperties()

    }, [])

    useEffect(() => {

        if (match.params.buildingOperationalCarbuildingOperationalCarbonEmissionsAssessmentItemId !== "_add") {

            setLoading(true)

            axios.get(process.env.REACT_APP_API_URL + '/buildings_operational_carbon_emissions/' + match.params.buildingOperationalCarbonEmissionsId)
                .then((res) => res.data)
                .then((res) => {
                    //Set the individual useState variables

                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });

        }
    }, [match.params.buildingOperationalCarbonEmissionsId, match.params.buildingId, match.params.buildingOperationalCarbuildingOperationalCarbonEmissionsAssessmentItemId])
    

    if (!userPropertiesLoading && role && company && !loading && !usersLoading) {

        return (

            <div className='flex items-center bg-jungle-mist-100 min-h-screen'>
                <div className="container-form-8A">

                    <Header title="Buildings - Add / Edit Operational Carbon Emissions Assessment Item" section="Building Operational Footprint Item Details" iconFileName="Building-Hover" url={role === 3001 || role === 6001 ? "/ViewClient/" + company : "/"}/>

                    <br></br>

                    <div className="flex items-center justify-between p-5 bg-white rounded-2xl shadow-md shadow-slate-200 border border-slate-200">
                        <div>
                            <div className="text-sm text-gray-500 ">Uploaded Documentary Evidence</div>
                            <div className="flex items-center pt-1">
                                <div className="text-xs font-medium text-indigo-500 w-11/12"><FileUploadAndDownload id={match.params.footprintItemId} onChange={setUpdatedNumberOfFilesUploaded} allowUploads={role === 4001 || role === 2004 || role === 6001 || role === 2001 ? true : false}/></div>
                            </div>
                        </div>
                        <div className="text-gray-400">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M5 19a2 2 0 01-2-2V7a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1M5 19h14a2 2 0 002-2v-5a2 2 0 00-2-2H9a2 2 0 00-2 2v5a2 2 0 01-2 2z" />
                            </svg>
                        </div>

                    </div>

                    <form className="add-form-8A" onSubmit={onSubmit}>

                        <div className="form-control-8A">
                            <label>Footprint Item Description</label>
                            <input type="text" placeholder="Enter Footprint Item Description Here" value={itemDescription} onChange={(e) => setOrganisationFootprintItemDescription(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Consultant Comment</label>
                            <input type="text" placeholder="Enter Consultant Comment Here" value={itemConsultantComment} onChange={(e) => setOrganisationFootprintItemConsultantComment(e.target.value)} />
                        </div>

                        <input type="submit" value="Save" className="btn-8A btn-block-8A" />

                    </form>
                    <Modal reportingYear={organisationFootprintYear} tableData={carbonEmissionFactors} onChange={setSelectedFactor} onClose={() => setShowModal(false)} show={showModal} title="Browse Emission Factor Database" />
                </div>
            </div>
        )

    } else {

        return (
            <div className='flex bg-jungle-mist-100 min-h-screen'>
                <div className="container-form-8A">
                    <Header title="Organisations - Add / Edit Footprint Item" section="Organisation Footprint Item Details" iconFileName="Building-Hover" url={role === 3001 || role === 6001 ? "/ViewClient/" + company : "/"}/>
                    <p>Data is loading...</p>
                </div>
            </div>)

    }

}

export default AddOrUpdateBuildingOperationalCarbonEmissionsAssessmentItems