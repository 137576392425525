import { useEffect, useState } from 'react'
import axios from 'axios'
import Button from '../UI/Button'
import Header from '../UI/Header'
import { useNavigate } from 'react-router-dom'
import Sidebar from '../UI/Sidebar'
import Options from '../UI/Options'
import AuthService from '../../services/AuthService'

const ListUsers = () => {

    const [users, setUsers] = useState([])
    const [clients, setClients] = useState([])
    const [currentUserRole, setCurrentUserRole] = useState('')
    const [username, setUsersname] = useState('')

    const [sidebarToggle, setSidebarToggle] = useState(false)

    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {

        setLoading(true)

        setUsersname(AuthService.getCurrentUserName())

        axios.get(process.env.REACT_APP_API_URL + '/users')
            .then((res) => {
                setUsers(res.data.filter(item => item.id !== '6303a09f7ba3f683fef4e25a').sort((item1, item2) => item1.firstName + item1.lastName < item2.firstName + item2.lastName ? -1 : 0))
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });

            axios.get(process.env.REACT_APP_API_URL + '/clients')
            .then((res) => {
                setClients(res.data)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });

            axios.get(process.env.REACT_APP_API_URL + "/current_user_properties")
            .then((response) => {
                setCurrentUserRole(response.data.role)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });

    }, [])

    const deleteUser = (id) => {
        // axios.delete(process.env.REACT_APP_API_URL + '/users/' + id)
        // setUsers(users.filter((user) => user.id !== id))
    }

    const editUser = (id) => {
        navigate(`/addOrUpdateUser/${id}`);
    }

    if (users.length > 0 && !loading && clients.length > 0) {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">

                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Users" section="Select a User" showUserMenu='true' />
                        <div className="flex flex-col py-2">
                            <div className="overflow-x-auto items-holder-8A">
                                <div className="text-eight-text align-middle inline-block min-w-full px-3">
                                    <div className="shadow-md shadow-slate-300 rounded-2xl">
                                        <table className="objects-8A min-w-full divide-y divide-gray-200">

                                            <thead className="bg-pacific-blue-50 z-50 sticky top-0">
                                                <tr>
                                                    <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> User First Name</th>
                                                    <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> User Last Name</th>
                                                    <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> User email</th>
                                                    <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> User Company</th>
                                                    <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> User Status</th>
                                                    <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> User Line Manager</th>
                                                    <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> User Department</th>
                                                    <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider md:pr-10 lg:pr-20 float-right"> Actions</th>
                                                </tr>
                                            </thead>

                                            <tbody className="bg-white divide-y divide-gray-200">
                                                {users.map((user) => (
                                                    <tr key={user.id}>
                                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {user.firstName}</td>
                                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {user.lastName}</td>
                                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {user.emailId}</td>
                                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {user.company.length !== 24 ? user.company : clients.find(item => item.id === user.company).clientName }</td>
                                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {user.role === 9999 ? "Inactive" : "Active" }</td>
                                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {user.lineManager.length>0 ? users.find(item => item.id === user.lineManager).firstName + " " + users.find(item => item.id === user.lineManager).lastName : ""}</td>
                                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer"> {user.department}</td>
                                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap md:pr-10 lg:pr-20 float-right">
                                                            {( username==='stacey.cougill@eightversa.com' || username==='yiota.paraskeva@eightversa.com' || username==='cristina.porubin@eightversa.com' || username==='chris.hocknell@eightversa.com' || username==='james.wilton@eightversa.com' || username==='daniel.champ@eightversa.com' || username==='sabina.adamska@eightversa.com' ) &&
                                                                <Options edit={`/addOrUpdateUser/${user.id}`} ></Options>
                                                            }
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br></br>
                    </div>
                </div>
            </div>
        )

    } else {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Users" section="Select a User" showUserMenu='true' />
                        <p>Data is loading</p>
                    </div>

                </div>

            </div>
        )

    }

}

export default ListUsers
