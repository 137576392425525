import Header from '../UI/Header'
import { useState, useEffect, useRef } from 'react'
import { useMatch } from 'react-router-dom'
import Sidebar from '../UI/Sidebar'
import axios from 'axios'
import ReactPDF, { Page, Text, View, Document, StyleSheet, Image, Font} from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';


const ViewBREEAMSiteVisitReport = () => {

    Font.register({ family: 'TT Norms Pro', src: '/fonts/TypeType - TT Norms Pro Regular.ttf', fontStyle: 'normal', fontWeight: 'normal' });

    // const match = useMatch('/viewBuilding/:buildingId')

    const match = useMatch('/viewBREEAMInformationScheduleReport/:buildingId/:assessmentId/:stakeholder')

    const [sidebarToggle, setSidebarToggle] = useState(false)
    const [buildingBREEAMAssessments, setBuildingBREEAMAssessments] = useState([])

    const [files, setFiles] = useState([]);

    const [clientName, setClientName] = useState('');

    const [loading, setLoading] = useState(true)
    const [buildingsLoading, setBuildingsLoading] = useState(true)
    const [clientsLoading, setClientsLoading] = useState(true)

    // const styles = StyleSheet.create({
    //     rowView: {
    //         fontSize: 8, display: 'flex', flexDirection: 'row', borderTop: '1px solid #EEE', paddingTop: 8,
    //       paddingBottom: 8,
    //       paddingHorizontal: 35,
    //     }
    // });

    useEffect(() => {

        axios.get(process.env.REACT_APP_API_URL + "/buildings_BREEAM_assessments/" + match.params.assessmentId)
            .then((response) => {
                // console.log(response.data)
                if (match.params.stakeholder !== "All") {
                    setBuildingBREEAMAssessments(response.data.credits.filter(item => item.documentaryEvidence.filter(item => item.responsibility === match.params.stakeholder).length > 0 && (item.creditsTargeted > 0 || item.creditsTargeted === "Yes")))
                } else {
                    setBuildingBREEAMAssessments(response.data.credits.filter(item => item.creditsTargeted > 0 || item.creditsTargeted === "Yes"))
                }
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });

        axios.get(process.env.REACT_APP_API_URL + "/buildings/" + match.params.buildingId)
            .then((response) => {
                axios.get(process.env.REACT_APP_API_URL + "/clients/" + response.data.clientId)
                .then((res) => {
                    setClientName(res.data.clientName)
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setClientsLoading(false);
                });
            })
            // setClientName (response.data.clientId) })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setBuildingsLoading(false);
            });

        axios.get(process.env.REACT_APP_API_URL + '/presigned_url/' + match.params.buildingId)
        // axios.get(process.env.REACT_APP_API_URL + '/presigned_url')
            .then((res) => {
                setFiles(res.data)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });

    }, [])

    // const Quixote = () => (
    //     <Document>
    //       <Page style={styles.body}>

    //         {buildingBREEAMAssessments.credits.map((rowData) => <>
    //             <View>

    //                     {rowData.documentaryEvidence.map((evidenceDocument) => (
    //                         <Text style={styles.rowView} key={evidenceDocument.id} >
    //                             {evidenceDocument.description}
    //                             <Text break></Text>
    //                         </Text>
    //                     ))}


    //             </View>
    //         </>)}
    //       </Page>
    //     </Document>
    //   );
      
      const styles = StyleSheet.create({
        body: {
          paddingTop: 35,
          paddingBottom: 65,
          paddingHorizontal: 35,
          fontFamily: 'TT Norms Pro'
        },
        reportTitle: {
            fontSize: 40,
            paddingTop: '40%',
            textAlign: 'center',
            fontFamily: 'TT Norms Pro'
            
          },
        reportSubtitle: {
            fontSize: 40,
            textAlign: 'center',
            fontFamily: 'TT Norms Pro'
            
          },
        author: {
            fontSize: 25,
            paddingTop: '40%',
            textAlign: 'center',
            fontFamily: 'TT Norms Pro'
          },
        title: {
          fontSize: 34,
          color: '#cbd5e1',
          paddingBottom: 10,
          fontFamily: 'TT Norms Pro'
        },
        section: {
            fontSize: 28,
            paddingBottom: 10,
            fontFamily: 'TT Norms Pro'
          },
        categoryCode: {
            fontSize: 15,
            paddingBottom: 10,
            paddingTop: 10,
            fontFamily: 'TT Norms Pro'
          },
        creditDescription: {
            fontSize: 16,
            paddingBottom: 10,
            fontFamily: 'TT Norms Pro'
          },
        imageDescription: {
            fontSize: 14,
            paddingBottom: 10,
            fontFamily: 'TT Norms Pro'
          },
        text: {
          margin: 12,
          fontSize: 14,
          textAlign: 'justify',
          fontFamily: 'TT Norms Pro'
        },
        logo: {
            width: '119',
            paddingBottom: 20,
            fontFamily: 'TT Norms Pro'
          },
        image: {
          width: 'auto',
          height: 'auto',
          objectFit: 'scale-down',
          paddingBottom: 20,
          paddingTop: 20,
          fontFamily: 'TT Norms Pro'
        },
        pageNumber: {
          position: 'absolute',
          fontSize: 12,
          bottom: 30,
          left: 0,
          right: 0,
          textAlign: 'center',
          color: 'grey',
          fontFamily: 'TT Norms Pro'
        },
        rowView: {
            fontSize: 8, display: 'flex', flexDirection: 'row', borderTop: '1px solid #EEE', borderBottom: '1px solid #EEE', paddingTop: 8,
          paddingBottom: 8,
          paddingHorizontal: 35,
          fontFamily: 'TT Norms Pro'
        },
        responsibility: {
            fontWeight: 'bold',
            fontSize: 10,
            fontFamily: 'TT Norms Pro'
        },
        tickBox: {
            fontWeight: 'bold',
            fontSize: 20,
            fontFamily: 'TT Norms Pro'
        }
      });
     

    if (!loading && !buildingsLoading && !clientsLoading) {

        return (

            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                        
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden mt-1 m-5">
                        <div>
                            <div className="flex items-center bg-slate-100">
                                <div className="container max-w-6xl px-5 mx-auto my-5">


                                </div>
                            </div>
                        </div>
                    </div>
                    <PDFViewer className='w-full'>
                        <Document>
                            <Page style={styles.body}>

                                {/* {buildingBREEAMAssessments.credits.filter(item => item.documentaryEvidence.filter(item => item.responsibility === match.params.stakeholder).length > 0 && item.creditsTargeted > 0).map((rowData) =>  */}
                                {buildingBREEAMAssessments.map((rowData) =>     
                                    <View key={rowData.id}>
                                            <Text style={styles.categoryCode}>
                                            {rowData.section + " - "}
                                            {rowData.creditCategoryCode + " - "}
                                            {rowData.creditCategoryDescription + " - "}
                                            {rowData.creditDescription}
                                            </Text>

                                            {match.params.stakeholder !== "All" &&

                                            <div>
                                            {rowData.documentaryEvidence.filter(item => item.evidenceDocumentRequired === "YES" && item.responsibility === match.params.stakeholder).map((evidenceDocument) => (
                                                <Text style={styles.rowView} key={evidenceDocument.id} >
                                                    <Text style={styles.tickBox}>
                                                    O
                                                    </Text>
                                                    <Text>
                                                    {" " + evidenceDocument.description}
                                                    </Text>
                                                    <Text>{"\n"}</Text>
                                                    <Text>{"\n"}</Text>
                                                    <Text style={styles.responsibility}>
                                                    {"(Responsibility: " + evidenceDocument.responsibility + " " + evidenceDocument.timeCritical + ")"}
                                                    </Text>

                                                </Text>
                                            ))}
                                            </div>
                                            }

                                            {match.params.stakeholder === "All" &&

                                            <div>
                                            {rowData.documentaryEvidence.filter(item => item.evidenceDocumentRequired === "YES").map((evidenceDocument) => (
                                                <Text style={styles.rowView} key={evidenceDocument.id} >
                                                    <Text style={styles.tickBox}>
                                                    O
                                                    </Text>
                                                    <Text>
                                                    {" " + evidenceDocument.description}
                                                    </Text>
                                                    <Text>{"\n"}</Text>
                                                    <Text>{"\n"}</Text>
                                                    <Text style={styles.responsibility}>
                                                    {"(Responsibility: " + evidenceDocument.responsibility + " " + evidenceDocument.timeCritical + ")"}
                                                    </Text>

                                                </Text>
                                            ))}
                                            </div>
                                            }


                                    </View>
                                )}
                            </Page>
                        </Document>
                    </PDFViewer >
                </div>

            </div>
        )

    } else {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                    <PDFViewer className='w-full'>
                        <Text>
                        Data is loading
                        </Text>
                    </PDFViewer >
                    </div>

                </div>

            </div>
        )

    }

};

export default ViewBREEAMSiteVisitReport;
