import { useEffect, useState } from 'react'
import axios from 'axios'
import Button from '../UI/Button'
import Header from '../UI/Header'
import Options from '../UI/Options'
import { useNavigate, useMatch } from 'react-router-dom'

const ListBuildingConstructionCarbonEmissions = () => {


    const match = useMatch('/viewBuilding/:buildingId')

    const [loading, setLoading] = useState(false)

    const [buildingConstructionCarbonEmissions, setBuildingConstructionCarbonEmissions] = useState([])

    useEffect(() => {

        setLoading(true)

        axios.get(process.env.REACT_APP_API_URL + "/buildings_construction_carbon_emissions")
            .then((response) => {
                setBuildingConstructionCarbonEmissions(response.data.filter(item => item.buildingId === match.params.buildingId))
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });

    }, [match.params.buildingId])

    const navigate = useNavigate ()

    const deleteBuildingConstructionCarbonEmissions = (id) => {
        if (window.confirm('Are you sure you want to delete this Assessment?')) {
            axios.delete(process.env.REACT_APP_API_URL + '/buildings_environmental_assessments/' + id)
            setBuildingConstructionCarbonEmissions(buildingConstructionCarbonEmissions.filter((assessment) => assessment.id !== id))
        }
    }

    return (
        <div className=''>
            <h1 className='pt-4 sm:px-6 lg:px-8 pb-2 mx-auto header-8A'>Construction Carbon Emissions</h1>

            <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full px-4 sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-jungle-mist-100 sm:rounded border-2">
                            <table className="objects-8A min-w-full divide-y divide-gray-200">
                                <thead className="bg-pacific-blue-50">
                                    <tr>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Address</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Area (m2)</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Embodied Carbon (A1-A5) in tonnes CO2/m2</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> WLC in tonnes CO2/m2</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> RIBA Stage</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Offsetting in tonnes CO2</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Construction Label</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider md:pr-10 lg:pr-20 float-right "> Actions</th>
                                    </tr>
                                </thead>


                                <tbody className="bg-white divide-y divide-gray-200">
                                {buildingConstructionCarbonEmissions.map((buildingCCAItem) => (
                                    <tr key={buildingCCAItem.id}>
                                        <td className="px-4 md:px-6 py-2 whitespace-nowrap">{buildingCCAItem.address}</td>
                                        <td className="px-4 md:px-6 py-2 whitespace-nowrap">{buildingCCAItem.areaSqMetres}</td>
                                        <td className="px-4 md:px-6 py-2 whitespace-nowrap">{buildingCCAItem.embodiedCarbon}</td>
                                        <td className="px-4 md:px-6 py-2 whitespace-nowrap">{buildingCCAItem.wholeLifeCycleCarbon}</td>
                                        <td className="px-4 md:px-6 py-2 whitespace-nowrap">{buildingCCAItem.RIBAStage}</td>
                                        <td className="px-4 md:px-6 py-2 whitespace-nowrap">{buildingCCAItem.offsetting}</td>
                                        <td className="px-4 md:px-6 py-2 whitespace-nowrap">{buildingCCAItem.constructionLabel}</td>
                                        <td className="px-4 md:px-6 py-2 whitespace-nowrap md:pr-10 lg:pr-20 float-right ">
                                        <Options deleteFunction={deleteBuildingConstructionCarbonEmissions} deleteObject={buildingCCAItem.id} edit={`/addOrUpdateBuildingConstructionCarbonEmissions/${match.params.buildingId}/${buildingCCAItem.id}`}></Options>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>

                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <br></br>
            <Button className='bg-bondi-blue' text="Add New Construction Carbon Emissions Assessment" onClick={() => navigate(`/addOrUpdateBuildingConstructionCarbonEmissions/${match.params.buildingId}/_add`)}/>

        </div>
    )

    /*     } else {
    
            return (
            <div>
                <Header title="BREEAM Assessments" section="" showLogo={false}/>
                <p>Data is loading...</p>
            </div>)
    
        } */

}

export default ListBuildingConstructionCarbonEmissions
