import { useEffect, useState } from 'react'
import { useNavigate, useMatch } from 'react-router-dom'
import ProgressBar from '../UI/ProgressBar'
import Options from '../UI/Options'
import axios from 'axios'
import { toBeInTheDocument } from '@testing-library/jest-dom/dist/matchers'
import ModalUpdateValue from '../UI/ModalUpdateValue'
import Button from '../UI/Button'
import ReactSelect from 'react-select'

const ListClientProjectActivities = (props) => {

    const match = useMatch('/viewClientProject/:projectId')

    const [projectActivities, setProjectActivities] = useState([])
    const [projectActivitiesFiltered, setProjectActivitiesFiltered] = useState([])

    const [usersLoading, setUsersLoading] = useState(true)
    const [users, setUsers] = useState([])

    const [showModal, setShowModal] = useState(false)
    const [valueToSendToModal, setValueToSendToModal] = useState('')
    const [objectIdToSendToModal, setObjectIdToSendToModal] = useState('')
    const [fieldToSendToModal, setFieldToSendToModal] = useState('')
    const [collectionToSendToModal, setCollectionToSendToModal] = useState('')
    const [collectionArrayToSendToModal, setCollectionArrayToSendToModal] = useState('')
    const [collectionArrayIdToSendToModal, setCollectionArrayIdToSendToModal] = useState('')

    const [selectedService, setSelectedService] = useState('All')
    const [selectedServiceComments, setSelectedServiceComments] = useState('All')
    const [selectedDepartment, setSelectedDepartment] = useState('All')
    const [selectedWorkStage, setSelectedWorkStage] = useState('All')
    const [selectedServiceManager, setSelectedServiceManager] = useState('All')
    const [selectedActivityOwner, setSelectedActivityOwner] = useState('All')

    const navigate = useNavigate()

    const internationalNumberFormat = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const styles = {
        option: provided => ({
            ...provided,
            color: 'black'
        }),
        control: provided => ({
            ...provided,
            color: 'black',
            width: 160,
            fontSize: 13
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'black'
        }),
        menu: provided => ({
            ...provided,
            color: 'black',
            width: 160,
            fontSize: 13
        }),
    }

    const clearFilters = () => {
        setSelectedService('All')
        setSelectedServiceComments('All')
        setSelectedDepartment('All')
        setSelectedWorkStage('All')
        setSelectedServiceManager('All')
        setSelectedActivityOwner('All')
    }

    const aggregateServicesArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.service === val.service);
            if (index === -1) {
                acc.push({
                    service: val.service,
                });
            };
            return acc;
        }, []);
    };

    const aggregateServiceCommentsArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.serviceComments === val.serviceComments);
            if (index === -1) {
                acc.push({
                    serviceComments: val.serviceComments,
                });
            };
            return acc;
        }, []);
    };

    const aggregateDepartmentsArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.department === val.department);
            if (index === -1) {
                acc.push({
                    department: val.department
                });
            };
            return acc;
        }, []);
    };

    const aggregateWorkStagesArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.workStage === val.workStage);
            if (index === -1) {
                acc.push({
                    workStage: val.workStage
                });
            };
            return acc;
        }, []);
    };

    const aggregateServiceManagersArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.serviceManager === val.serviceManager);
            if (index === -1) {
                acc.push({
                    serviceManager: val.serviceManager,
                    serviceManagerName: users.find(user => user.id === val.serviceManager).firstName + ' ' + users.find(user => user.id === val.serviceManager).lastName
                });
            };
            return acc;
        }, []);
    };

    const aggregateActivityOwnersArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.activityOwner === val.activityOwner);
            if (index === -1) {
                acc.push({
                    activityOwner: val.activityOwner,
                    activityOwnerName: users.find(user => user.id === val.activityOwner).firstName + ' ' + users.find(user => user.id === val.activityOwner).lastName
                });
            };
            return acc;
        }, []);
    };

    const deleteProjectActivity = async (activityAndServiceIDs) => {

        if (window.confirm('Are you sure you want to delete Activity?')) {

            //code here to to an await get on timesheets to check activity doesn't exist in timesheets:

            var activityExistsInTimesheets = false

            await axios.get(process.env.REACT_APP_API_URL + "/timesheets_by_projectId/" + match.params.projectId)
            .then((res) => {
                if (res.data.filter(item => item.activityId === activityAndServiceIDs.activityId).length > 0) {
                    activityExistsInTimesheets = true
                }
            })
            .catch((err) => {
                console.log(err);
            })


            if (activityExistsInTimesheets) {
                window.alert("Please remove activity from timesheets in order to delete activity")
            } else {

                var service
                var activities

                await axios.get(process.env.REACT_APP_API_URL + "/projects_services/" + activityAndServiceIDs.serviceId)
                    .then((res) => {

                        service = res.data
                        activities = res.data.activities.filter((activity) => activity.activityId !== activityAndServiceIDs.activityId)

                            })
                    .catch((err) => {
                        console.log(err);
                    })
                
                axios.put(process.env.REACT_APP_API_URL + '/projects_services/' + activityAndServiceIDs.serviceId, { ...service, activities: activities })

                setProjectActivities(projectActivities.filter((activity) => activity.activityKey !== activityAndServiceIDs.activityKey))

            }
        }
    }


    useEffect(() => {

        setUsers(props.users.sort((item1, item2) => item1.firstName + item1.lastName < item2.firstName + item2.lastName ? -1 : 0))
        setUsersLoading(false)


        var activitiesForProject = []
        var service
        var serviceIndex
        var activityIndex
        var activity
        var activityItem = 1
        var actualHours
        var activityId
        var serviceId
        var estimatedHours
        var fee
        var complete
        var toBeInvoiced
        var activityComplete
        var projectId = match.params.projectId
        var cost
        var costCategory
        var serviceComments
        var serviceStatus
        var workStage
        var serviceManager
        var activityOwner
        var department
        var activityIdInTimesheets


        for (serviceIndex = 0; serviceIndex < props.services.filter((item) => item.projectId === projectId).length; serviceIndex++) {
            for (activityIndex = 0; activityIndex < props.services.filter((item) => item.projectId === projectId)[serviceIndex].activities.length; activityIndex++) {

                //filter timesheet data here to serviceId and activityId to get actual hours

                service = props.services.filter((item) => item.projectId === projectId)[serviceIndex].service
                serviceId = props.services.filter((item) => item.projectId === projectId)[serviceIndex].id
                activityId = props.services.filter((item) => item.projectId === projectId)[serviceIndex].activities[activityIndex].activityId
                activity = props.services.filter((item) => item.projectId === projectId)[serviceIndex].activities[activityIndex].activity
                estimatedHours = props.services.filter((item) => item.projectId === projectId)[serviceIndex].activities[activityIndex].estimatedHours
                fee = props.services.filter((item) => item.projectId === projectId)[serviceIndex].activities[activityIndex].fee
                toBeInvoiced = props.services.filter((item) => item.projectId === projectId)[serviceIndex].activities[activityIndex].activityToBeInvoiced
                activityComplete = props.services.filter((item) => item.projectId === projectId)[serviceIndex].activities[activityIndex].activityComplete
                actualHours = props.timesheets.filter((item) => item.serviceId === serviceId && item.activityId === activityId).map(item => item.timesheetHours).reduce((prev, curr) => Number(prev) + Number(curr), 0)
                
                if (props.timesheets.filter((item) => item.serviceId === serviceId && item.activityId === activityId).length > 0) { 
                    activityIdInTimesheets = true
                } else {
                    activityIdInTimesheets = false
                }

                complete = (actualHours / estimatedHours) * 100
                cost = props.services.filter((item) => item.projectId === projectId)[serviceIndex].activities[activityIndex].cost
                costCategory = props.services.filter((item) => item.projectId === projectId)[serviceIndex].activities[activityIndex].costCategory
                serviceComments = props.services.filter((item) => item.projectId === projectId)[serviceIndex].serviceComments
                serviceStatus = props.services.filter((item) => item.projectId === projectId)[serviceIndex].serviceStatus
                workStage = props.services.filter((item) => item.projectId === projectId)[serviceIndex].workStage
                department = props.services.filter((item) => item.projectId === projectId)[serviceIndex].department
                serviceManager = props.services.filter((item) => item.projectId === projectId)[serviceIndex].serviceManager
                activityOwner = props.services.filter((item) => item.projectId === projectId)[serviceIndex].activities[activityIndex].activityOwner
                activitiesForProject.push({ service, serviceId, activityKey: activityItem, activityId, activity, estimatedHours, fee, complete, toBeInvoiced, actualHours, activityComplete, cost, costCategory, serviceComments, serviceStatus, workStage, department, serviceManager, activityOwner, activityIdInTimesheets })
                activityItem++

            }
        }

        if (props.billingCompany === "All") {

            setProjectActivities(activitiesForProject.sort((item1, item2) => item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : item1.serviceComments < item2.serviceComments ? -1 : item1.serviceComments > item2.serviceComments ? 1 : item1.workStage < item2.workStage ? -1 : item1.workStage > item2.workStage ? 1 : item1.activity < item2.activity ? -1 : item1.activity > item2.activity ? 1 : 0))
            setProjectActivitiesFiltered(activitiesForProject.sort((item1, item2) => item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : item1.serviceComments < item2.serviceComments ? -1 : item1.serviceComments > item2.serviceComments ? 1 : item1.workStage < item2.workStage ? -1 : item1.workStage > item2.workStage ? 1 : item1.activity < item2.activity ? -1 : item1.activity > item2.activity ? 1 : 0))

        }

        if (props.billingCompany === "Eight Versa") {

            setProjectActivities(activitiesForProject.filter((item) => item.department.slice(0,2) !== "20").sort((item1, item2) => item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : item1.serviceComments < item2.serviceComments ? -1 : item1.serviceComments > item2.serviceComments ? 1 : item1.workStage < item2.workStage ? -1 : item1.workStage > item2.workStage ? 1 : item1.activity < item2.activity ? -1 : item1.activity > item2.activity ? 1 : 0))
            setProjectActivitiesFiltered(activitiesForProject.sort((item1, item2) => item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : item1.serviceComments < item2.serviceComments ? -1 : item1.serviceComments > item2.serviceComments ? 1 : item1.workStage < item2.workStage ? -1 : item1.workStage > item2.workStage ? 1 : item1.activity < item2.activity ? -1 : item1.activity > item2.activity ? 1 : 0))

        }

        if (props.billingCompany === "NCS") {

            setProjectActivities(activitiesForProject.filter((item) => item.department.slice(0,2) === "20").sort((item1, item2) => item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : item1.serviceComments < item2.serviceComments ? -1 : item1.serviceComments > item2.serviceComments ? 1 : item1.workStage < item2.workStage ? -1 : item1.workStage > item2.workStage ? 1 : item1.activity < item2.activity ? -1 : item1.activity > item2.activity ? 1 : 0))
            setProjectActivitiesFiltered(activitiesForProject.sort((item1, item2) => item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : item1.serviceComments < item2.serviceComments ? -1 : item1.serviceComments > item2.serviceComments ? 1 : item1.workStage < item2.workStage ? -1 : item1.workStage > item2.workStage ? 1 : item1.activity < item2.activity ? -1 : item1.activity > item2.activity ? 1 : 0))

        }

    }, [match.params.projectId, props.timesheets, props.services, props.users, props.billingCompany])

    useEffect(() => {

        setProjectActivitiesFiltered(projectActivities)

        if (selectedService !== "All") {
            setProjectActivitiesFiltered(activitiesFiltered => activitiesFiltered.filter((item) => item.service === selectedService.value))
        }

        if (selectedServiceComments !== "All") {
            setProjectActivitiesFiltered(activitiesFiltered => activitiesFiltered.filter((item) => item.serviceComments === selectedServiceComments.value))
        }

        if (selectedServiceManager !== "All") {
            setProjectActivitiesFiltered(activitiesFiltered => activitiesFiltered.filter((item) => item.serviceManager === selectedServiceManager.value))
        }

        if (selectedDepartment !== "All") {
            setProjectActivitiesFiltered(activitiesFiltered => activitiesFiltered.filter((item) => item.department === selectedDepartment.value))
        }

        if (selectedActivityOwner !== "All") {
            setProjectActivitiesFiltered(activitiesFiltered => activitiesFiltered.filter((item) => item.activityOwner === selectedActivityOwner.value))
        }

        if (selectedWorkStage !== "All") {
            setProjectActivitiesFiltered(activitiesFiltered => activitiesFiltered.filter((item) => item.workStage === selectedWorkStage.value))
        }

    }, [selectedService, selectedServiceComments, selectedServiceManager, selectedDepartment, selectedActivityOwner, selectedWorkStage, projectActivities])


    if (projectActivitiesFiltered.length > 0 && projectActivities.length > 0 && !usersLoading && users.length > 0) {

        return (
            <div>
                <h2 className='pt-4 px-3 pb-2 w-full max-w-9xl mx-auto header-8A'>Activities</h2>
                <hr></hr>

                <div className="flex items-center">
                    <div className="w-full mx-auto m-5">
                        <div className="grid grid-cols-2 gap-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-7 px-3">
                            <div>
                                <p className="text-xs">Department</p>
                                <ReactSelect
                                    styles={styles}
                                    value={selectedDepartment}
                                    onChange={setSelectedDepartment}
                                    options={aggregateDepartmentsArray(projectActivities).sort((item1, item2) => item1.department < item2.department ? -1 : 0).map((item) => {
                                        return {
                                            label: item.department,
                                            value: item.department
                                        }
                                    })} />
                            </div>

                            <div>
                                <p className="text-xs">Service</p>
                                <ReactSelect
                                    styles={styles}
                                    value={selectedService}
                                    onChange={setSelectedService}
                                    options={aggregateServicesArray(projectActivities).sort((item1, item2) => item1.service < item2.service ? -1 : 0).map((item) => {
                                        return {
                                            label: item.service,
                                            value: item.service
                                        }
                                    })} />
                            </div>

                            <div>
                                <p className="text-xs">Service Comments</p>
                                <ReactSelect
                                    styles={styles}
                                    value={selectedServiceComments}
                                    onChange={setSelectedServiceComments}
                                    options={aggregateServiceCommentsArray(projectActivities).sort((item1, item2) => item1.serviceComments < item2.serviceComments ? -1 : 0).map((item) => {
                                        return {
                                            label: item.serviceComments,
                                            value: item.serviceComments
                                        }
                                    })} />
                            </div>

                            <div>
                                <p className="text-xs">Work Stage</p>
                                <ReactSelect
                                    styles={styles}
                                    value={selectedWorkStage}
                                    onChange={setSelectedWorkStage}
                                    options={aggregateWorkStagesArray(projectActivities).sort((item1, item2) => item1.workStage < item2.workStage ? -1 : 0).map((item) => {
                                        return {
                                            label: item.workStage,
                                            value: item.workStage
                                        }
                                    })} />
                            </div>

                            <div>
                                <p className="text-xs">Service Manager</p>
                                <ReactSelect
                                    styles={styles}
                                    value={selectedServiceManager}
                                    onChange={setSelectedServiceManager}
                                    options={aggregateServiceManagersArray(projectActivities).sort((item1, item2) => item1.serviceManager < item2.serviceManager ? -1 : 0).map((item) => {
                                        return {
                                            label: item.serviceManagerName,
                                            value: item.serviceManager
                                        }
                                    })} />
                            </div>

                            <div>
                                <p className="text-xs">Activity Owner</p>
                                <ReactSelect
                                    styles={styles}
                                    value={selectedActivityOwner}
                                    onChange={setSelectedActivityOwner}
                                    options={aggregateActivityOwnersArray(projectActivities).sort((item1, item2) => item1.activityOwner < item2.activityOwner ? -1 : 0).map((item) => {
                                        return {
                                            label: item.activityOwnerName,
                                            value: item.activityOwner
                                        }
                                    })} />
                            </div>
                            <div className="pl-3">
                                <p className="text-xs font-bold">Filters</p>
                                <Button color="bg-bondi-blue" text="Clear Filters" onClick={() => clearFilters()} />
                            </div>

                        </div>
                    </div>
                </div>

                <hr></hr>
                <br></br>

                <div className="flex flex-col">
                <h2 className='pl-3 w-full max-w-9xl mx-auto header-8A'>Live</h2>
                <div className="overflow-x-auto">
                        <div className="px-3 align-middle inline-block min-w-full mb-5">
                            <div className="overflow-hidden  rounded-2xl shadow-md shadow-slate-200 ">
                                <table className="objects-8A min-w-full divide-y divide-gray-200">
                                    <thead className="bg-pacific-blue-50">
                                        <tr>
                                            {/* <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Activity Id</th> */}
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Service</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Service Status</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Service Comments</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Work Stage</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Activity</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Estimated Hours</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Actual Hours</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Fee</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Cost</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Cost Category</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Complete</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Activity Complete</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Actions</th>
                                        </tr>
                                    </thead>

                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {projectActivitiesFiltered.filter(item => item.serviceStatus==="Live").map((item) => (
                                            <tr key={item.activityKey}>
                                                {/* <td className="px-4 md:px-2 py-2 whitespace-nowrap"> {item.activityId}</td> */}
                                                <td className="px-4 md:px-2 py-2"> {item.service}</td>
                                                <td className="px-4 md:px-2 py-2"> {item.serviceStatus}</td>
                                                <td className="px-4 md:px-2 py-2"> {item.serviceComments}</td>
                                                <td className="px-4 md:px-2 py-2 whitespace-nowrap"> {item.workStage}</td>
                                                <td className="px-4 md:px-2 py-2"> {item.activity}</td>
                                                {/* <td className="px-4 md:px-2 py-2 whitespace-nowrap"> {item.estimatedHours.toFixed(2)}</td> */}
                                                {item.cost === 0 && props.role.toString().slice(0,1) === "2" &&
                                                <td onClick={() => { setShowModal(true); setValueToSendToModal(item.estimatedHours.toFixed(2)); setFieldToSendToModal("estimatedHours"); setObjectIdToSendToModal(item.serviceId); setCollectionToSendToModal("projects_services"); setCollectionArrayToSendToModal("activities"); setCollectionArrayIdToSendToModal(item.activityId) }} className="px-4 md:px-2 py-2 hover:cursor-pointer whitespace-nowrap"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                </svg>{item.estimatedHours.toFixed(2)}</td>
                                                }
                                                {(item.cost !== 0 || props.role.toString().slice(0,1) !== "2") &&
                                                <td className="px-4 md:px-2 py-2 hover:cursor-pointer whitespace-nowrap">{item.estimatedHours.toFixed(2)}</td>
                                                }
                                                <td className="px-4 md:px-2 py-2 whitespace-nowrap"> {item.actualHours.toFixed(2)}</td>
                                                <td className="px-4 md:px-2 py-2 whitespace-nowrap"> {internationalNumberFormat.format(item.fee.toFixed(2))}</td>
                                                {/* <td className="px-4 md:px-2 py-2 whitespace-nowrap"> {item.cost.toFixed(2)}</td> */}
                                                {item.estimatedHours === 0 && props.role.toString().slice(0,1) === "2" &&
                                                <td onClick={() => { setShowModal(true); setValueToSendToModal(item.cost.toFixed(2)); setFieldToSendToModal("cost"); setObjectIdToSendToModal(item.serviceId); setCollectionToSendToModal("projects_services"); setCollectionArrayToSendToModal("activities"); setCollectionArrayIdToSendToModal(item.activityId) }} className="px-4 md:px-2 py-2 hover:cursor-pointer whitespace-nowrap"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                </svg>{internationalNumberFormat.format(item.cost.toFixed(2))}</td>
                                                }
                                                {(item.estimatedHours !== 0 || props.role.toString().slice(0,1) !== "2") &&
                                                <td className="px-4 md:px-2 py-2 hover:cursor-pointer whitespace-nowrap"> {internationalNumberFormat.format(item.cost.toFixed(2))}</td>
                                                }
                                                {/* <td className="px-4 md:px-2 py-2 whitespace-nowrap"> {item.costCategory}</td> */}
                                                {item.estimatedHours === 0 && props.role.toString().slice(0,1) === "2" &&
                                                <td onClick={() => { setShowModal(true); setValueToSendToModal(item.costCategory); setFieldToSendToModal("costCategory"); setObjectIdToSendToModal(item.serviceId); setCollectionToSendToModal("projects_services"); setCollectionArrayToSendToModal("activities"); setCollectionArrayIdToSendToModal(item.activityId) }} className="px-4 md:px-2 py-2 hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                </svg>{item.costCategory}</td>
                                                }
                                                {(item.estimatedHours !== 0 || props.role.toString().slice(0,1) !== "2") &&
                                                    <td className="px-4 md:px-2 py-2 hover:cursor-pointer">{item.costCategory}</td>
                                                }
                                                <td className="px-4 md:px-2 py-2 whitespace-nowrap"> <ProgressBar bgcolor={item.activityComplete === "NO" && item.complete >= 100 ? "#ff0000" : item.activityComplete === "YES" ? "#49fc03" : "#0091ca"} completed={isNaN(item.complete) || item.complete >= 100 ? 100 : Math.round(item.complete)} completedLabel={isNaN(item.complete) ? 100 : Math.round(item.complete) === Infinity ? 100 : Math.round(item.complete)}></ProgressBar></td>
                                                {/* <td className="px-4 md:px-2 py-2 whitespace-nowrap"> {item.activityComplete}</td> */}
                                                <td onClick={() => { setShowModal(true); setValueToSendToModal(item.activityComplete); setFieldToSendToModal("activityComplete"); setObjectIdToSendToModal(item.serviceId); setCollectionToSendToModal("projects_services"); setCollectionArrayToSendToModal("activities"); setCollectionArrayIdToSendToModal(item.activityId) }} className="px-4 md:px-2 py-2 hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                </svg>{item.activityComplete}</td>
                                                <td className="text-center whitespace-nowrap">
                                                    {props.role.toString().slice(0,1) === "2" && <Options edit={`/addOrUpdateClientProjectActivity/${match.params.projectId}/${item.serviceId}/${item.activityId}`} deleteFunction={deleteProjectActivity} deleteObject={{ activityId: item.activityId, serviceId: item.serviceId, activityKey: item.activityKey, actualHours: item.actualHours, activityIdInTimesheets: item.activityIdInTimesheets}}></Options>}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    <tfoot>
                                        <tr className='pt-5'>
                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                            <td className="py-2 px-4 md:px-2 whitespace-nowrap bg-slate-200 text-congress-blue font-bold text-right">Total Hours</td>
                                            <td className="py-2 px-4 md:px-2 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"> {projectActivitiesFiltered.filter(item => item.serviceStatus==="Live").map(item => Number(item.estimatedHours)).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)}</td>
                                            <td className="py-2 px-4 md:px-2 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"> {projectActivitiesFiltered.filter(item => item.serviceStatus==="Live").map(item => Number(item.actualHours)).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)}</td>
                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                        </tr>
                                    </tfoot>
                                </table>
                                <ModalUpdateValue objectToUpdate={objectIdToSendToModal} fieldToUpdate={fieldToSendToModal} valueToUpdate={valueToSendToModal} collectionToUpdate={collectionToSendToModal} arrayToUpdate={collectionArrayToSendToModal} arrayIdToUpdate={collectionArrayIdToSendToModal} arrayToShow={projectActivities} onClose={() => setShowModal(false)} show={showModal} title={"Update Activity"} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col mt-5">
                <h2 className='pl-3 w-full max-w-9xl mx-auto header-8A'>Quote</h2>
                    <div className="overflow-x-auto">
                        <div className="px-3 align-middle inline-block min-w-full mb-5">
                            <div className="overflow-hidden  rounded-2xl shadow-md shadow-slate-200 ">
                                <table className="objects-8A min-w-full divide-y divide-gray-200">
                                    <thead className="bg-pacific-blue-50">
                                        <tr>
                                            {/* <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Activity Id</th> */}
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Service</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Service Status</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Service Comments</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Work Stage</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Activity</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Estimated Hours</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Actual Hours</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Fee</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Cost</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Cost Category</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Complete</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Activity Complete</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Actions</th>
                                        </tr>
                                    </thead>

                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {projectActivitiesFiltered.filter(item => item.serviceStatus.slice(0,5)==="Quote").map((item) => (
                                            <tr key={item.activityKey}>
                                                {/* <td className="px-4 md:px-2 py-2 whitespace-nowrap"> {item.activityId}</td> */}
                                                <td className="px-4 md:px-2 py-2"> {item.service}</td>
                                                <td className="px-4 md:px-2 py-2"> {item.serviceStatus}</td>
                                                <td className="px-4 md:px-2 py-2"> {item.serviceComments}</td>
                                                <td className="px-4 md:px-2 py-2 whitespace-nowrap"> {item.workStage}</td>
                                                <td className="px-4 md:px-2 py-2"> {item.activity}</td>
                                                {/* <td className="px-4 md:px-2 py-2 whitespace-nowrap"> {item.estimatedHours.toFixed(2)}</td> */}
                                                {item.cost === 0 && props.role.toString().slice(0,1) === "2" &&
                                                <td onClick={() => { setShowModal(true); setValueToSendToModal(item.estimatedHours.toFixed(2)); setFieldToSendToModal("estimatedHours"); setObjectIdToSendToModal(item.serviceId); setCollectionToSendToModal("projects_services"); setCollectionArrayToSendToModal("activities"); setCollectionArrayIdToSendToModal(item.activityId) }} className="px-4 md:px-2 py-2 hover:cursor-pointer whitespace-nowrap"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                </svg>{item.estimatedHours.toFixed(2)}</td>
                                                }
                                                {(item.cost !== 0 || props.role.toString().slice(0,1) !== "2") &&
                                                <td className="px-4 md:px-2 py-2 hover:cursor-pointer whitespace-nowrap">{item.estimatedHours.toFixed(2)}</td>
                                                }
                                                <td className="px-4 md:px-2 py-2 whitespace-nowrap"> {item.actualHours.toFixed(2)}</td>
                                                <td className="px-4 md:px-2 py-2 whitespace-nowrap"> {internationalNumberFormat.format(item.fee.toFixed(2))}</td>
                                                {/* <td className="px-4 md:px-2 py-2 whitespace-nowrap"> {item.cost.toFixed(2)}</td> */}
                                                {item.estimatedHours === 0 && props.role.toString().slice(0,1) === "2" &&
                                                <td onClick={() => { setShowModal(true); setValueToSendToModal(item.cost.toFixed(2)); setFieldToSendToModal("cost"); setObjectIdToSendToModal(item.serviceId); setCollectionToSendToModal("projects_services"); setCollectionArrayToSendToModal("activities"); setCollectionArrayIdToSendToModal(item.activityId) }} className="px-4 md:px-2 py-2 hover:cursor-pointer whitespace-nowrap"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                </svg>{internationalNumberFormat.format(item.cost.toFixed(2))}</td>
                                                }
                                                {(item.estimatedHours !== 0 || props.role.toString().slice(0,1) !== "2") &&
                                                <td className="px-4 md:px-2 py-2 hover:cursor-pointer whitespace-nowrap"> {internationalNumberFormat.format(item.cost.toFixed(2))}</td>
                                                }
                                                {/* <td className="px-4 md:px-2 py-2 whitespace-nowrap"> {item.costCategory}</td> */}
                                                {item.estimatedHours === 0 && props.role.toString().slice(0,1) === "2" &&
                                                <td onClick={() => { setShowModal(true); setValueToSendToModal(item.costCategory); setFieldToSendToModal("costCategory"); setObjectIdToSendToModal(item.serviceId); setCollectionToSendToModal("projects_services"); setCollectionArrayToSendToModal("activities"); setCollectionArrayIdToSendToModal(item.activityId) }} className="px-4 md:px-2 py-2 hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                </svg>{item.costCategory}</td>
                                                }
                                                {(item.estimatedHours !== 0 || props.role.toString().slice(0,1) !== "2") &&
                                                    <td className="px-4 md:px-2 py-2 hover:cursor-pointer">{item.costCategory}</td>
                                                }
                                                <td className="px-4 md:px-2 py-2 whitespace-nowrap"> <ProgressBar bgcolor={item.activityComplete === "NO" && item.complete >= 100 ? "#ff0000" : item.activityComplete === "YES" ? "#49fc03" : "#0091ca"} completed={isNaN(item.complete) || item.complete >= 100 ? 100 : Math.round(item.complete)} completedLabel={isNaN(item.complete) ? 100 : Math.round(item.complete) === Infinity ? 100 : Math.round(item.complete)}></ProgressBar></td>
                                                {/* <td className="px-4 md:px-2 py-2 whitespace-nowrap"> {item.activityComplete}</td> */}
                                                <td onClick={() => { setShowModal(true); setValueToSendToModal(item.activityComplete); setFieldToSendToModal("activityComplete"); setObjectIdToSendToModal(item.serviceId); setCollectionToSendToModal("projects_services"); setCollectionArrayToSendToModal("activities"); setCollectionArrayIdToSendToModal(item.activityId) }} className="px-4 md:px-2 py-2 hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                </svg>{item.activityComplete}</td>
                                                <td className="text-center whitespace-nowrap">
                                                    <Options edit={`/addOrUpdateClientProjectActivity/${match.params.projectId}/${item.serviceId}/${item.activityId}`} deleteFunction={deleteProjectActivity} deleteObject={{ activityId: item.activityId, serviceId: item.serviceId, activityKey: item.activityKey, actualHours: item.actualHours, activityIdInTimesheets: item.activityIdInTimesheets}}></Options>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    <tfoot>
                                        <tr className='pt-5'>
                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                            <td className="py-2 px-4 md:px-2 whitespace-nowrap bg-slate-200 text-congress-blue font-bold text-right">Total Hours</td>
                                            <td className="py-2 px-4 md:px-2 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"> {projectActivitiesFiltered.filter(item => item.serviceStatus.slice(0,5)==="Quote").map(item => Number(item.estimatedHours)).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)}</td>
                                            <td className="py-2 px-4 md:px-2 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"> {projectActivitiesFiltered.filter(item => item.serviceStatus.slice(0,5)==="Quote").map(item => Number(item.actualHours)).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)}</td>
                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                        </tr>
                                    </tfoot>
                                </table>
                                <ModalUpdateValue objectToUpdate={objectIdToSendToModal} fieldToUpdate={fieldToSendToModal} valueToUpdate={valueToSendToModal} collectionToUpdate={collectionToSendToModal} arrayToUpdate={collectionArrayToSendToModal} arrayIdToUpdate={collectionArrayIdToSendToModal} arrayToShow={projectActivities} onClose={() => setShowModal(false)} show={showModal} title={"Update Activity"} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col mt-5">
                <h2 className='pl-3 w-full max-w-9xl mx-auto header-8A'>Closed/Other</h2>
                    <div className="overflow-x-auto">
                        <div className="px-3 align-middle inline-block min-w-full mb-5">
                            <div className="overflow-hidden  rounded-2xl shadow-md shadow-slate-200 ">
                                <table className="objects-8A min-w-full divide-y divide-gray-200">
                                    <thead className="bg-pacific-blue-50">
                                        <tr>
                                            {/* <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Activity Id</th> */}
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Service</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Service Status</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Service Comments</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Work Stage</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Activity</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Estimated Hours</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Actual Hours</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Fee</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Cost</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Cost Category</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Complete</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Activity Complete</th>
                                            <th scope="col" className="px-4 md:px-2  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Actions</th>
                                        </tr>
                                    </thead>

                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {projectActivitiesFiltered.filter(item => item.serviceStatus!=="Live" && item.serviceStatus.slice(0,5)!=="Quote").map((item) => (
                                            <tr key={item.activityKey}>
                                                {/* <td className="px-4 md:px-2 py-2 whitespace-nowrap"> {item.activityId}</td> */}
                                                <td className="px-4 md:px-2 py-2"> {item.service}</td>
                                                <td className="px-4 md:px-2 py-2"> {item.serviceStatus}</td>
                                                <td className="px-4 md:px-2 py-2"> {item.serviceComments}</td>
                                                <td className="px-4 md:px-2 py-2 whitespace-nowrap"> {item.workStage}</td>
                                                <td className="px-4 md:px-2 py-2"> {item.activity}</td>
                                                {/* <td className="px-4 md:px-2 py-2 whitespace-nowrap"> {item.estimatedHours.toFixed(2)}</td> */}
                                                {item.cost === 0 && props.role.toString().slice(0,1) === "2" &&
                                                <td onClick={() => { setShowModal(true); setValueToSendToModal(item.estimatedHours.toFixed(2)); setFieldToSendToModal("estimatedHours"); setObjectIdToSendToModal(item.serviceId); setCollectionToSendToModal("projects_services"); setCollectionArrayToSendToModal("activities"); setCollectionArrayIdToSendToModal(item.activityId) }} className="px-4 md:px-2 py-2 hover:cursor-pointer whitespace-nowrap"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                </svg>{item.estimatedHours.toFixed(2)}</td>
                                                }
                                                {(item.cost !== 0 || props.role.toString().slice(0,1) !== "2") &&
                                                <td className="px-4 md:px-2 py-2 hover:cursor-pointer whitespace-nowrap">{item.estimatedHours.toFixed(2)}</td>
                                                }
                                                <td className="px-4 md:px-2 py-2 whitespace-nowrap"> {item.actualHours.toFixed(2)}</td>
                                                <td className="px-4 md:px-2 py-2 whitespace-nowrap"> {internationalNumberFormat.format(item.fee.toFixed(2))}</td>
                                                {/* <td className="px-4 md:px-2 py-2 whitespace-nowrap"> {item.cost.toFixed(2)}</td> */}
                                                {item.estimatedHours === 0 && props.role.toString().slice(0,1) === "2" &&
                                                <td onClick={() => { setShowModal(true); setValueToSendToModal(item.cost.toFixed(2)); setFieldToSendToModal("cost"); setObjectIdToSendToModal(item.serviceId); setCollectionToSendToModal("projects_services"); setCollectionArrayToSendToModal("activities"); setCollectionArrayIdToSendToModal(item.activityId) }} className="px-4 md:px-2 py-2 hover:cursor-pointer whitespace-nowrap"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                </svg>{internationalNumberFormat.format(item.cost.toFixed(2))}</td>
                                                }
                                                {(item.estimatedHours !== 0 || props.role.toString().slice(0,1) !== "2") &&
                                                <td className="px-4 md:px-2 py-2 hover:cursor-pointer whitespace-nowrap"> {internationalNumberFormat.format(item.cost.toFixed(2))}</td>
                                                }
                                                {/* <td className="px-4 md:px-2 py-2 whitespace-nowrap"> {item.costCategory}</td> */}
                                                {item.estimatedHours === 0 && props.role.toString().slice(0,1) === "2" &&
                                                <td onClick={() => { setShowModal(true); setValueToSendToModal(item.costCategory); setFieldToSendToModal("costCategory"); setObjectIdToSendToModal(item.serviceId); setCollectionToSendToModal("projects_services"); setCollectionArrayToSendToModal("activities"); setCollectionArrayIdToSendToModal(item.activityId) }} className="px-4 md:px-2 py-2 hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                </svg>{item.costCategory}</td>
                                                }
                                                {(item.estimatedHours !== 0 || props.role.toString().slice(0,1) !== "2") &&
                                                    <td className="px-4 md:px-2 py-2 hover:cursor-pointer">{item.costCategory}</td>
                                                }
                                                <td className="px-4 md:px-2 py-2 whitespace-nowrap"> <ProgressBar bgcolor={item.activityComplete === "NO" && item.complete >= 100 ? "#ff0000" : item.activityComplete === "YES" ? "#49fc03" : "#0091ca"} completed={isNaN(item.complete) || item.complete >= 100 ? 100 : Math.round(item.complete)} completedLabel={isNaN(item.complete) ? 100 : Math.round(item.complete) === Infinity ? 100 : Math.round(item.complete)}></ProgressBar></td>
                                                {/* <td className="px-4 md:px-2 py-2 whitespace-nowrap"> {item.activityComplete}</td> */}
                                                <td onClick={() => { setShowModal(true); setValueToSendToModal(item.activityComplete); setFieldToSendToModal("activityComplete"); setObjectIdToSendToModal(item.serviceId); setCollectionToSendToModal("projects_services"); setCollectionArrayToSendToModal("activities"); setCollectionArrayIdToSendToModal(item.activityId) }} className="px-4 md:px-2 py-2 hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                </svg>{item.activityComplete}</td>
                                                <td className="text-center whitespace-nowrap">
                                                    {props.role.toString().slice(0,1) === "2" && <Options edit={`/addOrUpdateClientProjectActivity/${match.params.projectId}/${item.serviceId}/${item.activityId}`} deleteFunction={deleteProjectActivity} deleteObject={{ activityId: item.activityId, serviceId: item.serviceId, activityKey: item.activityKey, actualHours: item.actualHours, activityIdInTimesheets: item.activityIdInTimesheets}}></Options>}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    <tfoot>
                                        <tr className='pt-5'>
                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                            <td className="py-2 px-4 md:px-2 whitespace-nowrap bg-slate-200 text-congress-blue font-bold text-right">Total Hours</td>
                                            <td className="py-2 px-4 md:px-2 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"> {projectActivitiesFiltered.filter(item => item.serviceStatus!=="Live" && item.serviceStatus.slice(0,5)!=="Quote").map(item => Number(item.estimatedHours)).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)}</td>
                                            <td className="py-2 px-4 md:px-2 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"> {projectActivitiesFiltered.filter(item => item.serviceStatus!=="Live" && item.serviceStatus.slice(0,5)!=="Quote").map(item => Number(item.actualHours)).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)}</td>
                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                        </tr>
                                    </tfoot>
                                </table>
                                <ModalUpdateValue objectToUpdate={objectIdToSendToModal} fieldToUpdate={fieldToSendToModal} valueToUpdate={valueToSendToModal} collectionToUpdate={collectionToSendToModal} arrayToUpdate={collectionArrayToSendToModal} arrayIdToUpdate={collectionArrayIdToSendToModal} arrayToShow={projectActivities} onClose={() => setShowModal(false)} show={showModal} title={"Update Activity"} />
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>
                <div><div className="flex items-center">
                    <div className="w-full mx-auto mb-5 px-3">
                        <div className="grid gap-2 w-full sm:gap-7 sm:grid-cols-5">


                            <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                <div>
                                    <div className="text-sm text-gray-500 ">Total Live & Completed</div>
                                    <div className="flex items-center pt-1">
                                        <div className="text-xl font-medium text-indigo-500 "><p className="my-1">{props.currency} {internationalNumberFormat.format(projectActivitiesFiltered.filter(item => item.serviceStatus==="Live" || item.serviceStatus==="Closed-completed").map(item => item.fee).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2))}</p></div>
                                    </div>
                                </div>
                                <div className="text-gray-400">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 9a2 2 0 10-4 0v5a2 2 0 01-2 2h6m-6-4h4m8 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </div>
                            </div>
                            <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                <div>
                                    <div className="text-sm text-gray-500 ">Total Live</div>
                                    <div className="flex items-center pt-1">
                                        <div className="text-xl font-medium text-indigo-500 "><p className="my-1">{props.currency} {internationalNumberFormat.format(projectActivitiesFiltered.filter(item => item.serviceStatus==="Live").map(item => item.fee).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2))}</p></div>
                                    </div>
                                </div>
                                <div className="text-gray-400">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 9a2 2 0 10-4 0v5a2 2 0 01-2 2h6m-6-4h4m8 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </div>
                            </div>
                            <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                <div>
                                    <div className="text-sm text-gray-500 ">Total Closed Completed</div>
                                    <div className="flex items-center pt-1">
                                        <div className="text-xl font-medium text-indigo-500 "><p className="my-1">{props.currency} {internationalNumberFormat.format(projectActivitiesFiltered.filter(item => item.serviceStatus==="Closed-completed").map(item => item.fee).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2))}</p></div>
                                    </div>
                                </div>
                                <div className="text-gray-400">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 9a2 2 0 10-4 0v5a2 2 0 01-2 2h6m-6-4h4m8 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </div>
                            </div>
                            <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                <div>
                                    <div className="text-sm text-gray-500 ">Total Quote</div>
                                    <div className="flex items-center pt-1">
                                        <div className="text-xl font-medium text-indigo-500 "><p className="my-1">{props.currency} {internationalNumberFormat.format(projectActivitiesFiltered.filter(item => item.serviceStatus.slice(0,5)==="Quote").map(item => item.fee).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2))}</p></div>
                                    </div>
                                </div>
                                <div className="text-gray-400">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 9a2 2 0 10-4 0v5a2 2 0 01-2 2h6m-6-4h4m8 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </div>
                            </div>
                            <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                <div>
                                    <div className="text-sm text-gray-500 ">Total Lost / Cancelled</div>
                                    <div className="flex items-center pt-1">
                                        <div className="text-xl font-medium text-indigo-500 "><p className="my-1">{props.currency} {internationalNumberFormat.format(projectActivitiesFiltered.filter(item => item.serviceStatus!=="Live" && item.serviceStatus.slice(0,5)!=="Quote" && item.serviceStatus!=="Closed-completed").map(item => item.fee).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2))}</p></div>
                                    </div>
                                </div>
                                <div className="text-gray-400">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 9a2 2 0 10-4 0v5a2 2 0 01-2 2h6m-6-4h4m8 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </div>
                            </div>
                            <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                                <div>
                                    <div className="text-sm text-gray-500 ">Percentage Time Progress:</div>
                                    <div className="flex items-center pt-1">
                                        <div className="text-xl font-medium text-indigo-500 "><p className="my-1">{((projectActivitiesFiltered.map(item => item.actualHours).reduce((prev, curr) => Number(prev) + Number(curr), 0) / projectActivitiesFiltered.map(item => item.estimatedHours).reduce((prev, curr) => Number(prev) + Number(curr), 0) * 100)).toFixed(0)} %</p></div>
                                    </div>
                                </div>
                                <div className="text-gray-400">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M13 10V3L4 14h7v7l9-11h-7z" />
                                    </svg>
                                </div>
                            </div>
                            <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                <div>
                                    <div className="text-sm text-gray-500 ">Est. Hours Total</div>
                                    <div className="flex items-center pt-1">
                                        <div className="text-xl font-medium text-indigo-500 "><p className="my-1">{projectActivitiesFiltered.map(item => Number(item.estimatedHours)).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)}</p></div>
                                    </div>
                                </div>
                                <div className="text-gray-400">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 9a2 2 0 10-4 0v5a2 2 0 01-2 2h6m-6-4h4m8 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </div>
                            </div>
                            <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                <div>
                                    <div className="text-sm text-gray-500 ">Actual Hours Total</div>
                                    <div className="flex items-center pt-1">
                                        <div className="text-xl font-medium text-indigo-500 "><p className="my-1">{projectActivitiesFiltered.map(item => Number(item.actualHours)).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)}</p></div>
                                    </div>
                                </div>
                                <div className="text-gray-400">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 9a2 2 0 10-4 0v5a2 2 0 01-2 2h6m-6-4h4m8 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </div>
                            </div>
                            <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                <div>
                                    <div className="text-sm text-gray-500 ">Completed %</div>
                                    <div className="flex items-center pt-1">
                                        <div className="text-xl font-medium text-indigo-500 "><p className="my-1">{internationalNumberFormat.format((Number(projectActivitiesFiltered.filter(item => item.activityComplete==="YES").map(item => item.fee).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)) / Number(projectActivitiesFiltered.map(item => item.fee).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)))*100)}%</p></div>
                                    </div>
                                </div>
                                <div className="text-gray-400">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 9a2 2 0 10-4 0v5a2 2 0 01-2 2h6m-6-4h4m8 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>

            </div>
        )

    } else {

        return (
            <div>
                No Activities

                <br></br>
                <hr></hr>
                <br></br>

                <div className="grid sm:grid-cols-2 lg:grid-cols-8">
                    <div className="pl-3">
                        <p className="text-xs font-bold">Filters</p>
                        <Button color="bg-bondi-blue" text="Clear Filters" onClick={() => clearFilters()} />
                    </div>
                </div>
                <br></br>

                <hr></hr>

                <br></br>

            </div>)

    }

}

export default ListClientProjectActivities
