import Header from '../UI/Header'
import { useState, useEffect } from 'react'
import { useMatch } from 'react-router-dom'
import ListBuildingBREEAMAssessments from './ListBuildingBREEAMAssessments'
import ListBuildingEPCAssessments from './ListBuildingEPCAssessments'
import ListBuildingConstructionCarbonEmissions from './ListBuildingConstructionCarbonEmissions'
import ListBuildingOperationalCarbonEmissions from './ListBuildingOperationalCarbonEmissions'
import ListBuildingWELLAssessments from './ListBuildingWELLAssessments'
import ListBuildingOccupantSatisfactionSurveys from './ListBuildingOccupantSatisfactionSurveys'
import ListBuildingConstructionSiteImpactAssessments from './ListBuildingConstructionSiteImpactAssessments'
import Sidebar from '../UI/Sidebar'
import axios from 'axios'
import FileUploadAndDownload from '../FileUploadAndDownload'


const ViewBuilding = () => {

    //const instance = axios.create({});
    const match = useMatch('/viewBuilding/:buildingId')

    const [sidebarToggle, setSidebarToggle] = useState(false)

    const [loading, setLoading] = useState(true)

    const [buildingName, setBuildingName] = useState('')
    const [buildingAddress, setBuildingAddress] = useState('')
    const [buildingMarketClassification, setBuildingMarketClassification] = useState('')
    const [buildingParkName, setBuildingParkName] = useState('')
    const [buildingGreenBuildingRating, setBuildingGreenBuildingRating] = useState('')
    const [buildingGreenBuildingLevel, setBuildingGreenBuildingLevel] = useState('')
    const [buildingType, setBuildingType] = useState('')
    const [buldingTotalAreaSqMetres, setbuldingTotalAreaSqMetres] = useState('')
    const [buildingAnnualkWhElectricity, setBuildingAnnualkWhElectricity] = useState('')
    const [buildingAnnualkWhGas, setBuildingAnnualkWhGas] = useState('')
    const [buildingCountry, setBuildingCountry] = useState('')

    useEffect(() => {

        axios.get(process.env.REACT_APP_API_URL + '/buildings/' + match.params.buildingId)
            .then((res) => res.data)
            .then((res) => {
                setBuildingName(res.buildingName)
                setBuildingAddress(res.buildingAddress)
                setBuildingMarketClassification(res.buildingMarketClassification)
                setBuildingParkName(res.buildingParkName)
                setBuildingGreenBuildingRating(res.buildingGreenBuildingRating)
                setBuildingGreenBuildingLevel(res.buildingGreenBuildingLevel)
                setBuildingType(res.buildingType)
                setbuldingTotalAreaSqMetres(res.buldingTotalAreaSqMetres)
                setBuildingAnnualkWhElectricity(res.buildingAnnualkWhElectricity)
                setBuildingAnnualkWhGas(res.buildingAnnualkWhGas)
                setBuildingCountry(res.buildingCountry)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });

    }, [match.params.buildingId])

    if (!loading) {

        return (

            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden mt-1 m-5">
                        <Header title="Building - View" iconFileName="Building-Hover" section="Building Main Details" showUserMenu='true' />
                        <div><div className="flex items-center bg-slate-100">
                            <div className="container max-w-6xl px-5 mx-auto my-5">
                                <div className="grid gap-2 sm:gap-7 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">


                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                        <div>
                                            <div className="text-sm text-gray-500 ">Building</div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-indigo-500 "><p className="my-1">{buildingName}</p><p className="my-2">{buildingAddress}</p><p className="my-1">{buildingCountry}</p></div>
                                            </div>
                                        </div>
                                        <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                                        <div>
                                            <div className="text-sm text-gray-500 ">Estimated Annual kWh Electricity</div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-indigo-500 ">{buildingAnnualkWhElectricity}</div>
                                            </div>
                                        </div>
                                        <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M13 10V3L4 14h7v7l9-11h-7z" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                        <div>
                                            <div className="text-sm text-gray-500 ">Estimated Annual kWh Gas</div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-indigo-500 ">{buildingAnnualkWhGas}</div>
                                            </div>
                                        </div>
                                        <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8" fill="none" viewBox="0 0 47 46">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M41.536 30.456a19.21 19.21 0 01-5.675 7.4 19.771 19.771 0 01-8.557 3.937c-3.138.608-6.38.455-9.444-.447a19.673 19.673 0 01-8.129-4.725 19.1 19.1 0 01-4.92-7.902 18.775 18.775 0 01-.564-9.237 18.98 18.98 0 013.923-8.419 19.538 19.538 0 017.497-5.639"></path>
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M43.083 23c0-2.517-.506-5.01-1.49-7.335a19.142 19.142 0 00-4.246-6.218 19.617 19.617 0 00-6.353-4.155A19.953 19.953 0 0023.5 3.833V23h19.583z"></path>
                                            </svg>
                                        </div>

                                    </div>
                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                        <div>
                                            <div className="text-sm text-gray-500 ">Green Building Rating, Level</div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-indigo-500">{buildingGreenBuildingRating}, {buildingGreenBuildingLevel}</div>
                                            </div>
                                        </div>
                                        <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                        <div>
                                            <div className="text-sm text-gray-500 ">Total Area Square Foot</div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-indigo-500 ">{buldingTotalAreaSqMetres}</div>
                                            </div>
                                        </div>
                                        <div className="text-gray-400">
                                            <svg className="w-8 h-8" viewBox="0 0 47 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M23.5 38.3334V19.1667" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M35.25 38.3334V7.66675" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M11.75 38.3334V30.6667" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="p-4 bg-white rounded shadow-sm max-h-52 overflow-y-scroll">

                                        <div className="text-md font-small text-pacific-blue w-full h-auto"> <FileUploadAndDownload id={match.params.buildingId} showThumbnails = {true} showCamera = {true} allowDeletes={true} performFileRefChecks={true} fileUploadWindowPosition={"fixed top-0 left-0"}/></div>

                                    </div>
                                </div>
                            </div>
                        </div></div>
                        
                        <ListBuildingEPCAssessments />
                        <ListBuildingConstructionCarbonEmissions />
                        <ListBuildingOperationalCarbonEmissions />
                        <ListBuildingBREEAMAssessments />
                        <ListBuildingWELLAssessments />
                        <ListBuildingOccupantSatisfactionSurveys />
                        <ListBuildingConstructionSiteImpactAssessments />

                        <br></br>
                       
                    </div>

                </div>

            </div>
        )

    } else {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Building - View" iconFileName="Building-Hover" section="Building Main Details" showUserMenu='true' />
                        <p>Data is loading</p>
                    </div>

                </div>

            </div>
        )

    }

};

export default ViewBuilding;
