import { Watch } from  'react-loader-spinner'

const ModalLoading = (props) => {

    if (!props.show) {
        return null
    }
    return (
        <div className="loading-modal z-50">
            <div className="loading-modal-content">
                <div className="loading-modal-body px-24">
                    <Watch 
                        height="100"
                        width="100"
                        radius="48"
                        color="#00518c"
                        ariaLabel="watch-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </div>
                <div className="loading-modal-header">
                    <p className="loading-modal-title text-congress-blue text-xl font-bold">Data Processing</p>
                    <p className="loading-modal-title text-congress-blue text-xl font-bold">{props.currentRecord} of {props.totalRecords}</p>
                </div>      
            </div>
    
        </div>
    )
}

export default ModalLoading