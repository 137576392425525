import Header from '../UI/Header'
import { useState, useEffect } from 'react'
import { useMatch } from 'react-router-dom'
import Sidebar from '../UI/Sidebar'
import axios from 'axios'
import FileUploadAndDownload from '../FileUploadAndDownload'
import Button from '../UI/Button'
import Options from '../UI/Options'
import ModalUpdateValue from '../UI/ModalUpdateValue'
import { Doughnut, Bar, Line } from 'react-chartjs-2'
import {
    Chart as ChartJS,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle,
  } from "chart.js";
  
  ChartJS.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
  );


const ViewBuildingConstructionSiteImpactAssessment = () => {

    const match = useMatch('/viewBuildingConstructionSiteImpactAssessment/:buildingId/:siteLogId')

    const [sidebarToggle, setSidebarToggle] = useState(false)

    const [loading, setLoading] = useState(true)
    const [waterLogloading, setWaterLogLoading] = useState(true)
    const [electricityLogloading, setElectricityLogLoading] = useState(true)
    const [materialLogloading, setMaterialLogLoading] = useState(true)
    const [wasteLogloading, setWasteLogLoading] = useState(true)

    const [siteLog, setSiteLog] = useState({})

    const [electricityLogRecords, setElectricityLogRecords] = useState([])
    const [waterLogRecords, setWaterLogRecords] = useState([])
    const [materialLogRecords, setMaterialLogRecords] = useState([])
    const [wasteLogRecords, setWasteLogRecords] = useState([])

    const [showModal, setShowModal] = useState(false)
    const [valueToSendToModal, setValueToSendToModal] = useState('')
    const [objectIdToSendToModal, setObjectIdToSendToModal] = useState('')
    const [fieldToSendToModal, setFieldToSendToModal] = useState('')
    const [collectionToUpdate, setCollectionToUpdate] = useState('')

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            time = d.toString().substring(16,21);

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-') + " " + time;
    }

    const addElectricityReading = async () => {

        var currentDate

        const newDate = new Date()

        currentDate = formatDate(newDate)

        await axios.post(
            process.env.REACT_APP_API_URL + "/site_logs_electricity",
            {readingDate: currentDate, readingValue: 0, siteLogId: match.params.siteLogId, sinceLastReading: 0}
        ).then((response) => {
            setElectricityLogRecords([...electricityLogRecords, {id: response.data.Inserted_id, readingDate: currentDate, readingValue: 0, siteLogId: match.params.siteLogId, sinceLastReading: 0}].sort((item1, item2) => item1.readingDate > item2.readingDate ? -1 : 0))
            setFieldToSendToModal("siteElectricityReadingValue")
            setObjectIdToSendToModal(response.data.Inserted_id)
            setValueToSendToModal(0)
            setCollectionToUpdate("site_logs_electricity")      
        })
        .catch((err) => {
            console.log(err);
        });

        setShowModal(true)

    }

    useEffect(() => {

        axios.get(process.env.REACT_APP_API_URL + '/site_log/' + match.params.siteLogId)
            .then((res) => res.data)
            .then((res) => {
                setSiteLog(res)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });

        axios.get(process.env.REACT_APP_API_URL + '/site_log_electricity_by_log_id/' + match.params.siteLogId)
            .then((res) => res.data)
            .then((res) => {
                setElectricityLogRecords(res.sort((item1, item2) => item1.readingDate > item2.readingDate ? -1 : 0))    
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setElectricityLogLoading(false);
            });

        axios.get(process.env.REACT_APP_API_URL + '/site_log_transport_material_by_log_id/' + match.params.siteLogId)
            .then((res) => res.data)
            .then((res) => {
                setMaterialLogRecords(res)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setMaterialLogLoading(false);
            });

        axios.get(process.env.REACT_APP_API_URL + '/site_log_transport_waste_by_log_id/' + match.params.siteLogId)
            .then((res) => res.data)
            .then((res) => {
                setWasteLogRecords(res)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setWasteLogLoading(false);
            });

        axios.get(process.env.REACT_APP_API_URL + '/site_log_water_by_log_id/' + match.params.siteLogId)
            .then((res) => res.data)
            .then((res) => {
                setWaterLogRecords(res.sort((item1, item2) => item1.readingDate < item2.readingDate ? -1 : 0))
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setWaterLogLoading(false);
            });

    }, [match.params.siteLogId])

    if (!loading && !waterLogloading && !wasteLogloading && !electricityLogloading && !materialLogloading) {

        return (

            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden mt-1 m-5">
                        <Header title="Construction Site Log - View" iconFileName="Building-Hover" section="Construction Site Log Main Details" showUserMenu='true' />
                            <div className="flex items-center bg-slate-100">
                                <div className="container px-5 mx-auto my-5">
                                <div className="grid gap-2 sm:gap-7 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">


                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                        <div>
                                            <div className="text-sm text-gray-500 ">Building</div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-indigo-500 ">{siteLog.buildingId}</div>
                                            </div>
                                        </div>
                                        <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                                            </svg>
                                        </div>
                                    </div>

                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                                        <div>
                                            <div className="text-sm text-gray-500 ">Total kWh Electricity</div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-indigo-500 ">{electricityLogRecords.length > 0 ? electricityLogRecords[0].readingValue : 0}</div>
                                            </div>
                                        </div>
                                        <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M13 10V3L4 14h7v7l9-11h-7z" />
                                            </svg>
                                        </div>

                                    </div>

                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                                    <div>
                                        <div className="text-sm text-gray-500 ">Total tCO2e from Electricity</div>
                                        <div className="flex items-center pt-1">
                                            <div className="text-xl font-medium text-indigo-500 ">{electricityLogRecords.length > 0 ? electricityLogRecords[0].readingValue : 0}</div>
                                        </div>
                                    </div>
                                    <div className="text-gray-400">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M13 10V3L4 14h7v7l9-11h-7z" />
                                        </svg>
                                    </div>
                                    </div>

                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                        <div>
                                            <div className="text-sm text-gray-500 ">Total m3 Water</div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-indigo-500 ">{waterLogRecords.length > 0 ? waterLogRecords[0].readingValue : 0}</div>
                                            </div>
                                        </div>
                                        <div className="text-gray-400">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="0.5">
                                        <path fillRule="evenodd" d="M7.21.8C7.69.295 8 0 8 0q.164.544.371 1.038c.812 1.946 2.073 3.35 3.197 4.6C12.878 7.096 14 8.345 14 10a6 6 0 0 1-12 0C2 6.668 5.58 2.517 7.21.8m.413 1.021A31 31 0 0 0 5.794 3.99c-.726.95-1.436 2.008-1.96 3.07C3.304 8.133 3 9.138 3 10a5 5 0 0 0 10 0c0-1.201-.796-2.157-2.181-3.7l-.03-.032C9.75 5.11 8.5 3.72 7.623 1.82z"/>
                                        <path fillRule="evenodd" d="M4.553 7.776c.82-1.641 1.717-2.753 2.093-3.13l.708.708c-.29.29-1.128 1.311-1.907 2.87z"/>
                                        </svg>
                                        </div>

                                    </div>
                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                        <div>
                                            <div className="text-sm text-gray-500 ">Total km transportation of materials to site</div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-indigo-500 ">{materialLogRecords.map(item => item.emissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)}</div>
                                            </div>
                                        </div>
                                        <div className="text-gray-400 flex items-center justify-between">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 18.75a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 0 1-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 0 0-3.213-9.193 2.056 2.056 0 0 0-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 0 0-10.026 0 1.106 1.106 0 0 0-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12" />
                                            </svg>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-8 w-8">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                                            </svg>

                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-8 w-8">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                        <div>
                                            <div className="text-sm text-gray-500 ">Total km transportation of waste from site</div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-indigo-500 ">{wasteLogRecords.map(item => item.emissions).reduce((prev, curr) => Number(prev) + Number(curr), 0)}</div>
                                            </div>
                                        </div>
                                        <div className="text-gray-400 flex items-center justify-between">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-8 w-8">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z" />
                                            </svg>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-8 w-8">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                                            </svg>

                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 float-right" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 18.75a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 0 1-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 0 0-3.213-9.193 2.056 2.056 0 0 0-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 0 0-10.026 0 1.106 1.106 0 0 0-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12" />
                                            </svg>

                                        </div>
                                    </div>
                                    {/* <div className="p-4 bg-white rounded shadow-sm max-h-52 overflow-y-scroll">

                                        <div className="text-md font-small text-pacific-blue w-full h-auto"> <FileUploadAndDownload id={match.params.buildingId} showThumbnails = {true} showCamera = {true} allowDeletes={true} performFileRefChecks={true} /></div>

                                    </div> */}
                                </div>
                            </div>

                                               
                    </div>
                    <h1 className='pt-4 sm:px-6 lg:px-8 pb-2 header-8A'>Electricity Consumption</h1>
                    <div className="flex">
                                            <div className="basis-full shadow-md shadow-slate-300 rounded-2xl m-3 p-3 bg-white">
                                                <Bar data={{labels: ["One", "Two", "Three"], datasets: [
                                                {
                                                    data: [100,200,300],
                                                    // backgroundColor: chartColours,
                                                    // hoverBackgroundColor: chartHoverColours,
                                                }
                                                ]}} height={70} />

                                            </div>
                                        </div>
                    <div className="flex flex-col">
                            <table className="objects-8A min-w-full divide-y divide-gray-200">
                                <thead className="bg-pacific-blue-50">
                                    <tr>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Date</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> kWh reading</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> kgCO2e</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> kWh usage since previous reading</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Cumulative kgCO2e</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider md:pr-10 lg:pr-20 float-right "> Actions</th>
                                    </tr>
                                </thead>


                                <tbody className="bg-white divide-y divide-gray-200">
                                {electricityLogRecords.map((electricityLogRecord) => (
                                    <tr key={electricityLogRecord.id}>
                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer">{electricityLogRecord.readingDate}</td>
                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer">{electricityLogRecord.readingValue}</td>
                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer">{electricityLogRecord.readingValue}</td>
                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer">{electricityLogRecord.readingValue}</td>
                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer">{electricityLogRecord.readingValue}</td>

                                        <td className="px-4 md:px-6 py-2 whitespace-nowrap md:pr-10 lg:pr-20 float-right ">
                                            <Options deleteObject={electricityLogRecord.id}></Options>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>

                            </table>
                        </div>
                        <br></br>
                        <Button className='bg-bondi-blue' text="Add New Electricity Reading" onClick={() => addElectricityReading()}/>

                        <h1 className='pt-4 sm:px-6 lg:px-8 pb-2 header-8A'>Water Consumption</h1>
                        <div className="flex">
                                            <div className="basis-full shadow-md shadow-slate-300 rounded-2xl m-3 p-3 bg-white">
                                                <Bar data={{labels: ["One", "Two", "Three"], datasets: [
                                                {
                                                    data: [100,200,300],
                                                    // backgroundColor: chartColours,
                                                    // hoverBackgroundColor: chartHoverColours,
                                                }
                                                ]}} height={70} />

                                            </div>
                                        </div>
                    <div className="flex flex-col">
                            <table className="objects-8A min-w-full divide-y divide-gray-200">
                                <thead className="bg-pacific-blue-50">
                                    <tr>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Date</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> m3 reading</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> m3 usage since previous reading</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider md:pr-10 lg:pr-20 float-right "> Actions</th>
                                    </tr>
                                </thead>


                                <tbody className="bg-white divide-y divide-gray-200">
                                {waterLogRecords.map((waterLogRecord) => (
                                    <tr key={waterLogRecord.id}>
                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer">{waterLogRecord.readingDate}</td>
                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer">{waterLogRecord.readingValue}</td>
                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer">{waterLogRecord.readingValue}</td>

                                        <td className="px-4 md:px-6 py-2 whitespace-nowrap md:pr-10 lg:pr-20 float-right ">
                                            <Options deleteObject={waterLogRecord.id}></Options>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>

                            </table>
                        </div>
                        <br></br>
                        <Button className='bg-bondi-blue' text="Add New Water Reading"/>

                        <h1 className='pt-4 sm:px-6 lg:px-8 pb-2 header-8A'>Transportation of Materials into Site</h1>
                        <div className="flex">
                                            <div className="basis-full shadow-md shadow-slate-300 rounded-2xl m-3 p-3 bg-white">
                                                <Bar data={{labels: ["One", "Two", "Three"], datasets: [
                                                {
                                                    data: [100,200,300],
                                                    // backgroundColor: chartColours,
                                                    // hoverBackgroundColor: chartHoverColours,
                                                }
                                                ]}} height={70} />

                                            </div>
                                        </div>
                    <div className="flex flex-col">
                            <table className="objects-8A min-w-full divide-y divide-gray-200">
                                <thead className="bg-pacific-blue-50">
                                    <tr>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Date</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Material</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> From Postcode</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> To Postcode</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Unit</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Consumption</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Vehicle Type</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Fuel Type</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Emissions</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider md:pr-10 lg:pr-20 float-right "> Actions</th>
                                    </tr>
                                </thead>


                                <tbody className="bg-white divide-y divide-gray-200">
                                {materialLogRecords.map((materialLogRecord) => (
                                    <tr key={materialLogRecord.id}>
                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer">{materialLogRecord.recordDate}</td>
                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer">{materialLogRecord.material}</td>
                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer">{materialLogRecord.fromPostCode}</td>
                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer">{materialLogRecord.toPostCode}</td>
                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer">{materialLogRecord.unit}</td>
                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer">{materialLogRecord.consumption}</td>
                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer">{materialLogRecord.vehicleType}</td>
                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer">{materialLogRecord.fuelType}</td>
                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer">{materialLogRecord.emissions}</td>

                                        <td className="px-4 md:px-6 py-2 whitespace-nowrap md:pr-10 lg:pr-20 float-right ">
                                            <Options deleteObject={materialLogRecord.id}></Options>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>

                            </table>
                        </div>
                        <br></br>
                        <Button className='bg-bondi-blue' text="Add New Materials Transport Record"/>

                        <h1 className='pt-4 sm:px-6 lg:px-8 pb-2 header-8A'>Transportation of Waste from Site</h1>
                        <div className="flex">
                                            <div className="basis-full shadow-md shadow-slate-300 rounded-2xl m-3 p-3 bg-white">
                                                <Bar data={{labels: ["One", "Two", "Three"], datasets: [
                                                {
                                                    data: [100,200,300],
                                                    // backgroundColor: chartColours,
                                                    // hoverBackgroundColor: chartHoverColours,
                                                }
                                                ]}} height={70} />

                                            </div>
                                        </div>
                    <div className="flex flex-col">
                    <table className="objects-8A min-w-full divide-y divide-gray-200">
                                <thead className="bg-pacific-blue-50">
                                    <tr>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Date</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Material</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> From Postcode</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> To Postcode</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Unit</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Consumption</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Vehicle Type</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Fuel Type</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Emissions</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider md:pr-10 lg:pr-20 float-right "> Actions</th>
                                    </tr>
                                </thead>


                                <tbody className="bg-white divide-y divide-gray-200">
                                {wasteLogRecords.map((wasteLogRecord) => (
                                    <tr key={wasteLogRecord.id}>
                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer">{wasteLogRecord.recordDate}</td>
                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer">{wasteLogRecord.material}</td>
                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer">{wasteLogRecord.fromPostCode}</td>
                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer">{wasteLogRecord.toPostCode}</td>
                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer">{wasteLogRecord.unit}</td>
                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer">{wasteLogRecord.consumption}</td>
                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer">{wasteLogRecord.vehicleType}</td>
                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer">{wasteLogRecord.fuelType}</td>
                                        <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer">{wasteLogRecord.emissions}</td>

                                        <td className="px-4 md:px-6 py-2 whitespace-nowrap md:pr-10 lg:pr-20 float-right ">
                                            <Options deleteObject={wasteLogRecord.id}></Options>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>

                            </table>
                        </div>
                        <br></br>
                        <Button className='bg-bondi-blue' text="Add New Waste Transport Record"/>

                </div>

            </div>
            <ModalUpdateValue objectToUpdate={objectIdToSendToModal} fieldToUpdate={fieldToSendToModal} valueToUpdate={valueToSendToModal} collectionToUpdate={collectionToUpdate} arrayToShow={fieldToSendToModal === "siteElectricityReadingValue" ? electricityLogRecords : waterLogRecords} onClose={() => setShowModal(false)} show={showModal} title="Update Site Log" />
            </div>

        )

    } else {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Building - View" iconFileName="Building-Hover" section="Building Main Details" showUserMenu='true' />
                        <p>Data is loading</p>
                    </div>

                </div>

            </div>
        )

    }

};

export default ViewBuildingConstructionSiteImpactAssessment;
