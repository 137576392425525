import Header from '../UI/Header'
import { useState, useEffect } from 'react'
import { useMatch, useNavigate } from 'react-router-dom'
import ListBuildingOperationalCarbonEmissionsAssessmentItems from './ListBuildingOperationalCarbonEmissionsAssessmentItems'
import Sidebar from '../UI/Sidebar'
import FileUploadAndDownload from '../FileUploadAndDownload'
import axios from 'axios'

const ViewBuildingOperationalCarbonEmissionsAssessment = (props) => {

    const match = useMatch('/viewBuildingOperationalCarbonEmissionsAssessment/:buildingId/:buildingOperationalCarbonEmissionsAssessmentId')

    const [sidebarToggle, setSidebarToggle] = useState(false)

    const [buildingOperationalCarbonEmissionsAssessment, setBuildingOperationalCarbonEmissionsAssessment] = useState({})

    const [loading, setLoading] = useState(true)

    const [clientId, setClientId] = useState('')

    const navigate = useNavigate()

    const [company, setCompany] = useState("")
    const [role, setRole] = useState(0)
    const [userPropertiesLoading, setUserPropertiesLoading] = useState(true)

    const getUserProperties = async () => {

        await axios.get(process.env.REACT_APP_API_URL + "/current_user_properties")
            .then((response) => {
                setCompany(response.data.company)
                setRole(response.data.role)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUserPropertiesLoading(false);
            });

    }

    useEffect(() => {

        setUserPropertiesLoading(true)

        getUserProperties()

    }, [])

    useEffect(() => {
        
        //Need to check company if 3001 or 6001 !!!!

        if (company && role && !userPropertiesLoading) {

        axios.get(process.env.REACT_APP_API_URL + '/buildings/' + match.params.buildingId)
            .then((res) => {
                setClientId(res.data.clientId)
                if ((role === 3001 || role === 6001) && res.data.clientId !== company) {
                    navigate(`/errorPage`)
                }
            })
            .catch((err) => {
                console.log(err);
            })

        axios.get(process.env.REACT_APP_API_URL + '/buildings_operational_carbon_emissions/' + match.params.buildingOperationalCarbonEmissionsAssessmentId)
            .then((res) => res.data)
            .then((res) => {
                setBuildingOperationalCarbonEmissionsAssessment(res)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });

        }

    }, [match.params.buildingOperationalCarbonEmissionsAssessmentId, company, role, userPropertiesLoading])

    if (!loading) {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Building Operational Carbon Emissions Assessment" section="Building Operational Carbon Emissions Assessment Assessment Main Details" showUserMenu='true' iconFileName="Building-Hover"/>

                        <div><div className="flex items-center bg-slate-100">
                            <div className="container max-w-6xl px-5 mx-auto my-5">
                                <div className="grid gap-2 sm:gap-7 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">

                                    <div className="flex items-center justify-between p-3 md:p-5  bg-white rounded-2xl shadow-md shadow-slate-300">
                                        <div>
                                            <div className="text-sm text-gray-500 ">Address</div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-pacific-blue"><p className="my-1">{buildingOperationalCarbonEmissionsAssessment.address}</p></div>
                                            </div>
                                        </div>
                                        <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                                            </svg>
                                        </div>
                                    </div>

                                    {/* <div className="flex items-center justify-between  p-3 md:p-5  bg-white rounded shadow-sm">
                                        <div>
                                            <div className="text-sm text-gray-500 ">LCA Year</div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-pacific-blue">{LCAYear}</div>
                                            </div>
                                        </div>
                                        <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                            </svg>
                                        </div>
                                    </div>

                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded shadow-sm">
                                        <div>
                                            <div className="text-sm text-gray-500 ">LCA Date</div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-pacific-blue">{LCADate}</div>
                                            </div>
                                        </div>
                                        <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                            </svg>
                                        </div>
                                    </div>

                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                        <div>
                                            <div className="text-sm text-gray-500 ">Supporting Documents: </div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-md font-small text-pacific-blue"> <FileUploadAndDownload id={match.params.buildingLCAId} /></div>
                                            </div>
                                        </div>
                                        <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M5 19a2 2 0 01-2-2V7a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1M5 19h14a2 2 0 002-2v-5a2 2 0 00-2-2H9a2 2 0 00-2 2v5a2 2 0 01-2 2z" />
                                            </svg>
                                        </div>
                                    </div>

                                    <div className="flex items-center justify-between col-span-1 sm:col-span-2 p-3 md:p-5 bg-white rounded shadow-sm">
                                        <div>
                                            <div className="text-sm text-gray-500 ">Notes:</div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-pacific-blue">Lorem Ipsum</div>
                                            </div>
                                        </div>
                                        <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                            </svg>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div></div><br></br>

                        <ListBuildingOperationalCarbonEmissionsAssessmentItems />
                    </div>

                </div>

            </div>
        )

    } else {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Building Operational Carbon Emissions Assessment" section="Building Operational Carbon Emissions Assessment Assessment Main Details" showUserMenu='true' iconFileName="Building-Hover"/>
                        <p>Data is loading</p>
                    </div>

                </div>

            </div>
        )

    }

};

export default ViewBuildingOperationalCarbonEmissionsAssessment;
