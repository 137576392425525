import { useState, useEffect } from 'react'
import axios from 'axios'
import Header from '../UI/Header'
import Button from '../UI/Button'
import Modal from '../UI/Modal'
import { useNavigate, useMatch } from 'react-router-dom'
import FileUploadAndDownload from '../FileUploadAndDownload'
import { v4 as uuidv4 } from 'uuid'
import AuthService from '../../services/AuthService'
import Options from '../UI/Options'
import ModalUpdateValue from '../UI/ModalUpdateValue'


const AddOrUpdateBuildingBREEAMAssessmentCredit = () => {

    const match = useMatch('/AddOrUpdateBuildingBREEAMAssessmentCredit/:buildingId/:assessmentId/:BREEAMCreditId')

    const navigate = useNavigate()

    const [showModal, setShowModal] = useState(false)

    const [usersLoading, setUsersLoading] = useState(false)
    const [loading, setLoading] = useState(false)

    const [carbonEmissionFactors, setCarbonEmissionFactors] = useState([])

    const [users, setUsers] = useState([])

    const [buildingId, setBuildingId] = useState('')
    const [buildingBREEAMAssessmentCredits, setBuildingBREEAMAssessmentCredits] = useState([])
    const [BREEAMAssessment, setBREEAMAssessment] = useState({})

    const [id, setBREEAMCreditId] = useState()
    const [section, setSection] = useState('')
    const [creditCategoryCode, setCreditCategoryCode] = useState('')
    const [creditCategoryDescription, setCreditCategoryDescription] = useState('')
    const [creditDescription, setCreditDescription] = useState('')
    const [credits, setCredits] = useState('')
    const [creditsMinimumStandards, setCreditsMinimumStandards] = useState('')
    const [creditsTargeted, setCreditsTargeted] = useState('')
    const [creditsPotential, setCreditsPotential] = useState('')
    const [creditsOverview, setCreditsOverview] = useState('')
    const [creditNotes, setCreditNotes] = useState('')
    const [creditAchieved, setCreditAchieved] = useState('')
    const [files, setFiles] = useState([])
    const [documentaryEvidence, setDocumentaryEvidence] = useState([])

    const [company, setCompany] = useState("")
    const [role, setRole] = useState(0)
    const [userPropertiesLoading, setUserPropertiesLoading] = useState(true)

    const [descriptionToAdd, setDescriptionToAdd] = useState('')
    const [filenameToAdd, setFilenameToAdd] = useState('')
    const [responsibilityToAdd, setResponsibilityDateToAdd] = useState('')
    const [timeCriticalToAdd, setTimeCriticalToAdd] = useState('')
    const [complianceLetterToAdd, setComplianceLetterToAdd] = useState('')

    const [showAddDocumentsToggle, setShowAddDocumentsToggle] = useState(false)

    const [valueToSendToModal, setValueToSendToModal] = useState('')
    const [objectIdToSendToModal, setObjectIdToSendToModal] = useState('')
    const [fieldToSendToModal, setFieldToSendToModal] = useState('')
    const [collectionToSendToModal, setCollectionToSendToModal] = useState('')

    const getUserProperties = async () => {

        await axios.get(process.env.REACT_APP_API_URL + "/current_user_properties")
            .then((response) => {
                setCompany(response.data.company)
                setRole(response.data.role)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUserPropertiesLoading(false);
            });

    }

    const onSubmit = (e) => {
        e.preventDefault()

        var newBREEAMAssessment = {...BREEAMAssessment}

        if (match.params.BREEAMCreditId === "_add") {
            newBREEAMAssessment.credits.push({id: uuidv4(), section, creditCategoryCode, creditCategoryDescription, creditDescription, credits, creditsMinimumStandards, creditsTargeted, creditsPotential, creditsOverview, creditAchieved, creditNotes, files, documentaryEvidence })
        } else {
            newBREEAMAssessment.credits.find(item => item.id === match.params.BREEAMCreditId).section = section
            newBREEAMAssessment.credits.find(item => item.id === match.params.BREEAMCreditId).creditCategoryCode = creditCategoryCode
            newBREEAMAssessment.credits.find(item => item.id === match.params.BREEAMCreditId).creditCategoryDescription = creditCategoryDescription
            newBREEAMAssessment.credits.find(item => item.id === match.params.BREEAMCreditId).creditDescription = creditDescription
            newBREEAMAssessment.credits.find(item => item.id === match.params.BREEAMCreditId).credits = credits
            newBREEAMAssessment.credits.find(item => item.id === match.params.BREEAMCreditId).creditsMinimumStandards = creditsMinimumStandards
            newBREEAMAssessment.credits.find(item => item.id === match.params.BREEAMCreditId).creditsTargeted = creditsTargeted
            newBREEAMAssessment.credits.find(item => item.id === match.params.BREEAMCreditId).creditsPotential = creditsPotential
            newBREEAMAssessment.credits.find(item => item.id === match.params.BREEAMCreditId).creditsOverview = creditsOverview
            newBREEAMAssessment.credits.find(item => item.id === match.params.BREEAMCreditId).creditNotes = creditNotes
            newBREEAMAssessment.credits.find(item => item.id === match.params.BREEAMCreditId).creditAchieved = creditAchieved
            newBREEAMAssessment.credits.find(item => item.id === match.params.BREEAMCreditId).files = files
            newBREEAMAssessment.credits.find(item => item.id === match.params.BREEAMCreditId).documentaryEvidence = documentaryEvidence

        }

        updateForm(newBREEAMAssessment).then(res => { navigate(-1) })

    }

    const updateForm = async (form) => {
        await axios.put(process.env.REACT_APP_API_URL + '/buildings_BREEAM_assessments/' + match.params.assessmentId, form)
    }

    const deleteDocumentaryEvidence = (e) => {
       
        if (window.confirm('Are you sure you want to delete this documentary evidence requirement?')) {
    
            //code here to get array index of scope item id=id
          
              const index = documentaryEvidence.findIndex(teamMember => teamMember.id === id)
  
              documentaryEvidence.splice(index, 1);
            
              BREEAMAssessment.credits.find(item => item.id === match.params.BREEAMCreditId).documentaryEvidence = documentaryEvidence
              
              setDocumentaryEvidence(documentaryEvidence.filter((teamMember) => teamMember.id !== id))
  
          }

    }

    const addDocumentaryEvidence = (e) => {
       
        setDocumentaryEvidence([ ...documentaryEvidence, {id: uuidv4(), description: descriptionToAdd, filename: filenameToAdd, responsibility: responsibilityToAdd, timeCritical: timeCriticalToAdd, complianceLetter: complianceLetterToAdd, evidenceDocumentRequired: "YES", requiredAtStage: "Stage 1"} ] )
    
    }

    useEffect(() => {

        setUserPropertiesLoading(true)

        getUserProperties()

    }, [])

    useEffect(() => {

            setLoading(true)

            axios.get(process.env.REACT_APP_API_URL + '/buildings_BREEAM_assessments/' + match.params.assessmentId)
                .then((res) => {

                    setBREEAMAssessment(res.data)

                    if (match.params.BREEAMCreditId !== "_add") {
                        setBREEAMCreditId(res.data.credits.find(credit => credit.id === match.params.BREEAMCreditId).id)
                        setSection(res.data.credits.find(credit => credit.id === match.params.BREEAMCreditId).section)
                        setCreditCategoryCode(res.data.credits.find(credit => credit.id === match.params.BREEAMCreditId).creditCategoryCode)
                        setCreditCategoryDescription(res.data.credits.find(credit => credit.id === match.params.BREEAMCreditId).creditCategoryDescription)
                        setCreditDescription(res.data.credits.find(credit => credit.id === match.params.BREEAMCreditId).creditDescription)
                        setCredits(res.data.credits.find(credit => credit.id === match.params.BREEAMCreditId).credits)
                        setCreditsMinimumStandards(res.data.credits.find(credit => credit.id === match.params.BREEAMCreditId).creditsMinimumStandards)
                        setCreditsTargeted(res.data.credits.find(credit => credit.id === match.params.BREEAMCreditId).creditsTargeted)
                        setCreditsPotential(res.data.credits.find(credit => credit.id === match.params.BREEAMCreditId).creditsPotential)
                        setCreditsOverview(res.data.credits.find(credit => credit.id === match.params.BREEAMCreditId).creditsOverview)
                        setCreditNotes(res.data.credits.find(credit => credit.id === match.params.BREEAMCreditId).creditNotes)
                        setCreditAchieved(res.data.credits.find(credit => credit.id === match.params.BREEAMCreditId).creditAchieved)
                        setFiles(res.data.credits.find(credit => credit.id === match.params.BREEAMCreditId).files)
                        setDocumentaryEvidence(res.data.credits.find(credit => credit.id === match.params.BREEAMCreditId).documentaryEvidence)
                    }
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });

    }, [match.params.assessmentId, match.params.buildingId, match.params.BREEAMCreditId])
    

    if (!userPropertiesLoading && role && company && !loading && !usersLoading) {

        return (

            <div className='flex items-center bg-jungle-mist-100 min-h-screen'>
                <div className="container-form-8A">

                    <Header title="Buildings - Add / Edit BREEAM Credit" section="BREEAM Credit Details" iconFileName="Building-Hover" url={role === 3001 || role === 6001 ? "/ViewClient/" + company : "/"}/>

                    <br></br>

                    {match.params.BREEAMCreditId !== "_add" &&
                    <div className="relative flex flex-col flex-1 p-4 bg-white rounded shadow-sm max-h-auto">

                        <div className="text-md font-small text-pacific-blue w-full h-auto"> <FileUploadAndDownload id={match.params.buildingId} objectToLink = {{assessmentId: match.params.assessmentId, assessmentItemId: match.params.BREEAMCreditId }} filesToShowArray = {files} showThumbnails = {true} showCamera = {true}/></div>

                    </div>
                    }
   
                    <form className="add-form-8A" onSubmit={onSubmit}>

                        <div className="form-control-8A">
                            <label>Section</label>
                            <input type="text" placeholder="Enter Footprint Item Description Here" value={section} onChange={(e) => setSection(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Credit Category Code</label>
                            <input type="text" placeholder="Enter Consultant Comment Here" value={creditCategoryCode} onChange={(e) => setCreditCategoryCode(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Credit Category Description</label>
                            <input type="text" placeholder="Enter Consultant Comment Here" value={creditCategoryDescription} onChange={(e) => setCreditCategoryDescription(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Credit Description</label>
                            <input type="text" placeholder="Enter Consultant Comment Here" value={creditDescription} onChange={(e) => setCreditDescription(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Credits</label>
                            <input type="text" placeholder="Enter Consultant Comment Here" value={credits} onChange={(e) => setCredits(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Minimum Standards</label>
                            <input type="text" placeholder="Enter Consultant Comment Here" value={creditsMinimumStandards} onChange={(e) => setCreditsMinimumStandards(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Targeted</label>
                            <input type="text" placeholder="Enter Consultant Comment Here" value={creditsTargeted} onChange={(e) => setCreditsTargeted(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Potential</label>
                            <input type="text" placeholder="Enter Consultant Comment Here" value={creditsPotential} onChange={(e) => setCreditsPotential(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Credit Overview</label>
                            <input type="text" placeholder="Enter Credit Overview Here" value={creditsOverview} onChange={(e) => setCreditsOverview(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Credit Notes</label>
                            <textarea className="w-full border-slate-300 border-2 min-h-[150px]" type="text" placeholder="Enter Credit Notes Here" value={creditNotes} onChange={(e) => setCreditNotes(e.target.value)} />
                        </div>

                        {/* <div className="form-control-8A">
                            <label>Credit Achieved</label>
                            <textarea className="w-full border-slate-300 border-2 min-h-[150px]" type="text" placeholder="Enter Credit Notes Here" value={creditAchieved} onChange={(e) => setCreditAchieved(e.target.value)} />
                        </div> */}

                        {!showAddDocumentsToggle &&
                        <Button className='bg-bondi-blue' text="Click here to add new documentary evidence requirement" onClick={() => setShowAddDocumentsToggle(true)}/>
                        }

                        {showAddDocumentsToggle && (
                            <div>
                                <div className="grid grid-cols-3 gap-3">
                                    <div className="form-control-8A">
                                        <label className="text-center">Description</label>
                                        <input className="bg-slate-100"  type="text" placeholder="Enter Value Here"  value={descriptionToAdd} onChange={(e) => setDescriptionToAdd(e.target.value)} />
                                    </div>
                                    <div className="form-control-8A">
                                        <label className="text-center">Responsibility</label>
                                        <input className="bg-slate-100"  type="text" placeholder="Enter Value Here"  value={responsibilityToAdd} onChange={(e) => setResponsibilityDateToAdd(e.target.value)} />
                                    </div>
                                    <div className="form-control-8A">
                                        <label className="text-center">Time Critical</label>
                                        <input className="bg-slate-100"  type="text" placeholder="Enter Value Here"  value={timeCriticalToAdd} onChange={(e) => setTimeCriticalToAdd(e.target.value)} />
                                    </div>
                                    <div className="form-control-8A">
                                        <label className="text-center">Compliance Letter</label>
                                        <input className="bg-slate-100"  type="text" placeholder="Enter Value Here"  value={complianceLetterToAdd} onChange={(e) => setComplianceLetterToAdd(e.target.value)} />
                                    </div>
                                </div>

                                <div className="h3">
                                    <Button color="bg-bondi-blue" text="Add Documentary Evidence Requirement >>" onClick={() => addDocumentaryEvidence()} />
                                </div>
                            </div>
                        )}

                        <div className="shadow-md shadow-slate-300 overflow-hidden rounded-2xl m-4">
                            <table className="objects-8A min-w-full divide-y divide-gray-200">

                                <thead className="bg-pacific-blue-50">
                                    <tr>
                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Description</th>
                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> File Name(s)</th>
                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Responsibility</th>
                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Stage</th>
                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Time Critical</th>
                                        {/* <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Compliance Letter</th> */}
                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Actions</th>
                                    </tr>
                                </thead>

                                <tbody className="bg-white divide-y divide-gray-200">
                                    {documentaryEvidence.filter(item => item.evidenceDocumentRequired === "YES").map((evidenceDocument) => (
                                        <tr key={evidenceDocument.id}>
                                            <td className="px-4 md:px-6  py-2 hover:cursor-pointer"> {evidenceDocument.description} </td>
                                            {/* <td className="px-4 md:px-6  py-2 hover:cursor-pointer whitespace-nowrap"> <FileUploadAndDownload id={match.params.buildingId} filesToShowArray = {files.filter(item => item.filename === evidenceDocument.filename)} allowUploads ={files.filter(item => item.filename === evidenceDocument.filename).length > 0 ? false : true} relatedToRecordId = {evidenceDocument.id} objectToLink = {{assessmentId: match.params.assessmentId, assessmentItemId: match.params.BREEAMCreditId }}/></td> */}
                                            <td className="px-4 md:px-6  py-2 hover:cursor-pointer whitespace-nowrap"><div onClick={() => { setShowModal(true); setValueToSendToModal(evidenceDocument.filename); setFieldToSendToModal("evidenceDocumentFilename"); setObjectIdToSendToModal(evidenceDocument.id); setCollectionToSendToModal("documentaryEvidence") }} className="text-sm font-medium text-pacific-blue hover:cursor-pointer whitespace-pre-line"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
  <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
  <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
</svg> {evidenceDocument.filename}</div></td>
                                            <td className="px-4 md:px-6  py-2 hover:cursor-pointer"> {evidenceDocument.responsibility} </td>
                                            <td className="px-4 md:px-6  py-2 hover:cursor-pointer"> {evidenceDocument.requiredAtStage} </td>
                                            <td className="px-4 md:px-6  py-2 hover:cursor-pointer"> {evidenceDocument.timeCritical} </td>
                                            {/* <td className="px-4 md:px-6  py-2 hover:cursor-pointer"> {evidenceDocument.complianceLetter} </td> */}
                                            <td className="px-4 md:px-6 py-2 whitespace-nowrap md:pr-10 lg:pr-20 float-right ">
                                                <Options deleteFunction={deleteDocumentaryEvidence} deleteObject={evidenceDocument.id}></Options>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>

                            </table>
                        </div>

                        <input type="submit" value="Save" className="btn-8A btn-block-8A" />

                    </form>
                    <ModalUpdateValue objectToUpdate={objectIdToSendToModal} fieldToUpdate={fieldToSendToModal} valueToUpdate={valueToSendToModal} collectionToUpdate={collectionToSendToModal} arrayToShow={documentaryEvidence} selectionOptions ={files} onClose={() => setShowModal(false)} show={showModal} title="Update Documentary Evidence Information" />

                </div>
            </div>
        )

    } else {

        return (
            <div className='flex bg-jungle-mist-100 min-h-screen'>
                <div className="container-form-8A">
                <Header title="Buildings - Add / Edit BREEAM Credit" section="BREEAM Credit Details" iconFileName="Building-Hover" url={role === 3001 || role === 6001 ? "/ViewClient/" + company : "/"}/>
                    <p>Data is loading...</p>
                </div>
            </div>)

    }

}

export default AddOrUpdateBuildingBREEAMAssessmentCredit