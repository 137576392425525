import { useState, useEffect } from 'react'
import { useMatch } from 'react-router-dom'
import axios from 'axios'
import ReactPDF, { PDFDownloadLink, Page, Text, View, Document, StyleSheet, Image, Font} from '@react-pdf/renderer';

import { Watch } from  'react-loader-spinner'
import Button from './Button';

const ModalDownload = (props) => {

    Font.register({ family: 'TT Norms Pro', src: '/fonts/TypeType - TT Norms Pro Regular.ttf', fontStyle: 'normal', fontWeight: 'normal' });

    const match = useMatch('/viewBuildingBREEAMAssessment/:buildingId/:assessmentId')

    const [sidebarToggle, setSidebarToggle] = useState(false)
    const [buildingBREEAMAssessments, setBuildingBREEAMAssessments] = useState([])

    const [files, setFiles] = useState([]);

    const [clientName, setClientName] = useState('');

    const [loading, setLoading] = useState(true)
    const [buildingsLoading, setBuildingsLoading] = useState(true)
    const [clientsLoading, setClientsLoading] = useState(true)

    useEffect(() => {

        axios.get(process.env.REACT_APP_API_URL + "/buildings_BREEAM_assessments/" + match.params.assessmentId)
            .then((response) => {
                setBuildingBREEAMAssessments(response.data)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });

        axios.get(process.env.REACT_APP_API_URL + "/buildings/" + match.params.buildingId)
            .then((response) => {
                axios.get(process.env.REACT_APP_API_URL + "/clients/" + response.data.clientId)
                .then((res) => {
                    setClientName(res.data.clientName)
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setClientsLoading(false);
                });
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setBuildingsLoading(false);
            });

        axios.get(process.env.REACT_APP_API_URL + '/presigned_url/' + match.params.buildingId)
            .then((res) => {
                setFiles(res.data)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });


    }, [match.params.buildingId, match.params.assessmentId, props.stakeholder])
 
    const styles = StyleSheet.create({
        body: {
          paddingTop: 35,
          paddingBottom: 65,
          paddingHorizontal: 35,
          fontFamily: 'TT Norms Pro'
        },
        reportTitle: {
            fontSize: 40,
            paddingTop: '40%',
            textAlign: 'center',
            fontFamily: 'TT Norms Pro'
            
          },
        reportSubtitle: {
            fontSize: 40,
            textAlign: 'center',
            fontFamily: 'TT Norms Pro'
            
          },
        author: {
            fontSize: 25,
            paddingTop: '40%',
            textAlign: 'center',
            fontFamily: 'TT Norms Pro'
          },
        title: {
          fontSize: 34,
          color: '#cbd5e1',
          paddingBottom: 10,
          fontFamily: 'TT Norms Pro'
        },
        section: {
            fontSize: 28,
            paddingBottom: 10,
            fontFamily: 'TT Norms Pro'
          },
        categoryCode: {
            fontSize: 15,
            paddingBottom: 10,
            fontFamily: 'TT Norms Pro'
          },
        creditDescription: {
            fontSize: 16,
            paddingBottom: 10,
            fontFamily: 'TT Norms Pro'
          },
        imageDescription: {
            fontSize: 14,
            paddingBottom: 10,
            fontFamily: 'TT Norms Pro'
          },
        text: {
          margin: 12,
          fontSize: 14,
          textAlign: 'justify',
          fontFamily: 'TT Norms Pro'
        },
        logo: {
            width: '119',
            paddingBottom: 20,
            fontFamily: 'TT Norms Pro'
          },
        image: {
          width: 'auto',
          height: 'auto',
          objectFit: 'scale-down',
          paddingBottom: 20,
          paddingTop: 20,
          fontFamily: 'TT Norms Pro'
        },
        pageNumber: {
          position: 'absolute',
          fontSize: 12,
          bottom: 30,
          left: 0,
          right: 0,
          textAlign: 'center',
          color: 'grey',
          fontFamily: 'TT Norms Pro'
        },
        rowView: {
          fontSize: 8, display: 'flex', flexDirection: 'row', borderTop: '1px solid #EEE', paddingTop: 8,
          paddingBottom: 8,
          paddingHorizontal: 35,
          fontFamily: 'TT Norms Pro'
        },
        responsibility: {
            fontWeight: 'bold',
            fontSize: 10,
            fontFamily: 'TT Norms Pro'
        },
        tickBox: {
            fontWeight: 'bold',
            fontSize: 20,
            fontFamily: 'TT Norms Pro'
        },
        letterHeader: {
            textAlign: 'right',
            fontFamily: 'TT Norms Pro'
        },
        letterFooter: {
            textAlign: 'center',
            fontSize: 20,
            fontFamily: 'TT Norms Pro'
        }
      });

    const ComplianceLetter = () => (
                
        <Document>
            <Page style={styles.body}>
                <Text style={styles.letterHeader}>Date:</Text>
                <Text style={styles.letterHeader}>{Date().slice(0,15)}</Text>
                <Text>{"\n"}</Text>
                <Text style={styles.letterHeader}>Attention:</Text>
                <Text style={styles.letterHeader}>{buildingBREEAMAssessments.projectTeam.find(item => item.role === props.stakeholder[0].value) ? buildingBREEAMAssessments.projectTeam.find(item => item.role === props.stakeholder[0].value).name : "[" + props.stakeholder[0].value + " name not set]"}</Text>
                <Text style={styles.letterHeader}>{buildingBREEAMAssessments.projectTeam.find(item => item.role === props.stakeholder[0].value) ? buildingBREEAMAssessments.projectTeam.find(item => item.role === props.stakeholder[0].value).company : "[" + props.stakeholder[0].value + " company not set]"}</Text>
                <Text>{"\n"}</Text>
                <Text>
                <Text>{"\n"}</Text>
                Reference:
                <Text>{"\n"}</Text>
                {buildingBREEAMAssessments.projectName}
                <Text>{"\n"}</Text>
                BREEAM 2018 New Construction Assessment
                <Text>{"\n"}</Text>
                Design Stage Compliance Letter
                <Text>{"\n"}</Text>
                Dear {buildingBREEAMAssessments.projectTeam.find(item => item.role === props.stakeholder[0].value) ? buildingBREEAMAssessments.projectTeam.find(item => item.role === props.stakeholder[0].value).name : "[" + props.stakeholder[0].value + " name not set]"}, 
                <Text>{"\n\n"}</Text>
                Please review the statements below to ensure all meet with your approval and will be included in the design and carried out during construction. 
                <Text>{"\n\n"}</Text>
                These statements will be used as supporting evidence for the Design Stage of the BREEAM 2018 New Construction assessment. 
                <Text>{"\n\n"}</Text>
                Please amend anything highlighted in yellow, copy and paste the text into company letterhead, before printing, signing by hand, scanning and sending back to us as a PDF attachment.
                <Text>{"\n\n"}</Text>
                ---- Copy text below this line ----
                </Text>
                <Text>{"\n\n"}</Text>

                {buildingBREEAMAssessments.credits.filter(item => item.documentaryEvidence.filter(item => item.complianceLetter !== "" && item.complianceLetter !== "-" && item.responsibility === props.stakeholder[0].value).length > 0 && item.creditsTargeted > 0).map((credit) => (
                    <view break>
                        <div key={credit.id}>
                            <Text style={styles.section}>{credit.section}</Text>
                            <Text style={styles.categoryCode}>{credit.creditCategoryCode + " - " + credit.creditCategoryDescription}</Text>
                            <Text style={styles.creditDescription}>{credit.creditDescription}</Text>
                            {credit.documentaryEvidence.filter(item => item.complianceLetter !== "" && item.complianceLetter !== "-" && item.evidenceDocumentRequired === "YES" && item.responsibility === props.stakeholder[0].value).map((evidenceDocument) => (
                                <div key={evidenceDocument.id}>
                                    <Text style={styles.rowView}>{evidenceDocument.complianceLetter}</Text>

                                </div>                        
                            ))}
                        </div>
                    </view>
                ))}
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed />
            </Page>
        </Document>
    )

    const SiteVisitReport = () => (

        <Document>
            <Page style={styles.body}>
            <Image
                style={styles.logo}
                src="/images/eight-versa-colour.png"
            />
            <Text style={styles.reportTitle}>BREEAM</Text>
            <Text style={styles.reportSubtitle}>Site Inspection Report</Text>
            <Text style={styles.reportSubtitle}>{clientName}</Text>
            <Text style={styles.author}>By {buildingBREEAMAssessments.projectManager}</Text>
            {buildingBREEAMAssessments.credits.map((credit) => (
                <view break>
                    <div key={credit.id}>
                        <Image
                            style={styles.logo}
                            src="/images/eight-versa-colour.png"
                        />
                        <Text style={styles.section}>{credit.section}</Text>
                        <Text style={styles.title}>BREEAM Site Inspection Report</Text>
                        <Text style={styles.categoryCode}>{credit.creditCategoryCode + " - " + credit.creditCategoryDescription}</Text>
                        <Text style={styles.creditDescription}>{credit.creditDescription}</Text>
                        {credit.files.filter(item => item.filename.slice(-3) === "jpg" || item.filename.slice(-3) === "JPG").map((file) => (
                            <div key={file.id}>
                                <Text style={styles.imageDescription}>{file.description}</Text>
                                <View wrap={false} style={{ display: 'flex', height: '400', width:'auto', position: 'relative' }}>
                                    <Image 
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                            bottom: 0,
                                            left: 0,
                                            objectFit: 'scale-down',
                                        }}
                                        src={files.find(item => item.name === match.params.buildingId + "/" + file.filename) && files.find(item => item.name === match.params.buildingId + "/" + file.filename).url}
                                    />
                                    </View>
                            </div>                        
                        ))}
                    </div>
                </view>
                ))}
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed />
            </Page>
        </Document>
    )

    if (!props.show) {
        // return null
        return (
            <div>
                {/* <p>Nothing to display</p> */}
            </div>
        )
    }
    return (
        <div className="loading-modal z-50">
            <div className="loading-modal-content">
                <div className="loading-modal-header">
                    <p className="loading-modal-title text-congress-blue text-xl font-bold">Document Download</p>
                    <div className="loading-modal-title text-congress-blue text-xl font-bold">
                        <div>
                            <PDFDownloadLink document={props.reportType==="ComplianceLetter" ? <ComplianceLetter /> : <SiteVisitReport />} fileName={props.reportType==="ComplianceLetter" ? "BREEAM Compliance Letter - " + buildingBREEAMAssessments.projectNumber + ".pdf" : "BREEAM Site Visit Report - " + buildingBREEAMAssessments.projectNumber + ".pdf"}>
                                {({ blob, url, loading, error }) =>
                                    loading ? 
                                        <div>
                                            <p className="loading-modal-title text-congress-blue text-xl font-bold pt-10 pb-10">Your document is being created.<br/>This may take a minute.</p>
                                        </div>
                                    : 
                                        <p className="loading-modal-title text-congress-blue text-xl font-bold pt-10 pb-20">Please click here to download the file</p>
                                }
                            </PDFDownloadLink>
                            <div>
                                <Button text="Close" onClick={props.onClose}></Button>
                            </div>                           
                        </div>
                    </div>
                </div>      
            </div>
        </div>
    )
}

export default ModalDownload