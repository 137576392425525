import { useState, useEffect } from 'react'
import axios from 'axios'
import Header from '../UI/Header'
import { useNavigate, useMatch } from 'react-router-dom'

const AddOrUpdateBuildingEPC = () => {

    const match = useMatch('/addOrUpdateBuildingEPC/:buildingId/:buildingEPCId')

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [buildingId, setBuildingId] = useState('')
    const [address, setAddress] = useState('')
    const [EPCRating, setEPCRating] = useState('')
    const [areaSqMetres, setAreaSqMetres] = useState('')
    const [lodgementDate, setLodgementDate] = useState([])
    const [expiryDate, setExpiryDate] = useState([])
    const [EPCLink, setEPCLink] = useState([])
    const [recommendationReportLink, setRecommendationReportLink] = useState([])


    useEffect(() => {

        setBuildingId(match.params.buildingId)

        if (match.params.buildingEPCId !== "_add") {

            setLoading(true)

            axios.get(process.env.REACT_APP_API_URL + '/buildings_EPCs/' + match.params.buildingEPCId)
                .then((res) => res.data)
                .then((res) => {
                    setBuildingId(res.buildingId)
                    setAddress(res.address)
                    setEPCRating(res.EPCRating)
                    setAreaSqMetres(res.areaSqMetres)
                    setLodgementDate(res.lodgementDate)
                    setExpiryDate(res.expiryDate)
                    setEPCLink(res.EPCLink)
                    setRecommendationReportLink(res.recommendationReportLink)

                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });

        }
    }, [match.params.buildingEPCId, match.params.buildingId])

    const addForm = async (form) => {
        await axios.post(process.env.REACT_APP_API_URL + '/buildings_EPCs', form)
    }

    const updateForm = async (form) => {
        await axios.put(process.env.REACT_APP_API_URL + '/buildings_EPCs/' + match.params.buildingEPCId, form)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (match.params.buildingEPCId !== "_add") {
            updateForm({ buildingId, address, EPCRating, areaSqMetres, lodgementDate, expiryDate, EPCLink, recommendationReportLink }).then(res => { navigate(-1) })
        } else {
            addForm({ buildingId, address, EPCRating, areaSqMetres, lodgementDate, expiryDate, EPCLink, recommendationReportLink }).then(res => { navigate(-1) })
        }
    }

    if (!loading) {

        return (

                <div className='flex items-center bg-jungle-mist-100 min-h-screen min-w-fit'>
                    <div className="container-form-8A">

                    <Header title="Building EPC - Add / Edit Building EPC" section="Building EPC Main Details" iconFileName="Building-Hover" />

                    <form className="add-form-8A" onSubmit={onSubmit}>

                        <div className="form-control-8A">
                            <label>Building Address</label>
                            <input type="text" placeholder="Enter Address Here" value={address} onChange={(e) => setAddress(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>EPC Rating</label>
                            <input type="text" placeholder="Enter EPC Rating Here" value={EPCRating} onChange={(e) => setEPCRating(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Area (m2)</label>
                            <input type="text" placeholder="Enter Area (m2) Here" value={areaSqMetres} onChange={(e) => setAreaSqMetres(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Lodgement Date</label>
                            <input type="date" placeholder="Enter Lodgement Date Here" value={lodgementDate} onChange={(e) => setLodgementDate(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Expiry Date</label>
                            <input type="date" placeholder="Enter Expiry Date Here" value={expiryDate} onChange={(e) => setExpiryDate(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>EPC Link</label>
                            <input type="text" placeholder="Enter EPC Link Here" value={EPCLink} onChange={(e) => setEPCLink(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Recommendation Report Link</label>
                            <input type="text" placeholder="Enter Recommendation Report Link Here" value={recommendationReportLink} onChange={(e) => setRecommendationReportLink(e.target.value)} />
                        </div>

                        <input type="submit" value="Save" className="btn-8A btn-block-8A" />

                    </form>

                </div>
            </div>
        )

    } else {

        return (
            <div>
                <Header title="Building EPC - Add / Edit Building EPC" section="Building EPC Main Details" iconFileName="Building-Hover"/>
                <p>Data is loading...</p>
            </div>)

    }

}

export default AddOrUpdateBuildingEPC