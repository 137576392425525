import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { useNavigate, useMatch } from 'react-router-dom'
import { zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core'
import * as zxcvbnCommonPackage from '@zxcvbn-ts/language-common'
import * as zxcvbnEnPackage from '@zxcvbn-ts/language-en'

const Register = () => {

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [status, setStatus] = useState("")
    const [requiredFields, setRequiredFields] = useState("* All fields are required")
    const [emailError, setEmailError] = useState("")
    const [passwordStrenght, setPasswordStrenght] = useState(0)


    const options = {
        translations: zxcvbnEnPackage.translations,
        graphs: zxcvbnCommonPackage.adjacencyGraphs,
        dictionary: {
            ...zxcvbnCommonPackage.dictionary,
            ...zxcvbnEnPackage.dictionary,
        },
    }

    zxcvbnOptions.setOptions(options)
    const addForm = async (form) => {
        setStatus('Registering...')
        await axios.post(process.env.REACT_APP_API_URL + '/register', form)
            .then(res => {
                setStatus("Registration successful. Please check your email for further instructions.")
                setRequiredFields("")
            })
            .catch(error => {
                setStatus("Error")
                setRequiredFields("")
                console.log(error)
            })
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if ((emailError == "✔️") && (passwordStrenght === 4)) {
            addForm({ firstName, lastName, email, password })
            //.then(res => {setStatus("You have been registered!") })
        }
    }

    useEffect(() => {
        const re = /(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$)/
        if (email.match(re) && email.length < 50) {
            setEmailError("✔️")
        }
        else if (email.length <= 15) {
            setEmailError("")
        }
        else {
            setEmailError("Enter a valid email address")
        }
    }, [email])

    useEffect(() => {
        if (password.length > 0) {
            // zxcvbn(password)
            //console.log(zxcvbn(password).score)
            setPasswordStrenght(zxcvbn(password).score)
            // console.log(zxcvbn(password).feedback.warning)

        }
    }, [password])

    return (
        <form className="min-h-screen bg-jungle-mist-100 flex flex-wrap items-center justify-center py-2 px-2 sm:px-6 lg:px-8 w-full " onSubmit={onSubmit}>
            <div className="max-w-3xl flex shadow-xl shadow-slate-300 rounded-2xl overflow-hidden">

                <div className="md:w-1/2 relative bg-white items-center justify-center ">

                    <div className="md:absolute md:inset-x-0 md:bottom-0">
                        <div className="m-6">
                            <img className="mx-16 md:mx-24 h-20" src='../images/eight-versa-colour.png' />
                        </div>
                        {/* <h2 className="font-medium text-center text-xl text-pacific-blue-700 mb-6">Sign up for our services</h2> */}
                        <div className="w-4/5 mx-auto">
                            <p className='text-xs px-1'>{requiredFields}</p>
                            <div className="flex items-center bg-white rounded-full shadow-md shadow-slate-300 mb-4 border border-slate-200 border-solid mt-1">
                                <span className="px-3">
                                </span>
                                <input
                                    id="firstName"
                                    className='w-full h-8 border-none focus:outline-none'
                                    name="firstName"
                                    type="text"
                                    placeholder="First Name"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                                <span className="px-3">
                                </span>
                            </div>
                            <div className="flex items-center bg-white rounded-full shadow-md shadow-slate-300 mb-4 border border-slate-200 border-solid">
                                <span className="px-3">
                                </span>
                                <input
                                    id="lastName"
                                    className='w-full h-8 border-none focus:outline-none'
                                    name="lastName"
                                    type="text"
                                    placeholder="Last Name"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                                <span className="px-3">
                                </span>
                            </div>

                            <div className="flex items-center bg-white rounded-full shadow-md shadow-slate-300 mb-4 border border-slate-200 border-solid">
                                <span className="px-3">
                                    <svg className="text-pacific-blue-700 w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" >
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                    </svg>
                                </span>
                                <input
                                    id="email"
                                    className='w-full h-8 border-none focus:outline-none'
                                    name="email"
                                    type="text"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <p>{emailError}</p>
                                <span className="px-3">
                                </span>
                            </div>
                            <section className="flex items-center bg-white rounded-full shadow-md shadow-slate-300 mb-4 border border-slate-200 border-solid">
                                <span className="px-3">
                                    <svg className="text-pacific-blue-700 w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" >
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                                    </svg>                                </span>
                                <input
                                    id="password"
                                    // className={'w-full h-8 border-none focus:outline-none ' + (errors.hasOwnProperty('password') ? "border-red-500" : '')}
                                    className='w-full h-8 border-none focus:outline-none'
                                    name="password"
                                    type="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <span className="px-3">
                                </span>
                                {/* {errors.hasOwnProperty('username') &&
                                    <p className="text-red-500 text-xs italic">{errors.username}</p>
                                } */}
                            </section >
                            {/* <a href="http://localhost:3000/login" className="font-medium text-center text-pacific-blue-700 mb-6">Already registered?</a> */}
                            <p className='text-xs'>{zxcvbn(password).feedback.warning}</p>
                            <hr className={`rounded-full border-4 border-slate-300 bg-slate-300 my-1 ${passwordStrenght > 3 ? 'border-emerald-500 bg-emerald-500' : ''}`}></hr>
                            <p className='text-xs'>Password strength: {passwordStrenght}/4. Minimum 4/4.</p>

                            <p className='text-sm font-bold'>{status}</p>
                            <button disabled={(passwordStrenght < 4 || password.length > 64 || emailError !== "✔️" || firstName.length < 2 || firstName.length > 29 || lastName.length < 2 || lastName.length > 29)} className={`bg-emerald-500 block mx-auto text-white text-sm uppercase rounded-full shadow-slate-300 shadow-md px-8 py-3 m-4 cursor-not-allowed 
                            ${(passwordStrenght > 3 && password.length < 65 && emailError === "✔️" && firstName.length > 1 && firstName.length < 30 && lastName.length > 1 && lastName.length < 30) ? 'cursor-pointer' : ''}`}>Sign Up</button>
                        </div>
                    </div>
                </div>
                <div className='hidden md:inline-block md:w-1/2'><img src='../images/staircase.jpg' /></div>
            </div>
        </form>
    );
}

export default Register;