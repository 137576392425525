import { useEffect, useState } from 'react'
import axios from 'axios'
import Button from '../UI/Button'
import Header from '../UI/Header'
import Options from '../UI/Options'
import { useNavigate, useMatch } from 'react-router-dom'

const ListBuildingConstructionSiteImpactAssessments = () => {


    const match = useMatch('/viewBuilding/:buildingId')

    const [loading, setLoading] = useState(false)

    const [buildingConstructionSiteImpactAssessments, setBuildingConstructionSiteImpactAssessments] = useState([])

    useEffect(() => {

        setLoading(true)

        axios.get(process.env.REACT_APP_API_URL + "/site_logs")
            .then((response) => {
                setBuildingConstructionSiteImpactAssessments(response.data.filter(item => item.buildingId === match.params.buildingId))
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });

    }, [match.params.buildingId])

    const navigate = useNavigate ()

    const deleteConstructionSiteImpactAssessment = (id) => {
        if (window.confirm('Are you sure you want to delete this BREEAM Assessment?')) {
            axios.delete(process.env.REACT_APP_API_URL + '/site_log/' + id)
            setBuildingConstructionSiteImpactAssessments(buildingConstructionSiteImpactAssessments.filter((item) => item.id !== id))
        }
    }

    const viewConstructionSiteImpactAssessment = (id) => {
        navigate(`/viewBuildingConstructionSiteImpactAssessment/${match.params.buildingId}/${id}`);
    }

    return (
        <div className=''>
            <h1 className='pt-4 sm:px-6 lg:px-8 pb-2 mx-auto header-8A'>Construction Site Environmental Impact Assessments</h1>

            <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full px-4 sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-jungle-mist-100 sm:rounded border-2">
                            <table className="objects-8A min-w-full divide-y divide-gray-200">
                                <thead className="bg-pacific-blue-50">
                                    <tr>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Site Manager</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Sustainability Champion</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Start Date</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> End Date</th>
                                        <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider md:pr-10 lg:pr-20 float-right "> Actions</th>
                                    </tr>
                                </thead>


                                <tbody className="bg-white divide-y divide-gray-200">
                                {buildingConstructionSiteImpactAssessments.map((buildingConstructionSiteImpactAssessment) => (
                                    <tr key={buildingConstructionSiteImpactAssessment.id}>
                                        <td onClick={() => viewConstructionSiteImpactAssessment(buildingConstructionSiteImpactAssessment.id)} className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer">{buildingConstructionSiteImpactAssessment.siteManager}</td>
                                        <td onClick={() => viewConstructionSiteImpactAssessment(buildingConstructionSiteImpactAssessment.id)} className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer">{buildingConstructionSiteImpactAssessment.sustainabilityChampion}</td>
                                        <td onClick={() => viewConstructionSiteImpactAssessment(buildingConstructionSiteImpactAssessment.id)} className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer">{buildingConstructionSiteImpactAssessment.startDate}</td>
                                        <td onClick={() => viewConstructionSiteImpactAssessment(buildingConstructionSiteImpactAssessment.id)} className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer">{buildingConstructionSiteImpactAssessment.endDate}</td>

                                        <td className="px-4 md:px-6 py-2 whitespace-nowrap md:pr-10 lg:pr-20 float-right ">
                                            <Options deleteFunction={deleteConstructionSiteImpactAssessment} deleteObject={buildingConstructionSiteImpactAssessment.id} edit={`/addOrUpdateBuildingConstructionSiteImpactAssessment/${match.params.buildingId}/${buildingConstructionSiteImpactAssessment.id}`}></Options>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>

                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <br></br>
            <Button className='bg-bondi-blue' text="Add New Construction Site Environmental Impact Log" onClick={() => navigate(`/addOrUpdateBuildingConstructionSiteImpactAssessment/${match.params.buildingId}/_add`)}/>

        </div>
    )

    /*     } else {
    
            return (
            <div>
                <Header title="BREEAM Assessments" section="" showLogo={false}/>
                <p>Data is loading...</p>
            </div>)
    
        } */

}

export default ListBuildingConstructionSiteImpactAssessments
