import { useEffect, useState } from 'react'
import axios from 'axios'
import Button from '../UI/Button'
import Header from '../UI/Header'
import { useNavigate, Navigate } from 'react-router-dom'
import Sidebar from '../UI/Sidebar'
import Options from '../UI/Options'
import authHeader from '../../services/AuthHeader'
import ReactSelect from 'react-select'
import ModalUpdateTimesheet from '../UI/ModalUpdateTimesheet'
import AuthService from '../../services/AuthService'
import Moment from 'react-moment'
import LoadingSpinner from "../UI/LoadingSpinner";

const ListTimesheetEntries = () => {

    //Date formatting to set today's date as yyyy-mm-dd
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    const formattedToday = yyyy + '-' + mm + '-' + dd;

    const [projects, setProjects] = useState([])

    const [users, setUsers] = useState([])

    const [projectServices, setProjectServices] = useState([])

    const [timeSheetData, setTimeSheetData] = useState([])
    const [timeSheetDataFiltered, setTimeSheetDataFiltered] = useState([])

    const [sidebarToggle, setSidebarToggle] = useState(false)

    const [loading, setLoading] = useState(false)
    const [usersLoading, setUsersLoading] = useState(true)
    const [servicesLoading, setServicesLoading] = useState(true)
    const [timeSheetDataLoading, setTimeSheetDataLoading] = useState(true)
    const [redirecting, setRedirecting] = useState(false)
    const [selectedActivityOwner, setSelectedActivityOwner] = useState('All')
    const [selectedTimesheetDate, setSelectedTimesheetDate] = useState('All')
    const [selectedTimesheetDateWeekCommencing, setSelectedTimesheetDateWeekCommencing] = useState('All')
    const [hoursPerWeek, setHoursPerWeek] = useState(Number(0))
    const [hoursAvailablePerWeek, setHoursAvailablePerWeek] = useState(Number(0))
    const [hoursPerWeekNewUser, setHoursPerWeekNewUser] = useState(Number(0))
    const [userId, setUserId] = useState('')
    const [projectId, setProjectId] = useState('')
    const [selectedProjectId, setSelectedProjectId] = useState({})
    const [serviceId, setServiceId] = useState({})
    const [activityId, setActivityId] = useState({})
    const [timesheetDate, setTimesheetDate] = useState(formattedToday)
    const [timesheetHours, setTimesheetHours] = useState(Number(0))
    const [timesheetMonday, setTimesheetMonday] = useState(Number(0))
    const [timesheetTuesday, setTimesheetTuesday] = useState(Number(0))
    const [timesheetWednesday, setTimesheetWednesday] = useState(Number(0))
    const [timesheetThursday, setTimesheetThursday] = useState(Number(0))
    const [timesheetFriday, setTimesheetFriday] = useState(Number(0))
    const [timesheetSaturday, setTimesheetSaturday] = useState(Number(0))
    const [timesheetSunday, setTimesheetSunday] = useState(Number(0))
    const [timesheetNotes, setTimesheetNotes] = useState('')
    const [timesheetNotesToAdd, setTimesheetNotesToAdd] = useState('')
    const [timesheetAvailableHours, setTimesheetAvailableHours] = useState(Number(0))
    const [showModal, setShowModal] = useState(false)
    const [activityAdded, setActivityAdded] = useState(false)
    const [valueToSendToModal, setValueToSendToModal] = useState('')
    const [objectIdToSendToModal, setObjectIdToSendToModal] = useState('')
    const [fieldToSendToModal, setFieldToSendToModal] = useState('')
    const [collectionToSendToModal, setCollectionToSendToModal] = useState('')
    const [timeSheetHoursToSendToModel, setTimeSheetHoursToSendToModal] = useState('')
    const moment = require('moment');
    const [showAdd, setShowAdd] = useState(false)
    const [showImport, setShowImport] = useState(false)
    const [selectedTimesheetDateWeekImport, setSelectedTimesheetDateWeekImport] = useState('')
    const [dataToImport, setDataToImport] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [initialLoad, setInitialLoad] = useState(false)
    const [currentUser, setCurrentUser] = useState('')
    const [deleteFlag, setDeleteFlag] = useState(false)

    let tomorrow = new Date(selectedTimesheetDate)
    tomorrow.setDate(tomorrow.getDate() + 1)

    const API_URL = process.env.REACT_APP_API_URL;

    function stringToDate(_date, _format, _delimiter) {
        var formatLowerCase = _format.toLowerCase();
        var formatItems = formatLowerCase.split(_delimiter);
        var dateItems = _date.split(_delimiter);
        var monthIndex = formatItems.indexOf("mm");
        var dayIndex = formatItems.indexOf("dd");
        var yearIndex = formatItems.indexOf("yyyy");
        var month = parseInt(dateItems[monthIndex]);
        month -= 1;
        var formatedDate = new Date(dateItems[yearIndex], month, dateItems[dayIndex]);
        return formatedDate;
    }

    const styles = {
        option: provided => ({
            ...provided,
            color: 'black'
        }),
        control: provided => ({
            ...provided,
            color: 'black',
            width: 160,
            fontSize: 13
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'black'
        }),
        menu: provided => ({
            ...provided,
            color: 'black',
            width: 160,
            fontSize: 13
        }),
    }

    const [company, setCompany] = useState("")
    const [role, setRole] = useState(0)
    const [userPropertiesLoading, setUserPropertiesLoading] = useState(true)
    const getUserProperties = async () => {

        await axios.get(process.env.REACT_APP_API_URL + "/current_user_properties")
            .then((response) => {
                setCompany(response.data.company)
                setRole(response.data.role)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUserPropertiesLoading(false);
            });

    }

    const noAccess = () => {

        if (company && role && !userPropertiesLoading) {
            // Since admins can have both roles and users only have 1 role
            // we have to exclude if this person has an admin role as well
            // noAccess is going to be true if the person is not admin
            const admin = [2001, 2002, 2003, 2004]
            const notadmin = !admin.includes(role)
            return notadmin
        }
    }

    const startOfWeek = date => {

        const dateConverted = new Date(date)
        var diff = dateConverted.getDate() - dateConverted.getDay() + (dateConverted.getDay() === 0 ? -6 : 1);
        var weekCommencing = new Date(dateConverted.setDate(diff));
        const yyyy = weekCommencing.getFullYear();
        let mm = weekCommencing.getMonth() + 1; // Months start at 0!
        let dd = weekCommencing.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        const formattedWeekCommencingDate = yyyy + '-' + mm + '-' + dd;
        return formattedWeekCommencingDate
    }

    const [timesheetStartDate, setTimesheetStartDate] = useState(startOfWeek(Date()))

    const aggregateTimesheetDateWeekCommencingArray = arr => {

        //ensure that the new week is included in the dropdown even if there are no records
        arr.push({ timesheetDate: startOfWeek(Date()) },)

        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => startOfWeek(obj.timesheetDate) === startOfWeek(val.timesheetDate));
            if (index === -1) {
                acc.push({
                    timesheetDate: startOfWeek(val.timesheetDate),
                });
            };
            return acc.sort((item1, item2) => item1.timesheetDate > item2.timesheetDate ? -1 : 0)
        }, []);
    };

    const aggregateServicesArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.service === val.service);
            if (index === -1) {
                acc.push({
                    label: val.service,
                    id: val.id,
                });
            };
            return acc;
        }, []);
    };

    //reset Add components
    const resetAddComponents = async () => {

        setSelectedProjectId("")
        setProjectId("")
        setServiceId("")
        setActivityId("")
        setTimesheetNotesToAdd("")

    }

    const deleteAllRows = async () => {

        if (timeSheetDataFiltered.length>0){

            if (window.confirm('Are you sure you want to delete all timesheet entries for this week?')) {

                let timeSheetDataCopy = [...timeSheetData]

                // setIsLoading(true)
                await timeSheetDataFiltered.forEach(async timeSheetLine => {

                    const objWithIdIndex = timeSheetDataCopy.findIndex((obj) => obj.id === timeSheetLine.id);
                    timeSheetDataCopy.splice(objWithIdIndex, 1);
                    axios.delete(process.env.REACT_APP_API_URL + '/timesheets/' + timeSheetLine.id)
                    setTimeSheetData(timeSheetDataCopy)
                    setTimeSheetDataFiltered([])
                    
                // }).then(() => {
                //     setTimeSheetData(timeSheetDataCopy)
                //     setTimeSheetDataFiltered([])
                //     // setIsLoading(false)
                })

                //await sleep(2000)
                // setTimeSheetData(timeSheetDataCopy)
                //await sleep(2000)
                // setTimeSheetDataFiltered([])
            }

        }else{
            window.alert('No records to delete')
        }

    }

    const deleteZeroRows= async () => {

        if (timeSheetDataFiltered.length>0){

            if (window.confirm('Are you sure you want to delete all timesheet entries for this week THAT HAVE ZERO HOURS BOOKED AGAINST IT?')) {

                let timeSheetDataCopy = timeSheetData
                let timeSheetDataFilteredCopy = timeSheetDataFiltered.slice()

                timeSheetDataFiltered.forEach(async timeSheetLine => {

                    var totalHrsThisWeek = Number(timeSheetLine.timesheetMonday)+Number(timeSheetLine.timesheetTuesday)+Number(timeSheetLine.timesheetWednesday)+Number(timeSheetLine.timesheetThursday)+Number(timeSheetLine.timesheetFriday)+Number(timeSheetLine.timesheetSaturday)+Number(timeSheetLine.timesheetSunday)

                    if (totalHrsThisWeek === 0){
                        const objWithIdIndex = timeSheetDataCopy.findIndex((obj) => obj.id === timeSheetLine.id);
                        timeSheetDataCopy.splice(objWithIdIndex, 1);

                        const objWithIdIndex2 = timeSheetDataFilteredCopy.findIndex((obj) => obj.id === timeSheetLine.id);
                        timeSheetDataFilteredCopy.splice(objWithIdIndex2, 1);

                        await axios.delete(process.env.REACT_APP_API_URL + '/timesheets/' + timeSheetLine.id)
                    }
                    
                });

                setDeleteFlag(true)

                setTimeSheetData(timeSheetDataCopy)
                setTimeSheetDataFiltered(timeSheetDataFilteredCopy)
                
            }

        }else{
            window.alert('No records to delete')
        }

    }

    const importData = () => {

        setDataToImport(timeSheetData.filter((item) => item.userId === selectedActivityOwner.value && item.timesheetDate === startOfWeek(selectedTimesheetDateWeekImport.value)))

    }

    const deleteTimesheetEntry = (id) => {

        //check if there are any hours for this id.  If there are, prevent the delete
        var timeSheetCheck = timeSheetDataFiltered.filter((item) => item.id === id)
        var timeSheetTotalHours = Number(timeSheetCheck[0].timesheetMonday) + Number(timeSheetCheck[0].timesheetTuesday) + Number(timeSheetCheck[0].timesheetWednesday) + Number(timeSheetCheck[0].timesheetThursday) + Number(timeSheetCheck[0].timesheetFriday) + Number(timeSheetCheck[0].timesheetSaturday) + Number(timeSheetCheck[0].timesheetSunday)

        // if (window.confirm('Are you sure you want to delete this Timesheet entry?')) {
        if (timeSheetTotalHours===0){

            axios.delete(process.env.REACT_APP_API_URL + '/timesheets/' + id)
            setTimeSheetData(timeSheetData.filter((timesheetEntry) => timesheetEntry.id !== id))

        }else{
            window.alert("Please remove all hours allocated to this activity and try again")
        }
    }

    //add function
    const addTimesheetEntry = async () => {
        setTimesheetDate(startOfWeek(Date()))
        setTimesheetHours(Number(0))
        setTimesheetMonday(Number(0))
        setTimesheetTuesday(Number(0))
        setTimesheetWednesday(Number(0))
        setTimesheetThursday(Number(0))
        setTimesheetFriday(Number(0))
        setTimesheetSaturday(Number(0))
        setTimesheetSunday(Number(0))
        setTimesheetNotes("")
        setTimesheetAvailableHours(Number(0))

        var activityAndServiceExist = false

        //Code here to check that serviceId and activityId still exist:
        await axios.get(API_URL + '/projects_services/' + serviceId.value)
        .then((response) => {
            if (response.data.toBeDeleted === "NO" && response.data.activities.filter((item) => item.activityId === activityId.value).length > 0) {
                activityAndServiceExist = true
            }
        })
        .catch((err) => {
            console.log(err);
        }).finally(() => {
            setServicesLoading(false);
        });

        if (activityAndServiceExist) {
            setIsLoading(true)
            await axios.post(process.env.REACT_APP_API_URL + '/timesheets', { userId: selectedActivityOwner.value, projectId: selectedProjectId.value, serviceId: serviceId.value, activityId: activityId.value, timesheetDate: selectedTimesheetDateWeekCommencing.value, timesheetHours, timesheetStartDate: selectedTimesheetDateWeekCommencing.value, timesheetMonday, timesheetTuesday, timesheetWednesday, timesheetThursday, timesheetFriday, timesheetSaturday, timesheetSunday, timesheetNotes: timesheetNotesToAdd.value ? timesheetNotesToAdd.value : timesheetNotesToAdd, timesheetAvailableHours: hoursAvailablePerWeek })
            await getData()
        } else {
            window.alert("Unable to add timesheet entry.  Activity or Service no longer exists. Refresh this page to see updated Service and Activity list")
        }

    }

    const getData = async () => {
        // await sleep(2000)
        await axios.get(API_URL + '/timesheets')
            .then((res) => {
                setTimeSheetData(res.data)
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false)
            }).finally(() => {
                setTimeSheetDataLoading(false);
                setIsLoading(false)
            });
    }

    function sleep(time) {
        return new Promise((resolve) => setTimeout(resolve, time)
        )
    }

    // const migrateData = () => {

    //     console.log("Migrate Code")

    //     let startOfTheWeekDate
    //     let dayField
    //     let iCount = 0

    //     //get all timesheet data into array
    //     //loop through array and for each entry get the date
    //     //timeSheetDataFiltered.forEach(item => {

    //     timeSheetDataFiltered.forEach(item => {
    //         //find the monday for that week
    //         startOfTheWeekDate = startOfWeek(item.timesheetDate)
    //         //find the day of the week for the date
    //         dayField = moment(item.timesheetDate, "YYYY-MM-DD HH:mm:ss").format('dddd')
    //         //update the the weekstartdate and mon, tues, weds... field with the hrs
    //         console.log("id: " + item.id + "Date: " + item.timesheetDate + ", Start of the Week: " + startOfTheWeekDate + ", Day Bucket: " + dayField + ", Hrs: " + item.timesheetHours)
    //         //update function
    //         if (dayField==="Monday"){
    //             axios.put(process.env.REACT_APP_API_URL + '/timesheets/' + item.id, { ...item, timesheetStartDate: startOfTheWeekDate, timesheetMonday: item.timesheetHours })
    //             console.log("Monday")
    //         }else if (dayField==="Tuesday"){
    //             axios.put(process.env.REACT_APP_API_URL + '/timesheets/' + item.id, { ...item, timesheetStartDate: startOfTheWeekDate, timesheetTuesday: item.timesheetHours })
    //             console.log("Tuesday")
    //         }else if (dayField==="Wednesday"){
    //             axios.put(process.env.REACT_APP_API_URL + '/timesheets/' + item.id, { ...item, timesheetStartDate: startOfTheWeekDate, timesheetWednesday: item.timesheetHours })
    //             console.log("Wednesday")
    //         }else if (dayField==="Thursday"){
    //             axios.put(process.env.REACT_APP_API_URL + '/timesheets/' + item.id, { ...item, timesheetStartDate: startOfTheWeekDate, timesheetThursday: item.timesheetHours })
    //             console.log("Thursday")
    //         }else if (dayField==="Friday"){
    //             axios.put(process.env.REACT_APP_API_URL + '/timesheets/' + item.id, { ...item, timesheetStartDate: startOfTheWeekDate, timesheetFriday: item.timesheetHours })
    //             console.log("Friday")
    //         }else if (dayField==="Saturday"){
    //             axios.put(process.env.REACT_APP_API_URL + '/timesheets/' + item.id, { ...item, timesheetStartDate: startOfTheWeekDate, timesheetSaturday: item.timesheetHours })
    //             console.log("Saturday")
    //         }else if (dayField==="Sunday"){
    //             axios.put(process.env.REACT_APP_API_URL + '/timesheets/' + item.id, { ...item, timesheetStartDate: startOfTheWeekDate, timesheetSunday: item.timesheetHours })
    //             console.log("Sunday")
    //         }

    //     })

    //     console.log("Migrate Finished")
    // }

    useEffect(() => {

        setUserPropertiesLoading(true)
        getUserProperties()

    }, [])

    useEffect(() => {

        if (company && role && !userPropertiesLoading) {

            setLoading(true)
            setUsersLoading(true)
            setServicesLoading(true)

            axios.get(API_URL + '/projects_services', { headers: authHeader() })
                .then((response) => {
                    setProjectServices(response.data.filter((item) => item.toBeDeleted === "NO"))
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setServicesLoading(false);
                });

            axios.get(API_URL + '/users')
                .then((res) => {
                    setUsers(res.data.filter(item => item.role !== 3001).sort((item1, item2) => item1.firstName + item1.lastName < item2.firstName + item2.lastName ? -1 : 0))
                    setSelectedActivityOwner({ value: res.data.find(item => item.emailId === AuthService.getCurrentUserName()).id, label: res.data.find(item => item.emailId === AuthService.getCurrentUserName()).firstName + " " + res.data.find(item => item.emailId === AuthService.getCurrentUserName()).lastName })
                    setUserId(res.data.find(item => item.emailId === AuthService.getCurrentUserName()).id)
                    setHoursPerWeek(Number(res.data.find(item => item.emailId === AuthService.getCurrentUserName()).hoursPerWeek))
                    setCurrentUser(res.data.find(item => item.emailId === AuthService.getCurrentUserName()).id)
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setUsersLoading(false);
                });

            axios.get(API_URL + '/projects')
                .then((res) => {
                    setProjects(res.data)
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });

            // setSelectedActivityOwner({ value: "61c4413069a8cf166ec9d6af", label: "Daniel Champ" })
            setSelectedTimesheetDateWeekCommencing({ value: startOfWeek(Date()), label: "Week " + moment(startOfWeek(Date())).locale('en').week() + " (" + startOfWeek(Date()) + " to " + moment(startOfWeek(Date())).add(6, 'days').locale('en').format("YYYY-MM-DD") + ")" })

        }

    }, [role, company, userPropertiesLoading])

    //get the main data
    useEffect(() => {

        axios.get(API_URL + '/timesheets')
            .then((res) => {
                setTimeSheetData(res.data)
                setInitialLoad(true)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setTimeSheetDataLoading(false);
            });

    }, [])

    useEffect(() => {

        if (timeSheetData.length > 0 && selectedActivityOwner.value && selectedTimesheetDateWeekCommencing.value && projects.length > 0 && projectServices.length > 0) {

            let iCount = 0
            var timesheetDataFilteredCopy = timeSheetData.filter((item) => item.userId === selectedActivityOwner.value && startOfWeek(item.timesheetDate) === selectedTimesheetDateWeekCommencing.value)

            if (currentUser !== selectedActivityOwner.value){  //new user selected

                setCurrentUser(selectedActivityOwner.value)
    
                axios.get(API_URL + '/users')
                .then((res) => {
                    setHoursPerWeek(Number(res.data.find(item => item.id === selectedActivityOwner.value).hoursPerWeek))
                    setHoursPerWeekNewUser(Number(res.data.find(item => item.id === selectedActivityOwner.value).hoursPerWeek))
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setUsersLoading(false);
                });
    
            }

            if (timesheetDataFilteredCopy.length>0){
                
                timesheetDataFilteredCopy.forEach(item => {

                    let projectName = projects.find(project => project.id === item.projectId).projectName
                    let projectNumber = projects.find(project => project.id === item.projectId).projectNumber
                    let serviceName = projectServices.filter(service => service.id === item.serviceId).length > 0 ? projectServices.find(service => service.id === item.serviceId).service : "Service ID No Longer Exists"
                    let activityName = projectServices.filter(service => service.id === item.serviceId).length > 0 ? projectServices.find(service => service.id === item.serviceId).activities.filter(activity => activity.activityId === item.activityId).length > 0 ? projectServices.find(service => service.id === item.serviceId).activities.find(activity => activity.activityId === item.activityId).activity : "Activity ID No Longer Exists" : "Service ID No Longer Exists"
                    let serviceComments = projectServices.filter(service => service.id === item.serviceId).length > 0 ? projectServices.find(service => service.id === item.serviceId).serviceComments : "Service ID No Longer Exists"

                    timesheetDataFilteredCopy[iCount].projectName = projectName
                    timesheetDataFilteredCopy[iCount].projectNumber = projectNumber
                    timesheetDataFilteredCopy[iCount].serviceName = serviceName
                    timesheetDataFilteredCopy[iCount].activityName = activityName
                    timesheetDataFilteredCopy[iCount].serviceComments = serviceComments
                    if (timesheetDataFilteredCopy[iCount].timesheetAvailableHours!==0) { //use timesheetAvailableHours
                        setHoursAvailablePerWeek(timesheetDataFilteredCopy[iCount].timesheetAvailableHours)
                    }else{
                        setHoursAvailablePerWeek(hoursPerWeek)
                    }
                    iCount++

                })
            }else{
                setHoursAvailablePerWeek(hoursPerWeek)
            }

            setTimeSheetDataFiltered(timesheetDataFilteredCopy.sort((item1, item2) => item1.projectName < item2.projectName ? -1 : item1.projectName > item2.projectName ? 1 : item1.serviceName < item2.serviceName ? -1 : item1.serviceName > item2.serviceName ? 1 : item1.activityName < item2.activityName ? -1 : item1.activityName > item2.activityName ? 1 : 0))

        }
            
    }, [selectedActivityOwner, selectedTimesheetDate, selectedTimesheetDateWeekCommencing, timeSheetData, projects, projectServices])

    useEffect(() => {

        let iCount = 0
        var timesheetDataFilteredCopy = timeSheetData.filter((item) => item.userId === selectedActivityOwner.value && startOfWeek(item.timesheetDate) === selectedTimesheetDateWeekCommencing.value)

        if (timesheetDataFilteredCopy.length>0){
                
            timesheetDataFilteredCopy.forEach(item => {

                let projectName = projects.find(project => project.id === item.projectId).projectName
                let projectNumber = projects.find(project => project.id === item.projectId).projectNumber
                let serviceName = projectServices.filter(service => service.id === item.serviceId).length > 0 ? projectServices.find(service => service.id === item.serviceId).service : "Service ID No Longer Exists"
                let activityName = projectServices.filter(service => service.id === item.serviceId).length > 0 ? projectServices.find(service => service.id === item.serviceId).activities.filter(activity => activity.activityId === item.activityId).length > 0 ? projectServices.find(service => service.id === item.serviceId).activities.find(activity => activity.activityId === item.activityId).activity : "Activity ID No Longer Exists" : "Service ID No Longer Exists"

                timesheetDataFilteredCopy[iCount].projectName = projectName
                timesheetDataFilteredCopy[iCount].projectNumber = projectNumber
                timesheetDataFilteredCopy[iCount].serviceName = serviceName
                timesheetDataFilteredCopy[iCount].activityName = activityName
                if (timesheetDataFilteredCopy[iCount].timesheetAvailableHours!==0) { //use timesheetAvailableHours
                    setHoursAvailablePerWeek(timesheetDataFilteredCopy[iCount].timesheetAvailableHours)
                }else{
                    setHoursAvailablePerWeek(hoursPerWeek)
                }
                iCount++

            })
        }else{
            setHoursAvailablePerWeek(hoursPerWeekNewUser)
        }

      }, [hoursPerWeekNewUser])

    useEffect( () => {

        if (showModal===false && activityAdded===true) {  //when modal closed

            //check if a record has been added.  If it has, refresh the data
            setIsLoading(true)
            getData()
            setActivityAdded(false)

        }

      }, [showModal])

    useEffect(() => {

        if (dataToImport.length > 0) {

            setIsLoading(true)

            dataToImport.forEach(item => {
                const addData = async () => {
                    await axios.post(process.env.REACT_APP_API_URL + '/timesheets', { userId: item.userId, projectId: item.projectId, serviceId: item.serviceId, activityId: item.activityId, timesheetDate: selectedTimesheetDateWeekCommencing.value, timesheetHours: Number(0), timesheetStartDate: selectedTimesheetDateWeekCommencing.value, timesheetMonday: Number(0), timesheetTuesday: Number(0), timesheetWednesday: Number(0), timesheetThursday: Number(0), timesheetFriday: Number(0), timesheetSaturday: Number(0), timesheetSunday: Number(0), timesheetNotes: item.timesheetNotes, timesheetAvailableHours: Number(hoursAvailablePerWeek) })
                }
                addData()
            })
            getData()
            setDataToImport([])
        }
    }, [dataToImport])

    useEffect(() => {
        setTimesheetNotesToAdd("")
    }, [activityId])

    useEffect(() => {
        if (selectedProjectId.value) {
            setServiceId("")
            setActivityId("")
            if (selectedProjectId.label === " Non-chargeable time | #0") {
                setServiceId({ value: projectServices.filter(item => item.projectId === selectedProjectId.value)[0].id, label: projectServices.filter(item => item.projectId === selectedProjectId.value)[0].service})
            }
        }
    }, [selectedProjectId, projectServices])

    useEffect(() => {
        if (serviceId.value) {
            setActivityId("")
            setTimesheetNotesToAdd("")
        }
    }, [serviceId])

    // useEffect(() => {
    //     if (activityId.value) {
    //         setActivityId(activityId.value)
    //     }
    // }, [activityId])

    if (redirecting) {

        return (
            <Navigate replace to="/login" />
        )
    }
    else if (timeSheetData.length > 0 && projects.length > 0 && projectServices.length > 0 && users.length > 0 && !loading && !usersLoading && !servicesLoading && !timeSheetDataLoading) {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">

                    {sidebarToggle &&
                        <Sidebar />
                    }

                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">

                        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                            <Header title="Time Sheet Entires" section="View / Add / Edit Time Sheet Entries" showUserMenu='true' />
                            {/* <Button color="bg-bondi-blue" text="Migrate Data" onClick={() => { migrateData()}} /> */}

                            <hr></hr>
                            <br></br>

                            <div className="flex items-center">
                                <div className="w-full mx-auto m-5 px-3">
                                    <div className="grid grid-cols-3 gap-2 sm:gap-5 md:grid-cols-5 lg:grid-cols-8">
                                        {/* <div className="pl-3">
                                    <p className="text-xs font-bold">Filters</p>
                                    <Button color="bg-bondi-blue" text="Clear Filters" onClick={() => clearFilters()} />
                                </div> */}
                                        <div>
                                            <p className="text-xs">User</p>
                                            {/* <p className="text-xs">{selectedActivityOwner}</p> */}
                                            <ReactSelect
                                                className="text-xs z-50"
                                                styles={styles}
                                                value={selectedActivityOwner}
                                                onChange={setSelectedActivityOwner}
                                                isDisabled={noAccess()}
                                                //isDisabled
                                                options={users.filter(item => item.role === 1001 || item.role.toString().slice(0,1) === "2" || item.role === 4001 || item.role === 5001 || role === 6002).map((item) => {
                                                    return {
                                                        label: item.firstName + ' ' + item.lastName,
                                                        value: item.id
                                                    }
                                                })} />
                                        </div>
                                        <div className="px-3 md:px-5 col-span-2 w-80 " >
                                            <p className="text-xs">Week Commencing</p>
                                            <ReactSelect
                                                className="text-xs z-50"
                                                value={selectedTimesheetDateWeekCommencing}
                                                onChange={setSelectedTimesheetDateWeekCommencing}
                                                options={aggregateTimesheetDateWeekCommencingArray(timeSheetData).map((item) => {
                                                    return {
                                                        label: "Week " + moment(item.timesheetDate).locale('en').week() + " (" + item.timesheetDate + " to " + moment(item.timesheetDate).add(6, 'days').locale('en').format("YYYY-MM-DD") + ")",
                                                        value: item.timesheetDate
                                                    }
                                                })} />
                                        </div>
                                        <div className="px-3 md:px-5">
                                            <p className="text-xs">&nbsp;</p>
                                            <Button color="bg-bondi-blue" text="Add New Entry" onClick={() => { setShowAdd(!showAdd); resetAddComponents() }} />
                                        </div>
                                                                                <div className="px-3 md:px-5">
                                            <p className="text-xs">&nbsp;</p>
                                            <Button color="bg-bondi-blue" text="Delete All" onClick={() => { deleteAllRows() }} />
                                        </div>
                                        <div className="px-3 md:px-5">
                                            <p className="text-xs">&nbsp;</p>
                                            <Button color="bg-bondi-blue" text="Delete Zero Hrs" onClick={() => { deleteZeroRows() }} />
                                        </div>
                                        <div className="px-3 md:px-5">
                                            <p className="text-xs">&nbsp;</p>
                                            <Button color="bg-bondi-blue" text="Import Data" onClick={() => { setShowImport(!showImport) }} />
                                            {showImport && (
                                                <div className="rounded-2xl bg-white shadow-md shadow-slate-300 border-slate-200 grid-cols-3 p-3 col-span-1" style={{ width: "300px" }}>
                                                    <div className="col-span-2" style={{ width: "250px" }}>
                                                        <p className="text-xs">Week Number</p>
                                                        <ReactSelect
                                                            className="text-xs z-20"
                                                            value={selectedTimesheetDateWeekImport}
                                                            onChange={setSelectedTimesheetDateWeekImport}
                                                            options={aggregateTimesheetDateWeekCommencingArray(timeSheetData).slice(1, 6).map((item) => {
                                                                return {
                                                                    label: "Week " + moment(item.timesheetDate).locale('en').week() + " (" + item.timesheetDate + " to " + moment(item.timesheetDate).add(6, 'days').locale('en').format("YYYY-MM-DD") + ")",
                                                                    value: item.timesheetDate
                                                                }
                                                            })} />
                                                    </div>
                                                    <div className=" col-span-1" style={{ width: "250px" }}>
                                                        <Button color="bg-bondi-blue" text="Import" onClick={() => { setShowImport(false); importData() }} />
                                                        <Button color="bg-bondi-blue" text="Cancel" onClick={() => { setShowImport(false) }} />
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <br></br>

                            <hr></hr>

                            <br></br>
                            {showAdd === true && (
                                <div className="p-3 grid grid-cols-12 mb-5 mx-3 bg-slate-200 rounded-2xl">
                                    <div className="px-3 md:px-5 col-span-3">
                                        <p>Project Number | Name</p>
                                        <div className="form-control-8A pr-1">
                                            <ReactSelect
                                                className="z-20"
                                                // styles={styles}
                                                value={selectedProjectId}
                                                onChange={setSelectedProjectId}
                                                onClick={setSelectedProjectId}
                                                options={projects.sort((item1, item2) => item1.projectNumber < item2.projectNumber ? -1 : item1.projectNumber > item2.projectNumber ? 1 : 0).map((item) => {
                                                    return {
                                                        label: item.projectName + " | #" + item.projectNumber,
                                                        value: item.id
                                                    }
                                                })} />
                                        </div>
                                    </div>
                                    {selectedProjectId.value && selectedProjectId.label !== " Non-chargeable time | #0" && (
                                    <div className="px-3 md:px-5 col-span-2">
                                        {/* Only show once project has been selected */}
                                    
                                            <p>Service</p>
                                            <div className="form-control-8A pr-1">
                                                <ReactSelect
                                                    className="z-20"
                                                    // styles={styles}
                                                    value={serviceId}
                                                    onChange={setServiceId}
                                                    onClick={setServiceId}
                                                    options={projectServices.filter(service => service.projectId === selectedProjectId.value && service.serviceStatus.slice(0,5) !== "Quote" && service.serviceStatus.slice(0,6) !== "Closed").map((service) => {
                                                        return {
                                                            label: service.service + ' (Work Stage: ' + service.workStage + ') (Comments: ' + service.serviceComments + ')',
                                                            value: service.id
                                                        }

                                                    })} />
                                            </div>

                                        </div>

                                        )
                                        }
                                        {serviceId.value && (
                                        <div className="px-3 md:px-5 col-span-2">
                                            <p>Activity</p>
                                            {/* Only show once service has been selected */}
                                                <div className="form-control-8A pr-1">

                                                    <ReactSelect
                                                        className="z-20"
                                                        // styles={styles}
                                                        value={activityId}
                                                        onChange={setActivityId}
                                                        onClick={setActivityId}
                                                        options={projectServices.find(service => service.id === serviceId.value).activities.filter(item => item.activity !== "07. Fee Proposals").sort((item1, item2) => item1.activity < item2.activity ? -1 : 0).map((activity) => {
                                                            return {
                                                                label: activity.activity,
                                                                value: activity.activityId
                                                            }
                                                        })} />

                                                </div>
                                                </div>
                                            )
                                            }
                                    {activityId && activityId.label === "22. Systems Development" && (
                                        <div className="px-3 md:px-5 col-span-2">
                                            <label>Task</label>
                                            <div className="form-control-8A pr-1">

                                                <ReactSelect
                                                    className="z-20"
                                                    // styles={styles}
                                                    value={timesheetNotesToAdd}
                                                    onChange={setTimesheetNotesToAdd}
                                                    onClick={setTimesheetNotesToAdd}
                                                    options={[{ value: "SEO", label: "SEO" }, { value: "Website", label: "Website" }, { value: "Versatility", label: "Versatility" }, { value: "Vero Platform", label: "Vero Platform" }, { value: "Client Meetings", label: "Client Meetings" }, { value: "Project Troubleshooting", label: "Project Troubleshooting" }, { value: "Other Business Development (miscellaneous)", label: "Other Business Development (miscellaneous)" }]}/>
                                            </div>
                                        </div> 
                                    )}

                                    {activityId && activityId.label === "21. Marketing" && (
                                        <div className="px-3 md:px-5 col-span-2">
                                            <label>Task</label>
                                            <div className="form-control-8A pr-1">

                                                <ReactSelect
                                                    className="z-20"
                                                    // styles={styles}
                                                    value={timesheetNotesToAdd}
                                                    onChange={setTimesheetNotesToAdd}
                                                    onClick={setTimesheetNotesToAdd}
                                                    options={[{ value: "Content Creation/Editing", label: "Content Creation/Editing" }, { value: "Events", label: "Events" }, { value: "SEO", label: "SEO" }, { value: "Website and Social Media", label: "Website and Social Media" }, { value: "Email Marketing", label: "Email Marketing" }, { value: "PR", label: "PR" }, { value: "Market/Client Research", label: "Market/Client Research" }, { value: "Strategy Development", label: "Strategy Development" }, { value: "Marketing Analysis", label: "Marketing Analysis" }, { value: "Sales/Consultant Enablement", label: "Sales/Consultant Enablement" }, { value: "Other Marketing (miscellaneous)", label: "Other Marketing (miscellaneous)" }]}/>
                                            </div>
                                        </div> 
                                    )}

                                    {activityId && activityId.label === "19. Operations" && (
                                        <div className="px-3 md:px-5 col-span-2">
                                            <label>Task</label>
                                            <div className="form-control-8A pr-1">

                                                <ReactSelect
                                                    className="z-20"
                                                    // styles={styles}
                                                    value={timesheetNotesToAdd}
                                                    onChange={setTimesheetNotesToAdd}
                                                    onClick={setTimesheetNotesToAdd}
                                                    options={[{ value: "Team Supplier Purchases", label: "Team Supplier Purchases" }, { value: "Office Maintenance", label: "Office Maintenance" }, { value: "Contracts", label: "Contracts" }, { value: "Invoicing and Invoicing Queries", label: "Invoicing and Invoicing Queries" }, { value: "QEMs", label: "QEMs" }, { value: "Supplier Registration", label: "Supplier Registration" }, { value: "Client Registration", label: "Client Registration" }, { value: "Switchboard", label: "Switchboard" }, { value: "Hosting", label: "Hosting" }, { value: "Bookings Management", label: "Bookings Management" }, { value: "Other Operations (miscellaneous)", label: "Other Operations (miscellaneous)" }]}/>
                                            </div>
                                        </div> 
                                    )}

                                    {activityId && activityId.label === "20. Business Development" && (
                                        <div className="px-3 md:px-5 col-span-2">
                                            <label>Task</label>
                                            <div className="form-control-8A pr-1">

                                                <ReactSelect
                                                    className="z-20"
                                                    // styles={styles}
                                                    value={timesheetNotesToAdd}
                                                    onChange={setTimesheetNotesToAdd}
                                                    onClick={setTimesheetNotesToAdd}
                                                    options={[{ value: "Preparing Fee Proposals", label: "Preparing Fee Proposals" }, { value: "New Business: Client Meetings", label: "New Business: Client Meetings" }, { value: "Account Management: Client Meetings", label: "Account Management: Client Meetings" }, { value: "QA of Fee Proposals", label: "QA of Fee Proposals" }, { value: "New Business Outreach", label: "New Business Outreach" }, { value: "Account Management Outreach", label: "Account Management Outreach" }, { value: "Market / Client Research", label: "Market / Client Research" }, { value: "External CPDs delivered", label: "External CPDs delivered" }, { value: "Other Business Development (miscellaneous)", label: "Other Business Development (miscellaneous)" }]}/>
                                            </div>
                                        </div> 
                                    )}

                                    {activityId && activityId.label === "23. HR" && (
                                        <div className="px-3 md:px-5 col-span-2">
                                            <label>Task</label>
                                            <div className="form-control-8A pr-1">

                                                <ReactSelect
                                                    className="z-20"
                                                    // styles={styles}
                                                    value={timesheetNotesToAdd}
                                                    onChange={setTimesheetNotesToAdd}
                                                    onClick={setTimesheetNotesToAdd}
                                                    options={[{ value: "Recruitment", label: "Recruitment" }, { value: "On-boarding", label: "On-boarding" }, { value: "Off-boarding", label: "Off-boarding" }, { value: "Payroll", label: "Payroll" }, { value: "Policy & Procedure", label: "Policy & Procedure" }, { value: "Happiness and Socials", label: "Happiness and Socials" }, { value: "Review and Probation Management", label: "Review and Probation Management" }, { value: "Other HR (miscellaneous)", label: "Other HR (miscellaneous)" }, { value: "Health & Safety", label: "Health & Safety" }, { value: "Phone management", label: "Phone management" }]}/>
                                            </div>
                                        </div> 
                                    )}

                                    {activityId && activityId.label === "24. Finance" && (
                                        <div className="px-3 md:px-5 col-span-2">
                                            <label>Task</label>
                                            <div className="form-control-8A pr-1">

                                                <ReactSelect
                                                    className="z-20"
                                                    // styles={styles}
                                                    value={timesheetNotesToAdd}
                                                    onChange={setTimesheetNotesToAdd}
                                                    onClick={setTimesheetNotesToAdd}
                                                    options={[{ value: "General Finance (bookkeeping)", label: "General Finance (bookkeeping)" }, { value: "Debtors", label: "Debtors" }, { value: "Payroll", label: "Payroll" }, { value: "Management Information", label: "Management Information" }, { value: "Accounts Receivable", label: "Accounts Receivable" }, { value: "Accounts Payable", label: "Accounts Payable" }, { value: "Operations", label: "Operations" }, { value: "Appointments", label: "Appointments" }, { value: "Finance Admin", label: "Finance Admin" }, { value: "Other Finance (miscellaneous)", label: "Other Finance (miscellaneous)" }]}/>
                                            </div>
                                        </div> 
                                    )}

                                    {activityId && activityId.label !== "22. Systems Development" && activityId.label !== "21. Marketing" && activityId.label !== "19. Operations" && activityId.label !== "20. Business Development" && activityId.label !== "23. HR" && activityId.label !== "24. Finance" && (
                                        <div className="px-3 md:px-5 col-span-2">
                                            <label>Task</label>
                                            <div className="form-control-8A pr-1">
                                                <input type="Text" placeholder="Enter Notes Here" value={timesheetNotesToAdd} onChange={(e) => setTimesheetNotesToAdd(e.target.value)} />
                                            </div>
                                        </div> 
                                    )}

                                    <div className="px-3 md:px-5 py-2 pt-12">
                                        {activityId && activityId.label !== "22. Systems Development" && activityId.label !== "21. Marketing" && activityId.label !== "19. Operations" && activityId.label !== "20. Business Development" && activityId.label !== "23. HR" && activityId.label !== "24. Finance" && (
                                            // <button className="btn-8A btn-block-8A" onClick={addTimesheetEntry()}>Add</button>
                                            <button className="btn-8A btn-block-8A" onClick={() => { setShowAdd(false); addTimesheetEntry() }}>Add</button>
                                        )}
                                        {activityId && (activityId.label === "22. Systems Development" || activityId.label === "21. Marketing" || activityId.label === "19. Operations" || activityId.label === "20. Business Development" || activityId.label === "23. HR" || activityId.label === "24. Finance") && timesheetNotesToAdd !== "" && (
                                            // <button className="btn-8A btn-block-8A" onClick={addTimesheetEntry()}>Add</button>
                                            <button className="btn-8A btn-block-8A" onClick={() => { setShowAdd(false); addTimesheetEntry() }}>Add</button>
                                        )}
                                    </div>

                                    <br></br>
                                    <hr></hr>
                                    <br></br>

                                </div>

                            )}

                            <div className="flex flex-col py-2">
                                <div className="overflow-x-auto items-holder-8A">
                                    <div className="text-eight-text align-middle inline-block min-w-full px-3">
                                        <div className="shadow-md shadow-slate-300 rounded-2xl">
                                            {!isLoading &&
                                                <table className="objects-8A min-w-full divide-y divide-gray-200 table-fixed">

                                                    <thead className="bg-pacific-blue-50 z-10 sticky top-0">
                                                        <tr>
                                                            <th scope="col" className="pl-4 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '240px' }}> Project</th>
                                                            <th scope="col" className="pl-4 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '240px' }}> Service</th>
                                                            <th scope="col" className="pl-4 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '280px' }}> Activity</th>
                                                            <th scope="col" className="pl-4 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '250px' }}> Task / Notes</th>
                                                            <th scope="col" className="pl-4 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '100px' }}> Monday <br />({selectedTimesheetDateWeekCommencing.value})</th>
                                                            <th scope="col" className="pl-4 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '100px' }}> Tuesday <br />({<Moment format="YYYY-MM-DD" add={{ days: 1 }}>{selectedTimesheetDateWeekCommencing.value}</Moment>})</th>
                                                            <th scope="col" className="pl-4 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '100px' }}> Wednesday <br />({<Moment format="YYYY-MM-DD" add={{ days: 2 }}>{selectedTimesheetDateWeekCommencing.value}</Moment>})</th>
                                                            <th scope="col" className="pl-4 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '100px' }}> Thursday <br />({<Moment format="YYYY-MM-DD" add={{ days: 3 }}>{selectedTimesheetDateWeekCommencing.value}</Moment>})</th>
                                                            <th scope="col" className="pl-4 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '100px' }}> Friday <br />({<Moment format="YYYY-MM-DD" add={{ days: 4 }}>{selectedTimesheetDateWeekCommencing.value}</Moment>})</th>
                                                            <th scope="col" className="pl-4 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '100px' }}> Saturday <br />({<Moment format="YYYY-MM-DD" add={{ days: 5 }}>{selectedTimesheetDateWeekCommencing.value}</Moment>})</th>
                                                            <th scope="col" className="pl-4 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '100px' }}> Sunday <br />({<Moment format="YYYY-MM-DD" add={{ days: 6 }}>{selectedTimesheetDateWeekCommencing.value}</Moment>})</th>
                                                            <th scope="col" className="pl-4 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '80px' }}> Total</th>
                                                            <th scope="col" className="pl-4 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '80px' }}> </th>
                                                            {/* <th scope="col" className="pl-4 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider md:pr-10 lg:pr-20 float-right"> Actions</th> */}
                                                        </tr>
                                                    </thead>

                                                    <tbody className="bg-white divide-y divide-gray-200">
                                                        {timeSheetDataFiltered.map((item) => (
                                                            <tr key={item.id}>
                                                                <td className="pl-4 py-2 whitespace-normal"> {item.projectNumber} | {item.projectName}</td>
                                                                <td className="pl-4 py-2 whitespace-normal"> {item.serviceComments !== "" && item.serviceComments !== "Internal" ? item.serviceName + " (Comments: " + item.serviceComments + ")" : item.serviceName}</td>
                                                                <td className="pl-4 py-2 whitespace-normal"> {item.activityName}</td>
                                                                <td className="pl-4 py-2 whitespace-normal hover:cursor-pointer" onClick={() => { setShowModal(true); setValueToSendToModal(item.timesheetNotes); setFieldToSendToModal("timesheetNotes"); setObjectIdToSendToModal(item.id); setCollectionToSendToModal("timesheets") }}>{item.timesheetNotes}</td>
                                                                <td className="pl-4 py-2 whitespace-nowrap hover:cursor-pointer" onClick={() => { setShowModal(true); setValueToSendToModal(item.timesheetMonday); setFieldToSendToModal("timesheetMonday"); setObjectIdToSendToModal(item.id); setCollectionToSendToModal("timesheets") }}>{item.timesheetMonday === 0 ? "0" : item.timesheetMonday}</td>
                                                                <td className="pl-4 py-2 whitespace-nowrap hover:cursor-pointer" onClick={() => { setShowModal(true); setValueToSendToModal(item.timesheetTuesday); setFieldToSendToModal("timesheetTuesday"); setObjectIdToSendToModal(item.id); setCollectionToSendToModal("timesheets") }}>{item.timesheetTuesday === 0 ? "0" : item.timesheetTuesday}</td>
                                                                <td className="pl-4 py-2 whitespace-nowrap hover:cursor-pointer" onClick={() => { setShowModal(true); setValueToSendToModal(item.timesheetWednesday); setFieldToSendToModal("timesheetWednesday"); setObjectIdToSendToModal(item.id); setCollectionToSendToModal("timesheets") }}>{item.timesheetWednesday === 0 ? "0" : item.timesheetWednesday}</td>
                                                                <td className="pl-4 py-2 whitespace-nowrap hover:cursor-pointer" onClick={() => { setShowModal(true); setValueToSendToModal(item.timesheetThursday); setFieldToSendToModal("timesheetThursday"); setObjectIdToSendToModal(item.id); setCollectionToSendToModal("timesheets") }}>{item.timesheetThursday === 0 ? "0" : item.timesheetThursday}</td>
                                                                <td className="pl-4 py-2 whitespace-nowrap hover:cursor-pointer" onClick={() => { setShowModal(true); setValueToSendToModal(item.timesheetFriday); setFieldToSendToModal("timesheetFriday"); setObjectIdToSendToModal(item.id); setCollectionToSendToModal("timesheets") }}>{item.timesheetFriday === 0 ? "0" : item.timesheetFriday}</td>
                                                                <td className="pl-4 py-2 whitespace-nowrap hover:cursor-pointer" onClick={() => { setShowModal(true); setValueToSendToModal(item.timesheetSaturday); setFieldToSendToModal("timesheetSaturday"); setObjectIdToSendToModal(item.id); setCollectionToSendToModal("timesheets") }}>{item.timesheetSaturday === 0 ? "0" : item.timesheetSaturday}</td>
                                                                <td className="pl-4 py-2 whitespace-nowrap hover:cursor-pointer" onClick={() => { setShowModal(true); setValueToSendToModal(item.timesheetSunday); setFieldToSendToModal("timesheetSunday"); setObjectIdToSendToModal(item.id); setCollectionToSendToModal("timesheets") }}>{item.timesheetSunday === 0 ? "0" : item.timesheetSunday}</td>
                                                                {/* <td className="pl-4 py-2 whitespace-nowrap text-congress-blue">{item.timesheetHours}</td> */}
                                                                <td className="pl-4 py-2 whitespace-nowrap text-congress-blue">{Number(item.timesheetMonday) + Number(item.timesheetTuesday) + Number(item.timesheetWednesday) + Number(item.timesheetThursday) + Number(item.timesheetFriday) + Number(item.timesheetSaturday) + Number(item.timesheetSunday)}</td>

                                                                <td>
                                                                    {/* <Options cloneFunction={cloneTimesheetEntry} cloneObject={[item.userId, item.projectId, item.serviceId, item.activityId, item.timesheetDate, item.timesheetHours]} deleteFunction={deleteTimesheetEntry} deleteObject={item.id}></Options> */}
                                                                    {/* <Options deleteFunction={deleteTimesheetEntry} deleteObject={item.id}></Options> */}
                                                                    <button onClick={() => { deleteTimesheetEntry(item.id) }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16"> <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/> <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/> </svg>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold">Total hours booked per day</td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold">{timeSheetDataFiltered.map(item => Number(item.timesheetMonday)).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(1)}</td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold">{timeSheetDataFiltered.map(item => Number(item.timesheetTuesday)).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(1)}</td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold">{timeSheetDataFiltered.map(item => Number(item.timesheetWednesday)).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(1)}</td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold">{timeSheetDataFiltered.map(item => Number(item.timesheetThursday)).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(1)}</td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold">{timeSheetDataFiltered.map(item => Number(item.timesheetFriday)).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(1)}</td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold">{timeSheetDataFiltered.map(item => Number(item.timesheetSaturday)).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(1)}</td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold">{timeSheetDataFiltered.map(item => Number(item.timesheetSunday)).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(1)}</td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold">Total hours booked this week</td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold">{timeSheetDataFiltered.map(item => Number(item.timesheetMonday) + Number(item.timesheetTuesday) + Number(item.timesheetWednesday) + Number(item.timesheetThursday) + Number(item.timesheetFriday) + Number(item.timesheetSaturday) + Number(item.timesheetSunday)).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)}</td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold">Available hours</td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold">{Number(hoursAvailablePerWeek)}</td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold">Unbooked hours</td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold">{(Number(hoursAvailablePerWeek) - timeSheetDataFiltered.map(item => Number(item.timesheetMonday) + Number(item.timesheetTuesday) + Number(item.timesheetWednesday) + Number(item.timesheetThursday) + Number(item.timesheetFriday) + Number(item.timesheetSaturday) + Number(item.timesheetSunday)).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2)}</td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold">Project hours</td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold">{timeSheetDataFiltered.filter(item => item.projectId !== "630c6b53c35272b916fdb09a").map(item => Number(item.timesheetMonday) + Number(item.timesheetTuesday) + Number(item.timesheetWednesday) + Number(item.timesheetThursday) + Number(item.timesheetFriday) + Number(item.timesheetSaturday) + Number(item.timesheetSunday)).reduce((prev, curr) => Number(prev) + Number(curr), 0)}</td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold">Admin hours</td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold">{timeSheetDataFiltered.filter(item => item.projectId === "630c6b53c35272b916fdb09a").map(item => Number(item.timesheetMonday) + Number(item.timesheetTuesday) + Number(item.timesheetWednesday) + Number(item.timesheetThursday) + Number(item.timesheetFriday) + Number(item.timesheetSaturday) + Number(item.timesheetSunday)).reduce((prev, curr) => Number(prev) + Number(curr), 0)}</td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue"></td>
                                                            <td className="pl-4  bg-slate-200 text-congress-blue font-bold">Productivity (Project hrs / Booked hrs )</td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold">{isNaN(((timeSheetDataFiltered.filter(item => item.projectId !== "630c6b53c35272b916fdb09a").map(item => Number(item.timesheetMonday) + Number(item.timesheetTuesday) + Number(item.timesheetWednesday) + Number(item.timesheetThursday) + Number(item.timesheetFriday) + Number(item.timesheetSaturday) + Number(item.timesheetSunday)).reduce((prev, curr) => Number(prev) + Number(curr), 0) / timeSheetDataFiltered.map(item => Number(item.timesheetMonday) + Number(item.timesheetTuesday) + Number(item.timesheetWednesday) + Number(item.timesheetThursday) + Number(item.timesheetFriday) + Number(item.timesheetSaturday) + Number(item.timesheetSunday)).reduce((prev, curr) => Number(prev) + Number(curr), 0)) * 100).toFixed(2)) ? 0 : ((timeSheetDataFiltered.filter(item => item.projectId !== "630c6b53c35272b916fdb09a").map(item => Number(item.timesheetMonday) + Number(item.timesheetTuesday) + Number(item.timesheetWednesday) + Number(item.timesheetThursday) + Number(item.timesheetFriday) + Number(item.timesheetSaturday) + Number(item.timesheetSunday)).reduce((prev, curr) => Number(prev) + Number(curr), 0) / timeSheetDataFiltered.map(item => Number(item.timesheetMonday) + Number(item.timesheetTuesday) + Number(item.timesheetWednesday) + Number(item.timesheetThursday) + Number(item.timesheetFriday) + Number(item.timesheetSaturday) + Number(item.timesheetSunday)).reduce((prev, curr) => Number(prev) + Number(curr), 0)) * 100).toFixed(2)}%</td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                            <td className="pl-4 whitespace-nowrap bg-slate-200 text-congress-blue font-bold"></td>
                                                        </tr>
                                                    </tfoot>
                                                </table>}
                                            {isLoading && <LoadingSpinner />}
                                            {/* <ModalUpdateTimesheet objectToUpdate={objectIdToSendToModal} fieldToUpdate={fieldToSendToModal} valueToUpdate={valueToSendToModal} collectionToUpdate={collectionToSendToModal} arrayToShow={timeSheetData} timeSheetHoursToUpdate={timeSheetHoursToSendToModel} timeSheetProjectServices={projectServices} timeSheetDataForThisWeek={timeSheetDataFiltered} bob={"Yes"} onChange={() => setActivityAdded(true)} onClose={() => setShowModal(false)} show={showModal} title="Update Timesheet" /> */}
                                            <ModalUpdateTimesheet objectToUpdate={objectIdToSendToModal} fieldToUpdate={fieldToSendToModal} valueToUpdate={valueToSendToModal} collectionToUpdate={collectionToSendToModal} arrayToShow={timeSheetData} timeSheetHoursToUpdate={timeSheetHoursToSendToModel} timeSheetProjectServices={projectServices} timeSheetDataForThisWeek={timeSheetDataFiltered} activityAdded={() => setActivityAdded(true)} onClose={() => setShowModal(false)} show={showModal} title="Update Timesheet" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    } else {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Time Sheet Entries" section="View / Add / Edit Time Sheet Entries" showUserMenu='true' />
                        <hr></hr>
                        <br></br>
                        <div className="flex items-center">
                            <div className="w-full mx-auto m-5 px-3">
                                <div className="grid grid-cols-2 gap-2 sm:gap-5 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 ">

                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >
                                    </div>

                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >
                                    </div>

                                    <div className="w-24 mb-2.5 " >
                                    </div>

                                    <div className="w-24 mb-2.5 " >
                                    </div>

                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >
                                    </div>

                                    <div className="h-6 bg-slate-300 rounded-2xl  w-24 mb-2.5 animate-pulse" >
                                    </div>

                                </div>
                            </div>
                        </div>

                        <br></br>
                        <hr></hr>

                        <div className="h-8 bg-slate-300 rounded-2xl w-full mx-3 mb-5 animate-pulse" >
                        </div>
                        <div className="flex flex-col py-2">
                            <div className="overflow-x-auto items-holder-8A">
                                <div className="text-eight-text align-middle inline-block min-w-full px-3">
                                    <div role="status" className=" bg-white w-full p-4 space-y-4 border border-slate-200 divide-y divide-slate-200 rounded-2xl shadow-slate-300 shadow-md animate-pulse ">

                                        <div className="flex items-center justify-between">
                                            <div>
                                                <div className="h-5 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-5 bg-slate-300 rounded-full w-48"></div><div className="h-5 bg-slate-300 rounded-full w-24"></div><div className="h-5 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>

                                        <div className="flex items-center justify-between pt-4">
                                            <div>
                                                <div className="h-3 bg-slate-300 rounded-full  w-10 mb-2.5"></div>
                                            </div>
                                            <div className="h-3 bg-slate-300 rounded-full w-48"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div><div className="h-3 bg-slate-300 rounded-full w-24"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        )

    }

}

export default ListTimesheetEntries
