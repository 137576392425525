import { useLocation, Navigate, Outlet } from "react-router-dom"
import { useEffect, useState } from 'react'
import axios from 'axios'
import AuthService from "../services/AuthService";


const RequireAuth = ({ allowedRoles }) => {
    const location = useLocation();

    const [company, setCompany] = useState("")
    const [role, setRole] = useState(0)

    const getUserProperties = async () => {

        await axios.get(process.env.REACT_APP_API_URL + "/current_user_properties")
        .then((response) => {
            setCompany(response.data.company)
            setRole(response.data.role)
        })
    
    }

    useEffect(() => {

        if (AuthService.getCurrentUser()) {

            getUserProperties()

        }

    }, [])

    if (role && company) {

        if ((typeof (role) !== 'undefined') && (allowedRoles.includes(role))) {

            return (
                <Outlet />
            )
        }
        else if ((typeof (role) == 'undefined') || (role == null)) {

            return (
                <Navigate to="/login" state={{ from: location }} replace />
            )
        }
        // redirecring everyone to their home page
        else if ((role===6001 || role===3001) && location.pathname === "/")
        {
            return (
                <Navigate to={"/viewClient/" + company} />
            )
        }
        else {
            return (
                <Navigate to="/comingSoon" state={{ from: location }} />
            )
        }
    } else if (!AuthService.getCurrentUser()) {
        return (
            <Navigate to="/login" state={{ from: location }} replace />
        )
    }
}
export default RequireAuth
