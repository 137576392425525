import { useState, useEffect } from 'react'
import axios from 'axios'
import Header from '../UI/Header'
import { useNavigate, useMatch } from 'react-router-dom'

const AddOrUpdateBuildingOperationalCarbonEmissions = () => {

    const match = useMatch('/addOrUpdateBuildingOperationalCarbonEmissions/:buildingId/:buildingOperationalCarbonEmissionsId')

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [buildingId, setBuildingId] = useState('')
    const [address, setAddress] = useState('')
    const [electricityEnergyDemand, setElectricityEnergyDemand] = useState('')
    const [gasEnergyDemand, setGasEnergyDemand] = useState('')
    const [areaSqMetres, setAreaSqMetres] = useState('')
    const [renewableGeneration, setRenewableGeneration] = useState('')
    const [RIBAStage, setRIBAStage] = useState('')
    const [offsetting, setOffsetting] = useState('')
    const [operationalLabel, setOperationalLabel] = useState('')
    const [year, setYear] = useState('')
    const [otherFuel, setOtherFuel] = useState('')
    const [emissions, setEmissions] = useState('')
    const [operationalCarbonEmissionsAssessmentItems, setOperationalCarbonEmissionsAssessmentItems] = useState([])

    useEffect(() => {

        setBuildingId(match.params.buildingId)

        if (match.params.buildingOperationalCarbonEmissionsId !== "_add") {

            setLoading(true)

            axios.get(process.env.REACT_APP_API_URL + '/buildings_operational_carbon_emissions/' + match.params.buildingOperationalCarbonEmissionsId)
                .then((res) => res.data)
                .then((res) => {
                    setBuildingId(res.buildingId)
                    setAddress(res.address)
                    setElectricityEnergyDemand(res.electricityEnergyDemand)
                    setGasEnergyDemand(res.gasEnergyDemand)
                    setAreaSqMetres(res.areaSqMetres)
                    setRenewableGeneration(res.renewableGeneration)
                    setRIBAStage(res.RIBAStage)
                    setOffsetting(res.offsetting)
                    setOperationalLabel(res.operationalLabel)
                    setYear(res.year)
                    setOtherFuel(res.otherFuel)
                    setEmissions(res.emissions)
                    setOperationalCarbonEmissionsAssessmentItems(res.operationalCarbonEmissionsAssessmentItems)

                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });

        }
    }, [match.params.buildingOperationalCarbonEmissionsId, match.params.buildingId])

    const addForm = async (form) => {
        await axios.post(process.env.REACT_APP_API_URL + '/buildings_operational_carbon_emissions', form)
    }

    const updateForm = async (form) => {
        await axios.put(process.env.REACT_APP_API_URL + '/buildings_operational_carbon_emissions/' + match.params.buildingOperationalCarbonEmissionsId, form)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (match.params.buildingOperationalCarbonEmissionsId !== "_add") {
            updateForm({ buildingId, address, areaSqMetres, electricityEnergyDemand, gasEnergyDemand, renewableGeneration, offsetting, operationalLabel, year, otherFuel, emissions, RIBAStage, operationalCarbonEmissionsAssessmentItems }).then(res => { navigate(-1) })
        } else {
            addForm({ buildingId, address, areaSqMetres, electricityEnergyDemand, gasEnergyDemand, renewableGeneration, offsetting, operationalLabel, year, otherFuel, emissions, RIBAStage, operationalCarbonEmissionsAssessmentItems }).then(res => { navigate(-1) })
        }
    }

    if (!loading) {

        return (

                <div className='flex items-center bg-jungle-mist-100 min-h-screen'>
                    <div className="container-form-8A">

                    <Header title="Add / Edit Building Operational Carbon Emissions" section="Building Operational Carbon Emissions Main Details" iconFileName="Building-Hover"/>

                    <form className="add-form-8A" onSubmit={onSubmit}>

                        <div className="form-control-8A">
                            <label>Building Address</label>
                            <input type="text" placeholder="Enter Address Here" value={address} onChange={(e) => setAddress(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Area (m2)</label>
                            <input type="text" placeholder="Enter Area (m2) Here" value={areaSqMetres} onChange={(e) => setAreaSqMetres(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Electricity Energy Demand</label>
                            <input type="text" placeholder="Enter Electricity Energy Demand Here" value={electricityEnergyDemand} onChange={(e) => setElectricityEnergyDemand(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Gas Energy Demand</label>
                            <input type="text" placeholder="Enter Gas Energy Demand Here" value={gasEnergyDemand} onChange={(e) => setGasEnergyDemand(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Other Fuel</label>
                            <input type="text" placeholder="Enter Other Fuel Here" value={otherFuel} onChange={(e) => setOtherFuel(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Renewable Generation kWh/m2</label>
                            <input type="text" placeholder="Enter Renewable Generation Here" value={renewableGeneration} onChange={(e) => setRenewableGeneration(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>RIBA Stage</label>
                            <input type="text" placeholder="Enter RIBA Stage Here" value={RIBAStage} onChange={(e) => setRIBAStage(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Year</label>
                            <input type="text" placeholder="Enter Year Here" value={year} onChange={(e) => setYear(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>CO2 Emissions Tonnes in CO2/m2​</label>
                            <input type="text" placeholder="Enter CO2 Emissions Here" value={emissions} onChange={(e) => setEmissions(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Offsetting</label>
                            <input type="text" placeholder="Enter Offsetting Here" value={offsetting} onChange={(e) => setOffsetting(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Operational Label</label>
                            <input type="text" placeholder="Enter Operational Label Here" value={operationalLabel} onChange={(e) => setOperationalLabel(e.target.value)} />
                        </div>

                        <input type="submit" value="Save" className="btn-8A btn-block-8A" />

                    </form>

                </div>
            </div>
        )

    } else {

        return (
            <div className='flex items-center bg-jungle-mist-100 min-h-screen'>
                <div className="container-form-8A">
                    <Header title="Add / Edit Building Operational Carbon Emissions" section="Building Operational Carbon Emissions Main Details" iconFileName="Building-Hover"/>
                    <p>Data is loading...</p>
                </div>
            </div>)

    }

}

export default AddOrUpdateBuildingOperationalCarbonEmissions