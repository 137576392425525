import { useState, useEffect } from 'react'
import axios from 'axios'
import Header from '../UI/Header'
import { useNavigate, useMatch } from 'react-router-dom'

const AddOrUpdateBuilding = (props) => {

    const match = useMatch('/addOrUpdateBuilding/:id')

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [loadingClients, setLoadingClients] = useState(false)

    const [clientId, setClientId] = useState('')
    const [buildingName, setBuildingName] = useState('')
    const [buildingAddress, setBuildingAddress] = useState('')
    const [buildingContact, setBuildingContact] = useState('')
    const [buildingMarketClassification, setBuildingMarketClassification] = useState('')
    const [buildingParkName, setBuildingParkName] = useState('')
    const [buildingGreenBuildingRating, setBuildingGreenBuildingRating] = useState('')
    const [buildingGreenBuildingLevel, setBuildingGreenBuildingLevel] = useState('')
    const [buildingType, setBuildingType] = useState('')
    const [buldingTotalAreaSqMetres, setbuldingTotalAreaSqMetres] = useState('')
    const [buildingAnnualkWhElectricity, setBuildingAnnualkWhElectricity] = useState('')
    const [buildingAnnualkWhGas, setBuildingAnnualkWhGas] = useState('')
    const [buildingCO2eFromElectricityConsumption, setBuildingCO2eFromElectricityConsumption] = useState('')
    const [buildingCO2eFromGasConsumption, setBuildingCO2eFromGasConsumption] = useState('')
    const [buildingCountry, setBuildingCountry] = useState('')

    const [clients, setClients] = useState([])

    useEffect(() => {
        if (match.params.id !== "_add") {

            setLoading(true)

            axios.get(process.env.REACT_APP_API_URL + '/buildings/' + match.params.id)
                .then((res) => res.data)
                .then((res) => {
                    setClientId(res.clientId)
                    setBuildingName(res.buildingName)
                    setBuildingAddress(res.buildingAddress)
                    setBuildingContact(res.buildingContact)
                    setBuildingMarketClassification(res.buildingMarketClassification)
                    setBuildingParkName(res.buildingParkName)
                    setBuildingGreenBuildingRating(res.buildingGreenBuildingRating)
                    setBuildingGreenBuildingLevel(res.buildingGreenBuildingLevel)
                    setBuildingType(res.buildingType)
                    setbuldingTotalAreaSqMetres(res.buldingTotalAreaSqMetres)
                    setBuildingAnnualkWhElectricity(res.buildingAnnualkWhElectricity)
                    setBuildingAnnualkWhGas(res.buildingAnnualkWhGas)
                    setBuildingCO2eFromElectricityConsumption(res.buildingCO2eFromElectricityConsumption)
                    setBuildingCO2eFromGasConsumption(res.buildingCO2eFromGasConsumption)
                    setBuildingCountry(res.buildingCountry)
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });
        }

        axios.get(process.env.REACT_APP_API_URL + '/clients')
        .then((res) => {
            setClients(res.data.sort((item1, item2) => item1.clientName + item1.clientName < item2.clientName + item2.clientName ? -1 : 0));
            if (match.params.organisationId === "_add") {
                setClientId(res.data[0].id);
            }
        })
        .catch((err) => {
            console.log(err);
        }).finally(() => {
            setLoadingClients(false);
        });

    }, [match.params.id])

    const addForm = async (form) => {
        await axios.post(process.env.REACT_APP_API_URL + '/buildings', form)
    }

    const updateForm = async (form) => {
        await axios.put(process.env.REACT_APP_API_URL + '/buildings/' + match.params.id, form)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (match.params.id !== "_add") {
            updateForm({ clientId, buildingName, buildingAddress, buildingContact, buildingMarketClassification, buildingParkName, buildingGreenBuildingRating, buildingGreenBuildingLevel, buildingType, buldingTotalAreaSqMetres, buildingAnnualkWhElectricity, buildingAnnualkWhGas, buildingCO2eFromElectricityConsumption, buildingCO2eFromGasConsumption, buildingCountry }).then(res => { navigate(-1) })
        } else {
            addForm({ clientId, buildingName, buildingAddress, buildingContact, buildingMarketClassification, buildingParkName, buildingGreenBuildingRating, buildingGreenBuildingLevel, buildingType, buldingTotalAreaSqMetres, buildingAnnualkWhElectricity, buildingAnnualkWhGas, buildingCO2eFromElectricityConsumption, buildingCO2eFromGasConsumption, buildingCountry }).then(res => { navigate(-1) })
        }
    }

    if (!loading && !loadingClients) {

        return (
            <div className='flex items-center bg-jungle-mist-100 min-h-screen'>
                <div className="container-form-8A">

                    <Header title="Buildings - Add / Edit Building" section="Main Section" iconFileName="Building-Hover" />

                    <form className="add-form-8A" onSubmit={onSubmit}>

                        <div className="form-control-8A">
                            <label>Client</label>
                            <select value={clientId} onChange={(e) => { setClientId(e.target.value)}}>
                                {clients.map((client) => (
                                    <option key={client.id} value={client.id}>{client.clientName}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-control-8A">
                            <label>Building Name</label>
                            <input type="text" placeholder="Enter Building Name Here" value={buildingName} onChange={(e) => setBuildingName(e.target.value)} />
                        </div>
                        <div className="form-control-8A">
                            <label>Building Address</label>
                            <input type="text" placeholder="Enter Building Address Here" value={buildingAddress} onChange={(e) => setBuildingAddress(e.target.value)} />
                        </div>
                        <div className="form-control-8A">
                            <label>Building Country</label>
                            <input type="text" placeholder="Enter Building Country Here" value={buildingCountry} onChange={(e) => setBuildingCountry(e.target.value)} />
                        </div>
                        <div className="form-control-8A">
                            <label>Building Contact</label>
                            <input type="text" placeholder="Enter Building Contact Here" value={buildingContact} onChange={(e) => setBuildingContact(e.target.value)} />
                        </div>
                        <div className="form-control-8A">
                            <label>Market Classification</label>
                            <input type="text" placeholder="Enter Market Classification Here" value={buildingMarketClassification} onChange={(e) => setBuildingMarketClassification(e.target.value)} />
                        </div>
                        <div className="form-control-8A">
                            <label>Park Name</label>
                            <input type="text" placeholder="Enter Park Name Here" value={buildingParkName} onChange={(e) => setBuildingParkName(e.target.value)} />
                        </div>
                        <div className="form-control-8A">
                            <label>Green Building Rating</label>
                            <input type="text" placeholder="Enter Green Building Rating Here" value={buildingGreenBuildingRating} onChange={(e) => setBuildingGreenBuildingRating(e.target.value)} />
                        </div>
                        <div className="form-control-8A">
                            <label>Green Building Level</label>
                            <input type="text" placeholder="Enter Green Building Level Here" value={buildingGreenBuildingLevel} onChange={(e) => setBuildingGreenBuildingLevel(e.target.value)} />
                        </div>
                        <div className="form-control-8A">
                            <label>Building Type</label>
                            <input type="text" placeholder="Enter Building Type Here" value={buildingType} onChange={(e) => setBuildingType(e.target.value)} />
                        </div>
                        <div className="form-control-8A">
                            <label>Total Area (sqft)</label>
                            <input type="text" placeholder="Enter Total Area (sqft) Here" value={buldingTotalAreaSqMetres} onChange={(e) => setbuldingTotalAreaSqMetres(e.target.value)} />
                        </div>
                        <div className="form-control-8A">
                            <label>Annual kWh Electricity (Calculated Estimate)</label>
                            <input type="text" placeholder="Enter Annual kWh Electricity Here" value={buildingAnnualkWhElectricity} onChange={(e) => setBuildingAnnualkWhElectricity(e.target.value)} />
                        </div>
                        <div className="form-control-8A">
                            <label>Annual kWh Gas (Calculated Estimate)</label>
                            <input type="text" placeholder="Enter Annual kWh Gas Here" value={buildingAnnualkWhGas} onChange={(e) => setBuildingAnnualkWhGas(e.target.value)} />
                        </div>
                        <div className="form-control-8A">
                            <label>Annual CO2e from Electricity Consumption (Calculated Estimate)</label>
                            <input type="text" placeholder="Enter CO2e from Electricity Consumption Here" value={buildingCO2eFromElectricityConsumption} onChange={(e) => setBuildingCO2eFromElectricityConsumption(e.target.value)} />
                        </div>
                        <div className="form-control-8A">
                            <label>Annual CO2e from Gas Consumption (Calculated Estimate)</label>
                            <input type="text" placeholder="Enter CO2e from Gas Consumption Here" value={buildingCO2eFromGasConsumption} onChange={(e) => setBuildingCO2eFromGasConsumption(e.target.value)} />
                        </div>

                        <input type="submit" value="Save" className="btn-8A btn-block-8A" />

                    </form>

                </div>
            </div>
        )

    } else {

        return (
            <div className='flex items-center bg-jungle-mist-100 min-h-screen'>

                <div className="container-form-8A">
                    <Header title="Buildings - Add / Edit Building" section="Main Section" iconFileName="Building-Hover" />
                    <p>Data is loading...</p>
                </div>
                
            </div>)

    }

}

export default AddOrUpdateBuilding
