import { useState, useEffect } from 'react'
import axios from 'axios'
import Header from '../UI/Header'
// import ProgressBar from './ProgressBar'
import { useNavigate, useMatch } from 'react-router-dom'

const AddOrUpdateSite = () => {

    const match = useMatch('/addOrUpdateSite/:siteId')

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)

    const [siteName, setSiteName] = useState('')
    const [siteDescription, setSiteDescription] = useState('')
    const [siteLatitude, setSiteLatitude] = useState('')
    const [siteLongitude, setSiteLongitude] = useState('')
    const [sitePolygonCoordinates, setSitePolygonCoordinates] = useState([])

    useEffect(() => {
        if (match.params.siteId !== "_add") {

            setLoading(true)

            axios.get(process.env.REACT_APP_API_URL + '/sites/' + match.params.siteId)
                .then((res)  => {
                    setSiteName(res.data.siteName)
                    setSiteDescription(res.data.siteDescription)
                    setSiteLatitude(res.data.siteLatitude)
                    setSiteLongitude(res.data.siteLongitude)
                    setSitePolygonCoordinates(res.data.sitePolygonCoordinates)
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });
        }
    }, [match.params.siteId])

    const addForm = async (form) => {
        await axios.post(process.env.REACT_APP_API_URL + '/sites', form)
    }

    const updateForm = async (form) => {
        await axios.put(process.env.REACT_APP_API_URL + '/sites/' + match.params.siteId, form)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (match.params.siteId !== "_add") {
            updateForm({ siteName, siteDescription, siteLatitude, siteLongitude, sitePolygonCoordinates }).then(res => { navigate(-1) })
        } else {
            addForm({ siteName, siteDescription, siteLatitude, siteLongitude, sitePolygonCoordinates }).then(res => { navigate(-1) })
        }
    }

    if (!loading) {

        return (

            <div className="container-form-8A">

                <Header title="Sites - Add / Edit Site" section="Sites" iconFileName="Site-Hover" />

                <br></br>

                <form className="add-form-8A" onSubmit={onSubmit}>

                    <div className="form-control-8A">
                        <label>Site Name</label>
                        <input type="text" placeholder="Enter Site Name Here" value={siteName} onChange={(e) => setSiteName(e.target.value)} />
                    </div>
                    <div className="form-control-8A">
                        <label>Site Description</label>
                        <input type="text" placeholder="Enter Site Description Here" value={siteDescription} onChange={(e) => setSiteDescription(e.target.value)} />
                    </div>
                    <div className="form-control-8A">
                        <label>Site Latitude</label>
                        <input type="text" placeholder="Enter Site Latitude Here" value={siteLatitude} onChange={(e) => setSiteLatitude(e.target.value)} />
                    </div>
                    <div className="form-control-8A">
                        <label>Site Longitude</label>
                        <input type="text" placeholder="Enter Site Longitude Here" value={siteLongitude} onChange={(e) => setSiteLongitude(e.target.value)} />
                    </div>

                    <input type="submit" value="Save" className="btn-8A btn-block-8A" />

                </form>

            </div>
        )

    } else {

        return (
            <div>
                <Header title="Sites - Add / Edit Site" section="Sites" iconFileName="Site-Hover" />
                <p>Data is loading...</p>
            </div>)

    }

}

export default AddOrUpdateSite
