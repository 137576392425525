import React from 'react';
import DOMPurify from 'dompurify';

const Note = ({ htmlContent }) => {
  // Sanitize the HTML content
  const sanitizedHtmlContent = DOMPurify.sanitize(htmlContent);

  return (
    <div
      dangerouslySetInnerHTML={{ __html: sanitizedHtmlContent }}
    />
  );
};

export default Note;