import { useEffect, useState } from 'react'
import axios from 'axios'
import Button from '../UI/Button'
import Header from '../UI/Header'
import { useNavigate } from 'react-router-dom'
import Sidebar from '../UI/Sidebar'
import AuthService from '../../services/AuthService'


const ListOrganisations = () => {

    const [organisations, setOrganisations] = useState([])

    const [sidebarToggle, setSidebarToggle] = useState(false)

    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    const [company, setCompany] = useState("")
    const [role, setRole] = useState(0)
    const [userPropertiesLoading, setUserPropertiesLoading] = useState(true)

    const getUserProperties = async () => {

        await axios.get(process.env.REACT_APP_API_URL + "/current_user_properties")
        .then((response) => {
            setCompany(response.data.company)
            setRole(response.data.role)
        })
        .catch((err) => {
            console.log(err);
        }).finally(() => {
            setUserPropertiesLoading(false);
        });
    
    }

    useEffect(() => {

        setUserPropertiesLoading(true)

        getUserProperties()

    }, [])

    useEffect(() => {

        if (company && role && !userPropertiesLoading) {

            setLoading(true)

            if (role === 3001 || role === 6001) {

                //Code here to call special API endpoint returning only organisations for that client
                axios.get(process.env.REACT_APP_API_URL + '/organisations_by_clientId/' + company)
                    .then((res) => {
                        setOrganisations(res.data.sort((item1, item2) => item1.organisationName < item2.organisationName ? -1 : item1.organisationName > item2.organisationName ? 1 : 0))
                    })
                    .catch((err) => {
                        console.log(err);
                    }).finally(() => {
                        setLoading(false);
                    });

            } else if (role === 6002) {

                //Code here to call special API endpoint returning only organisations set for that special 6002 user
                axios.get(process.env.REACT_APP_API_URL + '/organisations_by_special_user')
                    .then((res) => {
                        setOrganisations(res.data.organisations)
                    })
                    .catch((err) => {
                        console.log(err);
                    }).finally(() => {
                        setLoading(false);
                    });

            } else {

                axios.get(process.env.REACT_APP_API_URL + '/organisations')
                .then((res) => {
                    setOrganisations(res.data.sort((item1, item2) => item1.organisationName < item2.organisationName ? -1 : item1.organisationName > item2.organisationName ? 1 : 0))
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });    

            }

            axios.post(process.env.REACT_APP_API_URL + "/log", {message: AuthService.getCurrentUserName() + " ListOrganisations"})
            
        }

    }, [company, role, userPropertiesLoading])

    const deleteOrganisation = (id) => {

        if (window.confirm('Are you sure you want to delete this organisation?')) {

            if (role === 3001 || role === 6001) {

                window.alert("Please contact your Eight consultant to delete organisations")

            } else {

            //Check here to see if any organisation_footprints relate to this organisation
            //If there are any then do not allow the deletion

                axios.get(process.env.REACT_APP_API_URL + '/organisations_footprints_by_organisationId/' + id)
                .then((res) => {

                    if (res.data.length > 0) {
                        window.alert("Please remove organisation footprints from organisation in order to delete organisation")
                    } else {
                        axios.delete(process.env.REACT_APP_API_URL + '/organisations/' + id)
                        setOrganisations(organisations.filter((organisation) => organisation.id !== id))
                    }

                })
                .catch((err) => {
                    console.log(err);
                })

            }

        }

    }

    const editOrganisation = (id) => {
        navigate(`/addOrUpdateOrganisation/${id}`);
    }

    const viewOrganisation = (id) => {
        navigate(`/viewOrganisation/${id}`);
    }


    if (organisations.length > 0 && !loading && !userPropertiesLoading) {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="flex h-screen overflow-hidden bg-slate-100">

                    {sidebarToggle &&
                        <Sidebar />
                    }

                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">

                        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                            <Header title="Organisations" section="Select Organisation" showUserMenu='true' url={role === 3001 || role === 6001 ? "/viewClient/" + company : role === 6002 ? "/listOrganisations" : "/"}/>
                            <div className="flex flex-wrap">
                                {organisations.map((organisation) => (

                                    <div key={organisation.id} className=' md:w-1/4 lg:w-1/6 max-w-[17rem]'>
                                        <div className=" group flex relative bg-slate-200 aspect-square shadow-xl m-2 shadow-slate-300 overflow-hidden content-center rounded-2xl" key={organisation.id}>
                                            <div className='bg-slate-200 w-full pb-2'>
                                                {/* TODO: organisation.buildingName to ther way of handling organisation logo */}
                                                <img className="object-cover w-full aspect-square text-center rounded-md " src={`https://storage.googleapis.com/public_images_bucket/${organisation.id}/` + organisation.organisationName + ".jpg"} onError={(e) => { e.target.src = "/images/organisation_generic.png" }} alt="loading..." />
                                            </div>

                                            <div className="absolute hover:cursor-pointer bg-congress-blue w-full h-full bg-opacity-0 transition-bg-opacity duration-500  hover:bg-opacity-80 z-1">

                                                <div className='group-hover:cursor-pointer pt-6'></div>

                                                {role !== 6002 &&
                                                <div className='group-hover:cursor-pointer pl-2' onClick={() => deleteOrganisation(organisation.id)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="opacity-0 inline-block stroke-red-600 h-6 w-6 m-1 group-hover:cursor-pointer group-hover:opacity-100" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.5">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                    </svg>
                                                    <p className="opacity-0 inline-block text-red-600 drop-shadow-xl text-md group-hover:cursor-pointer group-hover:opacity-100 hover:underline">Delete</p>
                                                </div>
                                                }

                                                {role !== 6002 &&
                                                <div className='group-hover:cursor-pointer pl-2' onClick={() => editOrganisation(organisation.id)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="opacity-0 inline-block stroke-slate-50 h-6 w-6 m-1 group-hover:opacity-100" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.5">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                                    </svg>
                                                    <p className="opacity-0 inline-block text-slate-50 text-md group-hover:opacity-100 hover:underline">Edit Organisation</p>
                                                </div>
                                                }

                                                <div className='group-hover:cursor-pointer pl-2' onClick={() => viewOrganisation(organisation.id)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="opacity-0 inline-block stroke-slate-50 h-6 w-6 m-1 group-hover:opacity-100" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.5">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                    </svg>
                                                    <p className="opacity-0 inline-block text-slate-50 text-md group-hover:opacity-100 hover:underline">View Footprints</p>
                                                </div>

                                            </div>

                                            {/* <figcaption className='p-2 w-full  left-0 bottom-0 right-0 text-slate-50 bg-gradient-to-b from-transparent to-black '>{building.buildingName}</figcaption>
                                    <p className="text-xs pt-1">{building.buildingName}</p>
                                    <p className="h-10 overflow-hidden text-xs">{building.buildingAddress}</p>
                                    <p className="text-xs">{building.buildingContact}</p>
                                    <Button color="black" text="View" onClick={() => viewBuilding(building.id)} /><Button color="black" text="Edit" onClick={() => editBuilding(building.id)} /><Button color="black" text="Delete" onClick={() => deleteBuilding(building.id)} /> */}
                                        </div>
                                        <div className='pb-2 m-2 mx-4 text-eight-text'>
                                            <p onClick={() => viewOrganisation(organisation.id)} className="text-md pt-1 hover:cursor-pointer hover:underline">{organisation.organisationName}</p>
                                            <p className="text-xs">{organisation.organisationAddress}</p>
                                        </div>
                                    </div>

                                ))}
                            </div>

                            <br></br>
                            {role !== 3001 && role !== 6001 && role !== 6002 &&
                            <Button color="bondi-blue" text="Add New Organisation" onClick={() => navigate('/addOrUpdateOrganisation/_add')} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        )

    } else if (organisations.length === 0 && (loading || userPropertiesLoading)) {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Organisations" section="Select Organisation" showUserMenu='true' url={role === 3001 || role === 6001 ? "/viewClient/" + company : role === 6002 ? "/listOrganisations" : "/"}/>
                        <br></br>
                        <p>Data is loading</p>
                    </div>

                </div>

            </div>
        )

    } else if (organisations.length === 0 && !loading && !userPropertiesLoading) {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                    <Header title="Organisations" section="Select Organisation" showUserMenu='true' url={role === 3001 || role === 6001 ? "/viewClient/" + company : role === 6002 ? "/listOrganisations" : "/"}/>
                        <br></br>
                        <div className="grid h-screen place-items-center">
                            <div className="flex flex-wrap lg:w-3/4 l divide-y">
                                <h1 className='p-10 mx-auto border-2 rounded-lg header-8A'>No Organisations</h1>
                            </div>
                        </div>
                        <br></br>
                        {role !== 3001 &&
                        <Button color="bondi-blue" text="Add New Organisation" onClick={() => navigate('/addOrUpdateOrganisation/_add')} />
                        }
                    </div>

                </div>
            </div>
        )

    }

}

export default ListOrganisations
