import { useEffect, useState, useReducer } from 'react'
import Button from '../UI/Button'
import Options from '../UI/Options'
import { useNavigate, useMatch, Navigate } from 'react-router-dom'
import axios from 'axios'
import authHeader from '../../services/AuthHeader'
import { v4 as uuidv4 } from 'uuid'
import ModalUpdateValue from '../UI/ModalUpdateValue'
import ModalAddToBillingPlan from '../UI/ModalAddToBillingPlan'
import ModalAddEditActivity from '../UI/ModalAddEditActivity'
import InfoModal from '../UI/InfoModal'
import ReactSelect from 'react-select'
import { ExportToCsv } from 'export-to-csv'
// import ReactTooltip from "react-tooltip"
// import { isNumber } from 'util'
import * as XLSX from "xlsx"
import {Table} from "antd"
import '../UI/antd.css'
import ActivityProgressBar from '../UI/ActivityProgressBar'

const ListClientProjectServices = (props) => {

    const match = useMatch('/viewClientProject/:projectId')

    const [loading, setLoading] = useState(false)

    const [refreshTrigger, setRefreshTrigger] = useState(false)

    const [projectServiceIdsToExpand, setProjectServiceIdsToExpand] = useState([])

    const [projectServices, setProjectServices] = useState([])
    const [projectServicesFiltered, setProjectServicesFiltered] = useState([])

    // const [usersLoading, setUsersLoading] = useState(true)
    const [users, setUsers] = useState([])
    const [clients, setClients] = useState([])
    const [contacts, setContacts] = useState([])
    // const [clientsLoading, setClientsLoading] = useState(false)

    const [invoicesSchedule, setInvoicesSchedule] = useState([])
    const [invoicesScheduleLoading, setInvoicesScheduleLoading] = useState(true)

    const [invoices, setInvoices] = useState([])
    const [invoicesLoading, setInvoicesLoading] = useState(true)

    const [invoiceLineAdded, setInvoiceLineAdded] = useState(false)
    const [serviceAddedOrDeleted, setServiceAddedOrDeleted] = useState(false)

    const [departmentsServicesActivities, setDepartmentsServicesActivities] = useState([])
    const [departmentsServicesActivitiesLoading, setDepartmentsServicesActivitiesLoading] = useState(true)

    const [timesheets, setTimesheets] = useState([])
    // const [timesheetsLoading, setTimesheetsLoading] = useState(true)

    const [servicesList, setServicesList] = useState([])
    const [departmentsList, setDepartmentsList] = useState([])
    const [workStagesList, setWorkStagesList] = useState([])

    const [department, setDepartment] = useState('')
    const [service, setService] = useState('')
    const [workStage, setWorkStage] = useState('')
    const [serviceManager, setServiceManager] = useState('')
    const [activityOwner, setActivityOwner] = useState('')

    const [showModal, setShowModal] = useState(false)
    const [showInfoModal, setShowInfoModal] = useState(false)
    //const [modalMessage, setModalMessage] = useState(false)
    const [valueToSendToModal, setValueToSendToModal] = useState('')
    const [objectIdToSendToModal, setObjectIdToSendToModal] = useState('')
    const [fieldToSendToModal, setFieldToSendToModal] = useState('')
    const [collectionToSendToModal, setCollectionToSendToModal] = useState('')
    const [collectionArrayToSendToModal, setCollectionArrayToSendToModal] = useState('')
    const [collectionArrayIdToSendToModal, setCollectionArrayIdToSendToModal] = useState('')
    const [serviceIdToSendToModal, setServiceIdToSendToModal] = useState('')
    const [activityIdToSendToModal, setActivityIdToSendToModal] = useState('')

    const [todaysDate, setTodaysDate] = useState('')

    // const [activities, setActivities] = useState([])
    const [redirecting, setRedirecting] = useState(false)

    const [showModalAddToBillingPlan, setShowModalAddToBillingPlan] = useState(false)
    const [showModalAddEditActivity, setShowModalAddEditActivity] = useState(false)
    const [activityModalUse, setActivityModalUse] = useState('')

    const API_URL = process.env.REACT_APP_API_URL

    const [selectedService, setSelectedService] = useState('All')
    const [selectedDepartment, setSelectedDepartment] = useState('All')
    const [selectedWorkStage, setSelectedWorkStage] = useState('All')
    const [selectedServiceManager, setSelectedServiceManager] = useState('All')
    const [selectedServiceStatus, setSelectedServiceStatus] = useState('All')
    const [selectedCustomerPo, setSelectedCustomerPo] = useState('All')
    const [selectedDealNumber, setSelectedDealNumber] = useState('All')

    const [projectSustainabilityTargets, setProjectSustainabilityTargets] = useState('')
    const [projectLocation, setProjectLocation] = useState('')
    const [projectDrawings, setProjectDrawings] = useState('')
    const [projectUseClassOfBuilding, setProjectUseClassOfBuilding] = useState('')
    const [projectType, setProjectType] = useState('')
    const [projectGrossInternalArea, setProjectGrossInternalArea] = useState('')
    const [projectCurrentRIBAStage, setProjectCurrentRIBAStage] = useState({})
    const [projectProgrammeProvided, setProjectProgrammeProvided] = useState('')
    const [projectSecondaryUseClassOfBuilding, setProjectSecondaryUseClassOfBuilding] = useState('')
    const [projectGrossInternalLandArea, setProjectGrossInternalLandArea] = useState('')
    const [projectBuildingDataLastUpdated, setProjectBuildingDataLastUpdated] = useState('')

    const [hubSpotLineItems, setHubSpotLineItems] = useState([])
    const [xlData, setXlData] = useState([])

    const [afterEditActivity, setAfterEditActivity] = useState(false)    

    const forceUpdateReducer = (x) => x + 1;

    // const [currentId, setCurrentId] = useState('')
    // const [currentActivityId, setCurrentActivityId] = useState('')
    const [expandedDepartmentKeys, setExpandedDepartmentKeys] = useState([]);

    const onExpand = (expanded, record) => {
        // Only expand the current department
        setExpandedDepartmentKeys(expanded ? [record._id] : []);
    };

    // const [deals, setDeals] = useState([])

    function containsNumber(str) {
        return /[1-9999]/.test(str);
    }

    const styles = {
        option: provided => ({
            ...provided,
            color: 'black'
        }),
        control: provided => ({
            ...provided,
            color: 'black',
            width: 160,
            fontSize: 13
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'black'
        }),
        menu: provided => ({
            ...provided,
            color: 'black',
            width: 160,
            fontSize: 13
        }),
    }

    const internationalNumberFormat = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

    const clearFilters = () => {
        setSelectedService('All')
        setSelectedDepartment('All')
        setSelectedWorkStage('All')
        setSelectedServiceManager('All')
        setSelectedServiceStatus('All')
        setSelectedCustomerPo('All')
        setSelectedDealNumber('All')
    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const serviceIncludedInBillingPlan = (id) => {

        //checks to see if serviceId is present on an invoice and
        //checks total fee by service against total value added to billing plan by service

        var invoice
        var invoiceLine
        var totalFeeOnBillingPlan = 0
        var onInvoice = false

        for (invoice = 0; invoice < invoices.length; invoice++) {
            for (invoiceLine = 0; invoiceLine < invoices[invoice].invoiceLines.length; invoiceLine++) {

                if (
                    invoices[invoice].invoiceLines[invoiceLine].serviceId === id
                ) {
                    onInvoice = true
                    totalFeeOnBillingPlan = totalFeeOnBillingPlan + invoices[invoice].invoiceLines[invoiceLine].value
                }
            }
        }

        if (onInvoice && totalFeeOnBillingPlan === 0) {
            totalFeeOnBillingPlan = -1
        }

        return totalFeeOnBillingPlan

    }

    const serviceTotal = (id) => {

        //checks to see if serviceId is present on an invoice and
        //checks total fee by service against total value added to billing plan by service

        var invoice
        var invoiceLine
        var totalFeeOnBillingPlan = 0
        var onInvoice = false

        for (invoice = 0; invoice < invoices.length; invoice++) {
            for (invoiceLine = 0; invoiceLine < invoices[invoice].invoiceLines.length; invoiceLine++) {

                if (
                    invoices[invoice].invoiceLines[invoiceLine].serviceId === id
                ) {
                    onInvoice = true
                    totalFeeOnBillingPlan = totalFeeOnBillingPlan + invoices[invoice].invoiceLines[invoiceLine].value
                }
            }
        }

        if (onInvoice && totalFeeOnBillingPlan === 0) {
            totalFeeOnBillingPlan = -1
        }

        return totalFeeOnBillingPlan

    }

    const navigate = useNavigate()

    const deleteProjectService = async (id) => {

        if (window.confirm('Are you sure you want to delete Service?')) {

            var checkOK = await deletionValidityCheck(id)

            if (checkOK) {
                // mark toBeDeleted

                var projectId = projectServices.find((service) => service.id === id).projectId
                var service = projectServices.find((service) => service.id === id).service
                var serviceManager = projectServices.find((service) => service.id === id).serviceManager
                var department = projectServices.find((service) => service.id === id).department
                var activities = projectServices.find((service) => service.id === id).activities
                var workStage = projectServices.find((service) => service.id === id).workStage
                var serviceStatus = projectServices.find((service) => service.id === id).serviceStatus
                var serviceComments = projectServices.find((service) => service.id === id).serviceComments
                var serviceCreatedDate = projectServices.find((service) => service.id === id).serviceCreatedDate
                var serviceLiveDate = projectServices.find((service) => service.id === id).serviceLiveDate
                var serviceClosedDate = projectServices.find((service) => service.id === id).serviceClosedDate
                var dealNumber = projectServices.find((service) => service.id === id).dealNumber
                var dealName = projectServices.find((service) => service.id === id).dealName
                var lineItemNumber = projectServices.find((service) => service.id === id).lineItemNumber
                var customerPo = projectServices.find((service) => service.id === id).customerPo
                // var changedFlag = projectServices.find((service) => service.id === id).changedFlag
                var serviceInvoiceClient = projectServices.find((service) => service.id === id).serviceInvoiceClient
                var serviceInvoiceClientContact = projectServices.find((service) => service.id === id).serviceInvoiceClientContact
                var toBeDeleted = "YES"

                await updateForm({ projectId, service, serviceManager, department, activities, workStage, serviceStatus, serviceComments, toBeDeleted, serviceCreatedDate, serviceLiveDate, serviceClosedDate, dealNumber, dealName, lineItemNumber, customerPo, changedFlag: true, serviceInvoiceClient, serviceInvoiceClientContact }, id)

                setProjectServices(projectServices.filter((service) => service.id !== id))

                setServiceAddedOrDeleted(true)

            } else {
                window.alert("Please remove service from billing plan and timesheets in order to delete service")
            }

        }

    }

    const cloneService = (id) => {

        const serviceToClone = projectServices.find(item => item.id === id)
        const newService = { ...serviceToClone, serviceComments: "Clone"}

        var item
        for (item = 0; item < newService.activities.length; item++) {

            newService.activities[item].activityId = uuidv4()

        }

        addForm(newService)

        setServiceAddedOrDeleted(true)

    }

    const deletionValidityCheck = async (id) => {

        //check here to make sure no invoices are allocated against the service
        //Get the timesheets again in case a timesheet entry has been made against this service without this page refreshing

        var serviceExistsInTimesheets = false

        await axios.get(process.env.REACT_APP_API_URL + "/timesheets_by_projectId/" + match.params.projectId)
        .then((res) => {
            if (res.data.filter(item => item.serviceId === id).length > 0) {
                serviceExistsInTimesheets = true
            }
        })
        .catch((err) => {
            console.log(err);
        })

        var invoice
        var onInvoice = false
        var newInvoices

        await axios.get(API_URL + '/invoices_by_project/' + match.params.projectId)
        .then((res) => {
            setInvoices(res.data.sort((item1, item2) => item1.invoiceNumber < item2.invoiceNumber ? -1 : item1.invoiceNumber > item2.invoiceNumber ? 1 : 0))
            newInvoices = res.data.sort((item1, item2) => item1.invoiceNumber < item2.invoiceNumber ? -1 : item1.invoiceNumber > item2.invoiceNumber ? 1 : 0)
        })
        .catch((err) => {
            console.log(err);
        })

        for (invoice = 0; invoice < newInvoices.length; invoice++) {
            if (newInvoices[invoice].invoiceLines.filter(item => item.serviceId === id).length > 0) {
                onInvoice = true      
            }
        }

        if (onInvoice === false && serviceExistsInTimesheets === false) {
            return true
        } else {
            return false
        }
    }

    const addToBillingPlan = async (id, service, fee, serviceManagerId, projectManagerId, clientToInvoice, clientToInvoiceContact, clientPO, serviceStatus) => {
        
        var newInvoices
        await axios.get(API_URL + '/invoices_by_project/' + match.params.projectId)
        .then((res) => {
            setInvoices(res.data.sort((item1, item2) => item1.invoiceNumber < item2.invoiceNumber ? -1 : item1.invoiceNumber > item2.invoiceNumber ? 1 : 0))
            newInvoices = res.data.sort((item1, item2) => item1.invoiceNumber < item2.invoiceNumber ? -1 : item1.invoiceNumber > item2.invoiceNumber ? 1 : 0)
        })
        .catch((err) => {
            console.log(err);
        }).finally(() => {
            setInvoicesLoading(false);
        });

        var invoiceNumber
        var invoiceDate
        var invoicePaid
        var invoiceContactId
        var invoiceIssued
        var invoiceNotes
        var clientId
        var draftInvoice
        var invoiceCustPo
        var clientAndClientPOCheck = false
        //var serviceStatus

        var newInvoiceListArray = []
        var a

        function formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        }

        //Is the serviceStatus Quote?
        if (serviceStatus.slice(0,5) === "Quote"){
            window.alert("This service line cannot be added to the billing plan because it's status is Quote")
        }else{

            // UNCOMMENT THIS WHEN READY:
            if (clientToInvoice === "" || clientToInvoiceContact === "") {
                window.alert("The 'client to invoice' and 'client contact' need to be set by the Finance Team before this service can be added to the billing plan")
                return
            }

            if (clients.find(item => item.id === clientToInvoice).requirePoForInvoicing === "YES" && clientPO === "") {
                window.alert("The client PO reference needs to be entered before this service can be added to the billing plan")
                return
            }

            if (newInvoices.filter((item) => item.invoiceNumber.substring(0, 1) === "D").length > 0) {
                invoiceNumber = Number(newInvoices.filter((item) => item.invoiceNumber.substring(0, 1) === "D").sort((item1, item2) => Number(item1.invoiceNumber.slice(1)) < Number(item2.invoiceNumber.slice(1)) ? -1 : Number(item1.invoiceNumber.slice(1)) > Number(item2.invoiceNumber.slice(1)) ? 1 : 0)[newInvoices.filter((item) => item.invoiceNumber.substring(0, 1) === "D").length - 1].invoiceNumber.slice(1))
                clientAndClientPOCheck = clientToInvoice === newInvoices.find(item => item.invoiceNumber === "D" + invoiceNumber).clientId && clientPO === newInvoices.find(item => item.invoiceNumber === "D" + invoiceNumber).invoiceCustPo && clientToInvoiceContact === newInvoices.find(item => item.invoiceNumber === "D" + invoiceNumber).invoiceContactId
            }

            //UNCOMMENT THIS WHEN READY: 
            if (newInvoices.filter((item) => item.invoiceNumber.substring(0, 1) === "D").length > 0 && clientAndClientPOCheck) {

                invoiceNumber = "D" + invoiceNumber

                invoiceDate = newInvoices.find(item => item.invoiceNumber === invoiceNumber).invoiceDate
                invoicePaid = newInvoices.find(item => item.invoiceNumber === invoiceNumber).invoicePaid
                invoiceContactId = newInvoices.find(item => item.invoiceNumber === invoiceNumber).invoiceContactId
                invoiceIssued = newInvoices.find(item => item.invoiceNumber === invoiceNumber).invoiceIssued
                invoiceNotes = newInvoices.find(item => item.invoiceNumber === invoiceNumber).invoiceNotes
                clientId = clientToInvoice
                draftInvoice = newInvoices.find(item => item.invoiceNumber === invoiceNumber).draftInvoice
                invoiceCustPo = clientPO
            
                if (invoicesSchedule.filter((invoiceItem) => invoiceItem.service === service).length > 0) {

                    for (a = 0; a < invoicesSchedule.filter((invoiceItem) => invoiceItem.service === service).length; a++) {
                        newInvoices.find(item => item.invoiceNumber === invoiceNumber).invoiceLines.push({ invoiceLineItemId: uuidv4(), serviceId: id, value: ((invoicesSchedule.filter((invoiceItem) => invoiceItem.service === service)[a].feePercentage) / 100) * fee, invoiceLineDescription: props.projectNumber + " - " + props.projectName + ": " + invoicesSchedule.filter((invoiceItem) => invoiceItem.service === service)[a].description, invoiceLineComments: "", invoiceLineStatus: "Amber", okToInvoice: "NO", serviceManager: serviceManagerId, projectManager: projectManagerId })
                    }

                } else {

                    newInvoices.find(item => item.invoiceNumber === invoiceNumber).invoiceLines.push({ invoiceLineItemId: uuidv4(), serviceId: id, value: fee, invoiceLineDescription: props.projectNumber + " - " + props.projectName + ": " + service + " - Fee due for completion of <ENTER WORK DETAILS HERE> <MMM-YY>", invoiceLineComments: "", invoiceLineStatus: "Amber", okToInvoice: "NO", serviceManager: serviceManagerId, projectManager: projectManagerId })

                }

                updateInvoice(newInvoices.find(item => item.invoiceNumber === invoiceNumber).id, { invoiceNumber, invoiceDate, invoicePaid, invoiceContactId, invoiceIssued, invoiceNotes, clientId, invoiceLines: newInvoices.find(item => item.invoiceNumber === invoiceNumber).invoiceLines, draftInvoice, projectId: match.params.projectId, invoiceCustPo })

            } else {

                // UNCOMMENT THIS WHEN READY:
                if (newInvoices.filter((item) => item.invoiceNumber.substring(0, 1) === "D").length > 0) {
                    invoiceNumber = invoiceNumber + 1
                    invoiceNumber = "D" + invoiceNumber
                } else {
                    invoiceNumber = "D1"
                }
            
                if (invoicesSchedule.filter((invoiceItem) => invoiceItem.service === service).length > 0) {
                    for (a = 0; a < invoicesSchedule.filter((invoiceItem) => invoiceItem.service === service).length; a++) {
                        newInvoiceListArray.push({ invoiceLineItemId: uuidv4(), serviceId: id, value: ((invoicesSchedule.filter((invoiceItem) => invoiceItem.service === service)[a].feePercentage) / 100) * fee, invoiceLineDescription: props.projectNumber + " - " + props.projectName + ": " + invoicesSchedule.filter((invoiceItem) => invoiceItem.service === service)[a].description + " - Fee due for completion of <ENTER WORK DETAILS HERE> <MMM-YY>", invoiceLineComments: "", invoiceLineStatus: "Amber", okToInvoice: "NO", serviceManager: serviceManagerId, projectManager: projectManagerId })
                    }
                } else {

                    newInvoiceListArray.push({ invoiceLineItemId: uuidv4(), serviceId: id, value: fee, invoiceLineDescription: props.projectNumber + " - " + props.projectName + ": " + service + " - Fee due for completion of <ENTER WORK DETAILS HERE> <MMM-YY>", invoiceLineComments: "", invoiceLineStatus: "Amber", okToInvoice: "NO", serviceManager: serviceManagerId, projectManager: projectManagerId })

                }

                const newInvoiceDate = new Date()
                newInvoiceDate.setMonth(newInvoiceDate.getMonth() + 1)
                // addInvoice({ invoiceNumber, invoiceDate: formatDate(newInvoiceDate), invoicePaid: "NO", invoiceContactId: props.projectClientContactId, invoiceIssued: "NO", invoiceNotes: "", clientId: props.clientId, invoiceLines: newInvoiceListArray, draftInvoice: "YES", projectId: match.params.projectId, invoiceClient: "", invoiceCustPo: "" })
                // UNCOMMENT THIS WHEN READY
                addInvoice({ invoiceNumber, invoiceDate: formatDate(newInvoiceDate), invoicePaid: "NO", invoiceContactId: clientToInvoiceContact, invoiceIssued: "NO", invoiceNotes: "", clientId: clientToInvoice, invoiceLines: newInvoiceListArray, draftInvoice: "YES", projectId: match.params.projectId, invoiceCustPo: clientPO })
            }

            setInvoiceLineAdded(true)
        }
    }

    const addInvoice = async (form) => {

        await axios.post(process.env.REACT_APP_API_URL + '/invoices', form)
    }

    const updateInvoice = async (id, form) => {

        await axios.put(process.env.REACT_APP_API_URL + '/invoices/' + id, form)
    }

    const aggregateServicesArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.service === val.service);
            if (index === -1) {
                acc.push({
                    service: val.service,
                    department: val.department
                });
            };
            return acc;
        }, []);
    };

    const aggregateDepartmentsArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.department === val.department);
            if (index === -1) {
                acc.push({
                    department: val.department
                });
            };
            return acc;
        }, []);
    };

    const aggregateWorkStagesArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.workStage === val.workStage);
            if (index === -1) {
                acc.push({
                    workStage: val.workStage
                });
            };
            return acc;
        }, []);
    };

    const aggregateDealNumbersArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.dealNumber === val.dealNumber);
            if (index === -1) {
                acc.push({
                    dealNumber: val.dealNumber,
                    dealName: val.dealName
                });
            };
            return acc;
        }, []);
    };

    const aggregateServiceStatusesArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.serviceStatus === val.serviceStatus);
            if (index === -1) {
                acc.push({
                    serviceStatus: val.serviceStatus
                });
            };
            return acc;
        }, []);
    };

    const aggregateServiceManagersArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.serviceManager === val.serviceManager);
            if (index === -1) {
                acc.push({
                    serviceManager: val.serviceManager,
                    serviceManagerName: users.find(user => user.id === val.serviceManager).firstName + ' ' + users.find(user => user.id === val.serviceManager).lastName
                });
            };
            return acc;
        }, []);
    };

    const aggregateCustomerPoArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.customerPo === val.customerPo);
            if (index === -1) {
                if (val.customerPo.length > 0) {
                    acc.push({
                        customerPo: val.customerPo
                    });
                }
            };
            return acc;
        }, []);
    };

    //JW - Get Built Environment Projects data
    useEffect(() => {

        axios.get(API_URL + '/projects/' + match.params.projectId)
            .then((res) => {
                setProjectSustainabilityTargets(res.data.projectSustainabilityTargets)
                setProjectLocation(res.data.projectLocation)
                setProjectDrawings(res.data.projectDrawings)
                setProjectUseClassOfBuilding(res.data.projectUseClassOfBuilding)
                setProjectType(res.data.projectType)
                setProjectGrossInternalArea(res.data.projectGrossInternalArea)
                setProjectCurrentRIBAStage(res.data.projectCurrentRIBAStage)
                setProjectProgrammeProvided(res.data.projectProgrammeProvided)
                //setProjectSecondaryUseClassOfBuilding(res.data.projectSecondaryUseClassOfBuilding)
                setProjectGrossInternalLandArea(res.data.projectGrossInternalLandArea)
                setProjectBuildingDataLastUpdated(res.data.projectBuildingDataLastUpdated)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });

    }, [match.params.projectId])

    const addService = async () => {

        if (service) {

            //Create list of activities
            var activitiesToAdd = []
            var activityItem
            var activityItemEstimatedHours
            var activityHourlyRate
            // var activityItemId = 1
            var a

            //JW - 22/11/2022
            //If the department for the service is 1-6
            // if (department.substring(0, 2) < 7){
            //     //Check if the Built Env fields have been filled-in for the project
            //         if (projectSustainabilityTargets.length === 0 || projectLocation.length === 0 || projectDrawings.length === 0 || projectUseClassOfBuilding.length === 0 || projectType.length === 0 || projectGrossInternalArea.length === 0 || projectCurrentRIBAStage.length === 0 || projectProgrammeProvided.length === 0 || projectGrossInternalLandArea.length === 0 || projectBuildingDataLastUpdated.length === 0) {
            //             //Data missing - show the modal
            //             setShowInfoModal(true);
            //         }else{
            //             //Data present - Add the service
            //             console.log("All Built Env data present, continue with the ADD")
            //             for (a = 0; a < departmentsServicesActivities.filter((activity) => activity.service === service && activity.workStage === workStage && activity.department === department).length; a++) {
            //                 activityItem = departmentsServicesActivities.filter((activity) => activity.service === service && activity.workStage === workStage && activity.department === department)[a].activity
            //                 activityItemEstimatedHours = Number(departmentsServicesActivities.filter((activity) => activity.service === service && activity.workStage === workStage && activity.department === department)[a].estimatedHours)
            //                 activityHourlyRate = Number(departmentsServicesActivities.filter((activity) => activity.service === service && activity.workStage === workStage && activity.department === department)[a].hourlyRate)
            //                 activitiesToAdd.push({ activityId: uuidv4(), activity: activityItem, estimatedHours: activityItemEstimatedHours, hourlyRate: activityHourlyRate, fee: activityItemEstimatedHours * activityHourlyRate, activityOwner, activityComplete: "NO", activityToBeInvoiced: "YES", contractAgreed: "", canIssueDeliverables: "", cost: 0, costCategory: "" })
            //             }
            //             addForm({ service, workStage, serviceManager, department, projectId: match.params.projectId, activities: activitiesToAdd, serviceStatus: "Quote", serviceComments: "", toBeDeleted: "NO", serviceCreatedDate: todaysDate, serviceLiveDate: todaysDate, serviceClosedDate: "", dealNumber: ""})
            //             setServiceAddedOrDeleted(true)
            //             setDepartment()
            //             setWorkStage()
            //             setService()
            //         }
            // }else{
            //Department is >7 so add the service
            for (a = 0; a < departmentsServicesActivities.filter((activity) => activity.service === service && activity.workStage === workStage && activity.department === department).length; a++) {
                activityItem = departmentsServicesActivities.filter((activity) => activity.service === service && activity.workStage === workStage && activity.department === department)[a].activity
                activityItemEstimatedHours = Number(departmentsServicesActivities.filter((activity) => activity.service === service && activity.workStage === workStage && activity.department === department)[a].estimatedHours)
                activityHourlyRate = Number(departmentsServicesActivities.filter((activity) => activity.service === service && activity.workStage === workStage && activity.department === department)[a].hourlyRate)
                activitiesToAdd.push({ activityId: uuidv4(), activity: activityItem, estimatedHours: activityItemEstimatedHours, hourlyRate: activityHourlyRate, fee: activityItemEstimatedHours * activityHourlyRate, activityOwner, activityComplete: "NO", activityToBeInvoiced: "YES", contractAgreed: "", canIssueDeliverables: "", cost: 0, costCategory: "" })
            }
            await addForm({ service, workStage, serviceManager, department, projectId: match.params.projectId, activities: activitiesToAdd, serviceStatus: "Quote-qualified", serviceComments: "", toBeDeleted: "NO", serviceCreatedDate: todaysDate, serviceLiveDate: "", serviceClosedDate: "", dealNumber: props.projectDealNumberSeed, dealName: props.projectDealNumberSeedName, lineItemNumber:"", customerPo:"", changedFlag: true, serviceInvoiceClient:"", serviceInvoiceClientContact:"" })
            setServiceAddedOrDeleted(true)
            setDepartment()
            setWorkStage()
            setService()
            //}
        }
    }

    const arrayToCsv = (data) => {

        var j, k
        var serviceCommentTemp = ""
        var scopeOfWorksTemp = ""

        var dataToExport = []

        for (j = 0; j < data.length; j++) {

            if (data[j].serviceComments.indexOf("/") === -1) {
                scopeOfWorksTemp = ""
                serviceCommentTemp = data[j].serviceComments
            } else {
                scopeOfWorksTemp = (data[j].serviceComments.slice(data[j].serviceComments.indexOf("/") + 1, data[j].serviceComments.length)).trim()
                serviceCommentTemp = (data[j].serviceComments.slice(0, data[j].serviceComments.indexOf("/"))).trim()
            }

            dataToExport.push({
            projectNumber: props.projectNumber,
            projectName: props.projectName,
            projectClient: data[j].serviceInvoiceClient !== "" ? clients.find(client => client.id === data[j].serviceInvoiceClient).clientName : "",
            dealName: data[j].dealName,
            department: data[j].department,
            workStage: data[j].workStage,
            service: data[j].service,
            serviceComments: serviceCommentTemp,
            scopeOfWorks: scopeOfWorksTemp,
            totalFee: data[j].activities.filter(item => !item.activity.includes("3rd Party Fee")).map(item => item.fee).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2),
            consultancyFees: data[j].activities.filter(item => !item.activity.includes("3rd Party Fee")).map(item => item.fee).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2) - data[j].activities.filter(item => !item.activity.includes("3rd Party Fee")).map(item => item.cost).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2),
            costs: data[j].activities.filter(item => !item.activity.includes("3rd Party Fee")).map(item => item.cost).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2),
            serviceStatus: data[j].serviceStatus,

            })

            if (data[j].activities.filter(item => item.activity.includes("3rd Party Fee")).length > 0) {

                console.log("includes a 3rd Party Fee")

                for (k = 0; k < data[j].activities.filter(item => item.activity.includes("3rd Party Fee")).length; k++) {

                    console.log(data[j].activities.filter(item => item.activity.includes("3rd Party Fee"))[k].activity)

                    dataToExport.push({
                        projectNumber: props.projectNumber,
                        projectName: props.projectName,
                        projectClient: data[j].serviceInvoiceClient !== "" ? clients.find(client => client.id === data[j].serviceInvoiceClient).clientName : "",
                        dealName: data[j].dealName,
                        department: data[j].department,
                        workStage: data[j].workStage,
                        service: data[j].service,
                        serviceComments: serviceCommentTemp + ": " + data[j].activities.filter(item => item.activity.includes("3rd Party Fee"))[k].activity.substring(data[j].activities.filter(item => item.activity.includes("3rd Party Fee"))[k].activity.indexOf("3rd Party Fee")+15),
                        scopeOfWorks: scopeOfWorksTemp,
                        totalFee: data[j].activities.filter(item => item.activity.includes("3rd Party Fee"))[k].cost.toFixed(2),
                        consultancyFees: 0,
                        costs: data[j].activities.filter(item => item.activity.includes("3rd Party Fee"))[k].cost.toFixed(2),
                        serviceStatus: data[j].serviceStatus,
            
                        })

                    }
                }
            
        }

        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: 'Services',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };

        const csvExporter = new ExportToCsv(options);

        // dataToExport.forEach(a => delete a.activities)

        csvExporter.generateCsv(dataToExport.filter(item => item.serviceStatus.slice(0,5) === "Quote"))

    }

    const [file, setFile] = useState()
    const fileReader = new FileReader()

    const handleOnChange = (e) => {
        setFile(e.target.files[0]);
    };

    const expandToggle = (e) => {

        var projectServiceIdsToExpandTemp = [...projectServiceIdsToExpand]

        if (projectServiceIdsToExpand.filter(item => item.id === e).length > 0) {
            projectServiceIdsToExpandTemp = projectServiceIdsToExpandTemp.filter(item => item.id !== e)
        }

        if (projectServiceIdsToExpand.filter(item => item.id === e).length === 0) {
            projectServiceIdsToExpandTemp.push({id: e})
        }

        setProjectServiceIdsToExpand(projectServiceIdsToExpandTemp)

        if (projectServiceIdsToExpandTemp.length === 1 || projectServiceIdsToExpandTemp.length === 0) {
            scrollTop(e)
        }
    };

    const scrollTop = (id) => {

        //This should scroll to the top of the page
        document.getElementById(id).scrollIntoView(true)
        
    }


    const handleOnSubmit = (e) => {
        e.preventDefault();

        if (file) {
            fileReader.onload = function (organisation) {
                const text = organisation.target.result;
                var re = /(?:\.([^.]+))?$/;
                if (re.exec(file.name)[0]===".csv"){
                    csvFileToArray(text);
                }else if (re.exec(file.name)[0]===".xls" || re.exec(file.name)[0]===".xlsx"){
                    //xlsFileToArray(text);
                    const reader = new FileReader();
                    reader.readAsBinaryString(file);
                    reader.onload = (e) => {
                        const data = e.target.result;
                        const workbook = XLSX.read(data, { type: "binary" });
                        let sheetName = 0
                        if (workbook.SheetNames.length > 1){
                            sheetName = workbook.SheetNames[workbook.SheetNames.findIndex((element) => element === 'Output')];
                            if (sheetName===undefined){
                                window.alert("Output sheet not found")
                                return
                            }
                        }else{
                            sheetName=workbook.SheetNames[0]
                        }
                        const sheet = workbook.Sheets[sheetName];
                        const parsedData = XLSX.utils.sheet_to_json(sheet);
                        setXlData(parsedData);

                        ////////////////////////////////////////////////////////////////////////////////////
                        //Check the column headings
                        var dataCheck = 0
                        if (sheet.A1.h!=="Department"){dataCheck++}
                        if (sheet.B1.h!=="Service"){dataCheck++}
                        if (sheet.C1.h!=="Work Stage"){dataCheck++}
                        if (sheet.D1.h!=="Comments"){dataCheck++}
                        if (sheet.E1.h!=="Activity"){dataCheck++}
                        if (sheet.F1.h!=="Hours"){dataCheck++}                        
                        if (sheet.G1.h!=="3rd Party Cost"){dataCheck++}                       
                        if (sheet.H1.h!=="Cost Category"){dataCheck++}

                        if (dataCheck>0){
                            window.alert("The sheet is not in the correct format.  Column titles ARE case sensitive and should be: \n\n"+
                            "Department\n"+
                            "Service\n"+
                            "Work Stage\n"+
                            "Comments\n"+
                            "Activity\n"+
                            "Hours\n"+
                            "3rd Party Cost\n"+
                            "Cost Category\n\n"+
                            "Please review the sheet and try again"
                            )
                            return
                        }

                        var i, j
                        var allFees = []
                        var service
                        var department
                        var workStage
                        var departmentAndServiceAndWorkStage
                        var hours
                        var thirdPartyCost
                        var costCategory
                        var comments
                        var array = parsedData.filter(item => ((!containsNumber(item.Hours) && containsNumber(item['3rd Party Cost']))) || containsNumber(item.Hours))

                        //Get an aggregated list of the services first (including department, service, workStage)
                        // if (array.length===0){
                        //     window.alert("There is no data to pull in.  Please review the spreadsheet and ensure that all columns are ")
                        // }
                        for (j = 0; j < array.length; j++) {

                            service = array[j].Service
                            department = array[j].Department
                            workStage = array[j]["Work Stage"]
                            departmentAndServiceAndWorkStage  = service + department + workStage
                            hours = array[j].Hours
                            thirdPartyCost = array[j]['3rd Party Cost']
                            costCategory = array[j]['Cost Category'] === undefined ? "" : array[j]['Cost Category']
                            comments = array[j].Comments === undefined ? "" : array[j].Comments

                            allFees.push({
                                departmentAndServiceAndWorkStage,
                                department,
                                service,
                                workStage,
                                hours,
                                thirdPartyCost,
                                costCategory,
                                comments
                            })
                        }

                        var result = [];
                        allFees.reduce(function (res, value) {
                            if (!res[value.departmentAndServiceAndWorkStage]) {
                                res[value.departmentAndServiceAndWorkStage] = {
                                    departmentAndServiceAndWorkStage: value.departmentAndServiceAndWorkStage,
                                    department: value.department,
                                    service: value.service,
                                    workStage: value.workStage,
                                    hours: value.hours,
                                    thirdPartyCost: value.thirdPartyCost,
                                    costCategory: value.costCategory,
                                    comments: value.comments,
                                };
                                result.push(res[value.departmentAndServiceAndWorkStage]);
                            }
                            return res;
                        }, {});

                        var listOfServices = result
                        var activitiesListForThatService = []

                        var activitiesToAdd = []
                        var errCount = 0
                        var errMessage = ""

                        for (i = 0; i < listOfServices.length; i++) {

                            activitiesToAdd = []
                            activitiesListForThatService = array.filter(item => item.Service === listOfServices[i].service && item.Department === listOfServices[i].department && item["Work Stage"] === listOfServices[i].workStage)

                            var varDep = ""
                            var varSvc = ""
                            var varAct = ""
                            var varCostCategory = ""

                            for (j = 0; j < activitiesListForThatService.length; j++) {

                                //Check that all activites are in departments_services_activities in order to grab the fees
                                varDep = listOfServices[i].department
                                varSvc = listOfServices[i].service
                                varAct = activitiesListForThatService[j].Activity

                                if (!departmentsServicesActivities.find(item => item.department.trim().toLowerCase() ===varDep.trim().toLowerCase() && item.service.trim().toLowerCase()===varSvc.trim().toLowerCase() && item.activity.trim().toLowerCase()===varAct.trim().toLowerCase())){
                                    errCount ++
                                    errMessage = errMessage + "\n\nDepartment: " + varDep + "\nService: " + varSvc + "\nActivity: " + varAct
                                }

                            }

                        }

                        if (errCount>0){
                            window.alert("Error - Activites on the import do not have an hourly rates set-up in Versatility.  Please add this information and try again.  It has not been added to the project." + errMessage)
                        }else{

                            for (i = 0; i < listOfServices.length; i++) {

                                activitiesListForThatService = array.filter(item => item.Service === listOfServices[i].service && item.Department === listOfServices[i].department && item["Work Stage"] === listOfServices[i].workStage)
                                activitiesToAdd = []

                                for (j = 0; j < activitiesListForThatService.length; j++) {

                                    //Grab the fees from departments_services_activities
                                    var varRate = 0
                                    var varFee = 0
                                    var varCost=0
                                    varDep = listOfServices[i].department
                                    varSvc = listOfServices[i].service
                                    varAct = activitiesListForThatService[j].Activity
                                    varCostCategory = activitiesListForThatService[j]["Cost Category"] === undefined ? "" : activitiesListForThatService[j]["Cost Category"]
                                    var varHrs
                                    if (containsNumber(activitiesListForThatService[j].Hours)) {
                                        varHrs = activitiesListForThatService[j].Hours
                                    }else{
                                        varHrs = 0
                                    }

                                    varRate = Number(departmentsServicesActivities.find(item => item.department.trim().toLowerCase()===varDep.trim().toLowerCase() && item.service.trim().toLowerCase()===varSvc.trim().toLowerCase() && item.activity.trim().toLowerCase()===varAct.trim().toLowerCase()).hourlyRate)
                                    if (varAct.includes("3rd Party Fee") && containsNumber(activitiesListForThatService[j]["3rd Party Cost"])) {
                                        varCost = Number(activitiesListForThatService[j]["3rd Party Cost"])
                                        varFee = Number(activitiesListForThatService[j]["3rd Party Cost"])
                                    }else if (containsNumber(activitiesListForThatService[j]["3rd Party Cost"]) && varHrs===0) {
                                        varCost = Number(activitiesListForThatService[j]["3rd Party Cost"])
                                        varFee = Number(activitiesListForThatService[j]["3rd Party Cost"])
                                    }else{
                                        varFee = activitiesListForThatService[j].Hours * varRate
                                    }
                                    
                                    activitiesToAdd.push({activityId: uuidv4(), activity: varAct, activityOwner: activityOwner, activityComplete: "NO", activityToBeInvoiced: "YES", canIssueDeliverables: "", contractAgreed: "", estimatedHours: varHrs, fee: varFee, hourlyRate: varRate, cost: varCost, costCategory: varCostCategory})

                                }
                                addForm({workStage: listOfServices[i].workStage, toBeDeleted: "NO", serviceCreatedDate: todaysDate, serviceLiveDate: "", serviceClosedDate: "", projectId: match.params.projectId, activities: activitiesToAdd, department: listOfServices[i].department, service: listOfServices[i].service, serviceManager: serviceManager, serviceComments: listOfServices[i].comments, serviceStatus: "Quote-qualified", dealNumber: "", dealName: "", customerPo: "", lineItemNumber: "", changedFlag: false, serviceInvoiceClient: "", serviceInvoiceClientContact:""})
                            }

                            setServiceAddedOrDeleted(true)
                            setDepartment()
                            setWorkStage()
                            setService()

                            window.alert("Data imported")
                        }

                    //     ////////////////////////////////////////////////////////////////////////////////////

                    };
                }else{
                    window.alert("Please choose a csv, xls or xlsx file type")
                }
            };

            fileReader.readAsText(file);
        }
    };

    const csvFileToArray = async string => {

        const csvHeader = ["department", "service", "workStage", "comments", "activity", "hours", "thirdPartyCost", "costCategory"]
        const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

        //drop the last line (blank)
        csvRows.pop()

        var columns 

        const array1 = csvRows.map(i => {

            //const values = i.split(",")

            const values = i.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/)

            columns = values.length
            
            var field

            for (field = 0; field < values.length; field++) {

                values[field] = values[field].replace(/^"|"$/g, '')
                values[field] = values[field].replace(/""/g, '"')
                values[field] = values[field].replace(/^"|"$/, '')
                values[field] = values[field].replace("\r", '')

                //If the last field has any commas then a traling quotation mark (") still remains before the end of the line
                //This code checks for this and removes the quotation mark (")

                if (values[field].slice(values[field].length - 2, values[field].length - 1) === '"') {
                    values[field] = values[field].slice(0, values[field].length - 2)
                }
            }

            const obj = csvHeader.reduce((object, header, index) => {
                object[header] = values[index];
                return object;
            }, {});
            return obj;

        });

        //Simple column count to check if (at least) the CSV has the right number of columns
        if (columns !== 8){
            window.alert("The CSV you are trying to import does not appear to be in the correct format.  Please review the file or speak to the Sys Dev team for assistance.")
            return
        }

        var itemIndex

        var array = array1.filter(item => (!containsNumber(item.hours) && containsNumber(item.thirdPartyCost)) || containsNumber(item.hours))

        for (itemIndex = 0; itemIndex < array.length; itemIndex++) {
            array[itemIndex].hours = Number(array[itemIndex].hours)
        }

        var i, j
        var allFees = []
        var service
        var department
        var workStage
        var departmentAndServiceAndWorkStage
        var hours
        var thirdPartyCost
        var costCategory
        var comments

        //Get an aggregated list of the services first (including department, service, workStage)

        for (j = 0; j < array.length; j++) {

            service = array[j].service
            department = array[j].department
            workStage = array[j].workStage
            departmentAndServiceAndWorkStage  = service + department + workStage
            hours = array[j].hours
            thirdPartyCost = array[j].thirdPartyCost
            costCategory = array[j].costCategory
            comments = array[j].comments

            if (containsNumber(hours) || (!containsNumber(hours) && containsNumber(thirdPartyCost))){
                allFees.push({
                    departmentAndServiceAndWorkStage,
                    department,
                    service,
                    workStage,
                    hours,
                    thirdPartyCost,
                    costCategory,
                    comments
                })
            }            
        }

        var result = [];
        allFees.reduce(function (res, value) {
            if (!res[value.departmentAndServiceAndWorkStage]) {
                res[value.departmentAndServiceAndWorkStage] = {
                    departmentAndServiceAndWorkStage: value.departmentAndServiceAndWorkStage,
                    department: value.department,
                    service: value.service,
                    workStage: value.workStage,
                    hours: value.hours,
                    thirdPartyCost: value.thirdPartyCost,
                    costCategory: value.costCategory,
                    comments: value.comments,
                };
                result.push(res[value.departmentAndServiceAndWorkStage]);
            }
            return res;
        }, {});

        var listOfServices = result
        var activitiesListForThatService = []

        var activitiesToAdd = []
        var errCount = 0
        var errMessage = ""

        for (i = 0; i < listOfServices.length; i++) {

            activitiesToAdd = []
            activitiesListForThatService = array.filter(item => item.service === listOfServices[i].service && item.department === listOfServices[i].department && item.workStage === listOfServices[i].workStage)

            var varDep = ""
            var varSvc = ""
            var varAct = ""

            for (j = 0; j < activitiesListForThatService.length; j++) {

                //Check that all activites are in departments_services_activities in order to grab the fees
                varDep = listOfServices[i].department
                varSvc = listOfServices[i].service
                varAct = activitiesListForThatService[j].activity
                if (!departmentsServicesActivities.find(item => item.department.trim().toLowerCase() ===varDep.trim().toLowerCase() && item.service.trim().toLowerCase()===varSvc.trim().toLowerCase() && item.activity.trim().toLowerCase()===varAct.trim().toLowerCase())){
                    errCount ++
                    errMessage = errMessage + "\n\nDepartment: " + varDep + "\nService: " + varSvc + "\nActivity: " + varAct
                }

            }

        }

        if (errCount>0){
            window.alert("Error - Activites on the import do not have an hourly rates set-up in Versatility.  Please add this information and try again.  It has not been added to the project." + errMessage)
        }else{

            for (i = 0; i < listOfServices.length; i++) {

                activitiesListForThatService = array.filter(item => item.service === listOfServices[i].service && item.department === listOfServices[i].department && item.workStage === listOfServices[i].workStage)
                activitiesToAdd = []

                for (j = 0; j < activitiesListForThatService.length; j++) {

                    //Grab the fees from departments_services_activities
                    var varRate = 0
                    var varFee = 0
                    varDep = listOfServices[i].department
                    varSvc = listOfServices[i].service
                    varAct = activitiesListForThatService[j].activity
                    var varHrs
                    if (containsNumber(activitiesListForThatService[j].hours)) {
                        varHrs = activitiesListForThatService[j].hours
                    }else{
                        varHrs = 0
                    }

                    varRate = Number(departmentsServicesActivities.find(item => item.department.trim().toLowerCase()===varDep.trim().toLowerCase() && item.service.trim().toLowerCase()===varSvc.trim().toLowerCase() && item.activity.trim().toLowerCase()===varAct.trim().toLowerCase()).hourlyRate)
                    if (varAct.includes("3rd Party Fee") && containsNumber(activitiesListForThatService[j].thirdPartyCost)) {
                        varFee = Number(activitiesListForThatService[j].thirdPartyCost)
                    }else{
                        varFee = activitiesListForThatService[j].hours * varRate
                    }
                    
                    activitiesToAdd.push({activityId: uuidv4(), activity: activitiesListForThatService[j].activity, activityOwner: activityOwner, activityComplete: "NO", activityToBeInvoiced: "YES", canIssueDeliverables: "", contractAgreed: "", estimatedHours: varHrs, fee: varFee, hourlyRate: varRate, cost: 0, costCategory: activitiesListForThatService[j].costCategory})

                }
                addForm({workStage: listOfServices[i].workStage, toBeDeleted: "NO", serviceCreatedDate: todaysDate, serviceLiveDate: "", serviceClosedDate: "", projectId: match.params.projectId, activities: activitiesToAdd, department: listOfServices[i].department, service: listOfServices[i].service, serviceManager: serviceManager, serviceComments: listOfServices[i].comments, serviceStatus: "Quote-qualified", dealNumber: "", dealName: "", customerPo: "", lineItemNumber: "", changedFlag: false, serviceInvoiceClient: "", serviceInvoiceClientContact:""})
            }

            setServiceAddedOrDeleted(true)
            setDepartment()
            setWorkStage()
            setService()

            window.alert("Data imported")
        }
    };

    const xlsFileToArray = async string => {
        console.log("xls Processing")
    }

    const updateForm = async (form, id) => {
        await axios.put(process.env.REACT_APP_API_URL + '/projects_services/' + id, form, { headers: authHeader() })
    }

    const addForm = async (form) => {
        await axios.post(process.env.REACT_APP_API_URL + '/projects_services', form, { headers: authHeader() })
    }

    const hubSpotSync = async () => {

        /////////////////////////////////////////////////////////////////
        //Allocate any service lines with no deal number to the deal seed
        /////////////////////////////////////////////////////////////////
        // if (projectServices.filter(item => item.dealNumber==="").length!==0){
        //     const servicesNoHSNumber = [...projectServicesFiltered.filter(item => item.dealNumber==="")]
        //     servicesNoHSNumber.forEach(async service => {
        //         projectServicesFiltered.find(item => item.id === service.id).dealNumber = props.projectDealNumberSeed
        //         const dummyArray = [...projectServicesFiltered]
        //         setProjectServicesFiltered(dummyArray)
        //         await axios.put(process.env.REACT_APP_API_URL + '/projects_services/' + service.id, {...service, dealNumber: props.projectDealNumberSeed})
        //     });
        // }

        ///////////////////////////////////////////////////////////////
        //Get the list of HS dealNumbers that we'll need to loop through
        ///////////////////////////////////////////////////////////////
        const servicesWithHSNumber = []
        projectServicesFiltered.filter(item => item.dealNumber!=="").forEach(item => {
            if (!servicesWithHSNumber.includes(item.dealNumber)){
                servicesWithHSNumber.push(item.dealNumber)
            }
        });

        /////////////////////////////////////////////////////////
        //Loop through each HS deal
        /////////////////////////////////////////////////////////
        servicesWithHSNumber.forEach(async dealNumber => {

            /////////////////////////////////////////////////////////////////////////////////////////////
            //Check that the deal on HS isn't closed.  If it is, don't update anything and post an error
            //
            //closed won = 104658676
            //closed lost = 104658677
            //closed pending = 110741742
            //
            /////////////////////////////////////////////////////////////////////////////////////////////
            await axios.get(process.env.REACT_APP_API_URL + '/hubspotDeals_onedata/'+dealNumber)
            .then(async (res) => {
                if (res.data.properties.dealstage==="104658676" || res.data.properties.dealstage==="104658677" || res.data.properties.dealstage==="110741742"){
                    window.alert("Deal " + dealNumber + " has been marked as closed on Hubspot.  You cannot sync the data from Versatility.")
                }else{                    
                    /////////////////////////////////////////////////////////
                    //Get all associations in hubspot for the current deal ID
                    /////////////////////////////////////////////////////////
                    await axios.get(process.env.REACT_APP_API_URL + '/hubspotGetAssociations_by_dealId/'+dealNumber)
                    .then((res) => {
                        
                        if (res.data.associations){
                            ////////////////////////////////////////////
                            //Remove any existing line items in HS via the useEffect
                            ////////////////////////////////////////////
                            setHubSpotLineItems(res.data.associations["line items"].results)
                        }

                        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        //If there are services selected for this deal then loop through the selected services & add them to HS and the running total
                        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        let totalFee = 0
                        const serviceLines = [...projectServices.filter(service => service.dealNumber===dealNumber && service.serviceStatus!=="Closed-cancelled")]
                        if (serviceLines.length!==0){
                            serviceLines.forEach(async service => {
                                totalFee += parseFloat(service.activities.map(item => item.fee).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2))

                                ////////////////////////////////////////////
                                //Check for 3rd Party Fees
                                ////////////////////////////////////////////
                                const varActivities =[...service.activities]
                                let thirdPartyFeeActivity = ""
                                let thirdPartyFee = 0
                                let totalThirdPartyFee = 0
                                let serviceDescription = ""

                                if (service.serviceComments === "") {
                                    serviceDescription = service.service
                                } else {
                                    serviceDescription = service.serviceComments
                                }

                                if (serviceDescription.indexOf("/") !== -1) {
                                    serviceDescription = (serviceDescription.slice(0, serviceDescription.indexOf("/"))).trim()
                                }

                                varActivities.forEach(async activity => {
                                    if (activity.activity.includes("3rd Party Fee")){
                                        thirdPartyFeeActivity=activity.activity.substring(activity.activity.indexOf("3rd Party Fee")+15)

                                        thirdPartyFee = Number(activity.fee)
                                        totalThirdPartyFee = totalThirdPartyFee + thirdPartyFee

                                        ///////////////////////////////////////////////////////////////
                                        //Add line item and associate it to the deal for 3rd Party Fees
                                        ///////////////////////////////////////////////////////////////
                                        await axios.post(process.env.REACT_APP_API_URL + '/hubspotLineItems', {dealId:dealNumber, service: serviceDescription + ": " + thirdPartyFeeActivity, price: Number(activity.fee).toFixed(2)})
                                        
                                    }   
                                
                                });

                                console.log(serviceDescription)
                                
                                ////////////////////////////////////////////
                                //Add line item and associate it to the deal
                                ////////////////////////////////////////////
                                await axios.post(process.env.REACT_APP_API_URL + '/hubspotLineItems', {dealId:dealNumber, service:serviceDescription, price:service.activities.map(item => item.fee).reduce((prev, curr) => Number(prev) + Number(curr), 0)-totalThirdPartyFee.toFixed(2)})
                                
                            });

                            //////////////////////////////////////////
                            //Update the total deal value in hubspot
                            //////////////////////////////////////////
                            axios.patch(process.env.REACT_APP_API_URL + '/hubspotDeals_onedata/'+dealNumber, {amount:totalFee})

                        }

                    })
                    .catch((err) => {
                        console.log(err);
                    }).finally(() => {
                        setLoading(false);
                    });
                    //Data synced
                    window.alert("HubSpot sync complete for deal "+ dealNumber +".  Please review HubSpot to verfiy the data.")
                }
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });

        });

        arrayToCsv(projectServicesFiltered)

    }

    useEffect(() => {

        //console.log("Remove Associations:")
        if (hubSpotLineItems.length>0){
            
            hubSpotLineItems.forEach(async lineItem => {
                ////////////////////////////////////////////////////
                //Delete the line_items on the Deal
                ////////////////////////////////////////////////////                
                await axios.delete(process.env.REACT_APP_API_URL + '/hubspotLineItems_onedata/'+lineItem.id)
                
            });
        }

    }, [hubSpotLineItems])

    useEffect(() => {

        axios.get(API_URL + '/invoices_schedule')
            .then((res) => {
                setInvoicesSchedule(res.data)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setInvoicesScheduleLoading(false);
            });

        setUsers(props.users.sort((item1, item2) => item1.firstName + item1.lastName < item2.firstName + item2.lastName ? -1 : 0))
        setActivityOwner(props.users[0].id)
        setServiceManager(props.users[0].id)

        if (props.billingCompany === "All") {

            setProjectServices(props.services.filter((item) => item.projectId === match.params.projectId && item.toBeDeleted === "NO").sort((item1, item2) => item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : item1.serviceComments < item2.serviceComments ? -1 : item1.serviceComments > item2.serviceComments ? 1 : 0))
            setProjectServicesFiltered(props.services.filter((item) => item.projectId === match.params.projectId && item.toBeDeleted === "NO").sort((item1, item2) => item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : item1.serviceComments < item2.serviceComments ? -1 : item1.serviceComments > item2.serviceComments ? 1 : 0))

        }

        if (props.billingCompany === "Eight Versa") {

            setProjectServices(props.services.filter((item) => item.projectId === match.params.projectId && item.toBeDeleted === "NO").filter((item) => item.department.slice(0,2) !== "20").sort((item1, item2) => item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : item1.serviceComments < item2.serviceComments ? -1 : item1.serviceComments > item2.serviceComments ? 1 : 0))
            setProjectServicesFiltered(props.services.filter((item) => item.projectId === match.params.projectId && item.toBeDeleted === "NO").filter((item) => item.department.slice(0,2) !== "20").sort((item1, item2) => item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : item1.serviceComments < item2.serviceComments ? -1 : item1.serviceComments > item2.serviceComments ? 1 : 0))

        }

        if (props.billingCompany === "NCS") {

            setProjectServices(props.services.filter((item) => item.projectId === match.params.projectId && item.toBeDeleted === "NO").filter((item) => item.department.slice(0,2) === "20").sort((item1, item2) => item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : item1.serviceComments < item2.serviceComments ? -1 : item1.serviceComments > item2.serviceComments ? 1 : 0))
            setProjectServicesFiltered(props.services.filter((item) => item.projectId === match.params.projectId && item.toBeDeleted === "NO").filter((item) => item.department.slice(0,2) === "20").sort((item1, item2) => item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : item1.serviceComments < item2.serviceComments ? -1 : item1.serviceComments > item2.serviceComments ? 1 : 0))

        }

        setInvoiceLineAdded(false)

        axios.get(API_URL + '/departments_services_activities')
            .then((res) => {

                setDepartmentsServicesActivities(res.data)
                setWorkStagesList(aggregateWorkStagesArray(res.data).sort((item1, item2) => item1.workStage < item2.workStage ? -1 : item1.workStage > item2.workStage ? 1 : 0))
                setServicesList(aggregateServicesArray(res.data).sort((item1, item2) => item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))
                setDepartmentsList(aggregateDepartmentsArray(res.data).sort((item1, item2) => item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : 0))

            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setDepartmentsServicesActivitiesLoading(false);
            });

        const newDate = new Date()

        setTodaysDate(formatDate(newDate))

    }, [match.params.projectId, props.billingCompany, props.services, props.users, API_URL])

    useEffect(() => {

        axios.get(API_URL + '/projects_services_by_projectId/' + match.params.projectId, { headers: authHeader() })
        .then((response) => {

            if (props.billingCompany === "All") {

                setProjectServices(response.data.filter((item) => item.projectId === match.params.projectId && item.toBeDeleted === "NO").sort((item1, item2) => item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : item1.serviceComments < item2.serviceComments ? -1 : item1.serviceComments > item2.serviceComments ? 1 : 0))
                setProjectServicesFiltered(response.data.filter((item) => item.projectId === match.params.projectId && item.toBeDeleted === "NO").sort((item1, item2) => item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : item1.serviceComments < item2.serviceComments ? -1 : item1.serviceComments > item2.serviceComments ? 1 : 0))
    
            }
    
            if (props.billingCompany === "Eight Versa") {
    
                setProjectServices(response.data.filter((item) => item.projectId === match.params.projectId && item.toBeDeleted === "NO").filter((item) => item.department.slice(0,2) !== "20").sort((item1, item2) => item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : item1.serviceComments < item2.serviceComments ? -1 : item1.serviceComments > item2.serviceComments ? 1 : 0))
                setProjectServicesFiltered(response.data.filter((item) => item.projectId === match.params.projectId && item.toBeDeleted === "NO").filter((item) => item.department.slice(0,2) !== "20").sort((item1, item2) => item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : item1.serviceComments < item2.serviceComments ? -1 : item1.serviceComments > item2.serviceComments ? 1 : 0))
    
            }
    
            if (props.billingCompany === "NCS") {
    
                setProjectServices(response.data.filter((item) => item.projectId === match.params.projectId && item.toBeDeleted === "NO").filter((item) => item.department.slice(0,2) === "20").sort((item1, item2) => item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : item1.serviceComments < item2.serviceComments ? -1 : item1.serviceComments > item2.serviceComments ? 1 : 0))
                setProjectServicesFiltered(response.data.filter((item) => item.projectId === match.params.projectId && item.toBeDeleted === "NO").filter((item) => item.department.slice(0,2) === "20").sort((item1, item2) => item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : item1.serviceComments < item2.serviceComments ? -1 : item1.serviceComments > item2.serviceComments ? 1 : 0))
    
            }

            props.updateServices(response.data.filter((item) => item.projectId === match.params.projectId && item.toBeDeleted === "NO").sort((item1, item2) => item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : item1.serviceComments < item2.serviceComments ? -1 : item1.serviceComments > item2.serviceComments ? 1 : 0))

            // setProjectServices(response.data.filter((item) => item.toBeDeleted === "NO").sort((item1, item2) => item1.serviceStatus < item2.serviceStatus ? -1 : item1.serviceStatus > item2.serviceStatus ? 1 : item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.workStage < item2.workStage ? -1 : item1.workStage > item2.workStage ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : item1.serviceComments < item2.serviceComments ? -1 : item1.serviceComments > item2.serviceComments ? 1 : 0))
            // setProjectServicesFiltered(response.data.filter((item) => item.toBeDeleted === "NO").sort((item1, item2) => item1.serviceStatus < item2.serviceStatus ? -1 : item1.serviceStatus > item2.serviceStatus ? 1 : item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : item1.workStage < item2.workStage ? -1 : item1.workStage > item2.workStage ? 1 : item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : item1.serviceComments < item2.serviceComments ? -1 : item1.serviceComments > item2.serviceComments ? 1 : 0))
        
        },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                console.log(_content);
                setRedirecting(true)
            })
        .catch((err) => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
        });

        axios.get(API_URL + '/invoices_by_project/' + match.params.projectId)
            .then((res) => {
                setInvoices(res.data.sort((item1, item2) => item1.invoiceNumber < item2.invoiceNumber ? -1 : item1.invoiceNumber > item2.invoiceNumber ? 1 : 0))
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setInvoicesLoading(false);
            });

        setTimesheets(props.timesheetData)
        setClients(props.clients.sort((item1, item2) => item1.clientName + item1.clientName < item2.clientName + item2.clientName ? -1 : 0))
        setContacts(props.contacts.sort((item1, item2) => item1.contactName + item1.contactName < item2.contactName + item2.contactName ? -1 : 0))
        setInvoiceLineAdded(false)
        setServiceAddedOrDeleted(false)

    }, [match.params.projectId, invoiceLineAdded, serviceAddedOrDeleted])

    useEffect(() => {

        setServicesList(aggregateServicesArray(departmentsServicesActivities.filter((service) => service.department === department && service.workStage === workStage)).sort((item1, item2) => item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0))

        setWorkStagesList(aggregateWorkStagesArray(departmentsServicesActivities.filter((service) => service.department === department)).sort((item1, item2) => item1.workStage < item2.workStage ? -1 : item1.workStage > item2.workStage ? 1 : 0))

        setActivityOwner(serviceManager)

    }, [department, workStage, departmentsServicesActivities, serviceManager])

    useEffect(() => {

        setProjectServicesFiltered(projectServices)

        if (selectedService !== "All") {
            setProjectServicesFiltered(servicesFiltered => servicesFiltered.filter((item) => item.service === selectedService.value))
        }

        if (selectedDepartment !== "All") {
            setProjectServicesFiltered(servicesFiltered => servicesFiltered.filter((item) => item.department === selectedDepartment.value))
        }

        if (selectedWorkStage !== "All") {
            setProjectServicesFiltered(servicesFiltered => servicesFiltered.filter((item) => item.workStage === selectedWorkStage.value))
        }

        if (selectedServiceManager !== "All") {
            setProjectServicesFiltered(servicesFiltered => servicesFiltered.filter((item) => item.serviceManager === selectedServiceManager.value))
        }

        if (selectedServiceStatus !== "All") {
            setProjectServicesFiltered(servicesFiltered => servicesFiltered.filter((item) => item.serviceStatus === selectedServiceStatus.value))
        }

        if (selectedCustomerPo !== "All") {
            setProjectServicesFiltered(servicesFiltered => servicesFiltered.filter((item) => item.customerPo === selectedCustomerPo.value))
        }

        if (selectedDealNumber !== "All") {
            setProjectServicesFiltered(servicesFiltered => servicesFiltered.filter((item) => item.dealNumber === selectedDealNumber.value))
        }

    }, [selectedService, selectedDepartment, selectedWorkStage, selectedServiceManager, selectedServiceStatus, selectedCustomerPo, projectServices, selectedDealNumber])

    ////////////////////////////////////////

    const groupedLiveData = projectServicesFiltered.filter(item => item.serviceStatus === "Live").map(service => ({

        ...service,
        totalActivityFees: service.activities.reduce((sum, activity) => sum + activity.fee, 0),
        totalConsultancyFees: service.activities.reduce((sum, activity) => (sum + activity.fee)-activity.cost, 0),
        totalCosts: service.activities.reduce((sum, activity) => sum + activity.cost, 0),
        totalEstHrs: service.activities.reduce((sum, activity) => sum + activity.estimatedHours, 0),
        totalActHrs: props.timesheetData.filter((item) => item.serviceId === service.id).map(item => item.timesheetHours).reduce((prev, curr) => Number(prev) + Number(curr), 0),
        totalRecoveryRate: service.activities.reduce((sum, activity) => sum + activity.estimatedHours, 0) / props.timesheetData.filter((item) => item.serviceId === service.id).map(item => item.timesheetHours).reduce((prev, curr) => Number(prev) + Number(curr), 0),
    }));

    const groupedQuoteData = projectServicesFiltered.filter(item => item.serviceStatus.slice(0, 5) === "Quote").map(service => ({

        ...service,
        totalActivityFees: service.activities.reduce((sum, activity) => sum + activity.fee, 0),
        totalConsultancyFees: service.activities.reduce((sum, activity) => (sum + activity.fee)-activity.cost, 0),
        totalCosts: service.activities.reduce((sum, activity) => sum + activity.cost, 0),
        totalEstHrs: service.activities.reduce((sum, activity) => sum + activity.estimatedHours, 0),
        totalActHrs: props.timesheetData.filter((item) => item.serviceId === service.id).map(item => item.timesheetHours).reduce((prev, curr) => Number(prev) + Number(curr), 0),
        totalRecoveryRate: service.activities.reduce((sum, activity) => sum + activity.estimatedHours, 0) / props.timesheetData.filter((item) => item.serviceId === service.id).map(item => item.timesheetHours).reduce((prev, curr) => Number(prev) + Number(curr), 0),
    }));

    const groupedOtherData = projectServicesFiltered.filter(item => item.serviceStatus!=="Live" && item.serviceStatus.slice(0, 5)!== "Quote").map(service => ({

        ...service,
        totalActivityFees: service.activities.reduce((sum, activity) => sum + activity.fee, 0),
        totalConsultancyFees: service.activities.reduce((sum, activity) => (sum + activity.fee)-activity.cost, 0),
        totalCosts: service.activities.reduce((sum, activity) => sum + activity.cost, 0),
        totalEstHrs: service.activities.reduce((sum, activity) => sum + activity.estimatedHours, 0),
        totalActHrs: props.timesheetData.filter((item) => item.serviceId === service.id).map(item => item.timesheetHours).reduce((prev, curr) => Number(prev) + Number(curr), 0),
        totalRecoveryRate: service.activities.reduce((sum, activity) => sum + activity.estimatedHours, 0) / props.timesheetData.filter((item) => item.serviceId === service.id).map(item => item.timesheetHours).reduce((prev, curr) => Number(prev) + Number(curr), 0),
    }));

    const handleAddActivityClick = (recordId) => {
        setServiceIdToSendToModal(recordId)
        setCollectionArrayToSendToModal(projectServicesFiltered.find(item => item.id===recordId).activities)
        setActivityModalUse("Add")
        setShowModalAddEditActivity(true)
    };

    const handleEditActivityClick = (serviceId, activityId) => {
        setServiceIdToSendToModal(serviceId)
        setActivityIdToSendToModal(activityId)
        setCollectionArrayToSendToModal(projectServicesFiltered.find(item => item.id===serviceId).activities)
        setActivityModalUse("Edit")
        setShowModalAddEditActivity(true)
    };

    const [, forceUpdate] = useReducer(forceUpdateReducer, 0); // Used to refresh the table

    const deleteProjectActivity = async (activityAndServiceIDs) => {

        if (window.confirm('Are you sure you want to delete Activity?')) {

            //code here to to an await get on timesheets to check activity doesn't exist in timesheets:

            var activityExistsInTimesheets = false

            await axios.get(process.env.REACT_APP_API_URL + "/timesheets_by_projectId/" + match.params.projectId)
            .then((res) => {
                if (res.data.filter(item => item.activityId === activityAndServiceIDs.activityId).length > 0) {
                    activityExistsInTimesheets = true
                }
            })
            .catch((err) => {
                console.log(err);
            })


            if (activityExistsInTimesheets) {
                window.alert("Please remove activity from timesheets in order to delete activity")
            } else {

                var service
                var activities

                await axios.get(process.env.REACT_APP_API_URL + "/projects_services/" + activityAndServiceIDs.serviceId)
                    .then((res) => {

                        service = res.data
                        activities = res.data.activities.filter((activity) => activity.activityId !== activityAndServiceIDs.activityId)

                            })
                    .catch((err) => {
                        console.log(err);
                    })
                
                axios.put(process.env.REACT_APP_API_URL + '/projects_services/' + activityAndServiceIDs.serviceId, { ...service, activities: activities })

                const service1 = projectServicesFiltered.find(s => s.id === activityAndServiceIDs.serviceId);
            
                if (service1) {
                    const activityIndex = service.activities.findIndex(activity => activity.activityId === activityAndServiceIDs.activityId);
                    
                    if (activityIndex !== -1) {
                        service1.activities.splice(activityIndex, 1);
                    }
                }
            
                setProjectServicesFiltered(projectServicesFiltered);
  
                forceUpdate();

            }
        }
    }

    ////////////////////////////////////////

    if (clients.length > 0 && projectServicesFiltered.length > 0 && projectServices.length > 0 && users.length > 0 && !loading ) {

        //JW - 11/11/22 Code to total the activity fees per project
        const myArray = []
        const myArrayTotal = []
        myArray.push(projectServicesFiltered.filter(item => item.serviceStatus==="Live" || item.serviceStatus==="Closed-completed").map(item => item.activities.map(item => item.fee).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)));
        myArrayTotal.push(myArray[0].map(item => item).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2));

        const myArrayLive = []
        const myArrayTotalLive = []
        myArrayLive.push(projectServicesFiltered.filter(item => item.serviceStatus==="Live").map(item => item.activities.map(item => item.fee).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)));
        myArrayTotalLive.push(myArrayLive[0].map(item => item).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2));

        const myArrayQuote = []
        const myArrayTotalQuote = []
        myArrayQuote.push(projectServicesFiltered.filter(item => item.serviceStatus.slice(0,5)==="Quote").map(item => item.activities.map(item => item.fee).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)));
        myArrayTotalQuote.push(myArrayQuote[0].map(item => item).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2));

        const myArrayClosedComplete= []
        const myArrayTotalClosedComplete = []
        myArrayClosedComplete.push(projectServicesFiltered.filter(item => item.serviceStatus==="Closed-completed").map(item => item.activities.map(item => item.fee).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)));
        myArrayTotalClosedComplete.push(myArrayClosedComplete[0].map(item => item).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2));

        const myArrayOther = []
        const myArrayTotalOther = []
        myArrayOther.push(projectServicesFiltered.filter(item => item.serviceStatus!=="Live" && item.serviceStatus.slice(0,5)!=="Quote" && item.serviceStatus!=="Closed-completed").map(item => item.activities.map(item => item.fee).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2)));
        myArrayTotalOther.push(myArrayOther[0].map(item => item).reduce((prev, curr) => Number(prev) + Number(curr), 0).toFixed(2));

        return (
            <div>
                <h2 className='pt-4 pl-3  pb-2 w-full max-w-9xl mx-auto header-8A'>Services</h2>
                
                <div className="flex items-center">
                    <div className="w-full mx-auto m-5 px-3">
                        <div className="grid grid-cols-2 gap-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-8">
                            
                            <div>
                                <p className="text-xs">Department</p>
                                <ReactSelect
                                    styles={styles}
                                    value={selectedDepartment}
                                    onChange={setSelectedDepartment}
                                    options={aggregateDepartmentsArray(projectServices).sort((item1, item2) => item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : 0).map((item) => {
                                        return {
                                            label: item.department,
                                            value: item.department
                                        }
                                    })} />
                            </div>

                            <div>
                                <p className="text-xs">Service</p>
                                <ReactSelect
                                    styles={styles}
                                    value={selectedService}
                                    onChange={setSelectedService}
                                    options={aggregateServicesArray(projectServices).sort((item1, item2) => item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0).map((item) => {
                                        return {
                                            label: item.service,
                                            value: item.service
                                        }
                                    })} />
                            </div>

                            <div>
                                <p className="text-xs">Work Stage</p>
                                <ReactSelect
                                    styles={styles}
                                    value={selectedWorkStage}
                                    onChange={setSelectedWorkStage}
                                    options={aggregateWorkStagesArray(projectServices).sort((item1, item2) => item1.workStage < item2.workStage ? -1 : item1.workStage > item2.workStage ? 1 : 0).map((item) => {
                                        return {
                                            label: item.workStage,
                                            value: item.workStage
                                        }
                                    })} />
                            </div>

                            <div>
                                <p className="text-xs">Service Manager</p>
                                <ReactSelect
                                    styles={styles}
                                    value={selectedServiceManager}
                                    onChange={setSelectedServiceManager}
                                    options={aggregateServiceManagersArray(projectServices).sort((item1, item2) => item1.serviceManager < item2.serviceManager ? -1 : item1.serviceManager > item2.serviceManager ? 1 : 0).map((item) => {
                                        return {
                                            label: item.serviceManagerName,
                                            value: item.serviceManager
                                        }
                                    })} />
                            </div>

                            {/* <div>
                                <p className="text-xs">Service Status</p>
                                <ReactSelect
                                    styles={styles}
                                    value={selectedServiceStatus}
                                    onChange={setSelectedServiceStatus}
                                    options={aggregateServiceStatusesArray(projectServices).sort((item1, item2) => item1.serviceStatus < item2.serviceStatus ? -1 : item1.serviceStatus > item2.serviceStatus ? 1 : 0).map((item) => {
                                        return {
                                            label: item.serviceStatus,
                                            value: item.serviceStatus
                                        }
                                    })} />
                            </div> */}

                            <div>
                                <p className="text-xs">Client PO</p>
                                <ReactSelect
                                    styles={styles}
                                    value={selectedCustomerPo}
                                    onChange={setSelectedCustomerPo}
                                    options={aggregateCustomerPoArray(projectServices).sort((item1, item2) => item1.customerPo < item2.customerPo ? -1 : item1.customerPo > item2.customerPo ? 1 : 0).map((item) => {
                                        return {
                                            label: item.customerPo,
                                            value: item.customerPo
                                        }
                                    })} />
                            </div>

                            <div>
                                <p className="text-xs">Hub Spot Deal</p>
                                <ReactSelect
                                    styles={styles}
                                    value={selectedDealNumber}
                                    onChange={setSelectedDealNumber}
                                    options={aggregateDealNumbersArray(projectServices).sort((item1, item2) => item1.dealNumber < item2.dealNumber ? -1 : item1.dealNumber > item2.dealNumber ? 1 : 0).map((item) => {
                                        return {
                                            label: item.dealName,
                                            value: item.dealNumber
                                        }
                                    })} />
                            </div>

                            <div className="">
                                <p className="text-xs font-bold pl-2">Filters</p>
                                <Button color="bg-bondi-blue" text="Clear Filters" onClick={() => clearFilters()} />
                            </div>

                            <div className="">
                                <p className="text-xs font-bold pl-2">Function</p>
                                <Button color="bg-bondi-blue" text="Update HubSpot" onClick={() => hubSpotSync()} />
                                {/* <Button color="bg-bondi-blue" text="Update HubSpot" onClick={() => hubSpotSync()} />*/}
                                {/* <Button color="bg-bondi-blue" text="Update HubSpot" onClick={() => { setShowModal(true); setValueToSendToModal(match.params.projectId); setFieldToSendToModal("hubSpotLink"); setObjectIdToSendToModal(match.params.projectId); setCollectionToSendToModal("hubSpotLink") }} /> */}
                            </div>

                        </div>
                    </div>
                </div>

                <div className="flex flex-col">
                <h2 className='pl-3 w-full max-w-9xl mx-auto header-8A'>Live</h2>
                    <div className="overflow-x-auto">
                        <div className="px-3 align-middle inline-block min-w-full mb-5">
                            <div className="overflow-hidden  rounded-2xl shadow-md shadow-slate-200 ">
                            <Table
                                rowKey="id"
                                dataSource={groupedLiveData}
                                size="small"
                                pagination={false}
                                columns={[{
                                    title: 'Department',
                                    dataIndex: 'department',
                                    width: 130,
                                },
                                {
                                    title: 'Service',
                                    dataIndex: 'service',
                                    width: 140,
                                },
                                {
                                    title: 'Comments',
                                    dataIndex: 'serviceComments',
                                    width: 150,
                                    key: 'id',
                                    render: (text, record) => <td onClick={() => { setShowModal(true); setValueToSendToModal(record.serviceComments ); setFieldToSendToModal("serviceComments"); setObjectIdToSendToModal(record.id); setCollectionToSendToModal("projects_services"); setCollectionArrayIdToSendToModal("") }} className="py-2 hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-4 float-left w-4" viewBox="0 0 20 20" fill="gray">
                                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                    </svg>{record.serviceComments}</td>,
                                },
                                {
                                    title: 'Work Stage',
                                    dataIndex: 'workStage',
                                    width: 100,
                                },
                                {
                                    title: 'Service Manager',
                                    dataIndex: 'serviceManager',
                                    width: 110,
                                    key: 'serviceManager',
                                    render: (text, record) => <td onClick={() => { setShowModal(true); setValueToSendToModal(record.serviceManager); setFieldToSendToModal("serviceManager"); setObjectIdToSendToModal(record.id); setCollectionToSendToModal("projects_services"); setCollectionArrayIdToSendToModal("") }} className="py-2 hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-4 float-left w-4" viewBox="0 0 20 20" fill="gray">
                                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                    </svg>{users.find(user => user.id === record.serviceManager).firstName + ' ' + users.find(user => user.id === record.serviceManager).lastName}</td>,
                                },
                                {
                                    title: 'Service Status',
                                    dataIndex: 'serviceStatus',
                                    key: 'serviceStatus',
                                    width: 110,                                                
                                    render: (text, record) => <td onClick={() => { setShowModal(true); setValueToSendToModal(record.serviceStatus); setFieldToSendToModal("serviceStatus"); setObjectIdToSendToModal(record.id); setCollectionToSendToModal("projects_services"); setCollectionArrayIdToSendToModal("") }} className="py-2 hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-4 float-left w-4" viewBox="0 0 20 20" fill="gray">
                                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                    </svg>{record.serviceStatus}</td>
                                },
                                {
                                    title: 'Total Fees',
                                    dataIndex: 'totalActivityFees',
                                    width: 90,
                                    key: 'totalActivityFees',
                                    render: (totalActivityFees) => `${internationalNumberFormat.format(totalActivityFees.toFixed(2))}`, // Show total fees with currency formatting
                                },
                                {
                                    title: 'Consultancy Fees',
                                    dataIndex: 'totalConsultancyFees',
                                    width: 105,
                                    key: 'totalConsultancyFees',
                                    render: (totalConsultancyFees, record) => {
                                        if (props.role.toString().slice(0,1) !== "2") {
                                            return `${internationalNumberFormat.format(totalConsultancyFees.toFixed(2))}`
                                        }else if (props.role.toString().slice(0,1) === "2") {
                                            return <td onDoubleClick={() => { setShowModal(true); setValueToSendToModal(totalConsultancyFees); setFieldToSendToModal("consultancyFee"); setObjectIdToSendToModal(record.id); setCollectionToSendToModal("projects_services"); setCollectionArrayIdToSendToModal("") }} className="py-2 whitespace-nowrap"> {`${internationalNumberFormat.format(totalConsultancyFees.toFixed(2))}`} </td>
                                        }
                                    }
                                },
                                {
                                    title: 'Costs',
                                    dataIndex: 'totalCosts',
                                    width: 80,
                                    key: 'totalCosts',
                                    render: (totalCosts) => `${internationalNumberFormat.format(totalCosts.toFixed(2))}`,
                                },
                                {
                                    title: 'SRR',
                                    dataIndex: 'totalRecoveryRate',
                                    width: 60,
                                    key: 'totalRecoveryRate',
                                    render: (totalRecoveryRate) => isFinite(totalRecoveryRate) ? `${totalRecoveryRate.toFixed(2)}` : "NA",
                                },
                                {
                                    title: 'Inv %',
                                    dataIndex: 'totalActivityFees',
                                    width: 60,
                                    key: 'totalActivityFees',
                                    render: (totalActivityFees, record) => {
                                        let invoiceTotal = 0
                                        invoices.forEach(invoice => {
                                            invoiceTotal +=invoice.invoiceLines.filter(invoiceLine => invoiceLine.serviceId===record.id && invoiceLine.okToInvoice==="YES").map(item => item.value).reduce((prev, curr) => Number(prev) + Number(curr), 0)
                                        });
                                        return `${((invoiceTotal / totalActivityFees)*100).toFixed(0)}%`
                                    }
                                },
                                {
                                    title: 'Client PO',
                                    dataIndex: 'customerPo',
                                    width: 100,
                                    key: 'id',
                                    render: (text, record) => <td onClick={() => { setShowModal(true); setValueToSendToModal(record.customerPo ); setFieldToSendToModal("customerPo"); setObjectIdToSendToModal(record.id); setCollectionToSendToModal("projects_services"); setCollectionArrayIdToSendToModal("") }} className="py-2 hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-4 float-left w-4" viewBox="0 0 20 20" fill="gray">
                                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                    </svg>{record.customerPo}</td>,
                                },
                                {
                                    title: 'Service Client to Invoice',
                                    dataIndex: 'serviceInvoiceClient',
                                    key: 'serviceInvoiceClient',
                                    width: 160,
                                    render: (text, record) => <td onClick={() => { setShowModal(true); setValueToSendToModal(record.serviceInvoiceClient); setFieldToSendToModal("serviceInvoiceClient"); setObjectIdToSendToModal(record.id); setCollectionToSendToModal("projects_services"); setCollectionArrayIdToSendToModal("") }} className="px-2 md:px-2  py-2 hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-4 float-left w-4" viewBox="0 0 20 20" fill="gray">
                                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                    </svg> {record.serviceInvoiceClient !== "" ? clients.find(client => client.id === record.serviceInvoiceClient).clientName : ""}</td>
                                },
                                {
                                    title: 'Service Client Contact',
                                    dataIndex: 'serviceInvoiceClientContact',
                                    key: 'serviceInvoiceClientContact',
                                    width: 105,
                                    render: (text, record) => <td onClick={() => { setShowModal(true); setValueToSendToModal(record.serviceInvoiceClient); setFieldToSendToModal("serviceInvoiceClient"); setObjectIdToSendToModal(record.id); setCollectionToSendToModal("projects_services"); setCollectionArrayIdToSendToModal("") }} className="px-2 md:px-2  py-2 hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-4 float-left w-4" viewBox="0 0 20 20" fill="gray">
                                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                    </svg> {record.serviceInvoiceClientContact !== "" ? contacts.find(contact => contact.id === record.serviceInvoiceClientContact).contactName : ""}</td>
                                },
                                {
                                    title: 'Actions',
                                    dataIndex: 'actions',
                                    width: 70,
                                    render: (text, record) => {
                                        return props.role.toString().slice(0,1) === "2" && <Options addActivity={() => handleAddActivityClick(record.id)} edit={`/addOrUpdateClientProjectService/${match.params.projectId}/${record.id}`} deleteFunction={deleteProjectService} cloneFunction={cloneService} deleteObject={record.id} cloneObject={record.id}></Options>
                                    }
                                },
                                {
                                    title: 'Billing Plan',
                                    dataIndex: 'serviceIncludedInBillingPlan',
                                    width: 90,
                                    render: (text, record) => {
                                        return serviceIncludedInBillingPlan(record.id).toFixed(2) === record.totalActivityFees.toFixed(2) ? <td className="pr-8 whitespace-nowrap"><svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 float-left mr-3" fill="none" viewBox="0 0 24 24" stroke="#0BDA51" strokeWidth="2">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>{internationalNumberFormat.format(record.totalActivityFees.toFixed(2))}</td> : serviceIncludedInBillingPlan(record.id) === 0 ? <td><Button color="bg-bondi-blue" text="+ Billing Plan" onClick={() => {setShowModalAddToBillingPlan(true); setValueToSendToModal({projectId: match.params.projectId, serviceId: record.id, fee: record.activities.map(item => item.fee).reduce((prev, curr) => Number(prev) + Number(curr), 0) - record.activities.map(item => item.cost).reduce((prev, curr) => Number(prev) + Number(curr), 0), projectNumber: props.projectNumber, projectName: props.projectName, service: record.service, serviceManagerId: record.serviceManager, projectManagerId: props.projectManager, clientToInvoiceContact: record.serviceInvoiceClientContact, clientToInvoice: record.serviceInvoiceClient, clientPO: record.customerPo, activities: record.activities, costs: record.activities.map(item => item.cost).reduce((prev, curr) => Number(prev) + Number(curr), 0)})}} /></td> : <td className="px-2 md:px-2  py-2 whitespace-nowrap"><svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 float-left mr-3" fill="none" viewBox="0 0 24 24" stroke="#FFBF00" strokeWidth="2">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                    </svg>{internationalNumberFormat.format(serviceIncludedInBillingPlan(record.id).toFixed(2))}</td>
                                    }
                                },
                                {
                                    title: 'HubSpot Deal',
                                    dataIndex: 'dealNumber',
                                    width: 120,
                                    key: 'dealNumber',
                                    render: (text, record) => <td onClick={() => { setShowModal(true); setValueToSendToModal(record.dealNumber); setFieldToSendToModal("dealNumber"); setObjectIdToSendToModal(record.id); setCollectionToSendToModal("projects_services"); setCollectionArrayIdToSendToModal("") }} className="py-2 hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-4 float-left w-4" viewBox="0 0 20 20" fill="gray">
                                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                    </svg>{record.dealNumber ? record.dealName : ""}</td>,
                                },
                                {
                                    dataIndex: 'id',
                                    key: 'id',
                                    render: () => null,
                                }]}
                                expandable={{
                                    rowExpandable: (record) => true,
                                    indentSize: 0,
                                    expandedRowRender: (serviceRecord) => {
                                        var actualHours = 0
                                        var complete
                                        var activitiesData = serviceRecord.activities
                                        var activitiesDataProcessed = []
                                        activitiesData.forEach(activity => {
                                            actualHours = props.timesheetData.filter((item) => item.serviceId === serviceRecord.id && item.activityId === activity.activityId).map(item => item.timesheetHours).reduce((prev, curr) => Number(prev) + Number(curr), 0)
                                            complete = (actualHours / activity.estimatedHours) * 100
                                            console.log("timesheetHrs", actualHours)
                                            activitiesDataProcessed.push({ serviceId: serviceRecord.id, activityId:activity.activityId, activity:activity.activity, estimatedHours:activity.estimatedHours, fee:activity.fee, complete: complete, toBeInvoiced:activity.activityToBeInvoiced, actualHours: actualHours, activityComplete:activity.activityComplete, cost:activity.cost, costCategory:activity.costCategory })

                                        });
                                        const columnsActivities = [
                                            {
                                                title: 'Activity',
                                                dataIndex: 'activity',
                                                width: 250
                                            },
                                            {
                                                title: 'Estimated Hours',
                                                dataIndex: 'estimatedHours',
                                                width: 120,
                                                render: (estimatedHours, record) => 
                                                    <div>
                                                        {record.cost === 0 && props.role.toString().slice(0,1) === "2" &&
                                                            <td onClick={() => { setShowModal(true); setValueToSendToModal(record.estimatedHours.toFixed(2)); setFieldToSendToModal("estimatedHours"); setObjectIdToSendToModal(serviceRecord.id); setCollectionToSendToModal("projects_services"); setCollectionArrayToSendToModal("activities"); setCollectionArrayIdToSendToModal(record.activityId) }} className="py-2 hover:cursor-pointer whitespace-nowrap"> <svg xmlns="http://www.w3.org/2000/svg" className="h-4 float-left w-4" viewBox="0 0 20 20" fill="gray">
                                                                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                                <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                            </svg>{record.estimatedHours.toFixed(2)}</td>
                                                        }
                                                        {(record.cost !== 0 || props.role.toString().slice(0,1) !== "2") &&
                                                            <td className="px-4 md:px-2 py-2 hover:cursor-pointer whitespace-nowrap">{record.estimatedHours.toFixed(2)}</td>
                                                        }                                                                    
                                                    </div>
                                            },
                                            {
                                                title: 'Actual Hours',
                                                dataIndex: 'actualHours',
                                                width: 120,
                                                render: (actualHours) => {
                                                    return actualHours.toFixed(2);
                                                }
                                            },
                                            {
                                                title: 'Fees',
                                                dataIndex: 'fee',
                                                width: 120,
                                                render: (fee) => {
                                                    return internationalNumberFormat.format(fee.toFixed(2));
                                                }
                                            },
                                            {
                                                title: 'Costs',
                                                dataIndex: 'cost',
                                                width: 120,
                                                render: (cost, record) => 
                                                    <div>
                                                        {record.estimatedHours === 0 && props.role.toString().slice(0,1) === "2" &&
                                                        <td onClick={() => { setShowModal(true); setValueToSendToModal(record.cost.toFixed(2)); setFieldToSendToModal("cost"); setObjectIdToSendToModal(serviceRecord.id); setCollectionToSendToModal("projects_services"); setCollectionArrayToSendToModal("activities"); setCollectionArrayIdToSendToModal(record.activityId) }} className="py-2 hover:cursor-pointer whitespace-nowrap"> <svg xmlns="http://www.w3.org/2000/svg" className="h-4 float-left w-4" viewBox="0 0 20 20" fill="gray">
                                                            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                            <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                        </svg>{internationalNumberFormat.format(record.cost.toFixed(2))}</td>
                                                        }
                                                        {(record.estimatedHours !== 0 || props.role.toString().slice(0,1) !== "2") &&
                                                            <td className="px-4 md:px-2 py-2 hover:cursor-pointer whitespace-nowrap"> {internationalNumberFormat.format(record.cost.toFixed(2))}</td>
                                                        }
                                                    </div>
                                            },
                                            {
                                                title: 'Cost Category',
                                                dataIndex: 'costCategory',
                                                width: 250,
                                                render: (costCategory, record) => 
                                                    <div>
                                                        {record.estimatedHours === 0 && props.role.toString().slice(0,1) === "2" &&
                                                        <td onClick={() => { setShowModal(true); setValueToSendToModal(record.costCategory); setFieldToSendToModal("costCategory"); setObjectIdToSendToModal(serviceRecord.id); setCollectionToSendToModal("projects_services"); setCollectionArrayToSendToModal("activities"); setCollectionArrayIdToSendToModal(record.activityId) }} className="py-2 hover:cursor-pointer whitespace-nowrap"> <svg xmlns="http://www.w3.org/2000/svg" className="h-4 float-left w-4" viewBox="0 0 20 20" fill="gray">
                                                            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                            <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                        </svg>{record.costCategory}</td>
                                                        }
                                                        {(record.estimatedHours !== 0 || props.role.toString().slice(0,1) !== "2") &&
                                                            <td className="px-4 md:px-2 py-2 hover:cursor-pointer whitespace-nowrap"> {record.costCategory}</td>
                                                        }
                                                    </div>
                                            },
                                            {
                                                title: 'Complete',
                                                dataIndex: 'complete',
                                                width: 120,
                                                render: (activityComplete, record) => {
                                                    return <ActivityProgressBar bgcolor={record.activityComplete === "NO" && record.complete >= 100 ? "#ff0000" : record.activityComplete === "YES" ? "#49fc03" : "#0091ca"} completed={isNaN(record.complete) || record.complete >= 100 ? 100 : Math.round(record.complete)} completedLabel={isNaN(record.complete) ? 100 : Math.round(record.complete) === Infinity ? 100 : Math.round(record.complete)}></ActivityProgressBar>
                                                }
                                            },
                                            {
                                                title: 'Activity Complete',
                                                dataIndex: 'activityComplete',
                                                width: 100,
                                                render: (activityComplete, record) => 
                                                    <div>                                                                            
                                                        <td onClick={() => { setShowModal(true); setValueToSendToModal(record.activityComplete); setFieldToSendToModal("activityComplete"); setObjectIdToSendToModal(serviceRecord.id); setCollectionToSendToModal("projects_services"); setCollectionArrayToSendToModal("activities"); setCollectionArrayIdToSendToModal(record.activityId) }} className="py-2 hover:cursor-pointer whitespace-nowrap"> <svg xmlns="http://www.w3.org/2000/svg" className="h-4 float-left w-4" viewBox="0 0 20 20" fill="gray">
                                                            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                            <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                        </svg>{activityComplete}</td>                                                                            
                                                    </div>
                                            },
                                            {
                                                title: 'Actions',
                                                dataIndex: 'actions',
                                                width: 70,
                                                render: (text, record) => {
                                                    return props.role.toString().slice(0,1) === "2" && <Options addActivity={() => handleAddActivityClick(serviceRecord.id)} editActivity={() => handleEditActivityClick(serviceRecord.id, record.activityId)} deleteFunction={deleteProjectActivity} deleteObject={{ activityId: record.activityId, serviceId: serviceRecord.id, activityKey: record.activityKey, actualHours: record.actualHours, activityIdInTimesheets: record.activityIdInTimesheets}}></Options>
                                                }
                                            },
                                            {
                                                dataIndex: 'activityId',
                                                render: () => null,
                                            }
                                        ]
                                        //Activities
                                        return (
                                            <div style={{ width: '1400px', margin: '0', marginBottom:'5px' }}>
                                                <Table 
                                                    columns={columnsActivities} 
                                                    dataSource={activitiesDataProcessed.sort((item1, item2) => item1.activity < item2.activity ? -1 : item1.activity > item2.activity ? 1 : 0)}
                                                    size="small"
                                                    rowKey="activityId"
                                                    rowClassName={(record, index) => {
                                                        return 'activity-row'
                                                    }}
                                                    pagination={false}
                                                />
                                            </div>
                                        );
                                    },
                                }}
                                >
                                </Table>                             

                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col mt-5">
                <h2 className='pl-3 w-full max-w-9xl mx-auto header-8A'>Quote</h2>
                    <div className="overflow-x-auto">
                        <div className="px-3 align-middle inline-block min-w-full mb-5">
                            <div className="overflow-hidden  rounded-2xl shadow-md shadow-slate-200 ">
                            <Table
                                rowKey="id"
                                dataSource={groupedQuoteData}
                                size="small"
                                pagination={false}
                                columns={[{
                                    title: 'Department',
                                    dataIndex: 'department',
                                    width: 130,
                                },
                                {
                                    title: 'Service',
                                    dataIndex: 'service',
                                    width: 140,
                                },
                                {
                                    title: 'Comments',
                                    dataIndex: 'serviceComments',
                                    width: 150,
                                    key: 'id',
                                    render: (text, record) => <td onClick={() => { setShowModal(true); setValueToSendToModal(record.serviceComments ); setFieldToSendToModal("serviceComments"); setObjectIdToSendToModal(record.id); setCollectionToSendToModal("projects_services"); setCollectionArrayIdToSendToModal("") }} className="py-2 hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-4 float-left w-4" viewBox="0 0 20 20" fill="gray">
                                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                    </svg>{record.serviceComments}</td>,
                                },
                                {
                                    title: 'Work Stage',
                                    dataIndex: 'workStage',
                                    width: 100,
                                },
                                {
                                    title: 'Service Manager',
                                    dataIndex: 'serviceManager',
                                    width: 110,
                                    key: 'serviceManager',
                                    render: (text, record) => <td onClick={() => { setShowModal(true); setValueToSendToModal(record.serviceManager); setFieldToSendToModal("serviceManager"); setObjectIdToSendToModal(record.id); setCollectionToSendToModal("projects_services"); setCollectionArrayIdToSendToModal("") }} className="py-2 hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-4 float-left w-4" viewBox="0 0 20 20" fill="gray">
                                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                    </svg>{users.find(user => user.id === record.serviceManager).firstName + ' ' + users.find(user => user.id === record.serviceManager).lastName}</td>,
                                },
                                {
                                    title: 'Service Status',
                                    dataIndex: 'serviceStatus',
                                    key: 'serviceStatus',
                                    width: 110,                                                
                                    render: (text, record) => <td onClick={() => { setShowModal(true); setValueToSendToModal(record.serviceStatus); setFieldToSendToModal("serviceStatus"); setObjectIdToSendToModal(record.id); setCollectionToSendToModal("projects_services"); setCollectionArrayIdToSendToModal("") }} className="py-2 hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-4 float-left w-4" viewBox="0 0 20 20" fill="gray">
                                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                    </svg>{record.serviceStatus}</td>
                                },
                                {
                                    title: 'Total Fees',
                                    dataIndex: 'totalActivityFees',
                                    width: 90,
                                    key: 'totalActivityFees',
                                    render: (totalActivityFees) => `${internationalNumberFormat.format(totalActivityFees.toFixed(2))}`, // Show total fees with currency formatting
                                },
                                {
                                    title: 'Consultancy Fees',
                                    dataIndex: 'totalConsultancyFees',
                                    width: 105,
                                    key: 'totalConsultancyFees',
                                    render: (totalConsultancyFees, record) => {
                                        if (props.role.toString().slice(0,1) !== "2") {
                                            return `${internationalNumberFormat.format(totalConsultancyFees.toFixed(2))}`
                                        }else if (props.role.toString().slice(0,1) === "2") {
                                            return <td onDoubleClick={() => { setShowModal(true); setValueToSendToModal(totalConsultancyFees); setFieldToSendToModal("consultancyFee"); setObjectIdToSendToModal(record.id); setCollectionToSendToModal("projects_services"); setCollectionArrayIdToSendToModal("") }} className="py-2 whitespace-nowrap"> {`${internationalNumberFormat.format(totalConsultancyFees.toFixed(2))}`} </td>
                                        }
                                    }
                                },
                                {
                                    title: 'Costs',
                                    dataIndex: 'totalCosts',
                                    width: 80,
                                    key: 'totalCosts',
                                    render: (totalCosts) => `${internationalNumberFormat.format(totalCosts.toFixed(2))}`,
                                },
                                {
                                    title: 'SRR',
                                    dataIndex: 'totalRecoveryRate',
                                    width: 60,
                                    key: 'totalRecoveryRate',
                                    render: (totalRecoveryRate) => isFinite(totalRecoveryRate) ? `${totalRecoveryRate.toFixed(2)}` : "NA",
                                },
                                {
                                    title: 'Inv %',
                                    dataIndex: 'totalActivityFees',
                                    width: 60,
                                    key: 'totalActivityFees',
                                    render: (totalActivityFees, record) => {
                                        let invoiceTotal = 0
                                        invoices.forEach(invoice => {
                                            invoiceTotal +=invoice.invoiceLines.filter(invoiceLine => invoiceLine.serviceId===record.id && invoiceLine.okToInvoice==="YES").map(item => item.value).reduce((prev, curr) => Number(prev) + Number(curr), 0)
                                        });
                                        return `${((invoiceTotal / totalActivityFees)*100).toFixed(0)}%`
                                    }
                                },
                                {
                                    title: 'Client PO',
                                    dataIndex: 'customerPo',
                                    width: 100,
                                    key: 'id',
                                    render: (text, record) => <td onClick={() => { setShowModal(true); setValueToSendToModal(record.customerPo ); setFieldToSendToModal("customerPo"); setObjectIdToSendToModal(record.id); setCollectionToSendToModal("projects_services"); setCollectionArrayIdToSendToModal("") }} className="py-2 hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-4 float-left w-4" viewBox="0 0 20 20" fill="gray">
                                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                    </svg>{record.customerPo}</td>,
                                },
                                {
                                    title: 'Service Client to Invoice',
                                    dataIndex: 'serviceInvoiceClient',
                                    key: 'serviceInvoiceClient',
                                    width: 160,
                                    render: (text, record) => <td onClick={() => { setShowModal(true); setValueToSendToModal(record.serviceInvoiceClient); setFieldToSendToModal("serviceInvoiceClient"); setObjectIdToSendToModal(record.id); setCollectionToSendToModal("projects_services"); setCollectionArrayIdToSendToModal("") }} className="px-2 md:px-2  py-2 hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-4 float-left w-4" viewBox="0 0 20 20" fill="gray">
                                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                    </svg> {record.serviceInvoiceClient !== "" ? clients.find(client => client.id === record.serviceInvoiceClient).clientName : ""}</td>
                                },
                                {
                                    title: 'Service Client Contact',
                                    dataIndex: 'serviceInvoiceClientContact',
                                    key: 'serviceInvoiceClientContact',
                                    width: 105,
                                    render: (text, record) => <td onClick={() => { setShowModal(true); setValueToSendToModal(record.serviceInvoiceClient); setFieldToSendToModal("serviceInvoiceClient"); setObjectIdToSendToModal(record.id); setCollectionToSendToModal("projects_services"); setCollectionArrayIdToSendToModal("") }} className="px-2 md:px-2  py-2 hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-4 float-left w-4" viewBox="0 0 20 20" fill="gray">
                                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                    </svg> {record.serviceInvoiceClientContact !== "" ? contacts.find(contact => contact.id === record.serviceInvoiceClientContact).contactName : ""}</td>
                                },
                                {
                                    title: 'Actions',
                                    dataIndex: 'actions',
                                    width: 70,
                                    render: (text, record) => {
                                        return props.role.toString().slice(0,1) === "2" && <Options addActivity={() => handleAddActivityClick(record.id)} edit={`/addOrUpdateClientProjectService/${match.params.projectId}/${record.id}`} deleteFunction={deleteProjectService} cloneFunction={cloneService} deleteObject={record.id} cloneObject={record.id}></Options>
                                    }
                                },
                                {
                                    title: 'Billing Plan',
                                    dataIndex: 'serviceIncludedInBillingPlan',
                                    width: 90,
                                    render: (text, record) => {
                                        return serviceIncludedInBillingPlan(record.id).toFixed(2) === record.totalActivityFees.toFixed(2) ? <td className="pr-8 whitespace-nowrap"><svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 float-left mr-3" fill="none" viewBox="0 0 24 24" stroke="#0BDA51" strokeWidth="2">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>{internationalNumberFormat.format(record.totalActivityFees.toFixed(2))}</td> : serviceIncludedInBillingPlan(record.id) === 0 ? <td></td> : <td className="px-2 md:px-2  py-2 whitespace-nowrap"><svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 float-left mr-3" fill="none" viewBox="0 0 24 24" stroke="#FFBF00" strokeWidth="2">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                    </svg>{internationalNumberFormat.format(serviceIncludedInBillingPlan(record.id).toFixed(2))}</td>
                                    }
                                },
                                {
                                    title: 'HubSpot Deal',
                                    dataIndex: 'dealNumber',
                                    width: 120,
                                    key: 'dealNumber',
                                    render: (text, record) => <td onClick={() => { setShowModal(true); setValueToSendToModal(record.dealNumber); setFieldToSendToModal("dealNumber"); setObjectIdToSendToModal(record.id); setCollectionToSendToModal("projects_services"); setCollectionArrayIdToSendToModal("") }} className="py-2 hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-4 float-left w-4" viewBox="0 0 20 20" fill="gray">
                                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                    </svg>{record.dealNumber ? record.dealName : ""}</td>,
                                },
                                {
                                    dataIndex: 'id',
                                    key: 'id',
                                    render: () => null,
                                }]}
                                expandable={{
                                    rowExpandable: (record) => true,
                                    indentSize: 0,
                                    expandedRowRender: (serviceRecord) => {
                                        var actualHours = 0
                                        var complete
                                        var activitiesData = serviceRecord.activities
                                        var activitiesDataProcessed = []
                                        activitiesData.forEach(activity => {
                                            actualHours = props.timesheetData.filter((item) => item.serviceId === serviceRecord.id && item.activityId === activity.activityId).map(item => item.timesheetHours).reduce((prev, curr) => Number(prev) + Number(curr), 0)
                                            complete = (actualHours / activity.estimatedHours) * 100
                                            console.log("timesheetHrs", actualHours)
                                            activitiesDataProcessed.push({ serviceId: serviceRecord.id, activityId:activity.activityId, activity:activity.activity, estimatedHours:activity.estimatedHours, fee:activity.fee, complete: complete, toBeInvoiced:activity.activityToBeInvoiced, actualHours: actualHours, activityComplete:activity.activityComplete, cost:activity.cost, costCategory:activity.costCategory })

                                        });
                                        const columnsActivities = [
                                            {
                                                title: 'Activity',
                                                dataIndex: 'activity',
                                                width: 250
                                            },
                                            {
                                                title: 'Estimated Hours',
                                                dataIndex: 'estimatedHours',
                                                width: 120,
                                                render: (estimatedHours, record) => 
                                                    <div>
                                                        {record.cost === 0 && props.role.toString().slice(0,1) === "2" &&
                                                            <td onClick={() => { setShowModal(true); setValueToSendToModal(record.estimatedHours.toFixed(2)); setFieldToSendToModal("estimatedHours"); setObjectIdToSendToModal(serviceRecord.id); setCollectionToSendToModal("projects_services"); setCollectionArrayToSendToModal("activities"); setCollectionArrayIdToSendToModal(record.activityId) }} className="py-2 hover:cursor-pointer whitespace-nowrap"> <svg xmlns="http://www.w3.org/2000/svg" className="h-4 float-left w-4" viewBox="0 0 20 20" fill="gray">
                                                                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                                <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                            </svg>{record.estimatedHours.toFixed(2)}</td>
                                                        }
                                                        {(record.cost !== 0 || props.role.toString().slice(0,1) !== "2") &&
                                                            <td className="px-4 md:px-2 py-2 hover:cursor-pointer whitespace-nowrap">{record.estimatedHours.toFixed(2)}</td>
                                                        }                                                                    
                                                    </div>
                                            },
                                            {
                                                title: 'Actual Hours',
                                                dataIndex: 'actualHours',
                                                width: 120,
                                                render: (actualHours) => {
                                                    return actualHours.toFixed(2);
                                                }
                                            },
                                            {
                                                title: 'Fees',
                                                dataIndex: 'fee',
                                                width: 120,
                                                render: (fee) => {
                                                    return internationalNumberFormat.format(fee.toFixed(2));
                                                }
                                            },
                                            {
                                                title: 'Costs',
                                                dataIndex: 'cost',
                                                width: 120,
                                                render: (cost, record) => 
                                                    <div>
                                                        {record.estimatedHours === 0 && props.role.toString().slice(0,1) === "2" &&
                                                        <td onClick={() => { setShowModal(true); setValueToSendToModal(record.cost.toFixed(2)); setFieldToSendToModal("cost"); setObjectIdToSendToModal(serviceRecord.id); setCollectionToSendToModal("projects_services"); setCollectionArrayToSendToModal("activities"); setCollectionArrayIdToSendToModal(record.activityId) }} className="py-2 hover:cursor-pointer whitespace-nowrap"> <svg xmlns="http://www.w3.org/2000/svg" className="h-4 float-left w-4" viewBox="0 0 20 20" fill="gray">
                                                            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                            <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                        </svg>{internationalNumberFormat.format(record.cost.toFixed(2))}</td>
                                                        }
                                                        {(record.estimatedHours !== 0 || props.role.toString().slice(0,1) !== "2") &&
                                                            <td className="px-4 md:px-2 py-2 hover:cursor-pointer whitespace-nowrap"> {internationalNumberFormat.format(record.cost.toFixed(2))}</td>
                                                        }
                                                    </div>
                                            },
                                            {
                                                title: 'Cost Category',
                                                dataIndex: 'costCategory',
                                                width: 250,
                                                render: (costCategory, record) => 
                                                    <div>
                                                        {record.estimatedHours === 0 && props.role.toString().slice(0,1) === "2" &&
                                                        <td onClick={() => { setShowModal(true); setValueToSendToModal(record.costCategory); setFieldToSendToModal("costCategory"); setObjectIdToSendToModal(serviceRecord.id); setCollectionToSendToModal("projects_services"); setCollectionArrayToSendToModal("activities"); setCollectionArrayIdToSendToModal(record.activityId) }} className="py-2 hover:cursor-pointer whitespace-nowrap"> <svg xmlns="http://www.w3.org/2000/svg" className="h-4 float-left w-4" viewBox="0 0 20 20" fill="gray">
                                                            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                            <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                        </svg>{record.costCategory}</td>
                                                        }
                                                        {(record.estimatedHours !== 0 || props.role.toString().slice(0,1) !== "2") &&
                                                            <td className="px-4 md:px-2 py-2 hover:cursor-pointer whitespace-nowrap"> {record.costCategory}</td>
                                                        }
                                                    </div>
                                            },
                                            {
                                                title: 'Complete',
                                                dataIndex: 'complete',
                                                width: 120,
                                                render: (activityComplete, record) => {
                                                    return <ActivityProgressBar bgcolor={record.activityComplete === "NO" && record.complete >= 100 ? "#ff0000" : record.activityComplete === "YES" ? "#49fc03" : "#0091ca"} completed={isNaN(record.complete) || record.complete >= 100 ? 100 : Math.round(record.complete)} completedLabel={isNaN(record.complete) ? 100 : Math.round(record.complete) === Infinity ? 100 : Math.round(record.complete)}></ActivityProgressBar>
                                                }
                                            },
                                            {
                                                title: 'Activity Complete',
                                                dataIndex: 'activityComplete',
                                                width: 100,
                                                render: (activityComplete, record) => 
                                                    <div>                                                                            
                                                        <td onClick={() => { setShowModal(true); setValueToSendToModal(record.activityComplete); setFieldToSendToModal("activityComplete"); setObjectIdToSendToModal(serviceRecord.id); setCollectionToSendToModal("projects_services"); setCollectionArrayToSendToModal("activities"); setCollectionArrayIdToSendToModal(record.activityId) }} className="py-2 hover:cursor-pointer whitespace-nowrap"> <svg xmlns="http://www.w3.org/2000/svg" className="h-4 float-left w-4" viewBox="0 0 20 20" fill="gray">
                                                            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                            <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                        </svg>{activityComplete}</td>                                                                            
                                                    </div>
                                            },
                                            {
                                                title: 'Actions',
                                                dataIndex: 'actions',
                                                width: 70,
                                                render: (text, record) => {
                                                    return props.role.toString().slice(0,1) === "2" && <Options addActivity={() => handleAddActivityClick(serviceRecord.id)} editActivity={() => handleEditActivityClick(serviceRecord.id, record.activityId)} deleteFunction={deleteProjectActivity} deleteObject={{ activityId: record.activityId, serviceId: serviceRecord.id, activityKey: record.activityKey, actualHours: record.actualHours, activityIdInTimesheets: record.activityIdInTimesheets}}></Options>
                                                }
                                            },
                                            {
                                                dataIndex: 'activityId',
                                                render: () => null,
                                            }
                                        ]
                                        //Activities
                                        return (
                                            <div style={{ width: '1400px', margin: '0', marginBottom:'5px' }}>
                                                <Table 
                                                    columns={columnsActivities} 
                                                    dataSource={activitiesDataProcessed.sort((item1, item2) => item1.activity < item2.activity ? -1 : item1.activity > item2.activity ? 1 : 0)}
                                                    size="small"
                                                    rowKey="activityId"
                                                    rowClassName={(record, index) => {
                                                        return 'activity-row'
                                                    }}
                                                    pagination={false}
                                                />
                                            </div>
                                        );
                                    },
                                }}
                                >
                                </Table>

                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="flex flex-col mt-5">
                <h2 className='pl-3 w-full max-w-9xl mx-auto header-8A'>Closed/Other</h2>
                    <div className="overflow-x-auto">
                        <div className="px-3 align-middle inline-block min-w-full mb-5">
                            <div className="overflow-hidden  rounded-2xl shadow-md shadow-slate-200 ">
                            <Table
                                rowKey="id"
                                dataSource={groupedOtherData}
                                size="small"
                                pagination={false}
                                columns={[{
                                    title: 'Department',
                                    dataIndex: 'department',
                                    width: 130,
                                },
                                {
                                    title: 'Service',
                                    dataIndex: 'service',
                                    width: 140,
                                },
                                {
                                    title: 'Comments',
                                    dataIndex: 'serviceComments',
                                    width: 150,
                                    key: 'id',
                                    render: (text, record) => <td onClick={() => { setShowModal(true); setValueToSendToModal(record.serviceComments ); setFieldToSendToModal("serviceComments"); setObjectIdToSendToModal(record.id); setCollectionToSendToModal("projects_services"); setCollectionArrayIdToSendToModal("") }} className="py-2 hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-4 float-left w-4" viewBox="0 0 20 20" fill="gray">
                                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                    </svg>{record.serviceComments}</td>,
                                },
                                {
                                    title: 'Work Stage',
                                    dataIndex: 'workStage',
                                    width: 100,
                                },
                                {
                                    title: 'Service Manager',
                                    dataIndex: 'serviceManager',
                                    width: 110,
                                    key: 'serviceManager',
                                    render: (text, record) => <td onClick={() => { setShowModal(true); setValueToSendToModal(record.serviceManager); setFieldToSendToModal("serviceManager"); setObjectIdToSendToModal(record.id); setCollectionToSendToModal("projects_services"); setCollectionArrayIdToSendToModal("") }} className="py-2 hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-4 float-left w-4" viewBox="0 0 20 20" fill="gray">
                                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                    </svg>{users.find(user => user.id === record.serviceManager).firstName + ' ' + users.find(user => user.id === record.serviceManager).lastName}</td>,
                                },
                                {
                                    title: 'Service Status',
                                    dataIndex: 'serviceStatus',
                                    key: 'serviceStatus',
                                    width: 110,                                                
                                    render: (text, record) => <td onClick={() => { setShowModal(true); setValueToSendToModal(record.serviceStatus); setFieldToSendToModal("serviceStatus"); setObjectIdToSendToModal(record.id); setCollectionToSendToModal("projects_services"); setCollectionArrayIdToSendToModal("") }} className="py-2 hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-4 float-left w-4" viewBox="0 0 20 20" fill="gray">
                                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                    </svg>{record.serviceStatus}</td>
                                },
                                {
                                    title: 'Total Fees',
                                    dataIndex: 'totalActivityFees',
                                    width: 90,
                                    key: 'totalActivityFees',
                                    render: (totalActivityFees) => `${internationalNumberFormat.format(totalActivityFees.toFixed(2))}`, // Show total fees with currency formatting
                                },
                                {
                                    title: 'Consultancy Fees',
                                    dataIndex: 'totalConsultancyFees',
                                    width: 105,
                                    key: 'totalConsultancyFees',
                                    render: (totalConsultancyFees, record) => {
                                        if (props.role.toString().slice(0,1) !== "2") {
                                            return `${internationalNumberFormat.format(totalConsultancyFees.toFixed(2))}`
                                        }else if (props.role.toString().slice(0,1) === "2") {
                                            return <td onDoubleClick={() => { setShowModal(true); setValueToSendToModal(totalConsultancyFees); setFieldToSendToModal("consultancyFee"); setObjectIdToSendToModal(record.id); setCollectionToSendToModal("projects_services"); setCollectionArrayIdToSendToModal("") }} className="py-2 whitespace-nowrap"> {`${internationalNumberFormat.format(totalConsultancyFees.toFixed(2))}`} </td>
                                        }
                                    }
                                },
                                {
                                    title: 'Costs',
                                    dataIndex: 'totalCosts',
                                    width: 80,
                                    key: 'totalCosts',
                                    render: (totalCosts) => `${internationalNumberFormat.format(totalCosts.toFixed(2))}`,
                                },
                                {
                                    title: 'SRR',
                                    dataIndex: 'totalRecoveryRate',
                                    width: 60,
                                    key: 'totalRecoveryRate',
                                    render: (totalRecoveryRate) => isFinite(totalRecoveryRate) ? `${totalRecoveryRate.toFixed(2)}` : "NA",
                                },
                                {
                                    title: 'Inv %',
                                    dataIndex: 'totalActivityFees',
                                    width: 60,
                                    key: 'totalActivityFees',
                                    render: (totalActivityFees, record) => {
                                        let invoiceTotal = 0
                                        invoices.forEach(invoice => {
                                            invoiceTotal +=invoice.invoiceLines.filter(invoiceLine => invoiceLine.serviceId===record.id && invoiceLine.okToInvoice==="YES").map(item => item.value).reduce((prev, curr) => Number(prev) + Number(curr), 0)
                                        });
                                        return `${((invoiceTotal / totalActivityFees)*100).toFixed(0)}%`
                                    }
                                },
                                {
                                    title: 'Client PO',
                                    dataIndex: 'customerPo',
                                    width: 100,
                                    key: 'id',
                                    render: (text, record) => <td onClick={() => { setShowModal(true); setValueToSendToModal(record.customerPo ); setFieldToSendToModal("customerPo"); setObjectIdToSendToModal(record.id); setCollectionToSendToModal("projects_services"); setCollectionArrayIdToSendToModal("") }} className="py-2 hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-4 float-left w-4" viewBox="0 0 20 20" fill="gray">
                                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                    </svg>{record.customerPo}</td>,
                                },
                                {
                                    title: 'Service Client to Invoice',
                                    dataIndex: 'serviceInvoiceClient',
                                    key: 'serviceInvoiceClient',
                                    width: 160,
                                    render: (text, record) => <td onClick={() => { setShowModal(true); setValueToSendToModal(record.serviceInvoiceClient); setFieldToSendToModal("serviceInvoiceClient"); setObjectIdToSendToModal(record.id); setCollectionToSendToModal("projects_services"); setCollectionArrayIdToSendToModal("") }} className="px-2 md:px-2  py-2 hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-4 float-left w-4" viewBox="0 0 20 20" fill="gray">
                                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                    </svg> {record.serviceInvoiceClient !== "" ? clients.find(client => client.id === record.serviceInvoiceClient).clientName : ""}</td>
                                },
                                {
                                    title: 'Service Client Contact',
                                    dataIndex: 'serviceInvoiceClientContact',
                                    key: 'serviceInvoiceClientContact',
                                    width: 105,
                                    render: (text, record) => <td onClick={() => { setShowModal(true); setValueToSendToModal(record.serviceInvoiceClient); setFieldToSendToModal("serviceInvoiceClient"); setObjectIdToSendToModal(record.id); setCollectionToSendToModal("projects_services"); setCollectionArrayIdToSendToModal("") }} className="px-2 md:px-2  py-2 hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-4 float-left w-4" viewBox="0 0 20 20" fill="gray">
                                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                    </svg> {record.serviceInvoiceClientContact !== "" ? contacts.find(contact => contact.id === record.serviceInvoiceClientContact).contactName : ""}</td>
                                },
                                {
                                    title: 'Actions',
                                    dataIndex: 'actions',
                                    width: 70,
                                    render: (text, record) => {
                                        return props.role.toString().slice(0,1) === "2" && <Options addActivity={() => handleAddActivityClick(record.id)} edit={`/addOrUpdateClientProjectService/${match.params.projectId}/${record.id}`} deleteFunction={deleteProjectService} cloneFunction={cloneService} deleteObject={record.id} cloneObject={record.id}></Options>
                                    }
                                },
                                {
                                    title: 'Billing Plan',
                                    dataIndex: 'serviceIncludedInBillingPlan',
                                    width: 90,
                                    render: (text, record) => {
                                        return serviceIncludedInBillingPlan(record.id).toFixed(2) === record.totalActivityFees.toFixed(2) ? <td className="pr-8 whitespace-nowrap"><svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 float-left mr-3" fill="none" viewBox="0 0 24 24" stroke="#0BDA51" strokeWidth="2">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>{internationalNumberFormat.format(record.totalActivityFees.toFixed(2))}</td> : serviceIncludedInBillingPlan(record.id) === 0 ? <td></td> : <td className="px-2 md:px-2  py-2 whitespace-nowrap"><svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 float-left mr-3" fill="none" viewBox="0 0 24 24" stroke="#FFBF00" strokeWidth="2">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                    </svg>{internationalNumberFormat.format(serviceIncludedInBillingPlan(record.id).toFixed(2))}</td>
                                    }
                                },
                                {
                                    title: 'HubSpot Deal',
                                    dataIndex: 'dealNumber',
                                    width: 120,
                                    key: 'dealNumber',
                                    render: (text, record) => <td onClick={() => { setShowModal(true); setValueToSendToModal(record.dealNumber); setFieldToSendToModal("dealNumber"); setObjectIdToSendToModal(record.id); setCollectionToSendToModal("projects_services"); setCollectionArrayIdToSendToModal("") }} className="py-2 hover:cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" className="h-4 float-left w-4" viewBox="0 0 20 20" fill="gray">
                                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                    </svg>{record.dealNumber ? record.dealName : ""}</td>,
                                },
                                {
                                    dataIndex: 'id',
                                    key: 'id',
                                    render: () => null,
                                }]}
                                expandable={{
                                    rowExpandable: (record) => true,
                                    indentSize: 0,
                                    expandedRowRender: (serviceRecord) => {
                                        var actualHours = 0
                                        var complete
                                        var activitiesData = serviceRecord.activities
                                        var activitiesDataProcessed = []
                                        activitiesData.forEach(activity => {
                                            actualHours = props.timesheetData.filter((item) => item.serviceId === serviceRecord.id && item.activityId === activity.activityId).map(item => item.timesheetHours).reduce((prev, curr) => Number(prev) + Number(curr), 0)
                                            complete = (actualHours / activity.estimatedHours) * 100
                                            console.log("timesheetHrs", actualHours)
                                            activitiesDataProcessed.push({ serviceId: serviceRecord.id, activityId:activity.activityId, activity:activity.activity, estimatedHours:activity.estimatedHours, fee:activity.fee, complete: complete, toBeInvoiced:activity.activityToBeInvoiced, actualHours: actualHours, activityComplete:activity.activityComplete, cost:activity.cost, costCategory:activity.costCategory })

                                        });
                                        const columnsActivities = [
                                            {
                                                title: 'Activity',
                                                dataIndex: 'activity',
                                                width: 250
                                            },
                                            {
                                                title: 'Estimated Hours',
                                                dataIndex: 'estimatedHours',
                                                width: 120,
                                                render: (estimatedHours, record) => 
                                                    <div>
                                                        {record.cost === 0 && props.role.toString().slice(0,1) === "2" &&
                                                            <td onClick={() => { setShowModal(true); setValueToSendToModal(record.estimatedHours.toFixed(2)); setFieldToSendToModal("estimatedHours"); setObjectIdToSendToModal(serviceRecord.id); setCollectionToSendToModal("projects_services"); setCollectionArrayToSendToModal("activities"); setCollectionArrayIdToSendToModal(record.activityId) }} className="py-2 hover:cursor-pointer whitespace-nowrap"> <svg xmlns="http://www.w3.org/2000/svg" className="h-4 float-left w-4" viewBox="0 0 20 20" fill="gray">
                                                                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                                <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                            </svg>{record.estimatedHours.toFixed(2)}</td>
                                                        }
                                                        {(record.cost !== 0 || props.role.toString().slice(0,1) !== "2") &&
                                                            <td className="px-4 md:px-2 py-2 hover:cursor-pointer whitespace-nowrap">{record.estimatedHours.toFixed(2)}</td>
                                                        }                                                                    
                                                    </div>
                                            },
                                            {
                                                title: 'Actual Hours',
                                                dataIndex: 'actualHours',
                                                width: 120,
                                                render: (actualHours) => {
                                                    return actualHours.toFixed(2);
                                                }
                                            },
                                            {
                                                title: 'Fees',
                                                dataIndex: 'fee',
                                                width: 120,
                                                render: (fee) => {
                                                    return internationalNumberFormat.format(fee.toFixed(2));
                                                }
                                            },
                                            {
                                                title: 'Costs',
                                                dataIndex: 'cost',
                                                width: 120,
                                                render: (cost, record) => 
                                                    <div>
                                                        {record.estimatedHours === 0 && props.role.toString().slice(0,1) === "2" &&
                                                        <td onClick={() => { setShowModal(true); setValueToSendToModal(record.cost.toFixed(2)); setFieldToSendToModal("cost"); setObjectIdToSendToModal(serviceRecord.id); setCollectionToSendToModal("projects_services"); setCollectionArrayToSendToModal("activities"); setCollectionArrayIdToSendToModal(record.activityId) }} className="py-2 hover:cursor-pointer whitespace-nowrap"> <svg xmlns="http://www.w3.org/2000/svg" className="h-4 float-left w-4" viewBox="0 0 20 20" fill="gray">
                                                            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                            <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                        </svg>{internationalNumberFormat.format(record.cost.toFixed(2))}</td>
                                                        }
                                                        {(record.estimatedHours !== 0 || props.role.toString().slice(0,1) !== "2") &&
                                                            <td className="px-4 md:px-2 py-2 hover:cursor-pointer whitespace-nowrap"> {internationalNumberFormat.format(record.cost.toFixed(2))}</td>
                                                        }
                                                    </div>
                                            },
                                            {
                                                title: 'Cost Category',
                                                dataIndex: 'costCategory',
                                                width: 250,
                                                render: (costCategory, record) => 
                                                    <div>
                                                        {record.estimatedHours === 0 && props.role.toString().slice(0,1) === "2" &&
                                                        <td onClick={() => { setShowModal(true); setValueToSendToModal(record.costCategory); setFieldToSendToModal("costCategory"); setObjectIdToSendToModal(serviceRecord.id); setCollectionToSendToModal("projects_services"); setCollectionArrayToSendToModal("activities"); setCollectionArrayIdToSendToModal(record.activityId) }} className="py-2 hover:cursor-pointer whitespace-nowrap"> <svg xmlns="http://www.w3.org/2000/svg" className="h-4 float-left w-4" viewBox="0 0 20 20" fill="gray">
                                                            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                            <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                        </svg>{record.costCategory}</td>
                                                        }
                                                        {(record.estimatedHours !== 0 || props.role.toString().slice(0,1) !== "2") &&
                                                            <td className="px-4 md:px-2 py-2 hover:cursor-pointer whitespace-nowrap"> {record.costCategory}</td>
                                                        }
                                                    </div>
                                            },
                                            {
                                                title: 'Complete',
                                                dataIndex: 'complete',
                                                width: 120,
                                                render: (activityComplete, record) => {
                                                    return <ActivityProgressBar bgcolor={record.activityComplete === "NO" && record.complete >= 100 ? "#ff0000" : record.activityComplete === "YES" ? "#49fc03" : "#0091ca"} completed={isNaN(record.complete) || record.complete >= 100 ? 100 : Math.round(record.complete)} completedLabel={isNaN(record.complete) ? 100 : Math.round(record.complete) === Infinity ? 100 : Math.round(record.complete)}></ActivityProgressBar>
                                                }
                                            },
                                            {
                                                title: 'Activity Complete',
                                                dataIndex: 'activityComplete',
                                                width: 100,
                                                render: (activityComplete, record) => 
                                                    <div>                                                                            
                                                        <td onClick={() => { setShowModal(true); setValueToSendToModal(record.activityComplete); setFieldToSendToModal("activityComplete"); setObjectIdToSendToModal(serviceRecord.id); setCollectionToSendToModal("projects_services"); setCollectionArrayToSendToModal("activities"); setCollectionArrayIdToSendToModal(record.activityId) }} className="py-2 hover:cursor-pointer whitespace-nowrap"> <svg xmlns="http://www.w3.org/2000/svg" className="h-4 float-left w-4" viewBox="0 0 20 20" fill="gray">
                                                            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                            <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                        </svg>{activityComplete}</td>                                                                            
                                                    </div>
                                            },
                                            {
                                                title: 'Actions',
                                                dataIndex: 'actions',
                                                width: 70,
                                                render: (text, record) => {
                                                    return props.role.toString().slice(0,1) === "2" && <Options addActivity={() => handleAddActivityClick(serviceRecord.id)} editActivity={() => handleEditActivityClick(serviceRecord.id, record.activityId)} deleteFunction={deleteProjectActivity} deleteObject={{ activityId: record.activityId, serviceId: serviceRecord.id, activityKey: record.activityKey, actualHours: record.actualHours, activityIdInTimesheets: record.activityIdInTimesheets}}></Options>
                                                }
                                            },
                                            {
                                                dataIndex: 'activityId',
                                                render: () => null,
                                            }
                                        ]
                                        //Activities
                                        return (
                                            <div style={{ width: '1400px', margin: '0', marginBottom:'5px' }}>
                                                <Table 
                                                    columns={columnsActivities} 
                                                    dataSource={activitiesDataProcessed.sort((item1, item2) => item1.activity < item2.activity ? -1 : item1.activity > item2.activity ? 1 : 0)}
                                                    size="small"
                                                    rowKey="activityId"
                                                    rowClassName={(record, index) => {
                                                        return 'activity-row'
                                                    }}
                                                    pagination={false}
                                                />
                                            </div>
                                        );
                                    },
                                }}
                                >
                                </Table>
                            <ModalAddToBillingPlan arrayToShow={projectServices} serviceDetails={valueToSendToModal} role={props.role} onClose={() => {setShowModalAddToBillingPlan(false); setInvoiceLineAdded(true)}} show={showModalAddToBillingPlan} title="Add to Billing Plan" />
                            <ModalAddEditActivity serviceIdToUpdate={serviceIdToSendToModal} activityIdToUpdate={activityIdToSendToModal} arrayToUpdate={collectionArrayToSendToModal} users={users} onClose={() => {setShowModalAddEditActivity(false); }} afterEdit={() => {setAfterEditActivity(true)}} show={showModalAddEditActivity} title="Add/Edit an Activity" modalUse={activityModalUse} />
                            <ModalUpdateValue objectToUpdate={objectIdToSendToModal} fieldToUpdate={fieldToSendToModal} valueToUpdate={valueToSendToModal} collectionToUpdate={collectionToSendToModal} arrayToUpdate={collectionArrayToSendToModal} arrayIdToUpdate={collectionArrayIdToSendToModal} arrayToShow={collectionArrayIdToSendToModal === "" ? projectServices : projectServices.find(item => item.id === objectIdToSendToModal).activities} role={props.role} userDepartment={props.userDepartment} onClose={() => setShowModal(false)} show={showModal} title="Update Service" />
                            </div>
                        </div>
                    </div>
                </div>

                <h2 className='pt-4 pl-3 w-full max-w-9xl mx-auto header-8A'>Add new service</h2>
                <div className="grid sm:grid-cols-1 lg:grid-cols-6 gap-3 px-3">
                    <div className="form-control-8A">
                        <label>Department</label>
                        <select value={department} onFocus={(e) => setDepartment(e.target.value)} onChange={(e) => setDepartment(e.target.value)}>
                            {departmentsList.map((department) => (
                                <option key={department.department} value={department.department}>{department.department}</option>
                            ))}
                        </select>
                    </div>
                    {department && (
                        <div className="form-control-8A">
                            <label>Work Stage</label>
                            <select value={workStage} onFocus={(e) => setWorkStage(e.target.value)} onChange={(e) => setWorkStage(e.target.value)}>
                                {workStagesList.map((workStage) => (
                                    <option key={workStage.workStage} value={workStage.workStage}>{workStage.workStage}</option>
                                ))}
                            </select>
                        </div>
                    )
                    }
                    {!department && (
                        <div className="form-control-8A">
                            <label className="text-red-500 text-xs">Select Department</label>
                            <div className="text-red-500 text-xs">to Add New Service</div>
                        </div>
                    )
                    }
                    {workStage && (
                        <div className="form-control-8A">
                            <label>Service</label>
                            <select value={service} onFocus={(e) => setService(e.target.value)} onChange={(e) => setService(e.target.value)}>
                                {servicesList.map((service) => (
                                    <option key={service.service} value={service.service}>{service.service}</option>
                                ))}
                            </select>
                        </div>
                    )
                    }
                    {service && (
                        <div className="form-control-8A">
                            <label>Service Manager</label>
                            <select value={serviceManager} onChange={(e) => setServiceManager(e.target.value)}>
                                {users.filter(item => item.role !== 9999 && item.role !== 9998 && item.role !== 3001).map((user) => (
                                    <option key={user.id} value={user.id}>{user.firstName + ' ' + user.lastName}</option>
                                ))}
                            </select>
                        </div>
                    )
                    }
                    {service && (
                        <div className="form-control-8A">
                            <label>Activity Owner</label>
                            <select value={activityOwner} onChange={(e) => setActivityOwner(e.target.value)}>
                                {users.filter(item => item.role !== 9999 && item.role !== 9998 && item.role !== 3001).map((user) => (
                                    <option key={user.id} value={user.id}>{user.firstName + ' ' + user.lastName}</option>
                                ))}
                            </select>
                        </div>
                    )
                    }
                    {service && (
                        <div className="form-control-8A pt-8 pl-3">
                            <Button color="bondi-blue" text="Add New Service >>" onClick={() => addService()} />
                        </div>
                    )
                    }

                </div>

                <div className="grid sm:grid-cols-1 lg:grid-cols-1 gap-3 px-3">
                    <div className="form-control-8A">
                        <label>Actions</label>
                        <div className="flex items-center pt-1">
                            <form>
                                <input id="csvFileInput" accept={".csv, .xlsx, .xls"} type={"file"} onChange={handleOnChange} />
                                <label className='' htmlFor="csvFileInput">Select Fee Calculation File to Import</label>
                                {file &&
                                    <Button color="bondi-blue" text="Import Data" onClick={(e) => { handleOnSubmit(e) }} />
                                }
                            </form>
                        </div>
                    </div>
                </div>
                
                <br></br>
                <div><div className="flex items-center bg-slate-100">
                    <div className="w-full mx-auto mb-5 px-3">
                        <div className="grid gap-7 sm:grid-cols-5 ">

                            <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                <div>
                                    <div className="text-sm text-gray-500 ">Total Live & Completed</div>
                                    <div className="flex items-center pt-1">
                                        <div className="text-xl font-medium text-indigo-500 ">
                                            <p className="my-1">{props.currency} {internationalNumberFormat.format(myArrayTotal)} </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-gray-400">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 9a2 2 0 10-4 0v5a2 2 0 01-2 2h6m-6-4h4m8 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </div>
                            </div>
                            <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                <div>
                                    <div className="text-sm text-gray-500 ">Total Live</div>
                                    <div className="flex items-center pt-1">
                                        <div className="text-xl font-medium text-indigo-500 ">
                                            <p className="my-1">{props.currency} {internationalNumberFormat.format(myArrayTotalLive)} </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-gray-400">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 9a2 2 0 10-4 0v5a2 2 0 01-2 2h6m-6-4h4m8 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </div>
                            </div>
                            <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                <div>
                                    <div className="text-sm text-gray-500 ">Total Closed Completed</div>
                                    <div className="flex items-center pt-1">
                                        <div className="text-xl font-medium text-indigo-500 ">
                                            <p className="my-1">{props.currency} {internationalNumberFormat.format(myArrayTotalClosedComplete)} </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-gray-400">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 9a2 2 0 10-4 0v5a2 2 0 01-2 2h6m-6-4h4m8 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </div>
                            </div>
                            <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                <div>
                                    <div className="text-sm text-gray-500 ">Total Quote</div>
                                    <div className="flex items-center pt-1">
                                        <div className="text-xl font-medium text-indigo-500 ">
                                            <p className="my-1">{props.currency} {internationalNumberFormat.format(myArrayTotalQuote)} </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-gray-400">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 9a2 2 0 10-4 0v5a2 2 0 01-2 2h6m-6-4h4m8 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </div>
                            </div>
                            <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                <div>
                                    <div className="text-sm text-gray-500 ">Total Lost / Cancelled</div>
                                    <div className="flex items-center pt-1">
                                        <div className="text-xl font-medium text-indigo-500 ">
                                            <p className="my-1">{props.currency} {internationalNumberFormat.format(myArrayTotalOther)} </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-gray-400">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 9a2 2 0 10-4 0v5a2 2 0 01-2 2h6m-6-4h4m8 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        )

    } else {

        return (
            <div>
                <br></br>
                <h2 className='pt-4 pl-3  pb-2 w-full max-w-9xl mx-auto header-8A'>Services</h2>
                <div className="flex items-center">
                    <div className="w-full mx-auto m-5 px-3">
                        <div className="grid grid-cols-2 gap-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-8">
                            
                            <div>
                                <p className="text-xs">Department</p>
                                <ReactSelect
                                    styles={styles}
                                    value={selectedDepartment}
                                    onChange={setSelectedDepartment}
                                    options={aggregateDepartmentsArray(projectServices).sort((item1, item2) => item1.department < item2.department ? -1 : item1.department > item2.department ? 1 : 0).map((item) => {
                                        return {
                                            label: item.department,
                                            value: item.department
                                        }
                                    })} />
                            </div>

                            <div>
                                <p className="text-xs">Service</p>
                                <ReactSelect
                                    styles={styles}
                                    value={selectedService}
                                    onChange={setSelectedService}
                                    options={aggregateServicesArray(projectServices).sort((item1, item2) => item1.service < item2.service ? -1 : item1.service > item2.service ? 1 : 0).map((item) => {
                                        return {
                                            label: item.service,
                                            value: item.service
                                        }
                                    })} />
                            </div>

                            <div>
                                <p className="text-xs">Work Stage</p>
                                <ReactSelect
                                    styles={styles}
                                    value={selectedWorkStage}
                                    onChange={setSelectedWorkStage}
                                    options={aggregateWorkStagesArray(projectServices).sort((item1, item2) => item1.workStage < item2.workStage ? -1 : item1.workStage > item2.workStage ? 1 : 0).map((item) => {
                                        return {
                                            label: item.workStage,
                                            value: item.workStage
                                        }
                                    })} />
                            </div>

                            <div>
                                <p className="text-xs">Service Manager</p>
                                <ReactSelect
                                    styles={styles}
                                    value={selectedServiceManager}
                                    onChange={setSelectedServiceManager}
                                    options={aggregateServiceManagersArray(projectServices).sort((item1, item2) => item1.serviceManager < item2.serviceManager ? -1 : item1.serviceManager > item2.serviceManager ? 1 : 0).map((item) => {
                                        return {
                                            label: item.serviceManagerName,
                                            value: item.serviceManager
                                        }
                                    })} />
                            </div>

                            {/* <div>
                                <p className="text-xs">Service Status</p>
                                <ReactSelect
                                    styles={styles}
                                    value={selectedServiceStatus}
                                    onChange={setSelectedServiceStatus}
                                    options={aggregateServiceStatusesArray(projectServices).sort((item1, item2) => item1.serviceStatus < item2.serviceStatus ? -1 : item1.serviceStatus > item2.serviceStatus ? 1 : 0).map((item) => {
                                        return {
                                            label: item.serviceStatus,
                                            value: item.serviceStatus
                                        }
                                    })} />
                            </div> */}

                            <div>
                                <p className="text-xs">Client PO</p>
                                <ReactSelect
                                    styles={styles}
                                    value={selectedCustomerPo}
                                    onChange={setSelectedCustomerPo}
                                    options={aggregateCustomerPoArray(projectServices).sort((item1, item2) => item1.customerPo < item2.customerPo ? -1 : item1.customerPo > item2.customerPo ? 1 : 0).map((item) => {
                                        return {
                                            label: item.customerPo,
                                            value: item.customerPo
                                        }
                                    })} />
                            </div>

                            <div>
                                <p className="text-xs">Hub Spot Deal</p>
                                <ReactSelect
                                    styles={styles}
                                    value={selectedDealNumber}
                                    onChange={setSelectedDealNumber}
                                    options={aggregateDealNumbersArray(projectServices).sort((item1, item2) => item1.dealNumber < item2.dealNumber ? -1 : item1.dealNumber > item2.dealNumber ? 1 : 0).map((item) => {
                                        return {
                                            label: item.dealName,
                                            value: item.dealNumber
                                        }
                                    })} />
                            </div>

                            <div className="">
                                <p className="text-xs font-bold pl-2">Filters</p>
                                <Button color="bg-bondi-blue" text="Clear Filters" onClick={() => clearFilters()} />
                            </div>
                        </div>
                    </div>
                </div>
                <h2 className='pt-4 pl-3 w-full max-w-9xl mx-auto header-8A'>Add new service</h2>
                <div className="grid sm:grid-cols-1 lg:grid-cols-6 gap-3">
                    <div className="form-control-8A">
                        <label>Department</label>
                        <select value={department} onFocus={(e) => setDepartment(e.target.value)} onChange={(e) => setDepartment(e.target.value)}>
                            {departmentsList.map((department) => (
                                <option key={department.department} value={department.department}>{department.department}</option>
                            ))}
                        </select>
                    </div>
                    {department && (
                        <div className="form-control-8A">
                            <label>Work Stage</label>
                            <select value={workStage} onFocus={(e) => setWorkStage(e.target.value)} onChange={(e) => setWorkStage(e.target.value)}>
                                {workStagesList.map((workStage) => (
                                    <option key={workStage.workStage} value={workStage.workStage}>{workStage.workStage}</option>
                                ))}
                            </select>
                        </div>
                    )
                    }
                    {!department && (
                        <div className="form-control-8A">
                            <label className="text-red-500 text-xs">Select Department</label>
                            <div className="text-red-500 text-xs">to Add New Service</div>
                        </div>
                    )
                    }
                    {workStage && (
                        <div className="form-control-8A">
                            <label>Service</label>
                            <select value={service} onFocus={(e) => setService(e.target.value)} onChange={(e) => setService(e.target.value)}>
                                {servicesList.map((service) => (
                                    <option key={service.service} value={service.service}>{service.service}</option>
                                ))}
                            </select>
                        </div>
                    )
                    }
                    {service && (
                        <div className="form-control-8A">
                            <label>Service Manager</label>
                            <select value={serviceManager} onChange={(e) => setServiceManager(e.target.value)}>
                                {users.map((user) => (
                                    <option key={user.id} value={user.id}>{user.firstName + ' ' + user.lastName}</option>
                                ))}
                            </select>
                        </div>
                    )
                    }
                    {service && (
                        <div className="form-control-8A">
                            <label>Activity Owner</label>
                            <select value={activityOwner} onChange={(e) => setActivityOwner(e.target.value)}>
                                {users.map((user) => (
                                    <option key={user.id} value={user.id}>{user.firstName + ' ' + user.lastName}</option>
                                ))}
                            </select>
                        </div>
                    )
                    }
                    {service && (
                        <div className="form-control-8A pt-8">
                            <Button color="bondi-blue" text="Add New Service >>" onClick={() => addService()} />
                        </div>
                    )
                    }
                </div>
                <div className="grid sm:grid-cols-1 lg:grid-cols-1 gap-3 px-3">
                    <div className="form-control-8A">
                        <label>Actions</label>
                        <div className="flex items-center pt-1">
                            <form>
                                <input id="csvFileInput" accept={".csv"} type={"file"} onChange={handleOnChange} />
                                <label className='' htmlFor="csvFileInput">Select Fee Calculation File to Import</label>
                                <Button color="bondi-blue" text="Import Data" onClick={(e) => { handleOnSubmit(e) }} />
                            </form>
                        </div>
                    </div>
                </div>
                <br></br>
                <br></br>

            </div>)

    }

}

export default ListClientProjectServices