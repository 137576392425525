import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom'
import AuthService from "../services/AuthService";
import axios from 'axios'
import { zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core'
import * as zxcvbnCommonPackage from '@zxcvbn-ts/language-common'
import * as zxcvbnEnPackage from '@zxcvbn-ts/language-en'


const ResetPassword = () => {
    const [loading, setLoading] = useState(false)

    const [oldPassword, setOldPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [repeatPassword, setRepeatPassword] = useState("")
    const [passwordError, setPasswordError] = useState("")
    const [passwordStrenght, setPasswordStrenght] = useState(0)
    const [status, setStatus] = useState("")
    const navigate = useNavigate()


    const options = {
        translations: zxcvbnEnPackage.translations,
        graphs: zxcvbnCommonPackage.adjacencyGraphs,
        dictionary: {
            ...zxcvbnCommonPackage.dictionary,
            ...zxcvbnEnPackage.dictionary,
        },
    }
    zxcvbnOptions.setOptions(options)


    function refreshPage() {
        window.location.reload();
    }

    useEffect(() => {
        if (newPassword === repeatPassword & repeatPassword.length>3) {
            setPasswordError("Matching passwords ✔️")
        }

        else if (repeatPassword.length>3) {
            setPasswordError("Passwords don't match ✖️")
        }

    })
    useEffect(() => {
        if (newPassword.length > 0) {
            setPasswordStrenght(zxcvbn(newPassword).score)
            console.log(zxcvbn(newPassword).feedback.warning)

        }
    })

    useEffect(() => {
        setLoading(false)
    }, [])

    const addForm = async (form) => {
        await axios.post(process.env.REACT_APP_API_URL + '/reset_password', form).then(res => { setStatus(res.data) })
    }


    const onSubmit = (e) => {
        e.preventDefault()
        addForm({ oldPassword, newPassword, repeatPassword })
        //.then(res => { navigate(-1) })
    }


    if (!loading) {
        return (
            <div className='flex items-center bg-jungle-mist-100 min-h-screen'>
                <div className="container-form-8A w-1/2">

                    <p className=' text-2xl text-congress-blue font-bold'>Reset password</p>

                    <form className="add-form-8A" onSubmit={onSubmit}>

                        <div className="form-control-8A">
                            <label className=' text-sm font-bold'>Old password</label>
                            <input type="password" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
                        </div>

                        
                        <div className="form-control-8A">
                            <label className=' text-sm font-bold'>New password</label>
                            <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                        </div>
                        
                        <div className="form-control-8A">
                            <label className=' text-sm font-bold'>Confirm new password</label>
                            <input type="password"  value={repeatPassword} onChange={(e) => setRepeatPassword(e.target.value)} />
                        </div>

                        <p className='text-xs'>{zxcvbn(newPassword).feedback.warning}</p>
                        <hr className={`rounded-full border-4 border-slate-300 bg-slate-300 my-1 ${passwordStrenght >= 3 ? 'border-emerald-500 bg-emerald-500' : ''}`}></hr>
                        <p className='text-xs'>Password strength: {passwordStrenght}/4. Minimum 4/4.</p>
                        <p className='text-xs'>{passwordError}</p>
                        <p className='text-xs font-bold'>{status}</p>

                        <input type="submit" value="Save" className={`bg-emerald-500 block mx-auto text-white text-sm uppercase rounded-full shadow-slate-300 shadow-md px-8 py-3 mt-4 cursor-not-allowed ${(passwordStrenght >= 3 && passwordError==="Matching passwords ✔️" )? 'cursor-pointer' : ''}`} />
                        <div className=' w-24'><p className='underline text-congress-blue cursor-pointer' onClick={()=>navigate(-1)}>Go back</p></div>
                    </form>
                </div>
            </div>
        );
    }



    else {

        return (
            <p>Data is loading...</p>
        );
    }
}

export default ResetPassword;