import { useEffect, useState } from 'react'
import axios from 'axios'
import Button from '../UI/Button'
import Header from '../UI/Header'
import { useNavigate } from 'react-router-dom'
import Sidebar from '../UI/Sidebar'
import Options from '../UI/Options'

const ListBuildings = () => {

    const navigate = useNavigate()

    const [buildings, setBuildings] = useState([])

    const [sidebarToggle, setSidebarToggle] = useState(false)

    const [loading, setLoading] = useState(false)

    useEffect(() => {

        setLoading(true)

        axios.get(process.env.REACT_APP_API_URL + '/buildings')
            .then((res) => res.data)
            .then((res) => {
                setBuildings(res)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });

    }, [])

    const deleteBuilding = (id) => {

        window.confirm('Are you sure you want to delete it?') && (axios.delete(process.env.REACT_APP_API_URL + '/buildings/' + id).then(setBuildings(buildings.filter((building) => building.id !== id))))

        //setBuildings(buildings.filter((building) => building.id !== id))
    }

    const editBuilding = (id) => {
        navigate(`/addOrUpdateBuilding/${id}`)

    }

    const viewBuilding = (id) => {
        navigate(`/viewBuilding/${id}`);
    }

    if (buildings.length > 0 && !loading) {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">

                    {sidebarToggle &&
                        <Sidebar />
                    }
                        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">

                        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                        <Header title="Buildings" section="Select Building" iconFileName="Building-Hover" showUserMenu />

                        <div className="flex flex-col py-2">
                            <div className="overflow-x-auto items-holder-8A">
                                <div className="text-eight-text align-middle inline-block min-w-full">
                                    <div className="shadow-md shadow-slate-300 rounded-2xl">

                                <table className="objects-8A min-w-full divide-y divide-gray-200">
 
                                    <thead className="bg-pacific-blue-50 z-10 sticky top-0">
                                        <tr>
                                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Building Name</th>
                                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"></th>
                                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Address</th>
                                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Area (m2)</th>
                                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Building Type</th>
                                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> EPC Rating</th>
                                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Operational Carbon Emissions (tonnes CO2/m2)</th>
                                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Operational Label</th>
                                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Construction carbon emissions (tonnes CO2/m2)​</th>
                                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Construction Label</th>
                                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Environmental Assessment</th>
                                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Enviromental Assessment scoring</th>
                                            <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Actions</th>
                                        </tr>
                                    </thead>

                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {buildings.map((building) => (
                                            <tr key={building.id}>
                                                <td onClick={() => viewBuilding(building.id)} className="px-4 md:px-6  py-2 hover:cursor-pointer"> {building.buildingName}</td>
                                                <td onClick={() => viewBuilding(building.id)} className="py-1 hover:cursor-pointer">                                         
                                                    <div className='bg-slate-200 w-full'>
                                                        <img className="aspect-square text-center rounded-md w-full" src={`https://storage.googleapis.com/public_images_bucket/${building.id}/` + building.buildingName + ".jpg"} onError={(e) => { e.target.src = "/images/building_generic.png" }} alt="loading..." />
                                                        {/* <img className="aspect-square text-center rounded-md" src={("/images/building_images/" + building.buildingName + ".jpg")} onError={(e) => { e.target.src = "/images/building_generic.png" }} alt="loading..." /> */}
                                                    </div>
                                                </td>
                                                <td onClick={() => viewBuilding(building.id)} className="px-4 md:px-6  py-2 hover:cursor-pointer"> {building.buildingAddress} </td>
                                                <td onClick={() => viewBuilding(building.id)} className="px-4 md:px-6  py-2 hover:cursor-pointer"> {building.buldingTotalAreaSqMetres} </td>
                                                <td onClick={() => viewBuilding(building.id)} className="px-4 md:px-6  py-2 hover:cursor-pointer"> {building.buildingType} </td>
                                                <td onClick={() => viewBuilding(building.id)} className="px-4 md:px-6  py-2 hover:cursor-pointer"></td>
                                                <td onClick={() => viewBuilding(building.id)} className="px-4 md:px-6  py-2 hover:cursor-pointer"></td>
                                                <td onClick={() => viewBuilding(building.id)} className="px-4 md:px-6  py-2 hover:cursor-pointer"></td>
                                                <td onClick={() => viewBuilding(building.id)} className="px-4 md:px-6  py-2 hover:cursor-pointer"></td>
                                                <td onClick={() => viewBuilding(building.id)} className="px-4 md:px-6  py-2 hover:cursor-pointer"></td>
                                                <td onClick={() => viewBuilding(building.id)} className="px-4 md:px-6  py-2 hover:cursor-pointer"></td>
                                                <td onClick={() => viewBuilding(building.id)} className="px-4 md:px-6  py-2 hover:cursor-pointer"></td>
                                                <td className="px-4 md:px-6 py-2 whitespace-nowrap md:pr-5">
                                                    <Options edit={`/addOrUpdateBuilding/${building.id}`} deleteFunction={() => deleteBuilding(building.id)} deleteObject={building.id}></Options>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                        <br></br>
                        <Button color="bg-bondi-blue" text="Add New Building" onClick={() => navigate('/addOrUpdateBuilding/_add')} />
                    </div>
                </div>
            </div>
            </div>
        )

    } else {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Buildings" section="Select Building" iconFileName="Building-Hover" showUserMenu />
                        <p>Data is loading</p>
                    </div>

                </div>

            </div>
        )

    }

}

export default ListBuildings
