import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Transition } from '@headlessui/react'
import AuthService from '../../services/AuthService';

function UserMenuNew() {

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [userName, setUserName] = useState('');

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      //if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
      if (!dropdownOpen || trigger.current.contains(target)) return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {

    setUserName(AuthService.getCurrentUserName())

  }, []);

  return (
    <div className="relative inline-flex z-50">
      <button
        ref={trigger}
        className="inline-flex justify-center items-center group"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        {/* <img className="h-10 hidden sm:inline-block" src="/images/NCS-Logo-Rev-02.svg" alt="NCS" /> */}
        <div className="flex items-center">
          {/* <span className=" hidden sm:inline-block"><img className="h-8 m-2" src="/images/eight-versa-colour.png" alt=""/></span> */}
          {/* <img className="h-8 m-2 rounded-full" src={"/images/user_avatar_generic.png"} alt="User" /> */}
          {/* <span className=" hidden sm:inline-block"><img className="logo-8A" src="/images/EA-Logo-Blue.svg" alt=""/></span> */}
          {/* <span className=" hidden sm:inline-block"><img className="logo-8A" src="/images/NCS-Logo-Rev-02.svg" alt=""/></span> */}
          <span className=" hidden sm:inline-block"><img className="h-12 pl-2" src="/images/eight-versa-colour.png" alt=""/></span>
          <svg className="w-4 h-4 shrink-0 ml-1 fill-current text-congress-blue" viewBox="0 0 12 12">
            <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
          </svg>
        </div>
      </button>

      <Transition
        className="origin-top-right backdrop-blur-sm bg-white/30 ring-1 ring-slate-500 ring-opacity-5 z-10 absolute top-full right-0 min-w-44 py-1.5 rounded-xl shadow-lg shadow-slate-300 overflow-hidden mt-1"
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        leave="transition ease-out duration-200"
      >
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          <div className="pt-0.5 pb-2 px-3 mb-1 border-b border-slate-200">
            <div className=" text-slate-800 text-sm md:text-base">{userName}</div>
            {/* <div className="mt-2 text-xs text-slate-500 italic">Administrator</div> */}
          </div>
          <ul>
            <li>
              <Link
                className="font-medium text-sm text-pacific-blue-600 hover:underline flex items-center py-1 px-3"
                to="/account/settings"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                Settings
              </Link>
            </li>
            <li>
              <Link
                className="font-medium text-sm text-pacific-blue-600 hover:underline flex items-center py-1 px-3"
                to="/"
                onClick={() => {AuthService.logout(); sessionStorage.setItem("siteArea", "")}}
              >
                Sign Out
              </Link>
            </li>
          </ul>
        </div>
      </Transition>
    </div>
  )
}

export default UserMenuNew;