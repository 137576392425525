import { useState, useEffect } from 'react'
import axios from 'axios'
import Header from '../UI/Header'
// import ProgressBar from './ProgressBar'
import { useNavigate, useMatch } from 'react-router-dom'

const AddOrUpdateProduct = () => {

    const match = useMatch('/addOrUpdateProduct/:productId')

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)

    const [productName, setProductName] = useState('')
    const [productDescription, setProductDescription] = useState('')
    const [productLCAGoalAndScope, setProductLCAGoalAndScope] = useState('')

    useEffect(() => {
        if (match.params.productId !== "_add") {

            setLoading(true)

            axios.get(process.env.REACT_APP_API_URL + '/products/' + match.params.productId)
                .then((res)  => {
                    setProductName(res.data.productName)
                    setProductDescription(res.data.productDescription)
                    setProductLCAGoalAndScope(res.data.productLCAGoalAndScope)
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });
        }
    }, [match.params.productId])

    const addForm = async (form) => {
        await axios.post(process.env.REACT_APP_API_URL + '/products', form)
    }

    const updateForm = async (form) => {
        await axios.put(process.env.REACT_APP_API_URL + '/products/' + match.params.productId, form)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (match.params.productId !== "_add") {
            updateForm({ productName, productDescription, productLCAGoalAndScope }).then(res => { navigate(-1) })
        } else {
            addForm({ productName, productDescription, productLCAGoalAndScope }).then(res => { navigate(-1) })
        }
    }

    if (!loading) {

        return (

            <div className="container-form-8A">

                <Header title="Products - Add / Edit Product" section="Products" iconFileName="Product-Hover" />

                <br></br>

                <form className="add-form-8A" onSubmit={onSubmit}>

                    <div className="form-control-8A">
                        <label>Product Name</label>
                        <input type="text" placeholder="Enter Product Name Here" value={productName} onChange={(e) => setProductName(e.target.value)} />
                    </div>
                    <div className="form-control-8A">
                        <label>Product Description</label>
                        <input type="text" placeholder="Enter Product Description Here" value={productDescription} onChange={(e) => setProductDescription(e.target.value)} />
                    </div>
                    <div className="form-control-8A">
                        <label>LCA Goal and Scope</label>
                        <input type="text" placeholder="Enter LCA Goal and Scope Here" value={productLCAGoalAndScope} onChange={(e) => setProductLCAGoalAndScope(e.target.value)} />
                    </div>

                    <input type="submit" value="Save" className="btn-8A btn-block-8A" />

                </form>

            </div>
        )

    } else {

        return (
            <div>
                <Header title="Products - Add / Edit Product" section="Products" iconFileName="Product-Hover" />
                <p>Data is loading...</p>
            </div>)

    }

}

export default AddOrUpdateProduct
