import { useEffect, useState } from "react";
import Header from "../UI/Header";
import axios from "axios";
import { Doughnut, Bar, Line } from "react-chartjs-2";
import Sidebar from "../UI/Sidebar";
import Table from "../UI/Table";
import CountUp from "react-countup";
import { MultiSelect } from "react-multi-select-component";

import {
    Chart as ChartJS,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle,
} from "chart.js";

ChartJS.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
);


const Dashboard = () => {
    const [loading, setLoading] = useState(false);

    const [sidebarToggle, setSidebarToggle] = useState(false);

    const [organisationFootprints, setOrganisationFootprints] = useState([]);
    const [organisationFootprintItems, setOrganisationFootprintItems] = useState([]);
    const [organisationFootprint, setOrganisationFootprint] = useState([]);
    const [organisationFootprintId, setOrganisationFootprintId] = useState("6391cc6320f109fc670c97f9");
    const [organisationId, setOrganisationId] = useState("6317284a832d4033fa992a55");
    const [organisations, setOrganisations] = useState([]);
    const [carbonEmissions, setCarbonEmissions] = useState(0);
    const [labels, setLabels] = useState(0);
    const [lineChartCarbonEmissions, setLineChartCarbonEmissions] = useState([]);
    const [lineChartLabels, setLineChartLabels] = useState([]);
    const [stackedLineChartCarbonEmissions, setStackedLineChartCarbonEmissions] = useState([]);
    const [stackedLineChartLabels, setStackedLineChartLabels] = useState([]);
    const [buildings, setBuildings] = useState([]);
    const [allBuildings, setAllBuildings] = useState([]);
    const [buildingsList, setBuildingsList] = useState([]);
    const [buildingCountry, setBuildingCountry] = useState("United Kingdom");
    const [stores, setStores] = useState([]);
    const [credits, setCredits] = useState([]);
    const [projects, setProjects] = useState([]);
    const [projectId, setProjectId] = useState("62164ad216c4cfb00e0f25c6");
    const [events, setEvents] = useState([]);
    const [sites, setSites] = useState([]);
    const [products, setProducts] = useState([]);

    const [allScopeItems, setAllScopeItems] = useState([]);
    const [allScopeItemsHeadings, setAllScopeItemsHeadings] = useState([]);
    const [allSubCategories, setAllSubCategories] = useState([]);
    const [allSubCategoriesHeadings, setAllSubCategoriesHeadings] = useState([]);

    const [selected, setSelected] = useState([{value: "test", label: "test"}]);

    // const [carbonEmissionsByYear, setCarbonEmissionsByYear] = useState([]);

    const getHeadings = (data) => {
        var keys = Object.keys(data[0])
        //This takes the last header and moves it to the first
        keys.splice(0, 0, keys.pop())
        return keys;
    }

    useEffect(() => {
        setLoading(true);

        axios.get(process.env.REACT_APP_API_URL + "/organisations_footprints")
            .then((res) => {
                setOrganisationFootprints(
                    res.data.sort((item1, item2) =>
                        item1.organisationFootprintRef < item2.organisationFootprintRef
                            ? -1
                            : item1.organisationFootprintRef > item2.organisationFootprintRef
                                ? 1
                                : 0
                    )
                );
                setOrganisationFootprintItems(
                    res.data.filter(
                        (organisationFootprint) =>
                            organisationFootprint.id === organisationFootprintId
                    )[0].organisationFootprintItems
                );
                setOrganisationFootprint(
                    res.data.filter(
                        (organisationFootprint) =>
                            organisationFootprint.id === organisationFootprintId
                    )
                );
                var concatenatedListOfScopeItems = [];

                var i;
                for (i = 0; i < res.data.length; i++) {
                    if (res.data[i].organisationId === organisationId) {
                        concatenatedListOfScopeItems = concatenatedListOfScopeItems.concat(
                            res.data[i].organisationFootprintItems
                        );
                    }
                }

                // setAllScopeItems(concatenatedListOfScopeItems)

                var result = [];
                concatenatedListOfScopeItems.reduce(function (res, value) {
                    if (!res[value.itemSubCategory]) {
                        res[value.itemSubCategory] = {
                            itemSubCategory: value.itemSubCategory,
                            carbonEmissions: 0,
                        };
                        result.push(res[value.itemSubCategory]);
                    }
                    res[value.itemSubCategory].carbonEmissions +=
                        value.carbonEmissions;
                    return res;
                }, {});

                const scopeSubCategoryData = result.map((item) => item.carbonEmissions);
                const scopeSubCategoryLabels = result.map(
                    (item) => item.itemSubCategory
                );

                setLabels(scopeSubCategoryLabels);
                setCarbonEmissions(scopeSubCategoryData);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });

        axios.get(process.env.REACT_APP_API_URL + "/organisations")
            .then((res) => {
                setOrganisations(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });

        axios.get(process.env.REACT_APP_API_URL + "/buildings")
            .then((res) => {
                setBuildings(res.data);
                const buildingsGroupedByCountry = [
                    ...new Set(res.data.map((item) => item.buildingCountry)),
                ];
                setBuildingsList(buildingsGroupedByCountry);
                setAllBuildings(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });

        axios.get(process.env.REACT_APP_API_URL + "/stores")
            .then((res) => {
                setStores(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });

        axios.get(process.env.REACT_APP_API_URL + "/offset_credits")
            .then((res) => {
                setCredits(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });

        axios.get(process.env.REACT_APP_API_URL + "/events")
            .then((res) => {
                setEvents(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });

        axios.get(process.env.REACT_APP_API_URL + "/sites")
            .then((res) => {
                setSites(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });

        axios.get(process.env.REACT_APP_API_URL + "/products")
            .then((res) => {
                setProducts(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {

        axios.get(process.env.REACT_APP_API_URL + "/organisations_footprints")
            .then((res) => {
                var concatenatedListOfScopeItems = [];
                var arrayOfYearlyEmissionsBySubCategory = [];
                var index = 0

                var filteredRes = []

                filteredRes = res.data.filter((itemA)=> {
                    return selected.find((itemB)=> {
                        return itemA.organisationFootprintRef === itemB.value;
                    })
                })

                var i;
                for (i = 0; i < filteredRes.length; i++) {
                    if (filteredRes[i].organisationId === organisationId) {
                        concatenatedListOfScopeItems = concatenatedListOfScopeItems.concat(
                            filteredRes[i].organisationFootprintItems
                        );
                    }
                }


                // var i;
                // for (i = 0; i < res.data.length; i++) {
                //     if (res.data[i].organisationId === organisationId) {
                //         concatenatedListOfScopeItems = concatenatedListOfScopeItems.concat(
                //             res.data[i].organisationFootprintItems
                //         );
                //     }
                // }

                const listOfFootprintItems = [
                    ...new Set(
                        concatenatedListOfScopeItems.map(
                            (item) => item.id
                        )
                    ),
                ];

                console.log(listOfFootprintItems)


                var result = [];
                concatenatedListOfScopeItems.reduce(function (res, value) {
                    if (!res[value.itemSubCategory]) {
                        res[value.itemSubCategory] = {
                            itemSubCategory: value.itemSubCategory,
                            carbonEmissions: 0,
                        };
                        result.push(res[value.itemSubCategory]);
                    }
                    res[value.itemSubCategory].carbonEmissions +=
                        value.carbonEmissions;
                    return res;
                }, {});

                var scopeSubCategoryData = result.map((item) => item.carbonEmissions);
                var scopeSubCategoryLabels = result.map(
                    (item) => item.itemSubCategory
                );

                setLabels(scopeSubCategoryLabels);
                setCarbonEmissions(scopeSubCategoryData);

                //Code here to get Carbon Emissions per year for that organisation

                var year;
                var carbonEmissionsThatYear;
                var carbonEmissionsPerYear = [];

                console.log(filteredRes)
                console.log(res.data)

                for (i = 0; i < filteredRes.length; i++) {
                    if (filteredRes[i].organisationId === organisationId) {
                        year = filteredRes[i].organisationFootprintYear;
                        carbonEmissionsThatYear = filteredRes[i].organisationFootprintItems
                            .map((item) => item.carbonEmissions)
                            .reduce((prev, curr) => Number(prev) + Number(curr), 0);
                        carbonEmissionsPerYear.push({ year, carbonEmissionsThatYear });
                    }
                }

                const carbonEmissionsPerYearSorted = carbonEmissionsPerYear.sort(
                    (item1, item2) =>
                        item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0
                );

                scopeSubCategoryData = carbonEmissionsPerYearSorted.map(
                    (item) => item.carbonEmissionsThatYear
                );
                scopeSubCategoryLabels = carbonEmissionsPerYearSorted.map(
                    (item) => item.year
                );

                setLineChartLabels(scopeSubCategoryLabels);
                setLineChartCarbonEmissions(scopeSubCategoryData);

                //Code Here to get to emissions per scopeCategory per year

                const carbonEmissionsPerYearPerSubCategory = [];
                const carbonEmissionsPerYearPerFootprintItem = [];

                const listOfSubCategories = [
                    ...new Set(
                        concatenatedListOfScopeItems.map(
                            (item) => item.itemSubCategory
                        )
                    ),
                ];

                const listOfYears = [
                    ...new Set(
                        res.data
                            .filter((item) => item.organisationId === organisationId)
                            .map((item) => item.organisationFootprintYear)
                            .sort((item1, item2) =>
                                item1 < item2 ? -1 : item1 > item2 ? 1 : 0
                            )
                    ),
                ];
                var j;
                var k;
                var subCategory;
                var id
                var emissions;
                var itemDescription;
                var datasetsArray = [];
                var emissionsForThatSubCategory = [];
                var arrayOfYearlyEmissions = [];
                concatenatedListOfScopeItems = [];
                var carbonEmissionsPerYearPerSubCategorySorted = [];

                for (i = 0; i < listOfYears.length; i++) {
                    concatenatedListOfScopeItems = [];

                    year = listOfYears[i];

                    for (k = 0; k < res.data.length; k++) {
                        if (
                            res.data[k].organisationId === organisationId &&
                            res.data[k].organisationFootprintYear === year
                        ) {
                            concatenatedListOfScopeItems =
                                concatenatedListOfScopeItems.concat(
                                    res.data[k].organisationFootprintItems
                                );
                        }
                    }

                    for (j = 0; j < listOfSubCategories.length; j++) {
                        subCategory = listOfSubCategories[j];
                        emissions = concatenatedListOfScopeItems
                            .filter((item) => item.itemSubCategory === subCategory)
                            .map((item) => item.carbonEmissions)
                            .reduce((prev, curr) => Number(prev) + Number(curr), 0);
                        carbonEmissionsPerYearPerSubCategory.push({
                            year,
                            subCategory,
                            emissions,
                        });
                    }

                    console.log(concatenatedListOfScopeItems)
                    console.log(listOfFootprintItems)

                    for (j = 0; j < listOfFootprintItems.length; j++) {
                        id = listOfFootprintItems[j];
                        emissions = concatenatedListOfScopeItems
                            .filter((item) => item.id === id)
                            .map((item) => item.carbonEmissions)
                            .reduce((prev, curr) => Number(prev) + Number(curr), 0);
                        itemDescription = concatenatedListOfScopeItems
                            .find((item) => item.id === id).itemDescription

                            if (carbonEmissionsPerYearPerFootprintItem.find(item => item.id === id)) {
                                index = carbonEmissionsPerYearPerFootprintItem.indexOf(carbonEmissionsPerYearPerFootprintItem.find(item => item.id === id))
                                carbonEmissionsPerYearPerFootprintItem[index] = {...carbonEmissionsPerYearPerFootprintItem.find(item => item.id === id), [year]: emissions.toFixed(2)}
    
                            } else {
                                carbonEmissionsPerYearPerFootprintItem.push({id, itemDescription, [year]: emissions.toFixed(2)})
                            }

                    }

                }

                carbonEmissionsPerYearPerSubCategorySorted =
                    carbonEmissionsPerYearPerSubCategory.sort((item1, item2) =>
                        item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0
                    );

                for (i = 0; i < listOfSubCategories.length; i++) {
                    subCategory = listOfSubCategories[i];
                    emissionsForThatSubCategory =
                        carbonEmissionsPerYearPerSubCategorySorted.filter(
                            (item) => item.subCategory === subCategory
                        );

                    arrayOfYearlyEmissions = [];

                    for (j = 0; j < listOfYears.length; j++) {
                        year = listOfYears[j];
                        emissions = emissionsForThatSubCategory
                            .filter((item) => item.year === year)
                            .map((item) => item.emissions)
                            .reduce((prev, curr) => Number(prev) + Number(curr), 0);
                        arrayOfYearlyEmissions.push(emissions);

                        if (arrayOfYearlyEmissionsBySubCategory.find(item => item.subCategory === subCategory)) {
                            index = arrayOfYearlyEmissionsBySubCategory.indexOf(arrayOfYearlyEmissionsBySubCategory.find(item => item.subCategory === subCategory))
                            arrayOfYearlyEmissionsBySubCategory[index] = {...arrayOfYearlyEmissionsBySubCategory.find(item => item.subCategory === subCategory), [year]: emissions.toFixed(2)}

                        } else {
                            arrayOfYearlyEmissionsBySubCategory.push({subCategory: subCategory, [year]: emissions.toFixed(2)})
                        }

                    }

                    datasetsArray.push({
                        label: subCategory,
                        data: arrayOfYearlyEmissions,
                        backgroundColor: chartColours,
                        borderColor: chartColours,
                        hoverBackgroundColor: chartHoverColours,
                        fill: true,
                    });
                }

                setStackedLineChartLabels(listOfYears);
                setStackedLineChartCarbonEmissions(datasetsArray);

                setAllSubCategories(arrayOfYearlyEmissionsBySubCategory)
                setAllSubCategoriesHeadings(getHeadings(arrayOfYearlyEmissionsBySubCategory))

                setAllScopeItems(carbonEmissionsPerYearPerFootprintItem)
                setAllScopeItemsHeadings(getHeadings(carbonEmissionsPerYearPerFootprintItem))

            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });

    }, [organisationId, selected]);

    useEffect(() => {
        // setLoading(true)

        axios.get(process.env.REACT_APP_API_URL + "/organisations_footprints")
            .then((res) => {
                setOrganisationFootprintItems(
                    res.data.filter(
                        (organisationFootprint) =>
                            organisationFootprint.id === organisationFootprintId
                    )[0].organisationFootprintItems
                );
                setOrganisationFootprint(
                    res.data.filter(
                        (organisationFootprint) =>
                            organisationFootprint.id === organisationFootprintId
                    )
                );
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [organisationFootprintId]);

    useEffect(() => {
        // setLoading(true)

        axios.get(process.env.REACT_APP_API_URL + "/offset_credits")
            .then((res) => {
                setCredits(res.data.filter((credit) => credit.projectId === projectId));
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });

        axios.get(process.env.REACT_APP_API_URL + "/offset_projects")
            .then((res) => {
                setProjects(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [projectId]);

    useEffect(() => {
        // setLoading(true)

        axios.get(process.env.REACT_APP_API_URL + "/buildings")
            .then((res) => {
                setBuildings(
                    res.data.filter(
                        (building) => building.buildingCountry === buildingCountry
                    )
                );
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [buildingCountry]);

    const chartColours = [
        "#0090CA",
        "#00A1AA",
        "#00518C",
        "#B9D3DC",
        "#05cfc0",
        "#0a4f4e",
        "#158a2c",
        "#78de4c",
        "#738a69",
        "#c2df7d",
        "#E89B26",
        "#FCE3BD",
        "#592c19",
        "#ff7276",
        "#FF7C40",
        "#FCF062",
        "#7BA7BC",
        "#5459C1",
        "#753fc2",
        "#827db8",
        "#90286b",
        "#ef66f0",
    ];
    const chartHoverColours = [
        "#10475C",
        "#007980",
        "#0E2343",
        "#96abb3",
        "#036860",
        "#0B4516",
        "#052827",
        "#3C6F26",
        "#3A4535",
        "#61703F",
        "#a16b1a",
        "#CEB691",
        "#2E211C",
        "#802741",
        "#732912",
        "#7A6009",
        "#4d6875",
        "#303372",
        "#3B2061",
        "#413F5C",
        "#481436",
        "#783378",
    ];

    //Chart 2 (All Scopes By Scope)
    const scope1Co2e = organisationFootprintItems.filter(
        (item) => item.itemCategory === "1"
    );
    const scope2Co2e = organisationFootprintItems.filter(
        (item) => item.itemCategory === "2"
    );
    const scope3Co2e = organisationFootprintItems.filter(
        (item) => item.itemCategory === "3"
    );

    const calcCo2eScope1 = scope1Co2e
        .map((item) => item.carbonEmissions)
        .reduce((prev, curr) => Number(prev) + Number(curr), 0);
    const calcCo2eScope2 = scope2Co2e
        .map((item) => item.carbonEmissions)
        .reduce((prev, curr) => Number(prev) + Number(curr), 0);
    const calcCo2eScope3 = scope3Co2e
        .map((item) => item.carbonEmissions)
        .reduce((prev, curr) => Number(prev) + Number(curr), 0);

    const dataChart1 = {
        labels: ["Scope 1", "Scope 2", "Scope 3"],
        datasets: [
            {
                // label: 'kgCO2e',
                data: [calcCo2eScope1, calcCo2eScope2, calcCo2eScope3],
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

    //Chart 2 (All Scopes All Items)
    const itemDescriptionLabels = organisationFootprintItems.map(
        (item) => item.itemDescription
    );
    const itemDescriptionData = organisationFootprintItems.map(
        (item) => item.carbonEmissions
    );

    const dataChart2 = {
        labels: itemDescriptionLabels,
        datasets: [
            {
                data: itemDescriptionData,
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

    //Chart 3 (All Scopes by Sub Category)
    var result = [];
    organisationFootprintItems.reduce(function (res, value) {
        if (!res[value.itemSubCategory]) {
            res[value.itemSubCategory] = {
                itemSubCategory: value.itemSubCategory,
                carbonEmissions: 0,
            };
            result.push(res[value.itemSubCategory]);
        }
        res[value.itemSubCategory].carbonEmissions += value.carbonEmissions;
        return res;
    }, {});

    const scopeSubCategoryData = result.map((item) => item.carbonEmissions);
    const scopeSubCategoryLabels = result.map(
        (item) => item.itemSubCategory
    );

    const dataChart3 = {
        labels: scopeSubCategoryLabels,
        datasets: [
            {
                data: scopeSubCategoryData,
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

    //Chart 4 (Scope 3 by Sub Category)
    result = [];
    scope3Co2e.reduce(function (res, value) {
        if (!res[value.itemSubCategory]) {
            res[value.itemSubCategory] = {
                itemSubCategory: value.itemSubCategory,
                carbonEmissions: 0,
            };
            result.push(res[value.itemSubCategory]);
        }
        res[value.itemSubCategory].carbonEmissions += value.carbonEmissions;
        return res;
    }, {});

    const scope3Data = result.map((item) => item.carbonEmissions);
    const scope3Labels = result.map((item) => item.itemSubCategory);

    const dataChart4 = {
        labels: scope3Labels,
        datasets: [
            {
                data: scope3Data,
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

    //Chart 5 (Scope 3 by Sub Category per Organisation)

    const dataChart5 = {
        labels: labels,
        datasets: [
            {
                data: carbonEmissions,
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

    //Chart 6
    const dataChart6 = {
        labels: lineChartLabels,
        datasets: [
            {
                data: lineChartCarbonEmissions,
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

    //Chart 7a
    const dataChart7a = {
        labels: lineChartLabels,
        datasets: [
            {
                data: lineChartCarbonEmissions,
            },
        ],
    };

    //Chart 7
    const dataChart7 = {
        labels: stackedLineChartLabels,
        datasets: stackedLineChartCarbonEmissions
    };

    //Chart 7c
    const dataChart7c = {
        labels: stackedLineChartLabels,
        datasets: [
            {
                label: 'Business As Usual',
                type: 'line',
                borderColor: 'rgb(75, 56, 192)',
                data: [70000, 70000, 70000, 70000, 70000, 70000]
            },
            {
                label: 'Target',
                type: 'line',
                borderColor: 'rgb(150, 160, 192)',
                data: [70000, 60000, 55000, 50000, 50000, 45000]
            },
            {
                label: 'Scope 1 Projection',
                type: 'bar',
                fill: true,
                backgroundColor: chartColours[0],
                data: [70000, 59000, 54000, 45000, 45000, 42000]
            },
            // {
            //     label: 'Scope 2 Projection',
            //     fill: true,
            //     type: 'bar',
            //     backgroundColor: chartColours[1],
            //     data: [35000, 32500, 30000, 27500, 25000, 22500]
            // }
        ]
    };

    //Chart 8 - All Buildings

    const buildingsSorted = buildings.sort((item1, item2) =>
        Number(item1.buildingCO2eFromElectricityConsumption) <
            Number(item2.buildingCO2eFromElectricityConsumption)
            ? -1
            : Number(item1.buildingCO2eFromElectricityConsumption) >
                Number(item2.buildingCO2eFromElectricityConsumption)
                ? 1
                : 0
    );

    const itemDescriptionLabelsBuildings = buildingsSorted.map(
        (item) => item.buildingName
    );
    const itemDescriptionDataBuildings = buildingsSorted.map(
        (item) => item.buildingCO2eFromElectricityConsumption
    );
    const itemDescriptionGasDataBuildings = buildingsSorted.map(
        (item) => item.buildingCO2eFromGasConsumption
    );

    const dataChart8 = {
        title: "kg CO2e Emissions from Electricity Consumption",
        labels: itemDescriptionLabelsBuildings,
        datasets: [
            {
                label: "kg CO2e Emissions from Electricity Consumption",
                data: itemDescriptionDataBuildings,
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
            {
                label: "kg CO2e Emissions from Gas Consumption",
                data: itemDescriptionGasDataBuildings,
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

    //Chart 9 - All Stores
    const itemDescriptionLabelsStores = stores.map((item) => item.storeName);
    const itemDescriptionDataStores = stores.map(
        (item) => (item.wellbeingScore / 6) * 100
    );
    const itemDescriptionDataStoresWaste = stores.map(
        (item) => (item.wasteScore / 2) * 100
    );

    const dataChart9 = {
        labels: itemDescriptionLabelsStores,
        datasets: [
            {
                label: "Wellness Score",
                data: itemDescriptionDataStores,
                backgroundColor: chartColours[0],
                hoverBackgroundColor: chartHoverColours[0],
            },
            {
                label: "Waste Score",
                data: itemDescriptionDataStoresWaste,
                backgroundColor: chartColours[1],
                hoverBackgroundColor: chartHoverColours[1],
            },
        ],
    };

    const itemDescriptionLabelsCredits = credits.map(
        (item) => item.creditSerialNumber
    );
    const itemDescriptionDataCredits = credits.map((item) => item.creditQuantity);

    const dataChart10 = {
        labels: itemDescriptionLabelsCredits,
        datasets: [
            {
                data: itemDescriptionDataCredits,
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

    //Pie Options
    const pieOptions = {
        plugins: {
            title: {
                display: true,
                text: 'Carbon footprint by subcategory',
                font: {
                    size: 20
                }
            },
            legend: {
                display: true,
                maxWidth: 100,
                position: "bottom",
                labels: {
                    font: {
                        size: 11,
                    },
                },
            },
        },
    };

    const barOptions = {
        responsive: true,
        indexAxis: "y",
        plugins: {
            title: {
                display: true,
                text: 'Emission reduction roadmap kgCO2e',
            },
            legend: {
                display: false,
                position: "top",
            },
            title: {
                display: true,
                text: 'Carbon footprint by scope category',
                font: {
                    size: 20
                }
            },
        },
    };

    const horizontalBarOptions = {
        responsive: true,
        elements: {
            title: {
                display: true,
            },
        },
        plugins: {
            legend: {
                display: true,
                position: "right",
            },
        },
    };

    const horizontalBarOptionsNoLegend = {
        responsive: true,
        elements: {
            title: {
                display: true,
            },
        },
        plugins: {
            title: {
                display: true,
                text: 'Annual carbon emissions in kgCO2e',
                font: {
                    size: 20
                }
            },
            legend: {
                display: false,
                position: "right",
                
            },
        },
    };

    const lineBarOptions = {
        elements: {
            line: {
                tension: 0.5,
            },
        },
        
        scales: {
            y: {
                min: 0,
                stacked: true,
            },
        },
        plugins: {
            title: {
                display: true,
                text: 'Emission reduction roadmap kgCO2e',
                font: {
                    size: 20
                }
            },
            legend: {
                display: true,
                position: "bottom",
                labels: {
                    font: {
                        size: 11,
                    },
                },
            },
        },
    };

    const lineBarNotStackedOptions = {
        elements: {
            line: {
                tension: 0.5, // disables bezier curves
            },
        },
        scales: {
            y: {
                min: 0,
                stacked: false,
            },
        },
        plugins: {
            legend: {
                display: false,
                position: "right",
                labels: {
                    font: {
                        size: 9,
                    },
                },
            },
        },
    };

    const barAndLineOptions = {
        plugins: {
            title: {
                display: true,
                text: 'Business as Usual vs Projection kgCO2e',
                font: {
                    size: 20
                }
            },
        },
        // scales: {
        //     x: {
        //         stacked: true
        //     },
        //     y: {
        //         min: 0,
        //         stacked: true
        //     },
        // }
    }

    if (
        carbonEmissions.length > 0 &&
        organisations.length > 0 &&
        organisationFootprints.length > 0 &&
        organisationFootprintItems.length > 0 &&
        organisationFootprint.length > 0 &&
        !loading &&
        allScopeItems.length > 0
    ) {
        return (
            <div>
                {!sidebarToggle && (
                    <button
                        className="absolute top-2 left-1"
                        onClick={() => setSidebarToggle(!sidebarToggle)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M4 6h16M4 12h16M4 18h16"
                            />
                        </svg>
                    </button>
                )}
                {sidebarToggle && (
                    <button
                        className="absolute top-2 left-1"
                        onClick={() => setSidebarToggle(!sidebarToggle)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                )}

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle && <Sidebar />}
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Dashboard" section="General Dashboard View" iconFileName="Dashboard-Hover" showUserMenu='true' />
                        <div className="flex items-center bg-slate-100">
                            <div className="container max-w-6xl px-5 mx-auto my-5">
                                <div className="grid gap-2 sm:gap-5 sm:grid-cols-3 md:grid-cols-7">

                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                                        <div>
                                            <div className="text-sm text-gray-500">Organisations: </div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-pacific-blue ">
                                                    <CountUp start={0} end={organisations.length} />

                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                                        <div>
                                            <div className="text-sm text-gray-500 ">Buildings: </div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-pacific-blue ">
                                                    <CountUp start={0} end={allBuildings.length} /></div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                                        <div>
                                            <div className="text-sm text-gray-500 ">Offset Projects:  </div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-pacific-blue ">
                                                    <CountUp start={0} end={projects.length} />
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>

                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                        <div>
                                            <div className="text-sm text-gray-500 ">Stores: </div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-pacific-blue ">
                                                    <CountUp start={0} end={stores.length} /></div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                        <div>
                                            <div className="text-sm text-gray-500 ">Events: </div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-pacific-blue ">
                                                    <CountUp start={0} end={events.length} /></div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                        <div>
                                            <div className="text-sm text-gray-500 ">Sites: </div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-pacific-blue ">
                                                    <CountUp start={0} end={sites.length} /></div>
                                            </div>
                                        </div>
                                        
                                    </div>

                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">
                                        <div>
                                            <div className="text-sm text-gray-500 ">Products: </div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-pacific-blue ">
                                                    <CountUp start={0} end={products.length} /></div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        

                        <div className="grid grid-cols-4 gap-3">
                            <div>
                                <label>Organisation</label>
                                <select
                                    className="rounded-xl m-3 p-3 w-1/3"
                                    value={organisationId}
                                    onChange={(e) => setOrganisationId(e.target.value)}
                                >
                                    {organisations.map((organisation) => (
                                        <option key={organisation.id} value={organisation.id}>
                                            {organisation.organisationName}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                            <MultiSelect
                                options={organisationFootprints.map((item) => {
                                    return {
                                        label: item.organisationFootprintRef,
                                        value: item.organisationFootprintRef
                                    }
                                })}
                                value={selected}
                                onChange={setSelected}
                                labelledBy="Select"
                            />
                            </div>
                        </div>

                        {/* <div className="flex">
                            <div className="basis-1/2 border-2 rounded-xl bg-linen m-3">
                                {" "}
                                <p className="text-center">
                                    Total Carbon Emissions (kgCO<sub>2</sub>e):{" "}
                                </p>
                                <p className="text-center text-xl font-bold">
                                    <CountUp
                                        start={0}
                                        end={carbonEmissions
                                            .reduce((prev, curr) => Number(prev) + Number(curr), 0)
                                            .toFixed(2)}
                                    />
                                </p>
                            </div>
                            <div className="basis-1/2 border-2 rounded-xl bg-linen m-3">
                                <p className="text-center">
                                    Total Carbon Emissions (tCO<sub>2</sub>e):{" "}
                                </p>
                                <p className="text-center text-xl font-bold">
                                    <CountUp
                                        start={0}
                                        end={(
                                            carbonEmissions.reduce(
                                                (prev, curr) => Number(prev) + Number(curr),
                                                0
                                            ) / 1000
                                        ).toFixed(2)}
                                    />
                                </p>
                            </div>
                        </div> */}

                        <div className="flex items-center bg-slate-100">
                            <div className="container max-w-6xl px-5 mx-auto my-5">
                                <div className="grid gap-2 sm:gap-7 sm:grid-cols-2">

                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                                        <div>
                                            <div className="text-sm text-gray-500 ">Total Carbon Emissions (kgCO<sub>2</sub>e):  </div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-pacific-blue "><CountUp
                                                    start={0}
                                                    end={carbonEmissions
                                                        .reduce((prev, curr) => Number(prev) + Number(curr), 0)
                                                        .toFixed(2)}
                                                /></div>
                                            </div>
                                        </div>
                                        <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                                        <div>
                                            <div className="text-sm text-gray-500 ">Total Carbon Emissions (tCO<sub>2</sub>e): </div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-pacific-blue "><CountUp
                                                    start={0}
                                                    end={(
                                                        carbonEmissions.reduce(
                                                            (prev, curr) => Number(prev) + Number(curr),
                                                            0
                                                        ) / 1000
                                                    ).toFixed(2)}
                                                /></div>
                                            </div>
                                        </div>
                                        <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex">
                            <div className="basis-1/3 border-2 rounded-xl m-3 p-3">
                                <Doughnut data={dataChart5} options={pieOptions} />
                            </div>
                            <div className="basis-1/3 border-2 rounded-xl m-3 p-3">
                                <Bar
                                    data={dataChart6}
                                    options={horizontalBarOptionsNoLegend}
                                    height={300}
                                />
                            </div>
                            <div className="basis-1/3 border-2 rounded-xl m-3 p-3">
                                <Line data={dataChart7c} options={barAndLineOptions} height={300} />
                            </div>
                        </div>

                        <div className="flex">
                            <div className="basis-full border-2 rounded-xl m-3 p-3">
                                <Line data={dataChart7} options={lineBarOptions} height={80} />
                            </div>
                        </div>

                        <div className="flex flex-col">
                            <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
                                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                    <div className="shadow overflow-hidden border-jungle-mist-100 sm:rounded border-2">
                                        <Table theadData={allScopeItemsHeadings} tbodyData={allScopeItems}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <br></br>

                        <div className="flex flex-col">
                            <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
                                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                    <div className="shadow overflow-hidden border-jungle-mist-100 sm:rounded border-2">
                                        <Table theadData={allSubCategoriesHeadings} tbodyData={allSubCategories}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <br></br>
                        <br></br>
                        <div>
                            <label>Footprint</label>
                            <select
                                className="rounded-xl m-3 p-3 w-1/3"
                                value={organisationFootprintId}
                                onChange={(e) => setOrganisationFootprintId(e.target.value)}
                            >
                                {organisationFootprints.map((organisationFootprint) => (
                                    <option
                                        key={organisationFootprint.id}
                                        value={organisationFootprint.id}
                                    >
                                        {organisationFootprint.organisationFootprintRef}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="flex items-center bg-slate-100">
                            <div className="container max-w-6xl px-5 mx-auto my-5">
                                <div className="grid gap-2 sm:gap-7 sm:grid-cols-2">

                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                                        <div>
                                            <div className="text-sm text-gray-500 ">Total Carbon Emissions (kgCO<sub>2</sub>e):  </div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-pacific-blue "><CountUp
                                                    start={0}
                                                    end={`${organisationFootprintItems
                                                        .map((item) => item.carbonEmissions)
                                                        .reduce((prev, curr) => Number(prev) + Number(curr), 0)
                                                        .toFixed(2)}`}
                                                /></div>
                                            </div>
                                        </div>
                                        <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                                        <div>
                                            <div className="text-sm text-gray-500 ">Total Carbon Emissions (tCO<sub>2</sub>e): </div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-pacific-blue "><CountUp
                                                    start={0}
                                                    end={`${(
                                                        organisationFootprintItems
                                                            .map((item) => item.carbonEmissions)
                                                            .reduce((prev, curr) => Number(prev) + Number(curr), 0) /
                                                        1000
                                                    ).toFixed(2)}`}
                                                /></div>
                                            </div>
                                        </div>
                                        <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex">
                            <div className="basis-1/3 border-2 rounded-xl m-3 p-3">
                                <Doughnut data={dataChart1} options={pieOptions} />
                            </div>
                            <div className="basis-1/3 border-2 rounded-xl m-3 p-3">
                                <Doughnut data={dataChart3} options={pieOptions} />
                            </div>
                            <div className="basis-1/3 border-2 rounded-xl m-3 p-3">
                                <Bar data={dataChart3} options={barOptions} height={300} />
                            </div>
                        </div>

                        <div className="flex">
                            <div className="basis-1/4 border-2 rounded-xl m-3 p-3">
                                <Bar data={dataChart3} options={barOptions} height={300} />
                            </div>
                            <div className="basis-1/4 border-2 rounded-xl m-3 p-3">
                                <Doughnut data={dataChart4} options={pieOptions} />
                            </div>
                            <div className="basis-1/4 border-2 rounded-xl m-3 p-3">
                                <Doughnut data={dataChart2} options={pieOptions} />
                            </div>
                            <div className="basis-1/4 border-2 rounded-xl m-3 p-3">
                                <Doughnut data={dataChart3} options={pieOptions} />
                            </div>
                        </div>
                        <br></br>
                        <br></br>
                        <div>
                            <label>Building - Country</label>
                            <select
                                className="rounded-xl m-3 p-3 w-1/3"
                                value={buildingCountry}
                                onChange={(e) => setBuildingCountry(e.target.value)}
                            >
                                {buildingsList.map((country) => (
                                    <option key={country} value={country}>
                                        {country}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="flex">
                            <div className="border-2 rounded-xl m-3 p-3">
                                <Bar
                                    data={dataChart8}
                                    options={horizontalBarOptionsNoLegend}
                                    height={300}
                                    width={1400}
                                />
                            </div>
                        </div>

                        <br></br>
                        <br></br>
                        <p>Stores - Wellness and Waste Scores</p>
                        <div className="flex">
                            <div className="border-2 rounded-xl m-3 p-3">
                                <Bar
                                    data={dataChart9}
                                    options={horizontalBarOptions}
                                    height={300}
                                    width={1400}
                                />
                            </div>
                        </div>

                        <br></br>
                        <br></br>
                        <div>
                            <label>Offset Project</label>
                            <select
                                className="rounded-xl m-3 p-3 w-1/3"
                                value={projectId}
                                onChange={(e) => setProjectId(e.target.value)}
                            >
                                {projects.map((project) => (
                                    <option key={project.id} value={project.id}>
                                        {project.projectName}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="flex">
                            <div className="border-2 rounded-xl m-3 p-3">
                                <Bar
                                    data={dataChart10}
                                    options={horizontalBarOptionsNoLegend}
                                    height={300}
                                    width={1400}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div>
                {/* <Header title="Organisation Footprint Items" section="" showLogo={false}/> */}
                <p>Data is loading...</p>
            </div>
        );
    }
};

export default Dashboard;
