import { useEffect, useState } from "react";
import Header from "../UI/Header";
import axios from "axios";
import { Doughnut, Bar, Line } from "react-chartjs-2";
import Sidebar from "../UI/Sidebar";
import CountUp from "react-countup";
import { MultiSelect } from "react-multi-select-component";
import Button from '../UI/Button'
import { ExportToCsv } from 'export-to-csv'
import { useMatch } from 'react-router-dom'

import {
    Chart as ChartJS,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle,
} from "chart.js";

ChartJS.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
);


const DashboardBREEAMAssessments = () => {

    const match = useMatch('/dashboardBREEAMAssessments/:buildingId/:assessmentId')

    const [loading, setLoading] = useState(true);

    const [sidebarToggle, setSidebarToggle] = useState(false);

    const [BREEAMAssessment, setBREEAMAssessment] = useState([]);

    const [servicesFullyFiltered, setServicesFullyFiltered] = useState([]);

    const [creditsBySection, setCreditsBySection] = useState([]);
    const [labelsBySection, setLabelsBySection] = useState([]);

    const [stackedBarChartLabels, setStackedBarChartLabels] = useState([]);
    const [stackedBarChartCredits, setStackedBarChartCredits] = useState([]);

    const [weightedScores, setWeightedScores] = useState([]);

    const [serviceCount, setServiceCount] = useState([]);

    const [selectedSection, setSelectedSection] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedCredit, setSelectedCredit] = useState([]);

    const [totalCurrentScore, setTotalCurrentScore] = useState(0);

    const chartColours = [
        "#0090CA",
        "#00A1AA",
        "#00518C",
        "#B9D3DC",
        "#05cfc0",
        "#0a4f4e",
        "#158a2c",
        "#78de4c",
        "#738a69",
        "#c2df7d",
        "#E89B26",
        "#FCE3BD",
        "#592c19",
        "#ff7276",
        "#FF7C40",
        "#FCF062",
        "#7BA7BC",
        "#5459C1",
        "#753fc2",
        "#827db8",
        "#90286b",
        "#ef66f0",
    ];
    const chartHoverColours = [
        "#10475C",
        "#007980",
        "#0E2343",
        "#96abb3",
        "#036860",
        "#0B4516",
        "#052827",
        "#3C6F26",
        "#3A4535",
        "#61703F",
        "#a16b1a",
        "#CEB691",
        "#2E211C",
        "#802741",
        "#732912",
        "#7A6009",
        "#4d6875",
        "#303372",
        "#3B2061",
        "#413F5C",
        "#481436",
        "#783378",
    ];

    const chartColoursAltOrder = [
        "#00518C",
        "#00A1AA",
        "#5fc2bb",
        "#B9D3DC",
        "#0a4f4e",
        "#158a2c",
        "#78de4c",
        "#738a69",
        "#c2df7d",
        "#E89B26",
        "#FCE3BD",
        "#592c19",
        "#ff7276",
        "#FF7C40",
        "#FCF062",
        "#7BA7BC",
        "#5459C1",
        "#753fc2",
        "#827db8",
        "#90286b",
        "#ef66f0",
    ];
    const chartHoverColoursAltOrder = [
        "#0E2343",
        "#10475C",
        "#007980",
        "#036860",
        "#96abb3",
        "#0B4516",
        "#052827",
        "#3C6F26",
        "#3A4535",
        "#61703F",
        "#a16b1a",
        "#CEB691",
        "#2E211C",
        "#802741",
        "#732912",
        "#7A6009",
        "#4d6875",
        "#303372",
        "#3B2061",
        "#413F5C",
        "#481436",
        "#783378",
    ];

    const weightings = [{assessmentType: "Fully Fitted", section: "MANAGEMENT", weighting: 0.11},
    {assessmentType: "Fully Fitted", section: "HEALTH AND WELLBEING", weighting: 0.14},
    {assessmentType: "Fully Fitted", section: "ENERGY", weighting: 0.16},
    {assessmentType: "Fully Fitted", section: "TRANSPORT", weighting: 0.1},
    {assessmentType: "Fully Fitted", section: "WATER", weighting: 0.07},
    {assessmentType: "Fully Fitted", section: "MATERIALS", weighting: 0.15},
    {assessmentType: "Fully Fitted", section: "WASTE", weighting: 0.06},
    {assessmentType: "Fully Fitted", section: "ECOLOGY", weighting: 0.13},
    {assessmentType: "Fully Fitted", section: "POLLUTION", weighting: 0.08},
    {assessmentType: "Fully Fitted", section: "CONSTRUCTION IMPACTS", weighting: 0.08},
    {assessmentType: "Fully Fitted", section: "INNOVATION", weighting: 0.1},
    {assessmentType: "Shell and Core", section: "MANAGEMENT", weighting: 0.11},
    {assessmentType: "Shell and Core", section: "HEALTH AND WELLBEING", weighting: 0.08},
    {assessmentType: "Shell and Core", section: "ENERGY", weighting: 0.14},
    {assessmentType: "Shell and Core", section: "TRANSPORT", weighting: 0.115},
    {assessmentType: "Shell and Core", section: "WATER", weighting: 0.07},
    {assessmentType: "Shell and Core", section: "MATERIALS", weighting: 0.175},
    {assessmentType: "Shell and Core", section: "WASTE", weighting: 0.07},
    {assessmentType: "Shell and Core", section: "ECOLOGY", weighting: 0.15},
    {assessmentType: "Shell and Core", section: "POLLUTION", weighting: 0.09},
    {assessmentType: "Shell and Core", section: "CONSTRUCTION IMPACTS", weighting: 0.08},
    {assessmentType: "Shell and Core", section: "INNOVATION", weighting: 0.1},
    {assessmentType: "Shell Only", section: "MANAGEMENT", weighting: 0.12},
    {assessmentType: "Shell Only", section: "HEALTH AND WELLBEING", weighting: 0.07},
    {assessmentType: "Shell Only", section: "ENERGY", weighting: 0.095},
    {assessmentType: "Shell Only", section: "TRANSPORT", weighting: 0.145},
    {assessmentType: "Shell Only", section: "WATER", weighting: 0.02},
    {assessmentType: "Shell Only", section: "MATERIALS", weighting: 0.22},
    {assessmentType: "Shell Only", section: "WASTE", weighting: 0.08},
    {assessmentType: "Shell Only", section: "ECOLOGY", weighting: 0.19},
    {assessmentType: "Shell Only", section: "POLLUTION", weighting: 0.06},
    {assessmentType: "Shell Only", section: "CONSTRUCTION IMPACTS", weighting: 0.08},
    {assessmentType: "Shell Only", section: "INNOVATION", weighting: 0.1},
    {assessmentType: "Simple Building", section: "MANAGEMENT", weighting: 0.075},
    {assessmentType: "Simple Building", section: "HEALTH AND WELLBEING", weighting: 0.165},
    {assessmentType: "Simple Building", section: "ENERGY", weighting: 0.115},
    {assessmentType: "Simple Building", section: "TRANSPORT", weighting: 0.115},
    {assessmentType: "Simple Building", section: "WATER", weighting: 0.075},
    {assessmentType: "Simple Building", section: "MATERIALS", weighting: 0.175},
    {assessmentType: "Simple Building", section: "WASTE", weighting: 0.07},
    {assessmentType: "Simple Building", section: "ECOLOGY", weighting: 0.15},
    {assessmentType: "Simple Building", section: "POLLUTION", weighting: 0.06},
    {assessmentType: "Simple Building", section: "CONSTRUCTION IMPACTS", weighting: 0.08},
    {assessmentType: "Simple Building", section: "INNOVATION", weighting: 0.1},
]

    const aggregateSections = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.section === val.section);
            if (index === -1) {
                acc.push({
                    section: val.section,
                });
            };
            return acc.sort((item1, item2) => item1.section < item2.section ? -1 : item1.section > item2.section ? 1 : 0);
        }, []);
    };
    const aggregateCredits = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.creditDescription === val.creditDescription);
            if (index === -1) {
                acc.push({
                    creditDescription: val.creditDescription,
                });
            };
            return acc.sort((item1, item2) => item1.creditDescription < item2.creditDescription ? -1 : item1.creditDescription > item2.creditDescription ? 1 : 0);
        }, []);
    };
    const aggregateCreditCategories = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.creditCategoryDescription === val.creditCategoryDescription);
            if (index === -1) {
                acc.push({
                    creditCategoryDescription: val.creditCategoryDescription,
                });
            };
            return acc.sort((item1, item2) => item1.creditCategoryDescription < item2.creditCategoryDescription ? -1 : item1.creditCategoryDescription > item2.creditCategoryDescription ? 1 : 0);
        }, []);
    };

    const dataChart5 = {
        labels: labelsBySection,
        datasets: [
            {
                data: creditsBySection,
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

    const dataChart7b = {
        labels: stackedBarChartLabels,
        datasets: stackedBarChartCredits
    };

    const pieOptions = {
        plugins: {
            title: {
                display: true,
                text: 'Credits per Section',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: false,
                maxWidth: 100,
                position: "bottom",
                labels: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    },
                },
            },
            datalabels: {
                formatter: (value, ctx) => {
                    let sum = 0;
                    let dataArr = ctx.chart.data.datasets[0].data;
                    dataArr.map(data => {
                        sum += data;
                    });
                    let percentage = (value*100 / sum).toFixed(0)+"%";
                    if ((value*100 / sum) > 5) {
                        return percentage;
                    }

                    return null
                },
                display: 'auto',
                color: 'white'

            },

        },
        
    };

    
    const barOptionsHorizontal = {

        indexAxis: 'y',
        scales: {
            y: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            },
            x: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            }
        },

        plugins: {
            title: {
                display: true,
                text: 'Credits per Section',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: false,
            },
            datalabels: {
                display: false,
            }
        },
        

        responsive: true,

    };

    const barOptionsStacked = {

        plugins: {
            title: {
                display: true,
                text: 'Credits per Section Achieved, Targeted, Potential and Remaining',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: true,
                maxWidth: 100,
                position: "bottom",
                labels: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    },
                },
            },
            datalabels: {
                display: false,
            }
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                ticks: {
                    font: {
                        size: 12,
                        family: 'TT Norms Pro'
                    }
                }
            },
            y: {
                stacked: true,
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            }
        },

    };

    const arrayToCsv = (data) => {


        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: 'Billing Plan Tracker',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };

        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(data)

    }

    
    useEffect(() => {

        axios.get(process.env.REACT_APP_API_URL + '/buildings_BREEAM_assessments/' + match.params.assessmentId)
            .then((response) => {
                setBREEAMAssessment(response.data);
                setSelectedSection(aggregateSections(response.data.credits).map((item) => {
                    return {
                        label: item.section,
                        value: item.section
                    }
                }))
                setSelectedCategory(aggregateCreditCategories(response.data.credits).map((item) => {
                    return {
                        label: item.creditCategoryDescription,
                        value: item.creditCategoryDescription
                    }
                }))
                setSelectedCredit(aggregateCredits(response.data.credits).map((item) => {
                    return {
                        label: item.creditDescription,
                        value: item.creditDescription
                    }
                }))
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });


    }, [match.params.assessmentId]);


    useEffect(() => {

        if (BREEAMAssessment.credits) {

            var filteredResBySection = []
            var filteredResBySectionAndCategory = []
            
            var filteredRes = BREEAMAssessment.credits

            filteredResBySection = filteredRes.filter((itemA) => {
                return selectedSection.find((itemB) => {
                    return itemA.section === itemB.value;
                })
            }).filter(item => item.credits !== "Yes" && item.credits !== "-")

            filteredResBySectionAndCategory = filteredResBySection.filter((itemA) => {
                return selectedCategory.find((itemB) => {
                    return itemA.creditCategoryDescription === itemB.value;
                })
            })

            console.log(filteredResBySectionAndCategory)

            var i

            for (i = 0; i < filteredResBySectionAndCategory.length; i++) {

                if (filteredResBySectionAndCategory[i].creditAchieved === "YES") {
                    filteredResBySectionAndCategory[i].creditsAchieved = filteredResBySectionAndCategory[i].credits
                } else {
                    filteredResBySectionAndCategory[i].creditsAchieved = 0
                }

                if (isNaN(Number(filteredResBySectionAndCategory[i].creditsPotential))) {
                    filteredResBySectionAndCategory[i].creditsPotential = 0
                }

                if (isNaN(Number(filteredResBySectionAndCategory[i].creditsTargeted))) {
                    filteredResBySectionAndCategory[i].creditsTargeted = 0
                }

            }

            var result = [];
            filteredResBySectionAndCategory.reduce(function (res, value) {
                if (!res[value.section]) {
                    res[value.section] = {
                        section: value.section,
                        credits: 0,
                        creditsAchieved: 0,
                        creditsPotential: 0,
                        creditsTargeted: 0,
                        creditsRemaining: 0
                    };
                    result.push(res[value.section]);
                }
                res[value.section].credits = Number(res[value.section].credits) + Number(value.credits);

                res[value.section].creditsAchieved = Number(res[value.section].creditsAchieved) + Number(value.creditsAchieved);

                res[value.section].creditsPotential = Number(res[value.section].creditsPotential) + Number(value.creditsPotential);

                res[value.section].creditsTargeted = Number(res[value.section].creditsTargeted) + Number(value.creditsTargeted) - Number(value.creditsAchieved);
                
                res[value.section].creditsRemaining = Number(res[value.section].creditsRemaining) + Number(value.credits) - Number(value.creditsTargeted) - Number(value.creditsPotential);
            
                return res;
            }, {});

            setCreditsBySection(result.map(item => item.credits))
            setLabelsBySection(result.map(item => item.section))

            console.log(result)

            var j
            var datasetsArray = []
            var chartColourIndex = 0
            var creditTypes = ["Credits Achieved", "Targeted", "Potential", "Not Targeted"]
            var creditValuesToAdd = []

            for (i = 0; i < creditTypes.length; i++) {

                creditValuesToAdd = []

                for (j = 0; j < result.length; j++) {

                    if (creditTypes[i] === "Credits Achieved") {
                        creditValuesToAdd.push(result[j].creditsAchieved)
                    }

                    if (creditTypes[i] === "Targeted") {
                        creditValuesToAdd.push(result[j].creditsTargeted)
                    }

                    if (creditTypes[i] === "Potential") {
                        creditValuesToAdd.push(result[j].creditsPotential)
                    }

                    if (creditTypes[i] === "Not Targeted") {
                        creditValuesToAdd.push(result[j].creditsRemaining)
                    }

                }

                datasetsArray.push({
                    label: creditTypes[i],
                    data: creditValuesToAdd,
                    backgroundColor: chartColoursAltOrder[chartColourIndex],
                    borderColor: chartColoursAltOrder[chartColourIndex],
                    hoverBackgroundColor: chartHoverColoursAltOrder[chartColourIndex],
                });

                if (chartColourIndex === chartColours.length - 1) {
                    chartColourIndex = 0
                } else {
                    chartColourIndex++
                }
            }

            console.log(datasetsArray)

            setStackedBarChartLabels(result.map(item => item.section))
            setStackedBarChartCredits(datasetsArray);

            setWeightedScores(result)

            var k
            var totalScore = 0

            for (k=0; k < result.length; k++) {

                if (!isNaN(Number(result[k].creditsTargeted)) && Number(result[k].creditsTargeted) !== 0) {

                    totalScore = totalScore + (((result[k].creditsAchieved / result[k].creditsTargeted) * 100) * weightings.find(weightingRecord => weightingRecord.section === result[k].section && weightingRecord.assessmentType === BREEAMAssessment.assessmentType).weighting)

                }

            }

            setTotalCurrentScore(totalScore.toFixed(2))

        }
 
    }, [selectedCategory, BREEAMAssessment, selectedCredit, selectedSection]);

    if (
        !loading && 
        BREEAMAssessment.credits
    ) {
        return (
            <div>
                {!sidebarToggle && (
                    <button
                        className="absolute top-2 left-1 text-congress-blue"
                        onClick={() => setSidebarToggle(!sidebarToggle)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M4 6h16M4 12h16M4 18h16"
                            />
                        </svg>
                    </button>
                )}
                {sidebarToggle && (
                    <button
                        className="absolute top-2 left-1 text-white"
                        onClick={() => setSidebarToggle(!sidebarToggle)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                )}

                <div className="flex bg-slate-100 h-screen overflow-hidden">
                    {sidebarToggle && <Sidebar />}
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="BREEAM Assessment" section="Dashboard View" iconFileName="Dashboard-Hover" showUserMenu='true' />

                        <div className="flex items-center">
                            <div className="w-full mx-auto m-5 px-3">
                                <div className="grid grid-cols-2 gap-2 sm:gap-7 sm:grid-cols-3">
                                    <div>
                                        <label>Section</label>
                                        <MultiSelect
                                            options={aggregateSections(BREEAMAssessment.credits).map((item) => {
                                                return {
                                                    label: item.section,
                                                    value: item.section
                                                }
                                            })}
                                            value={selectedSection}
                                            onChange={setSelectedSection}
                                            labelledBy="Select"
                                        />
                                    </div>
                                    <div>
                                        <label>Category</label>
                                        <MultiSelect
                                            options={aggregateCreditCategories(BREEAMAssessment.credits).map((item) => {
                                                return {
                                                    label: item.creditCategoryDescription,
                                                    value: item.creditCategoryDescription
                                                }
                                            })}
                                            value={selectedCategory}
                                            onChange={setSelectedCategory}
                                            labelledBy="Select"
                                        />
                                    </div>
                                    <div>
                                        <label>Credit</label>
                                        <MultiSelect
                                            options={aggregateCredits(BREEAMAssessment.credits).map((item) => {
                                                return {
                                                    label: item.creditDescription,
                                                    value: item.creditDescription
                                                }
                                            })}
                                            value={selectedCredit}
                                            onChange={setSelectedCredit}
                                            labelledBy="Select"
                                        />
                                    </div>

                                    <div>

                                    </div>
                                   
                                </div>
                            </div>
                        </div>


                        <div className="flex items-center">
                            <div className="w-full mx-auto mb-5 px-3">
                                <div className="grid gap-2 sm:gap-7 sm:grid-cols-6">

                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                                        <div>
                                            <div className="text-sm text-gray-500 ">Total Credits: </div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-pacific-blue "><CountUp
                                                    start={0}
                                                    end={(BREEAMAssessment.credits.filter(item => item.credits !== "Yes" && item.credits !== "-").map(item => item.credits).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2)}
                                                    separator=","
                                                /></div>
                                            </div>
                                        </div>
                                        <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5l-3.9 19.5m-2.1-19.5l-3.9 19.5" />
                                            </svg>
                                            </div>
                                    </div>

                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                                    <div>
                                        <div className="text-sm text-gray-500 ">Total Credits Targeted: </div>
                                        <div className="flex items-center pt-1">
                                            <div className="text-xl font-medium text-pacific-blue "><CountUp
                                                start={0}
                                                end={(BREEAMAssessment.credits.filter(item => item.creditsTargeted !== "Yes" && item.creditsTargeted !== "-").map(item => item.creditsTargeted).reduce((prev, curr) => Number(prev) + Number(curr), 0)).toFixed(2)}
                                                separator=","
                                            /></div>
                                        </div>
                                    </div>
                                    <div className="text-gray-400">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5l-3.9 19.5m-2.1-19.5l-3.9 19.5" />
                                    </svg>

                                    </div>
                                    </div>

                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                                    <div>
                                        <div className="text-sm text-gray-500 ">Total Credits Achieved: </div>
                                        <div className="flex items-center pt-1">
                                            <div className="text-xl font-medium text-pacific-blue "><CountUp
                                                start={0}
                                                end={BREEAMAssessment.credits.filter(item => item.creditAchieved !== "").map(item => item.creditsTargeted).reduce((prev, curr) => Number(prev) + Number(curr), 0)}
                                                separator=","
                                            /></div>
                                        </div>
                                    </div>
                                    <div className="text-gray-400">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5l-3.9 19.5m-2.1-19.5l-3.9 19.5" />
                                    </svg>

                                    </div>
                                    </div>

                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                                    <div>
                                        <div className="text-sm text-gray-500 ">Potential Credits: </div>
                                        <div className="flex items-center pt-1">
                                            <div className="text-xl font-medium text-pacific-blue "><CountUp
                                                start={0}
                                                end={serviceCount.length}
                                                separator=","
                                            /></div>
                                        </div>
                                    </div>
                                    <div className="text-gray-400">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5l-3.9 19.5m-2.1-19.5l-3.9 19.5" />
                                    </svg>

                                    </div>
                                    </div>

                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                                    <div>
                                        <div className="text-sm text-gray-500 ">Progress %: </div>
                                        <div className="flex items-center pt-1">
                                            <div className="text-xl font-medium text-pacific-blue "><CountUp
                                                start={0}
                                                end={(((BREEAMAssessment.credits.filter(item => item.creditAchieved !== "").map(item => item.credits).reduce((prev, curr) => Number(prev) + Number(curr), 0)) / (BREEAMAssessment.credits.filter(item => item.creditsTargeted !== "Yes" && item.creditsTargeted !== "-").map(item => item.creditsTargeted).reduce((prev, curr) => Number(prev) + Number(curr), 0))) *100)}
                                                // separator=","
                                            /></div>
                                        </div>
                                    </div>
                                    <div className="text-gray-400">

                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-percent" viewBox="0 0 16 16">
                                    <path d="M13.442 2.558a.625.625 0 0 1 0 .884l-10 10a.625.625 0 1 1-.884-.884l10-10a.625.625 0 0 1 .884 0M4.5 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5m7 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5"/>
                                    </svg>
                                    
                                    </div>
                                    </div>

                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                                    <div>
                                        <div className="text-sm text-gray-500 ">Current Score %: </div>
                                        <div className="flex items-center pt-1">
                                            <div className="text-xl font-medium text-pacific-blue "><CountUp
                                                start={0}
                                                end={totalCurrentScore}
                                                decimals={2}
                                                // separator=","
                                            /></div>
                                        </div>
                                    </div>
                                    <div className="text-gray-400">

                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-percent" viewBox="0 0 16 16">
                                    <path d="M13.442 2.558a.625.625 0 0 1 0 .884l-10 10a.625.625 0 1 1-.884-.884l10-10a.625.625 0 0 1 .884 0M4.5 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5m7 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5"/>
                                    </svg>

                                    </div>
                                    </div>

                                    {/* <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                                    <div>
                                        <div className="text-sm text-gray-500 ">Remaining Credits: </div>
                                        <div className="flex items-center pt-1">
                                            <div className="text-xl font-medium text-pacific-blue "><CountUp
                                                start={0}
                                                end={serviceCount.length}
                                                separator=","
                                            /></div>
                                        </div>
                                    </div>
                                    <div className="text-gray-400">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5l-3.9 19.5m-2.1-19.5l-3.9 19.5" />
                                    </svg>

                                    </div>
                                    </div> */}

                                </div>
                            </div>
                        </div>

                        <div className="grid lg:grid-cols-5 grid-cols-1">
                            <div className="col-span-1 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Doughnut data={dataChart5} options={pieOptions} height={200}/>
                            </div>

                            <div className="col-span-2 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Bar data={dataChart5} options={barOptionsHorizontal} height={200}/>
                            </div>

                            <div className="col-span-2 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Bar data={dataChart7b} options={barOptionsStacked} height={200}/>
                            </div>
                        </div>

                                <div className="col-span-1 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                        <table className="objects-8A min-w-full divide-y divide-gray-200">

                                            <thead className="bg-pacific-blue-50">
                                                <tr>
                                                    <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Section</th>
                                                    <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Targeted</th>
                                                    <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Available</th>
                                                    <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Targeted %</th>
                                                    <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Achieved</th>
                                                    <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Achieved %</th>
                                                    <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Weighting</th>
                                                    <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Score Targeted</th>
                                                    <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Score Achieved</th>
                                                </tr>
                                            </thead>

                                            <tbody className="bg-white divide-y divide-gray-200">
                                                {weightedScores.map((item) => (
                                                    <tr key={item.section}>
                                                        <td className="px-4 md:px-6  py-2"> {item.section} </td>
                                                        <td className="px-4 md:px-6  py-2"> {item.creditsTargeted} </td>
                                                        <td className="px-4 md:px-6  py-2"> {item.credits} </td>
                                                        <td className="px-4 md:px-6  py-2"> {((item.creditsTargeted / item.credits) * 100).toFixed(0) + "%"} </td>
                                                        <td className="px-4 md:px-6  py-2"> {item.creditsAchieved} </td>
                                                        <td className="px-4 md:px-6  py-2"> {((item.creditsAchieved / item.creditsTargeted) * 100).toFixed(0) + "%"} </td>
                                                        <td className="px-4 md:px-6  py-2"> {((weightings.find(weightingRecord => weightingRecord.section === item.section && weightingRecord.assessmentType === BREEAMAssessment.assessmentType).weighting) * 100).toFixed(3) + "%"} </td>
                                                        <td className="px-4 md:px-6  py-2"> {(((item.creditsTargeted / item.credits) * 100) * weightings.find(weightingRecord => weightingRecord.section === item.section && weightingRecord.assessmentType === BREEAMAssessment.assessmentType).weighting).toFixed(2) + "%"} </td>
                                                        <td className="px-4 md:px-6  py-2"> {(((item.creditsAchieved / item.creditsTargeted) * 100) * weightings.find(weightingRecord => weightingRecord.section === item.section && weightingRecord.assessmentType === BREEAMAssessment.assessmentType).weighting).toFixed(2) + "%"} </td>
                                                    </tr>
                                                ))}
                                            </tbody>

                                        </table>
                                    </div>

                                    <br></br>
                                    <Button color="bg-bondi-blue" text="Export Data to CSV" onClick={() => arrayToCsv(servicesFullyFiltered)} />

                                </div>
                            </div>
                        </div>
        );
    } else {
        return (
            <div>
                {!sidebarToggle && (
                    <button
                        className="absolute top-2 left-1"
                        onClick={() => setSidebarToggle(!sidebarToggle)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M4 6h16M4 12h16M4 18h16"
                            />
                        </svg>
                    </button>
                )}
                {sidebarToggle && (
                    <button
                        className="absolute top-2 left-1"
                        onClick={() => setSidebarToggle(!sidebarToggle)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                )}

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle && <Sidebar />}
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="BREEAM Assessment" section="Dashboard View" iconFileName="Dashboard-Hover" showUserMenu='true' />
                        <p>Data is loading...</p>
                    </div>

                </div>
            </div>
        );
    }
};

export default DashboardBREEAMAssessments;
