import { isNumber } from "util";

const ProgressBar = (props) => {
  const { bgcolor, completed, completedLabel } = props;

    const containerStyles = {
      height: 20,
      width: '99%',
      backgroundColor: "#ddd",
      borderRadius: 50,
      margin: 5
    }
  
    const fillerStyles = {
      height: '100%',
      width: `${completed}%`,
      backgroundColor: bgcolor,
      borderRadius: 'inherit',
      textAlign: 'right'
    }
  
    const labelStyles = {
      padding: 5,
      color: 'gray',  
      fontSize: 14
    }
  
    return (

      <>
  
        <span style={labelStyles}>{isNaN(completedLabel) ? "0%" : `${completedLabel}%`}</span>
    
        <div style={containerStyles}>
  
          <div style={fillerStyles}>
  
          </div>
  
        </div>
  
      </>
  
    );
  
  };
  
  export default ProgressBar;