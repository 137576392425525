import { useState, useEffect, useMemo } from 'react'
import countryList from 'react-select-country-list'
import axios from 'axios'
import Header from '../UI/Header'
// import ProgressBar from './ProgressBar'
import { useNavigate, useMatch } from 'react-router-dom'

const AddOrUpdateEvent = () => {

    const match = useMatch('/addOrUpdateEvent/:eventId')

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [loadingClients, setLoadingClients] = useState(false)

    const countryOptions = useMemo(() => countryList().getData(), [])

    const [clients, setClients] = useState([])

    const [eventName, setEventName] = useState('')
    const [eventDescription, setEventDescription] = useState('')
    const [eventCountry, setEventCountry] = useState()
    const [eventNumberOfAttendees, setEventNumberOfAttendees] = useState(0)
    const [eventDuration, setEventDuration] = useState()
    const [eventOrganiser, setEventOrganiser] = useState('')
    const [eventContact, setEventContact] = useState('')
    const [eventContactDetails, setEventContactDetails] = useState('')
    const [eventDate, setEventDate] = useState('')
    const [clientId, setClientId] = useState('')

    const [company, setCompany] = useState("")
    const [role, setRole] = useState(0)
    const [userPropertiesLoading, setUserPropertiesLoading] = useState(true)

    const getUserProperties = async () => {

        await axios.get(process.env.REACT_APP_API_URL + "/current_user_properties")
        .then((response) => {
            setCompany(response.data.company)
            setRole(response.data.role)
        })
        .catch((err) => {
            console.log(err);
        }).finally(() => {
            setUserPropertiesLoading(false);
        });
    
    }

    useEffect(() => {

        setUserPropertiesLoading(true)

        getUserProperties()

    }, [])

    useEffect(() => {

        if (company && role && !userPropertiesLoading) {

            if (match.params.eventId !== "_add") {

                setLoading(true)

                axios.get(process.env.REACT_APP_API_URL + '/events/' + match.params.eventId)
                    .then((res) => {
                        if ((role === 3001 || role === 6001) && res.data.clientId !== company) {
                            navigate(`/errorPage`)
                        }
                        setEventName(res.data.eventName)
                        setEventDescription(res.data.eventDescription)
                        setEventNumberOfAttendees(res.data.eventNumberOfAttendees)
                        setEventCountry(res.data.eventCountry)
                        setEventDuration(res.data.eventDuration)
                        setEventOrganiser(res.data.eventOrganiser)
                        setEventContact(res.data.eventContact)
                        setEventContactDetails(res.data.eventContactDetails)
                        setEventDate(res.data.eventDate)
                        setClientId(res.data.clientId)
                    })
                    .catch((err) => {
                        console.log(err);
                    }).finally(() => {
                        setLoading(false);
                    });
            }

            if (role === 3001 || role === 6001) {

                axios.get(process.env.REACT_APP_API_URL + '/clients/' + company)
                .then((res) => {
                    setClients([res.data]);
                    if (match.params.organisationId === "_add") {
                        setClientId(res.data.id);
                    }
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoadingClients(false);
                });

            } else {

                axios.get(process.env.REACT_APP_API_URL + '/clients')
                    .then((res) => {
                        setClients(res.data.sort((item1, item2) => item1.clientName + item1.clientName < item2.clientName + item2.clientName ? -1 : 0));
                        if (match.params.organisationId === "_add") {
                            setClientId(res.data[0].id);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    }).finally(() => {
                        setLoadingClients(false);
                    });

            }

        }
    }, [match.params.eventId, company, role, userPropertiesLoading])

    const addForm = async (form) => {
        console.log(form)
        await axios.post(process.env.REACT_APP_API_URL + '/events', form)
        console.log("after post")
    }

    const updateForm = async (form) => {
        await axios.put(process.env.REACT_APP_API_URL + '/events/' + match.params.eventId, form)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (match.params.eventId !== "_add") {
            updateForm({ clientId, eventName, eventDescription, eventCountry, eventNumberOfAttendees, eventDuration, eventOrganiser, eventContact, eventContactDetails, eventDate }).then(res => { navigate(-1) })
        } else {
            addForm({ clientId, eventName, eventDescription, eventCountry, eventNumberOfAttendees, eventDuration, eventOrganiser, eventContact, eventContactDetails, eventDate }).then(res => { navigate(-1) })
        }
    }

    if (clients.length > 0 && !loading && !loadingClients && !userPropertiesLoading && role && company) {

        return (

            <div className='flex items-center bg-jungle-mist-100 min-h-screen'>
                <div className="container-form-8A">

                <Header title="Event Footprint Tool - Add / Edit Event" section="Project Manager Section" iconFileName="Event-Hover" url={role === 3001 || role === 6001 ? "/ViewClient/" + company : "/"}/>

                {/* <p>Wellbeing Performance</p><ProgressBar bgcolor="#0091ca" completed={Math.round((wellbeingScore/6)*100)} />
                <p>Waste Performance</p><ProgressBar bgcolor="#0091ca" completed={Math.round((wasteScore/2)*100)} /> */}

                <br></br>

                <form className="add-form-8A" onSubmit={onSubmit}>

                    <div className="form-control-8A">
                        <label>Client</label>
                        <select className= {role === 3001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role === 3001 ? true : false} value={clientId} onChange={(e) => setClientId(e.target.value)}>
                            {clients.map((client) => (
                                <option key={client.id} value={client.id}>{client.clientName}</option>
                            ))}
                        </select>
                    </div>

                    <p className="text-2xl font-bold"> General Event Information</p>
                    <div className="form-control-8A">
                        <label>Event Name</label>
                        <input className= {role === 3001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} readOnly={role === 3001 ? true : false} type="text" placeholder="Enter Event Name Here" value={eventName} onChange={(e) => setEventName(e.target.value)} />
                    </div>
                    <div className="form-control-8A">
                        <label>Event Date</label>
                        <input type="date" placeholder="Enter Event Date Here" value={eventDate} onChange={(e) => setEventDate(e.target.value)} />
                    </div>
                    <div className="form-control-8A">
                        <label>Event Description</label>
                        <input type="text" placeholder="Enter Event Description Here" value={eventDescription} onChange={(e) => setEventDescription(e.target.value)} />
                    </div>
                    <div className="form-control-8A">
                        <label>Country Name</label>
                        <select value={eventCountry} onChange={(e) => setEventCountry(e.target.value)}>
                            {countryOptions.map((country) => (
                                <option key={country.value} value={country.value}>{country.label}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-control-8A">
                        <label>Event Number of Attendees</label>
                        <input type="text" placeholder="Enter Number of Attendees Here" value={eventNumberOfAttendees} onChange={(e) => setEventNumberOfAttendees(e.target.value)} />
                    </div>
                    <div className="form-control-8A">
                        <label>Duration</label>
                        <input type="text" placeholder="Enter Event Duration (Days) Here" value={eventDuration} onChange={(e) => setEventDuration(e.target.value)} />
                    </div>
                    <div className="form-control-8A">
                        <label>Event Organiser</label>
                        <input type="text" placeholder="Enter Event Organiser Here" value={eventOrganiser} onChange={(e) => setEventOrganiser(e.target.value)} />
                    </div>
                    <div className="form-control-8A">
                        <label>Main Contact</label>
                        <input type="text" placeholder="Enter Contact Name Here" value={eventContact} onChange={(e) => setEventContact(e.target.value)} />
                    </div>
                    <div className="form-control-8A">
                        <label>Contact Details</label>
                        <input type="text" placeholder="Enter Contact Details Here" value={eventContactDetails} onChange={(e) => setEventContactDetails(e.target.value)} />
                    </div>

                    <input type="submit" value="Save" className="btn-8A btn-block-8A" />

                </form>

            </div>
            </div>
        )

    } else {

        return (
            <div>
                <Header title="Event Footprint Tool - Add / Edit Event" section="Project Manager Section" iconFileName="Event-Hover" url={role === 3001 || role === 6001 ? "/ViewClient/" + company : "/"}/>
                <p>Data is loading...</p>
            </div>)

    }

}

export default AddOrUpdateEvent
