import { useEffect, useState } from 'react'
import axios from 'axios'
import { MultiSelect } from "react-multi-select-component";
import { useNavigate, useMatch } from 'react-router-dom'
import ModalUpdateValue from '../UI/ModalUpdateValue'
import FileUploadAndDownload from '../FileUploadAndDownload'


const ListBuildingBREEAMCreditsInformationSchedule = (props) => {

    const match = useMatch('/viewBuildingBREEAMAssessment/:buildingId/:assessmentId')

    const [loading, setLoading] = useState(true)
    const [filesLoading, setFilesLoading] = useState(true)

    const [buildingBREEAMCredits, setBuildingBREEAMCredits] = useState([])
    const [buildingBREEAMAssessment, setBuildingBREEAMAssessment] = useState([])
    //   const [buildingBREEAMRequiredEvidence, setBuildingBREEAMRequiredEvidence] = useState([])

    const [selectedSection, setSelectedSection] = useState([])
    const [selectedResponsibility, setSelectedResponsibility] = useState([])
    const [selectedCategoryCode, setSelectedCategoryCode] = useState([])
    const [selectedCreditDescription, setSelectedCreditDescription] = useState([])
    const [selectedCategoryDescription, setSelectedCategoryDescription] = useState([])
    const [selectedFiles, setSelectedFiles] = useState([])
    const [selectedEvidenceFiles, setSelectedEvidenceFiles] = useState([])
    const [selectedInfoRequired, setSelectedInfoRequired] = useState([])
    const [selectedStage, setSelectedStage] = useState([])
    const [selectedAchieved, setSelectedAchieved] = useState([])

    const [allTeamMembers, setAllTeamMembers] = useState([])
    const [allEvidenceItems, setAllEvidenceItems] = useState([])

    const [showModal, setShowModal] = useState(false)
    const [valueToSendToModal, setValueToSendToModal] = useState('')
    const [objectIdToSendToModal, setObjectIdToSendToModal] = useState('')
    const [fieldToSendToModal, setFieldToSendToModal] = useState('')
    const [collectionToSendToModal, setCollectionToSendToModal] = useState('')
    const [collectionArrayIdToSendToModal, setCollectionArrayIdToSendToModal] = useState('')
    const [collectionSubArrayIdToSendToModal, setCollectionSubArrayIdToSendToModal] = useState('')
    const [titleToSendToModal, setTitleToSendToModal] = useState('')
    const [collectionArrayToSendToModal, setCollectionArrayToSendToModal] = useState('')

    const [evidenceRequiredCount, setEvidenceRequiredCount] = useState(0)
    const [evidenceReceivedCount, setEvidenceReceivedCount] = useState(0)

    const [files, setFiles] = useState([])
    const [allFiles, setAllFiles] = useState([])

    const [idToShowFilesFor, setIdToShowFilesFor] = useState("");

    const toggleWhichItemToShowFilesFor = (id) => {

        setIdToShowFilesFor(id)

    }

    const aggregateResponsibilityArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.responsibility === val.responsibility);
            if (index === -1) {
                acc.push({
                    responsibility: val.responsibility,
                });
            };
            return acc.sort((item1, item2) => item1.responsibility < item2.responsibility ? -1 : item1.responsibility > item2.responsibility ? 1 : 0);
        }, []);
    };

    const aggregateSectionArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.section === val.section);
            if (index === -1) {
                acc.push({
                    section: val.section,
                });
            };
            return acc;
        }, []);
    };

    const aggregateCreditCategoryCodeArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.creditCategoryCode === val.creditCategoryCode);
            if (index === -1) {
                acc.push({
                    creditCategoryCode: val.creditCategoryCode,
                });
            };
            return acc;
        }, []);
    };

    const aggregateCreditCategoryDescriptionArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.creditCategoryDescription === val.creditCategoryDescription);
            if (index === -1) {
                acc.push({
                    creditCategoryDescription: val.creditCategoryDescription,
                });
            };
            return acc;
        }, []);
    };

    const aggregateCreditDescriptionArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.creditDescription === val.creditDescription);
            if (index === -1) {
                acc.push({
                    creditDescription: val.creditDescription,
                });
            };
            return acc;
        }, []);
    };

    const aggregateInfoRequiredArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.description === val.description);
            if (index === -1) {
                acc.push({
                    description: val.description,
                });
            };
            return acc;
        }, []);
    };

    const aggregateStageArray = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.requiredAtStage === val.requiredAtStage);
            if (index === -1) {
                acc.push({
                    requiredAtStage: val.requiredAtStage,
                });
            };
            return acc;
        }, []);
    };

    const toggleAchieved = async (id) => {

        var allBREEAMCreditData
        var newBREEAMAssessment

        await axios.get(process.env.REACT_APP_API_URL + '/buildings_BREEAM_assessments/' + match.params.assessmentId)
            .then((res) => res.data)
            .then((res) => {

                newBREEAMAssessment = res
                allBREEAMCreditData = res.credits

                if (allBREEAMCreditData.find(item => item.id === id).creditAchieved === "") {
                    allBREEAMCreditData.find(item => item.id === id).creditAchieved = "YES"
                } else {
                    allBREEAMCreditData.find(item => item.id === id).creditAchieved = ""
                }

                newBREEAMAssessment.credits = allBREEAMCreditData

                setBuildingBREEAMAssessment(newBREEAMAssessment)
                setBuildingBREEAMCredits(allBREEAMCreditData)

            })
            .catch((err) => {
                console.log(err);
            })

        await axios.put(process.env.REACT_APP_API_URL + '/buildings_BREEAM_assessments/' + match.params.assessmentId, { ...newBREEAMAssessment })

    }

    useEffect(() => {

        setLoading(true)

        axios.get(process.env.REACT_APP_API_URL + '/buildings_BREEAM_assessments/' + match.params.assessmentId)
            .then((res) => res.data)
            .then((res) => {

                setBuildingBREEAMCredits(res.credits);
                setBuildingBREEAMAssessment(res);
                setSelectedCategoryCode(aggregateCreditCategoryCodeArray(res.credits).map((item) => {
                    return {
                        label: item.creditCategoryCode,
                        value: item.creditCategoryCode
                    }
                }))
                setSelectedCategoryDescription(aggregateCreditCategoryDescriptionArray(res.credits).map((item) => {
                    return {
                        label: item.creditCategoryDescription,
                        value: item.creditCategoryDescription
                    }
                }))
                setSelectedCreditDescription(aggregateCreditDescriptionArray(res.credits).map((item) => {
                    return {
                        label: item.creditDescription,
                        value: item.creditDescription
                    }
                }))

                setSelectedResponsibility(res.projectTeam.map((item) => {
                    return {
                        label: item.role,
                        value: item.role
                    }
                }).sort((item1, item2) => item1.label < item2.label ? -1 : item1.label > item2.label ? 1 : 0))
                setAllTeamMembers(res.projectTeam.map((item) => {
                    return {
                        label: item.role + " (" + item.name + ")",
                        value: item.role
                    }
                }).sort((item1, item2) => item1.label < item2.label ? -1 : item1.label > item2.label ? 1 : 0))
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });

        axios.get(process.env.REACT_APP_API_URL + '/presigned_url/' + match.params.buildingId)
            .then((res) => {
                setAllFiles(res.data)
                setSelectedFiles(res.data.map((item) => {
                    return {
                        label: item.name.slice(25, item.name.length),
                        value: item.name.slice(25, item.name.length)
                    }
                }).concat([{ label: "", value: "" }]))
                setSelectedEvidenceFiles(res.data.map((item) => {
                    return {
                        label: item.name.slice(25, item.name.length),
                        value: item.name.slice(25, item.name.length)
                    }
                }).concat([{ label: "", value: "" }]))
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setFilesLoading(false);
            });

    }, [match.params.assessmentId, match.params.buildingId])

    useEffect(() => {

        var credit

        var documentaryEvidenceRequiredCount = 0
        var documentaryEvidenceReceivedCount = 0

        var evidenceDocumentRecords = []

        for (credit = 0; credit < buildingBREEAMCredits.length; credit++) {

            documentaryEvidenceRequiredCount = documentaryEvidenceRequiredCount + buildingBREEAMCredits[credit].documentaryEvidence.filter(item => item.evidenceDocumentRequired === "YES").length
            documentaryEvidenceReceivedCount = documentaryEvidenceReceivedCount + buildingBREEAMCredits[credit].documentaryEvidence.filter(item => item.evidenceDocumentRequired === "YES" && item.filename !== "").length

            //Code here to add to array for use by selectedInfoRequired, selectedResponsibility and selectedStage
            evidenceDocumentRecords.push(...buildingBREEAMCredits[credit].documentaryEvidence.filter(item => item.evidenceDocumentRequired === "YES"))

        }

        setAllEvidenceItems(evidenceDocumentRecords)

        setSelectedInfoRequired(aggregateInfoRequiredArray(evidenceDocumentRecords).map((item) => {
            return {
                label: item.description,
                value: item.description
            }
        }))

        setSelectedResponsibility(aggregateResponsibilityArray(evidenceDocumentRecords).map((item) => {
            return {
                label: item.responsibility,
                value: item.responsibility
            }
        }))

        setSelectedStage(aggregateStageArray(evidenceDocumentRecords).map((item) => {
            return {
                label: item.requiredAtStage,
                value: item.requiredAtStage
            }
        }))

        setEvidenceRequiredCount(documentaryEvidenceRequiredCount)
        setEvidenceReceivedCount(documentaryEvidenceReceivedCount)

    }, [buildingBREEAMCredits, showModal])

    useEffect(() => {

        props.selectedStakeholder(selectedResponsibility)

    }, [props, selectedResponsibility])

    const navigate = useNavigate()

    if (!loading && !filesLoading && buildingBREEAMCredits.length > 0) {

        return (
            <div>

                <div className="p-3 grid gap-2 sm:gap-7 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">

                    <div className="flex items-center grid-cols-1 sm:col-span-1 justify-between p-2 md:p-4 bg-white rounded-2xl shadow-md shadow-slate-300">
                        <div>
                            <div className="text-sm text-gray-500 ">Evidence Items Required: </div>
                            <div className="flex items-center">
                                <div className="text-xl font-medium text-pacific-blue "><p className="my-1">{evidenceRequiredCount}</p></div>
                            </div>
                        </div>
                        <div className="text-gray-400">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                            </svg>
                        </div>
                    </div>

                    <div className="flex items-center grid-cols-1 sm:col-span-1 justify-between p-2 md:p-4 bg-white rounded-2xl shadow-md shadow-slate-300">
                        <div>
                            <div className="text-sm text-gray-500 ">Evidence Items Received and Confirmed: </div>
                            <div className="flex items-center">
                                <div className="text-xl font-medium text-pacific-blue "><p className="my-1">{evidenceReceivedCount}</p></div>
                            </div>
                        </div>
                        <div className="text-gray-400">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                            </svg>
                        </div>
                    </div>

                    <div className="flex items-center grid-cols-1 sm:col-span-1 justify-between p-2 md:p-4 bg-white rounded-2xl shadow-md shadow-slate-300">
                        <div>
                            <div className="text-sm text-gray-500 ">Evidence Items Required to Date: </div>
                            <div className="flex items-center">
                                <div className="text-xl font-medium text-pacific-blue "><p className="my-1">{evidenceRequiredCount}</p></div>
                            </div>
                        </div>
                        <div className="text-gray-400">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                            </svg>
                        </div>
                    </div>

                    <div className="flex items-center grid-cols-1 sm:col-span-1 justify-between p-2 md:p-4 bg-white rounded-2xl shadow-md shadow-slate-300">
                        <div>
                            <div className="text-sm text-gray-500 ">Evidence Items Received and Confirmed to Date: </div>
                            <div className="flex items-center">
                                <div className="text-xl font-medium text-pacific-blue "><p className="my-1">{evidenceReceivedCount}</p></div>
                            </div>
                        </div>
                        <div className="text-gray-400">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                            </svg>
                        </div>
                    </div>

                </div>

                <div className="flex flex-col py-2">
                    <div className="overflow-x-auto items-holder-8A">
                        <div className="text-eight-text align-middle inline-block min-w-full px-3">
                            <div className="shadow-md shadow-slate-300 rounded-2xl">

                                <table id="exportContent" className="objects-8A min-w-full divide-y divide-gray-200 text-xs">
                                    <thead className="bg-pacific-blue-50 z-10 sticky top-0">
                                        <tr>
                                            <th scope="col" className="px-4 md:px-6 py-3 text-left text-sm font-medium text-congress-blue uppercase"> Achieved {!props.copyingView && <div className="w-32">
                                                <MultiSelect
                                                    options={[{ label: "NO", value: "" }, { label: "YES", value: "YES" }]}
                                                    value={selectedAchieved}
                                                    onChange={setSelectedAchieved}
                                                    labelledBy="Select"
                                                />
                                            </div>}</th>
                                            <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> Category {!props.copyingView && <div className="w-32">
                                                <MultiSelect
                                                    options={aggregateCreditCategoryCodeArray(buildingBREEAMCredits).map((item) => {
                                                        return {
                                                            label: item.creditCategoryCode,
                                                            value: item.creditCategoryCode
                                                        }
                                                    })}
                                                    value={selectedCategoryCode}
                                                    onChange={setSelectedCategoryCode}
                                                    labelledBy="Select"
                                                />
                                            </div>}</th>
                                            <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> Category Description {!props.copyingView && <div className="w-40">
                                                <MultiSelect
                                                    options={aggregateCreditCategoryDescriptionArray(buildingBREEAMCredits).map((item) => {
                                                        return {
                                                            label: item.creditCategoryDescription,
                                                            value: item.creditCategoryDescription
                                                        }
                                                    })}
                                                    value={selectedCategoryDescription}
                                                    onChange={setSelectedCategoryDescription}
                                                    labelledBy="Select"
                                                />
                                            </div>}</th>
                                            <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> Credit Description {!props.copyingView && <div className="w-40">
                                                <MultiSelect
                                                    options={aggregateCreditDescriptionArray(buildingBREEAMCredits).map((item) => {
                                                        return {
                                                            label: item.creditDescription,
                                                            value: item.creditDescription
                                                        }
                                                    })}
                                                    value={selectedCreditDescription}
                                                    onChange={setSelectedCreditDescription}
                                                    labelledBy="Select"
                                                />
                                            </div>}</th>
                                            <th scope="col" className="px-4 md:px-6 py-3 text-left text-sm font-medium text-congress-blue uppercase tracking-wider [writing-mode:vertical-lr]"> Credits</th>
                                            <th scope="col" className="px-4 md:px-6 py-3 text-left text-sm font-medium text-congress-blue uppercase tracking-wider [writing-mode:vertical-lr]"> Minimum</th>
                                            <th scope="col" className="px-4 md:px-6 py-3 text-left text-sm font-medium text-congress-blue uppercase tracking-wider [writing-mode:vertical-lr]"> Targeted</th>
                                            <th scope="col" className="px-4 md:px-6 py-3 text-left text-sm font-medium text-congress-blue uppercase tracking-wider [writing-mode:vertical-lr]"> Potential</th>
                                            {/* <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Manage Files<div className="w-40">
                                                                <MultiSelect
                                                                    options={allFiles.map((item) => {
                                                                        return {
                                                                            label: item.name.slice(25, item.name.length),
                                                                            value: item.name.slice(25, item.name.length)
                                                                        }
                                                                    }).concat([{label:"", value: ""}])}
                                                                    value={selectedFiles}
                                                                    onChange={setSelectedFiles}
                                                                    labelledBy="Select"
                                                                />
                                                                </div></th> */}
                                            <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider w-[1000px]">
                                                <div className="grid grid-cols-4 tracking-wider w-[1000px]">
                                                    {!props.copyingView &&
                                                        <div className="w-40 mr-3">
                                                            <label className="whitespace-nowrap">Info Required</label>
                                                            <MultiSelect
                                                                options={aggregateInfoRequiredArray(allEvidenceItems).map((item) => {
                                                                    return {
                                                                        label: item.description,
                                                                        value: item.description
                                                                    }
                                                                })}
                                                                value={selectedInfoRequired}
                                                                onChange={setSelectedInfoRequired}
                                                                labelledBy="Select"
                                                            />
                                                        </div>
                                                    }
                                                    {!props.copyingView &&
                                                        <div className="w-40 mr-3">
                                                            <label className="whitespace-nowrap">Responsibility</label>
                                                            <MultiSelect
                                                                options={aggregateResponsibilityArray(allEvidenceItems).map((item) => {
                                                                    return {
                                                                        label: item.responsibility,
                                                                        value: item.responsibility
                                                                    }
                                                                })}
                                                                value={selectedResponsibility}
                                                                onChange={setSelectedResponsibility}
                                                                labelledBy="Select"
                                                            />
                                                        </div>
                                                    }
                                                    {/* <div className="w-40">
                                                                <label>Time Critical</label>
                                                                <MultiSelect
                                                                    options={aggregateSectionArray(buildingBREEAMCredits).map((item) => {
                                                                        return {
                                                                            label: item.section,
                                                                            value: item.section
                                                                        }
                                                                    })}
                                                                    value={selectedSection}
                                                                    onChange={setSelectedSection}
                                                                    labelledBy="Select"
                                                                />
                                                                </div> */}
                                                    {!props.copyingView &&
                                                        <div className="w-40 mr-3">
                                                            <label className="whitespace-nowrap">Stage</label>
                                                            <MultiSelect
                                                                options={aggregateStageArray(allEvidenceItems).map((item) => {
                                                                    return {
                                                                        label: item.requiredAtStage,
                                                                        value: item.requiredAtStage
                                                                    }
                                                                })}
                                                                value={selectedStage}
                                                                onChange={setSelectedStage}
                                                                labelledBy="Select"
                                                            />
                                                        </div>
                                                    }
                                                    {!props.copyingView &&
                                                        <div className="w-40">
                                                            <label className="whitespace-nowrap">Evidence Files</label>
                                                            <MultiSelect
                                                                options={allFiles.map((item) => {
                                                                    return {
                                                                        label: item.name.slice(25, item.name.length),
                                                                        value: item.name.slice(25, item.name.length)
                                                                    }
                                                                }).concat([{ label: "", value: "" }])}
                                                                value={selectedEvidenceFiles}
                                                                onChange={setSelectedEvidenceFiles}
                                                                labelledBy="Select"
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            </th>
                                            {!props.copyingView &&
                                                <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Manage Files <div className="w-40">
                                                    <MultiSelect
                                                        options={allFiles.map((item) => {
                                                            return {
                                                                label: item.name.slice(25, item.name.length),
                                                                value: item.name.slice(25, item.name.length)
                                                            }
                                                        }).concat([{ label: "", value: "" }])}
                                                        value={selectedFiles}
                                                        onChange={setSelectedFiles}
                                                        labelledBy="Select"
                                                    />
                                                </div></th>}
                                            {!props.copyingView && <th scope="col" className="px-4 md:px-6 py-3 text-left text-sm font-medium text-congress-blue uppercase tracking-wider whitespace-nowrap"> Credit Overview</th>}
                                        </tr>
                                    </thead>
                                    <tbody className="m-5 bg-white divide-y divide-gray-200">
                                        {buildingBREEAMCredits.filter(item => Number(item.creditsTargeted) > 0 || Number(item.creditsPotential > 0 || item.creditsTargeted === "Yes" || item.creditsPotential === "Yes")).filter(item => item.documentaryEvidence.filter(item => item.evidenceDocumentRequired !== "NO").filter((itemA) => {
                                            return selectedInfoRequired.find((itemB) => {
                                                return itemA.description === itemB.value;
                                            })
                                        })
                                            .filter((itemA) => {
                                                return selectedResponsibility.find((itemB) => {
                                                    return itemA.responsibility === itemB.value;
                                                })
                                            })
                                            .filter((itemA) => {
                                                return selectedStage.find((itemB) => {
                                                    return itemA.requiredAtStage === itemB.value;
                                                })
                                            })
                                            .filter((itemA) => {
                                                return selectedEvidenceFiles.find((itemB) => {
                                                    return itemA.filename.includes(itemB.value);
                                                })
                                            }).length > 0)
                                            .filter((itemA) => {
                                                return selectedCategoryCode.find((itemB) => {
                                                    return itemA.creditCategoryCode === itemB.value;
                                                })
                                            })
                                            .filter((itemA) => {
                                                return selectedCategoryDescription.find((itemB) => {
                                                    return itemA.creditCategoryDescription === itemB.value;
                                                })
                                            })
                                            .filter((itemA) => {
                                                return selectedCreditDescription.find((itemB) => {
                                                    return itemA.creditDescription === itemB.value;
                                                })
                                            }).filter(item => item.files.concat([{ filename: "" }]).filter((itemA) => {
                                                return selectedFiles.find((itemB) => {
                                                    return itemA.filename.includes(itemB.value);
                                                })
                                            }).length > 0).map((credit) => (
                                                <tr key={credit.id}>
                                                    {!props.copyingView &&
                                                        <td className="px-4 md:px-3 py-2 hover:cursor-pointer" onClick={credit.documentaryEvidence.filter(item => item.filename !== "").length === credit.documentaryEvidence.filter(item => item.evidenceDocumentRequired === "YES").length ? (e) => toggleAchieved(credit.id) : () => { }}>{credit.documentaryEvidence.filter(item => item.filename !== "").length === credit.documentaryEvidence.filter(item => item.evidenceDocumentRequired === "YES").length && credit.creditAchieved === "" ? <img className="h-10 ml-6" src="/images/Amber-Tick.png" alt=""></img> : credit.documentaryEvidence.filter(item => item.filename !== "").length === credit.documentaryEvidence.filter(item => item.evidenceDocumentRequired === "YES").length && credit.creditAchieved !== "" ? <img className="h-10 ml-6" src="/images/GreenTick.png" alt=""></img> : ""}</td>
                                                    }
                                                    {props.copyingView &&
                                                        <td className="px-4 md:px-3 py-2 hover:cursor-pointer" onClick={credit.documentaryEvidence.filter(item => item.filename !== "").length === credit.documentaryEvidence.filter(item => item.evidenceDocumentRequired === "YES").length ? (e) => toggleAchieved(credit.id) : () => { }}>{credit.documentaryEvidence.filter(item => item.filename !== "").length === credit.documentaryEvidence.filter(item => item.evidenceDocumentRequired === "YES").length && credit.creditAchieved === "" ? "NO" : credit.documentaryEvidence.filter(item => item.filename !== "").length === credit.documentaryEvidence.filter(item => item.evidenceDocumentRequired === "YES").length && credit.creditAchieved !== "" ? "YES" : ""}</td>
                                                    }
                                                    <td className="px-2 md:px-3 py-2"> {credit.creditCategoryCode} </td>
                                                    <td className="px-2 md:px-3 py-2"> {credit.creditCategoryDescription} </td>
                                                    <td className="px-2 md:px-3 py-2"> {credit.creditDescription} </td>
                                                    <td className="px-2 md:px-3 py-2 hover:cursor-pointer" onClick={() => { setShowModal(true); setValueToSendToModal(credit.credits); setFieldToSendToModal("BREEAMCreditsValue"); setObjectIdToSendToModal(match.params.assessmentId); setCollectionToSendToModal("buildings_BREEAM_assessments"); setCollectionArrayToSendToModal("credits"); setCollectionArrayIdToSendToModal(credit.id) }}> {credit.credits} </td>
                                                    <td className="px-2 md:px-3 py-2 hover:cursor-pointer" onClick={() => { setShowModal(true); setValueToSendToModal(credit.creditsMinimumStandards); setFieldToSendToModal("BREEAMMinimumStandardsValue"); setObjectIdToSendToModal(match.params.assessmentId); setCollectionToSendToModal("buildings_BREEAM_assessments"); setCollectionArrayToSendToModal("credits"); setCollectionArrayIdToSendToModal(credit.id) }}> {credit.creditsMinimumStandards} </td>
                                                    <td className="px-2 md:px-3 py-2 hover:cursor-pointer" onClick={() => { setShowModal(true); setValueToSendToModal(credit.creditsTargeted); setFieldToSendToModal("BREEAMCreditsTargetValue"); setObjectIdToSendToModal(match.params.assessmentId); setCollectionToSendToModal("buildings_BREEAM_assessments"); setCollectionArrayToSendToModal("credits"); setCollectionArrayIdToSendToModal(credit.id) }}> {credit.creditsTargeted} </td>
                                                    <td className="px-2 md:px-3 py-2 hover:cursor-pointer" onClick={() => { setShowModal(true); setValueToSendToModal(credit.creditsPotential); setFieldToSendToModal("BREEAMCreditsPotentialValue"); setObjectIdToSendToModal(match.params.assessmentId); setCollectionToSendToModal("buildings_BREEAM_assessments"); setCollectionArrayToSendToModal("credits"); setCollectionArrayIdToSendToModal(credit.id) }}> {credit.creditsPotential} </td>
                                                    <td className="px-2 md:px-3 py-2 w-[1000px]">
                                                        <table className="min-w-full w-[1000px]">
                                                            {/* <thead className="bg-pacific-blue-50">
                                    <tr>
                                    
                                    <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase w-2/6 tracking-wider"> Information Required</th>
                                    <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase w-1/6 tracking-wider"> Responsibility</th>
                                    <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase w-1/6 tracking-wider"> Stage</th>
                                    <th scope="col" className="px-4 md:px-6 py-3 text-left text-xs font-medium text-congress-blue uppercase w-1/6 tracking-wider"> Evidence File Name(s)</th>
                                    </tr>
                                </thead> */}

                                                            <tbody className="bg-white divide-y divide-gray-200">
                                                                {buildingBREEAMCredits.find(item => item.id === credit.id).documentaryEvidence.filter(item => item.evidenceDocumentRequired !== "NO").filter((itemA) => {
                                                                    return selectedInfoRequired.find((itemB) => {
                                                                        return itemA.description === itemB.value;
                                                                    })
                                                                })
                                                                    .filter((itemA) => {
                                                                        return selectedResponsibility.find((itemB) => {
                                                                            return itemA.responsibility === itemB.value;
                                                                        })
                                                                    })
                                                                    .filter((itemA) => {
                                                                        return selectedStage.find((itemB) => {
                                                                            return itemA.requiredAtStage === itemB.value;
                                                                        })
                                                                    })
                                                                    .filter((itemA) => {
                                                                        return selectedEvidenceFiles.find((itemB) => {
                                                                            return itemA.filename.includes(itemB.value);
                                                                        })
                                                                    }).map((item) => (
                                                                        <tr key={item.id}>
                                                                            <td className={item.filename !== "" ? "px-2 md:px-3 py-2 bg-emerald-200 w-1/4" : item.complianceLetter !== "-" ? "px-2 md:px-3 py-2 w-1/4" : "px-2 md:px-3 py-2 bg-pacific-blue-100 w-1/4"}> {item.description} </td>
                                                                            <td className={item.filename !== "" ? "px-2 md:px-3 py-2 bg-emerald-200 w-1/4" : item.complianceLetter !== "-" ? "px-2 md:px-3 py-2 w-1/4" : "px-2 md:px-3 py-2 bg-pacific-blue-100 w-1/4"}> {buildingBREEAMAssessment.projectTeam.find(teamMember => teamMember.role === item.responsibility) ? item.responsibility + " (" + buildingBREEAMAssessment.projectTeam.find(teamMember => teamMember.role === item.responsibility).name + ")" : item.responsibility} </td>
                                                                            {/* <td className={item.filename !== "" ? "px-2 md:px-3 py-2 bg-emerald-200 w-1/4" : item.complianceLetter !== "-" ? "px-2 md:px-3 py-2 w-1/4" : "px-2 md:px-3 py-2 bg-pacific-blue-100 w-1/4"}> {item.responsibility} </td> */}
                                                                            <td className={item.filename !== "" ? "px-2 md:px-3 py-2 bg-emerald-200 w-1/4" : item.complianceLetter !== "-" ? "px-2 md:px-3 py-2 w-1/4" : "px-2 md:px-3 py-2 bg-pacific-blue-100 w-1/4"}> {item.requiredAtStage} </td>
                                                                            {/* <td className={item.filename !== "" ? "px-2 md:px-3 py-2 bg-emerald-200" : item.complianceLetter !== "-" ? "px-2 md:px-3 py-2" : "px-2 md:px-3 py-2 bg-pacific-blue-100"}> {item.requiredAtStage} </td> */}
                                                                            <td className={item.filename !== "" ? "px-2 md:px-3 py-2 hover:cursor-pointer whitespace-nowrap bg-emerald-200 w-1/4" : item.complianceLetter !== "-" ? "px-2 md:px-3 py-2 hover:cursor-pointer whitespace-nowrap w-1/4" : "px-2 md:px-3 py-2 hover:cursor-pointer whitespace-nowrap bg-pacific-blue-100 w-1/4"}><div onClick={credit.creditAchieved === "" ? () => { setShowModal(true); setValueToSendToModal(item.filename); setFieldToSendToModal("evidenceDocumentFilename"); setObjectIdToSendToModal(match.params.assessmentId); setCollectionArrayIdToSendToModal(credit.id); setCollectionSubArrayIdToSendToModal(item.id); setFiles(credit.files); setTitleToSendToModal("Select Evidence Documents") } : () => { }} className="text-sm font-medium text-pacific-blue hover:cursor-pointer whitespace-pre-line"> {credit.creditAchieved === "" ? <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
                                                                                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                                                <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                                            </svg> : <></>} {item.filename}</div></td>
                                                                        </tr>
                                                                    ))}
                                                            </tbody>

                                                        </table>
                                                    </td>
                                                    {!props.copyingView && <td className="px-2 md:px-3 py-2 hover:cursor-pointer" onClick={(e) => { toggleWhichItemToShowFilesFor(credit.id) }}>{credit.id === idToShowFilesFor ? <><FileUploadAndDownload id={match.params.buildingId} objectToLink={{ assessmentId: match.params.assessmentId, assessmentItemId: credit.id }} filesToShowArray={credit.files} showThumbnails={true} allowUploads={true} subcategory={credit.creditCategoryCode} allowZip={true} fileUploadWindowPosition={"fixed top-0 left-0"} /><button className="rounded-full ml-[3px] pr-2 pl-2 pt-[3px] pb-[3px] text-white t-10 bg-pacific-blue-500 text-center text-[17px] whitespace-nowrap" onClick={() => {
                                                        setShowModal(true); setValueToSendToModal(credit.files.map((item) => {
                                                            return {
                                                                label: item.filename,
                                                                value: item.filename
                                                            }
                                                        })); setFieldToSendToModal("associateExistingFiles"); setObjectIdToSendToModal(match.params.assessmentId); setCollectionArrayIdToSendToModal(credit.id); setFiles(allFiles); setTitleToSendToModal("Assign Existing Files to Credit")
                                                    }}>Assign existing files</button></> : "Click Here To Manage Files"} </td>}
                                                    {!props.copyingView && <td className="px-2 md:px-3 py-2 tracking-wider"> <p className="w-72">{credit.creditsOverview}</p> </td>}
                                                </tr>


                                            ))}
                                    </tbody>
                                </table>

                                <ModalUpdateValue objectToUpdate={objectIdToSendToModal} arrayIdToUpdate={collectionArrayIdToSendToModal} subArrayIdToUpdate={collectionSubArrayIdToSendToModal} fieldToUpdate={fieldToSendToModal} valueToUpdate={valueToSendToModal} collectionToUpdate={collectionToSendToModal} arrayToShow={buildingBREEAMCredits} selectionOptions={files} onClose={() => setShowModal(false)} show={showModal} title={titleToSendToModal} />
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>

            </div>
        )

    } else {

        return (
            <div>
                <p>Data is loading...</p>
            </div>)

    }

}

export default ListBuildingBREEAMCreditsInformationSchedule
