import Header from '../UI/Header'
import { useState, useEffect } from 'react'
import { useMatch } from 'react-router-dom'
import axios from 'axios';

const ViewStore = () => {

    const match = useMatch('/viewStore/:storeId')

    const [loading, setLoading] = useState(false)

    const [projectName, setProjectName] = useState('')
    const [brand, setBrand] = useState('')
    const [mainConcept, setMainConcept] = useState('')

    useEffect(() => {

        setLoading(true)

        axios.get(process.env.REACT_APP_API_URL + '/stores/' + match.params.storeId)
        .then((res) => {
        setProjectName(res.data.projectName)   
        setBrand(res.data.brand)   
        setMainConcept(res.data.mainConcept)   

        })
        .catch((err) => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
        });  

    }, [match.params.storeId])

    if (!loading) {

        return (
        <div>
            <Header title="Store - View" section="Store Main Details" showButtons={false} iconFileName="BE-Hover"/>

            <br></br>
            <div className = "viewObjectDetailsBox-8A">
                <h3> View Store Details</h3>
                <br></br>
                <div>
                    <div>
                        <label> Project Name: </label>
                        <p className = "viewObjectField-8A"> { projectName }</p>
                    </div>
                    <div>
                        <label> Brand: </label>
                        <p className = "viewObjectField-8A"> { brand }</p>
                    </div>
                    <div>
                        <label> Main Concept: </label>
                        <p className = "viewObjectField-8A"> { mainConcept }</p>
                    </div>
                </div>
            </div>

        </div>
        )

    } else {

        return (
        <div>
            <Header title="Store - View" section="Store Main Details" showButtons={false} iconFileName="BE-Hover"/>
            <p>Data is loading...</p>
        </div>)

    }
        
};

export default ViewStore;
