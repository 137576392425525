import { useState, useEffect } from 'react'
import Button from './Button'
import axios from 'axios'
import Note from './Note'
import DOMPurify from 'dompurify';

import { Watch } from  'react-loader-spinner'

const ModalInfo = (props) => {

    const [allNotes, setAllNotes] = useState([])
    const [loading, setLoading] = useState(false)
      
    const API_URL = process.env.REACT_APP_API_URL;

    var arrNoteData = []
    
    const getHubSpotNotes = async () => {

        var varCleanNote = ""
        var varDateTime = ""

        setLoading(true)

        for (let index = 0; index < props.data.length; index++) {

            const dealNumber = props.data[index].dealNumber;
            const dealName = props.data[index].dealName;

            await axios.post(API_URL + '/hubspotNotesGetID/' + dealNumber)
            .then(async (res) => {

                var arrDealIds = []
                res.data.results.forEach(deal => {
                    arrDealIds.push({ id: deal.id })
                });
        
                await axios.post(API_URL + '/hubspotNotesUseID', {
                    params: { dealIds: arrDealIds },
                    paramsSerializer: {
                    indexes: true,
                    }
                })
                .then((res2) => {

                    for (let index2 = 0; index2 < res2.data.results.length; index2++) {
                        const note = res2.data.results[index2];
                        
                        varCleanNote = ""    
                        varDateTime = ""

                        varCleanNote = DOMPurify.sanitize(note.properties.hs_note_body);

                        varDateTime = note.properties.hs_lastmodifieddate.substring(0, 19)
                        varDateTime = varDateTime.replace("T", " ")
                        
                        arrNoteData.push({noteId : note.id, dealNumber : dealNumber, dealName : dealName, noteDateTime : varDateTime, note : varCleanNote})                        

                        //This should be the last record so set the state
                        if (index+1 === props.data.length && index2+1 === res2.data.results.length) {

                            var arrNoteData2 = arrNoteData.sort((a, b) => {
                              if (a.dealNumber !== b.dealNumber) {
                                return a.dealNumber - b.dealNumber;
                              } else {
                                return new Date(b.noteDateTime) - new Date(a.noteDateTime);
                              }
                            });

                            setAllNotes(arrNoteData2)
                            setLoading(false)
                        }

                    }
            
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });        
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);                
            });
            
        }

    }

    useEffect(() => {

        if (props.data.length > 0) {
            getHubSpotNotes()
        }
        
    }, [props.data])

    
    if (!props.show) {
        return null
    }
    return (
        <div className="update-value-modal z-50" >
            <div className="update-value-modal-timesheet-content">
                <div className="update-value-modal-header mt-4">
                    <p className="update-value-modal-title text-congress-blue text-xl">{props.title}</p>
                </div>
                <div className="update-value-modal-body">
                    {!loading &&
                        <div className="min-w-full mt-4">
                            <div className='overflow-auto h-[400px]'>                                
                                <table className="objects-8A min-w-full divide-y divide-gray-200 table-fixed">
                                    <thead className="bg-pacific-blue-50">
                                        <tr>
                                            <th scope="col" className="pl-4 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '70px' }}>Deal #</th>
                                            <th scope="col" className="pl-4 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '150px' }}>Deal Name</th>
                                            <th scope="col" className="pl-4 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '100px' }}>Date/Time</th>
                                            <th scope="col" className="pl-4 py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" style={{ width: '240px' }}>Note</th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {allNotes.map((item) => (
                                            <tr key={item.noteId}>
                                                <td className="pl-4 py-2 whitespace-normal">{item.dealNumber}</td>
                                                <td className="pl-4 py-2 whitespace-normal">{item.dealName}</td>
                                                <td className="pl-4 py-2 whitespace-normal">{item.noteDateTime}</td>
                                                <td className="pl-4 py-2 whitespace-normal"><Note htmlContent={item.note} /></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                    {loading &&
                        <div className="min-w-full mt-4">
                            <div className='overflow-auto h-[400px]'>                                
                                <table className="min-w-full table-fixed">
                                    <tbody className="bg-white">
                                        <tr>
                                            <td className="pl-4 py-2 whitespace-normal flex justify-center items-center">
                                                <Watch 
                                                    height="100"
                                                    width="100"
                                                    radius="48"
                                                    color="#00518c"
                                                    ariaLabel="watch-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="loading-modal-header">
                                                    <p className="loading-modal-title text-congress-blue text-xl font-bold">Data Loading</p>
                                                </div>   
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                    <Button text="Close" onClick={props.onClose}></Button>
                </div>
            </div>    
        </div>
    )
}

export default ModalInfo