import { useState, useEffect } from 'react'
import axios from 'axios'
import Header from '../UI/Header'
import { useNavigate, useMatch } from 'react-router-dom'

const AddOrUpdateContact = () => {

    const match = useMatch('/addOrUpdateContact/:clientId/:contactId')

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)

    const [clients, setClients] = useState([])
    const [clientsLoading, setClientsLoading] = useState(true)

    const [contactName, setContactName] = useState('')
    const [contactTelephoneNumber, setContactTelephoneNumber] = useState('')
    const [contactEmail, setContactEmail] = useState('')
    const [clientId, setClientId] = useState('')

    useEffect(() => {

        axios.get(process.env.REACT_APP_API_URL + '/clients')
            .then((res) => {
                setClients(res.data.sort((item1, item2) => item1.clientName < item2.clientName ? -1 : 0))
                if (match.params.contactId === "_add") {
                    // setClientId(res.data.sort((item1, item2) => item1.clientName < item2.clientName ? -1 : 0)[0].id)
                    setClientId(match.params.clientId)
                }
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setClientsLoading(false);
            });

        if (match.params.contactId !== "_add") {

            axios.get(process.env.REACT_APP_API_URL + '/contacts/' + match.params.contactId)
                .then((res) => res.data)
                .then((res) => {
                    setContactName(res.contactName)
                    setContactTelephoneNumber(res.contactTelephoneNumber)
                    setContactEmail(res.contactEmail)
                    setClientId(res.clientId)
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false)
        }
    }, [match.params.contactId])

    const addForm = async (form) => {
        await axios.post(process.env.REACT_APP_API_URL + '/contacts', form)
    }

    const updateForm = async (form) => {
        await axios.put(process.env.REACT_APP_API_URL + '/contacts/' + match.params.contactId, form)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (match.params.contactId !== "_add") {
            updateForm({ contactName, contactTelephoneNumber, contactEmail, clientId }).then(res => { navigate(`/addOrUpdateClient/${match.params.clientId}`) })
        } else {
            addForm({ contactName, contactTelephoneNumber, contactEmail, clientId }).then(res => { navigate(`/addOrUpdateClient/${match.params.clientId}`) })
        }
    }

    if (!loading && !clientsLoading) {

        return (

            <div className='flex items-center bg-jungle-mist-100 min-h-screen'>
                <div className="container-form-8A">

                    <Header title="Contacts - Add / Edit Contact" section="Contacts" />

                    <br></br>

                    <form className="add-form-8A" onSubmit={onSubmit}>

                        <div className="form-control-8A">
                            <label>Contact Name</label>
                            <input type="text" placeholder="Enter Contact Name Here" value={contactName} onChange={(e) => setContactName(e.target.value)} />
                        </div>
                        <div className="form-control-8A">
                            <label>Contact Telephone Number</label>
                            <input type="text" placeholder="Enter Contact Telephone Number Here" value={contactTelephoneNumber} onChange={(e) => setContactTelephoneNumber(e.target.value)} />
                        </div>
                        <div className="form-control-8A">
                            <label>Contact Email</label>
                            <input type="text" placeholder="Enter Contact Email Here" value={contactEmail} onChange={(e) => setContactEmail(e.target.value)} />
                        </div>
                        <div className="form-control-8A">
                            <label>Client</label>
                            <select disabled={true} value={clientId} onChange={(e) => setClientId(e.target.value)}>
                                {clients.map((client) => (
                                    <option key={client.id} value={client.id}>{client.clientName}</option>
                                ))}
                            </select>
                        </div>

                        <input type="submit" value="Save" className="btn-8A btn-block-8A" />

                    </form>
                </div>
            </div>
        )

    } else {

        return (
            <div>
                <Header title="Contacts - Add / Edit Contact" section="Contacts" />
                <p>Data is loading...</p>
            </div>)

    }

}

export default AddOrUpdateContact
