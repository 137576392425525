import { useState, useEffect } from 'react'
import axios from 'axios'
import Header from '../UI/Header'
import { useNavigate, useMatch } from 'react-router-dom'

const AddOrUpdateEventFootprint = () => {

    const match = useMatch('/addOrUpdateEventFootprint/:eventId/:footprintId')

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [usersLoading, setUsersLoading] = useState(false)
    const [users, setUsers] = useState([])
    const [contactsLoading, setContactsLoading] = useState(false)
    const [contacts, setContacts] = useState([])
    const [eventId, setEventId] = useState('')
    const [eventFootprintRef, setEventFootprintRef] = useState('')
    const [eventFootprintYear, setEventFootprintYear] = useState('')
    const [eventFootprintDate, setEventFootprintDate] = useState('')
    const [eventFootprintItems, setEventFootprintItems] = useState([])
    const [eventFootprintRecordConsolidation, setEventFootprintRecordConsolidation] = useState('')
    const [eventFootprintType, setEventFootprintType] = useState('')
    const [eventFootprintNotes, setEventFootprintNotes] = useState('')
    const [eventFootprintCertification, setEventFootprintCertification] = useState('')
    const [eventFootprintConsultant, setEventFootprintConsultant] = useState('')
    const [eventFootprintClientContact, setEventFootprintClientContact] = useState('')
    const [eventFootprintVerifier, setEventFootprintVerifier] = useState('')
    const [eventFootprintTargetYear, setEventFootprintTargetYear] = useState('')
    const [eventFootprintOverallReductionTargets, setEventFootprintOverallReductionTargets] = useState({ "High": 90, "Medium": 90, "Low": 90, "-": 0 })
    const [eventFootprintNumberOfAttendees, setEventFootprintNumberOfAttendees] = useState(0)
    // const [eventTurnoverGBP, setEventTurnoverGBP] = useState(0)
    const [eventFootprintObjectives, setEventFootprintObjectives] = useState('')
    const [eventFootprintLocation, setEventFootprintLocation] = useState('')
    const [eventFootprintScopeBoundaries, setEventFootprintScopeBoundaries] = useState('')
    const [eventFootprintLevelOfAssurance, setEventFootprintLevelOfAssurance] = useState('')
    const [eventFootprintMateriality, setEventFootprintMateriality] = useState({"oneHundredPercentGHGEmissions": "", "categoryExclusionAllowed": ""})
    const [eventFootprintVerificationActivitiesAndTechniques, setEventFootprintVerificationActivitiesAndTechniques] = useState({"observation": "", "inquiry": "", "analyticalTesting": "", "confirmation": "", "recalculation": "", "examination": "", "retracing": "", "tracing": "", "controlTesting": "", "sampling": "", "estimateTesting": "", "crossChecking": "", "reconciliation": ""})

    const [clientId, setClientId] = useState('')

    const [company, setCompany] = useState("")
    const [role, setRole] = useState(0)
    const [userPropertiesLoading, setUserPropertiesLoading] = useState(true)

    const getUserProperties = async () => {

        await axios.get(process.env.REACT_APP_API_URL + "/current_user_properties")
            .then((response) => {
                setCompany(response.data.company)
                setRole(response.data.role)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUserPropertiesLoading(false);
            });

    }

    const removeCommas = (num) => num.toString().replace(/,/g, "");

    const handleChangeForEventNumberOfAttendees = (e) => {
        const value = e.target.value;
        const clean = value.replace(/,/g, "");
        const regex = /^[0-9]*\.?[0-9]*$/;

        if (value && clean.match(regex)) {
            if (!value.includes(".")) {
                const formatted = new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 10,
                }).format(parseFloat(clean));
                setEventFootprintNumberOfAttendees(formatted);
            } else {
                setEventFootprintNumberOfAttendees(value);
            }
        } else {
            setEventFootprintNumberOfAttendees(value.replace(/[^0-9,.]/g, ''))
        }
    };

    const setFormattedEventNumberOfAttendees = (e) => {
        const value = e.toString();
        const clean = value.replace(/,/g, "");
        const regex = /^[0-9]*\.?[0-9]*$/;

        if (value && clean.match(regex)) {

            const formatted = new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 10,
            }).format(parseFloat(clean));
            setEventFootprintNumberOfAttendees(formatted);

        } else {
            setEventFootprintNumberOfAttendees("");
        }

    };

    useEffect(() => {

        setUserPropertiesLoading(true)

        getUserProperties()

    }, [])

    useEffect(() => {

        if (company && role && !userPropertiesLoading) {

            var clientIdLocal = ""

            // if (match.params.footprintId !== "_add") {

                axios.get(process.env.REACT_APP_API_URL + '/events/' + match.params.eventId)
                    .then((res) => {
                        setClientId(res.data.clientId)
                        clientIdLocal = res.data.clientId
                        if ((role === 3001 || role === 6001) && res.data.clientId !== company) {
                            navigate(`/errorPage`)
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    })
            // }

            setEventFootprintItems([])
            setEventId(match.params.eventId)

            if (match.params.footprintId !== "_add") {

                setLoading(true)

                axios.get(process.env.REACT_APP_API_URL + '/events_footprints/' + match.params.footprintId)
                    .then((res) => {
                        if (res.data.eventId !== match.params.eventId) {
                            navigate(`/errorPage`)
                        }
                        setEventId(res.data.eventId)
                        setEventFootprintRef(res.data.eventFootprintRef)
                        setEventFootprintYear(res.data.eventFootprintYear)
                        setEventFootprintDate(res.data.eventFootprintDate)
                        setEventFootprintItems(res.data.eventFootprintItems)
                        setEventFootprintRecordConsolidation(res.data.eventFootprintRecordConsolidation)
                        setEventFootprintType(res.data.eventFootprintType)
                        setEventFootprintNotes(res.data.eventFootprintNotes)
                        setEventFootprintCertification(res.data.eventFootprintCertification)
                        setEventFootprintConsultant(res.data.eventFootprintConsultant)
                        setEventFootprintClientContact(res.data.eventFootprintClientContact)
                        setEventFootprintVerifier(res.data.eventFootprintVerifier)
                        setEventFootprintTargetYear(res.data.eventFootprintTargetYear)
                        setEventFootprintOverallReductionTargets(res.data.eventFootprintOverallReductionTargets)
                        setEventFootprintNumberOfAttendees(res.data.eventFootprintNumberOfAttendees)
                        //setEventTurnoverGBP(res.data.eventTurnoverGBP)
                        //setFormattedEventTurnoverGBP(res.data.eventTurnoverGBP)
                        setFormattedEventNumberOfAttendees(res.data.eventFootprintNumberOfAttendees)
                        setEventFootprintObjectives(res.data.eventFootprintObjectives)
                        setEventFootprintScopeBoundaries(res.data.eventFootprintScopeBoundaries)
                        setEventFootprintLevelOfAssurance(res.data.eventFootprintLevelOfAssurance)
                        setEventFootprintMateriality(res.data.eventFootprintMateriality)
                        setEventFootprintVerificationActivitiesAndTechniques(res.data.eventFootprintVerificationActivitiesAndTechniques)
                    })
                    .catch((err) => {
                        console.log(err);
                    }).finally(() => {
                        setLoading(false);
                    });

            }

            setUsersLoading(true)

            axios.get(process.env.REACT_APP_API_URL + '/users_internal_only')
            .then((res) => {
                setUsers(res.data.filter(item => item.role !== 3001).sort((item1, item2) => item1.firstName + item1.lastName < item2.firstName + item2.lastName ? -1 : 0))
                if (match.params.footprintId === "_add") {
                    setEventFootprintConsultant(res.data.filter(item => item.role !== 3001).sort((item1, item2) => item1.firstName + item1.lastName < item2.firstName + item2.lastName ? -1 : 0)[0].id)
                    setEventFootprintVerifier(res.data.filter(item => item.role !== 3001).sort((item1, item2) => item1.firstName + item1.lastName < item2.firstName + item2.lastName ? -1 : 0)[0].id)
                }
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUsersLoading(false);
            });

            setContactsLoading(true)

            if (role === 3001 || role === 6001) {

                axios.get(process.env.REACT_APP_API_URL + '/contacts_by_clientId/' + company)
                .then((res) => {
                    setContacts(res.data)
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setContactsLoading(false);
                });

            } else {

                axios.get(process.env.REACT_APP_API_URL + '/contacts')
                    .then((res) => {
                        setContacts(res.data)
                    })
                    .catch((err) => {
                        console.log(err);
                    }).finally(() => {
                        setContactsLoading(false);
                    });

                }

        }

    }, [match.params.footprintId, match.params.eventId, company, role, userPropertiesLoading])

    const addForm = async (form) => {
        await axios.post(process.env.REACT_APP_API_URL + '/events_footprints', form)
    }

    const updateForm = async (form) => {
        await axios.put(process.env.REACT_APP_API_URL + '/events_footprints/' + match.params.footprintId, form)
    }

    const onSubmit = (e) => {
        e.preventDefault()

        if (!isNaN(Number(removeCommas(eventFootprintNumberOfAttendees)))) {

            if (match.params.footprintId !== "_add") {
                updateForm({ eventId, eventFootprintRef, eventFootprintYear, eventFootprintDate, eventFootprintItems, eventFootprintRecordConsolidation, eventFootprintType, eventFootprintNotes, eventFootprintCertification, eventFootprintConsultant, eventFootprintClientContact, eventFootprintVerifier, eventFootprintTargetYear, eventFootprintOverallReductionTargets, eventFootprintNumberOfAttendees: Number(removeCommas(eventFootprintNumberOfAttendees)), eventFootprintObjectives, eventFootprintScopeBoundaries, eventFootprintLevelOfAssurance, eventFootprintMateriality, eventFootprintVerificationActivitiesAndTechniques, eventFootprintLocation }).then(res => { navigate(-1) })
            } else {
                addForm({ eventId, eventFootprintRef, eventFootprintYear, eventFootprintDate, eventFootprintItems, eventFootprintRecordConsolidation, eventFootprintType, eventFootprintNotes, eventFootprintCertification, eventFootprintConsultant, eventFootprintClientContact, eventFootprintVerifier, eventFootprintTargetYear, eventFootprintOverallReductionTargets, eventFootprintNumberOfAttendees: Number(removeCommas(eventFootprintNumberOfAttendees)), eventFootprintObjectives, eventFootprintScopeBoundaries, eventFootprintLevelOfAssurance, eventFootprintMateriality, eventFootprintVerificationActivitiesAndTechniques, eventFootprintLocation }).then(res => { navigate(-1) })
            }


        } else {

            window.alert("Please check that numeric values are valid")

        }
        
    }

    if (!loading && !userPropertiesLoading && role && company && !usersLoading && users.length > 0 && !contactsLoading) {

        return (
            <div className='flex items-center bg-jungle-mist-100 min-h-screen'>

                <div className="container-form-8A">

                    <Header title="Events - Add / Edit Footprint" section="Event Main Details" url={role === 3001 || role === 6001 ? "/ViewClient/" + company : "/"}/>

                    <form className="add-form-8A" onSubmit={onSubmit}>

                        <p className="font-bold text-xl">General Info:</p>

                        <div className="form-control-8A">
                            <label>Event Footprint Ref</label>
                            <input className={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} readOnly={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? true : false} type="text" placeholder="Enter Event Footprint Ref Here" value={eventFootprintRef} onChange={(e) => setEventFootprintRef(e.target.value)} />
                        </div>
                        <div className="form-control-8A">
                            <label>Event Footprint Year</label>
                            <input className={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} readOnly={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? true : false} type="text" placeholder="Enter Event Footprint Year Here" value={eventFootprintYear} onChange={(e) => setEventFootprintYear(e.target.value)} />
                        </div>
                        <div className="form-control-8A">
                            <label>Event Footprint Verification Date</label>
                            <input className={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} readOnly={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? true : false} type="date" placeholder="Enter Event Footprint Date Here" value={eventFootprintDate} onChange={(e) => setEventFootprintDate(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Number of Attendees</label>
                            <input className={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 && role !== 3001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} readOnly={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 && role !== 3001 ? true : false} type="text" placeholder="Enter Number of Attendees Here" value={eventFootprintNumberOfAttendees} onChange={handleChangeForEventNumberOfAttendees} />
                        </div>
                        <div className="form-control-8A">
                            <label>Record Consolidation Approach</label>
                            <select className={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? true : false} value={eventFootprintRecordConsolidation} onChange={(e) => setEventFootprintRecordConsolidation(e.target.value)}>
                                <option value="">Please Select...</option>
                                <option value="Financial Control">Financial Control</option>
                                <option value="Operational Control">Operational Control</option>
                                <option value="Equity Share">Equity Share</option>
                            </select>
                        </div>
                        <div className="form-control-8A">
                            <label>Event Footprint Type</label>
                            <select className={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? true : false} value={eventFootprintType} onChange={(e) => setEventFootprintType(e.target.value)}>
                                <option value="">Please Select...</option>
                                <option value="Actual (Baseline)">Actual (Baseline)</option>
                                <option value="Actual">Actual</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Event Footprint Notes</label>
                            <input className={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} readOnly={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? true : false} type="text" placeholder="Enter Event Footprint Notes Here" value={eventFootprintNotes} onChange={(e) => setEventFootprintNotes(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Event Footprint Certification</label>
                            <select className={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? true : false} value={eventFootprintCertification} onChange={(e) => setEventFootprintCertification(e.target.value)}>
                                <option value="">Please Select...</option>
                                <option value="Carbon Measured">Carbon Measured</option>
                                <option value="Lower Carbon Committed">Lower Carbon Committed</option>
                                <option value="Lower Carbon">Lower Carbon</option>
                                <option value="Net Zero Carbon Committed">Net Zero Carbon Committed</option>
                                <option value="Net Zero Carbon">Net Zero Carbon</option>
                                <option value="Climate Positive">Climate Positive</option>
                            </select>
                        </div>

                        {/* <div className="form-control-8A">
                            <label>Event Footprint Consultant</label>
                            <input className={role === 3001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} readOnly={role !== 3001 && role !== 4001 ? false : true} type="text" placeholder="Enter Event Footprint Consultant Here" value={eventFootprintConsultant} onChange={(e) => setEventFootprintConsultant(e.target.value)} />
                        </div> */}

                        <div className="form-control-8A">
                            <label>Event Footprint Consultant</label>
                            <select className={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? true : false} value={eventFootprintConsultant} onChange={(e) => setEventFootprintConsultant(e.target.value)}>
                                {users.filter(user => user.role !== 9999 && user.role !== 9998 && user.role !== 3001).map((user) => (
                                    <option key={user.id} value={user.id}>{user.firstName + ' ' + user.lastName}</option>
                                ))}
                            </select>
                        </div>

                        {/* <div className="form-control-8A">
                            <label>Event Client Contact</label>
                            <input className={role === 3001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} readOnly={role !== 3001 && role !== 4001 ? false : true} type="text" placeholder="Enter Event Footprint Client Contact Here" value={eventFootprintClientContact} onChange={(e) => setEventFootprintClientContact(e.target.value)} />
                        </div> */}

                        <div className="form-control-8A">
                            <label>Event Client Contact</label>
                            <select className={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 && role !== 3001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 && role !== 3001 ? true : false} onChange={(e) => setEventFootprintClientContact(e.target.value)} onClick={(e) => setEventFootprintClientContact(e.target.value)}>
                                {contacts.filter(item => item.clientId === clientId).sort((item1, item2) => item1.contactName < item2.contactName ? -1 : 0).map((contact) => (
                                    <option key={contact.id} value={contact.id}>{contact.contactName}</option>
                                ))}
                            </select>
                        </div>

                        <p className="font-bold text-xl">Reduction:</p>

                        <div className="form-control-8A">
                            <label>Event Footprint Final Target Year</label>
                            <input className={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} readOnly={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? true : false} type="text" placeholder="Enter Event Footprint Final Target Year Here" value={eventFootprintTargetYear} onChange={(e) => setEventFootprintTargetYear(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>High Priority Reduction Target</label>
                            <input className={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} readOnly={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? true : false} type="text" placeholder="Enter High Priority Reduction Target Here" value={eventFootprintOverallReductionTargets.High} onChange={(e) => setEventFootprintOverallReductionTargets({ ...eventFootprintOverallReductionTargets, "High": e.target.value })} />
                        </div>

                        <div className="form-control-8A">
                            <label>Medium Priority Reduction Target</label>
                            <input className={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} readOnly={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? true : false} type="text" placeholder="Enter Medium Priority Reduction Target Here" value={eventFootprintOverallReductionTargets.Medium} onChange={(e) => setEventFootprintOverallReductionTargets({ ...eventFootprintOverallReductionTargets, "Medium": e.target.value })} />
                        </div>

                        <div className="form-control-8A">
                            <label>Low Priority Reduction Target</label>
                            <input className={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} readOnly={role !== 2001 && role !== 4001 && role !== 6001 && role !== 2004 ? true : false} type="text" placeholder="Enter Low Priority Reduction Target Here" value={eventFootprintOverallReductionTargets.Low} onChange={(e) => setEventFootprintOverallReductionTargets({ ...eventFootprintOverallReductionTargets, "Low": e.target.value })} />
                        </div>

                        {/* <div className="form-control-8A">
                            <label>Event Verifier</label>
                            <input className={role === 3001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} readOnly={role !== 3001 && role !== 4001 ? false : true} type="text" placeholder="Enter Event Footprint Client Contact Here" value={eventFootprintVerifier} onChange={(e) => setEventFootprintVerifier(e.target.value)} />
                        </div> */}

                        <p className="font-bold text-xl">Verification:</p>

                        <div className="form-control-8A">
                            <label>Event Verifier</label>
                            <select className={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? true : false} value={eventFootprintVerifier} onChange={(e) => setEventFootprintVerifier(e.target.value)}>
                                {users.filter(user => user.role !== 9999 && user.role !== 9998 && user.role !== 3001).map((user) => (
                                    <option key={user.id} value={user.id}>{user.firstName + ' ' + user.lastName}</option>
                                ))}
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Event Footprint Objectives</label>
                            <input className={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} readOnly={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? true : false} type="text" placeholder="Enter Objectives Here" value={eventFootprintObjectives} onChange={(e) => setEventFootprintObjectives(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Event Footprint Scope - Boundaries</label>
                            <input className={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} readOnly={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? true : false} type="text" placeholder="Enter Objectives Here" value={eventFootprintScopeBoundaries} onChange={(e) => setEventFootprintScopeBoundaries(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Event Footprint Level of Assurance</label>
                            <select className={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? true : false} value={eventFootprintLevelOfAssurance} onChange={(e) => setEventFootprintLevelOfAssurance(e.target.value)}>
                                <option value="">Please Select...</option>
                                <option value="Limited level of assurance">Limited level of assurance</option>
                                <option value="Reasonable level of assurance">Reasonable level of assurance</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Event Footprint Materiality - 100% GHG emissions from Scope 1 and 2</label>
                            <select className={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? true : false} value={eventFootprintMateriality.oneHundredPercentGHGEmissions} onChange={(e) => setEventFootprintMateriality({ ...eventFootprintMateriality, "oneHundredPercentGHGEmissions": e.target.value })}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Event Footprint Materiality - Scope 3 categories can be excluded if their individual contribution is less than 1% of the total GHG emissions</label>
                            <select className={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? true : false} value={eventFootprintMateriality.categoryExclusionAllowed} onChange={(e) => setEventFootprintMateriality({ ...eventFootprintMateriality, "categoryExclusionAllowed": e.target.value })}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Event Footprint Verification Activities and Techniques - Observation</label>
                            <select className={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? true : false} value={eventFootprintVerificationActivitiesAndTechniques.observation} onChange={(e) => setEventFootprintVerificationActivitiesAndTechniques({ ...eventFootprintVerificationActivitiesAndTechniques, "observation": e.target.value })}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Event Footprint Verification Activities and Techniques - Inquiry</label>
                            <select className={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? true : false} value={eventFootprintVerificationActivitiesAndTechniques.inquiry} onChange={(e) => setEventFootprintVerificationActivitiesAndTechniques({ ...eventFootprintVerificationActivitiesAndTechniques, "inquiry": e.target.value })}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Event Footprint Verification Activities and Techniques - Analytical testing</label>
                            <select className={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? true : false} value={eventFootprintVerificationActivitiesAndTechniques.analyticalTesting} onChange={(e) => setEventFootprintVerificationActivitiesAndTechniques({ ...eventFootprintVerificationActivitiesAndTechniques, "analyticalTesting": e.target.value })}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Event Footprint Verification Activities and Techniques - Confirmation</label>
                            <select className={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? true : false} value={eventFootprintVerificationActivitiesAndTechniques.confirmation} onChange={(e) => setEventFootprintVerificationActivitiesAndTechniques({ ...eventFootprintVerificationActivitiesAndTechniques, "confirmation": e.target.value })}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Event Footprint Verification Activities and Techniques - Recalculation</label>
                            <select className={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? true : false} value={eventFootprintVerificationActivitiesAndTechniques.recalculation} onChange={(e) => setEventFootprintVerificationActivitiesAndTechniques({ ...eventFootprintVerificationActivitiesAndTechniques, "recalculation": e.target.value })}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Event Footprint Verification Activities and Techniques - Examination</label>
                            <select className={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? true : false} value={eventFootprintVerificationActivitiesAndTechniques.examination} onChange={(e) => setEventFootprintVerificationActivitiesAndTechniques({ ...eventFootprintVerificationActivitiesAndTechniques, "examination": e.target.value })}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Event Footprint Verification Activities and Techniques - Retracing</label>
                            <select className={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? true : false} value={eventFootprintVerificationActivitiesAndTechniques.retracing} onChange={(e) => setEventFootprintVerificationActivitiesAndTechniques({ ...eventFootprintVerificationActivitiesAndTechniques, "retracing": e.target.value })}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Event Footprint Verification Activities and Techniques - Tracing</label>
                            <select className={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? true : false} value={eventFootprintVerificationActivitiesAndTechniques.tracing} onChange={(e) => setEventFootprintVerificationActivitiesAndTechniques({ ...eventFootprintVerificationActivitiesAndTechniques, "tracing": e.target.value })}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Event Footprint Verification Activities and Techniques - Control testing</label>
                            <select className={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? true : false} value={eventFootprintVerificationActivitiesAndTechniques.controlTesting} onChange={(e) => setEventFootprintVerificationActivitiesAndTechniques({ ...eventFootprintVerificationActivitiesAndTechniques, "controlTesting": e.target.value })}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Event Footprint Verification Activities and Techniques - Sampling</label>
                            <select className={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? true : false} value={eventFootprintVerificationActivitiesAndTechniques.sampling} onChange={(e) => setEventFootprintVerificationActivitiesAndTechniques({ ...eventFootprintVerificationActivitiesAndTechniques, "sampling": e.target.value })}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Event Footprint Verification Activities and Techniques - Estimate testing</label>
                            <select className={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? true : false} value={eventFootprintVerificationActivitiesAndTechniques.estimateTesting} onChange={(e) => setEventFootprintVerificationActivitiesAndTechniques({ ...eventFootprintVerificationActivitiesAndTechniques, "estimateTesting": e.target.value })}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Event Footprint Verification Activities and Techniques - Cross-checking</label>
                            <select className={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? true : false} value={eventFootprintVerificationActivitiesAndTechniques.crossChecking} onChange={(e) => setEventFootprintVerificationActivitiesAndTechniques({ ...eventFootprintVerificationActivitiesAndTechniques, "crossChecking": e.target.value })}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Event Footprint Verification Activities and Techniques - Reconciliation</label>
                            <select className={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? ` bg-slate-100 cursor-not-allowed placeholder-gray-300` : ``} disabled={role !== 5001 && role !== 6002 && role !== 2003 && role !== 2001 ? true : false} value={eventFootprintVerificationActivitiesAndTechniques.reconciliation} onChange={(e) => setEventFootprintVerificationActivitiesAndTechniques({ ...eventFootprintVerificationActivitiesAndTechniques, "reconciliation": e.target.value })}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <input type="submit" value="Save" className="btn-8A btn-block-8A" />

                    </form>

                </div>
            </div>
        )

    } else {

        return (
            <div className='flex bg-jungle-mist-100 min-h-screen'>
                <div className="container-form-8A">

                    <Header title="Events - Add / Edit Footprint" section="Event Main Details" url={role === 3001 || role === 6001 ? "/ViewClient/" + company : "/"}/>

                    <p>Data is loading...</p>
                </div>
            </div>)

    }

}

export default AddOrUpdateEventFootprint