import Header from '../UI/Header'
import { useState, useEffect } from 'react'
import { useMatch } from 'react-router-dom'
import axios from 'axios';

const ViewCreditPublic = () => {

    const match = useMatch('/viewCreditPublic/:creditId')

    const [loading, setLoading] = useState(false)

    const [projectId, setProjectId] = useState('')
    const [creditSerialNumber, setCreditSerialNumber] = useState('')
    const [creditVintage, setCreditVintage] = useState('')
    const [creditStatus, setCreditStatus] = useState('')
    const [creditQuantity, setCreditQuantity] = useState('')
    // const [creditBatch, setCreditBatch] = useState('')
    // const [creditIssuanceDate, setCreditIssuanceDate] = useState('')
    // const [creditMonitoringPeriodFrom, setCreditMonitoringPeriodFrom] = useState('')
    // const [creditMonitoringPeriodTo, setCreditMonitoringPeriodTo] = useState('')
    // const [creditRetirementDate, setCreditRetirementDate] = useState('')
    // const [creditSplit, setCreditSplit] = useState('')
    // const [creditType, setCreditType] = useState('')
    // const [creditNumberFrom, setCreditNumberFrom] = useState(0)
    // const [creditNumberTo, setCreditNumberTo] = useState(0)

    useEffect(() => {

        setLoading(true)

        axios.get(process.env.REACT_APP_API_URL + '/offset_credits/' + match.params.creditId)
            .then((res) =>  {
                setProjectId(res.data.projectId)
                setCreditSerialNumber(res.data.creditSerialNumber)
                setCreditVintage(res.data.creditVintage)
                setCreditStatus(res.data.creditStatus)
                setCreditQuantity(res.data.creditQuantity)
                // setCreditBatch(res.data.creditBatch)
                // setCreditIssuanceDate(res.data.creditIssuanceDate)
                // setCreditMonitoringPeriodFrom(res.data.creditMonitoringPeriodFrom)
                // setCreditMonitoringPeriodTo(res.data.creditMonitoringPeriodTo)
                // setCreditRetirementDate(res.data.creditRetirementDate)
                // setCreditSplit(res.data.creditSplit)
                // setCreditType(res.data.creditType)
                // setCreditNumberFrom(res.data.creditNumberFrom)
                // setCreditNumberTo(res.data.creditNumberTo)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });

    }, [match.params.creditId])

    if (!loading) {

        return (
            <div>
                <Header title="Credit - View" section="Credit Details" showButtons={false} public="Public"/>

                <br></br>
                <div className="viewObjectDetailsBox-8A">
                    <h3> View Project Details</h3>
                    <br></br>
                    <div>
                        <div>
                            <label> Project ID: </label>
                            <p className="viewObjectField-8A"> {projectId}</p>
                        </div>
                        <div>
                            <label> Serial Number: </label>
                            <p className="viewObjectField-8A"> {creditSerialNumber}</p>
                        </div>
                        <div>
                            <label> Vintage: </label>
                            <p className="viewObjectField-8A"> {creditVintage}</p>
                        </div>
                        <div>
                            <label> Status: </label>
                            <p className="viewObjectField-8A"> {creditStatus}</p>
                        </div>
                        <div>
                            <label> Quantity: </label>
                            <p className="viewObjectField-8A"> {creditQuantity}</p>
                        </div>
                    </div>
                </div>

            </div>
        )

    } else {

        return (
            <div>
                <Header title="Project - View" section="Project Details" showButtons={false} public="Public"/>
                <p>Data is loading...</p>
            </div>)

    }
};

export default ViewCreditPublic;
