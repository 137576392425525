import { useEffect, useState } from 'react'
import axios from 'axios'
import Button from '../UI/Button'
import Header from '../UI/Header'
import { useNavigate, Navigate } from 'react-router-dom'
import Sidebar from '../UI/Sidebar'
import UserService from '../../services/UserService'
import authHeader from '../../services/AuthHeader'
import Options from '../UI/Options'
import ReactSelect from 'react-select'

const ListClients = () => {

    const [clients, setClients] = useState([])

    const [clientsFiltered, setClientsFiltered] = useState([])

    const [startingRecordForPage, setStartingRecordForPage] = useState(0)

    const [clients100PerPage, setClients100PerPage] = useState([])

    const [sidebarToggle, setSidebarToggle] = useState(false)

    const [loading, setLoading] = useState(false)

    const [redirecting, setRedirecting] = useState(false)

    const [selectedClient, setSelectedClient] = useState('All')

    const API_URL = process.env.REACT_APP_API_URL

    const clearFilters = () => {
        setSelectedClient('All')
    }

    const styles = {
        option: provided => ({
            ...provided,
            color: 'black'
        }),
        control: provided => ({
            ...provided,
            color: 'black',
            width: 160,
            fontSize: 13
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'black'
        }),
        menu: provided => ({
            ...provided,
            color: 'black',
            width: 160,
            fontSize: 13
        }),
    }


    useEffect(() => {

        setLoading(true)

        axios.get(API_URL + "/clients").then(
            (response) => {
                setClients(response.data.sort((item1, item2) => item1.clientName.toLowerCase() < item2.clientName.toLowerCase() ? -1 : 0));
                setClientsFiltered(response.data.sort((item1, item2) => item1.clientName.toLowerCase() < item2.clientName.toLowerCase() ? -1 : 0));

                var index
                var first100 = []

                for (index = 0; index < 100; index++) {
                    first100.push(response.data[index])
                }

                setClients100PerPage(first100)
                setStartingRecordForPage(0)
            },
            (error) => {
                // console.log(stores, loading, redirecting);
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                console.log(_content);
                setRedirecting(true)
            },
        ).finally(() => {
            setLoading(false)
        });

    }, [])

    useEffect(() => {

        setClientsFiltered(clients)

        var lastRecordIndex = 100
        var firstOneHundred = []
        var index

        if (selectedClient !== "All") {

            setClientsFiltered(clientsFiltered => clientsFiltered.filter((item) => item.id === selectedClient.value))

            if (clients.filter((item) => item.id === selectedClient.value).length < 100) {
                lastRecordIndex = clients.filter((item) => item.id === selectedClient.value).length
            }


            for (index = 0; index < lastRecordIndex; index++) {
                firstOneHundred.push(clients.filter((item) => item.id === selectedClient.value)[index])
            }

            setClients100PerPage(firstOneHundred)
            setStartingRecordForPage(0)

        } else {

            if (clients.length < 100) {
                lastRecordIndex = clients.length
            }

            for (index = 0; index < lastRecordIndex; index++) {
                firstOneHundred.push(clients[index])
            }

            setClients100PerPage(firstOneHundred)
            setStartingRecordForPage(0)

        }

    }, [selectedClient, clients])


    useEffect(() => {



    }, [clients100PerPage])


    const nextPage = (startingRecordIndex) => {

        if (startingRecordIndex + 100 < clientsFiltered.length ) {

            var NewStartingRecordIndex = startingRecordIndex + 100
            var lastRecordIndex = NewStartingRecordIndex + 100

                if (lastRecordIndex > clientsFiltered.length) {

                    lastRecordIndex = clientsFiltered.length -1 

                }

            var index
            var firstOneHundred = []

            for (index = NewStartingRecordIndex; index < lastRecordIndex; index++) {
                firstOneHundred.push(clientsFiltered[index])
            }

            setClients100PerPage(firstOneHundred)
            setStartingRecordForPage(NewStartingRecordIndex)

        }
        
    }

    const previousPage = (startingRecordIndex) => {

        if (startingRecordIndex > 0 ) {

            var NewStartingRecordIndex = startingRecordIndex - 100

            var lastRecordIndex = NewStartingRecordIndex + 100

            var index
            var firstOneHundred = []

            for (index = NewStartingRecordIndex; index < lastRecordIndex; index++) {
                firstOneHundred.push(clientsFiltered[index])
            }

            setClients100PerPage(firstOneHundred)
            setStartingRecordForPage(NewStartingRecordIndex)
        }
    }


    const navigate = useNavigate()

    const deleteClient = (id) => {
        window.confirm('Are you sure you want to delete it?')
        // axios.delete(process.env.REACT_APP_API_URL + '/clients/' + id)
        // setClients(clients.filter((client) => client.id !== id))
    }

    const editClient = (id) => {
        navigate(`/addOrUpdateClient/${id}`);
    }

    const viewClient = (id) => {
        navigate(`/viewClient/${id}`);
    }

    if (redirecting) {

        return (
            <Navigate replace to="/login" />
        )
    }

    else if (clients.length > 0 && clientsFiltered.length > 0 && clients100PerPage.length > 0 && !loading && !redirecting) {
        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">

                    {sidebarToggle &&
                        <Sidebar />
                    }

                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">

                        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                            <Header title="Clients" section="Select Client" showUserMenu='true' />

                            <hr></hr>

                            <div className="grid grid-cols-2 lg:grid-cols-6">
                                <div className="pl-3">
                                    <p className="text-xs font-bold">Filters</p>
                                    <Button color="bg-bondi-blue" text="Clear Filters" onClick={() => clearFilters()} />
                                </div>
                                <div className="pb-1 z-20">
                                    <p className="text-xs">Client</p>
                                    <ReactSelect
                                        styles={styles}
                                        value={selectedClient}
                                        onChange={setSelectedClient}
                                        options={clients.map((item) => {
                                            return {
                                                label: item.clientName,
                                                value: item.id
                                            }
                                        })} />
                                </div>
                                <div>
                                <p className="text-xs">Prev</p>
                                    <Button color="bg-bondi-blue" text=" << Prev 100 Records" onClick={() => previousPage(startingRecordForPage)} />
                                </div>

                                <div className="text-right">
                                <p className="text-xs">Next</p>
                                    <Button color="bg-bondi-blue" text="Next 100 Records >>" onClick={() => nextPage(startingRecordForPage)} />
                                </div>
                            </div>

                            {/* <div className="grid sm:grid-cols-2 lg:grid-cols-2 grid-rows-1">

                                <div>
                                    <Button color="bg-bondi-blue" text=" << Prev 100 Records" onClick={() => previousPage(startingRecordForPage)} />
                                </div>

                                <div className="text-right">
                                    <Button color="bg-bondi-blue" text="Next 100 Records >>" onClick={() => nextPage(startingRecordForPage)} />
                                </div>

                            </div> */}
                            
                            {/* <br></br>
                            <Button color="bg-bondi-blue" text="Add New Client" onClick={() => navigate('/addOrUpdateClient/_add')} />
                            <br></br> */}
                            
                            <div className="flex flex-col py-2">
                            <div className="overflow-x-auto items-holder-8A">
                                <div className="text-eight-text align-middle inline-block min-w-full">
                                    <div className="shadow-md shadow-slate-300 rounded-2xl">
                                            <table className="objects-8A min-w-full divide-y divide-gray-200">

                                                <thead className="bg-pacific-blue-50 z-10 sticky top-0">
                                                    <tr>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Client Name</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Client Address 1</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Client Address 2</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Client Town</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Client County</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Client Post Code</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Client Country</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Req. PO For Invoicing</th>
                                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider md:pr-10 lg:pr-20 float-right"></th>
                                                    </tr>
                                                </thead>

                                                <tbody className="bg-white divide-y divide-gray-200">
                                                    {clients100PerPage.map((client) => (
                                                        <tr key={client.id}>
                                                            <td className="px-4 md:px-6  py-2 "> {client.clientName}</td>
                                                            <td className="px-4 md:px-6  py-2 "> {client.clientAddress}</td>
                                                            <td className="px-4 md:px-6  py-2 "> {client.clientAddress2}</td>
                                                            <td className="px-4 md:px-6  py-2 "> {client.clientTown}</td>
                                                            <td className="px-4 md:px-6  py-2 "> {client.clientCounty} </td>
                                                            <td className="px-4 md:px-6  py-2 "> {client.clientPostCode}</td>
                                                            <td className="px-4 md:px-6  py-2 "> {client.clientCountry}</td>
                                                            <td className="px-4 md:px-6  py-2 "> {client.requirePoForInvoicing}</td>
                                                            <td className="px-4 md:px-6  py-2 whitespace-nowrap md:pr-10 lg:pr-20 float-right">
                                                                {/* <Options edit={`/addOrUpdateClient/${client.id}`} deleteFunction={deleteClient} deleteObject={client.id}></Options> */}
                                                                {/* <Options edit={`/addOrUpdateClient/${client.id}`}></Options> */}
                                                                <Button color="bg-bondi-blue" text="View Portfolio" onClick={() => viewClient(client.id)} />
                                                                <Button color="bg-bondi-blue" text="View / Edit Client" onClick={() => navigate(`/addOrUpdateClient/${client.id}`)} />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>

                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br></br>
                            <Button color="bg-bondi-blue" text="Add New Client" onClick={() => navigate('/addOrUpdateClient/_add')} />
                        </div>
                    </div>
                </div>

                {/* <div className="bg-slate-100 flex h-screen overflow-hidden">

                    {sidebarToggle &&
                        <Sidebar />
                    }

                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">

                        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                            <Header title="Clients" section="Select Cient" showUserMenu='true' />

                            <div className="flex flex-wrap">
                                {clients.map((client) => (

                                    <div className=' md:w-1/4 lg:w-1/6 max-w-[17rem]'>
                                        <div className=" group flex relative bg-slate-200 aspect-square shadow-xl m-2  overflow-hidden content-center rounded-md" key={client.id}>
                                            <div className='bg-slate-200 w-full pb-2'>
                                                <img className="object-cover w-full aspect-square text-center rounded-md " src={("/images/client_images/" + client.clientName + ".jpg")} onError={(e) => { e.target.src = "/images/organisation_generic.png" }} alt="loading..." />
                                            </div>

                                            <div className="absolute hover:cursor-pointer bg-black w-full h-full bg-opacity-0 transition-bg-opacity duration-500  hover:bg-opacity-60 z-1">

                                                <div className='group-hover:cursor-pointer' onClick={() => deleteClient(client.id)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="opacity-0 inline-block stroke-red-600 h-6 w-6 m-1 group-hover:cursor-pointer group-hover:opacity-100" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.5">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                    </svg>
                                                    <p className="opacity-0 inline-block text-red-600 text-md group-hover:cursor-pointer group-hover:opacity-100 hover:underline">Delete</p>
                                                </div>

                                                <div className='group-hover:cursor-pointer' onClick={() => editClient(client.id)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="opacity-0 inline-block stroke-slate-50 h-6 w-6 m-1 group-hover:opacity-100" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.5">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                                    </svg>
                                                    <p className="opacity-0 inline-block text-slate-50 text-md group-hover:opacity-100 hover:underline">Edit</p>
                                                </div>

                                                <div className='group-hover:cursor-pointer' onClick={() => viewClient(client.id)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="opacity-0 inline-block stroke-slate-50 h-6 w-6 m-1 group-hover:opacity-100" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.5">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                    </svg>
                                                    <p className="opacity-0 inline-block text-slate-50 text-md group-hover:opacity-100 hover:underline">View</p>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='pb-2 m-2'>
                                            <p onClick={() => viewClient(client.id)} className="text-md pt-1 hover:cursor-pointer hover:underline">{client.clientName}</p>
                                            <p className="text-xs">{client.clientAddress}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <Button color="bondi-blue" text="Add New Client" onClick={() => navigate('/addOrUpdateClient/_add', { replace: true })} />
                        </div>
                    </div>
                </div> */}
            </div>
        )

    } else {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Clients" section="Select Cient" showUserMenu='true' />
                        <p>Data is loading</p>
                    </div>

                </div>

            </div>
        )

    }

}

export default ListClients
