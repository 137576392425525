import { v4 as uuidv4 } from 'uuid'

const Table = (props) => {
    return (
      <table className="objects-8A min-w-full divide-y divide-gray-200">
          <thead className="bg-pacific-blue-50">
             <tr>
              {props.theadData.map(heading => (
                <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider" key={heading}>{heading}</th>
              ))}
            </tr>
          </thead>
          <tbody>
              {props.tbodyData.map(row => (
                <tr key={uuidv4()}>
                      {props.theadData.map(dataPoint => (
                        <td className="px-4 md:px-6  py-2 whitespace-nowrap hover:cursor-pointer" key={uuidv4()}>{row[dataPoint]}</td>
                      ))}
                </tr>
              ))}
          </tbody>
      </table>
   );
   }

export default Table