import { useState, useEffect } from 'react'
import axios from 'axios'
import Header from '../UI/Header'
import { useNavigate, useMatch, Navigate } from 'react-router-dom'
import authHeader from '../../services/AuthHeader'
import AuthService from '../../services/AuthService'
import ReactSelect from 'react-select'

const AddOrUpdateTimesheetEntry = () => {

    //Date formatting to set today's date as yyyy-mm-dd
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    const formattedToday = yyyy + '-' + mm + '-' + dd;

    const match = useMatch('/addOrUpdateTimesheetEntry/:entryId')

    const navigate = useNavigate()

    const [projects, setProjects] = useState([])
    const [users, setUsers] = useState([])
    const [projectServices, setProjectServices] = useState([])

    const [loading, setLoading] = useState(true)
    const [usersLoading, setUsersLoading] = useState(true)
    const [projectServicesLoading, setProjectServicesLoading] = useState(true)

    const [userId, setUserId] = useState('')
    const [projectId, setProjectId] = useState('')
    const [selectedProjectId, setSelectedProjectId] = useState({})
    const [serviceId, setServiceId] = useState('')
    const [activityId, setActivityId] = useState('')
    const [timesheetDate, setTimesheetDate] = useState(formattedToday)
    const [timesheetHours, setTimesheetHours] = useState('')
    //const [serviceComments, setServiceComments] = useState('')

    const [redirecting, setRedirecting] = useState(false)

    const API_URL = process.env.REACT_APP_API_URL;

    //set styles for ReactSelect
    const styles = {
        option: provided => ({
            ...provided,
            color: 'black'
        }),
        control: provided => ({
            ...provided,
            color: 'black',
            fontSize: 17,
            minWidth: "100%",
             borderRadius: "0%"
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'black'
        }),
        menu: provided => ({
            ...provided,
            color: 'black',
            width: '700px',
        }),
    }

    //run on component first render
    //get users, projects and services for selection boxes
    useEffect(() => {

        axios.get(API_URL + '/users')
            .then((res) => {
                setUsers(res.data.filter(item => item.role !== 3001 && item.role !== 9999 && item.role !== 9998).sort((item1, item2) => item1.firstName + item1.lastName < item2.firstName + item2.lastName ? -1 : 0))
                setUserId(res.data.find(item => item.emailId === AuthService.getCurrentUserName()).id)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUsersLoading(false);
            });

        axios.get(API_URL + '/projects')
            .then((res) => {
                setProjects(res.data.sort((item1, item2) => item1.projectName < item2.projectName ? -1 : 0))
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });

        axios.get(API_URL + '/projects_services', { headers: authHeader() })
            .then((response) => {
                //setProjectServices(response.data.sort((item1, item2) => item1.service < item2.service ? -1 : 0).filter((item) => item.toBeDeleted === "NO"))
                setProjectServices(response.data.sort((item1, item2) => item1.service.toString().toLowerCase() < item2.service.toString().toLowerCase() ? -1 : 0).filter((item) => item.toBeDeleted === "NO"))
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setProjectServicesLoading(false);
            });

    }, [match.params.entryId])

    useEffect(() => {
        if (match.params.entryId !== "_add") {

            setLoading(true)

            axios.get(process.env.REACT_APP_API_URL + '/timesheets/' + match.params.entryId)
                .then((res) => {
                    
                    axios.get(API_URL + '/projects/' + res.data.projectId)
                    .then((res2) => {
                        setSelectedProjectId({value:res2.data.projectId, label: res2.data.projectName + " | #" + res2.data.projectNumber,})
                    })
                    .catch((err) => {
                        console.log(err);
                    }).finally(() => {
                        //setProjectLoading(false);
                    });

                    setProjectId(res.data.projectId)
                    setServiceId(res.data.serviceId)
                    setActivityId(res.data.activityId)
                    setTimesheetDate(res.data.timesheetDate)
                    setTimesheetHours(res.data.timesheetHours)
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });
        }
    }, [match.params.entryId])

    //Run this when a project is selected using the ReactSelect component
    //Make sure service and activity are set to null so that selecting a different project forces the same project-service-activity selection logic
    useEffect(() => {
        if (selectedProjectId.value) {
            setProjectId(selectedProjectId.value)
            setServiceId("")
            setActivityId("")
        }
        //console.log(selectedProjectId.value)
    }, [selectedProjectId])

    //add function
    const addForm = async (form) => {
        await axios.post(process.env.REACT_APP_API_URL + '/timesheets', form)
    }

    //update function
    const updateForm = async (form) => {
        await axios.put(process.env.REACT_APP_API_URL + '/timesheets/' + match.params.entryId, form)
    }

    //on submit
    const onSubmit = (e) => {
        e.preventDefault()
        if (activityId && timesheetHours && timesheetDate) {
            if (match.params.entryId !== "_add") {
                console.log("Update code hit")
                updateForm({ userId, projectId, serviceId, activityId, timesheetDate, timesheetHours }).then(res => { navigate(-1) })
            } else {
                console.log("Add code hit")
                addForm({ userId, projectId, serviceId, activityId, timesheetDate, timesheetHours }).then(res => { navigate(-1) })
            }
        }
    }

    if (redirecting) {

        return (
            <Navigate replace to="/login" />
        )
    }
    else if (!loading && !projectServicesLoading && !usersLoading) {

        return (

            <div className="container-form-8A">

                <Header title="Time Sheet Entry - Add / Edit Time Sheet Entry" section="Time Sheet Entry" />

                <form className="add-form-8A" onSubmit={onSubmit}>

                    <div className="form-control-8A pr-1">
                        <label>User</label>
                        <select disabled = {true} value={userId} onChange={(e) => setUserId(e.target.value)}>
                            {users.map((user) => (
                                <option key={user.id} value={user.id}>{user.firstName + " " + user.lastName}</option>
                            ))}
                        </select>
                    </div>

                    <label>Project Name | Number</label>
                    <div className = "pt-1 pl-1">
                    <ReactSelect
                        styles={styles}
                        value={selectedProjectId}
                        onChange={setSelectedProjectId}
                        onClick={setSelectedProjectId}
                        options={projects.sort((item1, item2) => item1.projectName < item2.projectName ? -1 : item1.projectName > item2.projectName ? 1 : 0).map((item) => {
                            return {
                                label: item.projectName + " | #" + item.projectNumber,
                                value: item.id
                            }
                        })} />
                    </div>

                    {/* Only show once project has been selected */}
                    {projectId && (

                        <div className="form-control-8A pr-1">
                            <label>Service</label>
                            <select value={serviceId} onChange={(e) => { setServiceId(e.target.value); setActivityId(projectServices.find(service => service.id === e.target.value).activities[0].activityId) }} onFocus={(e) => { setServiceId(e.target.value); setActivityId(projectServices.find(service => service.id === e.target.value).activities[0].activityId) }}>
                                {projectServices.filter(service => service.projectId === projectId).map((service) => (
                                    <option key={service.id} value={service.id}>{service.service + ' (Work Stage: ' + service.workStage + ') (Comments: ' + service.serviceComments + ')'}</option>
                                ))}
                            </select>
                        </div>

                    )

                    }

                    {/* Only show once service has been selected
                    {serviceId && (

                    <div className="form-control-8A pr-1">
                        <label>Service Comments</label>
                        <input readOnly={true} type="text" value={serviceComments} />
                    </div>
                    )
                    } */}

                    {/* Only show once service has been selected */}
                    {serviceId && (

                        <div className="form-control-8A pr-1">
                            <label>Activity</label>
                            <select value={activityId} onChange={(e) => setActivityId(e.target.value)} onFocus={(e) => setActivityId(e.target.value)}>
                                {projectServices.find(service => service.id === serviceId).activities.sort((item1, item2) => item1.activity < item2.activity ? -1 : 0).map((activity) => (
                                    <option key={activity.activityId} value={activity.activityId}>{activity.activity}</option>
                                ))}
                            </select>
                        </div>
                    )

                    }

                    <div className="form-control-8A pr-1">
                        <label>Date</label>
                        <input type="date" placeholder="Enter Date Here" value={timesheetDate} onChange={(e) => setTimesheetDate(e.target.value)} />
                    </div>

                    <div className="form-control-8A pr-1">
                        <label>Hours</label>
                        <input type="number" placeholder="Enter Hours Here" value={timesheetHours} onChange={(e) => setTimesheetHours(Number(e.target.value))} />
                    </div>

                    <br></br>

                    <input type="submit" value="Save" className="btn-8A btn-block-8A" />

                </form>

            </div>
        )

    } else {

        return (
            <div>
                <Header title="Time Sheet Entry - Add / Edit Time Sheet Entry" section="Time Sheet Entry" />
                <p>Data is loading...</p>
            </div>)

    }

}

export default AddOrUpdateTimesheetEntry