import React, { useState, useEffect, useContext } from 'react';
import { Navigate, useNavigate } from 'react-router-dom'
import AuthService from "../services/AuthService";
import axios from 'axios'



const Login = () => {

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [emailError, setEmailError] = useState("")
    const [userError, setUserError] = useState("")
    const [redirecting, setRedirecting] = useState(false)
    // Value keeps the response from the API, it is used to determine users' role
    // const { value, setValue } = useContext(UserContext)
    const value = JSON.parse(localStorage.getItem("user"))

    const [company, setCompany] = useState("")
    const [role, setRole] = useState(0)
    const [userPropertiesLoading, setUserPropertiesLoading] = useState(true)
    const navigate = useNavigate()

    const getUserProperties = async () => {

        await axios.get(process.env.REACT_APP_API_URL + "/current_user_properties")
            .then((response) => {
                setCompany(response.data.company)
                setRole(response.data.role)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUserPropertiesLoading(false);
            });

    }

    const onSubmit = (e) => {

        e.preventDefault()

        // if (emailError == "✔️") {
            setUserError('Please wait...')
            AuthService.setToken(email, password)
                .then((resData) => {
                    if (sessionStorage.getItem("user") && resData.status === 200) {
                        setRedirecting(true)
                        localStorage.setItem("tab", "Services")
                    }

                }).catch(error => {
                    setUserError(error.data)
                });
        // }
    }
    function refreshPage() {
        window.location.reload();
    }

    useEffect(() => {

        const re = /\S+@\S+\.\S+/

        if (email.match(re)) {
            setEmailError("✔️")
        }

        else {
            setEmailError("✖️")
        }
    }, [email])


    if (redirecting) {

        // console.log('going to /mfa ')
        
        return (
            <Navigate replace to="/mfa_validate" />
        )

    } 

     else {

        return (
            <form className="min-h-screen bg-jungle-mist-100 flex flex-wrap items-center justify-center py-2 px-2 sm:px-6 lg:px-8 w-full " onSubmit={onSubmit}>
                <div className="max-w-3xl flex shadow-xl shadow-slate-300 rounded-2xl overflow-hidden">

                    <div className="md:w-1/2 relative bg-white items-center justify-center ">

                        <div className="md:absolute md:inset-x-0 md:bottom-0">
                            <div className="my-8 mx-12">
                                <img className="m-10 h-32" src='../images/eight-versa-colour.png' />
                            </div>
                            <h2 className="font-medium text-center text-xl text-pacific-blue-700 mb-4">Vero Platform</h2>
                            <div className="w-4/5 mx-auto">
                                <div className="flex items-center bg-white rounded-full shadow-md shadow-slate-300 mb-4 border border-slate-200 border-solid">
                                    <span className="px-3">
                                        <svg className="text-pacific-blue-700 w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" >
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                        </svg>
                                    </span>
                                    <input
                                        id="email"
                                        className='w-full h-12 border-none focus:outline-none'
                                        name="email"
                                        type="text"
                                        placeholder="Email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <p className='pr-3'>{emailError}</p>
                                </div>
                                <section className="flex items-center bg-white rounded-full shadow-md mb-4 shadow-slate-300 border border-slate-200 border-solid">
                                    <span className="px-3">
                                        <svg className="text-pacific-blue-700 w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" >
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                                        </svg>
                                    </span>
                                    <input
                                        id="password"
                                        className='w-full h-12 border-none focus:outline-none'
                                        name="password"
                                        type="password"
                                        placeholder="Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <span className="px-3">
                                    </span>
                                </section >

                                <button disabled={emailError !== "✔️"} className="bg-bondi-blue block mx-auto text-white text-sm uppercase rounded-full shadow-slate-300 shadow-md px-8 py-3 my-4 outline-none">Log in</button>
                                <p className="underline text-congress-blue cursor-pointer" onClick={() => navigate("/forgot_password_step_1")}>Forgot password?</p>
                            </div>
                            <div className="grid grid-cols-3">
                                <div className="text-xs p-2">v.1.1.2</div>
                                <div className="text-xs p-2 text-center">{userError}</div>
                                <div></div>
                            </div>
                        </div>

                    </div>
                    <div className='hidden md:inline-block md:w-1/2'><img src='../images/staircase.jpg' /></div>
                </div>
            </form>
        );
    }
}

export default Login;