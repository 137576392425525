const allBREEAMCredits = [{
        
        "sortOrder": 1,
        "section": "MANAGEMENT",
        "creditCategoryCode": "Man 01",
        "creditCategoryDescription": "Project brief and design",
        "creditDescription": "Project delivery planning",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Define roles, responsibilities and contributions of each team member before end of RIBA Stage 2.\nEvidence how stakeholder input influences initial project brief, project execution plan, communication strategy and concept design.",
        "creditNotes": "Use preparation and brief document saved in below folder: Z:\\Eight jobs\\000 Eight Versa Templates\\BREEAM NC 2018\\10. Document Templates for BREEAM NC 2018_Ol",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 2,
        "section": "MANAGEMENT",
        "creditCategoryCode": "Man 01",
        "creditCategoryDescription": "Project brief and design",
        "creditDescription": "Stakeholder consultation",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "-",
        "creditsPotential": "1",
        "creditsOverview": "All third party stakeholders consulted with (e.g. local residents) / feedback given to consultees",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 3,
        "section": "MANAGEMENT",
        "creditCategoryCode": "Man 01",
        "creditCategoryDescription": "Project brief and design",
        "creditDescription": "BREEAM AP (concept design)",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "AP appointed at RIBA Stage 1/2 and BREEAM targets set",
        "creditNotes": "Remember to reference stage dates and conflict of interest letter if we did AP",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 4,
        "section": "MANAGEMENT",
        "creditCategoryCode": "Man 01",
        "creditCategoryDescription": "Project brief and design",
        "creditDescription": "BREEAM AP (developed design)",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "AP monitor progress throughout design & report",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 5,
        "section": "MANAGEMENT",
        "creditCategoryCode": "Man 02",
        "creditCategoryDescription": "Life cycle cost and service life planning",
        "creditDescription": "Elemental LCC",
        "creditsMinimumStandards": "-",
        "credits": "2",
        "creditsTargeted": "2",
        "creditsPotential": "-",
        "creditsOverview": "LCC RIBA Stage 2 and LCC analysis shows: \na) outline LCC plan - structure and envelope\nb) fabric and servicing strategy - services and fit out",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 6,
        "section": "MANAGEMENT",
        "creditCategoryCode": "Man 02",
        "creditCategoryDescription": "Life cycle cost and service life planning",
        "creditDescription": "Component level LCC options appraisal",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Component LCC plan developed by RIBA Stage 4 including: \nEnvelope, Services Finishes and External Spaces",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 7,
        "section": "MANAGEMENT",
        "creditCategoryCode": "Man 02",
        "creditCategoryDescription": "Life cycle cost and service life planning",
        "creditDescription": "Capital cost reporting",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Report the capital cost for the building in £k/m2 ",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 8,
        "section": "MANAGEMENT",
        "creditCategoryCode": "Man 03",
        "creditCategoryDescription": "Responsible construction practices",
        "creditDescription": "Prerequisite - Legally harvested & traded timber",
        "creditsMinimumStandards": "-",
        "credits": "Yes",
        "creditsTargeted": "Yes",
        "creditsPotential": "-",
        "creditsOverview": "Pre-requisite: All timber to be legally sourced",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 9,
        "section": "MANAGEMENT",
        "creditCategoryCode": "Man 03",
        "creditCategoryDescription": "Responsible construction practices",
        "creditDescription": "Environmental management",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Contractors (who manage site) have an ISO 14001 EMS certificate and implement best practice pollution prevention in line with PPG 6",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 10,
        "section": "MANAGEMENT",
        "creditCategoryCode": "Man 03",
        "creditCategoryDescription": "Responsible construction practices",
        "creditDescription": "BREEAM AP (site)",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "BREEAM AP during construction - monitoring on site ",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 11,
        "section": "MANAGEMENT",
        "creditCategoryCode": "Man 03",
        "creditCategoryDescription": "Responsible construction practices",
        "creditDescription": "Responsible construction management",
        "creditsMinimumStandards": "1",
        "credits": "2",
        "creditsTargeted": "2",
        "creditsPotential": "-",
        "creditsOverview": "Responsible construction management (e.g. CCS)",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 12,
        "section": "MANAGEMENT",
        "creditCategoryCode": "Man 03",
        "creditCategoryDescription": "Responsible construction practices",
        "creditDescription": "Monitoring of construction site impacts - Energy & water",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Monitor energy & water data monthly and set targets for expected consumption",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 13,
        "section": "MANAGEMENT",
        "creditCategoryCode": "Man 03",
        "creditCategoryDescription": "Responsible construction practices",
        "creditDescription": "Transport of construction materials & waste",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Monitor transport data monthly and set targets for expected consumption",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 14,
        "section": "MANAGEMENT",
        "creditCategoryCode": "Man 04",
        "creditCategoryDescription": "Commissioning and handover",
        "creditDescription": "Commissioning - Testing schedule and responsibilities",
        "creditsMinimumStandards": "1",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Commissioning in line with regulations / programme of commissioning",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 15,
        "section": "MANAGEMENT",
        "creditCategoryCode": "Man 04",
        "creditCategoryDescription": "Commissioning and handover",
        "creditDescription": "Commissioning - Design and preparation",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Specialist commissioning manager (who was not involved in the general installation works)",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 16,
        "section": "MANAGEMENT",
        "creditCategoryCode": "Man 04",
        "creditCategoryDescription": "Commissioning and handover",
        "creditDescription": "Testing and inspecting building fabric",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "-",
        "creditsPotential": "1",
        "creditsOverview": "Thermographic survey and air tightness testing",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 17,
        "section": "MANAGEMENT",
        "creditCategoryCode": "Man 04",
        "creditCategoryDescription": "Commissioning and handover",
        "creditDescription": "Handover",
        "creditsMinimumStandards": "BUG",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Non-technical and technical building user guides & training schedule covering BREEAM specific points",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 18,
        "section": "MANAGEMENT",
        "creditCategoryCode": "Man 05",
        "creditCategoryDescription": "Aftercare",
        "creditDescription": "Aftercare Support",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Provide initial aftercare support to the building occupiers 12 months after handover",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 19,
        "section": "MANAGEMENT",
        "creditCategoryCode": "Man 05",
        "creditCategoryDescription": "Aftercare",
        "creditDescription": "Commissioning - Implementation",
        "creditsMinimumStandards": "1",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Seasonal commissioning of complex and simple Systems within first year of occupation at full load and part load",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 20,
        "section": "MANAGEMENT",
        "creditCategoryCode": "Man 05",
        "creditCategoryDescription": "Aftercare",
        "creditDescription": "Post Occupancy Evaluation (POE)",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "POE one year after initial building occupation by an independent 3rd party",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 21,
        "section": "HEALTH AND WELLBEING",
        "creditCategoryCode": "Hea 01",
        "creditCategoryDescription": "Visual comfort",
        "creditDescription": "Control of glare from sunlight",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Identify areas at risk of glare using a glare control assessment. Glare control strategy to design out potential glare in all relevant building areas where risk has been identified.",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 22,
        "section": "HEALTH AND WELLBEING",
        "creditCategoryCode": "Hea 01",
        "creditCategoryDescription": "Visual comfort",
        "creditDescription": "Daylighting",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "-",
        "creditsPotential": "1",
        "creditsOverview": "Daylighting factors of 2% are met as per BREEAM guidance AND uniformity ratio is at least 0.3; at least 80% of the room has a view of the sky from desk height",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 23,
        "section": "HEALTH AND WELLBEING",
        "creditCategoryCode": "Hea 01",
        "creditCategoryDescription": "Visual comfort",
        "creditDescription": "View out",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "View Out - all workstations are 8m from window AND window area is at least 20% of surrounding wall area, OR window to room depths comply with Table 1.0 BS 8206: Part 2 (25% where >8m, 30% where >11m and 35% where >14m)",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 24,
        "section": "HEALTH AND WELLBEING",
        "creditCategoryCode": "Hea 01",
        "creditCategoryDescription": "Visual comfort",
        "creditDescription": "Internal and external lighting levels, zoning and control",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Lighting guidance met - SLL Code for Lighting 2012 and CIBSE Lighting Guide 7 for Offices",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 25,
        "section": "HEALTH AND WELLBEING",
        "creditCategoryCode": "Hea 02",
        "creditCategoryDescription": "Indoor air quality",
        "creditDescription": "Prerequisite - Indoor air quality (IAQ) plan",
        "creditsMinimumStandards": "-",
        "credits": "Yes",
        "creditsTargeted": "Yes",
        "creditsPotential": "-",
        "creditsOverview": "HEA 02 Pre-requisite: An IAQ Plan is carried out in accordance with BREEAM requirements to achieve any of the below credits:",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 26,
        "section": "HEALTH AND WELLBEING",
        "creditCategoryCode": "Hea 02",
        "creditCategoryDescription": "Indoor air quality",
        "creditDescription": "Ventilation",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Designed to provide fresh air whilst minimising air pollutants: air intakes and exhausts over 10m apart & intakes 10m horizontal distance from sources of external pollution (e.g. car parks / roads)",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 27,
        "section": "HEALTH AND WELLBEING",
        "creditCategoryCode": "Hea 02",
        "creditCategoryDescription": "Indoor air quality",
        "creditDescription": "Emissions from construction products",
        "creditsMinimumStandards": "-",
        "credits": "2",
        "creditsTargeted": "1",
        "creditsPotential": "1",
        "creditsOverview": "Compliance with emission requirements for formaldehyde / TVOCs and carcinogens, as follows:\nOne credit: three products comply\nTwo credits: all products comply",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 28,
        "section": "HEALTH AND WELLBEING",
        "creditCategoryCode": "Hea 02",
        "creditCategoryDescription": "Indoor air quality",
        "creditDescription": "Post-construction indoor air quality measurement",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Test internal  air quality and remediate where necessary before re-testing.",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 29,
        "section": "HEALTH AND WELLBEING",
        "creditCategoryCode": "Hea 03",
        "creditCategoryDescription": "Safe containment in laboratories",
        "creditDescription": "Not applicable in BREEAM 2018",
        "creditsMinimumStandards": "-",
        "credits": "0",
        "creditsTargeted": "-",
        "creditsPotential": "-",
        "creditsOverview": "",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 30,
        "section": "HEALTH AND WELLBEING",
        "creditCategoryCode": "Hea 04",
        "creditCategoryDescription": "Thermal comfort",
        "creditDescription": "Thermal Modelling CIBSE AM11",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Thermal Modelling (in accordance with CIBSE AM11), to comply with CIBSE Guide A",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 31,
        "section": "HEALTH AND WELLBEING",
        "creditCategoryCode": "Hea 04",
        "creditCategoryDescription": "Thermal comfort",
        "creditDescription": "Design for future thermal comfort",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Thermal Modelling - As above for a projected climate change environment",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 32,
        "section": "HEALTH AND WELLBEING",
        "creditCategoryCode": "Hea 04",
        "creditCategoryDescription": "Thermal comfort",
        "creditDescription": "Thermal zoning and controls",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Thermal Strategy produced as a result of the thermal model and installation of occupant control within 7m zones",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 33,
        "section": "HEALTH AND WELLBEING",
        "creditCategoryCode": "Hea 05",
        "creditCategoryDescription": "Acoustic performance",
        "creditDescription": "Acoustic performance",
        "creditsMinimumStandards": "-",
        "credits": "3",
        "creditsTargeted": "3",
        "creditsPotential": "-",
        "creditsOverview": "Building meets appropriate acoustic performance standards (and testing reqs) for a) sound insulation; b) indoor ambient noise level; c) room acoustics.",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 34,
        "section": "HEALTH AND WELLBEING",
        "creditCategoryCode": "Hea 06",
        "creditCategoryDescription": "Security",
        "creditDescription": "Security of site and building",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "-",
        "creditsPotential": "1",
        "creditsOverview": "Security consultant appointed at design stage (RIBA Stage 2) to carry out a Security Needs Assessment",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 35,
        "section": "HEALTH AND WELLBEING",
        "creditCategoryCode": "Hea 07",
        "creditCategoryDescription": "Safe and healthy surroundings",
        "creditDescription": "Safe access",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Delivery routes do not cross pedestrian or cyclist routes. Separate access routes for cyclists, pedestrians and vehicles",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 36,
        "section": "HEALTH AND WELLBEING",
        "creditCategoryCode": "Hea 07",
        "creditCategoryDescription": "Safe and healthy surroundings",
        "creditDescription": "Outside space",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "There is an outside space providing building users with an external amenity area.",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 37,
        "section": "ENERGY",
        "creditCategoryCode": "Ene 01",
        "creditCategoryDescription": "Reduction of energy use & carbon emissions",
        "creditDescription": "Energy Performance",
        "creditsMinimumStandards": "4",
        "credits": "9",
        "creditsTargeted": "4",
        "creditsPotential": "-",
        "creditsOverview": "Energy Performance Ratio for New Construction (SBEM modelling) ",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 38,
        "section": "ENERGY",
        "creditCategoryCode": "Ene 01",
        "creditCategoryDescription": "Reduction of energy use & carbon emissions",
        "creditDescription": "Prediction of operational energy consumption",
        "creditsMinimumStandards": "-",
        "credits": "4",
        "creditsTargeted": "4",
        "creditsPotential": "-",
        "creditsOverview": "Prediction of operational energy consumption:\n- Energy design workshop to be carried out at design stage with relevant members of design team \n- Energy modelling & reporting to predict operational energy consumption figures by end use, design assumptions and input data\n - Risk assessment to highlight any significant design, technical, and process risks",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 39,
        "section": "ENERGY",
        "creditCategoryCode": "Ene 02",
        "creditCategoryDescription": "Energy monitoring",
        "creditDescription": "Sub-metering of end-use categories",
        "creditsMinimumStandards": "1",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Sub-meters with pulsed output for major energy consuming systems and high energy load areas: Space heating, Domestic hot water heating, Humidification, Cooling, Ventilation, i.e. fans (major), Pumps,  Lighting, Small power, Renewable or low carbon systems (separately),  Controls, Other major energy consuming systems or plant",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 40,
        "section": "ENERGY",
        "creditCategoryCode": "Ene 02",
        "creditCategoryDescription": "Energy monitoring",
        "creditDescription": "Sub-metering of high energy load & tenancy areas",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Accessible BEMS or accessible sub-meters for tenancy areas / relevant function areas / departments",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 41,
        "section": "ENERGY",
        "creditCategoryCode": "Ene 03",
        "creditCategoryDescription": "External lighting",
        "creditDescription": "External Lighting",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Average luminous efficacy of at least 70 luminaire lumens per circuit Watt and controls for daylighting and PIR in areas of intermittent pedestrian traffic",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 42,
        "section": "ENERGY",
        "creditCategoryCode": "Ene 04",
        "creditCategoryDescription": "Low carbon design",
        "creditDescription": "Passive Design Analysis",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Hea 04 achieved and a Passive Design Analysis produced outlining opportunities for passive design solutions ",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 43,
        "section": "ENERGY",
        "creditCategoryCode": "Ene 04",
        "creditCategoryDescription": "Low carbon design",
        "creditDescription": "Free Cooling ",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "\nAbove achieved / free cooling strategy implemented (or building naturally ventilated) ",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 44,
        "section": "ENERGY",
        "creditCategoryCode": "Ene 04",
        "creditCategoryDescription": "Low carbon design",
        "creditDescription": "LZC feasibility study",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Feasibility Study + LZC technology specified",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 45,
        "section": "ENERGY",
        "creditCategoryCode": "Ene 05",
        "creditCategoryDescription": "Energy efficient cold storage",
        "creditDescription": "Refrigeration energy consumption",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 46,
        "section": "ENERGY",
        "creditCategoryCode": "Ene 05",
        "creditCategoryDescription": "Energy efficient cold storage",
        "creditDescription": "Indirect greenhouse gas emissions",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 47,
        "section": "ENERGY",
        "creditCategoryCode": "Ene 06",
        "creditCategoryDescription": "Energy efficient transport systems",
        "creditDescription": "Energy consumption",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Transport demand analysis undertaken to determine correct number and size of lifts\nLift comparison between at least 2 different lift types with the most energy efficient specified",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 48,
        "section": "ENERGY",
        "creditCategoryCode": "Ene 06",
        "creditCategoryDescription": "Energy efficient transport systems",
        "creditDescription": "Energy efficient features - Lifts",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Energy efficient features installed for system - standby mode for off peak periods, lift car lighting >70lm/W and variable speed, voltage and frequency.\nRegenerative drives where this is shown to be energy saving",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 49,
        "section": "ENERGY",
        "creditCategoryCode": "Ene 06",
        "creditCategoryDescription": "Energy efficient transport systems",
        "creditDescription": "Energy efficient features - Escalators or moving walks",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 50,
        "section": "ENERGY",
        "creditCategoryCode": "Ene 07",
        "creditCategoryDescription": "Energy efficient laboratory systems",
        "creditDescription": "Design specification",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 51,
        "section": "ENERGY",
        "creditCategoryCode": "Ene 07",
        "creditCategoryDescription": "Energy efficient laboratory systems",
        "creditDescription": "Best practice energy efficient measures",
        "creditsMinimumStandards": "-",
        "credits": "4",
        "creditsTargeted": "4",
        "creditsPotential": "-",
        "creditsOverview": "",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 52,
        "section": "ENERGY",
        "creditCategoryCode": "Ene 08",
        "creditCategoryDescription": "Energy efficient equipment",
        "creditDescription": "Energy efficient equipment ",
        "creditsMinimumStandards": "-",
        "credits": "2",
        "creditsTargeted": "2",
        "creditsPotential": "-",
        "creditsOverview": "Where installed, equipment using a significant proportion of the total annual unregulated energy consumption of the building to meet BREEAM requirements (e.g. white goods / IT equipment)",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 53,
        "section": "TRANSPORT",
        "creditCategoryCode": "Tra 01",
        "creditCategoryDescription": "Transport assessment & travel plan",
        "creditDescription": "Travel Plan",
        "creditsMinimumStandards": "-",
        "credits": "2",
        "creditsTargeted": "2",
        "creditsPotential": "-",
        "creditsOverview": "Develop a travel plan based on a site-specific travel assessment, including analysis of Accessibility Index",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 54,
        "section": "TRANSPORT",
        "creditCategoryCode": "Tra 02",
        "creditCategoryDescription": "Sustainable transport measures",
        "creditDescription": "Transport options implementation",
        "creditsMinimumStandards": "-",
        "credits": "10",
        "creditsTargeted": "8",
        "creditsPotential": "-",
        "creditsOverview": "Identify the sustainable transport measures (e.g. cycle storage, cyclist facilities, electric car charging points, car share schemes, travel information point, new bus service, improved cycle / pedestrian routes)",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 55,
        "section": "WATER",
        "creditCategoryCode": "Wat 01",
        "creditCategoryDescription": "Water consumption",
        "creditDescription": "Water consumption",
        "creditsMinimumStandards": "1",
        "credits": "5",
        "creditsTargeted": "2",
        "creditsPotential": "-",
        "creditsOverview": "Percentage improvement on baseline - litres / person / day\n12.5% (1) / 25% (2) / 40% (3) / 50% (4) / 55% (5) / 65% (exemplar) ",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 56,
        "section": "WATER",
        "creditCategoryCode": "Wat 02",
        "creditCategoryDescription": "Water monitoring",
        "creditDescription": "Water monitoring ",
        "creditsMinimumStandards": "1",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Water meter on mains supply - pulsed output\nSub-metering for areas expected to use more than 10% consumption",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 57,
        "section": "WATER",
        "creditCategoryCode": "Wat 03",
        "creditCategoryDescription": "Water leak detection",
        "creditDescription": "Leak detection system",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Water leak detection between boundary and building and within building - alarm must be audible and programmable",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 58,
        "section": "WATER",
        "creditCategoryCode": "Wat 03",
        "creditCategoryDescription": "Water leak detection",
        "creditDescription": "Flow control devices",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Cold water supply shut off valves linked to presence detectors in each WC area / facility ",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 59,
        "section": "WATER",
        "creditCategoryCode": "Wat 04",
        "creditCategoryDescription": "Water efficient equipment",
        "creditDescription": "Water efficient equipment ",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Mitigate significant unregulated water demands OR Where there is no significant water demand",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 60,
        "section": "MATERIALS",
        "creditCategoryCode": "Mat 01",
        "creditCategoryDescription": "Building life cycle assessment (LCA)",
        "creditDescription": "Superstructure",
        "creditsMinimumStandards": "-",
        "credits": "6",
        "creditsTargeted": "4",
        "creditsPotential": "1",
        "creditsOverview": "Life cycle assessment:\n - Comparison with the BREEAM benchmark (office, retail & industrial only) and options appraisal\n - Integrate the LCA options appraisal activity within the wider design decision-making process\nEarly stage req: For maximum credits: LCA must be submitted to BRE prior to planning application",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 61,
        "section": "MATERIALS",
        "creditCategoryCode": "Mat 01",
        "creditCategoryDescription": "Building life cycle assessment (LCA)",
        "creditDescription": "Substructure and hard landscaping options appraisal during Concept Design",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Life cycle analysis:\n - Comparison with the BREEAM benchmark and options appraisal\n - Integrate the LCA options appraisal activity within the wider design decision-making process",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 62,
        "section": "MATERIALS",
        "creditCategoryCode": "Mat 02",
        "creditCategoryDescription": "Environmental Product Declarations (EPDs)",
        "creditDescription": "Specification of products with a recognised EPD",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "-",
        "creditsPotential": "1",
        "creditsOverview": "Specification of products with a recognised EPD within the life cycle analysis. Must be at least 14 EPDs to ",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 63,
        "section": "MATERIALS",
        "creditCategoryCode": "Mat 03",
        "creditCategoryDescription": "Responsible sourcing construction products",
        "creditDescription": "Prerequisite - Legally harvested & traded timber",
        "creditsMinimumStandards": "Yes",
        "credits": "Yes",
        "creditsTargeted": "Yes",
        "creditsPotential": "-",
        "creditsOverview": "Pre-requisite : all timber to be legally sourced",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 64,
        "section": "MATERIALS",
        "creditCategoryCode": "Mat 03",
        "creditCategoryDescription": "Responsible sourcing construction products",
        "creditDescription": "Enabling sustainable procurement",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Sustainable Procurement Plan in place - all materials for the project",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 65,
        "section": "MATERIALS",
        "creditCategoryCode": "Mat 03",
        "creditCategoryDescription": "Responsible sourcing construction products",
        "creditDescription": "Measuring responsible sourcing",
        "creditsMinimumStandards": "-",
        "credits": "3",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Responsible sourcing of materials (ISO / FSC etc) ",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 66,
        "section": "MATERIALS",
        "creditCategoryCode": "Mat 04",
        "creditCategoryDescription": "Insulation",
        "creditDescription": "Not applicable in BREEAM 2018",
        "creditsMinimumStandards": "-",
        "credits": "0",
        "creditsTargeted": "-",
        "creditsPotential": "-",
        "creditsOverview": "",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 67,
        "section": "MATERIALS",
        "creditCategoryCode": "Mat 05",
        "creditCategoryDescription": "Design for durability & resilience",
        "creditDescription": "Design for durability and resilience",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Protecting vulnerable parts of the building from damage / Protecting exposed parts of the building from material degradation",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 68,
        "section": "MATERIALS",
        "creditCategoryCode": "Mat 06",
        "creditCategoryDescription": "Material efficiency",
        "creditDescription": "Material efficiency",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Optimise the use of materials in building design, procurement, construction, maintenance and end of life",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 69,
        "section": "WASTE",
        "creditCategoryCode": "Wst 01",
        "creditCategoryDescription": "Construction waste management",
        "creditDescription": "Pre-demolition audit",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "-",
        "creditsPotential": "1",
        "creditsOverview": "Determine whether refurbishment or reuse is feasible / maximise recovery of materials",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 70,
        "section": "WASTE",
        "creditCategoryCode": "Wst 01",
        "creditCategoryDescription": "Construction waste management",
        "creditDescription": "Construction resource efficiency",
        "creditsMinimumStandards": "-",
        "credits": "3",
        "creditsTargeted": "2",
        "creditsPotential": "-",
        "creditsOverview": "Amount of Waste per 100m2 of GIFA - SWMP ",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 71,
        "section": "WASTE",
        "creditCategoryCode": "Wst 01",
        "creditCategoryDescription": "Construction waste management",
        "creditDescription": "Diversion of resources from landfill",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Meet with diversion from landfill benchmarks",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 72,
        "section": "WASTE",
        "creditCategoryCode": "Wst 01",
        "creditCategoryDescription": "Construction waste management",
        "creditDescription": "RMP (Resource Management Plan) measuring and reporting",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 73,
        "section": "WASTE",
        "creditCategoryCode": "Wst 02",
        "creditCategoryDescription": "Recycled & sustainably sourced aggregates",
        "creditDescription": "Pre-demolition audit",
        "creditsMinimumStandards": "-",
        "credits": "Yes",
        "creditsTargeted": "Yes",
        "creditsPotential": "-",
        "creditsOverview": "Identify all aggregate uses / types on the project + total amounts (weight) and distance travelled - points calculated using Wst 02 calculator",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 74,
        "section": "WASTE",
        "creditCategoryCode": "Wst 02",
        "creditCategoryDescription": "Recycled & sustainably sourced aggregates",
        "creditDescription": "Project sustainable aggregate points",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "-",
        "creditsPotential": "1",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 75,
        "section": "WASTE",
        "creditCategoryCode": "Wst 03",
        "creditCategoryDescription": "Operational waste",
        "creditDescription": "Operational waste ",
        "creditsMinimumStandards": "1",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Dedicated, recyclable waste storage area:\n1. At least 2m² per 1000m² of net floor area for buildings < 5000m²\n2. A minimum of 10m² for buildings ≥ 5000m²\n3. An additional 2m² per 1000m² of net floor area where catering is provided (with an additional minimum of 10m² for buildings ≥ 5000m²).",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 76,
        "section": "WASTE",
        "creditCategoryCode": "Wst 04",
        "creditCategoryDescription": "Speculative finishes (offices only)",
        "creditDescription": "Speculative floor and ceiling finishes",
        "creditsMinimumStandards": "-",
        "credits": "0",
        "creditsTargeted": "-",
        "creditsPotential": "-",
        "creditsOverview": "Offices only: no floor or ceiling finishes are installed OR show areas are installed OR tenant confirms finishes to be installed",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 77,
        "section": "WASTE",
        "creditCategoryCode": "Wst 05",
        "creditCategoryDescription": "Adaptation to climate change",
        "creditDescription": "Resilience of structure, fabric, building services and renewables installation",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Conduct a climate change adaptation strategy appraisal for structural and fabric resilience by the end of Concept Design - RIBA Stage 2",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 78,
        "section": "WASTE",
        "creditCategoryCode": "Wst 06",
        "creditCategoryDescription": "Design for disassembly & adaptability",
        "creditDescription": "Design for disassembly and functional adaptability - recommendations",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Recommend a building-specific functional adaptation strategy - RIBA Stage 2",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 79,
        "section": "WASTE",
        "creditCategoryCode": "Wst 06",
        "creditCategoryDescription": "Design for disassembly & adaptability",
        "creditDescription": "Disassembly and functional adaptability – implementation",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Disassembly and functional adaptability strategy to be implemented – RIBA Stage 4",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 80,
        "section": "ECOLOGY",
        "creditCategoryCode": "LE 01",
        "creditCategoryDescription": "Site selection",
        "creditDescription": "Previously Occupied Land",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "75% land pre-developed (building or hardstanding) in last 50 years",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 81,
        "section": "ECOLOGY",
        "creditCategoryCode": "LE 01",
        "creditCategoryDescription": "Site selection",
        "creditDescription": "Contaminated Land",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "-",
        "creditsPotential": "1",
        "creditsOverview": "Site investigation confirming contamination and remediation needed",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 82,
        "section": "ECOLOGY",
        "creditCategoryCode": "LE 02",
        "creditCategoryDescription": "Ecological risks & opportunities",
        "creditDescription": "Prerequisite",
        "creditsMinimumStandards": "-",
        "credits": "Yes",
        "creditsTargeted": "Yes",
        "creditsPotential": "-",
        "creditsOverview": "Assessment route selection\nDetermine Route 1 or Route 2 - GN 34 checklist",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 83,
        "section": "ECOLOGY",
        "creditCategoryCode": "LE 02",
        "creditCategoryDescription": "Ecological risks & opportunities",
        "creditDescription": "Survey and evaluation and Determining the ecological outcomes for the site (route 1)",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Route 1 (one credit) - checklist must be carried out to determine 'ecological value'\n\nRoute 2 (two credits) - desktop study and survey by Suitably Qualified Ecologist (SQE) confirming current and potential ecological value & condition to determine baseline, risks to ecological value and feasibility for enhancement, including determining the zone of influence.",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 84,
        "section": "ECOLOGY",
        "creditCategoryCode": "LE 02",
        "creditCategoryDescription": "Ecological risks & opportunities",
        "creditDescription": "Survey and evaluation and Determining the ecological outcomes for the site (route 2)",
        "creditsMinimumStandards": "-",
        "credits": "2",
        "creditsTargeted": "2",
        "creditsPotential": "-",
        "creditsOverview": "-",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 85,
        "section": "ECOLOGY",
        "creditCategoryCode": "LE 03",
        "creditCategoryDescription": "Managing impacts on ecology",
        "creditDescription": "Prerequisite - Achieved LE 02",
        "creditsMinimumStandards": "-",
        "credits": "Yes",
        "creditsTargeted": "Yes",
        "creditsPotential": "-",
        "creditsOverview": "Prerequisite – ID risks and opportunities for the site\nLE 02 must be achieved + EU & UK legislation will be implemented",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 86,
        "section": "ECOLOGY",
        "creditCategoryCode": "LE 03",
        "creditCategoryDescription": "Managing impacts on ecology",
        "creditDescription": "Planning, liaison, implementation and data (route 1)",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "0",
        "creditsPotential": "-",
        "creditsOverview": "Route 1 and Route 2 (one credit) - Planning to be carried out for activities during site clearance and construction, including:\n- Roles and responsibilities for managing negative impacts on the ecology have been identified.\n- Determine timescales for implementing on-site measures\n- Ensure contract requirements focus on reducing and managing potential knock-on impacts of works (e.g. pollution and disturbance)",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 87,
        "section": "ECOLOGY",
        "creditCategoryCode": "LE 03",
        "creditCategoryDescription": "Managing impacts on ecology",
        "creditDescription": "Planning, liaison, implementation and data (route 2)",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 88,
        "section": "ECOLOGY",
        "creditCategoryCode": "LE 03",
        "creditCategoryDescription": "Managing impacts on ecology",
        "creditDescription": "Managing negative impacts of the project (route 1)",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "0",
        "creditsPotential": "-",
        "creditsOverview": "Route 1 (one credit) - negative impacts are managed in accordance with mitigation hierarchy and no overall loss of ecological value\n\nRoute 2 (Up to two credits) – Managing negative impacts of the project and construction works have been managed in accordance with the mitigation hierarchy\nOne credit - minimising loss \nTwo credits - no loss of ecological value",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 89,
        "section": "ECOLOGY",
        "creditCategoryCode": "LE 03",
        "creditCategoryDescription": "Managing impacts on ecology",
        "creditDescription": "Managing negative impacts of the project (route 2)",
        "creditsMinimumStandards": "-",
        "credits": "2",
        "creditsTargeted": "2",
        "creditsPotential": "-",
        "creditsOverview": "",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 90,
        "section": "ECOLOGY",
        "creditCategoryCode": "LE 04",
        "creditCategoryDescription": "Ecological change & enhancement",
        "creditDescription": "Prerequisite - Achieved LE 03",
        "creditsMinimumStandards": "-",
        "credits": "Yes",
        "creditsTargeted": "Yes",
        "creditsPotential": "-",
        "creditsOverview": "ID risks and opportunities for the site\nLE 03 must be achieved + EU & UK legislation will be implemented",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 91,
        "section": "ECOLOGY",
        "creditCategoryCode": "LE 04",
        "creditCategoryDescription": "Ecological change & enhancement",
        "creditDescription": "Ecological enhancement (route 2 only)",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Route 2 only - Liaison, implementation and data collation: Ecological measures have been implemented that enhance the sites ecological value. \n\nMeasures are based on: \na) SQE recommendations \nb) input from the project team / relevant stakeholders and \nc) data collected for LE 02",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 92,
        "section": "ECOLOGY",
        "creditCategoryCode": "LE 04",
        "creditCategoryDescription": "Ecological change & enhancement",
        "creditDescription": "Change and enhancement of ecology (route 1)",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Route 1 only - Locally relevant ecological measures have been implemented that enhance the sites ecological value. \n\nMeasures are based on: \na) local expert recommendations \nb) input from the project team / relevant stakeholders and \nc) data collected for LE 02",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 93,
        "section": "ECOLOGY",
        "creditCategoryCode": "LE 04",
        "creditCategoryDescription": "Ecological change & enhancement",
        "creditDescription": "Change and enhancement of ecology (route 2)",
        "creditsMinimumStandards": "-",
        "credits": "3",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Route 1 - n/a\n\nRoute 2 Enhancement of ecology based on the change in ecological value, determined by a calculation carried out by the SQE",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 94,
        "section": "ECOLOGY",
        "creditCategoryCode": "LE 05",
        "creditCategoryDescription": "Long term ecology management & maintenance\n",
        "creditDescription": "Prerequisite (route 1)",
        "creditsMinimumStandards": "-",
        "credits": "Yes",
        "creditsTargeted": "-",
        "creditsPotential": "-",
        "creditsOverview": "Prerequisite: Route 1 - LE03 credit 'managing negative impacts of the project' is achieved",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 95,
        "section": "ECOLOGY",
        "creditCategoryCode": "LE 05",
        "creditCategoryDescription": "Long term ecology management & maintenance\n",
        "creditDescription": "Prerequisite (route 2)",
        "creditsMinimumStandards": "-",
        "credits": "Yes",
        "creditsTargeted": "Yes",
        "creditsPotential": "-",
        "creditsOverview": "Prerequisite: Route 2 - LE03 credit 'Managing negative impacts of the project' is achieved AND at least one LE 04 credit for 'Change and enhancement of ecology'",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 96,
        "section": "ECOLOGY",
        "creditCategoryCode": "LE 05",
        "creditCategoryDescription": "Long term ecology management & maintenance\n",
        "creditDescription": "Management and maintenance - Landscape and ecology management plan (or similar) development (route 1)",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Route 1 - Measures are implemented to manage and maintain ecology through the project to ensure optimal ecological outcomes agreed in LE 02 are met.\nInformation made available to future building user on ecological values.",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 97,
        "section": "ECOLOGY",
        "creditCategoryCode": "LE 05",
        "creditCategoryDescription": "Long term ecology management & maintenance\n",
        "creditDescription": "Management and maintenance (route 2)",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Route 2 - Measures are implemented to manage and maintain ecology through the project to ensure optimal ecological outcomes agreed in LE 02 are met.\nInformation made available to future building user on ecological values.",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 98,
        "section": "ECOLOGY",
        "creditCategoryCode": "LE 05",
        "creditCategoryDescription": "Long term ecology management & maintenance\n",
        "creditDescription": "Landscape and ecology management plan (or similar) development (route 2)",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Landscape and ecology management plan (or similar) development",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 99,
        "section": "POLLUTION",
        "creditCategoryCode": "Pol 01",
        "creditCategoryDescription": "Impact of refrigerants",
        "creditDescription": "No Refrigerants",
        "creditsMinimumStandards": "-",
        "credits": "3",
        "creditsTargeted": "3",
        "creditsPotential": "-",
        "creditsOverview": "",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 100,
        "section": "POLLUTION",
        "creditCategoryCode": "Pol 01",
        "creditCategoryDescription": "Impact of refrigerants",
        "creditDescription": "Prerequisite - BS EN 378:2016 (ammonia)",
        "creditsMinimumStandards": "-",
        "credits": "Yes",
        "creditsTargeted": "Yes",
        "creditsPotential": "-",
        "creditsOverview": "Pre-requisite : all systems with electric compressors comply with the requirements of BS EN 378:2016(207) (parts 2 and 3). Refrigeration systems containing ammonia comply with the Institute of Refrigeration Ammonia Refrigeration Systems code of practice (208). ",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 101,
        "section": "POLLUTION",
        "creditCategoryCode": "Pol 01",
        "creditCategoryDescription": "Impact of refrigerants",
        "creditDescription": "Impact of refrigerant",
        "creditsMinimumStandards": "-",
        "credits": "2",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "GWP of 10 or less / DELC CO2e of 100 kgCO2/kW cooling capacity OR DELC CO2e of 1000 kgCO2/kW cooling capacity ",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 102,
        "section": "POLLUTION",
        "creditCategoryCode": "Pol 01",
        "creditCategoryDescription": "Impact of refrigerants",
        "creditDescription": "Leak detection",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Refrigerant leak detection system provided",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 103,
        "section": "POLLUTION",
        "creditCategoryCode": "Pol 02",
        "creditCategoryDescription": "Local air quality",
        "creditDescription": "Local air quality",
        "creditsMinimumStandards": "-",
        "credits": "2",
        "creditsTargeted": "2",
        "creditsPotential": "-",
        "creditsOverview": "If all heating / hot water fed by electric - 2 credits achieved by default.\nWhere heating / hot water fed by combustion plant (i.e. boiler), minimum emission levels must be met.\nGas fired boilers:\n1 credit: 27 mg / kWh\n2 credits: 24 mg / kWh",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 104,
        "section": "POLLUTION",
        "creditCategoryCode": "Pol 03",
        "creditCategoryDescription": "Flood and surface water management",
        "creditDescription": "Prerequisite",
        "creditsMinimumStandards": "-",
        "credits": "Yes",
        "creditsTargeted": "Yes",
        "creditsPotential": "-",
        "creditsOverview": "Prerequisite - an appropriate consultant must be appointed ",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 105,
        "section": "POLLUTION",
        "creditCategoryCode": "Pol 03",
        "creditCategoryDescription": "Flood and surface water management",
        "creditDescription": "Flood resilience",
        "creditsMinimumStandards": "-",
        "credits": "2",
        "creditsTargeted": "2",
        "creditsPotential": "-",
        "creditsOverview": "Flood risk assessment (FRA) required. \n2 credits if low annual prob of flooding / 1 credit if medium/high annual prob of flooding",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 106,
        "section": "POLLUTION",
        "creditCategoryCode": "Pol 03",
        "creditCategoryDescription": "Flood and surface water management",
        "creditDescription": "Surface water run-off rate",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Surface Water Run-Off - Rate\n- Peak run off rate shows a 30% improvement\n- maintenance agreements\n- allowance for climate change",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 107,
        "section": "POLLUTION",
        "creditCategoryCode": "Pol 03",
        "creditCategoryDescription": "Flood and surface water management",
        "creditDescription": "Surface water run-off volume",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "Surface Water Run-Off - Volume\nWhere flooding will not occur in the event of local drainage failure\nPost-development run-off no greater than pre-development",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 108,
        "section": "POLLUTION",
        "creditCategoryCode": "Pol 03",
        "creditCategoryDescription": "Flood and surface water management",
        "creditDescription": "Minimum watercourse pollution",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "-",
        "creditsPotential": "1",
        "creditsOverview": "Minimum water course Pollution\nNo discharge from the developed site for rainfall up to 5 mm and SUDs / oil interceptors installed",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 109,
        "section": "POLLUTION",
        "creditCategoryCode": "Pol 04",
        "creditCategoryDescription": "Reduction of night time light pollution",
        "creditDescription": "Reduction of night time light pollution",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "External lighting in accordance with ILP guidance and connected to timer clock",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 110,
        "section": "POLLUTION",
        "creditCategoryCode": "Pol 05",
        "creditCategoryDescription": "Reduction of noise pollution",
        "creditDescription": "Reduction of noise pollution",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "1",
        "creditsPotential": "-",
        "creditsOverview": "External noise assessment if within 800m of a noise sensitive building",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 111,
        "section": "INNOVATION",
        "creditCategoryCode": "Inn 01",
        "creditCategoryDescription": "Man 03 - Responsible construction management",
        "creditDescription": "Man 03 - Responsible construction management",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "-",
        "creditsPotential": "1",
        "creditsOverview": "All responsible construction management criteria are met and evidenced by the Contractor",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 112,
        "section": "INNOVATION",
        "creditCategoryCode": "Inn 02",
        "creditCategoryDescription": "Hea 01 - Daylighting",
        "creditDescription": "Hea 01 - Daylighting",
        "creditsMinimumStandards": "-",
        "credits": "2",
        "creditsTargeted": "-",
        "creditsPotential": "1",
        "creditsOverview": "Daylighting levels achieved beyond standard BREEAM compliance",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 113,
        "section": "INNOVATION",
        "creditCategoryCode": "Inn 03",
        "creditCategoryDescription": "Hea 01 - Internal and external lighting levels, zoning and control",
        "creditDescription": "Hea 01 - Internal and external lighting levels, zoning and control",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "-",
        "creditsPotential": "1",
        "creditsOverview": "Lighting in each zone can be manually dimmed by occupants down to 20% of the max. light output",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 114,
        "section": "INNOVATION",
        "creditCategoryCode": "Inn 04",
        "creditCategoryDescription": "Hea 02 - Minimising sources of air pollution - Emissions from construction products",
        "creditDescription": "Hea 02 - Minimising sources of air pollution - Emissions from construction products",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "-",
        "creditsPotential": "1",
        "creditsOverview": "Emission levels achieved beyond standard BREEAM compliance",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 115,
        "section": "INNOVATION",
        "creditCategoryCode": "Inn 05",
        "creditCategoryDescription": "Hea 06 - Security of site and building",
        "creditDescription": "Hea 06 - Security of site and building",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "-",
        "creditsPotential": "1",
        "creditsOverview": "A compliant risk based security rating scheme (SABRE) is used and confirmed by independent assessment and verification",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 116,
        "section": "INNOVATION",
        "creditCategoryCode": "Inn 06",
        "creditCategoryDescription": "Ene 01 - Beyond zero net regulated carbon",
        "creditDescription": "Ene 01 - Beyond zero net regulated carbon",
        "creditsMinimumStandards": "-",
        "credits": "2",
        "creditsTargeted": "-",
        "creditsPotential": "1",
        "creditsOverview": "Energy performance beyond standard BREEAM compliance",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 117,
        "section": "INNOVATION",
        "creditCategoryCode": "Inn 07",
        "creditCategoryDescription": "Ene 01 - Carbon negative",
        "creditDescription": "Ene 01 - Carbon negative",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "-",
        "creditsPotential": "1",
        "creditsOverview": "Energy performance carbon negative",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 118,
        "section": "INNOVATION",
        "creditCategoryCode": "Inn 08",
        "creditCategoryDescription": "Ene 01 - Post occupancy stage",
        "creditDescription": "Ene 01 - Post occupancy stage",
        "creditsMinimumStandards": "-",
        "credits": "2",
        "creditsTargeted": "-",
        "creditsPotential": "1",
        "creditsOverview": "Contractual agreement to carry out DEC",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 119,
        "section": "INNOVATION",
        "creditCategoryCode": "Inn 09",
        "creditCategoryDescription": "Wat 01 - Water consumption",
        "creditDescription": "Wat 01 - Water consumption",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "-",
        "creditsPotential": "1",
        "creditsOverview": "Water consumption performance beyond standard BREEAM compliance",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 120,
        "section": "INNOVATION",
        "creditCategoryCode": "Inn 10",
        "creditCategoryDescription": "Mat 01 - Core building services options appraisal during Concept Design",
        "creditDescription": "Mat 01 - Core building services options appraisal during Concept Design",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "-",
        "creditsPotential": "1",
        "creditsOverview": "LCA including building services appraisal - must be carried out at concept design",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 121,
        "section": "INNOVATION",
        "creditCategoryCode": "Inn 11",
        "creditCategoryDescription": "Mat 01 - LCA and LCC alignment",
        "creditDescription": "Mat 01 - LCA and LCC alignment",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "-",
        "creditsPotential": "1",
        "creditsOverview": "LCA and LCC carried out with same programme, and findings align",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 122,
        "section": "INNOVATION",
        "creditCategoryCode": "Inn 12",
        "creditCategoryDescription": "Mat 01 - Third party verification",
        "creditDescription": "Mat 01 - Third party verification",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "-",
        "creditsPotential": "1",
        "creditsOverview": "LCA provider to provide third party verification of the LCA outputs",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 123,
        "section": "INNOVATION",
        "creditCategoryCode": "Inn 13",
        "creditCategoryDescription": "Mat 03 - Measuring responsible sourcing",
        "creditDescription": "Mat 03 - Measuring responsible sourcing",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "-",
        "creditsPotential": "1",
        "creditsOverview": "Responsible sourcing performance beyond standard BREEAM compliance",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 124,
        "section": "INNOVATION",
        "creditCategoryCode": "Inn 14",
        "creditCategoryDescription": "Wst 01 - Construction waste management",
        "creditDescription": "Wst 01 - Construction waste management",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "-",
        "creditsPotential": "1",
        "creditsOverview": "Waste consumption performance beyond standard BREEAM compliance",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 125,
        "section": "INNOVATION",
        "creditCategoryCode": "Inn 15",
        "creditCategoryDescription": "Wst 02 - Use of recycled and sustainably sourced aggregates",
        "creditDescription": "Wst 02 - Use of recycled and sustainably sourced aggregates",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "-",
        "creditsPotential": "1",
        "creditsOverview": "Waste aggregates performance beyond standard BREEAM compliance",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 126,
        "section": "INNOVATION",
        "creditCategoryCode": "Inn 16",
        "creditCategoryDescription": "Wst 05 - Responding to climate change",
        "creditDescription": "Wst 05 - Responding to climate change",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "-",
        "creditsPotential": "1",
        "creditsOverview": "Wst 05 credit achieved, AND credits for the following met: Hea 04; Ene 01 (6 credits); Ene 04 passive design; Wat 01 (3 credits); Mat 05 (environmental degradation); and Pol 03 (1 credit for resilience, 2 credits for surface water run off)",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 127,
        "section": "INNOVATION",
        "creditCategoryCode": "Inn 17",
        "creditCategoryDescription": "LE 02 - Determine the ecological outcomes for the site (sustainability-related activities)",
        "creditDescription": "LE 02 - Determine the ecological outcomes for the site (sustainability-related activities)",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "-",
        "creditsPotential": "1",
        "creditsOverview": "LE 02 credits achieved, AND achieve the following: Hea 07 (both credits); Pol 03 (surface water run off and minimising watercourse pollution credits); and Pol 05.",
        "creditNotes": "-",
"creditAchieved": ""
      },
      {
        
        "sortOrder": 128,
        "section": "INNOVATION",
        "creditCategoryCode": "Inn 18",
        "creditCategoryDescription": "LE 04 - Ecological enhancement",
        "creditDescription": "Change and enhancement of ecology",
        "creditsMinimumStandards": "-",
        "credits": "1",
        "creditsTargeted": "-",
        "creditsPotential": "1",
        "creditsOverview": "Ecological value calculation confirms significant net gain achieved as per GN36",
        "creditNotes": "-",
"creditAchieved": ""
      }].sort((item1, item2) => item1.sortOrder < item2.sortOrder ? -1 : item1.sortOrder > item2.sortOrder ? 1 : 0)

    const requiredDocumentaryEvidenceDesignStage = [{
        
        "creditCategoryCode": "Man 01",
        "creditDescription": "Project delivery planning",
        "documentaryEvidence": "Completed roles, responsibilities and contribution matrix",
        "responsibility": "Project Manager",
        "timeCritical": "Before end of Concept Design",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 01",
        "creditDescription": "Project delivery planning",
        "documentaryEvidence": "Copy of the design programme.",
        "responsibility": "Project Manager",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 01",
        "creditDescription": "Project delivery planning",
        "documentaryEvidence": "Meeting minutes/agendas/presentations demonstrating early consultation with all relevant stakeholders of the following:\n- End user requirements\n- Aims of the design and design strategy\n- Particular installation and construction requirements or limitations\n- Occupiers' budget and technical expertise in maintaining any proposed systems\n- Maintainability and adaptability of the proposals\n- Operational energy\n- Requirements for the production of project and end user documentation\n- Requirements for commissioning, training and aftercare support.",
        "responsibility": "Project Manager",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 01",
        "creditDescription": "Project delivery planning",
        "documentaryEvidence": "Documentation demonstrating contributions of design team at key stages within initial brief, project execution plan, communications strategy and concept design",
        "responsibility": "Project Manager",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 01",
        "creditDescription": "Project delivery planning",
        "documentaryEvidence": "Statement confirming stakeholder consultation process",
        "responsibility": "Project Manager",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 01",
        "creditDescription": "Stakeholder consultation",
        "documentaryEvidence": "Consultation plan setting out the process, timeline and scope of the consultation.",
        "responsibility": "Architect",
        "timeCritical": "Before end of Concept Design",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 01",
        "creditDescription": "Stakeholder consultation",
        "documentaryEvidence": "All documents used and collected from the consultation process including list of stakeholders, meeting minutes, agendas, presentations.",
        "responsibility": "Architect",
        "timeCritical": "Before end of Concept Design",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 01",
        "creditDescription": "Stakeholder consultation",
        "documentaryEvidence": "Documentation confirming that feedback was given to the consultees on their ideas / concerns (e.g. presentations, website/newsletter updates).",
        "responsibility": "Architect",
        "timeCritical": "Before end of Technical Design",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 01",
        "creditDescription": "BREEAM AP (concept design)",
        "documentaryEvidence": "Pre-requisite: Documentation demonstrating that the BREEAM performance targets (rating) has been set early in the design stage.",
        "responsibility": "Client",
        "timeCritical": "Preparation and Brief",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 01",
        "creditDescription": "BREEAM AP (concept design)",
        "documentaryEvidence": "Documentation demonstrating that the BREEAM AP is invited to key design team meetings, and provided with minutes for all other team meetings.",
        "responsibility": "Client",
        "timeCritical": "Preparation and Brief",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 01",
        "creditDescription": "BREEAM AP (concept design)",
        "documentaryEvidence": "Written confirmation that the BREEAM AP was appointed at the appropriate stage and will report progress against BREEAM targets.",
        "responsibility": "Client",
        "timeCritical": "Preparation and Brief",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 01",
        "creditDescription": "BREEAM AP (developed design)",
        "documentaryEvidence": "Meeting minutes confirming attendance of BREEAM AP to review project progress against BREEAM.",
        "responsibility": "Project Manager",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 01",
        "creditDescription": "BREEAM AP (developed design)",
        "documentaryEvidence": "BREEAM Progress report.",
        "responsibility": "BREEAM Accredited Professional",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 02",
        "creditDescription": "Elemental LCC",
        "documentaryEvidence": "Elemental life cycle cost plan at RIBA Stage 2 (60 year design life, unless otherwise confirmed by the client) in line with PD 156865:2008. This must:\na. Show an indication of future replacement costs over a period of analysis agreed to by the client (e.g. 20, 30, 50, 60+ years)\nb. Include service life, maintenance and operation cost estimates.",
        "responsibility": "Specialist Consultant",
        "timeCritical": "Before end of Concept Design",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 02",
        "creditDescription": "Elemental LCC",
        "documentaryEvidence": "Documentation (specifications / drawings) demonstrating examples of how the elemental LCC has influenced the design of the building",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 02",
        "creditDescription": "Component level LCC options appraisal",
        "documentaryEvidence": "Component level life cycle cost plan at RIBA Stage 4 in line with PD 156865:2008 that includes the following component types (where present): envelope, services, finishes, external spaces.",
        "responsibility": "Specialist Consultant",
        "timeCritical": "Before end of Technical Design",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 02",
        "creditDescription": "Component level LCC options appraisal",
        "documentaryEvidence": "A specification document / drawings showing how the component level LCC plan has been used to influence building and systems design/specification to minimise life cycle costs and maximise critical value.",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 02",
        "creditDescription": "Capital cost reporting",
        "documentaryEvidence": "Compliance letter confirming the predicted capital costs, included contingencies, in pounds per square metre (£k/m2).",
        "responsibility": "Client",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 03",
        "creditDescription": "Prerequisite - Legally harvested & traded timber",
        "documentaryEvidence": "Pre-requisite: Written confirmation that all site timber will be legally harvested\nand traded timber.",
        "responsibility": "Contractor",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 03",
        "creditDescription": "Prerequisite - Legally harvested & traded timber",
        "documentaryEvidence": "Pre-requisite: Written confirmation that any party who manages the site will operate under an Environmental Management System ISO 14001 or BS 8555:2016 (main contractor / demolition contractor / etc.)\n",
        "responsibility": "Contractor",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 03",
        "creditDescription": "Environmental management",
        "documentaryEvidence": "Written confirmation that any party managing the construction site (main contractor / demolition contractor) has ISO 14001 or BS 8555:2016 certification.",
        "responsibility": "Client",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 03",
        "creditDescription": "Environmental management",
        "documentaryEvidence": "Written confirmation that the main contractor's pollution prevention procedures will be implemented at the site (in line with PPG6).",
        "responsibility": "Contractor",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 03",
        "creditDescription": "BREEAM AP (site)",
        "documentaryEvidence": "Written confirmation that the BREEAM targets have been formally agreed between the client and main contractor prior to site works commencing.",
        "responsibility": "Contractor",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 03",
        "creditDescription": "BREEAM AP (site)",
        "documentaryEvidence": "Written confirmation that the BREEAM AP was appointed as Sustainability Champion to monitor and report against BREEAM performance targets during the construction / handover stages.",
        "responsibility": "Contractor",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 03",
        "creditDescription": "Responsible construction management",
        "documentaryEvidence": "Written commitment that the main contractor will carry out the mandatory BREEAM Responsible Construction Management requirements.",
        "responsibility": "Contractor",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 03",
        "creditDescription": "Responsible construction management",
        "documentaryEvidence": "Written commitment that the main contractor will carry out the mandatory BREEAM Responsible Construction Management requirements, plus six additional requirements.",
        "responsibility": "Contractor",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 03",
        "creditDescription": "Responsible construction management (Exemplary level)",
        "documentaryEvidence": "Written commitment that the main contractor will carry out all of the BREEAM Responsible Construction Management requirements.",
        "responsibility": "Contractor",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 03",
        "creditDescription": "Monitoring of construction site impacts - Energy & water",
        "documentaryEvidence": "Written confirmation that responsibility has been assigned to an individual(s) for monitoring, recording and reporting energy and water consumption data resulting from all construction processes.",
        "responsibility": "Contractor",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 03",
        "creditDescription": "Monitoring of construction site impacts - Energy & water",
        "documentaryEvidence": "Written confirmation that targets have been set for site water and energy consumption, and that consumption will be monitored and recorded. ",
        "responsibility": "Contractor",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 03",
        "creditDescription": "Transport of construction materials & waste",
        "documentaryEvidence": "Written confirmation that responsibility has been assigned to an individual(s) for monitoring, recording and reporting of transport details for all deliveries to the site, and all waste removal from the site.",
        "responsibility": "Contractor",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 03",
        "creditDescription": "Transport of construction materials & waste",
        "documentaryEvidence": "Written confirmation that targets will be set in respect of transport movements (in km and litres of fuel), and these will be monitored and recorded separately in terms of:\n-Materials delivered to the site\n-Waste removed from the site. ",
        "responsibility": "Contractor",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 04",
        "creditDescription": "Commissioning - Testing schedule and responsibilities",
        "documentaryEvidence": "Schedule of commissioning and testing, identifying suitable timescales for commissioning and re-commissioning of all systems.",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 04",
        "creditDescription": "Commissioning - Testing schedule and responsibilities",
        "documentaryEvidence": "Written confirmation of procedures for commissioning of the BMS.",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 04",
        "creditDescription": "Commissioning - Testing schedule and responsibilities",
        "documentaryEvidence": "Written confirmation that an appropriate project team member is appointed to monitor and programme pre-commissioning, commissioning and re-commissioning on behalf of the client, and that commissioning will be undertaken in line with CIBSE, BSRIA and Building Regulations.",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 04",
        "creditDescription": "Commissioning - Testing schedule and responsibilities",
        "documentaryEvidence": "Project Programme including dates for commissioning.",
        "responsibility": "Contractor",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 04",
        "creditDescription": "Commissioning - Design and preparation",
        "documentaryEvidence": "Written confirmation that a specialist commissioning manager will be appointed during the design stage to undertake design reviews and provide advice during construction programming, installation and testing.",
        "responsibility": "Contractor",
        "timeCritical": "Before end of Technical Design - appointment",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 04",
        "creditDescription": "Testing and inspecting building fabric",
        "documentaryEvidence": "Written confirmation that a Thermographic Survey and air tightness test will be completed at project completion and that any defects identified will be rectified prior to building handover.",
        "responsibility": "Contractor",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 04",
        "creditDescription": "Testing and inspecting building fabric",
        "documentaryEvidence": "Written confirmation that the Thermographer will have a Level 2 certificate in thermography.",
        "responsibility": "Contractor",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 04",
        "creditDescription": "Testing and inspecting building fabric",
        "documentaryEvidence": "Confirmation that the project budget includes allowance for the Thermographic Survey.",
        "responsibility": "Contractor",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 04",
        "creditDescription": "Handover",
        "documentaryEvidence": "Written commitment to produce two non-technical building user guides:\n1. A non-technical user guide for distribution to the building occupiers\n2. A technical user guide for the premises facilities managers.",
        "responsibility": "Contractor",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 04",
        "creditDescription": "Handover",
        "documentaryEvidence": "Confirmation that the building user guides will be provided to the building occupants.",
        "responsibility": "Contractor",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 04",
        "creditDescription": "Handover",
        "documentaryEvidence": "Confirmation of the content of the training schedules prepared:\n1. A non-technical training schedule for the building occupiers.\n2. A technical training schedule for the premises facilities managers.",
        "responsibility": "Contractor",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 05",
        "creditDescription": "Aftercare Support",
        "documentaryEvidence": "Written confirmation that the following will be undertaken post development:\n- Collect the energy and water consumption data for at least 12 months after occupation\n- Compare this with what was expected\n- Analyse any discrepancies with a view of adjusting systems if they are not operating as expected / designed.",
        "responsibility": "Client",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 05",
        "creditDescription": "Aftercare Support",
        "documentaryEvidence": "Written confirmation that aftercare support and training will be provided in line with the following: \n- A meeting to introduce the aftercare team and Building User Guide, present key information about how the building operates and answer questions.\n- Initial aftercare - e.g. on site attendance on a weekly basis for at least 4 weeks after handover.\n- On site FM training to include a walkabout of the building.\n- Longer term after care (e.g. a helpline, nominated individual or other appropriate system) to support building users for at least 12 months of occupation.",
        "responsibility": "Client",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 05",
        "creditDescription": "Commissioning - Implementation",
        "documentaryEvidence": "Written confirmation that seasonal commissioning responsibilities will be completed over a minimum 12 month period, once the building becomes occupied (in line with BREEAM criteria for either complex or simple systems).",
        "responsibility": "Client",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 05",
        "creditDescription": "Post Occupancy Evaluation (POE)",
        "documentaryEvidence": "Written commitment to undertake a POE in line with the BREEAM requirements 1 year after building occupation.",
        "responsibility": "Client",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Man 05",
        "creditDescription": "Post Occupancy Evaluation (POE)",
        "documentaryEvidence": "Written statement confirming that there will be appropriate dissemination of information on the buildings post occupancy performance.",
        "responsibility": "Client",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Hea 01",
        "creditDescription": "Control of glare from sunlight",
        "documentaryEvidence": "Copy of the glare control strategy report OR confirmation that blinds will be installed on all windows in relevant building areas.",
        "responsibility": "Specialist Consultant",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Hea 01",
        "creditDescription": "Daylighting",
        "documentaryEvidence": "Copy of the daylighting report.",
        "responsibility": "Specialist Consultant",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Hea 01",
        "creditDescription": "View out",
        "documentaryEvidence": "Floor layout plans confirming 95% of the floor areas is within 8m of a wall with a window.",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Hea 01",
        "creditDescription": "View out",
        "documentaryEvidence": "Elevations and calculations to confirm that windows represent at least 20% of the surrounding wall area for applicable rooms.",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Hea 01",
        "creditDescription": "Internal and external lighting levels, zoning and control",
        "documentaryEvidence": "Written confirmation that internal lighting and external lighting will meet the relevant illuminance level standards",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "We can confirm the following with regard to the lighting specification:\n\n\nInternal lighting\n\n- Internal lighting in all relevant areas of the building is designed to provide an illuminance (lux) level and colouring rendering index in accordance with the SLL Code for Lighting 2012 and any other relevant industry standard.\n\n- For areas where computer screens are regularly used, the lighting design complies with CIBSE Lighting Guide 7 sections 2.4, 2.13 to 2.15, 2.20, and 6.10 to 6.20. This gives recommendations highlighting:\n\n- Limits to the luminance of the luminaires to avoid screen reflections. \n\n- For uplighting, the recommendations refer to the luminance of the lit ceiling rather than the luminaire; a design team calculation is usually required to demonstrate this.\n\n- Recommendations for direct lighting, ceiling illuminance, and average wall illuminance.",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Hea 01",
        "creditDescription": "Internal and external lighting levels, zoning and control",
        "documentaryEvidence": "Written confirmation that lighting  controls will be appropriately zoned and occupant controlled",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "\nZoning and occupant control\n\nThe developments internal lighting design allows for different building areas to have separate lighting zones and occupant controls (i.e. switches / remote control) in accordance with the following criteria (where relevant):\n\n- In office areas, lighting zones of no more than four workplaces\n\n- Workstations adjacent to windows/atria and other building areas separately zoned and controlled\n\n- Seminar and lecture rooms zoned for presentation and audience areas\n\n- Library spaces: separate zoning of stacks, reading and counter areas\n\n- Teaching space or demonstration area\n\n- Whiteboard or display screen to have separate lighting zone and controls\n\n- Dining, restaurant, café areas: separate zoning of servery and seating/dining areas\n\n- Retail: separate zoning of display and counter areas\n\n- Bar areas: separate zoning of bar and seating areas\n\nAreas used for teaching, seminar or lecture purposes have lighting controls provided in accordance with CIBSE Lighting Guide 5. Manual lighting controls will easily accessible for the teacher while teaching and on entering or leaving the teaching space.\n\n\nExternal lighting\n\nAll external lighting located within the construction zone is designed to provide illuminance levels that enable users to perform outdoor visual tasks efficiently and accurately, especially during the night. \n\nExternal lighting provided is specified in accordance with BS 5489-1:2013 Lighting of roads and public amenity areas and BS EN 12464-2:2014 Light and lighting - Lighting of workplaces - Part 2: Outdoor workplaces.",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Hea 01",
        "creditDescription": "Internal and external lighting levels, zoning and control",
        "documentaryEvidence": "Lighting layout drawings - demonstrating zoning and control system",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Hea 02",
        "creditDescription": "Prerequisite - Indoor air quality (IAQ) plan",
        "documentaryEvidence": "A copy of a BREEAM Compliant indoor air quality plan covering:\na. Removal of contaminant sources\nb. Dilution and control of contaminant sources\nc. Procedures for pre-occupancy flush out\nd. Third party testing and analysis\ne. Maintaining indoor air quality in-use",
        "responsibility": "Specialist Consultant",
        "timeCritical": "Before end of Concept Design",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Hea 02",
        "creditDescription": "Ventilation",
        "documentaryEvidence": "Confirmation that air intakes and exhausts will be located in accordance with best practice in terms of minimising build-up of air pollutants",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Hea 02",
        "creditDescription": "Ventilation",
        "documentaryEvidence": "Pollution dispersion modelling will be provided to inform the positioning of air intakes and exhausts",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Hea 02",
        "creditDescription": "Ventilation",
        "documentaryEvidence": "Written confirmation that HVAC systems will incorporate suitable filtration to minimise external air pollution (BS EN 16798-3:2017) - with air classification of at least SUP 2",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Hea 02",
        "creditDescription": "Ventilation",
        "documentaryEvidence": "Drawings demonstrating air intakes and exhausts will be at least 10m apart (horizontally)",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Hea 02",
        "creditDescription": "Ventilation",
        "documentaryEvidence": "WHERE BUILDING  AREAS ARE SUBJECT TO LARGE / VARIABLE OCCUPANCY - Written confirmation that CO2 sensors / air quality sensors will be installed in areas of large / variable occupancy - with demand-controlled ventilation to the space",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Hea 02",
        "creditDescription": "Ventilation",
        "documentaryEvidence": "Written confirmation that the ventilation strategy is designed in line with CIBSE AM10, and that ventilation intakes and airflow pathways are designed in line with BRE FB 30; BRE IP 9/14; CIBSE TM21",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Hea 02",
        "creditDescription": "Ventilation",
        "documentaryEvidence": "Drawings demonstrating that ventilation inlets / openable windows / trickle and background ventilators will be positioned at least 10m horizontal distance from sources of external pollution",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Hea 02",
        "creditDescription": "Ventilation",
        "documentaryEvidence": "WHERE BUILDING  AREAS ARE SUBJECT TO LARGE / VARIABLE OCCUPANCY - Written confirmation that CO2 sensors / air quality sensors will be installed in areas of large / variable occupancy - sensors to have the capacity to either notify the building manager or automatically open windows / roof vents",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Hea 02",
        "creditDescription": "Emissions from construction products",
        "documentaryEvidence": "Written confirmation that VOC (volatile organic compounds) requirements will be met for at least three of the following product types:\n1. Interior paint and coatings\n2. Wood-based products (including timber floor)\n3. Flooring materials (including floor levelling compounds and resin flooring)\n4. Ceiling, wall, and acoustic and thermal insulation materials\n5. Interior adhesives and sealants (including floor adhesives)",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Hea 02",
        "creditDescription": "Emissions from construction products",
        "documentaryEvidence": "Written confirmation that VOC (volatile organic compounds) requirements will be met for all of the following product types:\n1. Interior paint and coatings\n2. Wood-based products (including timber floor)\n3. Flooring materials (including floor levelling compounds and resin flooring)\n4. Ceiling, wall, and acoustic and thermal insulation materials\n5. Interior adhesives and sealants (including floor adhesives)",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Hea 02",
        "creditDescription": "Post-construction indoor air quality measurement",
        "documentaryEvidence": "Written commitment to carry out post-construction indoor air quality testing to confirm that formaldehyde concentration levels do not exceed World Health Organisation guidelines for indoor air quality",
        "responsibility": "Contractor",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Hea 03",
        "creditDescription": "Not applicable in BREEAM 2018",
        "documentaryEvidence": "-",
        "responsibility": "-",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Hea 04",
        "creditDescription": "Thermal Modelling CIBSE AM11",
        "documentaryEvidence": "Written confirmation that full dynamic thermal modelling is carried out in compliance with CIBSE AM11",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Hea 04",
        "creditDescription": "Thermal Modelling CIBSE AM11",
        "documentaryEvidence": "FOR AIR CONDITIONED BUILDINGS: Thermal modelling results demonstrating compliance with CIBSE Guide A, and confirming PPD and PMV",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Hea 04",
        "creditDescription": "Thermal Modelling CIBSE AM11",
        "documentaryEvidence": "FOR NATURALLY VENTILATED BUILDINGS: Thermal modelling results demonstrate that winter operative temperatures meet CIBSE Guide A criteria; and the building is designed to limit the risk of overheating in line with either CIBSE TM52 or TM59",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Hea 04",
        "creditDescription": "Design for future thermal comfort",
        "documentaryEvidence": "Thermal modelling (CIBSE AM11) results demonstrating compliance with CIBSE Guide A for a future climate change environment",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Hea 04",
        "creditDescription": "Design for future thermal comfort",
        "documentaryEvidence": "OR: Where thermal modelling results cannot demonstrate compliance with CIBSE Guide A for a future climate change environment, drawings and / or statement detailing how the building has been or could be easily adapted using passive design solutions to comply with CIBSE Guide A",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Hea 04",
        "creditDescription": "Thermal zoning and controls",
        "documentaryEvidence": "Written statement confirming that the thermal modelling report has informed the design of the building",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Hea 04",
        "creditDescription": "Thermal zoning and controls",
        "documentaryEvidence": "Specification and / or design drawings confirming the controls for the heating system (in line with the outcome of the thermal modelling report)",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Hea 05",
        "creditDescription": "Acoustic performance",
        "documentaryEvidence": "Acoustic report confirming the building will meet the appropriate acoustic performance standards for:\n- sound insulation\n- indoor ambient noise level\n- reverberation times",
        "responsibility": "Specialist Consultant",
        "timeCritical": "Before end of Technical Design - appointment",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Hea 05",
        "creditDescription": "Acoustic performance",
        "documentaryEvidence": "Acoustic report demonstrating that the building will achieve an improvement on Building Regulations airborne and impact sound insulation criteria",
        "responsibility": "Specialist Consultant",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Hea 05",
        "creditDescription": "Acoustic performance",
        "documentaryEvidence": "Acoustic report demonstrating that indoor ambient noise levels within the building will comply with Section 7 of BS8233:2014",
        "responsibility": "Specialist Consultant",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Hea 05",
        "creditDescription": "Acoustic performance",
        "documentaryEvidence": "Acoustic report demonstrating that sound absorption within residential spaces and common spaces within the building will be in line with Building Regulations",
        "responsibility": "Specialist Consultant",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Hea 05",
        "creditDescription": "Acoustic performance",
        "documentaryEvidence": "Written confirmation that any remediation works will be followed where required",
        "responsibility": "Contractor",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Hea 05",
        "creditDescription": "Acoustic performance",
        "documentaryEvidence": "Qualifications, experience & membership of the Suitably Qualified Acoustician",
        "responsibility": "Specialist Consultant",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Hea 06",
        "creditDescription": "Security of site and building",
        "documentaryEvidence": "A copy of the Security Needs Assessment report, provided by a Suitably Qualified Security Specialist outlining:\n1. Scope of their advice/involvement\n2. The stage of design in which their advice was sought (no later than RIBA stage 2)\n3. Summary of their recommendations",
        "responsibility": "Specialist Consultant",
        "timeCritical": "Before end of Concept Design - appointment",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Hea 06",
        "creditDescription": "Security of site and building",
        "documentaryEvidence": "Written confirmation of the qualifications of the Suitably Qualified Security Specialist",
        "responsibility": "Specialist Consultant",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Hea 06",
        "creditDescription": "Security of site and building",
        "documentaryEvidence": "Written confirmation and / or site drawings confirming design in accordance with Suitably Qualified Security Specialist recommendation.",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Hea 06",
        "creditDescription": "Security of site and building",
        "documentaryEvidence": "Written confirmation from Suitably Qualified Security Specialist that any deviations from original recommendations have been agreed (if applicable)",
        "responsibility": "Specialist Consultant",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Hea 07",
        "creditDescription": "Safe access",
        "documentaryEvidence": "Site plan showing safe and separate access routes for:\n- cyclists (including cycle paths to cycle storage);\n- pedestrians (including mark-ups of any traffic calming measures, i.e. speed bumps, and raised pedestrian crossings); \n- small vehicles (including parking provision and pedestrian access from car to building); and\n- delivery vehicles / areas (including manoeuvring layouts and mark-up of any waste storage areas).",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Hea 07",
        "creditDescription": "Outside space",
        "documentaryEvidence": "Site plan demonstrating outside amenity space accessible to all building occupants. The space must:\n-be an outdoor landscaped area (garden / terrace open to the sky)\n-have appropriate seating\n-be designated as non-smoking\n-be away from areas that will be subject to noise disturbance (e.g. building services, car parks, busy roads, delivery areas, etc)",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Hea 07",
        "creditDescription": "Outside space",
        "documentaryEvidence": "Written statement confirming the amenity space has been sized appropriately for the number of expected building users",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Ene 01",
        "creditDescription": "Energy Performance",
        "documentaryEvidence": "A copy of the 2013 Part L Approved Document checks (BRUKL Output Document)",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Ene 01",
        "creditDescription": "Energy Performance",
        "documentaryEvidence": "Confirmation of suitably qualified energy modeller's qualifications and experience",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Ene 01",
        "creditDescription": "Prediction of operational energy consumption",
        "documentaryEvidence": "Pre-requisite - Meeting minutes from energy workshop (carried out prior to Concept Design) confirming agreed outcomes from meeting, regarding operational energy performance.",
        "responsibility": "Specialist Consultant",
        "timeCritical": "Before end of Concept Design",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Ene 01",
        "creditDescription": "Prediction of operational energy consumption",
        "documentaryEvidence": "Operational energy consumption report in line with BREEAM requirements: to include risk assessment and energy modelling results of predicted operational energy consumption values by end use, design vales and input data for five different operational scenarios.",
        "responsibility": "Specialist Consultant",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Ene 02",
        "creditDescription": "Sub-metering of end-use categories",
        "documentaryEvidence": "Written confirmation / specifications confirming that all major energy consuming systems will be sub-metered.",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Ene 02",
        "creditDescription": "Sub-metering of end-use categories",
        "documentaryEvidence": "Technical drawings and specification confirming: \n- energy consuming systems (heating, hot water, small power, lighting and any major fans) and their rated outputs.\n- metering arrangement for each system, type and location of meter specified\n- meters are pulsed and labelled.",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Ene 02",
        "creditDescription": "Sub-metering of high energy load & tenancy areas",
        "documentaryEvidence": "Written confirmation / specifications confirming that different function / tenant areas will be sub-metered (e.g. kitchen, office floor plates,.",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Ene 02",
        "creditDescription": "Sub-metering of high energy load & tenancy areas",
        "documentaryEvidence": "Technical drawings confirming: additional sub meter(s) for different function / tenancy areas.",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Ene 03",
        "creditDescription": "External Lighting",
        "documentaryEvidence": "Marked up site plans showing location and purpose of all external light fittings",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Ene 03",
        "creditDescription": "External Lighting",
        "documentaryEvidence": "Written confirmation / specifications confirming external lighting meets luminaire requirements (>70 lumens per circuit watt), and are fitted with daylight sensors, and presence detectors in areas of intermittent pedestrian traffic.",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Ene 04",
        "creditDescription": "Passive Design Analysis",
        "documentaryEvidence": "Results of thermal modelling (Hea04)",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Ene 04",
        "creditDescription": "Passive Design Analysis",
        "documentaryEvidence": "A Passive Design Analysis report of the proposed building (at RIBA Stage 2), that identifies passive design solutions and quantifies the total reduced energy demand and carbon dioxide emissions resulting from them.",
        "responsibility": "M&E",
        "timeCritical": "Before end of Concept Design",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Ene 04",
        "creditDescription": "Passive Design Analysis",
        "documentaryEvidence": "Drawing plans / specification confirming specification of passive design measures",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Ene 04",
        "creditDescription": "Free Cooling ",
        "documentaryEvidence": "As above for 'Passive Design', with analysis of free cooling solutions and opportunities for the building.",
        "responsibility": "M&E",
        "timeCritical": "Before end of Concept Design",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Ene 04",
        "creditDescription": "Free Cooling ",
        "documentaryEvidence": "Written confirmation / specification confirming either the building is naturally ventilated OR uses any combination of free cooling strategies",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Ene 04",
        "creditDescription": "LZC feasibility study",
        "documentaryEvidence": "A copy of the low/zero carbon feasibility study including quantified reduced regulated carbon dioxide (CO2.eq) emissions resulting from the low carbon / renewable technology.",
        "responsibility": "M&E",
        "timeCritical": "Before end of Concept Design",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Ene 04",
        "creditDescription": "LZC feasibility study",
        "documentaryEvidence": "A drawing or specification showing the specification of the low carbon / renewable technology as recommended within the feasibility study.",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Ene 05",
        "creditDescription": "Refrigeration energy consumption",
        "documentaryEvidence": "Written confirmation / specification confirming the refrigeration system is in accordance with the Code of Conduct for carbon reduction in the refrigeration retail section and BS EN 378-2:2016",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Ene 05",
        "creditDescription": "Indirect greenhouse gas emissions",
        "documentaryEvidence": "Above credit achieved, AND",
        "responsibility": "",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Ene 05",
        "creditDescription": "Indirect greenhouse gas emissions",
        "documentaryEvidence": "Written confirmation of the type of cold storage specified and calculations demonstrating the saving in indirect greenhouse gas emissions (CO2.eq) from the specified refrigeration system over the course of its operational life.",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Ene 06",
        "creditDescription": "Energy consumption",
        "documentaryEvidence": "Transport lift analysis to determine lift demand and usage patterns for the building. The analysis must determine the optimum number and size of lifts (including counter-balancing ratio), and the estimated energy consumption for one of the following: \n- at least two type of systems\n- an arrangement of systems \n- a strategy which is fit for purpose ",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Ene 06",
        "creditDescription": "Energy consumption",
        "documentaryEvidence": "Written confirmation that the lift analysis considered the use of regenerative drive, and the lift with the lowest energy consumption has been specified.",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Ene 06",
        "creditDescription": "Energy efficient features - Lifts",
        "documentaryEvidence": "Copy of the lift specification or manufacturer literature to confirm the energy-efficient features specified include:\n- Standby condition for off-peak periods\n- Car lighting and display lighting provided has an average luminous efficacy of > 70 luminaire lumens per circuit watt\n- Drive controller capable of variable speed, variable-voltage and variable frequency (VVVF)",
        "responsibility": "Specialist Consultant",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Ene 06",
        "creditDescription": "Energy efficient features - Escalators or moving walks",
        "documentaryEvidence": "Written confirmation that there will be either:\nA load-sensing device that synchronises motor output to passenger demand through a variable speed drive OR\nA passenger-sensing device for automated operation (auto walk), so the escalator operates in auto start mode when there is no passenger demand.",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Ene 07",
        "creditDescription": "Design specification",
        "documentaryEvidence": "Written confirmation / specification defining the performance criteria of lab facilities in accordance with BREEAM requirements and correct sizing of the services system equipment (including ventilation supply and extract).",
        "responsibility": "M&E",
        "timeCritical": "Before end of Concept Design",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Ene 07",
        "creditDescription": "Design specification",
        "documentaryEvidence": "Written confirmation to demonstrate that there is a minimised energy demand of the laboratory facilities resulting from the achievement of the defined design performance criteria.",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Ene 07",
        "creditDescription": "Design specification",
        "documentaryEvidence": "Written confirmation / specification confirming that ducted fume cupboards will comply with the following:\nDemonstrate that the average design air flow rate is no greater than 0.16 m3/s per linear metre (internal width) of fume cupboard workspace.\nThe volume flow rate in the exhaust duct (at the boundary of the laboratory) will be measured to take account of reductions in (inward) volume flow rate from fume cupboard leakage\nDemonstrate that a reduction in air flow does not compromise the defined performance criteria and does not increase the health and safety risk to future building occupants.",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Ene 07",
        "creditDescription": "Best practice energy efficient measures",
        "documentaryEvidence": "Written confirmation / specification confirming that laboratory plant and systems have been designed to promote energy efficiency.",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Ene 07",
        "creditDescription": "Best practice energy efficient measures",
        "documentaryEvidence": "Demonstrate by calculations or modelling that the measures chosen have had a reasonably significant effect on the total energy consumption of the laboratory",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Ene 08",
        "creditDescription": "Energy efficient equipment ",
        "documentaryEvidence": "Written confirmation of the energy consuming systems that will be responsible for the majority of unregulated energy consumption in the building (with calculations, where there is not a distinct high consumption use). For example: office equipment, white goods, swimming pool, kitchen facilities, IT intensive equipment, laundry facilities.",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Ene 08",
        "creditDescription": "Energy efficient equipment ",
        "documentaryEvidence": "Written confirmation / specification confirming that the unregulated energy consuming system identified above, will comply with the relevant identified by BREEAM.",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Tra 01",
        "creditDescription": "Travel Plan",
        "documentaryEvidence": "Travel Plan developed during the feasibility and design stages based on a site-specific travel assessment or statement in accordance with BREEAM requirements (please contact BREEAM assessor for full details).",
        "responsibility": "Specialist Consultant",
        "timeCritical": "Preparation and Brief",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Tra 01",
        "creditDescription": "Travel Plan",
        "documentaryEvidence": "Written confirmation that the travel plan will be implemented post construction and be supported by the building's management in operation.",
        "responsibility": "Client",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Tra 02",
        "creditDescription": "Transport options implementation",
        "documentaryEvidence": "Assessment of existing site Accessibility Index (AI), AND evidence of the following sustainable transport measures, where relevant:",
        "responsibility": "Eight Versa",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Tra 02",
        "creditDescription": "Transport options implementation",
        "documentaryEvidence": "Written statement (including correspondence if applicable) confirming negotiation with local authority / local bus / train company to increase the frequency of the local service provision for the development;",
        "responsibility": "Client",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Tra 02",
        "creditDescription": "Transport options implementation",
        "documentaryEvidence": "Written statement confirming a bus route has been diverted or a new bus stop provided (or similar) to demonstrate an increase over the existing AI, AND\nupdated AI calculations included the updated bus route.",
        "responsibility": "Client",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Tra 02",
        "creditDescription": "Transport options implementation",
        "documentaryEvidence": "Written confirmation that a dedicated transport service will be provided to the site.",
        "responsibility": "Client",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Tra 02",
        "creditDescription": "Transport options implementation",
        "documentaryEvidence": "Written confirmation that public transport information system will be provided in a publicly accessible area, to provide to up-to-date information on available public transport.",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Tra 02",
        "creditDescription": "Transport options implementation",
        "documentaryEvidence": "Written confirmation that electric recharging stations will be provided (of a minimum of 3kW) for at least 10% of the total car parking capacity for the development.",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Tra 02",
        "creditDescription": "Transport options implementation",
        "documentaryEvidence": "For fully-fitted buildings only: Written confirmation that a facility to facilitate and encourage building users to car share will be set up, and marketing and communication materials raising awareness of the sharing scheme with .",
        "responsibility": "Client",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Tra 02",
        "creditDescription": "Transport options implementation",
        "documentaryEvidence": "Correspondence between the design team and local authority (LA) on the state of the local cycling network and public accessible pedestrian routes, AND confirmation that one proposition chosen with the LA will be supported by the development with significant impact on the local cycling network or on pedestrian routes open to the public.",
        "responsibility": "Client",
        "timeCritical": "Preparation and Brief",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Tra 02",
        "creditDescription": "Transport options implementation",
        "documentaryEvidence": "Written confirmation that BREEAM compliant cycle storage will be installed",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Tra 02",
        "creditDescription": "Transport options implementation",
        "documentaryEvidence": "Written confirmation that at least two compliant cyclist facilities will be provided (showers, changing space, lockers, drying space)",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Tra 02",
        "creditDescription": "Transport options implementation",
        "documentaryEvidence": "Evidence of existing and new amenities where relevant.",
        "responsibility": "Eight Versa",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Tra 02",
        "creditDescription": "Transport options implementation",
        "documentaryEvidence": "Drawing plan confirming specification of compliant sustainable transport measures (where applicable):\na) Location of new bus stop(s)\nb) 10% electric recharging car parking spaces\nc) 5% marked-up car share spaces located nearest the development entrance \nd) Location and number of cycle storage spaces\ne) Location and number of cyclist facilities (showers, changing space, lockers, drying space - including heating / ventilation provision)",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Tra 02",
        "creditDescription": "Transport options implementation",
        "documentaryEvidence": "Information Required",
        "responsibility": "",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Wat 01",
        "creditDescription": "Water consumption",
        "documentaryEvidence": "Written Confirmation / Technical specification of sanitary fittings and controls to be installed. Including (where applicable) specification of: WCs, washbasin taps; urinals; kitchen taps; showers; bath; dishwasher; washing machines.",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Wat 01",
        "creditDescription": "Water consumption",
        "documentaryEvidence": "Drawing to show location and specification of sanitaryware",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Wat 02",
        "creditDescription": "Water monitoring ",
        "documentaryEvidence": "Compliance letter confirming a pulsed water meter will be fitted on the mains supply to the building",
        "responsibility": "Contractor",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Wat 02",
        "creditDescription": "Water monitoring ",
        "documentaryEvidence": "Compliance letter clarifying that where there are water consuming areas that consume 10% or more of the building's water demand, these are separately sub-metered",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Wat 02",
        "creditDescription": "Water monitoring ",
        "documentaryEvidence": "Design drawing to confirm the water meter is on the mains supply to the assessed building",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Wat 02",
        "creditDescription": "Water monitoring ",
        "documentaryEvidence": "Compliance letter confirming separate sub-meters will be fitted on the swimming pool water supply and any associated changing facilities (toilets, showers etc.) ",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Wat 02",
        "creditDescription": "Water monitoring ",
        "documentaryEvidence": "Compliance letter confirming a separate water meter will be fitted on the water supply to any process or cooling loop for ‘plumbed-in’ laboratory process equipment",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Wat 02",
        "creditDescription": "Water monitoring ",
        "documentaryEvidence": "Written confirmation of the water monitoring strategy/Copy of the post occupancy evaluation contract, outlining how this will enable the identification of all water consumption for sanitary uses.",
        "responsibility": "Client",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Wat 03",
        "creditDescription": "Leak detection system",
        "documentaryEvidence": "Compliance letter confirming a BREEAM compliant leak detection system will be specified",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Wat 03",
        "creditDescription": "Leak detection system",
        "documentaryEvidence": "Drawings to confirm a leak detection system has been installed on the mains water supply within the building and also between the building and the utilities water meter. ",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Wat 03",
        "creditDescription": "Flow control devices",
        "documentaryEvidence": "Compliance letter confirming a BREEAM compliant sanitary supply shut-off system will be specified",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Wat 03",
        "creditDescription": "Flow control devices",
        "documentaryEvidence": "Drawings to confirm the location of flow control device fitted to each WC area ",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Wat 04",
        "creditDescription": "Water efficient equipment ",
        "documentaryEvidence": "Compliance letter confirming that no irrigation systems are specified, and therefore there will be no unregulated water demands for the building",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Wat 04",
        "creditDescription": "Water efficient equipment ",
        "documentaryEvidence": "Compliance letter confirming the irrigation strategy",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Mat 01",
        "creditDescription": "Superstructure",
        "documentaryEvidence": "Design drawings, including elevations and build-ups detailing the build up of all major materials within the superstructure of the building.",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Mat 01",
        "creditDescription": "Superstructure",
        "documentaryEvidence": "A completed copy of the 'LCA DETAILS' document OR Mat 01 Proforma Table if the simplified approach is to be undertaken (to be provided by Eight Versa).",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Mat 01",
        "creditDescription": "Superstructure",
        "documentaryEvidence": "A copy of the LCA report including 3 - 4 option appraisals at Concept  Design stage.",
        "responsibility": "Specialist Consultant",
        "timeCritical": "Must be submitted before planning",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Mat 01",
        "creditDescription": "Superstructure",
        "documentaryEvidence": "A copy of the LCA report including 3 option appraisals at Technical Design stage",
        "responsibility": "Specialist Consultant",
        "timeCritical": "Before end of Technical Design",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Mat 01",
        "creditDescription": "Substructure and hard landscaping options appraisal during Concept Design",
        "documentaryEvidence": "Drawings including build-ups detailing the build up of all major materials within the substructure of the building and hard landscaping elements of the development.",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Mat 01",
        "creditDescription": "Substructure and hard landscaping options appraisal during Concept Design",
        "documentaryEvidence": "A completed copy of the 'LCA DETAILS' document including detail of substructure and hard landscaping",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Mat 01",
        "creditDescription": "Substructure and hard landscaping options appraisal during Concept Design",
        "documentaryEvidence": "A copy of the LCA report including 3 option appraisals at concept design stage",
        "responsibility": "Specialist Consultant",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Mat 02",
        "creditDescription": "Specification of products with a recognised EPD",
        "documentaryEvidence": "Contractor requirements document confirming that at least 15 products with recognised EPDs will be procured for the development.",
        "responsibility": "Contractor",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Mat 03",
        "creditDescription": "Prerequisite - Legally harvested & traded timber",
        "documentaryEvidence": "Compliance letter / Contractor Requirements confirming all timber procured will be legally harvested and traded timber",
        "responsibility": "Contractor",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Mat 03",
        "creditDescription": "Enabling sustainable procurement",
        "documentaryEvidence": "A copy of the sustainable procurement plan for the development or at a company operational level (developer or architect), produced at concept design stage. The plan must include sustainability aims, objectives and strategic targets to guide procurement activities.",
        "responsibility": "Architect",
        "timeCritical": "Before end of Concept Design",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Mat 03",
        "creditDescription": "Measuring responsible sourcing",
        "documentaryEvidence": "Drawings to show the specification of the build up of the relevant building elements (see MAT03 Table)",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Mat 03",
        "creditDescription": "Measuring responsible sourcing",
        "documentaryEvidence": "Completed MAT03 Table, including manufacturer details for each material specified and their corresponding volume (in m3) or mass (in kg) and where possible",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Mat 03",
        "creditDescription": "Measuring responsible sourcing",
        "documentaryEvidence": "A copy of suppliers responsible sourcing certification (e.g. ISO14001, BES6001, FSC) where available OR",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Mat 03",
        "creditDescription": "Measuring responsible sourcing",
        "documentaryEvidence": "Compliance Letter committing to procure from suppliers who can provide the relevant responsible sourcing certification for each material type (e.g. ISO14001, BES6001, FSC)",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Mat 04",
        "creditDescription": "Not applicable in BREEAM 2018",
        "documentaryEvidence": "",
        "responsibility": "",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Mat 05",
        "creditDescription": "Design for durability and resilience",
        "documentaryEvidence": "Design drawings marked up to show vulnerable areas of the building that may be susceptible to damage, including:\n- Areas of high user numbers - i.e. main entrances, circulation spaces\n- Areas of internal transport movement - i.e. trolley movement\n- External walls within 1m of vehicular movement",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Mat 05",
        "creditDescription": "Design for durability and resilience",
        "documentaryEvidence": "Design drawings / specification outlining the measures taken to enhance durability, such as:\n- Bollards / barriers / raised kerbs in delivery areas\n- Robust external wall construction\n- Protection rails to internal walls\n- Kickplates on doors\n- Hard wearing floors",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Mat 05",
        "creditDescription": "Design for durability and resilience",
        "documentaryEvidence": "Written confirmation or specifications confirming elements have been designed to limit long and short term material degradation through confirming that elements or products achieve an appropriate quality or durability standard.",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Mat 05",
        "creditDescription": "Design for durability and resilience",
        "documentaryEvidence": "Marked up drawing(s), or maintenance plan confirming access to the roof and facade for cost-effective cleaning, replacement and repair in the building's design.",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Mat 05",
        "creditDescription": "Design for durability and resilience",
        "documentaryEvidence": "Marked up drawing highlighting features of the roof and facade design that prevent water damage, ingress and detrimental ponding.",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Mat 05",
        "creditDescription": "Design for durability and resilience",
        "documentaryEvidence": "Risk assessment showing the design team considered the drainage mechanisms of the façade and roof on a small and large scale to prevent staining, detrimental oxidation, ponding, rot, ingress, penetration or any other deleterious effect as a result of water ingress or ponding.",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Mat 06",
        "creditDescription": "Material efficiency",
        "documentaryEvidence": "Report (at Preparation and Brief stage) setting targets relating to material efficiency and report on opportunities and methods to optimise the use of materials at each key stage of the project",
        "responsibility": "Architect",
        "timeCritical": "Preparation and Brief",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Mat 06",
        "creditDescription": "Material efficiency",
        "documentaryEvidence": "Commitment to implement the targets outlined in the above report, plus develop and record the implementation of materials efficiency at developed design, technical design and construction.",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Wst 01",
        "creditDescription": "Pre-demolition audit",
        "documentaryEvidence": "Confirmation that a pre-demolition audit will be carried out using an appropriate methodology. \n\nThis must show the recovery of demolition material for subsequent high grade or value applications. The audit must cover the content of Pre-demolition audit scope and:\na: Be carried out at Concept Design stage prior to strip-out or demolition works\nb: Guide the design, consider materials for reuse and set targets for waste management\nc: Engage all contractors in the process of maximising high grade reuse and recycling opportunities\nd: Compare actual waste arisings and waste management routes used with those forecast and investigate significant deviations from planned targets.",
        "responsibility": "Contractor",
        "timeCritical": "Before end of Concept Design",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Wst 01",
        "creditDescription": "Construction resource efficiency",
        "documentaryEvidence": "Compliance letter confirming a compliant Site Waste Management Plan will be produced containing the appropriate benchmarks, commitments and procedures, and that data records will be kept on waste arisings and waste management routes.",
        "responsibility": "Contractor",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Wst 01",
        "creditDescription": "Construction resource efficiency",
        "documentaryEvidence": "Confirmation that less than 13.3 m3 or 11.1 tonnes of waste will generated per 100m2 of floor area",
        "responsibility": "Contractor",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Wst 01",
        "creditDescription": "Construction resource efficiency",
        "documentaryEvidence": "Confirmation that less than 7.5 m3 or 6.5 tonnes of waste will generated per 100m2 of floor area",
        "responsibility": "Contractor",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Wst 01",
        "creditDescription": "Construction resource efficiency",
        "documentaryEvidence": "Confirmation that less than 3.4 m3 or 3.2 tonnes of waste will generated per 100m2 of floor area",
        "responsibility": "Contractor",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Wst 01",
        "creditDescription": "Diversion of resources from landfill",
        "documentaryEvidence": "Confirmation that the non-hazardous construction waste will meet the following diversion from landfill benchmarks;. Non-demolition 70% by volume or 80% by tonnage, demolition waste 80% by volume or 90% by tonnage.",
        "responsibility": "Contractor",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Wst 02",
        "creditDescription": "Pre-demolition audit",
        "documentaryEvidence": "Compliance letter confirming that a pre-demolition audit will be undertaken for any existing buildings, structures, or hard surfaces on site",
        "responsibility": "Contractor",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Wst 02",
        "creditDescription": "Project sustainable aggregate points",
        "documentaryEvidence": "Compliance letter confirming that the aggregate for each relevant building element will be specified in line with the BREEAM requirements",
        "responsibility": "Contractor",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Wst 02",
        "creditDescription": "Project sustainable aggregate points",
        "documentaryEvidence": "Completed Wst02 Table",
        "responsibility": "Contractor",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Wst 03",
        "creditDescription": "Operational waste ",
        "documentaryEvidence": "Annotated drawing to show both general and recyclable waste storage areas and that the latter will be at least 2m2 per 1000m2 of net floor area and located within 20m of the development",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Wst 03",
        "creditDescription": "Operational waste ",
        "documentaryEvidence": "Compliance Letter confirming the recycle storage will be BREEAM compliant: adequately sized and labelled 'recycling'",
        "responsibility": "Contractor",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Wst 03",
        "creditDescription": "Operational waste ",
        "documentaryEvidence": "Confirmation that a compliant Baler / Waste Compactor / Composting facilities has been specified",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Wst 03",
        "creditDescription": "Operational waste ",
        "documentaryEvidence": "Confirmation that each dwelling has adequate internal waste storage",
        "responsibility": "Contractor",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Wst 03",
        "creditDescription": "Operational waste ",
        "documentaryEvidence": "Confirmation that a school/college recycling policy is in place",
        "responsibility": "Client",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Wst 04",
        "creditDescription": "Speculative floor and ceiling finishes",
        "documentaryEvidence": "Confirmation that (for tenanted areas where the future occupant is not known), prior to full fit-out works, carpets, other floor finishes and ceiling finishes have been installed in a show area only.\nConfirmation that (in a building developed for a specific occupant) the future occupant has selected (or agreed to) the specified floor and ceiling finishes.",
        "responsibility": "Client",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Wst 05",
        "creditDescription": "Resilience of structure, fabric, building services and renewables installation",
        "documentaryEvidence": "A copy of the systematic (structural and fabric resilience specific) risk assessment to identify and evaluate the impact on the building over its projected life cycle from expected extreme weather conditions arising from climate change and, where feasible, mitigate against these impacts. The assessment must cover the following:\n - Hazard identification\n - Hazard assessment\n - Risk estimation\n - Risk evaluation\n - Risk management.",
        "responsibility": "Specialist Consultant",
        "timeCritical": "Preparation and Brief",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Wst 05",
        "creditDescription": "Resilience of structure, fabric, building services and renewables installation",
        "documentaryEvidence": "Design drawings demonstrating the structure and fabric are adequately protected from the risks of a future climate ",
        "responsibility": "Architect",
        "timeCritical": "During Concept Design and updated at Technical Design",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Wst 06",
        "creditDescription": "Design for disassembly and functional adaptability - recommendations",
        "documentaryEvidence": "A building-specific functional adaptation strategy study to explore the ease of disassembly and the functional adaptation potential of different design\nscenarios by the end of Concept Design.",
        "responsibility": "Architect",
        "timeCritical": "Preparation and Brief",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Wst 06",
        "creditDescription": "Design for disassembly and functional adaptability - recommendations",
        "documentaryEvidence": "Drawings or specification confirming potential for future adaptation of the building's function",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Wst 06",
        "creditDescription": "Disassembly and functional adaptability – implementation",
        "documentaryEvidence": "Update, during Technical Design, on:\n4.a How the recommendations or solutions proposed by Concept Design have been implemented where\npractical and cost effective. Omissions have been justified in writing to the assessor.\n4.b Changes to the recommendations and solutions during the development of the Technical Design.",
        "responsibility": "Architect",
        "timeCritical": "During Technical Design",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Wst 06",
        "creditDescription": "Disassembly and functional adaptability – implementation",
        "documentaryEvidence": "Confirmation that a building adaptability and disassembly guide will be produced",
        "responsibility": "Contractor",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Wst 06",
        "creditDescription": "Disassembly and functional adaptability – implementation",
        "documentaryEvidence": "Confirmation that the building adaptability and disassembly guide will be supplied to prospective tenants  to communicate the characteristics allowing functional adaptability and disassembly.",
        "responsibility": "Client",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "LE 01",
        "creditDescription": "Previously Occupied Land",
        "documentaryEvidence": "Site plan of the proposed construction zone, including all areas of hard standing, landscaping, site access and temporary site offices",
        "responsibility": "Architect",
        "timeCritical": "Before end of Concept Design",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "LE 01",
        "creditDescription": "Previously Occupied Land",
        "documentaryEvidence": "Site plan showing previous development covers at least 75% of the proposed development's footprint",
        "responsibility": "Architect",
        "timeCritical": "Before end of Concept Design",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "LE 01",
        "creditDescription": "Contaminated Land",
        "documentaryEvidence": "A copy of the specialist's land contamination report ",
        "responsibility": "Specialist Consultant",
        "timeCritical": "Before end of Concept Design",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "LE 01",
        "creditDescription": "Contaminated Land",
        "documentaryEvidence": "Design drawings (including existing site plan) showing contaminated areas and areas to be remediated in relation to any proposed development",
        "responsibility": "Specialist Consultant",
        "timeCritical": "Before end of Concept Design",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "LE 01",
        "creditDescription": "Contaminated Land",
        "documentaryEvidence": "A copy of the specialist's remediation report / strategy",
        "responsibility": "Specialist Consultant",
        "timeCritical": "Before end of Concept Design",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "LE 02",
        "creditDescription": "Prerequisite",
        "documentaryEvidence": "Copy of BREEAM Ecological Risk Evaluation Checklist confirming Route selected",
        "responsibility": "Architect",
        "timeCritical": "Preparation and Brief",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "LE 02",
        "creditDescription": "Prerequisite",
        "documentaryEvidence": "Confirmation that compliance is being monitored against all relevant UK and EU or international legislation relating to the ecology of the site.",
        "responsibility": "Contractor",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "LE 02",
        "creditDescription": "Survey and evaluation and Determining the ecological outcomes for the site (route 1)",
        "documentaryEvidence": "Copy of BREEAM Ecological Risk Evaluation Checklist confirming no ecological features are to be impacted by the scheme",
        "responsibility": "Architect",
        "timeCritical": "Preparation and Brief",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "LE 02",
        "creditDescription": "Survey and evaluation and Determining the ecological outcomes for the site (route 1)",
        "documentaryEvidence": "Assessment of the site's existing ecology and identification of ecological outcomes for the site in line with GN35 ",
        "responsibility": "Architect",
        "timeCritical": "Preparation and Brief",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "LE 02",
        "creditDescription": "Survey and evaluation and Determining the ecological outcomes for the site (route 1)",
        "documentaryEvidence": "Existing site plan showing any ecological features on the site",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "LE 02",
        "creditDescription": "Survey and evaluation and Determining the ecological outcomes for the site (route 2)",
        "documentaryEvidence": "Confirmation that a Suitably Qualified Ecologist was appointed at an early stage in the project's design",
        "responsibility": "Architect",
        "timeCritical": "Preparation and Brief",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "LE 02",
        "creditDescription": "Survey and evaluation and Determining the ecological outcomes for the site (route 2)",
        "documentaryEvidence": "Ecology report confirming habitats and priority species potential on the site, and identification of ecological constraints and opportunities",
        "responsibility": "Ecologist",
        "timeCritical": "Preparation and Brief",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "LE 02",
        "creditDescription": "Survey and evaluation and Determining the ecological outcomes for the site (route 2)",
        "documentaryEvidence": "Details of the site's ecological outcomes, following the following hierarchy of action;\nAvoidance\nProtection\nReduction or limitation of negative impacts\nOn site compensation and\nEnhancement (on or off site)",
        "responsibility": "Ecologist",
        "timeCritical": "Preparation and Brief",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "LE 03",
        "creditDescription": "Prerequisite - Achieved LE 02",
        "documentaryEvidence": "Prerequisite - Achieved LE 02",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "LE 03",
        "creditDescription": "Planning, liaison, implementation and data (route 1)",
        "documentaryEvidence": "Design drawings showing the retention/protection/removal of any ecological features on the site",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "LE 03",
        "creditDescription": "Planning, liaison, implementation and data (route 1)",
        "documentaryEvidence": "Confirmation that the design team have discussed and implemented plans for the existing ecological features' retention/protection/removal",
        "responsibility": "Architect",
        "timeCritical": "Before end of Concept Design",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "LE 03",
        "creditDescription": "Planning, liaison, implementation and data (route 2)",
        "documentaryEvidence": "Design drawings showing the retention/protection/removal of any ecological features on the site",
        "responsibility": "Ecologist",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "LE 03",
        "creditDescription": "Planning, liaison, implementation and data (route 2)",
        "documentaryEvidence": "Ecology report confirming the retention/protection/removal of any ecological features on the site",
        "responsibility": "Ecologist",
        "timeCritical": "Preparation and Brief",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "LE 03",
        "creditDescription": "Planning, liaison, implementation and data (route 2)",
        "documentaryEvidence": "Evidence e.g. emails confirming collaboration between ecologist and design team regarding the retention/protection/removal of ecological features",
        "responsibility": "Architect",
        "timeCritical": "Before end of Concept Design",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "LE 03",
        "creditDescription": "Managing negative impacts of the project (route 1)",
        "documentaryEvidence": "Confirmation that negative impacts from site preparation and construction works will be managed according to the mitigation hierarchy, and no overall loss of ecological value has occurred.",
        "responsibility": "Architect",
        "timeCritical": "Before end of Concept Design",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "LE 03",
        "creditDescription": "Managing negative impacts of the project (route 2)",
        "documentaryEvidence": "Confirmation from ecologist that negative impacts from site preparation and construction works will be managed according to the mitigation hierarchy, and the loss of ecological value has been minimised",
        "responsibility": "Ecologist",
        "timeCritical": "Before end of Concept Design",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "LE 03",
        "creditDescription": "Managing negative impacts of the project (route 2)",
        "documentaryEvidence": "Confirmation from ecologist that negative impacts from site preparation and construction works will be managed according to the mitigation hierarchy, and no overall loss of ecological value has occurred",
        "responsibility": "Ecologist",
        "timeCritical": "Before end of Concept Design",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "LE 04",
        "creditDescription": "Prerequisite - Achieved LE 03",
        "documentaryEvidence": "LE 03 'Planning, liaison, implementation and data' has been achieved",
        "responsibility": "-",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "LE 04",
        "creditDescription": "Ecological enhancement (route 2 only)",
        "documentaryEvidence": "Ecology report confirming that locally relevant ecological features have been incorporated into the scheme, with reference to local biodiversity targets ",
        "responsibility": "Ecologist",
        "timeCritical": "Preparation and Brief",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "LE 04",
        "creditDescription": "Ecological enhancement (route 2 only)",
        "documentaryEvidence": "Design drawings showing locally relevant incorporated ecological features",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "LE 04",
        "creditDescription": "Ecological enhancement (route 2 only)",
        "documentaryEvidence": "Confirmation that the recommended ecological features will be installed",
        "responsibility": "Contractor",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "LE 04",
        "creditDescription": "Ecological enhancement (route 2 only)",
        "documentaryEvidence": "Confirmation from ecologist that data collated has been provided to the local environmental records centre",
        "responsibility": "Ecologist",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "LE 04",
        "creditDescription": "Ecological enhancement (route 2 only)",
        "documentaryEvidence": "Evidence e.g. emails confirming collaboration between ecologist and design team regarding the ecological features",
        "responsibility": "Architect",
        "timeCritical": "Preparation and Brief",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "LE 04",
        "creditDescription": "Change and enhancement of ecology (route 1)",
        "documentaryEvidence": "Confirmation that locally relevant ecological features have been incorporated into the scheme, with reference to local biodiversity targets ",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "LE 04",
        "creditDescription": "Change and enhancement of ecology (route 1)",
        "documentaryEvidence": "Design drawings showing locally relevant incorporated ecological features",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "LE 04",
        "creditDescription": "Change and enhancement of ecology (route 2)",
        "documentaryEvidence": "Calculation in accordance with the process set out in GN36 - BREEAM, CEEQUAL and HQM Ecology Calculation Methodology",
        "responsibility": "Ecologist",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "LE 05",
        "creditDescription": "Prerequisite (route 1)",
        "documentaryEvidence": "LE 03 'Planning, liaison, implementation and data' has been achieved",
        "responsibility": "Project Manager",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "LE 05",
        "creditDescription": "Prerequisite (route 2)",
        "documentaryEvidence": "LE 03 'Planning, liaison, implementation and data' and 1 credit for LE 04 'Enhancement of Ecology' has been achieved",
        "responsibility": "Project Manager",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "LE 05",
        "creditDescription": "Management and maintenance - Landscape and ecology management plan (or similar) development (route 1)",
        "documentaryEvidence": "Confirmation that the design team have discussed the ecological features' future maintenance",
        "responsibility": "Client",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "LE 05",
        "creditDescription": "Management and maintenance - Landscape and ecology management plan (or similar) development (route 1)",
        "documentaryEvidence": "Confirmation that the end user of the building will continue to maintain the site's ecological features",
        "responsibility": "Client",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "LE 05",
        "creditDescription": "Management and maintenance - Landscape and ecology management plan (or similar) development (route 1)",
        "documentaryEvidence": "Confirmation that the Building User Guide will include a section on Ecology and Biodiversity",
        "responsibility": "Client",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "LE 05",
        "creditDescription": "Management and maintenance - Landscape and ecology management plan (or similar) development (route 1)",
        "documentaryEvidence": "Landscape and ecology management plan, or equivalent, is developed in accordance with BS 42020:2013 Section 11.1 (if available at this stage)",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "LE 05",
        "creditDescription": "Management and maintenance - Landscape and ecology management plan (or similar) development (route 1)",
        "documentaryEvidence": "Confirmation that the management plan will be updated as appropriate to support maintenance of the ecological value of the site",
        "responsibility": "Client",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "LE 05",
        "creditDescription": "Management and maintenance (route 2)",
        "documentaryEvidence": "Confirmation that the ecologist has liaised with design team with regards to the ecological features' future maintenance",
        "responsibility": "Client",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "LE 05",
        "creditDescription": "Management and maintenance (route 2)",
        "documentaryEvidence": "Confirmation that the end user of the building will continue to maintain the site's ecological features",
        "responsibility": "Client",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "LE 05",
        "creditDescription": "Management and maintenance (route 2)",
        "documentaryEvidence": "Confirmation that the Building User Guide will include a section on Ecology and Biodiversity",
        "responsibility": "Client",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "LE 05",
        "creditDescription": "Landscape and ecology management plan (or similar) development (route 2)",
        "documentaryEvidence": "Landscape and ecology management plan, or equivalent, is developed in accordance with BS 42020:2013 Section 11.1 (if available at this stage)",
        "responsibility": "Ecologist",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "LE 05",
        "creditDescription": "Landscape and ecology management plan (or similar) development (route 2)",
        "documentaryEvidence": "Confirmation that the management plan will be updated as appropriate to support maintenance of the ecological value of the site",
        "responsibility": "Client",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Pol 01",
        "creditDescription": "No Refrigerants",
        "documentaryEvidence": "Written statement confirming that there will be no refrigerant use within the installed plant or systems.",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Pol 01",
        "creditDescription": "Prerequisite - BS EN 378:2016 (ammonia)",
        "documentaryEvidence": "Written statement confirming that all systems with electric compressors will comply with the requirements of BS EN 378:2016 (Parts 2 and 3).",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Pol 01",
        "creditDescription": "Impact of refrigerant",
        "documentaryEvidence": "Written confirmation / specification confirming the refrigeration type and system, including:\n1. Global Warming Potential (GWP) of the specified system refrigerant(s)\n2. Total refrigerant charge (kg)\n3. Heating / cooling capacity (kW)",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Pol 01",
        "creditDescription": "Impact of refrigerant",
        "documentaryEvidence": "Design drawings confirming the type and location of systems using refrigerants within the development.",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Pol 01",
        "creditDescription": "Impact of refrigerant",
        "documentaryEvidence": "A copy of the completed BREEAM Pol 01 Calculator, confirming the number of credits achieved.",
        "responsibility": "Eight Versa",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Pol 01",
        "creditDescription": "Leak detection",
        "documentaryEvidence": "Written confirmation / specification confirming that all systems are hermetically sealed / only use environmentally benign refrigerants, OR refrigerant leak detection has been installed (clarifying the type of system, scope and that the containment strategy is capable of automatically responding and managing the remaining refrigerant charge to limit loss of refrigerant).",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Pol 01",
        "creditDescription": "Leak detection",
        "documentaryEvidence": "Design drawings confirming the type and location of refrigerant leak detection systems (if present).",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Pol 02",
        "creditDescription": "Local air quality",
        "documentaryEvidence": "Written confirmation / specification confirming that all space and water heating will be supplied by non-combustion systems (i.e. only powered by electricity), OR\nconfirmation of the make and model of the system (boiler, CHP).",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Pol 02",
        "creditDescription": "Local air quality",
        "documentaryEvidence": "A copy of the Defra map confirming whether the site is in a high or low pollution location.",
        "responsibility": "Eight Versa",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Pol 02",
        "creditDescription": "Local air quality",
        "documentaryEvidence": "Written confirmation / specification confirming details for the specified combustion system(s) (both existing and new if applicable) confirming the NOx emissions (mg/kWh) and PM10/VOC emissions (mg/m3), where applicable, for space and water heating.",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Pol 03",
        "creditDescription": "Prerequisite",
        "documentaryEvidence": "Written confirmation that an appropriate consultant has been appointed to carry out and develop the development's compliance with all flood and surface water management criteria.",
        "responsibility": "Project Manager",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Pol 03",
        "creditDescription": "Flood resilience",
        "documentaryEvidence": "Site-specific Flood Risk Assessment (FRA) report (considering all current and future sources of flooding) confirming the development is in a flood zone defined as having a low annual probability of flooding",
        "responsibility": "Civil Engineer",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Pol 03",
        "creditDescription": "Flood resilience",
        "documentaryEvidence": "Site plans/sections (for medium or high annual probability) confirming:\n- The design ground levels (for the development and site) compared to design flood levels; AND\n- Safe access routes to the development.",
        "responsibility": "Civil Engineer",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Pol 03",
        "creditDescription": "Surface water run-off rate",
        "documentaryEvidence": "Pre-requisite: statement from the appropriate consultant to confirm that bespoke surface water runoff design solutions have been developed for the site (accounting for specific site requirements and conditions).",
        "responsibility": "Civil Engineer",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Pol 03",
        "creditDescription": "Surface water run-off rate",
        "documentaryEvidence": "A copy of the drainage consultant's report confirming: \n- Total area of impermeable surfaces on the pre-development and post-development site (m2).\n- Calculation results for pre- and post-development peak runoff rates.\n- Calculation results for pre- and post-development runoff volumes as per with The SuDS Manual.\n- ( brownfield sites) peak rate of runoff from the site will be improved by 30% from pre-development (for the 1 in 1 year and 1 in 100 year event); OR\n- (For greenfield sites)  peak rate of runoff from the site will be no greater than it was ;\n- additional allowance for climate change",
        "responsibility": "Civil Engineer",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Pol 03",
        "creditDescription": "Surface water run-off rate",
        "documentaryEvidence": "EITHER compliance letter from the client OR section from the drainage consultant's report confirming that there will be relevant maintenance agreements for the ownership, long-term operation and maintenance of all specified Sustainable Drainage Systems (SuDS).",
        "responsibility": "Client",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Pol 03",
        "creditDescription": "Surface water run-off volume",
        "documentaryEvidence": "A copy of the drainage consultant's report confirming that flooding of property will not occur in the event of the local drainage system failure AND EITHER:\n- The post-development runoff volume will be no greater than pre-development runoff volume (managed by SuDS); OR\n- The consultant provides robust justification why the above criteria cannot be achieved (i.e. infiltration etc. are not technically viable) AND the post-development discharge rates can be limited to the highest of the following; pre-development 1 in 1 year peak runoff rate OR the mean annual runoff rate (Qbar) OR 2 litres/second/hectare.",
        "responsibility": "Civil Engineer",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Pol 03",
        "creditDescription": "Minimum watercourse pollution",
        "documentaryEvidence": "A copy of the drainage consultant's report confirming that there will be no discharge from the post-development site for rainfall events up to 5mm.",
        "responsibility": "Civil Engineer",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Pol 03",
        "creditDescription": "Minimum watercourse pollution",
        "documentaryEvidence": "Marked-up proposed site plan confirming the areas which may have a high risk (i.e. car parks, roadways, vehicle manoeuvring, plant areas) to watercourse pollution OR confirmation that there are no high risk areas.",
        "responsibility": "Civil Engineer",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Pol 03",
        "creditDescription": "Minimum watercourse pollution",
        "documentaryEvidence": "Confirmation that oil/petrol separators (or an equivalent system for pollution treatment) are specified for all high risk areas, in accordance with The SuDS Manual and designed in a bespoke manner for the site.",
        "responsibility": "Civil Engineer",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Pol 03",
        "creditDescription": "Minimum watercourse pollution",
        "documentaryEvidence": "Confirmation that:\n- Water pollution prevention systems have been designed in line with The SuDS Manual.\n- A comprehensive drainage plan will be produced and handed over to the building occupier.\n- All external storage and delivery areas will be designed in compliance with the relevant pollution prevention guidance.",
        "responsibility": "Civil Engineer",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Pol 03",
        "creditDescription": "Minimum watercourse pollution",
        "documentaryEvidence": "Compliance letter confirming that the drainage plan will be handed over to the building occupier",
        "responsibility": "Civil Engineer",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Pol 04",
        "creditDescription": "Reduction of night time light pollution",
        "documentaryEvidence": "Written confirmation / specification confirming EITHER:\n- There will be no external lighting included in the development; OR\n- External lighting design is in compliance with Table 2 of the ILP Guidance Notes (2011) and can be automatically switched off between 23:00 and 07:00 (or safety/security lighting on during the night complies with Table 2 of the ILP Guidance Notes (2011). Any illuminated advertisements (where present) are designed in compliance with ILP PLG05 (2015).",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Pol 04",
        "creditDescription": "Reduction of night time light pollution",
        "documentaryEvidence": "A marked up copy of the site plan showing areas of the building and site that will be externally lit AND any nearby properties that may be affected.",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Pol 05",
        "creditDescription": "Reduction of noise pollution",
        "documentaryEvidence": "A drawing showing any noise sensitive buildings within 800m of the development and proposed noise sources from the new development.",
        "responsibility": "Specialist Consultant",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Pol 05",
        "creditDescription": "Reduction of noise pollution",
        "documentaryEvidence": "Written confirmation that a Noise Impact Assessment has been commissioned and will be carried out by a suitably qualified acoustic consultant, in compliance with BS 4142:2014; AND\n- If the Noise Impact Assessment concludes that noise levels are above 5dB lower than the background noise throughout the day and night, measures will be installed to attenuate the noise at its source to a compliant level.",
        "responsibility": "Project Manager",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Pol 05",
        "creditDescription": "Reduction of noise pollution",
        "documentaryEvidence": "A copy of the Noise Impact Assessment, in line with BS 4142:2014, confirming:\n- Existing background noise levels (at nearest/most exposed noise sensitive area to the development and including existing plant on a building, where the development is an extension) and expected noise rating level from the assessed buildings proposed plant;\n- Confirmation of whether noise levels at the nearest/most exposed noise sensitive area to the development are higher or lower than 5dB lower than the background noise levels;\n- (Where the 5dB lower than background is not achieved) specification of attenuation measures to attenuate the noise at its source to a compliant level.",
        "responsibility": "Specialist Consultant",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Inn 01",
        "creditDescription": "Man 03 - Responsible construction management",
        "documentaryEvidence": "Achieve all items in Table 4.1 on page 51.",
        "responsibility": "Contractor",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Inn 02",
        "creditDescription": "Hea 01 - Daylighting",
        "documentaryEvidence": "Copy of the daylighting report",
        "responsibility": "Specialist Consultant",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Inn 03",
        "creditDescription": "Hea 01 - Internal and external lighting levels, zoning and control",
        "documentaryEvidence": "Written confirmation that lighting in each zone can be manually dimmed by occupants down to 20% of the maximum light output (using local dimmer switches / control)",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Inn 04",
        "creditDescription": "Hea 02 - Minimising sources of air pollution - Emissions from construction products",
        "documentaryEvidence": "Written confirmation that Exemplary Level VOC (volatile organic compounds) requirements will be met for at least three of the following product types:\n1. Interior paint and coatings\n2. Wood-based products (including timber floor)\n3. Flooring materials (including floor levelling compounds and resin flooring)\n4. Ceiling, wall, and acoustic and thermal insulation materials\n5. Interior adhesives and sealants (including floor adhesives)",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Inn 05",
        "creditDescription": "Hea 06 - Security of site and building",
        "documentaryEvidence": "A compliant risk based security rating scheme has been used.",
        "responsibility": "Specialist Consultant",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Inn 06",
        "creditDescription": "Ene 01 - Beyond zero net regulated carbon",
        "documentaryEvidence": "A copy of the 2013 Part L Approved Document checks (BRUKL Output Document) demonstrating an EPRNC>0.9 and zero net regulated CO2.eq emissions",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Inn 07",
        "creditDescription": "Ene 01 - Carbon negative",
        "documentaryEvidence": "The building is deemed carbon negative where > 100% of carbon emissions from regulated and unregulated energy use are offset by energy generated from LZC sources:\n- 1 credit - +10%\n- 2 credits - +50%\n- 3 credits - >+100%",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Inn 07",
        "creditDescription": "Ene 01 - Carbon negative",
        "documentaryEvidence": "Confirmation of:\n- The total carbon neutral energy generation (kWh/yr)\n- The source of the carbon neutral energy\n- Calculated estimate of energy consumption from unregulated systems or process (kWh/year) (IF targeting zero regulated carbon or carbon negative credits)\n- Calculated estimate of exported energy surplus(IF targeting carbon negative status)",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Inn 08",
        "creditDescription": "Ene 01 - Post occupancy stage",
        "documentaryEvidence": "Client commitment to proceed to the post occupancy stage and report the energy consumption",
        "responsibility": "Client",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Inn 09",
        "creditDescription": "Wat 01 - Water consumption",
        "documentaryEvidence": "Written Confirmation / Technical specification confirming 'exemplary level' flow/flush rates for sanitary fittings and controls.",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Inn 10",
        "creditDescription": "Mat 01 - Core building services options appraisal during Concept Design",
        "documentaryEvidence": "Specification outlining detail of all core building services specified for the development.",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Inn 10",
        "creditDescription": "Mat 01 - Core building services options appraisal during Concept Design",
        "documentaryEvidence": "A completed copy of the 'LCA DETAILS' document including detail of core building services.",
        "responsibility": "M&E",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Inn 10",
        "creditDescription": "Mat 01 - Core building services options appraisal during Concept Design",
        "documentaryEvidence": "A copy of the LCA report including a minimum of three significantly different core building services design options",
        "responsibility": "Specialist Consultant",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Inn 11",
        "creditDescription": "Mat 01 - LCA and LCC alignment",
        "documentaryEvidence": "Evidence for Man 02: LCC elemental and LCC component level option appraisals.",
        "responsibility": "Specialist Consultant",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Inn 11",
        "creditDescription": "Mat 01 - LCA and LCC alignment",
        "documentaryEvidence": "A report aligning the LCA and LCC options appraisal activity with an options appraisal summary document including the relevant cost information from the ‘elemental LCC plan’ and ‘Component level LCC option appraisal.",
        "responsibility": "Specialist Consultant",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Inn 11",
        "creditDescription": "Mat 01 - LCA and LCC alignment",
        "documentaryEvidence": "Written confirmation that the LCA and LCC options appraisal activity were used within the wider design decision-making process.",
        "responsibility": "Specialist Consultant",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Inn 12",
        "creditDescription": "Mat 01 - Third party verification",
        "documentaryEvidence": "Report from an independent suitably qualified third party verifying the building LCA work describing how they have checked the building LCA work and it accurately represents the designs under consideration during Concept Design and Technical Design",
        "responsibility": "TBC",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Inn 12",
        "creditDescription": "Mat 01 - Third party verification",
        "documentaryEvidence": "Written confirmation from the suitably qualified third party clarifying they have relevant skills and experience and a declaration of their third party independence from the project client and design team.",
        "responsibility": "TBC",
        "timeCritical": "-",
        "complianceLetter": "Yes",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Inn 13",
        "creditDescription": "Mat 03 - Measuring responsible sourcing",
        "documentaryEvidence": "Exemplary level achieved",
        "responsibility": "Architect",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Inn 14",
        "creditDescription": "Wst 01 - Construction waste management",
        "documentaryEvidence": "Exemplary level achieved",
        "responsibility": "Contractor",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Inn 15",
        "creditDescription": "Wst 02 - Use of recycled and sustainably sourced aggregates",
        "documentaryEvidence": "Exemplary level achieved",
        "responsibility": "Contractor",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Inn 16",
        "creditDescription": "Wst 05 - Responding to climate change",
        "documentaryEvidence": "As Wst 05, plus evidence for Hea 04 (thermal comfort for climate change scenario), Ene 01 (8 credits), Ene 04 (Passive design analysis), Wat 01 (3 credits), Mat 05 and Pol 03 (3 credits).",
        "responsibility": "Eight Versa",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      },
      {
        
        "creditCategoryCode": "Inn 17",
        "creditDescription": "LE 02 - Determine the ecological outcomes for the site (sustainability-related activities)",
        "documentaryEvidence": "Exemplary level achieved",
        "responsibility": "Ecologist",
        "timeCritical": "-",
        "complianceLetter": "-",
"requiredAtStage": "Stage 1"
      }]

    const requiredDocumentaryEvidencePCRStage = [
        {
          "creditCategoryCode": "Man 01",
          "creditDescription": "Project delivery planning",
          "documentaryEvidence": "Updated / final version of the construction programme.",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Man 01",
          "creditDescription": "Project delivery planning",
          "documentaryEvidence": "Site inspection demonstrating design features that are a result of the project delivery consultation process.",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
            "creditCategoryCode": "Man 01",
            "creditDescription": "BREEAM AP (concept design)",
            "documentaryEvidence": "BREEAM AP report Concept Design",
            "responsibility": "BREEAM Accredited Professional",
            "timeCritical": "-",
            "complianceLetter": "-",
            "requiredAtStage": "Stage 1"
          },
        {
          "creditCategoryCode": "Man 01",
          "creditDescription": "BREEAM AP (developed design)",
          "documentaryEvidence": "Final BREEAM report.",
          "responsibility": "BREEAM Accredited Professional",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Man 02",
          "creditDescription": "Elemental LCC",
          "documentaryEvidence": "As built documentation demonstrating how the Elemental Life Cycle Cost plan has influenced the final design of the building.",
          "responsibility": "Architect",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Man 02",
          "creditDescription": "Elemental LCC",
          "documentaryEvidence": "Assessor's site inspection report demonstrating examples of how the recommendations of the LCC analysis have been implemented.",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Man 02",
          "creditDescription": "Component level LCC options appraisal",
          "documentaryEvidence": "Documentation demonstrating that recommendations within the Component LCC plan have been implemented within the final construction.",
          "responsibility": "Architect",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Man 02",
          "creditDescription": "Component level LCC options appraisal",
          "documentaryEvidence": "Assessor's site inspection report confirming that the envelope / services / finishes / external spaces were installed as per the recommendations of the Component LCC plan.",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Man 02",
          "creditDescription": "Capital cost reporting",
          "documentaryEvidence": "Compliance letter confirming the final capital costs for the project in pounds per square metre (£k/m2).",
          "responsibility": "Client",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Man 03",
          "creditDescription": "Prerequisite - Legally harvested & traded timber",
          "documentaryEvidence": "Statement confirming the legal source of all timber used on the site during construction.",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Man 03",
          "creditDescription": "Prerequisite - Legally harvested & traded timber",
          "documentaryEvidence": "Copies of certification (for example, FSC / PEFC) / or other supplier confirmation that all site timber has been legally sourced.",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Man 03",
          "creditDescription": "Environmental management",
          "documentaryEvidence": "Copy of the main contractor(s) ISO 14001 certification.",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Man 03",
          "creditDescription": "Environmental management",
          "documentaryEvidence": "Written confirmation that the pollution prevention procedures have been implemented on site.",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Man 03",
          "creditDescription": "BREEAM AP (site)",
          "documentaryEvidence": "Copy of completed PPG 6 checklist.",
          "responsibility": "-",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Man 03",
          "creditDescription": "BREEAM AP (site)",
          "documentaryEvidence": "BREEAM AP report, confirming site visits and monitoring were carried out during the construction phase.",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Man 03",
          "creditDescription": "Responsible construction management",
          "documentaryEvidence": "Completed Checklist: BREEAM Responsible Construction Management.",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Man 03",
          "creditDescription": "Responsible construction management",
          "documentaryEvidence": "Supporting documentation for the BREEAM Responsible Construction Management requirements (this can include photographic documentation; site layout plans; site procedures documents for minimising pollution, keeping site tidy, etc.)",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Man 03",
          "creditDescription": "Responsible construction management",
          "documentaryEvidence": "Completed Checklist: BREEAM Responsible Construction Management, and final CCS scoring report and certificate (if carried out).",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Man 03",
          "creditDescription": "Responsible construction management",
          "documentaryEvidence": "Supporting documentation for the BREEAM Responsible Construction Management requirements (this can include photographic documentation; site layout plans; site procedures documents; site log books and training schedules).",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Man 03",
          "creditDescription": "Responsible construction management (Exemplary level)",
          "documentaryEvidence": "Completed Checklist: BREEAM Responsible Construction Management.",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Man 03",
          "creditDescription": "Responsible construction management (Exemplary level)",
          "documentaryEvidence": "Supporting documentation for the BREEAM Responsible Construction Management requirements (this can include photographic documentation; site layout plans; site procedures documents; site log books and training schedules).",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Man 03",
          "creditDescription": "Monitoring of construction site impacts - Energy & water",
          "documentaryEvidence": "Written confirmation that responsibility was assigned to an individual(s) for monitoring, recording and reporting energy and water consumption data resulting from all construction processes.",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Man 03",
          "creditDescription": "Monitoring of construction site impacts - Energy & water",
          "documentaryEvidence": "Site monitoring records demonstrating the water and energy consumption of the site throughout the project, in comparison with the targets.",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Man 03",
          "creditDescription": "Transport of construction materials & waste",
          "documentaryEvidence": "Written confirmation that responsibility was assigned to an individual(s) for monitoring, recording and reporting of transport details for all deliveries to the site, and all waste removal from the site.",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Man 03",
          "creditDescription": "Transport of construction materials & waste",
          "documentaryEvidence": "Site monitoring records demonstrating transport movements (in km and litres of fuel), and comparison with targets in terms of:\n-Materials delivered to the site\n-Waste removed from the site (these are to be monitored separately).",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Man 04",
          "creditDescription": "Commissioning - Testing schedule and responsibilities",
          "documentaryEvidence": "Commissioning records and reports in line with CIBSE, BSRIA and Building Regulations guidelines.",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Man 04",
          "creditDescription": "Commissioning - Testing schedule and responsibilities",
          "documentaryEvidence": "Written confirmation of procedures for commissioning of the BMS.",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Man 04",
          "creditDescription": "Commissioning - Testing schedule and responsibilities",
          "documentaryEvidence": "Written confirmation that an appropriate project team member was appointed to monitor and programme pre-commissioning, commissioning and re-commissioning on behalf of the client.",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Man 04",
          "creditDescription": "Commissioning - Testing schedule and responsibilities",
          "documentaryEvidence": "Final commissioning programme.",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Man 04",
          "creditDescription": "Commissioning - Design and preparation",
          "documentaryEvidence": "Written confirmation that a specialist commissioning manager was appointed during the design stage to undertake design reviews and provide advice during construction programming, installation and testing.",
          "responsibility": "Contractor",
          "timeCritical": "Before end of Technical Design - appointment",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Man 04",
          "creditDescription": "Testing and inspecting building fabric",
          "documentaryEvidence": "Copy of the Thermographic Survey and air tightness test reports.",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Man 04",
          "creditDescription": "Testing and inspecting building fabric",
          "documentaryEvidence": "Copy of the Thermographer's Level 2 certificate in thermography.",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Man 04",
          "creditDescription": "Testing and inspecting building fabric",
          "documentaryEvidence": "Confirmation that any remediation works have been carried out in line with recommendations of the Thermographic Survey and Air Tightness testing.",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Man 04",
          "creditDescription": "Handover",
          "documentaryEvidence": "Copies of the two non-technical building user guides:\n1. A non-technical user guide for distribution to the building occupiers\n2. A technical user guide for the premises facilities managers.",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Man 04",
          "creditDescription": "Handover",
          "documentaryEvidence": "Confirmation that the building user guides have been provided to the building occupants.",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Man 04",
          "creditDescription": "Handover",
          "documentaryEvidence": "A copy of the two training schedules:\n1. A non-technical training schedule for the building occupiers.\n2. A technical training schedule for the premises facilities managers.",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Man 05",
          "creditDescription": "Aftercare Support",
          "documentaryEvidence": "Written confirmation that the following will be undertaken post development:\n- Collect the energy and water consumption data for at least 12 months after occupation\n- Compare this with what was expected\n- Analyse any discrepancies with a view of adjusting systems if they are not operating as expected / designed.",
          "responsibility": "Client",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Man 05",
          "creditDescription": "Aftercare Support",
          "documentaryEvidence": "Written confirmation that the following will be undertaken post development:\n- Collect the energy and water consumption data for at least 12 months after occupation\n- Compare this with what was expected\n- Analyse any discrepancies with a view of adjusting systems if they are not operating as expected / designed.",
          "responsibility": "Client",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Man 05",
          "creditDescription": "Commissioning - Implementation",
          "documentaryEvidence": "Written confirmation that seasonal commissioning responsibilities will be completed over a minimum 12 month period, once the building becomes occupied (in line with BREEAM criteria for either complex or simple systems).",
          "responsibility": "Client",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Man 05",
          "creditDescription": "Commissioning - Implementation",
          "documentaryEvidence": "Seasonal Commissioning schedule outlining programme of commissioning for 12 months after practical completion.",
          "responsibility": "Client",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Man 05",
          "creditDescription": "Post Occupancy Evaluation (POE)",
          "documentaryEvidence": "Written commitment to undertake a POE in line with the BREEAM requirements 1 year after building occupation, including commitment of funds for POE, or confirmation of appointment of the independent third party POE consultant.",
          "responsibility": "Client",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Man 05",
          "creditDescription": "Post Occupancy Evaluation (POE)",
          "documentaryEvidence": "Written statement confirming that there will be appropriate dissemination of information on the buildings post occupancy performance.",
          "responsibility": "Client",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Hea 01",
          "creditDescription": "Control of glare from sunlight",
          "documentaryEvidence": "As Built documentation (drawings and  / or specification) demonstrating that the glare control strategy has been installed in line with the recommendations of the report carried out at the design stage.",
          "responsibility": "Specialist Consultant",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Hea 01",
          "creditDescription": "Control of glare from sunlight",
          "documentaryEvidence": "Assessor's site inspection report.",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Hea 01",
          "creditDescription": "Daylighting",
          "documentaryEvidence": "Written confirmation that there have been no changes since the design stage daylighting report was carried out.",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Hea 01",
          "creditDescription": "Daylighting",
          "documentaryEvidence": "OR Updated daylighting calculations report for the constructed building.",
          "responsibility": "Specialist Consultant",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Hea 01",
          "creditDescription": "View out",
          "documentaryEvidence": "Written statement confirming there have been no changes since the design stage layout plans and elevations",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Hea 01",
          "creditDescription": "View out",
          "documentaryEvidence": "OR Updated As Built floor layout plans confirming 95% of the floor area is within 7m of a wall with a window",
          "responsibility": "Architect",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Hea 01",
          "creditDescription": "View out",
          "documentaryEvidence": "Updated As Built elevations and calculations to confirm that windows represent at least 20% of the surrounding wall area for applicable rooms",
          "responsibility": "Architect",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Hea 01",
          "creditDescription": "View out",
          "documentaryEvidence": "Assessor's site inspection report",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Hea 01",
          "creditDescription": "Internal and external lighting levels, zoning and control",
          "documentaryEvidence": "Written confirmation that there have been no changes since the design stage ",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Hea 01",
          "creditDescription": "Internal and external lighting levels, zoning and control",
          "documentaryEvidence": "Assessor's site inspection report confirming zoning and local occupant controls",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Hea 02",
          "creditDescription": "Prerequisite - Indoor air quality (IAQ) plan",
          "documentaryEvidence": "Copy of the indoor air quality plan - as provided at the design stage",
          "responsibility": "Specialist Consultant",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Hea 02",
          "creditDescription": "Ventilation",
          "documentaryEvidence": "Where there have been changes since design stage, confirmation will be provided to demonstrate that air intakes and exhausts have been located in accordance with best practice in terms of minimising build-up of air pollutants",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Hea 02",
          "creditDescription": "Ventilation",
          "documentaryEvidence": "Where there have been changes since design stage, pollution dispersion modelling will be provided to inform the positioning of air intakes and exhausts",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Hea 02",
          "creditDescription": "Ventilation",
          "documentaryEvidence": "Where there have been changes since design stage,  a written statement will confirm that HVAC systems incorporate suitable filtration to minimise external air pollution (BS EN 16798-3:2017) - with air classification of at least SUP 2",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Hea 02",
          "creditDescription": "Ventilation",
          "documentaryEvidence": "Where there have been changes since the design stage, as-built drawings will demonstrate air intakes and exhausts are at least 10m apart (horizontally)",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Hea 02",
          "creditDescription": "Ventilation",
          "documentaryEvidence": "Where there have been changes since the design stage in areas with large or variable occupancy, written confirmation is provided to confirm that CO2 sensors / air quality sensors will be installed in these areas, with demand-controlled ventilation to the space",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Hea 02",
          "creditDescription": "Ventilation",
          "documentaryEvidence": "Where there have been changes since the design stage, written confirmation is provided to confirm that the ventilation strategy has been installed in line with CIBSE AM10, and that ventilation intakes and airflow pathways are designed in line with BRE FB 30; BRE IP 9/14; CIBSE TM21",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Hea 02",
          "creditDescription": "Ventilation",
          "documentaryEvidence": "Where there have been changes since the design stage, as-built drawings demonstrate that ventilation inlets / openable windows / trickle and background ventilators have been positioned at least 10m horizontal distance from sources of external pollution",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Hea 02",
          "creditDescription": "Ventilation",
          "documentaryEvidence": "Where there have been changes since the design stage in areas with large or variable occupancy, written confirmation is provided to confirm that CO2 sensors / air quality sensors have been installed in these areas, with sensors that have the capacity to either notify the building manager or automatically open windows / roof vents",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Hea 02",
          "creditDescription": "Ventilation",
          "documentaryEvidence": "Written confirmation that there have been no changes since the design stage documentation",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Hea 02",
          "creditDescription": "Ventilation",
          "documentaryEvidence": "Assessor's site inspection report",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Hea 02",
          "creditDescription": "Emissions from construction products",
          "documentaryEvidence": "Manufacturer's literature confirming that VOC (volatile organic compounds) requirements are met for at least three of the following product types:\n1. Interior paint and coatings\n2. Wood-based products (including timber floor)\n3. Flooring materials (including floor levelling compounds and resin flooring)\n4. Ceiling, wall, and acoustic and thermal insulation materials\n5. Interior adhesives and sealants (including floor adhesives)",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Hea 02",
          "creditDescription": "Emissions from construction products",
          "documentaryEvidence": "Written confirmation of the product types installed within the building (see Eight Versa' Hea 02 table)",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Hea 02",
          "creditDescription": "Emissions from construction products",
          "documentaryEvidence": "Manufacturer's literature confirming that VOC (volatile organic compounds) requirements are met for all of the following product types:\n1. Interior paint and coatings\n2. Wood-based products (including timber floor)\n3. Flooring materials (including floor levelling compounds and resin flooring)\n4. Ceiling, wall, and acoustic and thermal insulation materials\n5. Interior adhesives and sealants (including floor adhesives)",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Hea 02",
          "creditDescription": "Emissions from construction products",
          "documentaryEvidence": "Written confirmation of the product types installed within the building (see Eight Versa' Hea 02 table)",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Hea 02",
          "creditDescription": "Post-construction indoor air quality measurement",
          "documentaryEvidence": "Copy of final indoor air quality testing results",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Hea 02",
          "creditDescription": "Post-construction indoor air quality measurement",
          "documentaryEvidence": "Where World Health Organisation guidelines for indoor air quality are not met, confirmation that remedial works and retesting will be carried out",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Hea 04",
          "creditDescription": "Thermal Modelling CIBSE AM11",
          "documentaryEvidence": "Confirmation that there have been no changes to the design of the building since the design stage thermal modelling was carried out",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Hea 04",
          "creditDescription": "Thermal Modelling CIBSE AM11",
          "documentaryEvidence": "OR: As Built full dynamic thermal modelling in line with CIBSE AM11",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Hea 04",
          "creditDescription": "Design for future thermal comfort",
          "documentaryEvidence": "Confirmation that there have been no changes to the design of the building since the design stage thermal modelling was carried out",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Hea 04",
          "creditDescription": "Design for future thermal comfort",
          "documentaryEvidence": "OR: As Built Thermal modelling (CIBSE AM11) results demonstrating compliance with CIBSE Guide A for a future climate change environment",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Hea 04",
          "creditDescription": "Thermal zoning and controls",
          "documentaryEvidence": "As Built specification and / or design drawings confirming the controls for the heating system (in line with the outcome of the thermal modelling report)",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Hea 04",
          "creditDescription": "Thermal zoning and controls",
          "documentaryEvidence": "Assessor's site inspection report confirming zoning and local occupant controls",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Hea 05",
          "creditDescription": "Acoustic performance",
          "documentaryEvidence": "Confirmation that there have been no changes since the design stage acoustic testing was carried out",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Hea 05",
          "creditDescription": "Acoustic performance",
          "documentaryEvidence": "OR As built acoustic report confirming the building meets the appropriate acoustic performance standards for:\n-sound insulation\n-indoor ambient noise levels\n-reverberation times",
          "responsibility": "Specialist Consultant",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Hea 05",
          "creditDescription": "Acoustic performance",
          "documentaryEvidence": "Qualifications, experience & membership of the Suitably Qualified Acoustician",
          "responsibility": "Specialist Consultant",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Hea 06",
          "creditDescription": "Security of site and building",
          "documentaryEvidence": "As built drawings / specifications demonstrating how the building meets the requirements outlined in the Security Needs Assessment",
          "responsibility": "Architect",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Hea 06",
          "creditDescription": "Security of site and building",
          "documentaryEvidence": "Written confirmation from the Suitably Qualified Security Specialist that any deviations from original recommendations have been agreed (if applicable)",
          "responsibility": "Specialist Consultant",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Hea 06",
          "creditDescription": "Security of site and building",
          "documentaryEvidence": "Assessor's site inspection report",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Hea 07",
          "creditDescription": "Safe access",
          "documentaryEvidence": "As built site plan showing safe and separate access routes for:\n- cyclists (including cycle paths to cycle storage);\n- pedestrians (including mark-ups of any traffic calming measures, i.e. speed bumps, and raised pedestrian crossings); \n- small vehicles (including parking provision and pedestrian access from car to building); and\n- delivery vehicles / areas (including manoeuvring layouts and mark-up of any waste storage areas).",
          "responsibility": "Architect",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Hea 07",
          "creditDescription": "Safe access",
          "documentaryEvidence": "Assessor's site inspection report",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Hea 07",
          "creditDescription": "Outside space",
          "documentaryEvidence": "As Built site plan demonstrating outside amenity space accessible to all building occupants. The space must:\n-be an outdoor landscaped area (garden / terrace open to the sky)\n-have appropriate seating\n-be designated as non-smoking\n-be away from areas that will be subject to noise disturbance (e.g. building services, car parks, busy roads, delivery areas, etc)",
          "responsibility": "Architect",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Hea 07",
          "creditDescription": "Outside space",
          "documentaryEvidence": "Assessor's site inspection report",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Ene 01",
          "creditDescription": "Energy Performance",
          "documentaryEvidence": "An As Built copy of the 2013 Part L Approved Document checks (BRUKL Output Document)",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Ene 01",
          "creditDescription": "Energy Performance",
          "documentaryEvidence": "Confirmation of suitably qualified energy modeller's qualifications and experience",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Ene 01",
          "creditDescription": "Prediction of operational energy consumption",
          "documentaryEvidence": "Pre-requisite - as design stage.",
          "responsibility": "Specialist Consultant",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Ene 01",
          "creditDescription": "Prediction of operational energy consumption",
          "documentaryEvidence": "Updated operational energy consumption report including additional modelling scenarios carried out during construction stages.",
          "responsibility": "Specialist Consultant",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Ene 02",
          "creditDescription": "Sub-metering of end-use categories",
          "documentaryEvidence": "Written confirmation of no changes at design stage and the systems that have been sub-metered.",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Ene 02",
          "creditDescription": "Sub-metering of end-use categories",
          "documentaryEvidence": "As Built technical drawings confirming final metering arrangement, type and location for all energy consuming systems (including: heating, hot water, humidification, cooling, ventilation, pumps, small power, lighting, renewables, controls, major fans, and lifts) and their rated outputs.",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Ene 02",
          "creditDescription": "Sub-metering of end-use categories",
          "documentaryEvidence": "Assessor's site inspection confirming the sub-metering of different energy consuming systems installed.",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Ene 02",
          "creditDescription": "Sub-metering of high energy load & tenancy areas",
          "documentaryEvidence": "As Built technical drawings confirming: additional sub meter(s) for different function / tenancy areas.",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Ene 02",
          "creditDescription": "Sub-metering of high energy load & tenancy areas",
          "documentaryEvidence": "Assessor's site inspection report confirming sub-metering of different high energy load areas / tenancy areas installed.",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Ene 03",
          "creditDescription": "External Lighting",
          "documentaryEvidence": "As Built site plans showing location of all external light fittings, and connection to daylight sensors, and presence detectors in areas of intermittent pedestrian traffic.",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Ene 03",
          "creditDescription": "External Lighting",
          "documentaryEvidence": "Written confirmation that there have been no changes since design stage.",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Ene 03",
          "creditDescription": "External Lighting",
          "documentaryEvidence": "Manufacturer's literature / datasheet confirming the luminous efficacy of external lighting for the site",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Ene 03",
          "creditDescription": "External Lighting",
          "documentaryEvidence": "Assessor's site inspection confirming each type of installed external lighting, the daylight sensors and presence detectors.",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Ene 04",
          "creditDescription": "Passive Design Analysis",
          "documentaryEvidence": "Written confirmation that there have been no changes since the design stage and the results of the thermal modelling and passive design report are still relevant to the final building.",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Ene 04",
          "creditDescription": "Passive Design Analysis",
          "documentaryEvidence": "As Built drawing plans / specification confirming specification of passive design measures",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Ene 04",
          "creditDescription": "Passive Design Analysis",
          "documentaryEvidence": "Assessor's site inspection confirming passive design measures form part of the post construction development",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Ene 04",
          "creditDescription": "Free Cooling ",
          "documentaryEvidence": "Written confirmation that the specified free cooling strategy has been implemented in accordance with the design stage.",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Ene 04",
          "creditDescription": "Free Cooling ",
          "documentaryEvidence": "As Built drawing plans confirming free cooling strategy installed.",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Ene 04",
          "creditDescription": "Free Cooling ",
          "documentaryEvidence": "Assessor's site inspection report confirming the free cooling strategy used within the development ",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Ene 04",
          "creditDescription": "LZC feasibility study",
          "documentaryEvidence": "As Built energy calculations confirming the reduction in CO2 as a result of the specified low carbon / renewable technology.",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Ene 04",
          "creditDescription": "LZC feasibility study",
          "documentaryEvidence": "As Built drawing confirming installation of the low carbon / renewable technology as recommended at design stage.",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Ene 04",
          "creditDescription": "LZC feasibility study",
          "documentaryEvidence": "Assessor's site inspection report confirming the installation of the low carbon / renewable technology recommended at design stage.",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Ene 05",
          "creditDescription": "Refrigeration energy consumption",
          "documentaryEvidence": "A copy of manufacturers' / suppliers' literature confirming that the cold storage systems are manufactured and installed in accordance with the relevant standards.",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Ene 05",
          "creditDescription": "Refrigeration energy consumption",
          "documentaryEvidence": "Assessor's site inspection report confirming the type of cold storage system installed",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Ene 05",
          "creditDescription": "Indirect greenhouse gas emissions",
          "documentaryEvidence": "Above credit achieved, AND",
          "responsibility": "",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Ene 05",
          "creditDescription": "Indirect greenhouse gas emissions",
          "documentaryEvidence": "Written confirmation of the type of cold storage specified and calculations demonstrating the saving in indirect greenhouse gas emissions (CO2.eq) from the specified refrigeration system over the course of its operational life.",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Ene 06",
          "creditDescription": "Energy consumption",
          "documentaryEvidence": "Assessor's site inspection confirming the installation of the lift specified at the design stage",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Ene 06",
          "creditDescription": "Energy efficient features - Lifts",
          "documentaryEvidence": "Confirmation that there have been no changes since the design stage, and the lift specified at the design stage, has been installed",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Ene 06",
          "creditDescription": "Energy efficient features - Lifts",
          "documentaryEvidence": "Assessor's site inspection confirming the installation of the lift specified at the design stage with energy efficient features.",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Ene 06",
          "creditDescription": "Energy efficient features - Escalators or moving walks",
          "documentaryEvidence": "Assessor's site inspection confirming the installation of the lift specified at the design stage with energy efficient features.",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Ene 06",
          "creditDescription": "Energy efficient features - Escalators or moving walks",
          "documentaryEvidence": "Manufacturer's documentation confirming the specification of the installed system",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Ene 07",
          "creditDescription": "Design specification",
          "documentaryEvidence": "Written confirmation that there have been no changes to the laboratory facilities since the design stage.",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Ene 07",
          "creditDescription": "Design specification",
          "documentaryEvidence": "Written confirmation / specification confirming that ducted fume cupboards will comply with the following:\nDemonstrate that the average design air flow rate is no greater than 0.16 m3/s per linear metre (internal width) of fume cupboard workspace.\nThe volume flow rate in the exhaust duct (at the boundary of the laboratory) will be measured to take account of reductions in (inward) volume flow rate from fume cupboard leakage\nDemonstrate that a reduction in air flow does not compromise the defined performance criteria and does not increase the health and safety risk to future building occupants.",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Ene 07",
          "creditDescription": "Design specification",
          "documentaryEvidence": "Assessor's site inspection report confirming the type of laboratory cupboards / cabinets and systems installed",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Ene 07",
          "creditDescription": "Best practice energy efficient measures",
          "documentaryEvidence": "Written confirmation of no changes since design stage.",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Ene 07",
          "creditDescription": "Best practice energy efficient measures",
          "documentaryEvidence": "A copy of manufacturers' / suppliers' literature confirming that the cupboards / cabinets are manufactured and installed in accordance with the required standards",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Ene 07",
          "creditDescription": "Best practice energy efficient measures",
          "documentaryEvidence": "Assessor's site inspection report confirming the type of laboratory cupboards / cabinets and systems installed",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Ene 08",
          "creditDescription": "Energy efficient equipment ",
          "documentaryEvidence": "Written confirmation of no changes since design stage.",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Ene 08",
          "creditDescription": "Energy efficient equipment ",
          "documentaryEvidence": "Manufacturer's / supplier's literature for each relevant equipment type, confirming the relevant energy efficiency scheme or standards that the product complies with ",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Ene 08",
          "creditDescription": "Energy efficient equipment ",
          "documentaryEvidence": "Assessor's site inspection confirming each relevant equipment type installed within the development",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Tra 01",
          "creditDescription": "Travel Plan",
          "documentaryEvidence": "As design stage.",
          "responsibility": "Specialist Consultant",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Tra 01",
          "creditDescription": "Travel Plan",
          "documentaryEvidence": "Written confirmation that the travel plan will be provided to the build owner at handover.",
          "responsibility": "Client",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Tra 01",
          "creditDescription": "Travel Plan",
          "documentaryEvidence": "Assessor site inspection photos confirming recommended sustainable transport measures in-situ",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Tra 02",
          "creditDescription": "Transport options implementation",
          "documentaryEvidence": "Written confirmation that no changes since design stage",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Tra 02",
          "creditDescription": "Transport options implementation",
          "documentaryEvidence": "AS BUILT drawing plans showing sustainable transport measures in-situ.",
          "responsibility": "Architect",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Tra 02",
          "creditDescription": "Transport options implementation",
          "documentaryEvidence": "Assessor site inspection photos confirming sustainable transport measures in-situ",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wat 01",
          "creditDescription": "Water consumption",
          "documentaryEvidence": "As Built drawings OR specification confirming the manufacturer and product name for all sanitaryware fittings and controls installed",
          "responsibility": "Architect",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wat 01",
          "creditDescription": "Water consumption",
          "documentaryEvidence": "Manufacturer's details for all installed sanitaryware fittings",
          "responsibility": "Architect",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wat 01",
          "creditDescription": "Water consumption",
          "documentaryEvidence": "Assessor's site inspection report confirming the location and specification of sanitaryware",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wat 02",
          "creditDescription": "Water monitoring ",
          "documentaryEvidence": "Assessor's site inspection report  confirming a pulsed water meter has been fitted on the mains supply to the building",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wat 02",
          "creditDescription": "Water monitoring ",
          "documentaryEvidence": "Manufacturer's documentation confirming the specification of the installed meters",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wat 02",
          "creditDescription": "Water monitoring ",
          "documentaryEvidence": "As Built drawing to confirm the water meter is on the mains supply to the assessed building, and the locations of the sub-meters",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wat 02",
          "creditDescription": "Water monitoring ",
          "documentaryEvidence": "As Built drawing to confirm the location of separate, dedicated meters for the swimming pool and associated facilities (toilets, showers etc.)",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wat 02",
          "creditDescription": "Water monitoring ",
          "documentaryEvidence": "Assessor's site inspection report confirming the location and labelling of sub-meter",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wat 02",
          "creditDescription": "Water monitoring ",
          "documentaryEvidence": "As Built drawing to confirm the location of separate, dedicated meters for any process or cooling loop for ‘plumbed-in’ laboratory process equipment",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wat 02",
          "creditDescription": "Water monitoring ",
          "documentaryEvidence": "Assessor's site inspection report confirming the location and labelling of sub-meter",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wat 02",
          "creditDescription": "Water monitoring ",
          "documentaryEvidence": "Written confirmation of the water monitoring strategy/Copy of the post occupancy evaluation contract, outlining how this will enable the identification of all water consumption for sanitary uses.",
          "responsibility": "Client",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wat 03",
          "creditDescription": "Leak detection system",
          "documentaryEvidence": "Assessor's site inspection confirming the installation of the major leak detection system on the mains supply to the building",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wat 03",
          "creditDescription": "Leak detection system",
          "documentaryEvidence": "As Built drawings to confirm a leak detection system has been installed on the mains water supply within the building and also between the building and the utilities water meter. ",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wat 03",
          "creditDescription": "Leak detection system",
          "documentaryEvidence": "Manufacturer's documentation confirming the features of the leak detection system meet the BREEAM requirements",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wat 03",
          "creditDescription": "Flow control devices",
          "documentaryEvidence": "Assessor's site inspection confirming the control system for the sanitary supply shut off system (for example presence detection)",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wat 03",
          "creditDescription": "Flow control devices",
          "documentaryEvidence": "As Built drawings to confirm the location of flow control device fitted to each WC area ",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wat 04",
          "creditDescription": "Water efficient equipment ",
          "documentaryEvidence": "Assessor's site inspection confirming no unregulated water demands on site",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wat 04",
          "creditDescription": "Water efficient equipment ",
          "documentaryEvidence": "Assessor's site inspection confirming the irrigation strategy on site",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wat 04",
          "creditDescription": "Water efficient equipment ",
          "documentaryEvidence": "Compliance letter confirming that no irrigation systems have been specified, and therefore there are no unregulated water demands for the building",
          "responsibility": "Architect",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wat 04",
          "creditDescription": "Water efficient equipment ",
          "documentaryEvidence": "Confirmation that there have been no changes to the irrigation strategy specified at the design stage",
          "responsibility": "Architect",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Mat 01",
          "creditDescription": "Superstructure",
          "documentaryEvidence": "AS-BUILT drawings, including elevations and build-ups detailing the build up of all major materials within the superstructure of the building.",
          "responsibility": "Architect",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Mat 01",
          "creditDescription": "Superstructure",
          "documentaryEvidence": "Written confirmation that there were no changes to the superstructure materials since the design stage LCA was carried out.",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Mat 01",
          "creditDescription": "Superstructure",
          "documentaryEvidence": "Assessor site inspection photos showing all different material types installed.",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Mat 01",
          "creditDescription": "Substructure and hard landscaping options appraisal during Concept Design",
          "documentaryEvidence": "AS-BUILT drawings including build-ups detailing the build up of all major materials within the substructure of the building and hard landscaping elements of the development.",
          "responsibility": "Architect",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Mat 01",
          "creditDescription": "Substructure and hard landscaping options appraisal during Concept Design",
          "documentaryEvidence": "Written confirmation that there were no changes to the substructure and hard landscaping materials since the design stage LCA was carried out.",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Mat 01",
          "creditDescription": "Substructure and hard landscaping options appraisal during Concept Design",
          "documentaryEvidence": "A copy of the LCA report including 3 option appraisals at concept design stage",
          "responsibility": "Specialist Consultant",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Mat 02",
          "creditDescription": "Specification of products with a recognised EPD",
          "documentaryEvidence": "Mat 02 Proforma Table confirming the minimum 15  products installed with EPD certification, OR LCA confirmation with detail of products with recognised EPDs",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Mat 02",
          "creditDescription": "Specification of products with a recognised EPD",
          "documentaryEvidence": "EPD certificates for all materials outlined within the information above.",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Mat 03",
          "creditDescription": "Prerequisite - Legally harvested & traded timber",
          "documentaryEvidence": "Statement / AS BUILT Mat 03 Table from the Contractor confirming the timber suppliers / manufacturers that all timber was sourced from.",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Mat 03",
          "creditDescription": "Prerequisite - Legally harvested & traded timber",
          "documentaryEvidence": "All timber responsible sourcing products (i.e. FSC / PEFC certificates)",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Mat 03",
          "creditDescription": "Enabling sustainable procurement",
          "documentaryEvidence": "As design stage.",
          "responsibility": "Architect",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Mat 03",
          "creditDescription": "Measuring responsible sourcing",
          "documentaryEvidence": "AS-BUILT drawings to show the specification of the build up of the relevant building elements (see MAT03 Table)",
          "responsibility": "Architect",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Mat 03",
          "creditDescription": "Measuring responsible sourcing",
          "documentaryEvidence": "Completed AS-BUILT MAT03 Table, including manufacturer details for each material specified and their corresponding volume (in m3) or mass (in kg) and where possible",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Mat 03",
          "creditDescription": "Measuring responsible sourcing",
          "documentaryEvidence": "A copy of suppliers responsible sourcing certification (e.g. ISO14001, BES6001, FSC) to correspond with information in Mat 03 Table.",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Mat 05",
          "creditDescription": "Design for durability and resilience",
          "documentaryEvidence": "As design stage.",
          "responsibility": "Architect",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Mat 05",
          "creditDescription": "Design for durability and resilience",
          "documentaryEvidence": "AS BUILT drawings / specification outlining the measures taken to enhance durability, such as:\n- Bollards / barriers / raised kerbs in delivery areas\n- Robust external wall construction\n- Protection rails to internal walls\n- Kickplates on doors\n- Hard wearing floors",
          "responsibility": "Architect",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Mat 05",
          "creditDescription": "Design for durability and resilience",
          "documentaryEvidence": "Assessor site inspection photos showing all durability features in-situ.",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Mat 05",
          "creditDescription": "Design for durability and resilience",
          "documentaryEvidence": "As design stage",
          "responsibility": "Architect",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Mat 05",
          "creditDescription": "Design for durability and resilience",
          "documentaryEvidence": "Datasheets (if available) for cladding / glazing / roof, etc. to confirm the quality standard they comply with.",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Mat 05",
          "creditDescription": "Design for durability and resilience",
          "documentaryEvidence": "AS BUILT drawings confirming roof mansafe, access points/features for cleaning, etc. AND features of the roof and façade that prevent water damage / pooling ",
          "responsibility": "Architect",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Mat 05",
          "creditDescription": "Design for durability and resilience",
          "documentaryEvidence": "Assessor site inspection photos demonstrating the above.",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Mat 06",
          "creditDescription": "Material efficiency",
          "documentaryEvidence": "Updated materials efficiency report including records of the implementation of materials efficiency, and the targets and actual materials efficiencies achieved.",
          "responsibility": "Architect",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Mat 06",
          "creditDescription": "Material efficiency",
          "documentaryEvidence": "Assessor site inspection photos demonstrating features of materials efficiency in-situ.",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wst 01",
          "creditDescription": "Pre-demolition audit",
          "documentaryEvidence": "Copy of the pre-demolition audit\n\nThis must show the recovery of demolition material for subsequent high grade or value applications. The audit must cover the content of Pre-demolition audit scope and:\na: Be carried out at Concept Design stage prior to strip-out or demolition works\nb: Guide the design, consider materials for reuse and set targets for waste management\nc: Engage all contractors in the process of maximising high grade reuse and recycling opportunities\nd: Compare actual waste arisings and waste management routes used with those forecast and investigate significant deviations from planned targets.",
          "responsibility": "Contractor",
          "timeCritical": "Before end of Concept Design",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wst 01",
          "creditDescription": "Construction resource efficiency",
          "documentaryEvidence": "Final copy of the Site Waste Management Plan outlining waste minimisation procedures and target waste values",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wst 01",
          "creditDescription": "Construction resource efficiency",
          "documentaryEvidence": "Site data records on waste arisings and waste management routes, including the amount of waste produced in line with the target rates",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wst 01",
          "creditDescription": "Construction resource efficiency",
          "documentaryEvidence": "Site data records on waste arisings and waste management routes, including the amount of waste produced in line with the target rates",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wst 01",
          "creditDescription": "Construction resource efficiency",
          "documentaryEvidence": "Site data records on waste arisings and waste management routes, including the amount of waste produced in line with the target rates",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wst 01",
          "creditDescription": "Diversion of resources from landfill",
          "documentaryEvidence": "Confirmation of the non-hazardous construction waste diverted from landfill: Non-demolition 70% by volume or 80% by tonnage, demolition waste 80% by volume or 90% by tonnage",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wst 02",
          "creditDescription": "Pre-demolition audit",
          "documentaryEvidence": "A copy of the pre-demolition audit",
          "responsibility": "-",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wst 02",
          "creditDescription": "Project sustainable aggregate points",
          "documentaryEvidence": "Completed As Built Wst02 Table",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wst 02",
          "creditDescription": "Project sustainable aggregate points",
          "documentaryEvidence": "Transport monitoring records for aggregate deliveries (from aggregate gate to site)",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wst 03",
          "creditDescription": "Operational waste ",
          "documentaryEvidence": "As Built site plan showing both general and recyclable waste storage areas and that the latter will be at least 2m2 per 1000m2 of net floor area and located within 20m of the development",
          "responsibility": "Architect",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wst 03",
          "creditDescription": "Operational waste ",
          "documentaryEvidence": "Assessor's site inspection report and photographic documentation confirming the recyclable waste storage area is adequately sized and labelled, and located within 20m of the building ",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wst 03",
          "creditDescription": "Operational waste ",
          "documentaryEvidence": "Manufacturer's documentation for the Baler / Waste Compactor / Composting facilities installed (if applicable)",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wst 03",
          "creditDescription": "Operational waste ",
          "documentaryEvidence": "As-built drawings showing the location of the installed Baler / Waste Compactor / Composting facilities",
          "responsibility": "Architect",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wst 03",
          "creditDescription": "Operational waste ",
          "documentaryEvidence": "Assessor's site inspection confirming that each dwelling has adequate internal waste storage",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wst 03",
          "creditDescription": "Operational waste ",
          "documentaryEvidence": "Copy of the school/college recycling policy, confirmation that the policy is in place",
          "responsibility": "Client",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wst 04",
          "creditDescription": "Speculative floor and ceiling finishes",
          "documentaryEvidence": "Assessor's confirmation that no floor or ceiling finishes have been installed. OR only a 'show area' has been installed.",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wst 05",
          "creditDescription": "Resilience of structure, fabric, building services and renewables installation",
          "documentaryEvidence": "Confirmation that there have been no changes to the Adaptation to Climate Change Report, and that the recommended strategy has been implemented within the design",
          "responsibility": "Architect",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wst 05",
          "creditDescription": "Resilience of structure, fabric, building services and renewables installation",
          "documentaryEvidence": "Assessor's site inspection report confirming that any measures recommended",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wst 05",
          "creditDescription": "Resilience of structure, fabric, building services and renewables installation",
          "documentaryEvidence": "As-built drawings demonstrating the structure and fabric are adequately protected from the risks of a future climate ",
          "responsibility": "Architect",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wst 05",
          "creditDescription": "Resilience of structure, fabric, building services and renewables installation",
          "documentaryEvidence": "Technical Design update demonstrating how  recommendations or solutions  have been implemented.",
          "responsibility": "Architect",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wst 06",
          "creditDescription": "Design for disassembly and functional adaptability - recommendations",
          "documentaryEvidence": "A building-specific functional adaptation strategy study to explore the ease of disassembly and the functional adaptation potential of different design\nscenarios by the end of Concept Design.",
          "responsibility": "Architect",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wst 06",
          "creditDescription": "Design for disassembly and functional adaptability - recommendations",
          "documentaryEvidence": "As Built drawings showing features allowing for the potential for future adaptation of the building's function.",
          "responsibility": "Architect",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wst 06",
          "creditDescription": "Design for disassembly and functional adaptability - recommendations",
          "documentaryEvidence": "Assessor's site inspection report confirming that the functional adaptability measures recommended have been implemented within the final design",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wst 06",
          "creditDescription": "Disassembly and functional adaptability – implementation",
          "documentaryEvidence": "Copy of the building adaptability and disassembly guide.",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Wst 06",
          "creditDescription": "Disassembly and functional adaptability – implementation",
          "documentaryEvidence": "Confirmation that the building adaptability and disassembly guide has been supplied to prospective tenants.",
          "responsibility": "Client",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "LE 01",
          "creditDescription": "Previously Occupied Land",
          "documentaryEvidence": "Site plan of the construction zone, including all areas of hard standing, landscaping, site access and temporary site offices",
          "responsibility": "Architect",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "LE 01",
          "creditDescription": "Previously Occupied Land",
          "documentaryEvidence": "Site plan showing no change from design stage to the development's footprint",
          "responsibility": "Architect",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "LE 01",
          "creditDescription": "Contaminated Land",
          "documentaryEvidence": "A copy of the specialist's land contamination report ",
          "responsibility": "Specialist Consultant",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "LE 01",
          "creditDescription": "Contaminated Land",
          "documentaryEvidence": "Site plan showing areas remediated in relation to the development",
          "responsibility": "Specialist Consultant",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "LE 01",
          "creditDescription": "Contaminated Land",
          "documentaryEvidence": "Confirmation that all requirements within the remediation strategy were followed prior to construction on site",
          "responsibility": "Specialist Consultant",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "LE 02",
          "creditDescription": "Prerequisite",
          "documentaryEvidence": "Copy of BREEAM Ecological Risk Evaluation Checklist confirming Route selected",
          "responsibility": "Architect",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "LE 02",
          "creditDescription": "Prerequisite",
          "documentaryEvidence": "Confirmation that compliance has been monitored against all relevant UK and EU or international legislation relating to the ecology of the site.",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "LE 02",
          "creditDescription": "Survey and evaluation and Determining the ecological outcomes for the site (route 1)",
          "documentaryEvidence": "Confirmation that the planned ecological outcomes for the site have been met",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "LE 02",
          "creditDescription": "Survey and evaluation and Determining the ecological outcomes for the site (route 2)",
          "documentaryEvidence": "Confirmation that the planned ecological outcomes for the site have been met",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "LE 03",
          "creditDescription": "Prerequisite - Achieved LE 02",
          "documentaryEvidence": "Prerequisite - Achieved LE 02",
          "responsibility": "Architect",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "LE 03",
          "creditDescription": "Planning, liaison, implementation and data (route 1)",
          "documentaryEvidence": "As-built drawings showing the retention/protection/removal of any ecological features on the site",
          "responsibility": "Architect",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "LE 03",
          "creditDescription": "Planning, liaison, implementation and data (route 1)",
          "documentaryEvidence": "Confirmation that the design team have implemented the plans for the existing ecological features' retention/protection/removal",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "LE 03",
          "creditDescription": "Planning, liaison, implementation and data (route 2)",
          "documentaryEvidence": "As-built drawings showing the retention/protection/removal of any ecological features on the site",
          "responsibility": "Architect",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "LE 03",
          "creditDescription": "Planning, liaison, implementation and data (route 2)",
          "documentaryEvidence": "Ecology report confirming the retention/protection/removal of any ecological features on the site",
          "responsibility": "-",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "LE 03",
          "creditDescription": "Planning, liaison, implementation and data (route 2)",
          "documentaryEvidence": "Evidence e.g. emails confirming collaboration between ecologist and design team regarding the retention/protection/removal of ecological features",
          "responsibility": "-",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "LE 03",
          "creditDescription": "Managing negative impacts of the project (route 1)",
          "documentaryEvidence": "Confirmation that negative impacts from site preparation and construction works have been managed according to the mitigation hierarchy, and no overall loss of ecological value has occurred.",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "LE 03",
          "creditDescription": "Managing negative impacts of the project (route 1)",
          "documentaryEvidence": "Assessor site visit photos recording ecological protection measures",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "LE 03",
          "creditDescription": "Managing negative impacts of the project (route 2)",
          "documentaryEvidence": "Confirmation from ecologist that negative impacts from site preparation and construction works have been managed according to the mitigation hierarchy, and the loss of ecological value has been minimised",
          "responsibility": "Ecologist",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "LE 03",
          "creditDescription": "Managing negative impacts of the project (route 2)",
          "documentaryEvidence": "Confirmation from ecologist that negative impacts from site preparation and construction works have been managed according to the mitigation hierarchy, and no overall loss of ecological value has occurred",
          "responsibility": "Ecologist",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "LE 03",
          "creditDescription": "Managing negative impacts of the project (route 2)",
          "documentaryEvidence": "Assessor site visit photos recording ecological protection measures",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "LE 04",
          "creditDescription": "Prerequisite - Achieved LE 03",
          "documentaryEvidence": "LE 03 'Planning, liaison, implementation and data' has been achieved",
          "responsibility": "-",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "LE 04",
          "creditDescription": "Ecological enhancement (route 2 only)",
          "documentaryEvidence": "Ecology report confirming that locally relevant ecological features have been incorporated into the scheme, with reference to local biodiversity targets ",
          "responsibility": "Ecologist",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "LE 04",
          "creditDescription": "Ecological enhancement (route 2 only)",
          "documentaryEvidence": "As built drawings showing locally relevant incorporated ecological features",
          "responsibility": "Architect",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "LE 04",
          "creditDescription": "Ecological enhancement (route 2 only)",
          "documentaryEvidence": "Confirmation that the recommended ecological features have been installed",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "LE 04",
          "creditDescription": "Ecological enhancement (route 2 only)",
          "documentaryEvidence": "Evidence e.g. emails confirming collaboration between ecologist and design team regarding the ecological features",
          "responsibility": "Architect",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "LE 04",
          "creditDescription": "Change and enhancement of ecology (route 1)",
          "documentaryEvidence": "Confirmation that locally relevant ecological features have been incorporated into the scheme, with reference to local biodiversity targets ",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "LE 04",
          "creditDescription": "Change and enhancement of ecology (route 1)",
          "documentaryEvidence": "As built drawings showing locally relevant incorporated ecological features",
          "responsibility": "Architect",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "LE 04",
          "creditDescription": "Change and enhancement of ecology (route 1)",
          "documentaryEvidence": "Assessor site inspection photos confirming ecological measures that have been implemented within the development.",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "LE 04",
          "creditDescription": "Change and enhancement of ecology (route 2)",
          "documentaryEvidence": "Calculation in accordance with the process set out in GN36 - BREEAM, CEEQUAL and HQM Ecology Calculation Methodology",
          "responsibility": "Ecologist",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "LE 04",
          "creditDescription": "Change and enhancement of ecology (route 2)",
          "documentaryEvidence": "Assessor site inspection photos confirming ecological measures that have been implemented within the development.",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "LE 05",
          "creditDescription": "Prerequisite (route 1)",
          "documentaryEvidence": "LE 03 'Planning, liaison, implementation and data' has been achieved",
          "responsibility": "",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "LE 05",
          "creditDescription": "Prerequisite (route 2)",
          "documentaryEvidence": "LE 03 'Planning, liaison, implementation and data' and 1 credit for LE 04 'Enhancement of Ecology' has been achieved",
          "responsibility": "",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "LE 05",
          "creditDescription": "Management and maintenance - Landscape and ecology management plan (or similar) development (route 1)",
          "documentaryEvidence": "Confirmation that the design team have discussed the ecological features' future maintenance",
          "responsibility": "Client",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "LE 05",
          "creditDescription": "Management and maintenance - Landscape and ecology management plan (or similar) development (route 1)",
          "documentaryEvidence": "Confirmation that the end user of the building will continue to maintain the site's ecological features",
          "responsibility": "Client",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "LE 05",
          "creditDescription": "Management and maintenance - Landscape and ecology management plan (or similar) development (route 1)",
          "documentaryEvidence": "Building User Guide includes a section on Ecology and Biodiversity",
          "responsibility": "Client",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "LE 05",
          "creditDescription": "Management and maintenance - Landscape and ecology management plan (or similar) development (route 1)",
          "documentaryEvidence": "Landscape and ecology management plan, or equivalent, is developed in accordance with BS 42020:2013 Section 11.1",
          "responsibility": "Architect",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "LE 05",
          "creditDescription": "Management and maintenance - Landscape and ecology management plan (or similar) development (route 1)",
          "documentaryEvidence": "Confirmation that the management plan will be updated as appropriate to support maintenance of the ecological value of the site",
          "responsibility": "Client",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "LE 05",
          "creditDescription": "Management and maintenance (route 2)",
          "documentaryEvidence": "Confirmation that the ecologist has liaised with design team with regards to the ecological features' future maintenance",
          "responsibility": "Client",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "LE 05",
          "creditDescription": "Management and maintenance (route 2)",
          "documentaryEvidence": "Confirmation that the end user of the building will continue to maintain the site's ecological features",
          "responsibility": "Client",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "LE 05",
          "creditDescription": "Management and maintenance (route 2)",
          "documentaryEvidence": "Building User Guide includes a section on Ecology and Biodiversity",
          "responsibility": "Client",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "LE 05",
          "creditDescription": "Landscape and ecology management plan (or similar) development (route 2)",
          "documentaryEvidence": "Landscape and ecology management plan, or equivalent, is developed in accordance with BS 42020:2013 Section 11.1",
          "responsibility": "Ecologist",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "LE 05",
          "creditDescription": "Landscape and ecology management plan (or similar) development (route 2)",
          "documentaryEvidence": "Confirmation that the management plan will be updated as appropriate to support maintenance of the ecological value of the site",
          "responsibility": "Client",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Pol 01",
          "creditDescription": "No Refrigerants",
          "documentaryEvidence": "Written statement confirming that there are no refrigerants used within the final installed plant or systems.",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Pol 01",
          "creditDescription": "Prerequisite - BS EN 378:2016 (ammonia)",
          "documentaryEvidence": "Written statement confirming that all final systems with electric compressors comply with the requirements of BS EN 378:2016 (Parts 2 and 3).",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Pol 01",
          "creditDescription": "Impact of refrigerant",
          "documentaryEvidence": "Clause from the As Built specification confirming the refrigeration type and system information as follows:\n1. Global Warming Potential (GWP) of the specified system refrigerant(s)\n2. Total refrigerant charge (kg)\n3. Heating / cooling capacity (kW)",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Pol 01",
          "creditDescription": "Impact of refrigerant",
          "documentaryEvidence": "As Built drawings confirming the type and location of systems using refrigerants within the development.",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Pol 01",
          "creditDescription": "Impact of refrigerant",
          "documentaryEvidence": "A copy of the completed final BREEAM Pol 01 Calculator, confirming the number of credits achieved.",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Pol 01",
          "creditDescription": "Leak detection",
          "documentaryEvidence": "Written confirmation / specification confirming that all systems installed are hermetically sealed / only use environmentally benign refrigerants, OR refrigerant leak detection has been installed (clarifying the type of system, scope and that the containment strategy is capable of automatically responding and managing the remaining refrigerant charge to limit loss of refrigerant).",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Pol 01",
          "creditDescription": "Leak detection",
          "documentaryEvidence": "Manufacturer literature or datasheet confirming all systems installed are hermetically sealed / only use environmentally benign refrigerants, OR where leak detection installed: \n- Type of leak detection system(s).\n- Scope of the system(s) (must be robust, tested and capable of continuously monitoring for leaks).\n- Where relevant, containment strategy for such equipment (must be capable of automatically responding and managing the remaining refrigerant charge to limit loss of refrigerant).",
          "responsibility": "",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Pol 01",
          "creditDescription": "Leak detection",
          "documentaryEvidence": "As Built drawings confirming the type and location of refrigerant leak detection systems (if present).",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Pol 01",
          "creditDescription": "Leak detection",
          "documentaryEvidence": "Assessor site inspection photos confirming installed refrigerant leak detection system (if present)",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Pol 02",
          "creditDescription": "Local air quality",
          "documentaryEvidence": "Written confirmation / specification confirming that the installed space and water heating is supplied by non-combustion systems (i.e. only powered by electricity), OR\nconfirmation of the make and model of the system (boiler, CHP).",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Pol 02",
          "creditDescription": "Local air quality",
          "documentaryEvidence": "A copy of the Defra map confirming whether the site is in a high or low pollution location.",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Pol 02",
          "creditDescription": "Local air quality",
          "documentaryEvidence": "Assessor site inspection confirming the make and model of the space and water heating system (boiler, CHP)",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Pol 02",
          "creditDescription": "Local air quality",
          "documentaryEvidence": "A copy of the manufacturer's product details for the final combustion system(s) used (both existing and new) confirming the NOx emissions (mg/kWh) and PM10/VOC emissions (mg/m3), where applicable, for space and water heating.",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Pol 03",
          "creditDescription": "Flood resilience",
          "documentaryEvidence": "Written confirmation that the final development and site was constructed in accordance with the site-specific Flood Risk Assessment provided at design stage.",
          "responsibility": "Project Manager",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Pol 03",
          "creditDescription": "Flood resilience",
          "documentaryEvidence": "As-built site plans/sections (for medium or high annual probability) confirming:\n- The design ground levels (for the development and site) compared to design flood levels; AND\n- Safe access routes to the development.",
          "responsibility": "Civil Engineer",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Pol 03",
          "creditDescription": "Surface water run-off rate",
          "documentaryEvidence": "Written confirmation that the final development and site was constructed in accordance with the drainage consultant's report provided at design stage.\n(Where the drainage design for the development and site has changed from design stage, an updated drainage consultant's report is provided.)",
          "responsibility": "Civil Engineer",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Pol 03",
          "creditDescription": "Surface water run-off rate",
          "documentaryEvidence": "Section from the post-construction maintenance agreement contract for the ownership, long-term operation and maintenance of all specified Sustainable Drainage Systems (SuDS).",
          "responsibility": "Client",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Pol 03",
          "creditDescription": "Surface water run-off volume",
          "documentaryEvidence": "Written confirmation that the final development and site was constructed in accordance with the drainage consultant's report provided at design stage.\n(Where the drainage design for the development and site has changed from design stage, an updated drainage consultant's report is provided.)",
          "responsibility": "Civil Engineer",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Pol 03",
          "creditDescription": "Minimum watercourse pollution",
          "documentaryEvidence": "Written confirmation that the final development and site was constructed in accordance with the drainage consultant's report provided at design stage.",
          "responsibility": "Civil Engineer",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Pol 03",
          "creditDescription": "Minimum watercourse pollution",
          "documentaryEvidence": "(Where the drainage design for the development and site has changed from design stage, an updated drainage consultant's report is provided.)",
          "responsibility": "Civil Engineer",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Pol 03",
          "creditDescription": "Minimum watercourse pollution",
          "documentaryEvidence": "As Built site plan confirming the areas which have a high risk (i.e. car parks, roadways, vehicle manoeuvring, plant areas) to watercourse pollution OR confirmation that there are no high risk areas.",
          "responsibility": "Civil Engineer",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Pol 03",
          "creditDescription": "Minimum watercourse pollution",
          "documentaryEvidence": "As Built specification confirming that oil/petrol separators (or an equivalent system for pollution treatment) are installed for all high risk areas, in accordance with The SuDS Manual and designed in a bespoke manner for the site.",
          "responsibility": "Civil Engineer",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Pol 03",
          "creditDescription": "Minimum watercourse pollution",
          "documentaryEvidence": "Confirmation that:\n- Water pollution prevention systems have been designed in line with The SuDS Manual.\n- A comprehensive drainage plan will be produced and handed over to the building occupier.\n- All external storage and delivery areas will be designed in compliance with the relevant pollution prevention guidance.",
          "responsibility": "Civil Engineer",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Pol 03",
          "creditDescription": "Minimum watercourse pollution",
          "documentaryEvidence": "A comprehensive and up to date 'As Built' drainage plan of the site.",
          "responsibility": "Civil Engineer",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Pol 03",
          "creditDescription": "Minimum watercourse pollution",
          "documentaryEvidence": "Compliance letter confirming that the drainage plan was handed over to the building occupier",
          "responsibility": "Civil Engineer",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Pol 04",
          "creditDescription": "Reduction of night time light pollution",
          "documentaryEvidence": "Written confirmation / as-built specification confirming EITHER:\n- There is no external lighting installed within the development; OR\n- Installed external lighting is in compliance with Table 2 of the ILP Guidance Notes (2011) and can be automatically switched off between 23:00 and 07:00 (or safety/security lighting on during the night complies with Table 2 of the ILP Guidance Notes (2011). Any illuminated advertisements (where present) are installed in compliance with ILP PLG05 (2015).",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Pol 04",
          "creditDescription": "Reduction of night time light pollution",
          "documentaryEvidence": "As Built site plan showing areas of the building and site that are externally lit AND any nearby properties that may be affected.",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Pol 05",
          "creditDescription": "Reduction of noise pollution",
          "documentaryEvidence": "As-built drawing showing any noise sensitive buildings within 800m of the development and proposed noise sources from the new development.",
          "responsibility": "Specialist Consultant",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Pol 05",
          "creditDescription": "Reduction of noise pollution",
          "documentaryEvidence": "Written confirmation that a Noise Impact Assessment has been commissioned and will be carried out by a suitably qualified acoustic consultant, in compliance with BS 4142:2014; AND\n- If the Noise Impact Assessment concludes that noise levels are above 5dB lower than the background noise throughout the day and night, measures will be installed to attenuate the noise at its source to a compliant level.",
          "responsibility": "Project Manager",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Pol 05",
          "creditDescription": "Reduction of noise pollution",
          "documentaryEvidence": "A copy of the Noise Impact Assessment, in line with BS 4142:2014, confirming:\n- Existing background noise levels (at nearest/most exposed noise sensitive area to the development and including existing plant on a building, where the development is an extension) and noise rating level from the assessed building with installed plant;\n- Confirmation of whether noise levels at the nearest/most exposed noise sensitive area to the development are higher or lower than 5dB lower than the background noise levels;\n- (Where the 5dB lower than background is not achieved) specification of attenuation measures to attenuate the noise at its source to a compliant level.",
          "responsibility": "Specialist Consultant",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Inn 01",
          "creditDescription": "Man 03 - Responsible construction management",
          "documentaryEvidence": "Achieve all items in Table 4.1 on page 51.",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Inn 02",
          "creditDescription": "Hea 01 - Daylighting",
          "documentaryEvidence": "Post construction stage compliance letter confirming there have been no changes since the design stage daylighting report was carried out",
          "responsibility": "Client",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Inn 02",
          "creditDescription": "Hea 01 - Daylighting",
          "documentaryEvidence": "OR Updated daylighting calculations report for the constructed building",
          "responsibility": "Specialist Consultant",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Inn 03",
          "creditDescription": "Hea 01 - Internal and external lighting levels, zoning and control",
          "documentaryEvidence": "Assessor's site inspection report confirming local light dimming",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Inn 04",
          "creditDescription": "Hea 02 - Minimising sources of air pollution - Emissions from construction products",
          "documentaryEvidence": "Manufacturer's literature confirming that  Exemplary Level VOC (volatile organic compounds) requirements are met for at least three of the following product types:\n1. Interior paint and coatings\n2. Wood-based products (including timber floor)\n3. Flooring materials (including floor levelling compounds and resin flooring)\n4. Ceiling, wall, and acoustic and thermal insulation materials\n5. Interior adhesives and sealants (including floor adhesives)",
          "responsibility": "Architect",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Inn 04",
          "creditDescription": "Hea 02 - Minimising sources of air pollution - Emissions from construction products",
          "documentaryEvidence": "Written confirmation of the product types installed within the building (see Eight Versa' Hea 02 table)",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Inn 04",
          "creditDescription": "Hea 02 - Minimising sources of air pollution - Emissions from construction products",
          "documentaryEvidence": "Assessor's site inspection report confirming installed finishes",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Inn 05",
          "creditDescription": "Hea 06 - Security of site and building",
          "documentaryEvidence": "A compliant risk based security rating scheme has been used.",
          "responsibility": "Specialist Consultant",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Inn 06",
          "creditDescription": "Ene 01 - Beyond zero net regulated carbon",
          "documentaryEvidence": "A copy of the As Built 2013 Part L Approved Document checks (BRUKL Output Document) demonstrating an EPRNC>0.9 and zero net regulated CO2.eq emissions",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Inn 07",
          "creditDescription": "Ene 01 - Carbon negative",
          "documentaryEvidence": "Confirmation from suitably qualified energy modeller that no changes have been made since design stage modelling/where changes to design assumptions and input data have occurred at post construction stage, the energy modelling should be re-run to show this",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Inn 07",
          "creditDescription": "Ene 01 - Carbon negative",
          "documentaryEvidence": "Confirmation from suitably qualified energy modeller that no changes have been made since design stage modelling/where changes to design assumptions and input data have occurred at post construction stage, the energy modelling should be re-run to show this",
          "responsibility": "M&E",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Inn 08",
          "creditDescription": "Ene 01 - Post occupancy stage",
          "documentaryEvidence": "Client commitment to proceed to the post occupancy stage and report the energy consumption",
          "responsibility": "Client",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Inn 09",
          "creditDescription": "Wat 01 - Water consumption",
          "documentaryEvidence": "Manufacturer literature confirming 'exemplary level' flow/flush rates for sanitary fittings and controls.",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Inn 10",
          "creditDescription": "Mat 01 - Core building services options appraisal during Concept Design",
          "documentaryEvidence": "Written confirmation that there were no changes to the core building services since the design stage LCA was carried out.",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Inn 10",
          "creditDescription": "Mat 01 - Core building services options appraisal during Concept Design",
          "documentaryEvidence": "Assessor site inspection photos showing all different building services installed.",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Inn 11",
          "creditDescription": "Mat 01 - LCA and LCC alignment",
          "documentaryEvidence": "As design stage.",
          "responsibility": "Specialist Consultant",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Inn 12",
          "creditDescription": "Mat 01 - Third party verification",
          "documentaryEvidence": "Written confirmation that there have been no changes since the independent suitably qualified third party verification.",
          "responsibility": "TBC",
          "timeCritical": "-",
          "complianceLetter": "Yes",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Inn 13",
          "creditDescription": "Mat 03 - Measuring responsible sourcing",
          "documentaryEvidence": "Exemplary level achieved",
          "responsibility": "Architect",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Inn 14",
          "creditDescription": "Wst 01 - Construction waste management",
          "documentaryEvidence": "Exemplary level achieved",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Inn 15",
          "creditDescription": "Wst 02 - Use of recycled and sustainably sourced aggregates",
          "documentaryEvidence": "Exemplary level achieved",
          "responsibility": "Contractor",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Inn 16",
          "creditDescription": "Wst 05 - Responding to climate change",
          "documentaryEvidence": "As Wst 05, plus evidence for Hea 04 (thermal comfort for climate change scenario), Ene 01 (8 credits), Ene 04 (Passive design analysis), Wat 01 (3 credits), Mat 05 and Pol 03 (3 credits).",
          "responsibility": "Eight Versa",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        },
        {
          "creditCategoryCode": "Inn 17",
          "creditDescription": "LE 02 - Determine the ecological outcomes for the site (sustainability-related activities)",
          "documentaryEvidence": "Exemplary level achieved",
          "responsibility": "Ecologist",
          "timeCritical": "-",
          "complianceLetter": "-",
          "requiredAtStage": "Stage 1"
        }
       ]

    const minimumStandards = [
        {
          "creditCategoryCode": "Man 03",
          "creditDescription": "Responsible construction management",
          "targetRating": "Pass",
          "creditsMinimumStandards": "0"
        },
        {
          "creditCategoryCode": "Man 03",
          "creditDescription": "Responsible construction management",
          "targetRating": "Good",
          "creditsMinimumStandards": "0"
        },
        {
          "creditCategoryCode": "Man 03",
          "creditDescription": "Responsible construction management",
          "targetRating": "Very Good",
          "creditsMinimumStandards": "0"
        },
        {
          "creditCategoryCode": "Man 03",
          "creditDescription": "Responsible construction management",
          "targetRating": "Excellent",
          "creditsMinimumStandards": "1"
        },
        {
          "creditCategoryCode": "Man 03",
          "creditDescription": "Responsible construction management",
          "targetRating": "Outstanding",
          "creditsMinimumStandards": "2"
        },
        {
          "creditCategoryCode": "Man 04",
          "creditDescription": "Commissioning - Testing schedule and responsibilities",
          "targetRating": "Pass",
          "creditsMinimumStandards": "0"
        },
        {
          "creditCategoryCode": "Man 04",
          "creditDescription": "Commissioning - Testing schedule and responsibilities",
          "targetRating": "Good",
          "creditsMinimumStandards": "0"
        },
        {
          "creditCategoryCode": "Man 04",
          "creditDescription": "Commissioning - Testing schedule and responsibilities",
          "targetRating": "Very Good",
          "creditsMinimumStandards": "1"
        },
        {
          "creditCategoryCode": "Man 04",
          "creditDescription": "Commissioning - Testing schedule and responsibilities",
          "targetRating": "Excellent",
          "creditsMinimumStandards": "1"
        },
        {
          "creditCategoryCode": "Man 04",
          "creditDescription": "Commissioning - Testing schedule and responsibilities",
          "targetRating": "Outstanding",
          "creditsMinimumStandards": "1"
        },
        {
          "creditCategoryCode": "Man 04",
          "creditDescription": "Handover",
          "targetRating": "Pass",
          "creditsMinimumStandards": "0"
        },
        {
          "creditCategoryCode": "Man 04",
          "creditDescription": "Handover",
          "targetRating": "Good",
          "creditsMinimumStandards": "0"
        },
        {
          "creditCategoryCode": "Man 04",
          "creditDescription": "Handover",
          "targetRating": "Very Good",
          "creditsMinimumStandards": "BUG"
        },
        {
          "creditCategoryCode": "Man 04",
          "creditDescription": "Handover",
          "targetRating": "Excellent",
          "creditsMinimumStandards": "BUG"
        },
        {
          "creditCategoryCode": "Man 04",
          "creditDescription": "Handover",
          "targetRating": "Outstanding",
          "creditsMinimumStandards": "BUG"
        },
        {
          "creditCategoryCode": "Man 05",
          "creditDescription": "Commissioning - Implementation",
          "targetRating": "Pass",
          "creditsMinimumStandards": "0"
        },
        {
          "creditCategoryCode": "Man 05",
          "creditDescription": "Commissioning - Implementation",
          "targetRating": "Good",
          "creditsMinimumStandards": "0"
        },
        {
          "creditCategoryCode": "Man 05",
          "creditDescription": "Commissioning - Implementation",
          "targetRating": "Very Good",
          "creditsMinimumStandards": "0"
        },
        {
          "creditCategoryCode": "Man 05",
          "creditDescription": "Commissioning - Implementation",
          "targetRating": "Excellent",
          "creditsMinimumStandards": "1"
        },
        {
          "creditCategoryCode": "Man 05",
          "creditDescription": "Commissioning - Implementation",
          "targetRating": "Outstanding",
          "creditsMinimumStandards": "1"
        },
        {
          "creditCategoryCode": "Ene 01",
          "creditDescription": "Energy Performance",
          "targetRating": "Pass",
          "creditsMinimumStandards": "0"
        },
        {
          "creditCategoryCode": "Ene 01",
          "creditDescription": "Energy Performance",
          "targetRating": "Good",
          "creditsMinimumStandards": "0"
        },
        {
          "creditCategoryCode": "Ene 01",
          "creditDescription": "Energy Performance",
          "targetRating": "Very Good",
          "creditsMinimumStandards": "0"
        },
        {
          "creditCategoryCode": "Ene 01",
          "creditDescription": "Energy Performance",
          "targetRating": "Excellent",
          "creditsMinimumStandards": "4"
        },
        {
          "creditCategoryCode": "Ene 01",
          "creditDescription": "Energy Performance",
          "targetRating": "Outstanding",
          "creditsMinimumStandards": "6"
        },
        {
          "creditCategoryCode": "Ene 01",
          "creditDescription": "Prediction of operational energy consumption",
          "targetRating": "Pass",
          "creditsMinimumStandards": "0"
        },
        {
          "creditCategoryCode": "Ene 01",
          "creditDescription": "Prediction of operational energy consumption",
          "targetRating": "Good",
          "creditsMinimumStandards": "0"
        },
        {
          "creditCategoryCode": "Ene 01",
          "creditDescription": "Prediction of operational energy consumption",
          "targetRating": "Very Good",
          "creditsMinimumStandards": "0"
        },
        {
          "creditCategoryCode": "Ene 01",
          "creditDescription": "Prediction of operational energy consumption",
          "targetRating": "Excellent",
          "creditsMinimumStandards": "0"
        },
        {
          "creditCategoryCode": "Ene 01",
          "creditDescription": "Prediction of operational energy consumption",
          "targetRating": "Outstanding",
          "creditsMinimumStandards": "4"
        },
        {
          "creditCategoryCode": "Ene 02",
          "creditDescription": "Sub-metering of end-use categories",
          "targetRating": "Pass",
          "creditsMinimumStandards": "0"
        },
        {
          "creditCategoryCode": "Ene 02",
          "creditDescription": "Sub-metering of end-use categories",
          "targetRating": "Good",
          "creditsMinimumStandards": "0"
        },
        {
          "creditCategoryCode": "Ene 02",
          "creditDescription": "Sub-metering of end-use categories",
          "targetRating": "Very Good",
          "creditsMinimumStandards": "1"
        },
        {
          "creditCategoryCode": "Ene 02",
          "creditDescription": "Sub-metering of end-use categories",
          "targetRating": "Excellent",
          "creditsMinimumStandards": "1"
        },
        {
          "creditCategoryCode": "Ene 02",
          "creditDescription": "Sub-metering of end-use categories",
          "targetRating": "Outstanding",
          "creditsMinimumStandards": "1"
        },
        {
          "creditCategoryCode": "Wat 01",
          "creditDescription": "Water consumption",
          "targetRating": "Pass",
          "creditsMinimumStandards": "0"
        },
        {
          "creditCategoryCode": "Wat 01",
          "creditDescription": "Water consumption",
          "targetRating": "Good",
          "creditsMinimumStandards": "1"
        },
        {
          "creditCategoryCode": "Wat 01",
          "creditDescription": "Water consumption",
          "targetRating": "Very Good",
          "creditsMinimumStandards": "1"
        },
        {
          "creditCategoryCode": "Wat 01",
          "creditDescription": "Water consumption",
          "targetRating": "Excellent",
          "creditsMinimumStandards": "1"
        },
        {
          "creditCategoryCode": "Wat 01",
          "creditDescription": "Water consumption",
          "targetRating": "Outstanding",
          "creditsMinimumStandards": "2"
        },
        {
          "creditCategoryCode": "Wat 02",
          "creditDescription": "Water monitoring ",
          "targetRating": "Pass",
          "creditsMinimumStandards": "0"
        },
        {
          "creditCategoryCode": "Wat 02",
          "creditDescription": "Water monitoring ",
          "targetRating": "Good",
          "creditsMinimumStandards": "1"
        },
        {
          "creditCategoryCode": "Wat 02",
          "creditDescription": "Water monitoring ",
          "targetRating": "Very Good",
          "creditsMinimumStandards": "1"
        },
        {
          "creditCategoryCode": "Wat 02",
          "creditDescription": "Water monitoring ",
          "targetRating": "Excellent",
          "creditsMinimumStandards": "1"
        },
        {
          "creditCategoryCode": "Wat 02",
          "creditDescription": "Water monitoring ",
          "targetRating": "Outstanding",
          "creditsMinimumStandards": "1"
        },
        {
          "creditCategoryCode": "Wst 01",
          "creditDescription": "Construction waste management",
          "targetRating": "Pass",
          "creditsMinimumStandards": "0"
        },
        {
          "creditCategoryCode": "Wst 01",
          "creditDescription": "Construction waste management",
          "targetRating": "Good",
          "creditsMinimumStandards": "0"
        },
        {
          "creditCategoryCode": "Wst 01",
          "creditDescription": "Construction waste management",
          "targetRating": "Very Good",
          "creditsMinimumStandards": "0"
        },
        {
          "creditCategoryCode": "Wst 01",
          "creditDescription": "Construction waste management",
          "targetRating": "Excellent",
          "creditsMinimumStandards": "0"
        },
        {
          "creditCategoryCode": "Wst 01",
          "creditDescription": "Construction waste management",
          "targetRating": "Outstanding",
          "creditsMinimumStandards": "1"
        },
        {
          "creditCategoryCode": "Wst 03",
          "creditDescription": "Operational waste ",
          "targetRating": "Pass",
          "creditsMinimumStandards": "0"
        },
        {
          "creditCategoryCode": "Wst 03",
          "creditDescription": "Operational waste ",
          "targetRating": "Good",
          "creditsMinimumStandards": "0"
        },
        {
          "creditCategoryCode": "Wst 03",
          "creditDescription": "Operational waste ",
          "targetRating": "Very Good",
          "creditsMinimumStandards": "0"
        },
        {
          "creditCategoryCode": "Wst 03",
          "creditDescription": "Operational waste ",
          "targetRating": "Excellent",
          "creditsMinimumStandards": "1"
        },
        {
          "creditCategoryCode": "Wst 03",
          "creditDescription": "Operational waste ",
          "targetRating": "Outstanding",
          "creditsMinimumStandards": "1"
        }
       ]

    const creditExceptionsForSpecificBuildingTypes = [{
        
        "creditCategoryCode": "Hea 01",
        "creditCategoryDescription": "Visual comfort",
        "creditDescription": "Daylighting",
        "buildingType": "Education-Preschool and primary school",
        "value": "2"
      },
      {
        
        "creditCategoryCode": "Hea 01",
        "creditCategoryDescription": "Visual comfort",
        "creditDescription": "Daylighting",
        "buildingType": "Education-Further educational or vocational colleges and higher education institutions",
        "value": "2"
      },
      {
        
        "creditCategoryCode": "Hea 01",
        "creditCategoryDescription": "Visual comfort",
        "creditDescription": "Daylighting",
        "buildingType": "Healthcare",
        "value": "2"
      },
      {
        
        "creditCategoryCode": "Hea 01",
        "creditCategoryDescription": "Visual comfort",
        "creditDescription": "Daylighting",
        "buildingType": "Multi-residential",
        "value": "2"
      },
      {
        
        "creditCategoryCode": "Hea 01",
        "creditCategoryDescription": "Visual comfort",
        "creditDescription": "Daylighting",
        "buildingType": "Multi-residential - Residential institution (long term stay)",
        "value": "2"
      },
      {
        
        "creditCategoryCode": "Hea 01",
        "creditCategoryDescription": "Visual comfort",
        "creditDescription": "Daylighting",
        "buildingType": "Retail",
        "value": "2"
      },
      {
        
        "creditCategoryCode": "Hea 01",
        "creditCategoryDescription": "Visual comfort",
        "creditDescription": "Daylighting",
        "buildingType": "Office",
        "value": "2"
      },
      {
        
        "creditCategoryCode": "Hea 01",
        "creditCategoryDescription": "Visual comfort",
        "creditDescription": "Daylighting",
        "buildingType": "Prison",
        "value": "2"
      },
      {
        
        "creditCategoryCode": "Hea 05",
        "creditCategoryDescription": "Acoustic performance",
        "creditDescription": "Acoustic performance",
        "buildingType": "Multi-residential",
        "value": "4"
      },
      {
        
        "creditCategoryCode": "Hea 05",
        "creditCategoryDescription": "Acoustic performance",
        "creditDescription": "Acoustic performance",
        "buildingType": "Multi-residential - Residential institution (long term stay)",
        "value": "4"
      },
      {
        
        "creditCategoryCode": "Hea 05",
        "creditCategoryDescription": "Acoustic performance",
        "creditDescription": "Acoustic performance",
        "buildingType": "Other - Residential institution (short term stay)",
        "value": "4"
      },
    //   {
        
    //     "creditCategoryCode": "Ene 07",
    //     "creditCategoryDescription": "Energy efficient laboratory systems",
    //     "creditDescription": "Design specification",
    //     "buildingType": "Education-Preschool and primary school",
    //     "value": "0"
    //   },
    //   {
        
    //     "creditCategoryCode": "Ene 07",
    //     "creditCategoryDescription": "Energy efficient laboratory systems",
    //     "creditDescription": "Best practice energy efficient measures",
    //     "buildingType": "Education-Preschool and primary school",
    //     "value": "0"
    //   },
      {
        
        "creditCategoryCode": "Wst 04",
        "creditCategoryDescription": "Speculative finishes (offices only)",
        "creditDescription": "Speculative floor and ceiling finishes",
        "buildingType": "Office",
        "value": "1"
      }
    //   {
        
    //     "creditCategoryCode": "Inn 01",
    //     "creditCategoryDescription": "Man 03 - Responsible construction management",
    //     "creditDescription": "Man 03 - Responsible construction management",
    //     "buildingType": "Multi-residential - Residential institution (long term stay)",
    //     "value": "2"
    //   }
    ]

      const creditExceptionsForSpecificAssessmentTypes = [{
        
        "creditCategoryCode": "Man 01",
        "creditDescription": "BREEAM AP (concept design)",
        "assessmentType": "Simple Building",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Man 01",
        "creditDescription": "BREEAM AP (developed design)",
        "assessmentType": "Simple Building",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Man 02",
        "creditDescription": "Elemental LCC",
        "assessmentType": "Simple Building",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Man 02",
        "creditDescription": "Component level LCC options appraisal",
        "assessmentType": "Simple Building",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Man 03",
        "creditDescription": "BREEAM AP (site)",
        "assessmentType": "Simple Building",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Man 03",
        "creditDescription": "Responsible construction management",
        "assessmentType": "Simple Building",
        "value": "2"
      },
      {
        
        "creditCategoryCode": "Man 03",
        "creditDescription": "Transport of construction materials & waste",
        "assessmentType": "Simple Building",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Man 04",
        "creditDescription": "Commissioning - Testing schedule and responsibilities",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Man 04",
        "creditDescription": "Commissioning - Design and preparation",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Man 04",
        "creditDescription": "Commissioning - Design and preparation",
        "assessmentType": "Simple Building",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Man 04",
        "creditDescription": "Testing and inspecting building fabric",
        "assessmentType": "Simple Building",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Man 04",
        "creditDescription": "Handover",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Man 05",
        "creditDescription": "Aftercare Support",
        "assessmentType": "Shell and Core",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Man 05",
        "creditDescription": "Aftercare Support",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Man 05",
        "creditDescription": "Commissioning - Implementation",
        "assessmentType": "Shell and Core",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Man 05",
        "creditDescription": "Commissioning - Implementation",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Man 05",
        "creditDescription": "Post Occupancy Evaluation (POE)",
        "assessmentType": "Shell and Core",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Man 05",
        "creditDescription": "Post Occupancy Evaluation (POE)",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Hea 01",
        "creditDescription": "Control of glare from sunlight",
        "assessmentType": "Shell and Core",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Hea 01",
        "creditDescription": "Control of glare from sunlight",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Hea 02",
        "creditDescription": "Prerequisite - Indoor air quality (IAQ) plan",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Hea 02",
        "creditDescription": "Ventilation",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Hea 02",
        "creditDescription": "Emissions from construction products",
        "assessmentType": "Shell and Core",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Hea 02",
        "creditDescription": "Emissions from construction products",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Hea 02",
        "creditDescription": "Post-construction indoor air quality measurement",
        "assessmentType": "Shell and Core",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Hea 02",
        "creditDescription": "Post-construction indoor air quality measurement",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Hea 02",
        "creditDescription": "Post-construction indoor air quality measurement",
        "assessmentType": "Simple Building",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Hea 03",
        "creditDescription": "Not applicable in BREEAM 2018",
        "assessmentType": "Fully Fitted",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Hea 03",
        "creditDescription": "Not applicable in BREEAM 2018",
        "assessmentType": "Shell and Core",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Hea 03",
        "creditDescription": "Not applicable in BREEAM 2018",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Hea 03",
        "creditDescription": "Not applicable in BREEAM 2018",
        "assessmentType": "Simple Building",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Hea 04",
        "creditDescription": "Thermal Modelling CIBSE AM11",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Hea 04",
        "creditDescription": "Design for future thermal comfort",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Hea 04",
        "creditDescription": "Thermal zoning and controls",
        "assessmentType": "Shell and Core",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Hea 04",
        "creditDescription": "Thermal zoning and controls",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Hea 05",
        "creditDescription": "Acoustic performance",
        "assessmentType": "Shell and Core",
        "value": "0.333333333"
      },
      {
        
        "creditCategoryCode": "Hea 05",
        "creditDescription": "Acoustic performance",
        "assessmentType": "Shell Only",
        "value": "0.333333333"
      },
      {
        
        "creditCategoryCode": "Ene 01",
        "creditDescription": "Prediction of operational energy consumption",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Ene 01",
        "creditDescription": "Prediction of operational energy consumption",
        "assessmentType": "Simple Building",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Ene 02",
        "creditDescription": "Sub-metering of end-use categories",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Ene 02",
        "creditDescription": "Sub-metering of high energy load & tenancy areas",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Ene 05",
        "creditDescription": "Refrigeration energy consumption",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Ene 05",
        "creditDescription": "Refrigeration energy consumption",
        "assessmentType": "Simple Building",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Ene 05",
        "creditDescription": "Indirect greenhouse gas emissions",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Ene 05",
        "creditDescription": "Indirect greenhouse gas emissions",
        "assessmentType": "Simple Building",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Ene 06",
        "creditDescription": "Energy consumption",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Ene 06",
        "creditDescription": "Energy efficient features - Lifts",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Ene 06",
        "creditDescription": "Energy efficient features - Escalators or moving walks",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Ene 06",
        "creditDescription": "Energy efficient features - Escalators or moving walks",
        "assessmentType": "Simple Building",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Ene 07",
        "creditDescription": "Design specification",
        "assessmentType": "Shell and Core",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Ene 07",
        "creditDescription": "Design specification",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Ene 07",
        "creditDescription": "Design specification",
        "assessmentType": "Simple Building",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Ene 07",
        "creditDescription": "Best practice energy efficient measures",
        "assessmentType": "Shell and Core",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Ene 07",
        "creditDescription": "Best practice energy efficient measures",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Ene 07",
        "creditDescription": "Best practice energy efficient measures",
        "assessmentType": "Simple Building",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Ene 08",
        "creditDescription": "Energy efficient equipment ",
        "assessmentType": "Shell and Core",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Ene 08",
        "creditDescription": "Energy efficient equipment ",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Wat 01",
        "creditDescription": "Water consumption",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Wat 03",
        "creditDescription": "Flow control devices",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Wat 04",
        "creditDescription": "Water efficient equipment ",
        "assessmentType": "Simple Building",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Mat 04",
        "creditDescription": "Not applicable in BREEAM 2018",
        "assessmentType": "Fully Fitted",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Mat 04",
        "creditDescription": "Not applicable in BREEAM 2018",
        "assessmentType": "Shell and Core",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Mat 04",
        "creditDescription": "Not applicable in BREEAM 2018",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Mat 04",
        "creditDescription": "Not applicable in BREEAM 2018",
        "assessmentType": "Simple Building",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Wst 01",
        "creditDescription": "Construction resource efficiency",
        "assessmentType": "Simple Building",
        "value": "0.333333333"
      },
      {
        
        "creditCategoryCode": "Wst 01",
        "creditDescription": "RMP (Resource Management Plan) measuring and reporting",
        "assessmentType": "Fully Fitted",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Wst 01",
        "creditDescription": "RMP (Resource Management Plan) measuring and reporting",
        "assessmentType": "Shell and Core",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Wst 01",
        "creditDescription": "RMP (Resource Management Plan) measuring and reporting",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Wst 01",
        "creditDescription": "RMP (Resource Management Plan) measuring and reporting",
        "assessmentType": "Simple Building",
        "value": "2"
      },
      {
        
        "creditCategoryCode": "Wst 04",
        "creditDescription": "Speculative floor and ceiling finishes",
        "assessmentType": "Fully Fitted",
        "value": "1"
      },
      {
        
        "creditCategoryCode": "Wst 04",
        "creditDescription": "Speculative floor and ceiling finishes",
        "assessmentType": "Shell and Core",
        "value": "1"
      },
      {
        
        "creditCategoryCode": "Wst 04",
        "creditDescription": "Speculative floor and ceiling finishes",
        "assessmentType": "Simple Building",
        "value": "1"
      },
      {
        
        "creditCategoryCode": "Pol 01",
        "creditDescription": "No Refrigerants",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Pol 01",
        "creditDescription": "No Refrigerants",
        "assessmentType": "Simple Building",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Pol 01",
        "creditDescription": "Prerequisite - BS EN 378:2016 (ammonia)",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Pol 01",
        "creditDescription": "Prerequisite - BS EN 378:2016 (ammonia)",
        "assessmentType": "Simple Building",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Pol 01",
        "creditDescription": "Impact of refrigerant",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Pol 01",
        "creditDescription": "Impact of refrigerant",
        "assessmentType": "Simple Building",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Pol 01",
        "creditDescription": "Leak detection",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Pol 01",
        "creditDescription": "Leak detection",
        "assessmentType": "Simple Building",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Pol 02",
        "creditDescription": "Local air quality",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Pol 05",
        "creditDescription": "Reduction of noise pollution",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Pol 05",
        "creditDescription": "Reduction of noise pollution",
        "assessmentType": "Simple Building",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Inn 01",
        "creditDescription": "Man 03 - Responsible construction management",
        "assessmentType": "Simple Building",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Inn 02",
        "creditDescription": "Hea 01 - Daylighting",
        "assessmentType": "Simple Building",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Inn 03",
        "creditDescription": "Hea 01 - Internal and external lighting levels, zoning and control",
        "assessmentType": "Shell and Core",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Inn 03",
        "creditDescription": "Hea 01 - Internal and external lighting levels, zoning and control",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Inn 03",
        "creditDescription": "Hea 01 - Internal and external lighting levels, zoning and control",
        "assessmentType": "Simple Building",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Inn 04",
        "creditDescription": "Hea 02 - Minimising sources of air pollution - Emissions from construction products",
        "assessmentType": "Shell and Core",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Inn 04",
        "creditDescription": "Hea 02 - Minimising sources of air pollution - Emissions from construction products",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Inn 04",
        "creditDescription": "Hea 02 - Minimising sources of air pollution - Emissions from construction products",
        "assessmentType": "Simple Building",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Inn 05",
        "creditDescription": "Hea 06 - Security of site and building",
        "assessmentType": "Simple Building",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Inn 06",
        "creditDescription": "Ene 01 - Beyond zero net regulated carbon",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Inn 06",
        "creditDescription": "Ene 01 - Beyond zero net regulated carbon",
        "assessmentType": "Simple Building",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Inn 07",
        "creditDescription": "Ene 01 - Carbon negative",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Inn 07",
        "creditDescription": "Ene 01 - Carbon negative",
        "assessmentType": "Simple Building",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Inn 08",
        "creditDescription": "Ene 01 - Post occupancy stage",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Inn 08",
        "creditDescription": "Ene 01 - Post occupancy stage",
        "assessmentType": "Simple Building",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Inn 09",
        "creditDescription": "Wat 01 - Water consumption",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Inn 09",
        "creditDescription": "Wat 01 - Water consumption",
        "assessmentType": "Simple Building",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Inn 10",
        "creditDescription": "Mat 01 - Core building services options appraisal during Concept Design",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Inn 10",
        "creditDescription": "Mat 01 - Core building services options appraisal during Concept Design",
        "assessmentType": "Simple Building",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Inn 11",
        "creditDescription": "Mat 01 - LCA and LCC alignment",
        "assessmentType": "Simple Building",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Inn 12",
        "creditDescription": "Mat 01 - Third party verification",
        "assessmentType": "Simple Building",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Inn 13",
        "creditDescription": "Mat 03 - Measuring responsible sourcing",
        "assessmentType": "Simple Building",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Inn 14",
        "creditDescription": "Wst 01 - Construction waste management",
        "assessmentType": "Simple Building",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Inn 15",
        "creditDescription": "Wst 02 - Use of recycled and sustainably sourced aggregates",
        "assessmentType": "Simple Building",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Inn 16",
        "creditDescription": "Wst 05 - Responding to climate change",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Inn 16",
        "creditDescription": "Wst 05 - Responding to climate change",
        "assessmentType": "Simple Building",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Inn 17",
        "creditDescription": "LE 02 - Determine the ecological outcomes for the site (sustainability-related activities)",
        "assessmentType": "Shell Only",
        "value": "0"
      },
      {
        
        "creditCategoryCode": "Inn 17",
        "creditDescription": "LE 02 - Determine the ecological outcomes for the site (sustainability-related activities)",
        "assessmentType": "Simple Building",
        "value": "0"
      }]

export {allBREEAMCredits, requiredDocumentaryEvidenceDesignStage, requiredDocumentaryEvidencePCRStage, creditExceptionsForSpecificBuildingTypes, creditExceptionsForSpecificAssessmentTypes, minimumStandards}
