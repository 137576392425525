import { useEffect, useState } from "react";
import Header from "../UI/Header";
import axios from "axios";
import { Doughnut, Bar, Line } from "react-chartjs-2";
import Sidebar from "../UI/Sidebar";
import CountUp from "react-countup";
import { MultiSelect } from "react-multi-select-component";
import ReactApexChart from "react-apexcharts";
import Button from '../UI/Button'

import {
    Chart as ChartJS,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle,
} from "chart.js";

ChartJS.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
);


const DashboardOrganisations = () => {

    const [loading, setLoading] = useState(true);

    const [sidebarToggle, setSidebarToggle] = useState(false);

    const [numberOfRecordsToShow, setNumberOfRecordsToShow] = useState(50);

    const [organisationFootprints, setOrganisationFootprints] = useState([]);
    const [organisations, setOrganisations] = useState([]);

    // const [carbonEmissionsForSubItems, setCarbonEmissionsForSubItems] = useState([]);
    // const [labelsForSubItems, setLabelsForSubItems] = useState([]);

    const [carbonEmissions, setCarbonEmissions] = useState([]);
    const [labels, setLabels] = useState([]);

    const [carbonEmissionsForCategory, setCarbonEmissionsForCategory] = useState([]);
    const [labelsForCategory, setLabelsForCategory] = useState([]);

    const [carbonEmissionsPerYearActuals, setCarbonEmissionsPerYearActuals] = useState([]);
    const [carbonEmissionsPerYearProjections, setCarbonEmissionsPerYearProjections] = useState([]);
    const [carbonEmissionsPerYearTargets, setCarbonEmissionsPerYearTargets] = useState([]);

    const [carbonEmissionsPerYearActualsPerGBPTurnover, setCarbonEmissionsPerYearActualsPerGBPTurnover] = useState([]);
    const [carbonEmissionsPerYearProjectionsPerGBPTurnover, setCarbonEmissionsPerYearProjectionsPerGBPTurnover] = useState([]);
    const [carbonEmissionsPerYearTargetsPerGBPTurnover, setCarbonEmissionsPerYearTargetsPerGBPTurnover] = useState([]);

    const [carbonEmissionsPerYearActualsPerEmployee, setCarbonEmissionsPerYearActualsPerEmployee] = useState([]);
    const [carbonEmissionsPerYearProjectionsPerEmployee, setCarbonEmissionsPerYearProjectionsPerEmployee] = useState([]);
    const [carbonEmissionsPerYearTargetsPerEmployee, setCarbonEmissionsPerYearTargetsPerEmployee] = useState([]);

    const [stackedLineChartCarbonEmissions, setStackedLineChartCarbonEmissions] = useState([]);
    const [stackedLineChartLabels, setStackedLineChartLabels] = useState([]);

    const [stackedBarChartCarbonEmissions, setStackedBarChartCarbonEmissions] = useState([]);
    const [stackedBarChartCarbonEmissionsPerEmployee, setStackedBarChartCarbonEmissionsPerEmployee] = useState([]);
    const [stackedBarChartCarbonEmissionsPerGBP, setStackedBarChartCarbonEmissionsPerGBP] = useState([]);
    const [stackedBarChartLabels, setStackedBarChartLabels] = useState([]);
    // const [stackedBarChartLabelsForSubItems, setStackedBarChartLabelsForSubItems] = useState([]);
    // const [stackedBarChartCarbonEmissionsForSubItems, setStackedBarChartCarbonEmissionsForSubItems] = useState([]);
    // const [stackedBarChartCarbonEmissionsPerEmployeeForSubItems, setStackedBarChartCarbonEmissionsPerEmployeeForSubItems] = useState([]);
    // const [stackedBarChartCarbonEmissionsPerGBPForSubItems, setStackedBarChartCarbonEmissionsPerGBPForSubItems] = useState([]);

    const [singleHorizontalBarChartCarbonEmissionsPerEmployee, setSingleHorizontalBarChartCarbonEmissionsPerEmployee] = useState([]);
    const [singleHorizontalBarChartCarbonEmissionsPerGBP, setSingleHorizontalBarChartCarbonEmissionsPerGBP] = useState([]);
    const [singleHorizontalBarChartLabels, setSingleHorizontalBarChartLabels] = useState([]);

    const [treeMapCarbonEmissions, setTreeMapCarbonEmissions] = useState([]);

    const [selectedOrganisation, setSelectedOrganisation] = useState([]);
    const [selectedYear, setSelectedYear] = useState([]);
    const [selectedSubItem, setSelectedSubItem] = useState([{value:"", label:""}]);
    const [selectedYearDefaults, setSelectedYearDefaults] = useState([]);
    const [allFootprintData, setAllFootprintData] = useState([]);
    // const [subItems, setSubItems] = useState([]);

    const internationalNumberFormat = new Intl.NumberFormat('en-US')

    const [selectedFootprintType, setSelectedFootprintType] = useState([{ value: "Actual (Baseline)", label: "Actual (Baseline)" }]);

    const subCategories = [{ value: "1.1 Stationary Combustion", label: "1.1 Stationary Combustion" },
    { value: "1.2 Mobile Combustion", label: "1.2 Mobile Combustion" },
    { value: "1.3 Refrigerants", label: "1.3 Refrigerants" },
    { value: "2.1 Purchased Electricity", label: "2.1 Purchased Electricity" },
    { value: "3.1 Purchased Goods & Services", label: "3.1 Purchased Goods & Services" },
    { value: "3.2 Capital Goods", label: "3.2 Capital Goods" },
    { value: "3.3 Fuel & Energy", label: "3.3 Fuel & Energy" },
    { value: "3.4 Upstream Transportation & Distribution", label: "3.4 Upstream Transportation & Distribution" },
    { value: "3.5 Waste", label: "3.5 Waste" },
    { value: "3.6 Business Travel", label: "3.6 Business Travel" },
    { value: "3.7 Employee Commuting", label: "3.7 Employee Commuting" },
    { value: "3.8 Upstream Leased Assets", label: "3.8 Upstream Leased Assets" },
    { value: "3.9 Downstream Transportation & Distribution", label: "3.9 Downstream Transportation & Distribution" },
    { value: "3.10 Processing of Sold Products", label: "3.10 Processing of Sold Products" },
    { value: "3.11 Use of Sold Products", label: "3.11 Use of Sold Products" },
    { value: "3.12 End-of-Life Treatment of Sold Products", label: "3.12 End-of-Life Treatment of Sold Products" },
    { value: "3.13 Downstream Leased Assets", label: "3.13 Downstream Leased Assets" },
    { value: "3.14 Franchises", label: "3.14 Franchises" },
    { value: "3.15 Investments", label: "3.15 Investments" }
    ]

    const chartColours = [
        "#0090CA",
        "#00A1AA",
        "#00518C",
        "#B9D3DC",
        "#05cfc0",
        "#0a4f4e",
        "#158a2c",
        "#78de4c",
        "#738a69",
        "#c2df7d",
        "#E89B26",
        "#FCE3BD",
        "#592c19",
        "#ff7276",
        "#FF7C40",
        "#FCF062",
        "#7BA7BC",
        "#5459C1",
        "#753fc2",
        "#827db8",
        "#90286b",
        "#ef66f0",
    ];
    const chartHoverColours = [
        "#10475C",
        "#007980",
        "#0E2343",
        "#96abb3",
        "#036860",
        "#0B4516",
        "#052827",
        "#3C6F26",
        "#3A4535",
        "#61703F",
        "#a16b1a",
        "#CEB691",
        "#2E211C",
        "#802741",
        "#732912",
        "#7A6009",
        "#4d6875",
        "#303372",
        "#3B2061",
        "#413F5C",
        "#481436",
        "#783378",
    ];

    const treeMapOptions = {

        plotOptions: {
            treemap: {
              enableShades: false
            }
          },

        legend: {
            show: true,
            itemMargin: {
                horizontal: 10,
                vertical: 10
            },
            fontFamily: 'TT Norms Pro',
            fontSize: 15,
            color: '#5d6a70'
        },
        chart: {
            height: 350,
            type: 'treemap'
        },
        dataLabels: {
            style: {
              fontSize: 16,
              fontFamily: "TT Norms Pro"
            },
          },
        title: {
            text: 'Carbon Emissions by Organisation per Sub Category tCO2e',
            align: 'center',
            style: {
                fontFamily: 'TT Norms Pro',
                fontSize: 18,
                color: '#5d6a70'
            },
        },
        colors: [
            // '#00518C', '#00A1A8', '#B0D2D8', "#158a2c", "#5459C1", "#FFD800", '#0090CA', "#B9D3DC", "#00E396",
            "#0090CA",
        "#00A1AA",
        "#00518C",
        "#B9D3DC",
        "#05cfc0",
        "#0a4f4e",
        "#158a2c",
        "#78de4c",
        "#738a69",
        "#c2df7d",
        "#E89B26",
        "#FCE3BD",
        "#592c19",
        "#ff7276",
        "#FF7C40",
        "#FCF062",
        "#7BA7BC",
        "#5459C1",
        "#753fc2",
        "#827db8",
        "#90286b",
        "#ef66f0",
        ]
    }

    const [selectedItemSubCategory, setSelectedItemSubCategory] = useState(subCategories);

    const aggregateYearsList = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.value === val.value);
            if (index === -1) {
                acc.push({
                    value: val.value,
                });
            };
            return acc;
        }, []);
    };

    const aggregateYearsListFromFootprintData = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.organisationFootprintYear === val.organisationFootprintYear);
            if (index === -1) {
                acc.push({
                    organisationFootprintYear: val.organisationFootprintYear,
                });
            };
            return acc.sort((item1, item2) => item1.organisationFootprintYear < item2.organisationFootprintYear ? -1 : item1.organisationFootprintYear > item2.organisationFootprintYear ? 1 : 0);
        }, []);
    };

    const aggregateFootprintTypesListFromFootprintData = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.organisationFootprintType === val.organisationFootprintType);
            if (index === -1) {
                acc.push({
                    organisationFootprintType: val.organisationFootprintType,
                });
            };
            return acc;
        }, []);
    };

    const aggregateFootprintItemSubCategoriesListFromFootprintData = arr => {
        return arr.reduce((acc, val) => {
            const index = acc.findIndex(obj => obj.itemSubCategory === val.itemSubCategory);
            if (index === -1) {
                acc.push({
                    itemSubCategory: val.itemSubCategory,
                });
            };
            return acc;
        }, []);
    };

    // const aggregateSubItems = arr => {
    //     return arr.reduce((acc, val) => {
    //         const index = acc.findIndex(obj => obj.description === val.description);
    //         if (index === -1) {
    //             acc.push({
    //                 description: val.description,
    //             });
    //         };
    //         return acc;
    //     }, []);
    // };

    //Chart 5 ( 3 by Sub Category per Organisation)

    const dataChart5 = {
        labels: labels,
        datasets: [
            {
                data: carbonEmissions,
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

        //Chart 6 ( Category per Organisation)

        const dataChart6 = {
            labels: labelsForCategory,
            datasets: [
                {
                    data: carbonEmissionsForCategory,
                    backgroundColor: chartColours,
                    hoverBackgroundColor: chartHoverColours,
                },
            ],
        };

        const dataChart6PerEmployee = {
            labels: singleHorizontalBarChartLabels,
            datasets: [
                {
                    data: singleHorizontalBarChartCarbonEmissionsPerEmployee,
                    backgroundColor: chartColours,
                    hoverBackgroundColor: chartHoverColours,
                },
            ],
        };

        const dataChart6PerGBP = {
            labels: singleHorizontalBarChartLabels,
            datasets: [
                {
                    data: singleHorizontalBarChartCarbonEmissionsPerGBP,
                    backgroundColor: chartColours,
                    hoverBackgroundColor: chartHoverColours,
                },
            ],
        };

    // const dataChart10 = {
    //     labels: labelsForSubItems.slice(0,numberOfRecordsToShow),
    //     datasets: [
    //         {
    //             data: carbonEmissionsForSubItems.slice(0,numberOfRecordsToShow),
    //             backgroundColor: chartColours,
    //             hoverBackgroundColor: chartHoverColours,
    //         },
    //     ],
    // };

    //Chart 7
    const dataChart7 = {
        labels: selectedYear.sort((item1, item2) => item1.label < item2.label ? -1 : item1.label > item2.label ? 1 : 0).map(item => item.label),
        datasets: stackedLineChartCarbonEmissions
    };

    //Chart 7b
    const dataChart7b = {
        labels: stackedBarChartLabels,
        datasets: stackedBarChartCarbonEmissions
    };

    //Chart 7bb
    const dataChart7bb = {
        labels: stackedBarChartLabels,
        datasets: stackedBarChartCarbonEmissionsPerEmployee
    };

    //Chart 7bc
    const dataChart7bc = {
        labels: stackedBarChartLabels,
        datasets: stackedBarChartCarbonEmissionsPerGBP
    };


    //Chart 7bbSingleHorizontal
    const dataChart7bbSingleHorizontal = {
        labels: singleHorizontalBarChartLabels,
        datasets: [
            {
                data: singleHorizontalBarChartCarbonEmissionsPerEmployee,
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

    //Chart 7bcSingleHorizontal
    const dataChart7bcSingleHorizontal = {
        labels: singleHorizontalBarChartLabels,
        datasets: [
            {
                data: singleHorizontalBarChartCarbonEmissionsPerGBP,
                backgroundColor: chartColours,
                hoverBackgroundColor: chartHoverColours,
            },
        ],
    };

    // //Chart 7bForSubItems
    // const dataChart7bForSubItems = {
    //     labels: stackedBarChartLabelsForSubItems,
    //     datasets: stackedBarChartCarbonEmissionsForSubItems
    // };

    // //Chart 7bb
    // const dataChart7bbForSubItems = {
    //     labels: stackedBarChartLabelsForSubItems,
    //     datasets: stackedBarChartCarbonEmissionsPerEmployeeForSubItems
    // };

    // //Chart 7bc
    // const dataChart7bcForSubItems = {
    //     labels: stackedBarChartLabelsForSubItems,
    //     datasets: stackedBarChartCarbonEmissionsPerGBPForSubItems
    // };

    //Chart 7c
    const dataChart7c = {
        labels: selectedYear.sort((item1, item2) => item1.label < item2.label ? -1 : item1.label > item2.label ? 1 : 0).map(item => item.label),
        datasets: [
            {
                label: 'Target Emissions - Maximum acceptable carbon emissions in line with NCS protocol in order to achieve Net Zero Carbon as defined by SBTi',
                type: 'line',
                borderColor: chartColours[2],
                data: carbonEmissionsPerYearTargets,
                spanGaps: true,
                fill: 'origin'
            },
            {
                label: 'Forecast Emissions - Estimated carbon emissions in line with proposed reduction measures',
                type: 'line',
                borderColor: chartColours[1],
                data: carbonEmissionsPerYearProjections,
                spanGaps: true
            },
            {
                label: 'Actual Emissions - Verified carbon emissions per the reporting year in tCO2e',
                type: 'bar',
                fill: true,
                backgroundColor: chartColours[0],
                data: carbonEmissionsPerYearActuals
            },

        ]
    };

    //Chart 7d
    const dataChart7d = {
        labels: selectedYear.sort((item1, item2) => item1.label < item2.label ? -1 : item1.label > item2.label ? 1 : 0).map(item => item.label),
        datasets: [
            {
                label: 'Target Emissions per 1000 Base Currency Units of Turnover - Maximum acceptable carbon emissions in line with NCS protocol in order to achieve Net Zero Carbon as defined by SBTi',
                type: 'line',
                borderColor: chartColours[2],
                data: carbonEmissionsPerYearTargetsPerGBPTurnover,
                spanGaps: true,
                fill: 'origin'
            },
            {
                label: 'Forecast Emissions per 1000 Base Currency Units of Turnover - Estimated carbon emissions in line with proposed reduction measures',
                type: 'line',
                borderColor: chartColours[1],
                data: carbonEmissionsPerYearProjectionsPerGBPTurnover,
                spanGaps: true
            },
            {
                label: 'Actual Emissions per 1000 Base Currency Units of Turnover - Verified carbon emissions per the reporting year in tCO2e',
                type: 'bar',
                fill: true,
                backgroundColor: chartColours[0],
                data: carbonEmissionsPerYearActualsPerGBPTurnover
            },

        ]
    };

    //Chart 7e
    const dataChart7e = {
        labels: selectedYear.sort((item1, item2) => item1.label < item2.label ? -1 : item1.label > item2.label ? 1 : 0).map(item => item.label),
        datasets: [
            {
                label: 'Target Emissions Per Employee - Maximum acceptable carbon emissions in line with NCS protocol in order to achieve Net Zero Carbon as defined by SBTi',
                type: 'line',
                borderColor: chartColours[2],
                data: carbonEmissionsPerYearTargetsPerEmployee,
                spanGaps: true,
                fill: 'origin'
            },
            {
                label: 'Forecast Emissions Per Employee - Estimated carbon emissions in line with proposed reduction measures',
                type: 'line',
                borderColor: chartColours[1],
                data: carbonEmissionsPerYearProjectionsPerEmployee,
                spanGaps: true
            },
            {
                label: 'Actual Emissions Per Employee - Verified carbon emissions per the reporting year in tCO2e',
                type: 'bar',
                fill: true,
                backgroundColor: chartColours[0],
                data: carbonEmissionsPerYearActualsPerEmployee
            },

        ]
    };

    //Pie Options
    const pieOptions = {
        plugins: {
            title: {
                display: true,
                text: 'Carbon Emissions per Sub Category tCO2e',
                font: {
                    size: 20
                }
            },
            legend: {
                display: true,
                maxWidth: 100,
                position: "bottom",
                labels: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    },
                },
            },
            datalabels: {
                display: false,
            }
        },
    };

    const pieOptionsPerGBP = {
        plugins: {
            title: {
                display: true,
                text: 'Carbon Emissions by Sub Category tCO2e per 1000 Base Currency Units of Turnover',
                font: {
                    size: 20
                }
            },
            legend: {
                display: true,
                maxWidth: 100,
                position: "bottom",
                labels: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    },
                },
            },
            datalabels: {
                display: false,
            }
        },
    };

    const pieOptionsPerEmployee = {
        plugins: {
            title: {
                display: true,
                text: 'Carbon Emissions per Employee by Sub Category tCO2e',
                font: {
                    size: 20
                }
            },
            legend: {
                display: true,
                maxWidth: 100,
                position: "bottom",
                labels: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    },
                },
            },
            datalabels: {
                display: false,
            }
        },
    };

    const pieOptionsForCategory = {
        plugins: {
            title: {
                display: true,
                text: 'Carbon Emissions per Scope tCO2e',
                font: {
                    size: 20
                }
            },
            legend: {
                display: true,
                maxWidth: 100,
                position: "bottom",
                labels: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    },
                },
            },
            datalabels: {
                display: false,
            }
        },
    };

    const barOptionsStacked = {

        plugins: {
            title: {
                display: true,
                text: 'Absolute Carbon Emissions by Organisation per Sub Category tCO2e',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: true,
                maxWidth: 100,
                position: "bottom",
                labels: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    },
                },
            },
            datalabels: {
                display: false,
            }
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            },
            y: {
                stacked: true,
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            }
        },

    };

    const barOptionsStackedPerEmployee = {

        plugins: {
            title: {
                display: true,
                text: 'Intensity Based Carbon Emissions by Organisation per Sub Category tCO2e per Employee',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: true,
                maxWidth: 100,
                position: "bottom",
                labels: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    },
                },
            },
            datalabels: {
                display: false,
            }
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            },
            y: {
                stacked: true,
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            }
        },

    };

    const barOptionsStackedPerGBP = {

        plugins: {
            title: {
                display: true,
                text: 'Intensity Based Carbon Emissions by Organisation per Sub Category tCO2e per 1000 Base Currency Units of Turnover',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: true,
                maxWidth: 100,
                position: "bottom",
                labels: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    },
                },
            },
            datalabels: {
                display: false,
            }
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            },
            y: {
                stacked: true,
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            }
        },

    };

    // const barOptionsStackedForSubItems = {

    //     plugins: {
    //         title: {
    //             display: true,
    //             text: 'Absolute Carbon Emissions by Organisation per Sub Item tCO2e',
    //             font: {
    //                 size: 20,
    //                 family: 'TT Norms Pro'
    //             }
    //         },
    //         legend: {
    //             display: true,
    //             maxWidth: 100,
    //             position: "bottom",
    //             labels: {
    //                 font: {
    //                     size: 15,
    //                     family: 'TT Norms Pro'
    //                 },
    //             },
    //         },
    //         datalabels: {
    //             display: false,
    //         }
    //     },
    //     responsive: true,
    //     scales: {
    //         x: {
    //             stacked: true,
    //             ticks: {
    //                 font: {
    //                     size: 15,
    //                     family: 'TT Norms Pro'
    //                 }
    //             }
    //         },
    //         y: {
    //             stacked: true,
    //             ticks: {
    //                 font: {
    //                     size: 15,
    //                     family: 'TT Norms Pro'
    //                 }
    //             }
    //         }
    //     },

    // };

    // const barOptionsStackedPerEmployeeForSubItems = {

    //     plugins: {
    //         title: {
    //             display: true,
    //             text: 'Intensity Based Carbon Emissions by Organisation per Sub Item tCO2e per Employee',
    //             font: {
    //                 size: 20,
    //                 family: 'TT Norms Pro'
    //             }
    //         },
    //         legend: {
    //             display: true,
    //             maxWidth: 100,
    //             position: "bottom",
    //             labels: {
    //                 font: {
    //                     size: 15,
    //                     family: 'TT Norms Pro'
    //                 },
    //             },
    //         },
    //         datalabels: {
    //             display: false,
    //         }
    //     },
    //     responsive: true,
    //     scales: {
    //         x: {
    //             stacked: true,
    //             ticks: {
    //                 font: {
    //                     size: 15,
    //                     family: 'TT Norms Pro'
    //                 }
    //             }
    //         },
    //         y: {
    //             stacked: true,
    //             ticks: {
    //                 font: {
    //                     size: 15,
    //                     family: 'TT Norms Pro'
    //                 }
    //             }
    //         }
    //     },

    // };

    // const barOptionsStackedPerGBPForSubItems = {

    //     plugins: {
    //         title: {
    //             display: true,
    //             text: 'Intensity Based Carbon Emissions by Organisation per Sub Item tCO2e per 1000 Base Currency Units of Turnover',
    //             font: {
    //                 size: 20,
    //                 family: 'TT Norms Pro'
    //             }
    //         },
    //         legend: {
    //             display: true,
    //             maxWidth: 100,
    //             position: "bottom",
    //             labels: {
    //                 font: {
    //                     size: 15,
    //                     family: 'TT Norms Pro'
    //                 },
    //             },
    //         },
    //         datalabels: {
    //             display: false,
    //         }
    //     },
    //     responsive: true,
    //     scales: {
    //         x: {
    //             stacked: true,
    //             ticks: {
    //                 font: {
    //                     size: 15,
    //                     family: 'TT Norms Pro'
    //                 }
    //             }
    //         },
    //         y: {
    //             stacked: true,
    //             ticks: {
    //                 font: {
    //                     size: 15,
    //                     family: 'TT Norms Pro'
    //                 }
    //             }
    //         }
    //     },

    // };

    const barOptionsHorizontal = {

        indexAxis: 'y',
        scales: {
            y: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            },
            x: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            }
        },

        plugins: {
            title: {
                display: true,
                text: 'Carbon Emissions per Sub Category tCO2e',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: false,
            },
            datalabels: {
                display: false,
            }
        },
        

        responsive: true,

    };

    const barOptionsHorizontalForCategory = {

        indexAxis: 'y',
        scales: {
            y: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            },
            x: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            }
        },

        plugins: {
            title: {
                display: true,
                text: 'Carbon Emissions per Scope tCO2e',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: false,
            },
            datalabels: {
                display: false,
            }
        },
        

        responsive: true,

    };

    const barOptionsHorizontalPerGBP = {

        indexAxis: 'y',
        scales: {
            y: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            },
            x: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            }
        },

        plugins: {
            title: {
                display: true,
                text: 'Intensity Based Carbon Emissions by Organisation per Sub Item tCO2e per per 1000 Base Currency Units of Turnover',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: false,
            },
            datalabels: {
                display: false,
            }
        },
        

        responsive: true,

        options: {
            maintainAspectRatio: false,
        }

    };

    const barOptionsHorizontalPerEmployee = {

        indexAxis: 'y',
        scales: {
            y: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            },
            x: {
                ticks: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    }
                }
            }
        },

        plugins: {
            title: {
                display: true,
                text: 'Intensity Based Carbon Emissions by Organisation per Sub Item tCO2e per Employee',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: false,
            },
            datalabels: {
                display: false,
            }
        },
        

        responsive: true,
        
        options: {
            maintainAspectRatio: false,
        }


    };

    // const barOptionsHorizontalForSubItems = {

    //     indexAxis: 'y',
    //     scales: {
    //         y: {
    //             ticks: {
    //                 font: {
    //                     size: 15,
    //                     family: 'TT Norms Pro'
    //                 }
    //             }
    //         },
    //         x: {
    //             ticks: {
    //                 font: {
    //                     size: 15,
    //                     family: 'TT Norms Pro'
    //                 }
    //             }
    //         }
    //     },

    //     plugins: {
    //         title: {
    //             display: true,
    //             text: 'Carbon Emissions per Sub Item tCO2e',
    //             font: {
    //                 size: 20,
    //                 family: 'TT Norms Pro'
    //             }
    //         },
    //         legend: {
    //             display: false,
    //         },
    //         datalabels: {
    //             display: false,
    //         }
    //     },

    //     responsive: true,

    //     options: {
    //         maintainAspectRatio: false,
    //     }

    // };

    const lineBarOptions = {
        elements: {
            line: {
                tension: 0.5,
            },
            point: {
                radius: 2
            }
        },

        scales: {
            y: {
                min: 0,
                stacked: true,
                ticks: {
                    font: {
                    size: 15,
                    family: 'TT Norms Pro'
                    }
                }
            },
            x: {
                ticks: {
                    font: {
                    size: 15,
                    family: 'TT Norms Pro'
                    }
                }
            },
        },
        plugins: {
            title: {
                display: true,
                text: 'Carbon Emissions per year tCO2e',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: true,
                position: "bottom",
                labels: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    },
                },
            },
            datalabels: {
                display: false,
            }
        },
    };


    const [company, setCompany] = useState("")
    const [role, setRole] = useState(0)
    const [userPropertiesLoading, setUserPropertiesLoading] = useState(true)

    const getUserProperties = async () => {

        await axios.get(process.env.REACT_APP_API_URL + "/current_user_properties")
            .then((response) => {
                setCompany(response.data.company)
                setRole(response.data.role)
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setUserPropertiesLoading(false);
            });

    }

    useEffect(() => {

        setUserPropertiesLoading(true)

        getUserProperties()

    }, [])

    // const tooltip = document.getElementById("tooltip")

    const barAndLineOptions = {
        
        plugins: {

            title: {
                display: true,
                text: 'Scope 1 and 2 Forecast vs Target vs Actual tCO2e',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },    
           
            legend: {
                display: true,
                maxWidth: 100,
                position: "bottom",
                // onHover: function(event, target) {
                //     var text = target.text
                //     if (text === "Target Emissions") {
                //         text = "Maximum acceptable carbon emissions in line with NCS protocol in order to achieve Net Zero Carbon as defined by SBTi, 90% absolute reduction or 97% intensity reduction (only applicable for Scope 3)"
                //     }
                //     if (text === "Forecast Emissions") {
                //         text = "Estimated carbon emissions in line with proposed reduction measures"
                //     }
                //     if (text === "Actual Emissions") {
                //         text = "Verified carbon emissions per the reporting year in tCO2e"
                //     }
                //     tooltip.innerHTML = text;
                //     tooltip.style.left = event.x + 10 + "px";
                //     tooltip.style.top = event.y + 70 + "px";
                //     tooltip.style.backgroundColor = "black";
                //     tooltip.style.opacity = 0.8;
                //   },
                //   onLeave: function() {
                //     tooltip.innerHTML = "";
                //     tooltip.style.backgroundColor = "white";
                //   },
                labels: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    },
                },
            },
            datalabels: {
                display: false,
            }
        },
        responsive: true,
        scales: {
            y: {
                ticks: {
                    font: {
                    size: 15,
                    family: 'TT Norms Pro'
                    }
                }
            },
            x: {
                ticks: {
                    font: {
                    size: 15,
                    family: 'TT Norms Pro'
                    }
                }
            },
        },
    }

    const barAndLineOptionsPerGBP = {
        plugins: {
            title: {
                display: true,
                text: 'Scope 3 Forecast vs Target vs Actual tCO2e per 1000 Base Currency Units of Turnover',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: true,
                maxWidth: 100,
                position: "bottom",
                labels: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    },
                },
            },
            datalabels: {
                display: false,
            }
        },
        responsive: true,
        scales: {
            y: {
                ticks: {
                    font: {
                    size: 15,
                    family: 'TT Norms Pro'
                    }
                }
            },
            x: {
                ticks: {
                    font: {
                    size: 15,
                    family: 'TT Norms Pro'
                    }
                }
            },
        },
    }

    const barAndLineOptionsPerEmployee = {
        plugins: {
            title: {
                display: true,
                text: 'Scope 3 Forecast vs Target vs Actual tCO2e per Employee',
                font: {
                    size: 20,
                    family: 'TT Norms Pro'
                }
            },
            legend: {
                display: true,
                maxWidth: 100,
                position: "bottom",
                labels: {
                    font: {
                        size: 15,
                        family: 'TT Norms Pro'
                    },
                },
            },
            datalabels: {
                display: false,
            }
        },
        responsive: true,
        scales: {
            y: {
                ticks: {
                    font: {
                    size: 15,
                    family: 'TT Norms Pro'
                    }
                }
            },
            x: {
                ticks: {
                    font: {
                    size: 15,
                    family: 'TT Norms Pro'
                    }
                }
            },
        },
    }


    useEffect(() => {

        if (company && role && !userPropertiesLoading) {

            setSelectedItemSubCategory(subCategories.filter(item => item.value.slice(0,2) === "1." || item.value.slice(0,2) === "2."))

            if (role === 3001 || role === 6001) {

                //Code here to call special API endpoint returning only organisations for that client
                axios.get(process.env.REACT_APP_API_URL + '/organisations_by_clientId/' + company)
                    .then((res) => {
                        setOrganisations(res.data);
                        setSelectedOrganisation(res.data.map((item) => {
                            return {
                                label: item.organisationName,
                                value: item.id
                            }
                        }))

                        axios.get(process.env.REACT_APP_API_URL + "/organisations_footprints_without_subItems_by_clientId/" + company)
                            .then((res2) => {

                                var filteredResByOrganisations = res2.data.filter((itemA) => {
                                    return res.data.map((item) => {
                                        return {
                                            label: item.organisationName,
                                            value: item.id
                                        }
                                    }).find((itemB) => {
                                        return itemA.organisationId === itemB.value;
                                    })
                                })

                                setAllFootprintData(filteredResByOrganisations.filter(item => item.organisationFootprintCertification !== ""))

                                setSelectedYear(aggregateYearsListFromFootprintData(filteredResByOrganisations).map((item) => {
                                    return {
                                        label: item.organisationFootprintYear,
                                        value: item.organisationFootprintYear
                                    }
                                }))
                                setSelectedYearDefaults(aggregateYearsListFromFootprintData(filteredResByOrganisations).map((item) => {
                                    return {
                                        label: item.organisationFootprintYear,
                                        value: item.organisationFootprintYear
                                    }
                                }))
                            })
                            .catch((err) => {
                                console.log(err);
                            })

                    })
                    .catch((err) => {
                        console.log(err);
                    }).finally(() => {
                        setLoading(false);
                    });

            } else {

                axios.get(process.env.REACT_APP_API_URL + '/organisations')
                    .then((res) => {
                        setOrganisations(res.data);
                        // setSelectedOrganisation(res.data.map((item) => {
                        //     return {
                        //         label: item.organisationName,
                        //         value: item.id
                        //     }
                        // }))
                    })
                    .catch((err) => {
                        console.log(err);
                    }).finally(() => {
                        // setLoading(false);
                    });

                axios.get(process.env.REACT_APP_API_URL + "/organisations_footprints_without_subItems")
                    .then((res) => {

                        setAllFootprintData(res.data.filter(item => item.organisationFootprintCertification !== ""))

                        setSelectedYear(aggregateYearsListFromFootprintData(res.data).map((item) => {
                            return {
                                label: item.organisationFootprintYear,
                                value: item.organisationFootprintYear
                            }
                        }))
                        setSelectedYearDefaults(aggregateYearsListFromFootprintData(res.data).map((item) => {
                            return {
                                label: item.organisationFootprintYear,
                                value: item.organisationFootprintYear
                            }
                        }))
                    })
                    .catch((err) => {
                        console.log(err);
                    })
                    .finally(() => {
                        setLoading(false);
                    });

            }

        }

    }, [role, company]);

    // useEffect(() => {

    //     if (selectedSubItem !== [{value:"", label:""}]) {

    //         console.log("reset")

    //         console.log(selectedSubItem)

    //         setSelectedSubItem([{value:"", label:""}])

    //     }

    // }, [selectedOrganisation, selectedItemSubCategory, selectedYear, selectedFootprintType]);

    useEffect(() => {

        if (selectedItemSubCategory.length > 0) {
            if ((selectedItemSubCategory.find(item => item.value.slice(0,2) === "1.") && selectedItemSubCategory.find(item => item.value.slice(0,2) === "3.")) || (selectedItemSubCategory.find(item => item.value.slice(0,2) === "2.") && selectedItemSubCategory.find(item => item.value.slice(0,2) === "3."))) {

                setSelectedItemSubCategory(subCategories.filter(item => item.value.slice(0,2) === selectedItemSubCategory[selectedItemSubCategory.length -1].value.slice(0,2)))

            }
        }

        if (company && role && !userPropertiesLoading) {

            // if (selectedFootprintType.find(item => (item.value === "Target" || item.value === "Forecast") && selectedFootprintType.length === 2)) {

            //     selectedFootprintType.shift()

            // } else if (selectedFootprintType.find(item => (item.value === "Target" || item.value === "Forecast") && selectedFootprintType.length > 2)) {

            //     selectedFootprintType.shift()
            //     selectedFootprintType.shift()

            // }

            // axios.get(process.env.REACT_APP_API_URL + "/organisations_footprints_without_subItems")
            //     .then((res) => {

            //         console.log(res.data)

            //Set organisationFootprints for Multiselect filter
            setOrganisationFootprints(
                allFootprintData.filter(item => item.organisationFootprintCertification !== "").sort((item1, item2) =>
                    item1.organisationFootprintRef < item2.organisationFootprintRef
                        ? -1
                        : item1.organisationFootprintRef > item2.organisationFootprintRef
                            ? 1
                            : 0
                )
            );

            //Filter the organisationFootprints to selected organisation ids and footprint ids
            var filteredResByOrganisations = []
            var filteredResByOrganisationsAndFootprintType = []
            var filteredResByOrganisationsAndFootprintTypeAndYear = []
            var filteredRes = allFootprintData.filter(item => item.organisationFootprintCertification !== "")

            var filteredResActuals = []
            var filteredResProjections = []
            var filteredResTargets = []

            filteredResByOrganisations = filteredRes.filter((itemA) => {
                return selectedOrganisation.find((itemB) => {
                    return itemA.organisationId === itemB.value;
                })
            })

            filteredResByOrganisationsAndFootprintType = filteredResByOrganisations.filter((itemA) => {
                return selectedFootprintType.find((itemB) => {
                    return itemA.organisationFootprintType === itemB.value;
                })
            })

            filteredResByOrganisationsAndFootprintTypeAndYear = filteredResByOrganisationsAndFootprintType.filter((itemA) => {
                return selectedYear.find((itemB) => {
                    return itemA.organisationFootprintYear === itemB.value;
                })
            })

            //get all of the selected organisationFootprints and concatenat their footprintItems arrays
            var concatenatedListOfItems = [];
            //var arrayOfYearlyEmissionsBySubCategory = [];
            var arrayOfOrganisationEmissionsBySubCategory = [];
            var index = 0

            var i;
            for (i = 0; i < filteredResByOrganisationsAndFootprintTypeAndYear.length; i++) {
                concatenatedListOfItems = concatenatedListOfItems.concat(
                    filteredResByOrganisationsAndFootprintTypeAndYear[i].organisationFootprintItems.filter((itemA) => {
                        return selectedItemSubCategory.find((itemB) => {
                            return itemA.itemSubCategory === itemB.value;
                        })
                    })
                );
            }

            // console.log(concatenatedListOfItems)

            //Reduce to itemSubCategory and sum carbonEmissions
            var result = [];
            concatenatedListOfItems.reduce(function (res, value) {
                if (!res[value.itemSubCategory]) {
                    res[value.itemSubCategory] = {
                        itemSubCategory: value.itemSubCategory,
                        carbonEmissions: 0,
                    };
                    result.push(res[value.itemSubCategory]);
                }
                res[value.itemSubCategory].carbonEmissions +=
                    value.carbonEmissions/1000;
                return res;
            }, {});

            //Set labels (sub categories) and carbon emissions data

            result.sort((item1, item2) => item1.carbonEmissions > item2.carbonEmissions ? -1 : item1.carbonEmissions < item2.carbonEmissions ? 1 : 0)

            var scopeSubCategoryData = result.map((item) => item.carbonEmissions);
            var scopeSubCategoryLabels = result.map(
                (item) => item.itemSubCategory
            );

            setLabels(scopeSubCategoryLabels);
            setCarbonEmissions(scopeSubCategoryData);

            //Reduce to itemCategory and sum carbonEmissions
            result = [];
            concatenatedListOfItems.reduce(function (res, value) {
                if (!res[value.itemCategory]) {
                    res[value.itemCategory] = {
                        itemCategory: value.itemCategory,
                        carbonEmissions: 0,
                    };
                    result.push(res[value.itemCategory]);
                }
                res[value.itemCategory].carbonEmissions +=
                    value.carbonEmissions/1000;
                return res;
            }, {});

            //Set labels (categories) and carbon emissions data

            result.sort((item1, item2) => item1.carbonEmissions > item2.carbonEmissions ? -1 : item1.carbonEmissions < item2.carbonEmissions ? 1 : 0)

            var scopeCategoryData = result.map((item) => item.carbonEmissions);
            var scopeCategoryLabels = result.map(
                (item) => item.itemCategory
            );

            setLabelsForCategory(scopeCategoryLabels);
            setCarbonEmissionsForCategory(scopeCategoryData);


            //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

            //get all of the concatinated organisationFootprintItems and concatenat their subItems arrays
        
            // var concatenatedListOfSubItems = [];
            // var concatenatedListOfAllSubItems = [];

            // index = 0

            // for (i = 0; i < concatenatedListOfItems.length; i++) {
            //     concatenatedListOfAllSubItems = concatenatedListOfAllSubItems.concat(
            //         concatenatedListOfItems[i].footprintSubItems
            //     );
            // }

            // for (i = 0; i < concatenatedListOfItems.length; i++) {
            //     concatenatedListOfSubItems = concatenatedListOfSubItems.concat(
            //         concatenatedListOfItems[i].footprintSubItems.filter((itemA) => {
            //             return selectedSubItem.find((itemB) => {
            //                 return itemA.description=== itemB.value;
            //             })
            //         })
            //     );
            // }

            // //Reduce to description and sum carbonEmissions

            // result = [];
            // concatenatedListOfSubItems.reduce(function (res, value) {
            //     if (!res[value.description]) {
            //         res[value.description] = {
            //             description: value.description,
            //             carbonEmissions: 0,
            //         };
            //         result.push(res[value.description]);
            //     }
            //     res[value.description].carbonEmissions +=
            //         value.carbonEmissions/1000;
            //     return res;
            // }, {});

            // setSubItems(aggregateSubItems(concatenatedListOfAllSubItems).sort((item1, item2) => item1.description < item2.description ? -1 : item1.description > item2.description ? 1 : 0))

            // //Set labels (sub sub items) and carbon emissions data

            // result.sort((item1, item2) => item1.carbonEmissions > item2.carbonEmissions ? -1 : item1.carbonEmissions < item2.carbonEmissions ? 1 : 0)

            // var scopeSubItemData = result.map((item) => item.carbonEmissions);
            // var scopeSubItemLabels = result.map(
            //     (item) => item.description
            // );

            

            // setLabelsForSubItems(scopeSubItemLabels);
            // setCarbonEmissionsForSubItems(scopeSubItemData);

            //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!


            //Code Here to get to emissions per scopeCategory per year

            const carbonEmissionsPerYearPerSubCategory = [];

            // const listOfSubCategories = [
            //     ...new Set(
            //         concatenatedListOfItems.map(
            //             (item) => item.itemSubCategory
            //         )
            //     ),
            // ];

            var j, k, subCategory, emissions, year;
            var datasetsArray = [];
            var datasetsArrayPerEmployee = [];
            var datasetsArrayPerGBP = [];
            var emissionsForThatSubCategory = [];
            var arrayOfYearlyEmissions = [];
            var carbonEmissionsPerYearPerSubCategorySorted = [];

            for (i = 0; i < selectedYear.length; i++) {
                concatenatedListOfItems = [];

                year = selectedYear.sort((item1, item2) => item1.label < item2.label ? -1 : item1.label > item2.label ? 1 : 0).map(item => item.label)[i];

                for (k = 0; k < filteredResByOrganisationsAndFootprintTypeAndYear.length; k++) {
                    if (
                        filteredResByOrganisationsAndFootprintTypeAndYear[k].organisationFootprintYear === year
                    ) {
                        concatenatedListOfItems =
                            concatenatedListOfItems.concat(
                                filteredResByOrganisationsAndFootprintTypeAndYear[k].organisationFootprintItems
                            );
                    }
                }

                for (j = 0; j < scopeSubCategoryLabels.length; j++) {
                    subCategory = scopeSubCategoryLabels[j];
                    emissions = concatenatedListOfItems
                        .filter((item) => item.itemSubCategory === subCategory)
                        .map((item) => item.carbonEmissions)
                        .reduce((prev, curr) => Number(prev) + Number(curr), 0);
                    carbonEmissionsPerYearPerSubCategory.push({
                        year,
                        subCategory,
                        emissions,
                    });

                }

            }

            carbonEmissionsPerYearPerSubCategorySorted =
                carbonEmissionsPerYearPerSubCategory.sort((item1, item2) =>
                    item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0
                );

            for (i = 0; i < scopeSubCategoryLabels.length; i++) {
                subCategory = scopeSubCategoryLabels[i];
                emissionsForThatSubCategory =
                    carbonEmissionsPerYearPerSubCategorySorted.filter(
                        (item) => item.subCategory === subCategory
                    );

                arrayOfYearlyEmissions = [];

                for (j = 0; j < selectedYear.length; j++) {
                    year = selectedYear.sort((item1, item2) => item1.label < item2.label ? -1 : item1.label > item2.label ? 1 : 0).map(item => item.label)[j];
                    emissions = emissionsForThatSubCategory
                        .filter((item) => item.year === year)
                        .map((item) => item.emissions)
                        .reduce((prev, curr) => Number(prev) + Number(curr), 0);
                    arrayOfYearlyEmissions.push(emissions/1000);

                }

                datasetsArray.push({
                    label: subCategory,
                    data: arrayOfYearlyEmissions,
                    backgroundColor: chartColours,
                    borderColor: chartColours,
                    hoverBackgroundColor: chartHoverColours,
                    fill: true,
                    pointBorderColor: '#F1F6F8',
                    pointBackgroundColor: chartColours[i]
                });

            }

            setStackedLineChartLabels(selectedYear.sort((item1, item2) => item1.label < item2.label ? -1 : item1.label > item2.label ? 1 : 0).map(item => item.label));
            setStackedLineChartCarbonEmissions(datasetsArray);

            //Code here to calculate Actuals, Projections and Targets per year
            //Actuals

            filteredResActuals = filteredResByOrganisations.filter(item => item.organisationFootprintType === "Actual" || item.organisationFootprintType === "Actual (Baseline)")

            var carbonEmissionsPerYear = [];
            var carbonEmissionsPerYearPerGBP = [];
            var carbonEmissionsByTurnoverGBP;
            var carbonEmissionsPerYearPerEmployee = [];
            var carbonEmissionsByEmployee;

            for (i = 0; i < aggregateYearsList(selectedYear).length; i++) {
                concatenatedListOfItems = [];

                year = aggregateYearsList(selectedYear)[i].value

                for (k = 0; k < filteredResActuals.length; k++) {
                    if (
                        filteredResActuals[k].organisationFootprintYear === year
                    ) {
                        concatenatedListOfItems =
                            concatenatedListOfItems.concat(
                                filteredResActuals[k].organisationFootprintItems
                            );
                    }
                }

                emissions = concatenatedListOfItems.filter((itemA) => {
                    return selectedItemSubCategory.find((itemB) => {
                        return itemA.itemSubCategory === itemB.value;
                    })
                })
                    .map((item) => item.carbonEmissions)
                    .reduce((prev, curr) => Number(prev) + Number(curr), 0)/1000;
                if (emissions === 0) {
                    emissions = "N/A"
                }
                carbonEmissionsPerYear.push({
                    year,
                    emissions,
                });

                carbonEmissionsByTurnoverGBP = emissions / (allFootprintData.filter(item => item.organisationFootprintYear === year && (item.organisationFootprintType === "Actual" || item.organisationFootprintType === "Actual (Baseline)")).filter((itemA) => {
                    return selectedOrganisation.find((itemB) => {
                        return itemA.organisationId === itemB.value;
                    })
                }).map(item => item.organisationTurnoverGBP).reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000)

                carbonEmissionsPerYearPerGBP.push({
                    year,
                    emissions: carbonEmissionsByTurnoverGBP
                });

                carbonEmissionsByEmployee = emissions / (allFootprintData.filter(item => item.organisationFootprintYear === year && (item.organisationFootprintType === "Actual" || item.organisationFootprintType === "Actual (Baseline)")).filter((itemA) => {
                    return selectedOrganisation.find((itemB) => {
                        return itemA.organisationId === itemB.value;
                    })
                }).map(item => item.organisationNumberOfEmployees).reduce((prev, curr) => Number(prev) + Number(curr), 0))

                carbonEmissionsPerYearPerEmployee.push({
                    year,
                    emissions: carbonEmissionsByEmployee
                });

            }

            setCarbonEmissionsPerYearActuals(carbonEmissionsPerYear.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));
            setCarbonEmissionsPerYearActualsPerGBPTurnover(carbonEmissionsPerYearPerGBP.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));
            setCarbonEmissionsPerYearActualsPerEmployee(carbonEmissionsPerYearPerEmployee.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));

            //Targets

            filteredResTargets = filteredResByOrganisations.filter(item => item.organisationFootprintType === "Target")

            carbonEmissionsPerYear = [];
            carbonEmissionsPerYearPerGBP = [];
            carbonEmissionsPerYearPerEmployee = [];

            for (i = 0; i < aggregateYearsList(selectedYear).length; i++) {
                concatenatedListOfItems = [];

                year = aggregateYearsList(selectedYear)[i].value;

                for (k = 0; k < filteredResTargets.length; k++) {
                    if (
                        filteredResTargets[k].organisationFootprintYear === year
                    ) {
                        concatenatedListOfItems =
                            concatenatedListOfItems.concat(
                                filteredResTargets[k].organisationFootprintItems
                            );
                    }
                }

                emissions = concatenatedListOfItems.filter((itemA) => {
                    return selectedItemSubCategory.find((itemB) => {
                        return itemA.itemSubCategory === itemB.value;
                    })
                })
                    .map((item) => item.carbonEmissions)
                    .reduce((prev, curr) => Number(prev) + Number(curr), 0)/1000;
                if (emissions === 0) {
                    emissions = "N/A"
                }
                carbonEmissionsPerYear.push({
                    year,
                    emissions,
                });

                carbonEmissionsByTurnoverGBP = emissions / (allFootprintData.filter(item => item.organisationFootprintYear === year && item.organisationFootprintType === "Target").filter((itemA) => {
                    return selectedOrganisation.find((itemB) => {
                        return itemA.organisationId === itemB.value;
                    })
                }).map(item => item.organisationTurnoverGBP).reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000)

                carbonEmissionsPerYearPerGBP.push({
                    year,
                    emissions: carbonEmissionsByTurnoverGBP
                });

                carbonEmissionsByEmployee = emissions / (allFootprintData.filter(item => item.organisationFootprintYear === year && item.organisationFootprintType === "Target").filter((itemA) => {
                    return selectedOrganisation.find((itemB) => {
                        return itemA.organisationId === itemB.value;
                    })
                }).map(item => item.organisationNumberOfEmployees).reduce((prev, curr) => Number(prev) + Number(curr), 0))

                carbonEmissionsPerYearPerEmployee.push({
                    year,
                    emissions: carbonEmissionsByEmployee
                });

            }

            setCarbonEmissionsPerYearTargets(carbonEmissionsPerYear.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));
            setCarbonEmissionsPerYearTargetsPerGBPTurnover(carbonEmissionsPerYearPerGBP.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));
            setCarbonEmissionsPerYearTargetsPerEmployee(carbonEmissionsPerYearPerEmployee.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));

            //Projections

            filteredResProjections = filteredResByOrganisations.filter(item => item.organisationFootprintType === "Forecast")

            carbonEmissionsPerYear = [];
            carbonEmissionsPerYearPerGBP = [];
            carbonEmissionsPerYearPerEmployee = [];

            for (i = 0; i < aggregateYearsList(selectedYear).length; i++) {
                concatenatedListOfItems = [];

                year = aggregateYearsList(selectedYear)[i].value;

                for (k = 0; k < filteredResProjections.length; k++) {
                    if (
                        filteredResProjections[k].organisationFootprintYear === year
                    ) {
                        concatenatedListOfItems =
                            concatenatedListOfItems.concat(
                                filteredResProjections[k].organisationFootprintItems
                            );
                    }
                }

                emissions = concatenatedListOfItems.filter((itemA) => {
                    return selectedItemSubCategory.find((itemB) => {
                        return itemA.itemSubCategory === itemB.value;
                    })
                })
                    .map((item) => item.carbonEmissions)
                    .reduce((prev, curr) => Number(prev) + Number(curr), 0)/1000;
                if (emissions === 0) {
                    emissions = "N/A"
                }
                carbonEmissionsPerYear.push({
                    year,
                    emissions,
                });

                carbonEmissionsByTurnoverGBP = emissions / (allFootprintData.filter(item => item.organisationFootprintYear === year && item.organisationFootprintType === "Forecast").filter((itemA) => {
                    return selectedOrganisation.find((itemB) => {
                        return itemA.organisationId === itemB.value;
                    })
                }).map(item => item.organisationTurnoverGBP).reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000)

                carbonEmissionsPerYearPerGBP.push({
                    year,
                    emissions: carbonEmissionsByTurnoverGBP
                });

                carbonEmissionsByEmployee = emissions / (allFootprintData.filter(item => item.organisationFootprintYear === year && item.organisationFootprintType === "Target").filter((itemA) => {
                    return selectedOrganisation.find((itemB) => {
                        return itemA.organisationId === itemB.value;
                    })
                }).map(item => item.organisationNumberOfEmployees).reduce((prev, curr) => Number(prev) + Number(curr), 0))

                carbonEmissionsPerYearPerEmployee.push({
                    year,
                    emissions: carbonEmissionsByEmployee
                });

            }

            setCarbonEmissionsPerYearProjections(carbonEmissionsPerYear.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));
            setCarbonEmissionsPerYearProjectionsPerGBPTurnover(carbonEmissionsPerYearPerGBP.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));
            setCarbonEmissionsPerYearProjectionsPerEmployee(carbonEmissionsPerYearPerEmployee.sort((item1, item2) => item1.year < item2.year ? -1 : item1.year > item2.year ? 1 : 0).map(item => item.emissions));

            //Code Here to get to emissions per scopeCategory per organisation

            const carbonEmissionsPerOrganisationPerSubCategory = [];

            datasetsArray = [];
            datasetsArrayPerEmployee = [];
            datasetsArrayPerGBP = [];
            emissionsForThatSubCategory = [];
            var arrayOfEmissionsByOrganisation = [];
            var arrayOfEmissionsByOrganisationPerEmployee = [];
            var arrayOfEmissionsByOrganisationPerGBP = [];
            var organisationId
            var chartColourIndex = 0

            for (i = 0; i < selectedOrganisation.length; i++) {
                concatenatedListOfItems = [];

                organisationId = selectedOrganisation.sort((item1, item2) => item1.label < item2.label ? -1 : item1.label > item2.label ? 1 : 0)[i].value;

                for (k = 0; k < filteredResByOrganisationsAndFootprintTypeAndYear.length; k++) {
                    if (
                        filteredResByOrganisationsAndFootprintTypeAndYear[k].organisationId === organisationId
                    ) {
                        concatenatedListOfItems =
                            concatenatedListOfItems.concat(
                                filteredResByOrganisationsAndFootprintTypeAndYear[k].organisationFootprintItems
                            );
                    }
                }

                for (j = 0; j < scopeSubCategoryLabels.length; j++) {
                    subCategory = scopeSubCategoryLabels[j];
                    emissions = concatenatedListOfItems
                        .filter((item) => item.itemSubCategory === subCategory)
                        .map((item) => item.carbonEmissions)
                        .reduce((prev, curr) => Number(prev) + Number(curr), 0)/1000;
                    carbonEmissionsPerOrganisationPerSubCategory.push({
                        organisationId,
                        subCategory,
                        emissions,
                    });
                }

            }

            for (i = 0; i < scopeSubCategoryLabels.length; i++) {
                subCategory = scopeSubCategoryLabels[i];
                emissionsForThatSubCategory =
                    carbonEmissionsPerOrganisationPerSubCategory.filter(
                        (item) => item.subCategory === subCategory
                    );

                arrayOfEmissionsByOrganisation = [];
                arrayOfEmissionsByOrganisationPerEmployee = [];
                arrayOfEmissionsByOrganisationPerGBP = [];

                for (j = 0; j < selectedOrganisation.length; j++) {
                    organisationId = selectedOrganisation[j].value;
                    emissions = emissionsForThatSubCategory
                        .filter((item) => item.organisationId === organisationId)
                        .map((item) => item.emissions)
                        .reduce((prev, curr) => Number(prev) + Number(curr), 0);
                    arrayOfEmissionsByOrganisation.push(emissions);
                    arrayOfEmissionsByOrganisationPerEmployee.push(emissions / filteredResByOrganisationsAndFootprintTypeAndYear.filter(item => item.organisationId === organisationId).map(item => item.organisationNumberOfEmployees).reduce((prev, curr) => Number(prev) + Number(curr), 0))
                    arrayOfEmissionsByOrganisationPerGBP.push(emissions / (filteredResByOrganisationsAndFootprintTypeAndYear.filter(item => item.organisationId === organisationId).map(item => item.organisationTurnoverGBP).reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000))

                }

                datasetsArray.push({
                    label: subCategory,
                    data: arrayOfEmissionsByOrganisation,
                    backgroundColor: chartColours[chartColourIndex],
                    borderColor: chartColours[chartColourIndex],
                    hoverBackgroundColor: chartHoverColours[chartColourIndex],
                });

                datasetsArrayPerEmployee.push({
                    label: subCategory,
                    data: arrayOfEmissionsByOrganisationPerEmployee,
                    backgroundColor: chartColours[chartColourIndex],
                    borderColor: chartColours[chartColourIndex],
                    hoverBackgroundColor: chartHoverColours[chartColourIndex],
                });

                datasetsArrayPerGBP.push({
                    label: subCategory,
                    data: arrayOfEmissionsByOrganisationPerGBP,
                    backgroundColor: chartColours[chartColourIndex],
                    borderColor: chartColours[chartColourIndex],
                    hoverBackgroundColor: chartHoverColours[chartColourIndex],
                });

                if (chartColourIndex === chartColours.length - 1) {
                    chartColourIndex = 0
                } else {
                    chartColourIndex++
                }
            }

            setStackedBarChartLabels(selectedOrganisation.sort((item1, item2) => item1.label < item2.label ? -1 : item1.label > item2.label ? 1 : 0).map(item => item.label))
            setStackedBarChartCarbonEmissions(datasetsArray);
            setStackedBarChartCarbonEmissionsPerEmployee(datasetsArrayPerEmployee);
            setStackedBarChartCarbonEmissionsPerGBP(datasetsArrayPerGBP);

            var seperateDataForSingleOrganisationPerEmployee = []
            var seperateDataForSingleOrganisationPerGBP = []

            setSingleHorizontalBarChartLabels(datasetsArray.map(item => item.label))

            for (i = 0; i < datasetsArray.length; i++) {
                seperateDataForSingleOrganisationPerEmployee.push(datasetsArrayPerEmployee[i].data[0])
                seperateDataForSingleOrganisationPerGBP.push(datasetsArrayPerGBP[i].data[0])
            }

            setSingleHorizontalBarChartCarbonEmissionsPerEmployee(seperateDataForSingleOrganisationPerEmployee);
            setSingleHorizontalBarChartCarbonEmissionsPerGBP(seperateDataForSingleOrganisationPerGBP);

            var treeMapArray = []
            var treeMapArrayFiltered = []


            var totalForThisOrganisation = 0

            for (i = 0; i < selectedOrganisation.length; i++) {

                totalForThisOrganisation = 0

                treeMapArray.push({
                    name: selectedOrganisation.sort((item1, item2) => item1.label < item2.label ? -1 : item1.label > item2.label ? 1 : 0).map(item => item.label)[i],
                    data: []
                })

                for (j = 0; j < datasetsArray.length; j++) {

                    if (datasetsArray[j].data[i] > 0.001) {

                        treeMapArray[i].data.push({
                            x: [treeMapArray[i].name,  datasetsArray[j].label],
                            //Can add .toFixed(3) to the below, but numbers lower than 0.0001 will show with large text and 0 value?!?!?
                            y: datasetsArray[j].data[i].toFixed(3)
                        },)

                    totalForThisOrganisation = totalForThisOrganisation + 1

                    } else {

                        treeMapArray[i].data.push({
                            x: [""],
                            y: 0
                        },)
                    }

                }

                //ensure that only organisations with at least some emissions based on the filters applied are included (otherwise treemap labels are not
                //rendered correctly)

                if (totalForThisOrganisation > 0) {

                    treeMapArrayFiltered.push(treeMapArray[i])

                }

            }

            setTreeMapCarbonEmissions(treeMapArrayFiltered)

            //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            //Code Here to get to emissions per subItem per organisation

            var carbonEmissionsPerOrganisationPerSubItem = [];

             datasetsArray = [];
             datasetsArrayPerEmployee = [];
             datasetsArrayPerGBP = [];
             var l = 0
             var description
             var emissionsForThatSubItem = [];
             arrayOfEmissionsByOrganisation = [];
             arrayOfEmissionsByOrganisationPerEmployee = [];
             arrayOfEmissionsByOrganisationPerGBP = [];
             chartColourIndex = 0
             //var listOfSubItems = selectedSubItem
            //  var listOfSubItems = scopeSubItemLabels.slice(0,numberOfRecordsToShow)

            //  for (i = 0; i < selectedOrganisation.length; i++) {
            //     concatenatedListOfSubItems = [];
 
            //      organisationId = selectedOrganisation.sort((item1, item2) => item1.label < item2.label ? -1 : item1.label > item2.label ? 1 : 0)[i].value;
 
            //      for (k = 0; k < filteredResByOrganisationsAndFootprintTypeAndYear.length; k++) {
            //          if (
            //              filteredResByOrganisationsAndFootprintTypeAndYear[k].organisationId === organisationId
            //          ) {
            //             //loop through each item of footprint [k] and concatenate the subItems
            //             for (l = 0; l < filteredResByOrganisationsAndFootprintTypeAndYear[k].organisationFootprintItems.length; l++) {
            //                 concatenatedListOfSubItems =
            //                 concatenatedListOfSubItems.concat(
            //                         filteredResByOrganisationsAndFootprintTypeAndYear[k].organisationFootprintItems[l].footprintSubItems
            //                     )
            //             }
            //          }
            //      }

        
            //     //somehow need to get full list of subCategoryItems!!!!!!
            //      for (j = 0; j < listOfSubItems.length; j++) {
            //         description = listOfSubItems[j];
            //          emissions = concatenatedListOfSubItems
            //              .filter((item) => item.description === description)
            //              .map((item) => item.carbonEmissions)
            //              .reduce((prev, curr) => Number(prev) + Number(curr), 0)/1000;
            //          carbonEmissionsPerOrganisationPerSubItem.push({
            //              organisationId,
            //              description,
            //              emissions,
            //          });
            //      }
 
            //  }
 
            //  for (i = 0; i < listOfSubItems.length; i++) {
            //     description = listOfSubItems[i];
            //      emissionsForThatSubItem =
            //          carbonEmissionsPerOrganisationPerSubItem.filter(
            //              (item) => item.description === description
            //          );
 
            //      arrayOfEmissionsByOrganisation = [];
            //      arrayOfEmissionsByOrganisationPerEmployee = [];
            //      arrayOfEmissionsByOrganisationPerGBP = [];
 
            //      for (j = 0; j < selectedOrganisation.length; j++) {
            //          organisationId = selectedOrganisation[j].value;
            //          emissions = emissionsForThatSubItem
            //              .filter((item) => item.organisationId === organisationId)
            //              .map((item) => item.emissions)
            //              .reduce((prev, curr) => Number(prev) + Number(curr), 0);
            //          arrayOfEmissionsByOrganisation.push(emissions);
            //          arrayOfEmissionsByOrganisationPerGBP.push(emissions / (filteredResByOrganisationsAndFootprintTypeAndYear.filter(item => item.organisationId === organisationId).map(item => item.organisationTurnoverGBP).reduce((prev, curr) => Number(prev) + Number(curr), 0) / 1000))
 
            //      }

            //     if (description !== "") {
            //      datasetsArray.push({
            //          label: description,
            //          data: arrayOfEmissionsByOrganisation,
            //          backgroundColor: chartColours[chartColourIndex],
            //          borderColor: chartColours[chartColourIndex],
            //          hoverBackgroundColor: chartHoverColours[chartColourIndex],
            //      });
 
            //      datasetsArrayPerEmployee.push({
            //          label: description,
            //          data: arrayOfEmissionsByOrganisationPerEmployee,
            //          backgroundColor: chartColours[chartColourIndex],
            //          borderColor: chartColours[chartColourIndex],
            //          hoverBackgroundColor: chartHoverColours[chartColourIndex],
            //      });
 
            //      datasetsArrayPerGBP.push({
            //          label: description,
            //          data: arrayOfEmissionsByOrganisationPerGBP,
            //          backgroundColor: chartColours[chartColourIndex],
            //          borderColor: chartColours[chartColourIndex],
            //          hoverBackgroundColor: chartHoverColours[chartColourIndex],
            //      });
 
            //      if (chartColourIndex === chartColours.length - 1) {
            //          chartColourIndex = 0
            //      } else {
            //          chartColourIndex++
            //      }

            //     }

            //  }
 
            //  setStackedBarChartLabelsForSubItems(selectedOrganisation.sort((item1, item2) => item1.label < item2.label ? -1 : item1.label > item2.label ? 1 : 0).map(item => item.label))
            //  setStackedBarChartCarbonEmissionsForSubItems(datasetsArray);
            //  setStackedBarChartCarbonEmissionsPerEmployeeForSubItems(datasetsArrayPerEmployee);
            //  setStackedBarChartCarbonEmissionsPerGBPForSubItems(datasetsArrayPerGBP);

        }

    }, [selectedOrganisation, selectedYear, selectedFootprintType, selectedItemSubCategory, selectedSubItem, numberOfRecordsToShow, company, role]);

    if (
        organisations.length > 0 &&
        organisationFootprints.length > 0 &&
        !loading
    ) {
        return (
            <div>
                {!sidebarToggle && (
                    <button
                        className="absolute top-2 left-1 text-congress-blue"
                        onClick={() => setSidebarToggle(!sidebarToggle)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M4 6h16M4 12h16M4 18h16"
                            />
                        </svg>
                    </button>
                )}
                {sidebarToggle && (
                    <button
                        className="absolute top-2 left-1 text-white"
                        onClick={() => setSidebarToggle(!sidebarToggle)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                )}

                <div className="flex bg-slate-100 h-screen overflow-hidden">
                    {sidebarToggle && <Sidebar />}
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Organisation" section="Dashboard View" iconFileName="Dashboard-Hover" showUserMenu='true' url={role === 3001 || role === 6001 ? "/viewClient/" + company : role === 6002 ? "/listOrganisations" : "/"}/>

                        <div className="flex items-center">
                            <div className="w-full mx-auto m-5 px-3">
                                <div className="grid grid-cols-2 gap-2 sm:gap-7 sm:grid-cols-4">
                                    <div>
                                        <label>Organisation</label>
                                        <MultiSelect
                                            options={organisations.map((item) => {
                                                return {
                                                    label: item.organisationName,
                                                    value: item.id
                                                }
                                            })}
                                            value={selectedOrganisation}
                                            onChange={setSelectedOrganisation}
                                            labelledBy="Select"
                                        />
                                    </div>
                                    <div>
                                        <label>Year</label>
                                        <MultiSelect
                                            options={selectedYearDefaults}
                                            value={selectedYear}
                                            onChange={setSelectedYear}
                                            labelledBy="Select"
                                        />
                                    </div>
                                    {/* <div>
                                        <label>Footprint Type</label>
                                        <MultiSelect
                                            options={aggregateFootprintTypesListFromFootprintData(organisationFootprints).map((item) => {
                                                return {
                                                    label: item.organisationFootprintType,
                                                    value: item.organisationFootprintType
                                                }
                                            })}
                                            value={selectedFootprintType}
                                            onChange={setSelectedFootprintType}
                                            labelledBy="Select"
                                        />
                                    </div> */}
                                    <div>
                                        <label>Sub Category</label>
                                        <MultiSelect
                                            options={subCategories}
                                            value={selectedItemSubCategory}
                                            onChange={setSelectedItemSubCategory}
                                            labelledBy="Select"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="flex items-center">
                            <div className="w-full mx-auto mb-5 px-3">
                                <div className="grid gap-2 sm:gap-7 sm:grid-cols-3">

                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                                        <div>
                                            <div className="text-sm text-gray-500 ">Total Carbon Emissions (tCO<sub>2</sub>e): </div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-pacific-blue "><CountUp
                                                    start={0}
                                                    end={(
                                                        carbonEmissions.reduce(
                                                            (prev, curr) => Number(prev) + Number(curr),
                                                            0
                                                        )
                                                    ).toFixed(2)}
                                                    separator=","
                                                /></div>
                                            </div>
                                        </div>
                                        <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                                        <div>
                                            <div className="text-sm text-gray-500 ">Organisations Selected:  </div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-pacific-blue "><CountUp
                                                    start={0}
                                                    end={selectedOrganisation.length}
                                                    separator=","
                                                /></div>
                                            </div>
                                        </div>
                                        <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                                            </svg>
                                        </div>
                                    </div>
                                    {/* <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                                        <div>
                                            <div className="text-sm text-gray-500 ">Years Selected:  </div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-pacific-blue "><CountUp
                                                    start={0}
                                                    end={selectedYear.length}
                                                    separator=","
                                                /></div>
                                            </div>
                                        </div>
                                        <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                                            </svg>
                                        </div>
                                    </div> */}

                                    <div className="flex items-center justify-between p-3 md:p-5 bg-white rounded-2xl shadow-md shadow-slate-300">

                                        <div>
                                            <div className="text-sm text-gray-500 ">Sub Categories Selected:  </div>
                                            <div className="flex items-center pt-1">
                                                <div className="text-xl font-medium text-pacific-blue ">{selectedItemSubCategory.map(item => item.value + "; ")}</div>
                                            </div>
                                        </div>
                                        <div className="text-gray-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="basis-full rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                            <ReactApexChart options={treeMapOptions} series={treeMapCarbonEmissions} type="treemap" height={700} />
                        </div>

                        <div className="grid lg:grid-cols-3 grid-cols-1">
                            <div className="col-span-1 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Doughnut data={dataChart6} options={pieOptionsForCategory} />
                            </div>

                            <div className="col-span-2 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Bar data={dataChart6} options={barOptionsHorizontalForCategory} />
                            </div>
                        </div>

                        <div className="grid lg:grid-cols-3 grid-cols-1">
                            <div className="col-span-1 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Doughnut data={dataChart5} options={pieOptions} />
                            </div>

                            <div className="col-span-2 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Bar data={dataChart5} options={barOptionsHorizontal} />
                            </div>
                        </div>
                        {selectedOrganisation.length > 1 &&
                        <div>
                            <div className="basis-full rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Bar data={dataChart7b} options={barOptionsStacked} height={140} />
                            </div>

                            <div className="basis-full rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Bar data={dataChart7bc} options={barOptionsStackedPerGBP} height={140} />
                            </div>

                            <div className="basis-full rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Bar data={dataChart7bb} options={barOptionsStackedPerEmployee} height={140} />
                            </div>
                        </div>
                        }
                        {selectedOrganisation.length <= 1 && 
                        <div>
                            <div className="grid lg:grid-cols-3">
                            <div className="col-span-1 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                
                                    <Doughnut data={dataChart6PerGBP} options={pieOptionsPerGBP}/>

                            </div>
                                <div className="col-span-2 basis-full rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                    <Bar data={dataChart7bcSingleHorizontal} options={barOptionsHorizontalPerGBP} />
                                </div>

                            </div>    
                            <div className="grid lg:grid-cols-3">
                            <div className="col-span-1 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                    <Doughnut data={dataChart6PerEmployee} options={pieOptionsPerEmployee} />
                                </div>
                                <div className="col-span-2 basis-full rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                        <Bar data={dataChart7bbSingleHorizontal} options={barOptionsHorizontalPerEmployee} />
                                </div>
                            </div>

                        </div>
                        } */}

                        {selectedItemSubCategory.filter(item => item.value.slice(0,2) === "3.").length === 0 && 
                        <div className="basis-full rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white relative">
                            <Line data={dataChart7c} options={barAndLineOptions} height={100} />
                            {/* <div className="tooltip" id="tooltip"></div> */}
                        </div>
                        }

                        {selectedItemSubCategory.filter(item => item.value.slice(0,2) === "3.").length > 0 && 
                        <div>
                            <div className="basis-full rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Line data={dataChart7d} options={barAndLineOptionsPerGBP} height={100} />
                            </div>

                            <div className="basis-full rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Line data={dataChart7e} options={barAndLineOptionsPerEmployee} height={100} />
                            </div>
                        </div>
                        }

                        {/* <div className="basis-full rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                            <Line data={dataChart7} options={lineBarOptions} height={100} />
                        </div> */}

                        {/* <div className="flex items-center">
                            <div className="w-full mx-auto m-5 px-3">
                                <div className="grid grid-cols-2 gap-2 sm:gap-7 sm:grid-cols-4">
                                    <div>
                                        <label>Organisation</label>
                                        <MultiSelect
                                            options={organisations.map((item) => {
                                                return {
                                                    label: item.organisationName,
                                                    value: item.id
                                                }
                                            })}
                                            value={selectedOrganisation}
                                            onChange={setSelectedOrganisation}
                                            labelledBy="Select"
                                        />
                                    </div>
                                    <div>
                                        <label>Year</label>
                                        <MultiSelect
                                            options={selectedYearDefaults}
                                            value={selectedYear}
                                            onChange={setSelectedYear}
                                            labelledBy="Select"
                                        />
                                    </div>
                                    <div>
                                        <label>Footprint Type</label>
                                        <MultiSelect
                                            options={aggregateFootprintTypesListFromFootprintData(organisationFootprints).map((item) => {
                                                return {
                                                    label: item.organisationFootprintType,
                                                    value: item.organisationFootprintType
                                                }
                                            })}
                                            value={selectedFootprintType}
                                            onChange={setSelectedFootprintType}
                                            labelledBy="Select"
                                        />
                                    </div>
                                    <div>
                                        <label>Sub Category</label>
                                        <MultiSelect
                                            options={subCategories}
                                            value={selectedItemSubCategory}
                                            onChange={setSelectedItemSubCategory}
                                            labelledBy="Select"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <br></br>

                        <div style={{ borderTop: "0.5px solid #000000", marginLeft: 100, marginRight: 100 }}></div>

                        <div className="flex items-center">
                            <div className="w-full mx-auto m-5 px-3">
                                <div className="grid grid-cols-2 gap-2 sm:gap-7 sm:grid-cols-4">
                                    <div className="text-center">
                                        <br></br>
                                    <Button text="Top 5 Sub Items" onClick={() => setNumberOfRecordsToShow(5)} />
                                    </div>
                                    <div className="text-center">
                                    <br></br>
                                    <Button  text="Top 10 Sub Items" onClick={() => setNumberOfRecordsToShow(10)} />
                                    </div>
                                    <div className="text-center">
                                    <br></br>
                                    <Button text="Top 50 Sub Items" onClick={() => setNumberOfRecordsToShow(50)} />
                                    </div>
                                    <div>
                                        <label>Sub Item</label>
                                        <MultiSelect
                                            options={subItems.map((item) => {
                                                return {
                                                    label: item.description,
                                                    value: item.description
                                                }
                                            })}
                                            value={selectedSubItem}
                                            onChange={setSelectedSubItem}
                                            labelledBy="Select"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {numberOfRecordsToShow===50 &&
                        <div className="grid lg:grid-cols-1 grid-cols-1">
                            <div className="col-span-2 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Bar data={dataChart10} options={barOptionsHorizontalForSubItems} height={240}/>
                            </div>
                        </div>
                        }
                        {numberOfRecordsToShow===10 &&
                        <div className="grid lg:grid-cols-1 grid-cols-1">
                            <div className="col-span-2 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Bar data={dataChart10} options={barOptionsHorizontalForSubItems} height={150}/>
                            </div>
                        </div>
                        }
                        {numberOfRecordsToShow===5 &&
                        <div className="grid lg:grid-cols-1 grid-cols-1">
                            <div className="col-span-2 rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                                <Bar data={dataChart10} options={barOptionsHorizontalForSubItems} height={80}/>
                            </div>
                        </div>
                        }

                        <div className="basis-full rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                            <Bar data={dataChart7bForSubItems} options={barOptionsStackedForSubItems} height={140} />
                        </div>

                        <div className="basis-full rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                            <Bar data={dataChart7bcForSubItems} options={barOptionsStackedPerGBPForSubItems} height={140} />
                        </div>

                        <div className="basis-full rounded-2xl shadow-md shadow-slate-300 m-3 p-5 bg-white">
                            <Bar data={dataChart7bbForSubItems} options={barOptionsStackedPerEmployeeForSubItems} height={140} />
                        </div>*/}

                    </div>
                </div>
            </div>
        );
    } else if (
        organisationFootprints.length === 0 &&
        loading
    ){
        return (
            <div>
                {!sidebarToggle && (
                    <button
                        className="absolute top-2 left-1 text-congress-blue"
                        onClick={() => setSidebarToggle(!sidebarToggle)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M4 6h16M4 12h16M4 18h16"
                            />
                        </svg>
                    </button>
                )}
                {sidebarToggle && (
                    <button
                        className="absolute top-2 left-1 text-white"
                        onClick={() => setSidebarToggle(!sidebarToggle)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                )}

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle && <Sidebar />}
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Organisation" section="Dashboard View" iconFileName="Dashboard-Hover" showUserMenu='true' url={role === 3001 || role === 6001 ? "/viewClient/" + company : role === 6002 ? "/listOrganisations" : "/"}/>
                        <p>Data is loading...</p>
                    </div>

                </div>
            </div>
        );
    } else if (
        organisationFootprints.length === 0 &&
        !loading
    ){
        return (
            <div>
                {!sidebarToggle && (
                    <button
                        className="absolute top-2 left-1 text-congress-blue"
                        onClick={() => setSidebarToggle(!sidebarToggle)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M4 6h16M4 12h16M4 18h16"
                            />
                        </svg>
                    </button>
                )}
                {sidebarToggle && (
                    <button
                        className="absolute top-2 left-1 text-white"
                        onClick={() => setSidebarToggle(!sidebarToggle)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                )}

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle && <Sidebar />}
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Organisation" section="Dashboard View" iconFileName="Dashboard-Hover" showUserMenu='true' url={role === 3001 || role === 6001 ? "/viewClient/" + company : role === 6002 ? "/listOrganisations" : "/"}/>
                        <p>No Footprint Data Available</p>
                    </div>

                </div>
            </div>
        );
    }
};

export default DashboardOrganisations;
