import { useState, useEffect } from 'react'
import axios from 'axios'
import Header from '../UI/Header'
import ProgressBar from '../UI/ProgressBar'
import { useNavigate, useMatch } from 'react-router-dom'

const AddOrUpdateStore = () => {

    const match = useMatch('/addOrUpdateStore/:storeId')

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)

    const [projectName, setProjectName] = useState('')
    const [brand, setBrand] = useState('')
    const [mainConcept, setMainConcept] = useState('')
    const [mainConceptMsq, setMainConceptMsq] = useState('')
    const [secondConcept, setSecondConcept] = useState('')
    const [secondConceptMsq, setSecondConceptMsq] = useState('')
    const [thirdConcept, setThirdConcept] = useState('')
    const [thirdConceptMsq, setThirdConceptMsq] = useState('')
    const [tier, setTier] = useState('')
    const [ownership, setOwnership] = useState('')
    const [weatherStation, setWeatherStation] = useState('')
    const [locationType, setLocationType] = useState('')
    const [ageOfBuildingFabric, setAgeOfBuildingFabric] = useState('')
    const [shopType, setShopType] = useState('')
    const [openingDate, setOpeningDate] = useState('')
    const [storeName, setStoreName] = useState('')
    const [storeAddress, setStoreAddress] = useState('')
    const [storeContact, setStoreContact] = useState('')
    const [netArea, setNetArea] = useState('')
    const [officeFloorArea, setOfficeFloorArea] = useState('')
    const [toiletFloorArea, setToiletFloorArea] = useState('')
    const [stockRoomFloorArea, setStockRoomFloorArea] = useState('')
    const [otherFloorArea, setOtherFloorArea] = useState('')
    const [grossFloorArea, setGrossFloorArea] = useState('')
    const [floorToCeilingHeightSalesArea, setFloorToCeilingHeightSalesArea] = useState('')
    const [floorToCeilingHeightBackOfHouseArea, setFloorToCeilingHeightBackOfHouseArea] = useState('')
    const [wellbeingNaturalLight, setWellbeingNaturalLight] = useState('')
    const [wellbeingOpenableWindows, setWellbeingOpenableWindows] = useState('')
    const [wellbeingBlindsOnWindows, setWellbeingBlindsOnWindows] = useState('')
    const [wellbeingPlantsInOffices, setWellbeingPlantsInOffices] = useState('')
    const [wellbeingWaterPoint, setWellbeingWaterPoint] = useState('')
    const [wellbeingDedicatedSeatingAndKitchenForStaff, setWellbeingDedicatedSeatingAndKitchenForStaff] = useState('')
    const [wasteDedicatedRecyclingSpace, setWasteDedicatedRecyclingSpace] = useState('')
    const [wasteSeperated, setWasteSeperated] = useState('')
    const [wellbeingScore, setWellbeingScore] = useState(0)
    const [wasteScore, setWasteScore] = useState(0)

    useEffect(() => {
        if (match.params.storeId !== "_add") {

            setLoading(true)

            axios.get(process.env.REACT_APP_API_URL + '/stores/' + match.params.storeId)
                .then((res) => {
                    setProjectName(res.data.projectName)
                    setBrand(res.data.brand)
                    setMainConcept(res.data.mainConcept)
                    setMainConceptMsq(res.data.mainConceptMsq)
                    setSecondConcept(res.data.secondConcept)
                    setSecondConceptMsq(res.data.secondConceptMsq)
                    setThirdConcept(res.data.thirdConcept)
                    setThirdConceptMsq(res.data.thirdConceptMsq)
                    setTier(res.data.tier)
                    setOwnership(res.data.ownership)
                    setWeatherStation(res.data.weatherStation)
                    setLocationType(res.data.locationType)
                    setAgeOfBuildingFabric(res.data.ageOfBuildingFabric)
                    setShopType(res.data.shopType)
                    setOpeningDate(res.data.openingDate)
                    setStoreName(res.data.storeName)
                    setStoreAddress(res.data.storeAddress)
                    setStoreContact(res.data.storeContact)
                    setNetArea(res.data.netArea)
                    setOfficeFloorArea(res.data.officeFloorArea)
                    setToiletFloorArea(res.data.toiletFloorArea)
                    setStockRoomFloorArea(res.data.stockRoomFloorArea)
                    setOtherFloorArea(res.data.otherFloorArea)
                    setGrossFloorArea(res.data.grossFloorArea)
                    setFloorToCeilingHeightSalesArea(res.data.floorToCeilingHeightSalesArea)
                    setFloorToCeilingHeightBackOfHouseArea(res.data.floorToCeilingHeightBackOfHouseArea)
                    setWellbeingNaturalLight(res.data.wellbeingNaturalLight)
                    setWellbeingOpenableWindows(res.data.wellbeingOpenableWindows)
                    setWellbeingBlindsOnWindows(res.data.wellbeingBlindsOnWindows)
                    setWellbeingPlantsInOffices(res.data.wellbeingPlantsInOffices)
                    setWellbeingWaterPoint(res.data.wellbeingWaterPoint)
                    setWellbeingDedicatedSeatingAndKitchenForStaff(res.data.wellbeingDedicatedSeatingAndKitchenForStaff)
                    setWasteDedicatedRecyclingSpace(res.data.wasteDedicatedRecyclingSpace)
                    setWasteSeperated(res.data.wasteSeperated)
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });
        }
    }, [match.params.storeId])

    useEffect(() => {

        const calculateWellbeingScore = () => {

            var score = 0
            if (wellbeingNaturalLight === "Yes") {
                score++
            }
            if (wellbeingOpenableWindows === "Yes") {
                score++
            }
            if (wellbeingBlindsOnWindows === "Yes") {
                score++
            }
            if (wellbeingPlantsInOffices === "Yes") {
                score++
            }
            if (wellbeingWaterPoint === "Yes") {
                score++
            }
            if (wellbeingDedicatedSeatingAndKitchenForStaff === "Yes") {
                score++
            }

            setWellbeingScore(score)
        }

        calculateWellbeingScore()
    }
        , [wellbeingNaturalLight, wellbeingOpenableWindows, wellbeingBlindsOnWindows, wellbeingPlantsInOffices, wellbeingWaterPoint, wellbeingDedicatedSeatingAndKitchenForStaff])

    useEffect(() => {

        const calculateWasteScore = () => {

            var score = 0
            if (wasteDedicatedRecyclingSpace === "Yes") {
                score++
            }
            if (wasteSeperated === "Yes") {
                score++
            }

            setWasteScore(score)
        }

        calculateWasteScore()
    }
        , [wasteDedicatedRecyclingSpace, wasteSeperated])

    const addForm = async (form) => {
        await axios.post(process.env.REACT_APP_API_URL + '/stores', form)
    }

    const updateForm = async (form) => {
        await axios.put(process.env.REACT_APP_API_URL + '/stores/' + match.params.storeId, form)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (match.params.storeId !== "_add") {
            updateForm({ projectName, brand, mainConcept, mainConceptMsq, secondConcept, secondConceptMsq, thirdConcept, thirdConceptMsq, tier, ownership, weatherStation, locationType, ageOfBuildingFabric, shopType, openingDate, storeName, storeAddress, storeContact, netArea, officeFloorArea, toiletFloorArea, stockRoomFloorArea, otherFloorArea, grossFloorArea, floorToCeilingHeightSalesArea, floorToCeilingHeightBackOfHouseArea, wellbeingNaturalLight, wellbeingOpenableWindows, wellbeingBlindsOnWindows, wellbeingPlantsInOffices, wellbeingWaterPoint, wellbeingDedicatedSeatingAndKitchenForStaff, wasteDedicatedRecyclingSpace, wasteSeperated, wellbeingScore, wasteScore }).then(res => { navigate(-1) })
        } else {
            addForm({ projectName, brand, mainConcept, mainConceptMsq, secondConcept, secondConceptMsq, thirdConcept, thirdConceptMsq, tier, ownership, weatherStation, locationType, ageOfBuildingFabric, shopType, openingDate, storeName, storeAddress, storeContact, netArea, officeFloorArea, toiletFloorArea, stockRoomFloorArea, otherFloorArea, grossFloorArea, floorToCeilingHeightSalesArea, floorToCeilingHeightBackOfHouseArea, wellbeingNaturalLight, wellbeingOpenableWindows, wellbeingBlindsOnWindows, wellbeingPlantsInOffices, wellbeingWaterPoint, wellbeingDedicatedSeatingAndKitchenForStaff, wasteDedicatedRecyclingSpace, wasteSeperated, wellbeingScore, wasteScore }).then(res => { navigate(-1) })
        }
    }

    if (!loading) {

        return (

            <div className="container-form-8A">

                <Header title="Green-Up Tool - Add / Edit Store" section="Project Manager Section" iconFileName="BE-Hover" />

                <p>Wellbeing Performance</p><ProgressBar bgcolor="#0091ca" completed={Math.round((wellbeingScore / 6) * 100)} completedLabel={Math.round((wellbeingScore / 6) * 100)} />
                <p>Waste Performance</p><ProgressBar bgcolor="#0091ca" completed={Math.round((wasteScore / 2) * 100)} completedLabel={Math.round((wasteScore / 2) * 100)} />

                <br></br>

                <form className="add-form-8A" onSubmit={onSubmit}>
                    <h2 className="text-2xl font-bold">General Store Information</h2>
                    <div className="form-control-8A">
                        <label>Project Name</label>
                        <input type="text" placeholder="Enter Project Name Here" value={projectName} onChange={(e) => setProjectName(e.target.value)} />
                    </div>
                    <div className="form-control-8A">
                        <label>Brand</label>
                        <input type="text" placeholder="Enter Brand Here" value={brand} onChange={(e) => setBrand(e.target.value)} />
                    </div>
                    <div className="form-control-8A">
                        <label>Main Concept</label>
                        <input type="text" placeholder="Enter Main Concept Here" value={mainConcept} onChange={(e) => setMainConcept(e.target.value)} />
                    </div>
                    <div className="form-control-8A">
                        <label>Main Concept (m<sup>2</sup>)</label>
                        <input type="text" placeholder="Enter Main Concept (m2) Here" value={mainConceptMsq} onChange={(e) => setMainConceptMsq(e.target.value)} />
                    </div>
                    <div className="form-control-8A">
                        <label>Second Concept</label>
                        <input type="text" placeholder="Enter Second Concept Here" value={secondConcept} onChange={(e) => setSecondConcept(e.target.value)} />
                    </div>
                    <div className="form-control-8A">
                        <label>Second Concept (m<sup>2</sup>)</label>
                        <input type="text" placeholder="Enter Second Concept (m2) Here" value={secondConceptMsq} onChange={(e) => setSecondConceptMsq(e.target.value)} />
                    </div>
                    <div className="form-control-8A">
                        <label>Third Concept</label>
                        <input type="text" placeholder="Enter Third Concept Here" value={thirdConcept} onChange={(e) => setThirdConcept(e.target.value)} />
                    </div>
                    <div className="form-control-8A">
                        <label>Third Concept (m<sup>2</sup>)</label>
                        <input type="text" placeholder="Enter Third Concept (m2) Here" value={thirdConceptMsq} onChange={(e) => setThirdConceptMsq(e.target.value)} />
                    </div>
                    <div className="form-control-8A">
                        <label>Tier</label>
                        <input type="text" placeholder="Enter Tier Here" value={tier} onChange={(e) => setTier(e.target.value)} />
                    </div>
                    <div className="form-control-8A">
                        <label>Ownership</label>
                        <input type="text" placeholder="Enter Ownership Details Here" value={ownership} onChange={(e) => setOwnership(e.target.value)} />
                    </div>
                    <div className="form-control-8A">
                        <label>Nearest weather station</label>
                        <input type="text" placeholder="Enter Nearest Weather Station Here" value={weatherStation} onChange={(e) => setWeatherStation(e.target.value)} />
                    </div>
                    <div className="form-control-8A">
                        <label>Location Type</label>
                        <input type="text" placeholder="Enter Location Type Here" value={locationType} onChange={(e) => setLocationType(e.target.value)} />
                    </div>
                    <div className="form-control-8A">
                        <label>Age of building fabric</label>
                        <input type="text" placeholder="Enter Age of Building Fabric Here" value={ageOfBuildingFabric} onChange={(e) => setAgeOfBuildingFabric(e.target.value)} />
                    </div>
                    <div className="form-control-8A">
                        <label>Shop Type</label>
                        <input type="text" placeholder="Enter Store Name Here" value={shopType} onChange={(e) => setShopType(e.target.value)} />
                    </div>
                    <div className="form-control-8A">
                        <label>Opening Date</label>
                        <input type="text" placeholder="Enter Opening Date Here" value={openingDate} onChange={(e) => setOpeningDate(e.target.value)} />
                    </div>


                    <h2 className="text-2xl font-bold">Store Areas</h2>
                    <div className="form-control-8A">
                        <label>Store Name</label>
                        <input type="text" placeholder="Enter Store Name Here" value={storeName} onChange={(e) => setStoreName(e.target.value)} />
                    </div>
                    <div className="form-control-8A">
                        <label>Store Address</label>
                        <input type="text" placeholder="Enter Store Address Here" value={storeAddress} onChange={(e) => setStoreAddress(e.target.value)} />
                    </div>
                    <div className="form-control-8A">
                        <label>Store Contact</label>
                        <input type="text" placeholder="Enter Store Contact Name Here" value={storeContact} onChange={(e) => setStoreContact(e.target.value)} />
                    </div>
                    <div className="form-control-8A">
                        <label>Net Area (m2)</label>
                        <input type="number" placeholder="0" value={netArea} onChange={(e) => { setNetArea(e.target.value); setGrossFloorArea(Number(e.target.value) + Number(officeFloorArea) + Number(toiletFloorArea) + Number(stockRoomFloorArea) + Number(otherFloorArea)) }} />
                    </div>
                    <div className="form-control-8A">
                        <label>Office Floor Area (m2)</label>
                        <input type="number" placeholder="0" value={officeFloorArea} onChange={(e) => { setOfficeFloorArea(e.target.value); setGrossFloorArea(Number(e.target.value) + Number(netArea) + Number(toiletFloorArea) + Number(stockRoomFloorArea) + Number(otherFloorArea)) }} />
                    </div>
                    <div className="form-control-8A">
                        <label>Toilet Floor Area (m2)</label>
                        <input type="number" placeholder="0" value={toiletFloorArea} onChange={(e) => { setToiletFloorArea(e.target.value); setGrossFloorArea(Number(e.target.value) + Number(officeFloorArea) + Number(netArea) + Number(stockRoomFloorArea) + Number(otherFloorArea)) }} />
                    </div>
                    <div className="form-control-8A">
                        <label>Stock Room Floor Area (m2)</label>
                        <input type="number" placeholder="0" value={stockRoomFloorArea} onChange={(e) => { setStockRoomFloorArea(e.target.value); setGrossFloorArea(Number(e.target.value) + Number(officeFloorArea) + Number(toiletFloorArea) + Number(netArea) + Number(otherFloorArea)) }} />
                    </div>
                    <div className="form-control-8A">
                        <label>Other Floor Area (m2)</label>
                        <input type="number" placeholder="0" value={otherFloorArea} onChange={(e) => { setOtherFloorArea(e.target.value); setGrossFloorArea(Number(e.target.value) + Number(officeFloorArea) + Number(toiletFloorArea) + Number(stockRoomFloorArea) + Number(netArea)) }} />
                    </div>
                    <div className="form-control-8A">
                        <label>Gross Floor Area (m2)</label>
                        <input type="number" placeholder="0" value={grossFloorArea} onChange={(e) => setGrossFloorArea(e.target.value)} />
                    </div>
                    <div className="form-control-8A">
                        <label>Average Floor to Ceiling Height for the Sales Area (m)</label>
                        <input type="number" placeholder="0" value={floorToCeilingHeightSalesArea} onChange={(e) => setFloorToCeilingHeightSalesArea(e.target.value)} />
                    </div>
                    <div className="form-control-8A">
                        <label>Average Floor to Ceiling Height in the Back of House (m)</label>
                        <input type="number" placeholder="0" value={floorToCeilingHeightBackOfHouseArea} onChange={(e) => setFloorToCeilingHeightBackOfHouseArea(e.target.value)} />
                    </div>
                    <h2 className="text-2xl font-bold">Operation - Wellbeing</h2>
                    <div className="form-control-8A">
                        <label>Do the office/staff areas have natural daylight or views out of windows?</label>
                        <select value={wellbeingNaturalLight} onChange={(e) => setWellbeingNaturalLight(e.target.value)}>
                            <option value="">Please Select...</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>

                    <div className="form-control-8A">
                        <label>Are any windows in the offices/staff areas openable?</label>
                        <select value={wellbeingOpenableWindows} onChange={(e) => setWellbeingOpenableWindows(e.target.value)}>
                            <option value="">Please Select...</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>

                    <div className="form-control-8A">
                        <label>Have blinds been installed on windows in offices/staff areas to reduce glare?</label>
                        <select value={wellbeingBlindsOnWindows} onChange={(e) => setWellbeingBlindsOnWindows(e.target.value)}>
                            <option value="">Please Select...</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>

                    <div className="form-control-8A">
                        <label>Are there any plants in the staff room or office?</label>
                        <select value={wellbeingPlantsInOffices} onChange={(e) => setWellbeingPlantsInOffices(e.target.value)}>
                            <option value="">Please Select...</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>

                    <div className="form-control-8A">
                        <label>Is there a water point for potable water?</label>
                        <select value={wellbeingWaterPoint} onChange={(e) => setWellbeingWaterPoint(e.target.value)}>
                            <option value="">Please Select...</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>

                    <div className="form-control-8A">
                        <label>Has a dedicated seating area with basic kitchen equipment been provided for the staff?</label>
                        <select value={wellbeingDedicatedSeatingAndKitchenForStaff} onChange={(e) => setWellbeingDedicatedSeatingAndKitchenForStaff(e.target.value)}>
                            <option value="">Please Select...</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>

                    <h2 className="text-2xl font-bold">Operation - Waste</h2>

                    <div className="form-control-8A">
                        <label>Is there a dedicated recycling space?</label>
                        <select value={wasteDedicatedRecyclingSpace} onChange={(e) => setWasteDedicatedRecyclingSpace(e.target.value)}>
                            <option value="">Please Select...</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>

                    <div className="form-control-8A">
                        <label>Does waste get separated before collection?</label>
                        <select value={wasteSeperated} onChange={(e) => setWasteSeperated(e.target.value)}>
                            <option value="">Please Select...</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>

                    <br></br>

                    <p>Wellbeing Performance</p><ProgressBar completedLabel = {Math.round((wellbeingScore / 6) * 100)} bgcolor="#0091ca" completed={Math.round((wellbeingScore / 6) * 100)} />
                    <p>Waste Performance</p><ProgressBar completedLabel = {Math.round((wasteScore / 2) * 100)} bgcolor="#0091ca" completed={Math.round((wasteScore / 2) * 100)} />

                    <br></br>
                    <br></br>

                    <input type="submit" value="Save" className="btn-8A btn-block-8A" />

                </form>

            </div>
        )

    } else {

        return (
            <div>
                <Header title="Green-Up Tool - Add / Edit Store" section="Project Manager Section" iconFileName="BE-Hover" />
                <p>Data is loading...</p>
            </div>)

    }

}

export default AddOrUpdateStore
