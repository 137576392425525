import { useState, useEffect } from 'react'
import axios from 'axios'
import Header from '../UI/Header'
import { useNavigate, useMatch } from 'react-router-dom'
import Options from '../UI/Options'
import Button from '../UI/Button'
import { v4 as uuidv4 } from 'uuid'
import ModalUpdateValue from '../UI/ModalUpdateValue'

import {allBREEAMCredits} from './Data_BREEAMTemplateDataAndRules.js'
import {requiredDocumentaryEvidenceDesignStage} from './Data_BREEAMTemplateDataAndRules.js'
import {requiredDocumentaryEvidencePCRStage} from './Data_BREEAMTemplateDataAndRules.js'
import {creditExceptionsForSpecificBuildingTypes} from './Data_BREEAMTemplateDataAndRules.js'
import {creditExceptionsForSpecificAssessmentTypes} from './Data_BREEAMTemplateDataAndRules.js'
import {minimumStandards} from './Data_BREEAMTemplateDataAndRules.js'

const AddOrUpdateBuildingBREEAMAssessment = () => {

    const match = useMatch('/AddOrUpdateBuildingBREEAMAssessment/:buildingId/:buildingBREEAMAssessmentId')

    const navigate = useNavigate()

    const [showModal, setShowModal] = useState(false)

    const [valueToSendToModal, setValueToSendToModal] = useState('')
    const [objectIdToSendToModal, setObjectIdToSendToModal] = useState('')
    const [fieldToSendToModal, setFieldToSendToModal] = useState('')

    const [loading, setLoading] = useState(false)
    const [buildingId, setBuildingId] = useState('')
    const [projectName, setProjectName] = useState('')
    const [stage, setStage] = useState('')
    const [projectNumber, setProjectNumber] = useState('')
    const [targetRating, setTargetRating] = useState('')
    const [BREEAMRegistrationNumber, setBREEAMRegistrationNumber] = useState('')
    const [certificationLevelTargeted, setcertificationLevelTargeted] = useState('')
    const [netFloorArea, setNetFloorArea] = useState('')
    const [coPilot, setCoPilot] = useState('')
    const [projectManager, setProjectManager] = useState('')
    const [assessmentDate, setAssessmentDate] = useState('')
    const [buildingType, setBuildingType] = useState('')
    const [assessmentType, setAssessmentType] = useState('')
    const [officeOrOtherOccupiedAreas, setOfficeOrOtherOccupiedAreas] = useState('')
    const [unregulatedWaterUsesPresent, setUnregulatedWaterUsesPresent] = useState('')
    const [laboratory, setLaboratory] = useState('')
    const [lift, setLift] = useState('')
    const [escalators, setEscalators] = useState('')
    const [refrigerant, setRefrigerant] = useState('')
    const [coldStorage, setColdStorage] = useState('')
    const [unregulatedEnergyLoad, setUnregulatedEnergyLoad] = useState('')
    const [LERoute, setLERoute] = useState('')
    const [swimmingPoolsOrLargeWaterTanksAndAquariums, setSwimmingPoolsOrLargeWaterTanksAndAquariums] = useState('')
    const [pursuingAPostOccupanStageCertification, setPursuingAPostOccupanStageCertification] = useState('')
    const [consistentAndLargeAmountsOfOperationalWasteGenerated, setConsistentAndLargeAmountsOfOperationalWasteGenerated] = useState('')
    const [ventilation, setVentilation] = useState('')
    const [projectTeam, setProjectTeam] = useState([])
    const [credits, setCredits] = useState([])
    const [schedule, setSchedule] = useState([])
    const [comments, setComments] = useState('')

    const [roleToAdd, setRoleToAdd] = useState('')
    const [nameToAdd, setNameToAdd] = useState('')
    const [emailToAdd, setEmailToAdd] = useState('')
    const [companyToAdd, setCompanyToAdd] = useState('')
    const [telToAdd, setTelToAdd] = useState('')

    const [stageToAdd, setStageToAdd] = useState('')
    const [eventToAdd, setEventToAdd] = useState('')
    const [eventDateToAdd, setEventDateToAdd] = useState('')
    const [eventReminderDateToAdd, setEventReminderToAdd] = useState('')
    const [eventOwnerToAdd, setEventOwnerToAdd] = useState('')

    const [showAddTeamMemberFieldsToggle, setShowAddTeamMemberFieldsToggle] = useState(false)
    const [showAddEventFieldsToggle, setShowAddEventFieldsToggle] = useState(false)

    useEffect(() => {

        setBuildingId(match.params.buildingId)

        if (match.params.buildingBREEAMAssessmentId !== "_add") {

            setLoading(true)

            axios.get(process.env.REACT_APP_API_URL + '/buildings_BREEAM_assessments/' + match.params.buildingBREEAMAssessmentId)
                .then((res) => res.data)
                .then((res) => {
                    setBuildingId(res.buildingId)
                    setProjectName(res.projectName)
                    setProjectNumber(res.projectNumber)
                    setTargetRating(res.targetRating)
                    setBREEAMRegistrationNumber(res.BREEAMRegistrationNumber)
                    setcertificationLevelTargeted(res.certificationLevelTargeted)
                    setNetFloorArea(res.netFloorArea)
                    setProjectManager(res.projectManager)
                    setCoPilot(res.coPilot)
                    setAssessmentDate(res.assessmentDate)
                    setBuildingType(res.buildingType)
                    setAssessmentType(res.assessmentType)
                    setBuildingType(res.buildingType)
                    setOfficeOrOtherOccupiedAreas(res.officeOrOtherOccupiedAreas)
                    setUnregulatedWaterUsesPresent(res.unregulatedWaterUsesPresent)
                    setLaboratory(res.laboratory)
                    setLift(res.lift)
                    setEscalators(res.escalators)
                    setRefrigerant(res.refrigerant)
                    setColdStorage(res.coldStorage)
                    setUnregulatedEnergyLoad(res.unregulatedEnergyLoad)
                    setLERoute(res.LERoute)
                    setSwimmingPoolsOrLargeWaterTanksAndAquariums(res.swimmingPoolsOrLargeWaterTanksAndAquariums)
                    setPursuingAPostOccupanStageCertification(res.pursuingAPostOccupanStageCertification)
                    setConsistentAndLargeAmountsOfOperationalWasteGenerated(res.consistentAndLargeAmountsOfOperationalWasteGenerated)
                    setVentilation(res.ventilation)
                    setProjectTeam(res.projectTeam)
                    setCredits(res.credits)
                    setSchedule(res.schedule)
                    setStage(res.stage)
                    setComments(res.comments)

                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });

        } else {
            setSchedule([ ...schedule, {id: uuidv4(), stage: "Stage 1", event: "Stage 1 Reminder", eventDate: "", eventReminderDate: "", eventOwner: "Eight Versa"},
            {id: uuidv4(), stage: "Stage 2", event: "Stage 2 Reminder", eventDate: "", eventReminderDate: "", eventOwner: "Eight Versa"},
            {id: uuidv4(), stage: "Stage 3", event: "Stage 3 Reminder", eventDate: "", eventReminderDate: "", eventOwner: "Eight Versa"},
            {id: uuidv4(), stage: "Stage 4", event: "Stage 4 Reminder", eventDate: "", eventReminderDate: "", eventOwner: "Eight Versa"},
            {id: uuidv4(), stage: "Stage 5", event: "Stage 5 Reminder", eventDate: "", eventReminderDate: "", eventOwner: "Eight Versa"},
            {id: uuidv4(), stage: "Stage 6", event: "Stage 6 Reminder", eventDate: "", eventReminderDate: "", eventOwner: "Eight Versa"} ] )
            setProjectTeam([ ...projectTeam, {id: uuidv4(), role: "Project Manager", company: "", name: "", email: "", tel: ""},
            {id: uuidv4(), role: "Client", company: "", name: "", email: "", tel: ""},
            {id: uuidv4(), role: "Architect", company: "", name: "", email: "", tel: ""},
            {id: uuidv4(), role: "M&E", company: "", name: "", email: "", tel: ""},
            {id: uuidv4(), role: "Contractor", company: "", name: "", email: "", tel: ""},
            {id: uuidv4(), role: "Civil Engineer", company: "", name: "", email: "", tel: ""},
            {id: uuidv4(), role: "Ecologist", company: "", name: "", email: "", tel: ""},
            {id: uuidv4(), role: "Specialist Consultant", company: "", name: "", email: "", tel: ""},
            {id: uuidv4(), role: "Eight Versa", company: "", name: "", email: "", tel: ""},
            {id: uuidv4(), role: "BREEAM Accredited Professional", company: "", name: "", email: "", tel: ""} ] )
        }
    }, [match.params.buildingBREEAMAssessmentId, match.params.buildingId])

    const performExceptionChecks = (credit) => {

            var documentaryEvidenceItems = []
            var evidenceDocument
            var evidenceDocumentRequired = "YES"

            if (allBREEAMCredits.find(item => item.creditCategoryCode === credit.creditCategoryCode && item.creditDescription === credit.creditDescription)) {
                credit.credits = allBREEAMCredits.find(item => item.creditCategoryCode === credit.creditCategoryCode && item.creditDescription === credit.creditDescription).credits
            }

            //1. Exception Rules for specific buildingTypes
            if (creditExceptionsForSpecificBuildingTypes.find(item => item.creditCategoryCode === credit.creditCategoryCode && item.creditDescription === credit.creditDescription && item.buildingType === buildingType)) {   

                credit.credits = Number(creditExceptionsForSpecificBuildingTypes.find(item => item.creditCategoryCode === credit.creditCategoryCode && item.creditDescription === credit.creditDescription && item.buildingType === buildingType).value).toFixed(0)
                if (credit.credits < credit.creditsTargeted || credit.credits < credit.creditsPotential) {
                    credit.creditsTargeted = credit.credits
                    credit.creditsPotential = 0
                }

            }

            //2. Exception Rules for specific assessmentTypes
            if (creditExceptionsForSpecificAssessmentTypes.find(item => item.creditCategoryCode === credit.creditCategoryCode && item.creditDescription === credit.creditDescription && item.assessmentType === assessmentType)) {
                
                credit.credits = (Number(credit.credits) * Number(creditExceptionsForSpecificAssessmentTypes.find(item => item.creditCategoryCode === credit.creditCategoryCode && item.creditDescription === credit.creditDescription && item.assessmentType === assessmentType).value)).toFixed(0)
                if (credit.credits < credit.creditsTargeted || credit.credits < credit.creditsPotential) {
                    credit.creditsTargeted = credit.credits
                    credit.creditsPotential = 0
                }

            }


            //3. Minumum Standards
            if (minimumStandards.find(item => item.creditCategoryCode === credit.creditCategoryCode && item.creditDescription === credit.creditDescription && item.targetRating === targetRating)) {
                credit.creditsMinimumStandards = minimumStandards.find(item => item.creditCategoryCode === credit.creditCategoryCode && item.creditDescription === credit.creditDescription && item.targetRating === targetRating).creditsMinimumStandards
            }

            //4. Hardcoded Exception Rules
            // THE BELOW SECTION REPRESENTS HARD CODED RULES - THESE WILL NEED TO BE UPDATED IF THE BREEAM REQUIREMENTS CHANGE
            if (credit.creditCategoryCode === "Hea 02" || credit.creditCategoryCode === "Hea 04") {
                if (buildingType === "Industrial" && officeOrOtherOccupiedAreas === "NO") {
                    credit.credits = 0
                    credit.creditsTargeted = 0
                }
            }
            
            if (credit.creditCategoryCode === "Ene 05") {
                if (coldStorage === "NO") {
                    credit.credits = 0
                    credit.creditsTargeted = 0
                }
            }

            if (credit.creditCategoryCode === "Ene 06" && credit.creditDescription === "Energy consumption") {
                if (lift === "NO" && escalators === "NO") {
                    credit.credits = 0
                    credit.creditsTargeted = 0
                }
            }

            if (credit.creditCategoryCode === "Ene 06" && credit.creditDescription === "Energy efficient features - Lifts") {
                if (lift === "NO") {
                    credit.credits = 0
                    credit.creditsTargeted = 0
                }
            }

            if (credit.creditCategoryCode === "Ene 06" && credit.creditDescription === "Energy efficient features - Escalators or moving walks") {
                if (escalators === "NO") {
                    credit.credits = 0
                    credit.creditsTargeted = 0
                }
            }

            if (credit.creditCategoryCode === "Ene 07" && credit.creditDescription === "Design specification") {
                if (laboratory === "NO") {
                    credit.credits = 0
                    credit.creditsTargeted = 0
                }
            }

            if (credit.creditCategoryCode === "Ene 07" && credit.creditDescription === "Best practice energy efficient measures") {
                if (laboratory === "Yes-more than 25% of the total building floor areas") {
                    credit.credits = 1
                    credit.creditsTargeted = 1
                }
                if (laboratory === "Yes-more than 10% of the total building floor areas") {
                    credit.credits = 0.5
                    credit.creditsTargeted = 0.5
                }  
                if (laboratory === "Yes-less than 10% of the total building floor areas") {
                    credit.credits = 0
                    credit.creditsTargeted = 0
                }
                if (laboratory === "NO") {
                    credit.credits = 0
                    credit.creditsTargeted = 0
                }
            }

            if (credit.creditCategoryCode === "Ene 08") {
                if (unregulatedEnergyLoad === "NO") {
                    credit.credits = 0
                    credit.creditsTargeted = 0
                }
            }

            if (credit.creditCategoryCode === "LE 02" && credit.creditDescription === "Survey and evaluation and Determining the ecological outcomes for the site (route 1)") {
                if (LERoute === "Route 1") {
                    credit.credits = 1
                    credit.creditsTargeted = 1
                } else {
                    credit.credits = 0
                    credit.creditsTargeted = 0
                }
            }

            if (credit.creditCategoryCode === "LE 02" && credit.creditDescription === "Survey and evaluation and Determining the ecological outcomes for the site (route 2)") {
                if (LERoute === "Route 2") {
                    credit.credits = credit.credits * 1
                    credit.creditsTargeted = credit.creditsTargeted * 1
                } else {
                    credit.credits = 0
                    credit.creditsTargeted = 0
                }
            }

            if (credit.creditCategoryCode === "LE 03" && (credit.creditDescription === "Planning, liaison, implementation and data (route 1)" || credit.creditDescription === "Managing negative impacts of the project (route 1)")) {
                if (LERoute === "Route 2") {
                    credit.credits = 0
                    credit.creditsTargeted = 0
                } else {
                    credit.credits = credit.credits * 1
                    credit.creditsTargeted = credit.creditsTargeted * 1
                }
            }

            if (credit.creditCategoryCode === "LE 03" && (credit.creditDescription === "Planning, liaison, implementation and data (route 2)" || credit.creditDescription === "Managing negative impacts of the project (route 2)")) {
                if (LERoute === "Route 1") {
                    credit.credits = 0
                    credit.creditsTargeted = 0
                } else {
                    credit.credits = credit.credits * 1
                    credit.creditsTargeted = credit.creditsTargeted * 1
                }
            }


            if (credit.creditCategoryCode === "LE 04" && (credit.creditDescription === "Ecological enhancement (route 2 only)" || credit.creditDescription === "Change and enhancement of ecology (route 2)")) {
                if (LERoute === "Route 1") {
                    credit.credits = 0
                    credit.creditsTargeted = 0
                } else {
                    credit.credits = credit.credits * 1
                    credit.creditsTargeted = credit.creditsTargeted * 1
                }
            }

            if (credit.creditCategoryCode === "LE 04" && (credit.creditDescription === "Change and enhancement of ecology (route 1)")) {
                if (LERoute === "Route 2") {
                    credit.credits = 0
                    credit.creditsTargeted = 0
                } else {
                    credit.credits = credit.credits * 1
                }
            }

            if (credit.creditCategoryCode === "LE 05" && (credit.creditDescription === "Prerequisite (route 1)" || credit.creditDescription === "Management and maintenance - Landscape and ecology management plan (or similar) development (route 1)")) {
                if (LERoute === "Route 1") {
                    credit.credits = credit.credits * 1
                } else {
                    credit.credits = 0
                    credit.creditsTargeted = 0
                }
            }

            if (credit.creditCategoryCode === "LE 05" && (credit.creditDescription === "Prerequisite (route 2)" || credit.creditDescription === "Management and maintenance (route 2)" || credit.creditDescription === "Landscape and ecology management plan (or similar) development (route 2)")) {
                if (LERoute === "Route 1") {
                    credit.credits = 0
                    credit.creditsTargeted = 0
                } else {
                    credit.credits = credit.credits * 1
                }
            }

            if (credit.creditCategoryCode === "Pol 01" && credit.creditDescription === "No Refrigerants") {
                if (refrigerant === "NO") {
                    credit.credits = 1
                } else {
                    credit.credits = 0
                    credit.creditsTargeted = 0
                }
            }

            if (credit.creditCategoryCode === "Pol 01" && (credit.creditDescription === "Prerequisite - BS EN 378:2016 (ammonia)" || credit.creditDescription === "Impact of refrigerant" || credit.creditDescription === "Leak detection")) {
                if (refrigerant === "NO") {
                    credit.credits = 0
                    credit.creditsTargeted = 0
                } else {
                    credit.credits = 1
                }
            }
            //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        
            //filter requiredDocumentaryEvidence to this allBREEAMCredits[credit]

            if (stage === "Design") {

                documentaryEvidenceItems = requiredDocumentaryEvidenceDesignStage.filter(item => item.creditCategoryCode === credit.creditCategoryCode && item.creditDescription === credit.creditDescription)

            } else {

                documentaryEvidenceItems = requiredDocumentaryEvidencePCRStage.filter(item => item.creditCategoryCode === credit.creditCategoryCode && item.creditDescription === credit.creditDescription)
            }

            for (evidenceDocument = 0; evidenceDocument < documentaryEvidenceItems.length; evidenceDocument++) {
                
                evidenceDocumentRequired = "YES"

                //Design Stage Exception rules here:
                //If it's an exception then evidenceDocumentRequired = "NO"

                if (stage === "Design") {

                    if (credit.creditCategoryCode === "Man 03" && credit.creditDescription === "Prerequisite - Legally harvested & traded timber" && buildingType !== "Healthcare") {
                        if (documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "Pre-requisite: Written confirmation that any party who manages the site will operate under an Environmental Management System ISO 14001 or BS 8555:2016 (main contractor / demolition contractor / etc.)" ) {
                            evidenceDocumentRequired = "NO"
                        }
                    }

                    if (credit.creditCategoryCode === "Man 03" && credit.creditsTargeted === 1 && documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "Written commitment that the main contractor will carry out the mandatory BREEAM Responsible Construction Management requirements, plus six additional requirements.") {
                        evidenceDocumentRequired = "NO"
                    }

                    if (credit.creditCategoryCode === "Man 03" && credit.creditsTargeted === 2 && documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "Written commitment that the main contractor will carry out the mandatory BREEAM Responsible Construction Management requirements.") {
                        evidenceDocumentRequired = "NO"
                    }

                    if (credit.creditCategoryCode === "Hea 02" && credit.creditDescription === "Ventilation") {
                        if (ventilation === "Mechanically" && (documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "Written confirmation that the ventilation strategy is designed in line with CIBSE AM10, and that ventilation intakes and airflow pathways are designed in line with BRE FB 30; BRE IP 9/14; CIBSE TM21" || documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "Drawings demonstrating that ventilation inlets / openable windows / trickle and background ventilators will be positioned at least 10m horizontal distance from sources of external pollution" || documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "WHERE BUILDING  AREAS ARE SUBJECT TO LARGE / VARIABLE OCCUPANCY - Written confirmation that CO2 sensors / air quality sensors will be installed in areas of large / variable occupancy - sensors to have the capacity to either notify the building manager or automatically open windows / roof vents")) {
                            evidenceDocumentRequired = "NO"
                        }

                        if ((ventilation === "Naturally") && documentaryEvidenceItems[evidenceDocument].documentaryEvidence !== "Written confirmation that the ventilation strategy is designed in line with CIBSE AM10, and that ventilation intakes and airflow pathways are designed in line with BRE FB 30; BRE IP 9/14; CIBSE TM21" && documentaryEvidenceItems[evidenceDocument].documentaryEvidence !== "Drawings demonstrating that ventilation inlets / openable windows / trickle and background ventilators will be positioned at least 10m horizontal distance from sources of external pollution" && documentaryEvidenceItems[evidenceDocument].documentaryEvidence !== "WHERE BUILDING  AREAS ARE SUBJECT TO LARGE / VARIABLE OCCUPANCY - Written confirmation that CO2 sensors / air quality sensors will be installed in areas of large / variable occupancy - sensors to have the capacity to either notify the building manager or automatically open windows / roof vents") {
                            evidenceDocumentRequired = "NO"
                        }
                    }

                    if (credit.creditCategoryCode === "Hea 02" && credit.creditsTargeted !== "1" && documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "Written confirmation that VOC (volatile organic compounds) requirements will be met for at least three of the following product types:\n1. Interior paint and coatings\n2. Wood-based products (including timber floor)\n3. Flooring materials (including floor levelling compounds and resin flooring)\n4. Ceiling, wall, and acoustic and thermal insulation materials\n5. Interior adhesives and sealants (including floor adhesives)") {
                        evidenceDocumentRequired = "NO"
                    }

                    if (credit.creditCategoryCode === "Hea 02" && credit.creditsTargeted === "1" && documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "Written confirmation that VOC (volatile organic compounds) requirements will be met for all of the following product types:\n1. Interior paint and coatings\n2. Wood-based products (including timber floor)\n3. Flooring materials (including floor levelling compounds and resin flooring)\n4. Ceiling, wall, and acoustic and thermal insulation materials\n5. Interior adhesives and sealants (including floor adhesives)") {
                        evidenceDocumentRequired = "NO"
                    }

                    if (credit.creditCategoryCode === "Hea 05" && !buildingType.toLowerCase().includes("residential") && credit.documentaryEvidence.includes("Acoustic report demonstrating that")) {
                        evidenceDocumentRequired = "NO"
                    }

                    if (credit.creditCategoryCode === "Wat 02" && swimmingPoolsOrLargeWaterTanksAndAquariums === "NO" && documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "Compliance letter confirming separate sub-meters will be fitted on the swimming pool water supply and any associated changing facilities (toilets, showers etc.)") {
                        evidenceDocumentRequired = "NO"
                    }

                    if (credit.creditCategoryCode === "Wat 02" && laboratory === "NO" && documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "Compliance letter confirming a separate water meter will be fitted on the water supply to any process or cooling loop for ‘plumbed-in’ laboratory process equipment") {
                        evidenceDocumentRequired = "NO"
                    }

                    if (credit.creditCategoryCode === "Wat 02" && pursuingAPostOccupanStageCertification === "NO" && documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "Written confirmation of the water monitoring strategy/Copy of the post occupancy evaluation contract, outlining how this will enable the identification of all water consumption for sanitary uses.") {
                        evidenceDocumentRequired = "NO"
                    }

                    if (credit.creditCategoryCode === "Wat 04" && unregulatedWaterUsesPresent === "YES" && documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "Compliance letter confirming that no irrigation systems are specified, and therefore there will be no unregulated water demands for the building") {
                        evidenceDocumentRequired = "NO"
                    }

                    if (credit.creditCategoryCode === "Wat 04" && unregulatedWaterUsesPresent === "NO" && documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "Compliance letter confirming the irrigation strategy") {
                        evidenceDocumentRequired = "NO"
                    }

                    if (credit.creditCategoryCode === "Wst 01" && !(credit.creditsTargeted === "1" || credit.creditsPotential === "1") && documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "Confirmation that less than 13.3 m3 or 11.1 tonnes of waste will generated per 100m2 of floor area") {
                        evidenceDocumentRequired = "NO"
                    }

                    if (credit.creditCategoryCode === "Wst 01" && !(credit.creditsTargeted === "2" || credit.creditsPotential === "2") && documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "Confirmation that less than 7.5 m3 or 6.5 tonnes of waste will generated per 100m2 of floor area") {
                        evidenceDocumentRequired = "NO"
                    }

                    if (credit.creditCategoryCode === "Wst 01" && !(credit.creditsTargeted === "3" || credit.creditsPotential === "3") && documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "Confirmation that less than 3.4 m3 or 3.2 tonnes of waste will generated per 100m2 of floor area") {
                        evidenceDocumentRequired = "NO"
                    }

                    if (credit.creditCategoryCode === "Wst 03" && consistentAndLargeAmountsOfOperationalWasteGenerated === "NO" && documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "Confirmation that a compliant Baler / Waste Compactor / Composting facilities has been specified") {
                        evidenceDocumentRequired = "NO"
                    }

                    if (credit.creditCategoryCode === "Wst 03" && !buildingType.toLowerCase().includes("residential") && documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "Confirmation that each dwelling has adequate internal waste storage") {
                        evidenceDocumentRequired = "NO"
                    }

                    if (credit.creditCategoryCode === "Wst 03" && !buildingType.toLowerCase().includes("education") && documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "Confirmation that a school/college recycling policy is in place") {
                        evidenceDocumentRequired = "NO"
                    }

                    if (credit.creditCategoryCode === "LE 03" && credit.creditsTargeted === "1" && documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "Confirmation from ecologist that negative impacts from site preparation and construction works will be managed according to the mitigation hierarchy, and no overall loss of ecological value has occurred") {
                        evidenceDocumentRequired = "NO"
                    }

                    if (credit.creditCategoryCode === "LE 03" && credit.creditsTargeted !== "1" && documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "Confirmation from ecologist that negative impacts from site preparation and construction works will be managed according to the mitigation hierarchy, and the loss of ecological value has been minimised") {
                        evidenceDocumentRequired = "NO"
                    }

                }

                //PCR  Stage Exception rules here:
                //If it's an exception then evidenceDocumentRequired = "NO"

                if (stage === "PCR") {

                    if (credit.creditCategoryCode === "Man 03" && credit.creditDescription === "Prerequisite - Legally harvested & traded timber" && buildingType !== "Healthcare") {
                        if (documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "Copies of certification (for example, FSC / PEFC) / or other supplier confirmation that all site timber has been legally sourced." ) {
                            evidenceDocumentRequired = "NO"
                        }
                    }

                    if (credit.creditCategoryCode === "Man 03" && credit.creditsTargeted === 1 && documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "Completed Checklist: BREEAM Responsible Construction Management, and final CCS scoring report and certificate (if carried out).") {
                        evidenceDocumentRequired = "NO"
                    }

                    if (credit.creditCategoryCode === "Man 03" && credit.creditsTargeted !== 1 && documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "Completed Checklist: BREEAM Responsible Construction Management.") {
                        evidenceDocumentRequired = "NO"
                    }

                    if (credit.creditCategoryCode === "Man 03" && credit.creditsTargeted === 1 && documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "Supporting documentation for the BREEAM Responsible Construction Management requirements (this can include photographic documentation; site layout plans; site procedures documents; site log books and training schedules).") {
                        evidenceDocumentRequired = "NO"
                    }

                    if (credit.creditCategoryCode === "Man 03" && credit.creditsTargeted !== 1 && documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "Supporting documentation for the BREEAM Responsible Construction Management requirements (this can include photographic documentation; site layout plans; site procedures documents for minimising pollution, keeping site tidy, etc.)") {
                        evidenceDocumentRequired = "NO"
                    }

                    if (credit.creditCategoryCode === "Hea 02" && credit.creditDescription === "Ventilation") {
                        if (ventilation === "Mechanically" && (documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "Where there have been changes since the design stage, written confirmation is provided to confirm that the ventilation strategy has been installed in line with CIBSE AM10, and that ventilation intakes and airflow pathways are designed in line with BRE FB 30; BRE IP 9/14; CIBSE TM21" || documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "Where there have been changes since the design stage, as-built drawings demonstrate that ventilation inlets / openable windows / trickle and background ventilators have been positioned at least 10m horizontal distance from sources of external pollution" || documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "Where there have been changes since the design stage in areas with large or variable occupancy, written confirmation is provided to confirm that CO2 sensors / air quality sensors have been installed in these areas, with sensors that have the capacity to either notify the building manager or automatically open windows / roof vents")) {
                            evidenceDocumentRequired = "NO"
                        }

                        if ((ventilation === "Naturally") && (documentaryEvidenceItems[evidenceDocument].documentaryEvidence !== "Where there have been changes since the design stage, written confirmation is provided to confirm that the ventilation strategy has been installed in line with CIBSE AM10, and that ventilation intakes and airflow pathways are designed in line with BRE FB 30; BRE IP 9/14; CIBSE TM21" && documentaryEvidenceItems[evidenceDocument].documentaryEvidence !== "Where there have been changes since the design stage, as-built drawings demonstrate that ventilation inlets / openable windows / trickle and background ventilators have been positioned at least 10m horizontal distance from sources of external pollution" && documentaryEvidenceItems[evidenceDocument].documentaryEvidence !== "Where there have been changes since the design stage in areas with large or variable occupancy, written confirmation is provided to confirm that CO2 sensors / air quality sensors have been installed in these areas, with sensors that have the capacity to either notify the building manager or automatically open windows / roof vents")) {
                            evidenceDocumentRequired = "NO"
                        }
                    }

                    if (credit.creditCategoryCode === "Hea 02" && credit.creditsTargeted === 1 && documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "Manufacturer's literature confirming that VOC (volatile organic compounds) requirements are met for all of the following product types:\n1. Interior paint and coatings\n2. Wood-based products (including timber floor)\n3. Flooring materials (including floor levelling compounds and resin flooring)\n4. Ceiling, wall, and acoustic and thermal insulation materials\n5. Interior adhesives and sealants (including floor adhesives)") {
                        evidenceDocumentRequired = "NO"
                    }

                    if (credit.creditCategoryCode === "Hea 02" && credit.creditsTargeted !== 1 && documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "Manufacturer's literature confirming that VOC (volatile organic compounds) requirements are met for at least three of the following product types:\n1. Interior paint and coatings\n2. Wood-based products (including timber floor)\n3. Flooring materials (including floor levelling compounds and resin flooring)\n4. Ceiling, wall, and acoustic and thermal insulation materials\n5. Interior adhesives and sealants (including floor adhesives)") {
                        evidenceDocumentRequired = "NO"
                    }

                    if (credit.creditCategoryCode === "Hea 05" && !buildingType.toLowerCase().includes("residential") && credit.documentaryEvidence.includes("Acoustic report demonstrating that")) {
                        evidenceDocumentRequired = "NO"
                    }

                    if (credit.creditCategoryCode === "Wat 02" && swimmingPoolsOrLargeWaterTanksAndAquariums === "NO" && documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "As Built drawing to confirm the location of separate, dedicated meters for the swimming pool and associated facilities (toilets, showers etc.)") {
                        evidenceDocumentRequired = "NO"
                    }

                    if (credit.creditCategoryCode === "Wat 02" && swimmingPoolsOrLargeWaterTanksAndAquariums === "NO" && documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "Assessor's site inspection report confirming the location and labelling of sub-meter") {
                        evidenceDocumentRequired = "NO"
                    }

                    if (credit.creditCategoryCode === "Wat 02" && laboratory === "NO" && documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "As Built drawing to confirm the location of separate, dedicated meters for any process or cooling loop for ‘plumbed-in’ laboratory process equipment") {
                        evidenceDocumentRequired = "NO"
                    }

                    if (credit.creditCategoryCode === "Wat 02" && laboratory === "NO" && documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "Assessor's site inspection report confirming the location and labelling of sub-meter") {
                        evidenceDocumentRequired = "NO"
                    }

                    if (credit.creditCategoryCode === "Wat 02" && pursuingAPostOccupanStageCertification === "NO" && documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "Written confirmation of the water monitoring strategy/Copy of the post occupancy evaluation contract, outlining how this will enable the identification of all water consumption for sanitary uses.") {
                        evidenceDocumentRequired = "NO"
                    }

                    if (credit.creditCategoryCode === "Wat 04" && unregulatedWaterUsesPresent === "YES" && documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "Assessor's site inspection confirming no unregulated water demands on site") {
                        evidenceDocumentRequired = "NO"
                    }

                    if (credit.creditCategoryCode === "Wat 04" && unregulatedWaterUsesPresent === "NO" && documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "Assessor's site inspection confirming the irrigation strategy on site") {
                        evidenceDocumentRequired = "NO"
                    }

                    if (credit.creditCategoryCode === "Wat 04" && unregulatedWaterUsesPresent === "YES" && documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "Compliance letter confirming that no irrigation systems have been specified, and therefore there are no unregulated water demands for the building") {
                        evidenceDocumentRequired = "NO"
                    }

                    if (credit.creditCategoryCode === "Wat 04" && unregulatedWaterUsesPresent === "NO" && documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "Confirmation that there have been no changes to the irrigation strategy specified at the design stage") {
                        evidenceDocumentRequired = "NO"
                    }

                    if (credit.creditCategoryCode === "Wst 03" && consistentAndLargeAmountsOfOperationalWasteGenerated === "NO" && documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "Manufacturer's documentation for the Baler / Waste Compactor / Composting facilities installed (if applicable)") {
                        evidenceDocumentRequired = "NO"
                    }
                    
                    if (credit.creditCategoryCode === "Wst 03" && consistentAndLargeAmountsOfOperationalWasteGenerated === "NO" && documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "As-built drawings showing the location of the installed Baler / Waste Compactor / Composting facilities") {
                        evidenceDocumentRequired = "NO"
                    }

                    if (credit.creditCategoryCode === "Wst 03" && !buildingType.toLowerCase().includes("residential") && documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "Assessor's site inspection confirming that each dwelling has adequate internal waste storage") {
                        evidenceDocumentRequired = "NO"
                    }

                    if (credit.creditCategoryCode === "Wst 03" && !buildingType.toLowerCase().includes("education") && documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "Copy of the school/college recycling policy, confirmation that the policy is in place") {
                        evidenceDocumentRequired = "NO"
                    }

                    if (credit.creditCategoryCode === "LE 03" && credit.creditsTargeted === "1" && documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "Confirmation from ecologist that negative impacts from site preparation and construction works will be managed according to the mitigation hierarchy, and no overall loss of ecological value has occurred") {
                        evidenceDocumentRequired = "NO"
                    }

                    if (credit.creditCategoryCode === "LE 03" && credit.creditsTargeted !== "1" && documentaryEvidenceItems[evidenceDocument].documentaryEvidence === "Confirmation from ecologist that negative impacts from site preparation and construction works will be managed according to the mitigation hierarchy, and the loss of ecological value has been minimised") {
                        evidenceDocumentRequired = "NO"
                    }
                    
                }

                if (match.params.buildingBREEAMAssessmentId === "_add") {
                
                    credit.documentaryEvidence.push({id: uuidv4(), description: documentaryEvidenceItems[evidenceDocument].documentaryEvidence, filename: "", complianceLetter: documentaryEvidenceItems[evidenceDocument].complianceLetter, responsibility: documentaryEvidenceItems[evidenceDocument].responsibility, timeCritical: documentaryEvidenceItems[evidenceDocument].timeCritical, evidenceDocumentRequired, requiredAtStage: documentaryEvidenceItems[evidenceDocument].requiredAtStage})
                
                } else {

                    credit.documentaryEvidence.find(item => item.description === documentaryEvidenceItems[evidenceDocument].documentaryEvidence).evidenceDocumentRequired = evidenceDocumentRequired

                }

            }

            return credit
        }

    const deleteTeamMember = (id) => {

        if (window.confirm('Are you sure you want to delete this team member?')) {
    
          //code here to get array index of scope item id=id
        
            const index = projectTeam.findIndex(teamMember => teamMember.id === id)

            projectTeam.splice(index, 1);

            const form = { buildingId, projectName, projectNumber, targetRating, BREEAMRegistrationNumber, certificationLevelTargeted, netFloorArea, projectManager, coPilot, assessmentDate, buildingType, assessmentType, officeOrOtherOccupiedAreas, unregulatedWaterUsesPresent, laboratory, lift, escalators, refrigerant, coldStorage, unregulatedEnergyLoad, LERoute, swimmingPoolsOrLargeWaterTanksAndAquariums, pursuingAPostOccupanStageCertification, consistentAndLargeAmountsOfOperationalWasteGenerated, ventilation, projectTeam, credits, schedule, stage, comments }
            axios.put(process.env.REACT_APP_API_URL + '/buildings_BREEAM_assessments/' + match.params.buildingBREEAMAssessmentId, form)
            setProjectTeam(projectTeam.filter((teamMember) => teamMember.id !== id))

        }

    }

    const addTeamMember = () => {

        setProjectTeam([ ...projectTeam, {id: uuidv4(), role: roleToAdd, company: companyToAdd, name: nameToAdd, email: emailToAdd, tel: telToAdd} ] )

    }

    const deleteEvent = (id) => {

        if (window.confirm('Are you sure you want to delete this event?')) {
    
          //code here to get array index of scope item id=id
        
            const index = schedule.findIndex(event => event.id === id)

            schedule.splice(index, 1);

            const form = { buildingId, projectName, projectNumber, targetRating, BREEAMRegistrationNumber, certificationLevelTargeted, netFloorArea, projectManager, coPilot, assessmentDate, buildingType, assessmentType, officeOrOtherOccupiedAreas, unregulatedWaterUsesPresent, laboratory, lift, escalators, refrigerant, coldStorage, unregulatedEnergyLoad, LERoute, swimmingPoolsOrLargeWaterTanksAndAquariums, pursuingAPostOccupanStageCertification, consistentAndLargeAmountsOfOperationalWasteGenerated, ventilation, projectTeam, credits, schedule, stage, comments }
            axios.put(process.env.REACT_APP_API_URL + '/buildings_BREEAM_assessments/' + match.params.buildingBREEAMAssessmentId, form)
            setSchedule(schedule.filter((event) => event.id !== id))

        }

    }

    const addEvent = () => {

        setSchedule([ ...schedule, {id: uuidv4(), stage: stageToAdd, event: eventToAdd, eventDate: eventDateToAdd, eventReminderDate: eventReminderDateToAdd, eventOwner: eventOwnerToAdd} ] )

    }

    const addForm = async (form) => {
        await axios.post(process.env.REACT_APP_API_URL + '/buildings_BREEAM_assessments', form)
    }

    const updateForm = async (form) => {
        await axios.put(process.env.REACT_APP_API_URL + '/buildings_BREEAM_assessments/' + match.params.buildingBREEAMAssessmentId, form)
    }

    const onSubmit = (e) => {

        e.preventDefault()

        if (projectName !== "" && 
        stage !== "" && 
        targetRating !== "" && 
        buildingType !== "" && 
        assessmentType !== "" &&
        officeOrOtherOccupiedAreas !== "" &&
        unregulatedWaterUsesPresent !== "" &&
        laboratory !== "" &&
        lift !== "" &&
        escalators !== "" &&
        refrigerant !== "" &&
        coldStorage !== "" &&
        unregulatedEnergyLoad !== "" &&
        LERoute !== "" &&
        swimmingPoolsOrLargeWaterTanksAndAquariums !== "" &&
        pursuingAPostOccupanStageCertification !== "" &&
        consistentAndLargeAmountsOfOperationalWasteGenerated !== "" &&
        ventilation !== "") {

            var credit

            if (match.params.buildingBREEAMAssessmentId !== "_add") {

                for (credit = 0; credit < credits.length; credit++) {

                    credits[credit] = performExceptionChecks(credits[credit])

                }

                updateForm({ buildingId, projectName, projectNumber, targetRating, BREEAMRegistrationNumber, certificationLevelTargeted, netFloorArea, projectManager, coPilot, assessmentDate, buildingType, assessmentType, officeOrOtherOccupiedAreas, unregulatedWaterUsesPresent, laboratory, lift, escalators, refrigerant, coldStorage, unregulatedEnergyLoad, LERoute, swimmingPoolsOrLargeWaterTanksAndAquariums, pursuingAPostOccupanStageCertification, consistentAndLargeAmountsOfOperationalWasteGenerated, ventilation, projectTeam, credits, schedule, stage, comments }).then(res => { navigate(-1) })
            
            } else {

                //Code here to use buildings_BREAAM_credits and add them
                var creditsArray = []
                
                for (credit = 0; credit < allBREEAMCredits.length; credit++) {

                    creditsArray.push(allBREEAMCredits[credit])
                    creditsArray[credit].id = uuidv4()
                    creditsArray[credit].files = []
                    creditsArray[credit].documentaryEvidence = []

                    creditsArray[credit] = performExceptionChecks(creditsArray[credit])

                }

                addForm({ buildingId, projectName, projectNumber, targetRating, BREEAMRegistrationNumber, certificationLevelTargeted, netFloorArea, projectManager, coPilot, assessmentDate, buildingType, assessmentType, officeOrOtherOccupiedAreas, unregulatedWaterUsesPresent, laboratory, lift, escalators, refrigerant, coldStorage, unregulatedEnergyLoad, LERoute, swimmingPoolsOrLargeWaterTanksAndAquariums, pursuingAPostOccupanStageCertification, consistentAndLargeAmountsOfOperationalWasteGenerated, ventilation, projectTeam, credits: creditsArray, schedule, stage, comments }).then(res => { navigate(-1) })
            
        }
    }

    }

    if (!loading) {

        return (

                <div className='flex items-center bg-jungle-mist-100 min-h-screen min-w-fit'>
                    <div className="container-form-8A">

                    <Header title="Building EPC - Add / Edit Building BREEAM Assessment" section="Building BREEAM Assessment Main Details" iconFileName="Building-Hover" />

                    <form className="add-form-8A" onSubmit={onSubmit}>

                        <div className="form-control-8A">
                            <label>Project Name</label>
                            <input type="text" placeholder="Enter Project Name Here" value={projectName} onChange={(e) => setProjectName(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Stage</label>
                            {/* <input type="text" placeholder="Enter Stage Here" value={stage} onChange={(e) => setStage(e.target.value)} /> */}
                            <select disabled={match.params.buildingBREEAMAssessmentId === "_add" ? false : true} value={stage} onChange={(e) => setStage(e.target.value)}>
                                <option value="">Please Select...</option>
                                <option value="Design">Design</option>
                                <option value="PCR">PCR</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Project Number</label>
                            <input type="text" placeholder="Enter Project Number Here" value={projectNumber} onChange={(e) => setProjectNumber(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Required Rating</label>
                            <select value={targetRating} onChange={(e) => setTargetRating(e.target.value)}>
                                <option value="">Please Select...</option>
                                <option value="Pass">Pass</option>
                                <option value="Good">Good</option>
                                <option value="Very Good">Very Good</option>
                                <option value="Excellent">Excellent</option>
                                <option value="Outstanding">Outstanding</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>BREEAM Registration Number</label>
                            <input type="text" placeholder="Enter BREEAM Registration Number Here" value={BREEAMRegistrationNumber} onChange={(e) => setBREEAMRegistrationNumber(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Certification Level Targeted</label>
                            <input type="text" placeholder="Enter Certification Level Targeted Here" value={certificationLevelTargeted} onChange={(e) => setcertificationLevelTargeted(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Net Floor Area</label>
                            <input type="text" placeholder="Enter EPC Link Here" value={netFloorArea} onChange={(e) => setNetFloorArea(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Project Manager</label>
                            <input type="text" placeholder="Enter Project Manager Here" value={projectManager} onChange={(e) => setProjectManager(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label>Project Co Pilot</label>
                            <input type="text" placeholder="Enter Project Co Pilot Here" value={coPilot} onChange={(e) => setCoPilot(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label> Date</label>
                            <input type="date" placeholder="Enter Date Here" value={assessmentDate} onChange={(e) => setAssessmentDate(e.target.value)} />
                        </div>

                        <div className="form-control-8A">
                            <label> Building Type</label>
                            <select value={buildingType} onChange={(e) => setBuildingType(e.target.value)}>
                                <option value="">Please Select...</option>
                                <option value="Education-Preschool and primary school">Education-Preschool and primary school</option>
                                <option value="Education-Further educational or vocational colleges and higher education institutions">Education-Further educational or vocational colleges and higher education institutions</option>
                                <option value="Healthcare">Healthcare</option>
                                <option value="Multi-residential">Multi-residential</option>
                                <option value="Multi-residential - Residential institution (long term stay)">Multi-residential - Residential institution (long term stay)</option>
                                <option value="Other - Residential institution (short term stay)">Other - Residential institution (short term stay)</option>
                                <option value="Retail">Retail</option>
                                <option value="Office">Office</option>
                                <option value="Industrial">Industrial</option>
                                <option value="Law Courts">Law Courts</option>
                                <option value="Prison">Prison</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Assessment Type</label>
                            <select value={assessmentType} onChange={(e) => setAssessmentType(e.target.value)}>
                                <option value="">Please Select...</option>
                                <option value="Fully Fitted">Fully Fitted</option>
                                <option value="Shell and Core">Shell and Core</option>
                                <option value="Shell Only">Shell Only</option>
                                <option value="Simple Building">Simple Building</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Office or other occupied areas</label>
                            <select value={officeOrOtherOccupiedAreas} onChange={(e) => setOfficeOrOtherOccupiedAreas(e.target.value)}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Unregulated water uses present</label>
                            <select value={unregulatedWaterUsesPresent} onChange={(e) => setUnregulatedWaterUsesPresent(e.target.value)}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Laboratory</label>
                            <select value={laboratory} onChange={(e) => setLaboratory(e.target.value)}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Lift</label>
                            <select value={lift} onChange={(e) => setLift(e.target.value)}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Escalators</label>
                            <select value={escalators} onChange={(e) => setEscalators(e.target.value)}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Refrigerant</label>
                            <select value={refrigerant} onChange={(e) => setRefrigerant(e.target.value)}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Cold Storage</label>
                            <select value={coldStorage} onChange={(e) => setColdStorage(e.target.value)}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Unregulated energy load</label>
                            <select value={unregulatedEnergyLoad} onChange={(e) => setUnregulatedEnergyLoad(e.target.value)}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>LE Route</label>
                            <select value={LERoute} onChange={(e) => setLERoute(e.target.value)}>
                                <option value="">Please Select...</option>
                                <option value="Route 1">Route 1</option>
                                <option value="Route 2">Route 2</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Swimming pools, or large water tanks and aquariums</label>
                            <select value={swimmingPoolsOrLargeWaterTanksAndAquariums} onChange={(e) => setSwimmingPoolsOrLargeWaterTanksAndAquariums(e.target.value)}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Pursuing a post occupancy stage certification</label>
                            <select value={pursuingAPostOccupanStageCertification} onChange={(e) => setPursuingAPostOccupanStageCertification(e.target.value)}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Consistent and large amounts of operational waste generated</label>
                            <select value={consistentAndLargeAmountsOfOperationalWasteGenerated} onChange={(e) => setConsistentAndLargeAmountsOfOperationalWasteGenerated(e.target.value)}>
                                <option value="">Please Select...</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>
                            </select>
                        </div>

                        <div className="form-control-8A">
                            <label>Ventilation</label>
                            <select value={ventilation} onChange={(e) => setVentilation(e.target.value)}>
                                <option value="">Please Select...</option>
                                <option value="Mechanically">Mechanically</option>
                                <option value="Naturally">Naturally</option>
                                <option value="Mixed">Mixed</option>
                            </select>
                        </div>
                        {!showAddTeamMemberFieldsToggle &&
                        <Button className='bg-bondi-blue' text="Click here to add new project team members" onClick={() => setShowAddTeamMemberFieldsToggle(true)}/>
                        }

                        {showAddTeamMemberFieldsToggle && (
                            <div>
                                <div className="grid grid-cols-3 gap-3">
                                    <div className="form-control-8A">
                                        <label className="text-center">Role</label>
                                        <select className="bg-slate-100" value={roleToAdd} onChange={(e) => setRoleToAdd(e.target.value)}>
                                            <option value="">Please Select...</option>
                                            <option value="Project Manager">Project Manager</option>
                                            <option value="Client">Client</option>
                                            <option value="Architect">Architect</option>
                                            <option value="M&E">M&E</option>
                                            <option value="Contractor">Contractor</option>
                                            <option value="Civil Engineer">Civil Engineer</option>
                                            <option value="Ecologist">Ecologist</option>
                                            <option value="Specialist Consultant">Specialist Consultant</option>
                                            <option value="Eight Versa">Eight Versa</option>
                                            <option value="BREEAM Accredited Professional">BREEAM Accredited Professional</option>
                                        </select>
                                    </div>
                                    <div className="form-control-8A">
                                        <label className="text-center">Company</label>
                                        <input className="bg-slate-100"  type="text" placeholder="Enter Value Here"  value={companyToAdd} onChange={(e) => setCompanyToAdd(e.target.value)} />
                                    </div>
                                    <div className="form-control-8A">
                                        <label className="text-center">Name</label>
                                        <input className="bg-slate-100"  type="text" placeholder="Enter Value Here"  value={nameToAdd} onChange={(e) => setNameToAdd(e.target.value)} />
                                    </div>
                                    <div className="form-control-8A">
                                        <label className="text-center">Email</label>
                                        <input className="bg-slate-100"  type="text" placeholder="Enter Value Here"  value={emailToAdd} onChange={(e) => setEmailToAdd(e.target.value)} />
                                    </div>
                                    <div className="form-control-8A">
                                        <label className="text-center">Tel</label>
                                        <input className="bg-slate-100"  type="text" placeholder="Enter Value Here"  value={telToAdd} onChange={(e) => setTelToAdd(e.target.value)} />
                                    </div>
                                </div>

                                <div className="h3">
                                    <Button color="bg-bondi-blue" text="Add to team >>" onClick={() => addTeamMember()} />
                                </div>
                            </div>
                        )}

                        <div className="shadow-md shadow-slate-300 overflow-hidden rounded-2xl m-4">
                            <table className="objects-8A min-w-full divide-y divide-gray-200">

                                <thead className="bg-pacific-blue-50">
                                    <tr>
                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Role</th>
                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Company</th>
                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Name</th>
                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Email</th>
                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Tel</th>
                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Actions</th>
                                    </tr>
                                </thead>

                                <tbody className="bg-white divide-y divide-gray-200">
                                    {projectTeam.map((teamMember) => (
                                        <tr key={teamMember.id}>
                                            <td className="px-4 md:px-6  py-2 hover:cursor-pointer"> {teamMember.role} </td>
                                            <td className="px-4 md:px-6  py-2 hover:cursor-pointer"><div onClick={() => { setShowModal(true); setValueToSendToModal(teamMember.company); setFieldToSendToModal("Team Member Company"); setObjectIdToSendToModal(teamMember.id) }} className="text-sm font-medium text-pacific-blue hover:cursor-pointer whitespace-pre-line"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
  <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
  <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
</svg> {teamMember.company}</div></td>
                                            <td className="px-4 md:px-6  py-2 hover:cursor-pointer"><div onClick={() => { setShowModal(true); setValueToSendToModal(teamMember.name); setFieldToSendToModal("Team Member Name"); setObjectIdToSendToModal(teamMember.id) }} className="text-sm font-medium text-pacific-blue hover:cursor-pointer whitespace-pre-line"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
  <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
  <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
</svg> {teamMember.name}</div></td>
                                            <td className="px-4 md:px-6  py-2 hover:cursor-pointer"><div onClick={() => { setShowModal(true); setValueToSendToModal(teamMember.email); setFieldToSendToModal("Team Member Email"); setObjectIdToSendToModal(teamMember.id) }} className="text-sm font-medium text-pacific-blue hover:cursor-pointer whitespace-pre-line"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
  <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
  <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
</svg> {teamMember.email}</div></td>
                                            <td className="px-4 md:px-6  py-2 hover:cursor-pointer"><div onClick={() => { setShowModal(true); setValueToSendToModal(teamMember.tel); setFieldToSendToModal("Team Member Tel"); setObjectIdToSendToModal(teamMember.id) }} className="text-sm font-medium text-pacific-blue hover:cursor-pointer whitespace-pre-line"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
  <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
  <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
</svg> {teamMember.tel}</div></td>
                                            <td className="px-4 md:px-6 py-2 whitespace-nowrap md:pr-10 lg:pr-20 float-right ">
                                                <Options deleteFunction={deleteTeamMember} deleteObject={teamMember.id}></Options>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>

                            </table>
                        </div>

                        {!showAddEventFieldsToggle &&
                        <Button className='bg-bondi-blue' text="Click here to add new event" onClick={() => setShowAddEventFieldsToggle(true)}/>
                        }

                        {showAddEventFieldsToggle && (
                            <div>
                                <div className="grid grid-cols-3 gap-3">
                                    <div className="form-control-8A">
                                    <label className="text-center">Role</label>
                                        <select className="bg-slate-100" value={stageToAdd} onChange={(e) => setStageToAdd(e.target.value)}>
                                            <option value="">Please Select...</option>
                                            <option value="Stage 1">Stage 1</option>
                                            <option value="Stage 2">Stage 2</option>
                                            <option value="Stage 3">Stage 3</option>
                                            <option value="Stage 4">Stage 4</option>
                                            <option value="Stage 5">Stage 5</option>
                                            <option value="Stage 6">Stage 6</option>
                                        </select>
                                    </div>
                                    <div className="form-control-8A">
                                        <label className="text-center">Event</label>
                                        <input className="bg-slate-100"  type="text" placeholder="Enter Value Here"  value={eventToAdd} onChange={(e) => setEventToAdd(e.target.value)} />
                                    </div>
                                    <div className="form-control-8A">
                                        <label className="text-center">Event Date</label>
                                        <input className="bg-slate-100"  type="date" placeholder="Enter Value Here"  value={eventDateToAdd} onChange={(e) => setEventDateToAdd(e.target.value)} />
                                    </div>
                                    <div className="form-control-8A">
                                        <label className="text-center">Event Reminder Date</label>
                                        <input className="bg-slate-100"  type="date" placeholder="Enter Value Here"  value={eventReminderDateToAdd} onChange={(e) => setEventReminderToAdd(e.target.value)} />
                                    </div>
                                    <div className="form-control-8A">
                                        <label className="text-center">Event Owner</label>
                                        <select className="bg-slate-100" value={eventOwnerToAdd} onChange={(e) => setEventOwnerToAdd(e.target.value)}>
                                            <option value="">Please Select...</option>
                                            <option value="Project Manager">Project Manager</option>
                                            <option value="Client">Client</option>
                                            <option value="Architect">Architect</option>
                                            <option value="M&E">M&E</option>
                                            <option value="Contractor">Contractor</option>
                                            <option value="Civil Engineer">Civil Engineer</option>
                                            <option value="Ecologist">Ecologist</option>
                                            <option value="Specialist Consultant">Specialist Consultant</option>
                                            <option value="Eight Versa">Eight Versa</option>
                                            <option value="BREEAM Accredited Professional">BREEAM Accredited Professional</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="h3">
                                    <Button color="bg-bondi-blue" text="Add to schedule >>" onClick={() => addEvent()} />
                                </div>
                            </div>
                        )}

                        <div className="shadow-md shadow-slate-300 overflow-hidden rounded-2xl m-4">
                            <table className="objects-8A min-w-full divide-y divide-gray-200">

                                <thead className="bg-pacific-blue-50">
                                    <tr>
                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Stage</th>
                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Event</th>
                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Event Date</th>
                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Reminder Date</th>
                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Owner</th>
                                        <th scope="col" className="px-4 md:px-6  py-3 text-left text-xs font-medium text-congress-blue uppercase tracking-wider"> Actions</th>
                                    </tr>
                                </thead>

                                <tbody className="bg-white divide-y divide-gray-200">
                                    {schedule.map((event) => (
                                        <tr key={event.id}>
                                            <td className="px-4 md:px-6  py-2 hover:cursor-pointer"> {event.stage} </td>
                                            <td className="px-4 md:px-6  py-2 hover:cursor-pointer"><div onClick={() => { setShowModal(true); setValueToSendToModal(event.event); setFieldToSendToModal("BREEAM Stage Event"); setObjectIdToSendToModal(event.id) }} className="text-sm font-medium text-pacific-blue hover:cursor-pointer whitespace-pre-line"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
  <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
  <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
</svg> {event.event}</div></td>
                                            <td className="px-4 md:px-6  py-2 hover:cursor-pointer"><div onClick={() => { setShowModal(true); setValueToSendToModal(event.eventDate); setFieldToSendToModal("BREEAM Stage Event Date"); setObjectIdToSendToModal(event.id) }} className="text-sm font-medium text-pacific-blue hover:cursor-pointer whitespace-pre-line"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
  <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
  <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
</svg> {event.eventDate}</div></td>
                                            <td className="px-4 md:px-6  py-2 hover:cursor-pointer"><div onClick={() => { setShowModal(true); setValueToSendToModal(event.eventReminderDate); setFieldToSendToModal("BREEAM Stage Event Reminder Date"); setObjectIdToSendToModal(event.id) }} className="text-sm font-medium text-pacific-blue hover:cursor-pointer whitespace-pre-line"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
  <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
  <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
</svg> {event.eventReminderDate}</div></td>
                                            <td className="px-4 md:px-6  py-2 hover:cursor-pointer"><div onClick={() => { setShowModal(true); setValueToSendToModal(event.eventOwner); setFieldToSendToModal("BREEAM Stage Event Owner"); setObjectIdToSendToModal(event.id) }} className="text-sm font-medium text-pacific-blue hover:cursor-pointer whitespace-pre-line"> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 float-left w-5" viewBox="0 0 20 20" fill="gray">
  <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
  <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
</svg> {event.eventOwner}</div></td>
                                            <td className="px-4 md:px-6 py-2 whitespace-nowrap md:pr-10 lg:pr-20 float-right ">
                                                <Options deleteFunction={deleteEvent} deleteObject={event.id}></Options>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>

                            </table>
                        </div>

                        <input type="submit" value="Save" className="btn-8A btn-block-8A" />

                    </form>

                    <ModalUpdateValue objectToUpdate={objectIdToSendToModal} fieldToUpdate={fieldToSendToModal} valueToUpdate={valueToSendToModal} arrayToShow={fieldToSendToModal.includes("Team Member") ? projectTeam : schedule} onClose={() => setShowModal(false)} show={showModal} title={fieldToSendToModal.includes("Team Member") ? "Update Team Member" :"Update Scheduled Event"} />

                </div>
            </div>
        )

    } else {

        return (
            <div className='flex items-center bg-jungle-mist-100 min-h-screen'>
                <div className="container-form-8A">
                    <Header title="Building EPC - Add / Edit Building BREEAM Assessment" section="Building BREEAM Assessment Main Details" iconFileName="Building-Hover" />
                    <p>Data is loading...</p>
                </div>
            </div>)

    }

}

export default AddOrUpdateBuildingBREEAMAssessment