import React, { useState, useEffect } from 'react';
import axios from 'axios'


const ForgotPassword = () => {
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState("")
    const [emailError, setEmailError] = useState("")
    const [status, setStatus] = useState("")
    const re = /\S+@\S+\.\S+/


    useEffect(() => {
        if (email.match(re) && email.length < 50) {
            setEmailError("Valid email address format ✔️")
        }
        else if (email.length <= 15) {
            setEmailError("")
        }
        else {
            setEmailError("Enter a valid email address")
        }
    })


    useEffect(() => {
        setLoading(false)
    }, [])

    const addForm = async (form) => {
        await axios.post(process.env.REACT_APP_API_URL + '/mail_forgot', form)
            .then(res => { setStatus('If you have an account with us, check your email for password reset instructions.') })
    }

    const onSubmit = (e) => {
        e.preventDefault()
        addForm({ email })
    }


    if (!loading) {
        return (
            <div className='flex items-center bg-jungle-mist-100 min-h-screen'>
                <div className="container-form-8A w-1/2">

                    <p className=' text-2xl text-congress-blue font-bold'>Forgot password</p>

                    <form className="add-form-8A" onSubmit={onSubmit}>
                        <div className="form-control-8A">
                            <label className=' text-sm font-bold'>Your email address</label>
                            <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <p className='text-xs'>{emailError}</p>
                        <p className='text-xs'>{status}</p>
                        <input type="submit" value="Reset password" className={`bg-emerald-500 block mx-auto text-white text-sm rounded-full shadow-slate-300 shadow-md px-8 py-3 mt-4 cursor-not-allowed ${(emailError === "Valid email address format ✔️") ? 'cursor-pointer' : ''}`} />
                    </form>
                </div>
            </div>
        );
    }



    else {

        return (
            <p>Data is loading...</p>
        );
    }
}

export default ForgotPassword;