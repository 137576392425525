import { useState, useEffect, useMemo } from 'react'
import axios from 'axios'
import Header from '../UI/Header'
import { useNavigate, useMatch } from 'react-router-dom'
import countryList from 'react-select-country-list'
import Button from '../UI/Button'

const AddOrUpdateProject = () => {

    const match = useMatch('/addOrUpdateProject/:projectId')

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)

    const countryOptions = useMemo(() => countryList().getData(), [])

    const [projectNCSId, setProjectNCSId] = useState('')
    const [projectName, setProjectName] = useState('')
    const [projectType, setProjectType] = useState('')
    const [projectLocationCountry, setProjectLocationCountry] = useState('')
    const [projectStartDate, setProjectStartDate] = useState()
    const [projectDetails, setProjectDetails] = useState('')
    const [projectSDGs, setProjectSDGs] = useState([])
    const [projectStatus, setProjectStatus] = useState('')
    const [projectLatitude, setProjectLatitude] = useState(0)
    const [projectLongitude, setProjectLongitude] = useState(0)
    const [projectPolygonCoordinates, setProjectPolygonCoordinates] = useState([])
    const [point, setPoint] = useState(0)
    const [lat, setLat] = useState(0)
    const [lng, setLng] = useState(0)


    useEffect(() => {

        if (match.params.projectId !== "_add") {

            setLoading(true)

            axios.get(process.env.REACT_APP_API_URL + '/offset_projects/' + match.params.projectId)
                .then((res) =>{
                    setProjectNCSId(res.data.projectNCSId)
                    setProjectName(res.data.projectName)
                    setProjectType(res.data.projectType)
                    setProjectLocationCountry(res.data.projectLocationCountry)
                    setProjectStartDate(res.data.projectStartDate)
                    setProjectDetails(res.data.projectDetails)
                    setProjectSDGs(res.data.projectSDGs)
                    setProjectStatus(res.data.projectStatus)
                    setProjectLatitude(res.data.projectLatitude)
                    setProjectLongitude(res.data.projectLongitude)
                    setProjectPolygonCoordinates(res.data.projectPolygonCoordinates)
                    setPoint(res.data.point)
                    setLat(res.data.lat)
                    setLng(res.data.lng)

                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });
        }

    }, [match.params.projectId])

    const addForm = async (form) => {
        await axios.post(process.env.REACT_APP_API_URL + '/offset_projects', form)
    }

    const updateForm = async (form) => {
        await axios.put(process.env.REACT_APP_API_URL + '/offset_projects/' + match.params.projectId, form)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (match.params.projectId !== "_add") {
            updateForm({ projectNCSId, projectName, projectType, projectLocationCountry, projectStartDate, projectDetails, projectSDGs, projectStatus, projectLatitude, projectLongitude, projectPolygonCoordinates, point, lat, lng }).then(res => { navigate(-1) })
        } else {
            addForm({ projectNCSId, projectName, projectType, projectLocationCountry, projectStartDate, projectDetails, projectSDGs, projectStatus, projectLatitude, projectLongitude, projectPolygonCoordinates, point, lat, lng }).then(res => { navigate(-1) })
        }
    }

    const deleteCoordinate = (point) => {
        const index = projectPolygonCoordinates.findIndex(coordinateItem => coordinateItem.point === point)

        projectPolygonCoordinates.splice(index, 1)
        setProjectPolygonCoordinates(projectPolygonCoordinates.filter((coordinateItem) => coordinateItem.point !== point))
    }

    const submitCoordinate = () => {
        setProjectPolygonCoordinates((coordinates) => { return [...coordinates, { point, lat, lng }] })
        console.log(projectPolygonCoordinates)
    }

    if (!loading) {

        return (

            <div className="container-form-8A">

                <Header title="Project - Add / Edit Project" section="Project Main Details" showButtons={false} />

                <form className="add-form-8A" onSubmit={onSubmit}>

                    <div className="form-control-8A">
                        <label>NCS ID</label>
                        <input type="text" placeholder="Enter NCS ID Here" value={projectNCSId} onChange={(e) => setProjectNCSId(e.target.value)} />
                    </div>
                    <div className="form-control-8A">
                        <label>Project Name</label>
                        <input type="text" placeholder="Enter Project Name Here" value={projectName} onChange={(e) => setProjectName(e.target.value)} />
                    </div>
                    <div className="form-control-8A">
                        <label>Project Methodology</label>
                        <input type="text" placeholder="Enter Project Type Here" value={projectType} onChange={(e) => setProjectType(e.target.value)} />
                    </div>
                    <div className="form-control-8A">
                        <label>Project Country</label>
                        <select placeholder="Enter Project Country Here" value={projectLocationCountry} onChange={(e) => setProjectLocationCountry(e.target.value)}>
                            {countryOptions.map((country) => (
                                <option key={country.value} value={country.label}>{country.label}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-control-8A">
                        <label>Project Start Date</label>
                        <input type="text" placeholder="Enter Project Start Date Here" value={projectStartDate} onChange={(e) => setProjectStartDate(e.target.value)} />
                    </div>
                    <div className="form-control-8A">
                        <label>Project Details</label>
                        <input type="text" placeholder="Enter Project Details Here" value={projectDetails} onChange={(e) => setProjectDetails(e.target.value)} />
                    </div>
                    <div className="form-control-8A">
                        <label>Project Status</label>
                        <select value={projectStatus} onChange={(e) => setProjectStatus(e.target.value)}>
                            <option value="">Please Select...</option>
                            <option value="Open to Public Consultation">Open to Public Consultation</option>
                            <option value="Under Validation">Under Validation</option>
                            <option value="Validated">Validated</option>
                            <option value="Verified">Verified</option>
                            <option value="Inactive">Inactive</option>
                            <option value="Not Approved">Not Approved</option>
                        </select>
                    </div>
                    <div className="form-control-8A">
                        <label>Project Latitude</label>
                        <input type="number" placeholder="Enter Project Latitude Here" value={projectLatitude} onChange={(e) => setProjectLatitude(Number(e.target.value))} />
                    </div>
                    <div className="form-control-8A">
                        <label>Project Longitude</label>
                        <input type="number" placeholder="Enter Project Longitude Here" value={projectLongitude} onChange={(e) => setProjectLongitude(Number(e.target.value))} />
                    </div>
                    <div>
                        {/* <div className="btn-8A btn-block-8A text-center"><Button color="black" text="Add New Coordinate"/></div> */}
                        <div>Add New Coordinate:</div>
                        <div className="grid grid-cols-3 gap-10">
                            <div className="form-control-8A">
                                <label className="text-center">Point</label>
                                <input type="number" placeholder="Enter Point Number Here" value={point} onChange={(e) => setPoint(Number(e.target.value))} />
                            </div>
                            <div className="form-control-8A">
                                <label className="text-center">Latitude</label>
                                <input type="number" placeholder="Enter Point Latitude Here" value={lat} onChange={(e) => setLat(Number(e.target.value))} />
                            </div>
                            <div className="form-control-8A">
                                <label className="text-center">Longitude</label>
                                <input type="number" placeholder="Enter Point Longitude Here" value={lng} onChange={(e) => setLng(Number(e.target.value))} />
                            </div>
                        </div>
                    </div>
                    <div className="mt-10">
                        <h4> Project Polygon Coordinates: </h4>

                        <Button color="black" text="Submit Coordinate" onClick={() => submitCoordinate()} />

                        <table className="objects-8A mb-10 mt-5">
                            <thead>
                                <tr>
                                    <th> Point</th>
                                    <th> Latitude</th>
                                    <th> Longitude</th>
                                    <th> Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {projectPolygonCoordinates.map((coordinate) => (
                                    <tr key={coordinate.point}>
                                        <td> {coordinate.point} </td>
                                        <td> {coordinate.lat} </td>
                                        <td> {coordinate.lng}</td>
                                        <td> <Button color="black" text="Delete" onClick={() => deleteCoordinate(coordinate.point)} /></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <input type="submit" value="Save" className="btn-8A btn-block-8A" />

                </form>

            </div>
        )

    } else {

        return (
            <div>
                <Header title="Project - Add / Edit Project" section="Project Main Details" showButtons={false} />
                <p>Data is loading...</p>
            </div>)

    }

}

export default AddOrUpdateProject