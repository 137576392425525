import { useState, useEffect } from 'react'
import axios from 'axios'
import Header from '../UI/Header'
import { useNavigate, useMatch } from 'react-router-dom'

const AddOrUpdateUser = () => {

    const match = useMatch('/addOrUpdateUser/:userId')

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [emailId, setEmailId] = useState('')
    const [department, setDepartment] = useState('')
    const [lineManagerId, setLineManagerId] = useState('')
    const [company, setCompany] = useState('')
    const [clientName, setClientName] = useState('')
    const [hoursPerWeek, setHoursPerWeek] = useState(0)
    const [users, setUsers] = useState([])
    const [departments, setDepartments] = useState([])

    useEffect(() => {

        axios.get(process.env.REACT_APP_API_URL + '/users')
            .then((res) => {
                setUsers(res.data.sort((item1, item2) => item1.firstName + item1.lastName < item2.firstName + item2.lastName ? -1 : 0))
                let uniqueDepartments = []
                let eightUsersWithDepartment = res.data.filter(item => item.company === 'Eight' && item.department!=='')
                eightUsersWithDepartment.forEach(element => {
                    if (!uniqueDepartments.some(item => item.departmentName === element.department)) {
                        uniqueDepartments.push({department: element.department, departmentName: element.department});
                    }
                });
                setDepartments(uniqueDepartments.sort((item1, item2) => item1.department < item2.department ? -1 : 0))
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });

    }, [])

    useEffect(() => {

        if (match.params.userId !== "_add") {

            var companyId =''

            axios.get(process.env.REACT_APP_API_URL + '/users/' + match.params.userId)
                .then((res) => {
                    setFirstName(res.data.firstName)
                    setLastName(res.data.lastName)
                    setEmailId(res.data.emailId)
                    setDepartment(res.data.department)
                    setLineManagerId(res.data.lineManager)
                    setHoursPerWeek(res.data.hoursPerWeek)
                    setCompany(res.data.company)
                    companyId = res.data.company
                })
                .then(()=>{
                    if (companyId){
                        axios.get(process.env.REACT_APP_API_URL + '/clients/'+ companyId)
                            .then((res) => {
                                setClientName(res.data.clientName)
                            })
                            .catch((err) => {
                                console.log(err);
                            }).finally(() => {
                                setLoading(false);
                            });
                    }
                })
                .catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });

        } else {
            setLoading(false)
        }
    }, [match.params.userId])

    const addForm = async (form) => {
        await axios.post(process.env.REACT_APP_API_URL + '/users', form)
    }

    const updateForm = async (form) => {
        await axios.put(process.env.REACT_APP_API_URL + '/users/' + match.params.userId, form)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (match.params.userId !== "_add") {
            updateForm({ firstName, lastName, emailId, company, department, lineManager: lineManagerId==='6303a09f7ba3f683fef4e25a' ? '' : lineManagerId, hoursPerWeek: Number(hoursPerWeek) }).then(res => { navigate(-1) })
        } else {
            // this code is not used to add users
            // addForm({ firstName, lastName, emailId, company, department, lineManager: lineManagerId, hoursPerWeek }).then(res => { navigate(-1) })
        }
    }

    if (!loading && company.length === 24 ? clientName : company) {

        return (

            <div className='flex items-center bg-jungle-mist-100 min-h-screen'>
                <div className="container-form-8A">

                    <Header title="Contacts - Add / Edit Contact" section="Contacts" />

                    <br></br>

                    <form className="add-form-8A" onSubmit={onSubmit}>

                        <div className="form-control-8A">
                            <label>First Name</label>
                            <input type="text" placeholder="Enter First Name Here" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                        </div>
                        <div className="form-control-8A">
                            <label>Last Name</label>
                            <input type="text" placeholder="Enter Last Name Here" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                        </div>
                        <div className="form-control-8A">
                            <label>Email</label>
                            <input disabled type="text" placeholder="Enter Email Address Here" value={emailId} />
                        </div>
                        {company.length===24 &&
                            <div className="form-control-8A">
                                <label>Company</label>
                                <input disabled type="text" value={clientName} />
                            </div>
                        }
                        {company.length!==24 &&
                            <div>
                                <div className="form-control-8A">
                                    <label>Company</label>
                                    <input disabled type="text" value={company} />
                                </div>
                                <div className="form-control-8A">
                                    <label>Line Manager</label>
                                    <select value={lineManagerId} onChange={(e) => setLineManagerId(e.target.value)}>
                                        {users.filter(user => user.role !== 9999 && user.role !== 9998 && user.role !== 3001).map((user) => (
                                            <option key={user.id} value={user.id}>{user.firstName + ' ' + user.lastName}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-control-8A">
                                    <label>Department</label>
                                    <select value={department} onChange={(e) => setDepartment(e.target.value)}>
                                        {departments.map((item) => (
                                            <option value={item.department}>{item.departmentName}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-control-8A">
                                    <label>Hours Per Week</label>
                                    <input type="number" placeholder="Enter ours Per Week Here" value={hoursPerWeek} onChange={(e) => setHoursPerWeek(e.target.value)} />
                                </div>
                            </div>
                        }

                        <input type="submit" value="Save" className="btn-8A btn-block-8A" />

                    </form>
                </div>
            </div>
        )

    } else {

        return (
            <div>
                <Header title="Users - Add / Edit User" section="Users" />
                <p>Data is loading...</p>
            </div>)

    }

}

export default AddOrUpdateUser
