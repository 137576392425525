import React from 'react';
import { useNavigate } from 'react-router-dom'


const ComingSoon = () => {

    const navigate = useNavigate()

    // const goBack = () => {
    //     navigate(-1)
    // }

    const onSubmit = (e) => {

        e.preventDefault()

        navigate("/")

    }


    return (
        <form className="min-h-screen bg-jungle-mist-100 flex flex-wrap items-center justify-center py-2 px-2 sm:px-6 lg:px-8 w-full " onSubmit={onSubmit}>
            <div className="max-w-3xl flex shadow-md overflow-hidden">

                <div className="md:w-1/2 relative bg-white items-center justify-center ">

                    <div className="md:absolute md:inset-x-0 md:bottom-0">
                        <div className="m-12">
                            <img className="m-10 h-32" src='../images/eight-versa-colour.png' />
                        </div>
                        <h2 className=" text-center text-xl text-pacific-blue-700 mb-6 font-bold">Coming soon</h2>
                        <div className="w-4/5 mx-auto">
                            <input type="submit" value="Home Page" className="bg-bondi-blue block mx-auto text-white text-sm uppercase rounded shadow-md px-8 py-3 m-6 hover:cursor-pointer" />
                            {/* <button onClick={goBack} className="bg-bondi-blue block mx-auto text-white text-sm uppercase rounded shadow-md px-8 py-3 m-6 ">Go Back</button> */}
                        </div>

                    </div>
                </div>
                <div className='hidden md:inline-block md:w-1/2'><img src='../images/staircase.jpg' /></div>
            </div>
        </form>
    );

}

export default ComingSoon;