import Header from '../UI/Header'
import axios from 'axios'
import { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import Button from '../UI/Button'
import Sidebar from '../UI/Sidebar'

const XeroConnectionWindow = () => {

    const [sidebarToggle, setSidebarToggle] = useState(false)
    const [loading, setLoading] = useState(false)
    const [redirecting, setRedirecting] = useState(false)
    const [authCode, setAuthCode] = useState('')

    const XeroSendAuth = async () => {

        console.log("XeroSendAuth")
        axios.get(process.env.REACT_APP_API_URL + '/XeroSendAuth/' + authCode)
        .then(() => {
            window.top.close();
            })

    }

    useEffect(() => {

        console.log("useEffectAuthCode")
        console.log(window.location.href)

        let varURL = window.location.href
        let start_number = varURL.search('code=') + 5
        let end_number = varURL.search('&scope')
        let auth_code = varURL.substring(start_number, end_number)

        setAuthCode(auth_code)

        if (start_number > 5) {
            console.log("Auth Code is: " + auth_code)
        }

    }, [])

    if (redirecting) {

        return (
            <Navigate replace to="/login" />
        )
    }

    else if (!loading) {

        return (

            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Xero API" section="Xero API Connection Page" showUserMenu='true' />
                        
                        <div className='mt-40'><p className='text-center text-lg'>Thank you for connecting to Xero</p></div>
                        <div className='text-center'><Button color="bg-bondi-blue" text="Click the here to complete the process" onClick={XeroSendAuth}></Button></div>
                    </div>
                </div>
            </div>
        )

    } else {

        return (
            <div>
                {!sidebarToggle &&
                    <button className="absolute top-2 left-1 text-congress-blue" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                    </button>
                }
                {sidebarToggle &&
                    <button className="absolute top-2 left-1 text-white" onClick={() => setSidebarToggle(!sidebarToggle)}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                }

                <div className="bg-slate-100 flex h-screen overflow-hidden">
                    {sidebarToggle &&
                        <Sidebar />
                    }
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden m-5">
                        <Header title="Xero API" section="Xero API Connection Page" showUserMenu='true' /> 
                        <p>Data is loading</p>
                    </div>

                </div>

            </div>
        )

    }

};

export default XeroConnectionWindow;
