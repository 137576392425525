const ActivityProgressBar = (props) => {
    const { bgcolor, completed, completedLabel } = props;
  
      const containerStyles = {
        height: 10,
        width: '99%',
        backgroundColor: "#ddd",
        borderRadius: 50,
        margin: 2
      }
    
      const fillerStyles = {
        height: '100%',
        width: `${completed}%`,
        backgroundColor: bgcolor,
        borderRadius: 'inherit',
        textAlign: 'right'
      }
    
      const labelStyles = {
        padding: 5,
        color: 'gray',  
        fontSize: 10
      }
    
      return (
  
        <>
    
          <span style={labelStyles}>{isNaN(completedLabel) ? "0%" : `${completedLabel}%`}</span>
      
          <div style={containerStyles}>
    
            <div style={fillerStyles}>
    
            </div>
    
          </div>
    
        </>
    
      );
    
    };
    
    export default ActivityProgressBar;